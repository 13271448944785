import * as datetime from './datetime'

export const highlightColumns = ( selection, container, offset ) => {
    const styles = {
    // eslint-disable-next-line
    "thead th:nth-child(${hour})":
      'border-top: 1px solid #D3AE8B; border-left: 1px solid #D3AE8B; border-right: 1px solid #D3AE8B;',
        // eslint-disable-next-line
    "tbody td:nth-child(${hour})":
      'border-left: 1px solid #D3AE8B; border-right: 1px solid #D3AE8B;',
        // eslint-disable-next-line
    "tbody tr:last-child td:nth-child(${hour})":
      'border-bottom: 1.6px solid #D3AE8B;',
    }

    return Object.keys( styles )
        .map(
            ( rule ) =>
                selection.map(
                    hlColumn( rule, container || '.kfc-schedule', offset ? offset : 0 )
                ) +
        '{' +
        styles[ rule ] +
        '}'
        )
        .join( '' )
}

export const hlColumn = ( tmpl, cnt, offset ) => ( hour ) =>
    parseInt( hour ) > -1
        ? // eslint-disable-next-line
      `${cnt} .staff-schedule-chart.report-table ${tmpl.replace(
            '${hour}',
            hour + 7 + offset
        )}`
        : `${cnt} .staff-schedule-chart.report-table:empty`

export const getHighlights = ( schedule, day, hours, sideDays ) => {
    const max = datetime.maxHours( schedule, day, hours, sideDays ),
          hl = max ? highlightColumns( max ) : null

    return hl
}
