/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Link }             from 'react-router-dom'
import { Card }             from 'antd'
import { LeftOutlined }     from '@ant-design/icons'

/* APPLICATION */
import { CurrencySwitch } from 'components'
import './back-title.scss'

class BackTitle extends Component {
    static propTypes = {
        url:          PropTypes.string,
        text:         PropTypes.string.isRequired,
        showDownload: PropTypes.bool,
        download:     PropTypes.node,

        request: PropTypes.object,

        showCurrency: PropTypes.bool,
        showGrowing:  PropTypes.bool,
        action:       PropTypes.func,
    }

    action = ( e ) => {
        if ( this.props.action ) {
            e.preventDefault()
            this.props.action()
        }
    }

    render () {
        const { text, request, showCurrency, showGrowing, onChange, showDownload, download } = this.props,
              url = this.props.url || '/'

        return (
            <Card bordered={false} className="page-title">
                <Link to={url} onClick={this.action}>
                    <LeftOutlined />
                </Link>

                <h3>{text}</h3>
                {showDownload && download}

                <div className="back-title-content">{this.props.children}</div>

                {showCurrency && (
                    <CurrencySwitch
                        request={request}
                        update={onChange}
                        showGrowing={showGrowing}
                    />
                )}
            </Card>
        )
    }
}

export default BackTitle
