/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import moment               from 'moment'

/* APPLICATION */
import { SIVInfo } from 'components'
import config      from 'config'

import './siv-block.scss'

class SIVBlock extends Component {
    static propTypes = {
        type: PropTypes.string,

        current:     PropTypes.bool,
        currentType: PropTypes.string,
        assign:      PropTypes.object,

        data: PropTypes.shape({
            date:       PropTypes.string,
            siv:        PropTypes.object,
            restaurant: PropTypes.object,
        }),

        onSelect: PropTypes.func,
    }

    select = ( type ) => {
        return () => this.props.onSelect( type )
    }

    render () {
        const { type, data, currentType, current, assign } = this.props,
              date = data && data.date ? moment( data.date ) : null,
              cls = [ 'siv-block' ],
              siv = data && data.siv ? data.siv : {},
              rest = data && data.restaurant ? data.restaurant : {}

        current && cls.push( 'current-' + currentType )

        return (
            <section className={cls.join( ' ' )}>
                <header className="siv-block-header">
                    {date && date.format( config.format.onlyDayView )}
                </header>
                <div className="siv-block-content">
                    <SIVInfo
                        {...siv}
                        aref={assign && currentType === 'siv' ? assign : null}
                        type={type}
                        chart={true}
                        onSelect={this.select( 'siv' )}
                    />
                    <SIVInfo
                        {...rest}
                        aref={assign && currentType === 'restaurant' ? assign : null}
                        type="Ресторан"
                        onSelect={this.select( 'restaurant' )}
                    />
                </div>
            </section>
        )
    }
}

export default SIVBlock
