/* VENDOR */
import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { Layout }           from 'antd'

/* APPLICATION */

import { DashboardCard, ShiftSummary } from 'components'

import { turnoverActions, ratingActions } from 'services'

import { format } from 'tools'
import config     from 'config'
import moment     from 'moment/moment.js'

const { Content } = Layout

class ShiftCard extends Component {
    state = { timeZoneName: null, }

    componentDidMount () {
        this.load( this.props )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const {
            request,
            restaurants
        } = this.props


        if ( restaurants ) {
            const currRest = restaurants.find(( rest ) => +rest.factsNumber === +request.restaurantId )
            this.setState({ timeZoneName: currRest.timeZoneName })
        }

        if ( format.check.request( request, nextProps.request )) {
            this.load( nextProps )
        }
    }

    load = ( props ) => {
        if ( !props.workHours ) { return }

        const req = this.request( props )

        props.flushPreviousSummary()
        props.flushPreviousDishes()

        props.fetchPreviousSummary( req )
        props.fetchPreviousDishes( req )
    }

    request = ( props ) => {
        const req = format.copy.object( props.request ),
              prev = format.dates.getPreviousShift( props.workHours, this.state.timeZoneName )

        Object.assign( req, prev )

        return req
    }

    cardClass = () =>
        this.props.ui.width < config.ui.breakpoint ? 'horizontal' : ''

    render () {
        const currency = format.extract.currency( this.props.request.unitOfMeasure )

        return (
            <DashboardCard spans={[ 24, 7 ]}>
                <Layout>
                    <Content>
                        <h2>Предыдущая смена</h2>

                        <ShiftSummary
                            turnover={this.props.turnover}
                            dishes={this.props.dishes}
                            currency={currency}
                            className={this.cardClass()}
                        />
                    </Content>
                </Layout>
            </DashboardCard>
        )
    }
}

const mapStateToProps = ( state ) => ({
    workHours:   state.settings.workHours,
    ui:          state.ui,
    request:     state.request,
    turnover:    state.turnover.previousShift,
    dishes:      state.rating.previousDishes,
    restaurants: state.restaurants,
}),

      allActions = {
          ...turnoverActions,
          ...ratingActions,
      }

export default connect( mapStateToProps, allActions )( ShiftCard )
