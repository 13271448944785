/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'

/* APPLICATION */
import { Spinner } from 'components'
import { format }  from 'tools'

import './calendar-summary.scss'

class CalendarSummary extends Component {
    static propTypes = { data: PropTypes.object, }

    render () {
        const { data } = this.props

        if ( !data ) { return <Spinner /> }

        return (
            <div className="calendar-summary">
                <h3>Показатели за месяц</h3>
                <p className="done">
                    {format.strings.intPercentage( data.done )} выполнено в срок
                </p>
                <p className="outdated">
                    {format.strings.intPercentage( data.outdated )} выполнено с переносом
                </p>
                <p className="missed">
                    {format.strings.intPercentage( data.missed )} не выполнено
                </p>
            </div>
        )
    }
}

export default CalendarSummary
