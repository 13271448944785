import * as find from './find'

const addDay = ( days, dow, key ) =>
    days.push({
        dayOfWeek: dow,
        [ key ]:   [],
    }),
      findDay = ( days, item, key ) => {
          let found = find.index( days, 'dayOfWeek', item.dayOfWeek )

          if ( found === -1 ) {
              found = days.length
              addDay( days, item.dayOfWeek, key )
          }

          return found
      },
      addItem = ( key, days, prepare ) => {
          return ( item ) => {
              const index = findDay( days, item, key )
              days[ index ][ key ].push( prepare( item ))
          }
      }

export const days = ( key, prepare ) => {
    return ( data ) => {
        let days = []
        const handler = addItem( key, days, prepare )

        data.map( handler )
        days = days.filter(( d ) => d[ key ].length > 0 )

        return days.sort(( a, b ) => a.dayOfWeek - b.dayOfWeek )
    }
}
