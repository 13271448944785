import { format } from 'tools'

export const statisticsSummaryRows = [
    {
        key:    'factTransactions',
        label:  'Всего транзакций',
        format: format.strings.thousand,
        props:  { className: 'offset', },
    },
    {
        key:    'factTurnoverGross',
        label:  'Грязный товарооборот',
        format: format.strings.thousand,
    },
    {
        key:    'factTurnoverNet',
        label:  'Чистый товарооборот',
        format: format.strings.thousand,
    },
    {
        key:    'planTurnover',
        label:  'Плановый товарооборот',
        format: format.strings.thousand,
    },
    {
        key:    'deltaTurnover',
        label:  'Разница (План ТО - Чистый ТО)',
        format: format.strings.delta,
    },
    {
        key:    'factProductiveHours',
        label:  'Отработано производ. часов',
        format: format.strings.float,
    },
    {
        key:    'planProductiveHours',
        label:  'Запланировано производ. часов',
        format: format.strings.float,
    },
    {
        key:    'factUnproductiveHours',
        label:  'Отработано непроизвод. часов',
        format: format.strings.float,
    },
    {
        key:    'planUnproductiveHours',
        label:  'Запланировано непроизвод. часов',
        format: format.strings.float,
        props:  { className: 'offset', },
    },
    {
        key:    'factShiftSupervisorHours',
        label:  'Отработано часов МС',
        format: format.strings.float,
    },
    {
        key:    'planShiftSupervisorHours',
        label:  'Запланировано часов МС',
        format: format.strings.float,
    },
    {
        key:    'paidOverwork',
        label:  'Оплачиваемые переработки',
        format: format.strings.float,
    },
    {
        key:    'factTotalHours',
        label:  'Отработано общих часов',
        format: format.strings.float,
        props:  { className: 'offset', },
    },
    {
        key:    'planTotalHours',
        label:  'Запланировано общих часов',
        format: format.strings.float,
    },
    {
        key:    'averageRate',
        label:  'Средняя ставка',
        format: format.strings.float,
    },
    {
        key:    'labor',
        label:  'Labor cost',
        format: format.strings.float,
    },
    {
        key:    'laborPercent',
        label:  'Labor %',
        format: format.strings.float,
    },
    {
        key:    'factSpmh',
        label:  'SPMH факт',
        format: ( d ) => format.strings.thousand( format.strings.float( d )),
    },
    {
        key:    'planSpmh',
        label:  'SPMH план',
        format: ( d ) => format.strings.thousand( format.strings.float( d )),
    },
    {
        key:    'deltaSpmh',
        label:  'SPMH разница',
        format: ( d ) => format.strings.delta( format.strings.float( d )),
    },
    {
        key:    'factTcph',
        label:  'TCPH факт',
        format: format.strings.thousand,
    },
    {
        key:    'planTcph',
        label:  'TCPH план',
        format: format.strings.thousand,
    },
    {
        key:    'deltaTcph',
        label:  'TCPH разница',
        format: format.strings.thousand,
    },
    {
        key:    'factItemsPerHour',
        label:  'Items per Hour',
        format: format.strings.thousand,
    },
    {
        key:    'allProductsInChecks',
        label:  'Количество блюд',
        format: format.strings.thousand,
    },
    {
        key:    'totalPaid',
        label:  'Всего оплачивать',
        format: format.strings.thousand,
        props:  { className: 'offset', },
    },
    {
        key:   'unassignedShiftsRelative',
        label: 'Неназначенные смены, %',
        props: { className: 'offset', },
    },
    {
        key:   'unassignedHoursRelative',
        label: 'Неназначенные часы, %',
    },
    {
        key:   'scheduleMistakes',
        label: 'Ошибки',
    },
    {
        key:   'totalShifts',
        label: 'Всего смен',
    },
]
