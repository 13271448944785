import shared from './shared'
import column from './helpers'

export default {
    averageCheck: ( request ) => [
        shared.label( request ),
        column( 'Факт', 'factAverageCheck', {
            numeric: true,
            as:      'currency',
            sorter:  'bynull',
        }),
        column( 'Цель', 'planAverageCheck', {
            numeric: true,
            as:      'currency',
            sorter:  'bynull',
        }),
        column( 'Разница', 'deltaAverageCheck', {
            numeric: true,
            as:      'currency',
            sorter:  'bynull',
            cell:    [ 'cellclass' ],
        }),
        column( 'Прош. год', 'factAverageCheckLastYear', {
            numeric: true,
            as:      'currency',
            sorter:  'bynull',
        }),
        column( 'Разница', 'deltaAverageCheckLastYear', {
            numeric: true,
            as:      'currency',
            sorter:  'bynull',
            cell:    [ 'cellclass' ],
        }),
        {
            title:    'Транзакции',
            children: [
                column( 'Факт', 'factTransactions', {
                    width:   60,
                    numeric: true,
                    format:  'thousand',
                    sorter:  'bynull',
                }),
                column( 'Прош. год', 'factTransactionsLastYear', {
                    width:   60,
                    numeric: true,
                    format:  'thousand',
                    sorter:  'bynull',
                }),
            ],
        },
        shared.managers,
    ],

    turnover: ( request ) => [
        shared.label( request ),
        column( 'Факт', 'factTurnover', {
            numeric: true,
            as:      'currency',
            sorter:  'bynull',
        }),
        column( 'Цель', 'planTurnover', {
            numeric: true,
            as:      'currency',
            sorter:  'bynull',
        }),
        column( 'Разница', 'deltaTurnover', {
            numeric: true,
            as:      'currency',
            sorter:  'bynull',
            cell:    [ 'cellclass' ],
        }),
        column( 'Прош. год', 'factTurnoverLastYear', {
            numeric: true,
            as:      'currency',
            sorter:  'bynull',
        }),
        column( 'Разница', 'deltaTurnoverLastYear', {
            numeric: true,
            as:      'currency',
            sorter:  'bynull',
            cell:    [ 'cellclass' ],
        }),
        shared.managers,
    ],
}
