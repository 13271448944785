/* eslint-disable camelcase */
/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import moment               from 'moment'
import { Button }           from 'antd'

/* APPLICATION */
import {
    AddButton,
    ViewTypeDrop,
    ViewTypeSwitch,
    GenerateSchedule,
    DiscardChanges,
    ScheduleTurnover,
} from 'components'

import config from 'config'

import './schedule-controls.scss'

const basic = {
    current: 'На текущую неделю',
    next:    'На следующую неделю',
}

class ScheduleControls extends Component {
    static propTypes = {
        user:     PropTypes.object,
        list:     PropTypes.array,
        current:  PropTypes.string,
        turnover: PropTypes.array,
        request:  PropTypes.object,

        compact:          PropTypes.bool,
        showDays:         PropTypes.bool,
        changed:          PropTypes.bool,
        popup:            PropTypes.bool,
        saveTurnover:     PropTypes.bool,
        errorTurnover:    PropTypes.bool,
        errorSetTurnover: PropTypes.bool,
        isSummaryReport:  PropTypes.bool,

        onWeek:              PropTypes.func,
        onGenerate:          PropTypes.func,
        onRebuild:           PropTypes.func,
        getTurnover:         PropTypes.func,
        setTurnover:         PropTypes.func,
        flushTurnover:       PropTypes.func,
        changeSummaryReport: PropTypes.func,
    }

    constructor ( props ) {
        super( props )
        this.state = {
            weeks:  {},
            change: null,
            date:   null,

            popup:        props.popup,
            generate:     false,
            turnover:     false,
            loadTurnover: false,
        }
    }

    componentDidMount () {
        this.init( this.props )
        this.props.onWeek( this.props.current )
    }

    componentDidUpdate ( prevProps, prevState ) {
        const { generate, date } = this.state,
              { saveTurnover, errorTurnover, errorSetTurnover } = this.props

        if ( errorTurnover && errorTurnover !== prevProps.errorTurnover ) {
            this.setState({ loadTurnover: false })
        }

        if ( errorSetTurnover && errorSetTurnover !== prevProps.errorSetTurnover ) {
            this.setState({ loadTurnover: false })
        }

        generate !== prevState.generate && ( window._schedule_popup = generate )

        saveTurnover && this.hidePopup()
        saveTurnover && this.preGenerate({ week: date })
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        if ( nextProps.list !== this.props.list ) {
            this.init( nextProps )
        }

        if ( nextProps.popup !== this.props.popup && nextProps.popup ) {
            this.generate()
        }
    }

    init = ( props ) =>
        this.setState({ weeks: this.weeks( props ), })

    weeks = ( props ) => {
        const res = {}

        if ( props.list ) {
            props.list
                .filter(( d ) =>
                    moment( d, config.format.date ).isAfter(
                        moment().startOf( 'week' ).add( 1, 'week' )
                    )
                )
                .sort(
                    ( a, b ) =>
                        moment( a, config.format.date ).valueOf() -
                        moment( b, config.format.date ).valueOf()
                )
                .forEach(( start ) => ( res[ start.split( 'T' )[ 0 ] ] = this.week( start )))
        }


        return res
    }

    week = ( str ) => {
        const format = config.format.onlyDayView,
              date = moment( str, config.format.date ),
              start = moment( date.startOf( 'week' )),
              end = moment( date.endOf( 'week' )),
              dates = [ '–', end.format( format ), end.format( 'YYYY' ) ]


        dates.unshift(
            start.isSame( end, 'month' ) ? start.format( 'D' ) : start.format( format )
        )

        return dates.join( ' ' )
    }

    onChange = ( data ) => {
        this.props.changed ? this.showPopup( data ) : this.onWeek( data )
    }

    onWeek = ( data ) => this.props.onWeek( data )
    onPopup = () => this.onWeek( this.state.change )

    generate = () =>
        this.setState({ generate: true, })

    showPopup = ( data ) =>
        this.setState({
            change: data,
            popup:  true,
        })

    hidePopup = () => {
        this.setState({
            change:       null,
            popup:        false,
            generate:     false,
            turnover:     false,
            date:         null,
            loadTurnover: false,
        })

        this.props.flushTurnover()
    }

    callback = ( cb ) => {
        return ( data ) => {
            cb( data )
            this.hidePopup()
        }
    }

    preGenerate = ( date ) => {
        const datesInStorage = JSON.parse( sessionStorage.getItem( 'scheduleData' ))

        datesInStorage.start = moment( date.week ).startOf( 'week' ).format( config.format.dateFull )
        datesInStorage.end = moment( date.week ).endOf( 'week' ).format( config.format.dateFull )

        sessionStorage.setItem( 'scheduleData', JSON.stringify( datesInStorage ))

        this.callback( this.props.onGenerate )({ date: date.week })
    }

    showTurnover = ( date ) =>
        this.setState({
            generate: false,
            turnover: true,
            date:     date,
        })

    setTurnover = ( data ) => {
        this.setState({ loadTurnover: true, })
        this.props.setTurnover( data )
    }

    haveNext = () => {
        const { list } = this.props,
              { weeks } = this.state

        if ( !list ) {
            return false
        }
        if ( list.length < 1 ) {
            return false
        }

        return Object.keys( weeks ).length > 0
    }

    render () {
        const {
            current,
            showDays,
            list,
            getTurnover,
            compact,
            user,
            errorTurnover,
            addServerError
        } = this.props,
              { weeks, days, day, generate, turnover, popup, date, loadTurnover } =
                this.state

        return (
            <div className="schedule-controls view-type-switch">
                <ViewTypeSwitch
                    className="left"
                    tabs={basic}
                    current={current}
                    update={this.onChange}
                />

                {this.haveNext() && (
                    <ViewTypeDrop
                        items={weeks}
                        current={current}
                        update={this.onChange}
                    />
                )}

                <div className="generator-container">
                    <AddButton text="Сформировать..." action={this.generate}/>
                </div>


                <div className="generator-container">
                    <Button
                        type="ghost"
                        className="compact-button"
                        onClick={this.props.changeSummaryReport}
                    >
                        {this.props.isSummaryReport ? 'Вернуться к обычному расписанию' : 'Сводное расписание'}
                    </Button>
                </div>







                {false && showDays && (
                    <ViewTypeSwitch
                        className="capitalize"
                        tabs={days}
                        current={day}
                        update={this.onDay}
                    />
                )}



                <Button
                    type="primary"
                    className="compact-button"
                    onClick={this.props.onCompact}
                >
                    {compact ? 'Полный вид' : 'Компактный вид'}
                </Button>

                <GenerateSchedule
                    list={list}
                    visible={generate}
                    onSubmit={this.preGenerate}
                    onShow={this.callback( this.onChange )}
                    onCancel={this.hidePopup}
                    editPlan={this.showTurnover}
                    jobRole={user.jobRole}
                />

                <ScheduleTurnover
                    visible={turnover}
                    loading={loadTurnover}
                    date={date}
                    turnover={this.props.turnover}
                    error={errorTurnover}
                    getTurnover={getTurnover}
                    onSubmit={this.setTurnover}
                    onCancel={this.hidePopup}
                    addServerError={addServerError}
                />

                <DiscardChanges
                    title="Вы действительно хотите перейти на другую неделю?"
                    ok="Перейти"
                    visible={popup}
                    onSubmit={this.callback( this.onPopup )}
                    onCancel={this.hidePopup}
                />
            </div>
        )
    }
}

export default ScheduleControls
