/* VENDOR */
import axios from 'axios'

/* APPLICATION */
import { format } from 'tools'
import config     from 'config'

const service = 'business'

export const getCalendarEvents = ( params ) =>
    format.generate.get( service, 'calendar/inbox', params )
export const getCalendar = ( params ) =>
    format.generate.get( service, 'calendar', params )
export const getCalendarSummary = ( params ) =>
    format.generate.get( service, 'calendar/summary', params )
export const getCalendarSub = ( {rid, ...params} ) =>
    format.generate.get( service, 'calendar/rgm/' + rid, params )

export const updateCalendarEvent = ({ single, ...body }) =>
    axios.put( config.api.business + 'calendar/' + body.taskId, body, {
        ...config.api.options,
        params: { single },
    })

export const addCalendarEvent = ( body ) =>
    axios.post( config.api.business + 'calendar', body, config.api.options )

export const completeCalendarEvent = ( taskId ) =>
    axios.post(
        config.api.business + 'calendar/' + taskId + '/complete',
        {},
        config.api.options
    )

export const removeCalendarEvent = ({ id, ...params }) =>
    axios.delete( config.api.business + 'calendar/' + id, {
        ...config.api.options,
        params,
    })
