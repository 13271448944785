/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'

/* APPLICATION */
import './value.scss'

class Value extends Component {
    static propTypes = {
        value:    PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
        refValue: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),

        prepend: PropTypes.string,
        addon:   PropTypes.string,
        noClass: PropTypes.bool,

        format:  PropTypes.func,
        compare: PropTypes.func,
    }

    status = () => {
        const { noClass, value, refValue, compare } = this.props,
              ref = refValue || 0

        if ( noClass || value === ref ) { return '' }
        if ( !value && value !== 0 ) { return '' }

        if ( value ) { return 'none' }

        if ( compare ) {
            return compare( value, ref ) ? 'good' : 'bad'
        }

        return value > ref ? 'good' : 'bad'
    }

    render () {
        const { prepend, value, addon } = this.props,
              format = this.props.format ? this.props.format : ( n ) => n

        return (
            <span className={'data-value ' + this.status()}>
                {prepend} {format( value || '' )} {addon}
            </span>
        )
    }
}

export default Value
