/* VENDOR */
import { Component } from 'react'

/* APPLICATION */
import config from 'config'

export const createIcon = ( i, a ) => ({
    def:    i,
    active: a,
})

export class Icon extends Component {
    constructor ( props ) {
        super( props )
        this.primary = props.primary || config.ui.colors.def.a
        this.secondary = props.secondary || config.ui.colors.def.b
    }
}

export class IconActive extends Component {
    constructor ( props ) {
        super( props )
        this.primary = props.primary || config.ui.colors.active.a
        this.secondary = props.secondary || config.ui.colors.active.b
    }
}
