/* APPLICATION */
import { format } from 'tools'

import Template from './Template.js'
import './schedule-summary.scss'

const rows = ( data ) => [ data.total ],
      columns = () => ({
          total:     ( row ) => 'Всего часов: ' + format.strings.float( row.hours ),
          work:      ( row ) => 'Производственные: ' + format.strings.float( row.production ),
          intersect: ( row ) => 'Общие: ' + format.strings.float( row.common ),
          nonWork:   ( row ) =>
              'Непроизводственные: ' + format.strings.float( row.nonProduction ),
          salaried: ( row ) => 'Salaried hours: ' + format.strings.float( row.salaried ),
      })

class ScheduleSummary extends Template {
    constructor ( props ) {
        super( props )

        this.state = {
            data:      rows( props.data ),
            columns:   columns( props.data ),
            className: 'schedule-summary',
        }
    }

    data = ( data ) => rows( data )
    columns = ( data ) => columns( data )
}

export default ScheduleSummary
