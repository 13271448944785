import { format } from 'tools'
import config     from 'config'

export const shift = ( list, pos, shift ) => {
    const emp = list.find(( e ) => e.id === shift.name ),
          s = {
              guid:         format.generate.guid(),
              employeeId:   emp.id,
              employeeName: emp.name,
              shiftStart:   shift.shiftStart.format( config.format.dateFull ),
              shiftEnd:     shift.shiftEnd.format( config.format.dateFull ),
              _double:      !!shift._double,
          }

    pos.shifts.push( s )
}
