/* VENDOR */
import React, { Component } from 'react'

/* APPLICATION */
import './link.scss'

class Link extends Component {
    render () {
        const { className, children } = this.props,
              cls = className ? [ className, 'link' ].join( ' ' ) : 'link'

        return (
            <span {...this.props} className={cls}>
                {children}
            </span>
        )
    }
}

export default Link
