import {dropTypesColumns, dropTypesKeys} from '../../GuestVoteTable/const';
import {DataGroupType} from 'services/guestVote/statistic/type';

export const getConsolidatedStatisticsExcelColumns = (currentTypesFilter: DataGroupType) => {
    const columns = [
        {
            title: 'ID обращения',
            dataIndex: 'id',
            key: 'id',
            width: {wpx: 100}
        },
        {
            title: 'Создано',
            dataIndex: 'createDate',
            key: 'createDate',
            width: {wpx: 150}
        },
        {
            title: 'Источник',
            dataIndex: 'feedbackSource',
            key: 'feedbackSource',
            width: {wpx: 150}
        },
        {
            title: 'Ресторан',
            dataIndex: 'restaurantName',
            key: 'restaurantName',
            width: {wpx: 300}
        },
        {
            title: `${dropTypesColumns[currentTypesFilter]}`,
            dataIndex: `${dropTypesKeys[currentTypesFilter]}`,
            key: `${dropTypesKeys[currentTypesFilter]}`,
            width: {wpx: 300}
        },
        {
            title: 'Статус',
            dataIndex: 'feedbackStatus',
            key: 'feedbackStatus',
            width: {wpx: 150}
        },
        {
            title: 'Исполнитель',
            dataIndex: 'feedbackExecutor',
            key: 'feedbackExecutor',
            width: {wpx: 150}
        },
        {
            title: 'Руководитель',
            dataIndex: 'headOfEmployee',
            key: 'headOfEmployee',
            width: {wpx: 150}
        },
        {
            title: 'Содержание',
            dataIndex: 'feedbackFullText',
            key: 'feedbackFullText',
            width: {wpx: 150}
        },
        {
            title: 'Решение для ресторана',
            dataIndex: 'solutionRestaurant',
            key: 'solutionRestaurant',
            width: {wpx: 150}
        },
        {
            title: 'Решение для гостей',
            dataIndex: 'solutionGuest',
            key: 'solutionGuest',
            width: {wpx: 150}
        },
        {
            title: 'Категория',
            dataIndex: 'feedbackCategory',
            key: 'feedbackCategory',
            width: {wpx: 150}
        },
        {
            title: 'Подкатегория',
            dataIndex: 'subcategory',
            key: 'subcategory',
            width: {wpx: 150}
        },
        {
            title: 'Тип обращения',
            dataIndex: 'feedbackType',
            key: 'feedbackType',
            width: {wpx: 150}
        },
        {
            title: 'Уровень влияния',
            dataIndex: 'influenceLevel',
            key: 'influenceLevel',
            width: {wpx: 150}
        },
        {
            title: 'Канал продаж',
            dataIndex: 'saleChannelName',
            key: 'saleChannelName',
            width: {wpx: 150}
        },
        {
            title: 'Поставщик доставки',
            dataIndex: 'deliveryProvider',
            key: 'deliveryProvider',
            width: {wpx: 150}
        },
        {
            title: 'ID заказа',
            dataIndex: 'orderUuid',
            key: 'orderUuid',
            width: {wpx: 150}
        },
        {
            title: 'Оценка',
            dataIndex: 'rating',
            key: 'rating',
            width: {wpx: 150}
        },
    ]

    if (currentTypesFilter === 'RESTAURANTS') {
        columns.splice(3, 0, {
            title: 'Код ресторана',
            dataIndex: 'factsNumber',
            key: 'factsNumber',
            width: {wpx: 100}
        });
    }

    return columns
}
