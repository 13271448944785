/* VENDOR */
import { update } from 'reduxigen'

/* APPLICATION */
import * as api   from './api'
import { format } from 'tools'

const asyncUpdate = format.update.asyncu,
      helper = format.helper.basic( 'categories' )

export const flushCategoriesList = update(
    'categories.flush.list',
    ( data, state ) => ({
        ...state,
        categories: {
            ...state.categories,
            list: null,
        },
    })
)

export const flushCategory = update(
    'categories.flush.category',
    ( data, state ) => ({
        ...state,
        categories: {
            ...state.categories,
            category: null,
        },
    })
)

export const flushDishList = update(
    'categories.flush.dishes',
    ( data, state ) => ({
        ...state,
        categories: {
            ...state.categories,
            dishes: null,
        },
    })
)

export const fetchCategories = asyncUpdate(
    'categories.list',
    ( params ) => api.getCategories( params ),
    ( event, state ) => helper( 'list', event.data, state, event.status )
)

export const fetchCategory = asyncUpdate(
    'categories.category',
    ( params ) => api.getCategory( params ),
    ( event, state ) => helper( 'category', event.data, state, event.status )
)

export const fetchDishList = asyncUpdate(
    'categories.dishes',
    ( params ) => api.getDishList( params ),
    ( event, state ) => {
        const content = event.data,
              number = event.config.params.page,
              last = content.length === 0

        return helper( 'dishes', { content, number, last }, state, event.status )
    }
)

export const appendDishList = asyncUpdate(
    'categories.dishes.append',
    ( params ) => api.getDishList( params ),
    ( event, state ) => {
        let content = event.data

        const number = event.config.params.page,
              last = content.length === 0

        state.categories.dishes &&
      ( content = [ ...state.categories.dishes.content, ...content ])

        return {
            ...state,
            categories: {
                ...state.categories,
                dishes: {
                    ...state.categories.dishes,
                    content,
                    number,
                    last,
                },
            },
        }
    }
)

export const addCategory = asyncUpdate(
    'categories.add',
    ( body ) => api.addCategory( body ),
    ( event, state ) => {
        if ( event.status !== 200 ) {
            return state
        }

        const list = format.copy.array( state.categories.list ),
              index = list.find(( c ) => c.categoryId === event.data.id )

        index > -1 ? ( list[ index ] = event.data ) : list.push( event.data )

        return {
            ...state,
            categories: {
                ...state.categories,
                list,
                category: event.data,
            },
        }
    }
)

export const updateCategory = asyncUpdate(
    'categories.update',
    ( body ) => api.updateCategory( body ),
    ( event, state ) => {
        if ( event.status !== 200 ) {
            return state
        }

        const category = JSON.parse( event.config.data ),
              list = format.copy.array( state.categories.list )

        if ( event.status === 200 ) {
            const index = list.indexOf(
                list.find(( item ) => item.categoryId === category.categoryId )
            )
            list[ index ] = category
        }

        return {
            ...state,
            categories: {
                category,
                list,
            },
        }
    }
)

export const removeCategory = asyncUpdate(
    'categories.remove',
    ( body ) => api.removeCategory( body ),
    ( event, state ) => {
        if ( event.status !== 200 ) {
            return state
        }

        const cid = event.config.url.split( '/' ).pop(),
              list = format.copy.array( state.categories.list ),
              index = list.indexOf( list.find(( c ) => c.categoryId === cid ))

        if ( event.status === 200 ) {
            list.splice( index, 1 )
        }

        return {
            ...state,
            categories: {
                category: null,
                list,
            },
        }
    }
)

export const addDishToCategory = asyncUpdate(
    'categories.add.dish',
    ( params ) => api.addDish( params ),
    ( event, state ) => {
        if ( event.status !== 200 ) {
            return state
        }

        const data = JSON.parse( event.config.data ),
              category = format.copy.object( state.categories.category ),
              found = category.dishes.find(( d ) => d.productUid === data.productUid )

        !found && category.dishes.push( data )

        return {
            ...state,
            categories: {
                ...state.categories,
                category,
            },
        }
    }
)

export const removeDish = asyncUpdate(
    'categories.remove.dish',
    ( body ) => api.removeDish( body ),
    ( event, state ) => {
        if ( event.status !== 200 ) {
            return state
        }

        const puid = event.config.url.split( '/' ).pop(),
              category = format.copy.object( state.categories.category ),
              index = category.dishes.indexOf(
                  category.dishes.find(( c ) => c.productUid === parseInt( puid ))
              )

        category.dishes.splice( index, 1 )

        return {
            ...state,
            categories: {
                ...state.categories,
                category,
            },
        }
    }
)
