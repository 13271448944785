// @ts-ignore
import ReactExport from 'react-data-export';
import {format} from 'tools';
import {DataGroupType, PostMetricsSearchResponse, PostMetricsSummaryResponse} from 'services/guestVote/statistic/type';
import {dropTypesFilter, dropTypesKeys} from '../../GuestVoteTable/const';
import {getStatisticsTableColumns} from '../../statistics/StatisticsTab/const';
import config from 'config';
import React from 'react';
import {PostFeedbacksSearchResponse} from 'services/guestVote/consolidatedStatistics/type';
import {getConsolidatedStatisticsExcelColumns} from '../../statistics/ConsolidatedStatisticsTab/const';

const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const red = '00FF0000';
const green = '00038E1D';
const black = '00000000';

export const prepareConsolidatedStatisticsExcel = (feedbacksSearch: PostFeedbacksSearchResponse, dataGroup: DataGroupType) => {
    const columns = getConsolidatedStatisticsExcelColumns(dataGroup)

    const data = feedbacksSearch.content.map((item) => {
        const row = [
            {value: item.id === null ? config.ui.nullSymbol : item.id},
            {value: item.createDate === null ? config.ui.nullSymbol : item.createDate},
            {value: item.feedbackSource === null ? config.ui.nullSymbol : item.feedbackSource},
            {value: item.restaurantName === null ? config.ui.nullSymbol : item.restaurantName},
            {value: item[dropTypesKeys[dataGroup]] === null ? config.ui.nullSymbol : item[dropTypesKeys[dataGroup]]},
            {value: item.feedbackStatus === null ? config.ui.nullSymbol : item.feedbackStatus},
            {value: item.feedbackExecutor === null ? config.ui.nullSymbol : item.feedbackExecutor},
            {value: item.headOfEmployee === null ? config.ui.nullSymbol : item.headOfEmployee},
            {value: item.feedbackFullText === null ? config.ui.nullSymbol : item.feedbackFullText},
            {value: item.solutionRestaurant === null ? config.ui.nullSymbol : item.solutionRestaurant},
            {value: item.solutionGuest === null ? config.ui.nullSymbol : item.solutionGuest},
            {value: item.feedbackCategory === null ? config.ui.nullSymbol : item.feedbackCategory},
            {value: item.subcategory === null ? config.ui.nullSymbol : item.subcategory},
            {value: item.feedbackType === null ? config.ui.nullSymbol : item.feedbackType},
            {value: item.influenceLevel === null ? config.ui.nullSymbol : item.influenceLevel},
            {value: item.saleChannelName === null ? config.ui.nullSymbol : item.saleChannelName},
            {value: item.deliveryProvider === null ? config.ui.nullSymbol : item.deliveryProvider},
            {value: item.orderUuid === null ? config.ui.nullSymbol : item.orderUuid},
            {value: item.rating === null ? config.ui.nullSymbol : item.rating},
        ]

        if (dataGroup === 'RESTAURANTS') {
            row.splice(3, 0, {value: item.factsNumber === null ? config.ui.nullSymbol : item.factsNumber});
        }

        return row
    })

    const result = [
        {
            columns: columns,
            data: data,
        }
    ]

    return <ExcelSheet dataSet={result} name='consolidated statistics'/>;
}

export const summaryTableData = (happinessIndexesSummary: PostMetricsSummaryResponse, happinessIndexesSearch: PostMetricsSearchResponse, currentTypesFilter: DataGroupType) => {
    let newHappinessIndexSummary = format.copy.object(happinessIndexesSummary);
    let newHappinessIndexesSearch = format.copy.object(happinessIndexesSearch) as PostMetricsSearchResponse;

    newHappinessIndexSummary._alwaysOnTop = true;
    newHappinessIndexSummary.name = `Все ${dropTypesFilter[currentTypesFilter]}`;
    newHappinessIndexesSearch.content = [ newHappinessIndexSummary, ...newHappinessIndexesSearch.content ]

    return newHappinessIndexesSearch
}


export const prepareHappinessIndexesExcel = (happinessIndexesSummary: PostMetricsSummaryResponse, happinessIndexesSearch: PostMetricsSearchResponse, currentTypesFilter: DataGroupType) => {
    const newHappinessIndex = summaryTableData(happinessIndexesSummary, happinessIndexesSearch, currentTypesFilter);
    const columns = getStatisticsTableColumns(currentTypesFilter).map((item) => ({
        ...item,
        width: {wpx: item.width ?? 200}
    }));
    if (currentTypesFilter === 'RESTAURANTS') {
        columns.unshift({
            title: 'Код',
            dataIndex: 'id',
            key: 'id',
            width: {wpx: 100},
            sorter: (a: { factHappinessIndex: number; }, b: {
                factHappinessIndex: number;
            }) => a.factHappinessIndex - b.factHappinessIndex
        });
    }

    const data = newHappinessIndex.content.map((item) => {
        const row = [
            {value: item.name === null ? config.ui.nullSymbol : item.name},
            {value: item.factHappinessIndex === null ? config.ui.nullSymbol : item.factHappinessIndex},
            {value: item.factHappinessIndexLastYear === null ? config.ui.nullSymbol : item.factHappinessIndexLastYear},
            {
                value: item.deltaFactHappinessIndexLastYear === null ? config.ui.nullSymbol : item.deltaFactHappinessIndexLastYear,
                //@ts-ignore
                style: {font: {color: {rgb: item.deltaFactHappinessIndexLastYear < 0 ? red : item.deltaFactHappinessIndexLastYear > 0 ? green : black}}}
            },
            {value: item.factOsat === null ? config.ui.nullSymbol : `${item.factOsat}%`},
            {value: item.factOsatLastYear === null ? config.ui.nullSymbol : `${item.factOsatLastYear}%`},
            {
                value: item.deltaFactOsatLastYear === null ? config.ui.nullSymbol : `${item.deltaFactOsatLastYear}%`,
                // @ts-ignore
                style: {font: {color: {rgb: item.deltaFactOsatLastYear < 0 ? red : item.deltaFactOsatLastYear > 0 ? green : black}}}
            },

            {value: item.factFeedbacksRating === null ? config.ui.nullSymbol : item.factFeedbacksRating},
            {value: item.factFeedbacksRatingLastYear === null ? config.ui.nullSymbol : item.factFeedbacksRatingLastYear},
            {value: item.factFeedbacks === null ? config.ui.nullSymbol : item.factFeedbacks},
            {value: item.factTransactions === null ? config.ui.nullSymbol : item.factTransactions},
            {value: item.factFeedbacksPer10KTransactions === null ? config.ui.nullSymbol : item.factFeedbacksPer10KTransactions},
        ];

        if (currentTypesFilter === 'RESTAURANTS') {
            row.unshift({value: item.id === null ? config.ui.nullSymbol : item.id});
        }

        return row;
    });

    const result = [
        {
            columns: columns,
            data: data,
        }
    ];

    return <ExcelSheet dataSet={result} name='statistics'/>;
}
