/* VENDOR */
import React from 'react'

/* APPLICATION */
import { Icon, IconActive, createIcon } from './Icon'

const svg = () => (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2 12C1.9999 13.5702 2.36955 15.1183 3.07901 16.519C3.78847 17.9197 4.8178 19.1337 6.08367 20.0627C7.34955 20.9916 8.81638 21.6095 10.3654 21.8661C11.9145 22.1228 13.5022 22.011 15 21.54C12.9696 20.9019 11.1957 19.6327 9.93641 17.9169C8.67711 16.2011 7.99807 14.1283 7.99807 12C7.99807 9.87168 8.67711 7.79888 9.93641 6.0831C11.1957 4.36733 12.9696 3.09808 15 2.46C13.5022 1.98895 11.9145 1.87723 10.3654 2.13389C8.81638 2.39054 7.34955 3.00836 6.08367 3.93731C4.8178 4.86627 3.78847 6.08026 3.07901 7.48099C2.36955 8.88173 1.9999 10.4298 2 12Z"
            fill="#3D5AFE"
        />
    </svg>
)

class Night extends Icon {
    render () {
        return svg( this.primary, this.secondary )
    }
}

class NightActive extends IconActive {
    render () {
        return svg( this.primary, this.secondary )
    }
}

export default createIcon( Night, NightActive )
