/* VENDOR */
import React, { Component } from 'react'
import { connect }          from 'react-redux'
import PropTypes            from 'prop-types'
import moment               from 'moment'
import { Popover }          from 'antd'

/* APPLICATION */
import { format } from 'tools'

import './local-time.scss'
import config from 'config'

let timer = null

class LocalTime extends Component {
    static propTypes = {
        rid:   PropTypes.number,
        ready: PropTypes.bool,

        bothTimes: PropTypes.bool,
    }

    constructor ( props ) {
        super( props )

        this.state = { popover: props.ready && !!props.rid, }
    }

    componentDidMount () {
        this.hidePopover()
    }

    componentDidUpdate ( prevProps ) {
        this.state.popover && this.hidePopover()
        !prevProps.ready &&
      this.props.ready &&
      this.props.rid &&
      this.showPopover()
        !prevProps.rid && this.props.rid && this.showPopover()
    }

    componentWillUnmount () {
        clearTimeout( timer )
    }

    showPopover = () => {
        clearTimeout( timer )
        this.setState({ popover: true })
    }

    hidePopover = () =>
        ( timer = setTimeout(
            () =>
                this.setState({ popover: false, }),
            4000
        ))

    city = () => {
        const timeZone = Date().toString()
            .split( '(' )[ 1 ].split( ')' )[ 0 ]
            .replace( ' Standard Time', '' )

        if ( timeZone.match( /\d/g )) {
            const customTimeZone = Date().toString().split( 'GMT' )[ 1 ].split( ' (' )[ 0 ]
            if ( config.defs.timeZones[ customTimeZone ]) {
                return config.defs.timeZones[ customTimeZone ].replace( ', стандартное время', '' )
            }
        }
        return timeZone.replace( ', стандартное время', '' )
    }
    time = ( offset ) => moment().add( offset, 'hours' ).format( 'HH:mm' )
    roffset = () => -new Date().getTimezoneOffset() / 60
    offset = () => format.strings.delta( this.roffset())

    local = () => (
        <div className="time-info">
            <h3>Время вашего устройства</h3>
            <p>
                {this.city()}, {this.time()} (UTC {this.offset()})
            </p>
        </div>
    )

    data = ( rid ) =>
        this.props.restaurants
            ? this.props.restaurants.find(( r ) => r.factsNumber === rid )
            : null

    dtime = ( data ) =>
        `${this.time(
            this.doffset( data ) - this.roffset()
        )} (UTC ${format.strings.delta( this.doffset( data ))})`
    doffset = ( data ) => parseInt( data.timeZoneName.replace( 'GMT', '' ))

    byData = ( data ) =>
        data ? (
            <div className="time-info">
                <h3>Время ресторана</h3>
                <p>
                    {data.region}, {this.dtime( data )}
                </p>
            </div>
        ) : null

    render () {
        const { rid, bothTimes } = this.props,
              { popover } = this.state

        return (
            <Popover
                open={popover}
                content="!"
                placement="left"
                overlayClassName="warning"
                getPopupContainer={( t ) => t}
            >
                <div className="local-time">
                    {rid && !bothTimes && this.byData( this.data( rid ))}
                    {!rid && !bothTimes && this.local()}
                    {bothTimes && <>{this.byData( this.data( rid ))} {this.local()}</>}
                </div>
            </Popover>
        )
    }
}

const mapStateToProps = ( state ) => ({ restaurants: state.restaurants.list, }),

      allActions = {}

export default connect( mapStateToProps, allActions )( LocalTime )
