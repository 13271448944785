/* VENDOR */
import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { Popover }          from 'antd'
import Icon                 from '@ant-design/icons'


/* APPLICATION */
import { Spinner, Icons, UserAvatar } from 'components'
import { userActions }                from 'services'

import './user-info.scss'
import { format } from 'tools'

class UserInfo extends Component {
    constructor ( props ) {
        super( props )
        this.state = { logout: false, }
    }

    full = () =>
        !this.props.user.name || this.props.user.loading
            ? ''
            : this.props.user.name.split( ' ' )[ 0 ]

    logout = () => {
        this.setState({ logout: true })
        setTimeout(
            () => this.props.logOut(() => ( window.location.href = '/' )),
            100
        )
    }

    render () {
        const { user } = this.props,
              { logout } = this.state,
              popover = (
                  <React.Fragment>
                      <p>{user.name}</p>
                      <a onClick={() => format.update.getLogout( user?.endpoints )} className="logout-button">
                          <Icon component={Icons.LogOut.def} />
            Выйти
                      </a>
                  </React.Fragment>
              ),
              logoutCls = [ 'logout-overlay' ]

        logout && logoutCls.push( 'active' )

        return (
            <Popover
                placement="right"
                content={popover}
                overlayClassName="user-popover"
                className="user-bottom"
            >
                <div className="user-info">
                    <UserAvatar
                        loading={user.loading}
                        name={user.name}
                        userId={user.info.userId}
                    />
                    <span className="user-name">{this.full()}</span>
                </div>
                <div className={logoutCls.join( ' ' )}>
                    <Spinner size="large" />
                </div>
            </Popover>
        )
    }
}

const mapStateToProps = ( state ) => ({ user: state.user, })

export default connect( mapStateToProps, userActions )( UserInfo )
