import React          from 'react'
import { PprTable }   from 'components'
import classNames     from 'classnames'
import { randomName } from './fake'
import { format }     from 'tools'
import './ppr-partner-info.scss'

const randomInt = ( min, max ) => Math.floor( Math.random() * ( max - min ) + min )

const columns = [ 'Позиция', 'П', 'Ф', '%', '+/–', 'Вак.' ]

const prefixes = {
    ac:    'AC',
    empty: '',
    rgm:   'RGM',
    argm:  'ARGM',
    sm:    'SM',
    total: 'Структура менеджеров'
}
const postfixes = [ 'Optimal', 'Fact', 'Relative', 'Delta', 'Vacancie' ]

const data = {}

const fillData = () => {
    Object.keys( prefixes ).filter( key => key !== 'empty' ).forEach(( prefix ) => {
        postfixes.forEach(( postfix ) => {
            data[ `${prefix}Partner${postfix}` ] = randomInt( -10, 40 )
        })
    })
}

fillData()

const AreaData = [ ...Array( randomInt( 15, 60 )) ].map(() => ({
    acSurnameName:             randomName(),
    areaManagersStructureLong: { areaRate: randomInt( 80, 101 ) }
}))

const PprPartnerInfo = () => {
    const isRedColor = ( content, isNumber ) => {
        return ( isNumber && content < 100 ) || ( !isNumber && content < 0 )
    }

    const td = ({ key, content, isNumber = false, isTotal, className = '' }) => <td
        key={key}
        className={classNames(
            content < 0 && !isTotal && 'color-red',
            isRedColor( content, isNumber ) && ( isTotal ? 'bg-color-red' : 'color-red' ),
            className
        )}
    >
        {content}{isNumber && typeof content === 'number' && '%'}
    </td>

    const emptyRow = () => <>
        <td />
        <td />
        <td />
        <td />
        <td />
        <td />
    </>

    return (
        <PprTable
            horizontal
            className='ppr-partner-info'
            showScroll
        >
            <tbody>
                <tr>
                    {columns.map( col => <th key={`partner-area-info-${col}`}>{col}</th> )}
                    <th></th>
                    {format.array.chunk( AreaData, 5 ).map(( _, index ) =>
                        <React.Fragment key={`partner-ac-info-${index}`}>
                            <th rowSpan={2} className='ac-name'>AC</th>
                            <th rowSpan={2}>Струк.</th>
                        </React.Fragment>
                    )}
                </tr>

                {Object.entries( prefixes )
                    .map(([ prefix, colName ], indexPrefix ) => {
                        return <tr key={prefix} className={prefix}>
                            {prefix === 'empty' ?
                                emptyRow()
                                :
                                    [ null, ...postfixes ].map(( postfix ) => {
                                        const field = `${prefix}Partner${postfix}`
                                        const cell = td({
                                            key:       field,
                                            content:   data[ field ],
                                            isNumber:  postfix === 'Relative',
                                            isTotal:   prefix === 'total' && postfix === 'Relative',
                                            className: prefix === 'total' && 'bold'
                                        })

                                        if ( !postfix ) {
                                            return <th key={colName} className='col-name'>{colName}</th>
                                        }

                                        return cell

                                    })
                            }
                            <td></td>
                            {prefix !== 'ac' && format.array.chunk( AreaData, 5 ).map(( chunk, indexChunk ) => {

                                const index = indexPrefix > 2 ? indexPrefix - 1 : indexPrefix
                                const name = chunk[ index ]?.acSurnameName
                                const areaRate = chunk[ index ]?.areaManagersStructureLong?.areaRate

                                return <React.Fragment key={`partner-ac-info-cell-${index}-${indexChunk}`}>
                                    <td className='ac-name'>{name}</td>
                                    <td className={`ac-rate ${areaRate < 100 && 'color-red'}`}>{areaRate ? areaRate + '%' : ''}</td>
                                </React.Fragment>
                            })}
                        </tr>
                    })}
            </tbody>
        </PprTable>
    )
}

export default PprPartnerInfo
