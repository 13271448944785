/* eslint-disable one-var */
import config from 'config'
import React  from 'react'
import './ppr-restaurant-structure.scss'

const columns = {
    restaurantStructureRgmCount:     'RGM',
    restaurantStructureArgmCount:    'ARGM',
    restaurantStructureSmCount:      'SM',
    restaurantStructureTmCount:      'TM',
    restaurantStructureTrainerCount: 'Trainer'
}

const PprRestaurantStructure = ({ managersStructure, restaurant }) => {
    const tr = ( content ) => (
        <tr>
            <th className='no-border'>{content ?? config.ui.nullSymbol}</th>
        </tr>
    )

    return (
        <React.Fragment>
            <th>Структура ресторана</th>
            {Object.keys( columns ).map(( key ) => (
                <th className='restaurant-structure' key={key}>
                    <table style={{ height: 42.5 }}>
                        <tbody>
                            {tr( columns[ key ])}
                            {tr( managersStructure?.[ key ] || restaurant?.[ key ])}
                        </tbody>
                    </table>
                </th>
            ))}
        </React.Fragment>
    )
}

export default PprRestaurantStructure
