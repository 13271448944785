/* VENDOR */
import React               from 'react'
import { Route, Redirect } from 'react-router-dom'

/* APPLICATION */
import { Global }      from 'components'
import { userActions } from 'services'

const logged = userActions.isLoggedIn(),

      PrivateRoute = ({ component: Component, ...rest }) => (
          <Route
              {...rest}
              render={( props ) =>
                  logged ? (
                      <Global>
                          <Component {...props} />
                      </Global>
                  ) : (
                      <Redirect to="/login" />
                  )
              }
          />
      )

export default PrivateRoute
