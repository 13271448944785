import { Button, Tooltip } from 'antd'
import Icon from '@ant-design/icons'
import { Icons }                 from 'components'
import React                     from 'react'
import './report-excel-tooltip.scss';

const ReportExcelTooltip = () => {
    return (
        <Tooltip
            title="Нет данных для построения отчета"
        >
            <Button
                disabled
                type='link'
                className='button-download'
            >
                <Icon component={Icons.Download.active} />
            </Button>
        </Tooltip>
    )
}

export default ReportExcelTooltip
