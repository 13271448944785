/* IMPORT */

import { categoriesActions } from 'services'

/* EXPORT */

export const mapStateToProps = ( state ) => ({
    request:  state.request,
    list:     state.categories.list,
    category: state.categories.category,
    dishes:   state.categories.dishes,
})

export const allActions = { ...categoriesActions, }
