/* VENDOR */
import React, { Component }     from 'react'
import PropTypes                from 'prop-types'
import { Scrollbars }           from 'react-custom-scrollbars'
import { List, Row, Col } from 'antd'
import Icon from '@ant-design/icons'


/* APPLICATION */
import { AddButton, Spinner, Icons } from 'components'

import config from 'config'

import './dishes-list.scss'

class DishList extends Component {
    static propTypes = {
        data:     PropTypes.array,
        onAdd:    PropTypes.func,
        onRemove: PropTypes.func,
    }

    remove = ( dish ) => {
        return () => this.props.onRemove( dish )
    }

    item = ( item ) => {
        const cls = []

        return (
            <List.Item className={cls.join( ' ' ).trim()}>
                <Row>
                    <Col span={2}>{item.rkeeperCode}</Col>
                    <Col span={10}>
                        <span className="item-title">{item.name}</span>
                        {item.combo && <span className="combo-tag">КОМБО</span>}
                    </Col>
                    <Col span={10}>
                        <span>{item.alternateProductName}</span>
                    </Col>
                    <Col span={1} style={{ textAlign: 'right' }}>
                        <Icon
                            className="remove-icon"
                            component={Icons.Remove.active}
                            onClick={this.remove( item )}
                        />
                    </Col>
                </Row>
            </List.Item>
        )
    }

    render () {
        const { data } = this.props

        return (
            <div className="dishes-list">
                {data ? (
                    <Scrollbars
                        {...config.ui.scrolls}
                        ref={( node ) => ( this.scrolls = node )}
                    >
                        <List
                            size="small"
                            dataSource={data}
                            renderItem={this.item}
                            locale={{ emptyText: 'Ничего не найдено' }}
                        />
                    </Scrollbars>
                ) : (
                    <Spinner />
                )}

                <div className="list-footer">
                    <AddButton text="Добавить блюдо" action={this.props.onAdd} />
                </div>
            </div>
        )
    }
}

export default DishList
