/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'

/* APPLICATION */
import { ManagerReport, Spinner } from 'components'

import './tasks-overview.scss'

class TasksOverview extends Component {
    static propTypes = {
        data: PropTypes.object,

        executors: PropTypes.object,
        assignees: PropTypes.object,

        onChange:  PropTypes.func,
        onDetails: PropTypes.func,
    }

    render () {
        const { data, onDetails } = this.props,
              { executors, assignees } = this.props

        return (
            <div className="tasks-report">
                {data ? (
                    <ManagerReport
                        data={data}
                        executors={executors}
                        assignees={assignees}
                        onDetails={onDetails}
                    />
                ) : (
                    <Spinner />
                )}
            </div>
        )
    }
}

export default TasksOverview
