/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import Carousel             from 'nuka-carousel'

/* APPLICATION */
import { Center } from 'components'

import './photos.scss'

class Photos extends Component {
    static propTypes = {
        useDots: PropTypes.bool,
        data:    PropTypes.array,
        slide:   PropTypes.func,
    }

    slide = ( item, key ) => (
        <Center key={key}>{this.props.slide( item, key )}</Center>
    )

    render () {
        const { data, useDots } = this.props,
              config = useDots
                  ? {
                          renderCenterLeftControls:  () => null,
                          renderCenterRightControls: () => null,
                      }
                  : {
                          renderCenterLeftControls: ({ previousSlide, currentSlide }) =>
                              currentSlide > 0 ? (
                                  <button
                                      className="photos-btn photos-btn-prev"
                                      onClick={previousSlide}
                                  >
                                      <span>&lsaquo;</span>
                                  </button>
                              ) : null,
                          renderCenterRightControls: ({
                              nextSlide,
                              currentSlide,
                              slideCount,
                          }) =>
                              currentSlide < slideCount - 1 ? (
                                  <button
                                      className="photos-btn photos-btn-next"
                                      onClick={nextSlide}
                                  >
                                      <span>&rsaquo;</span>
                                  </button>
                              ) : null,
                          renderBottomCenterControls: () => null,
                      }

        return (
            <Carousel {...config} disableEdgeSwiping>
                {data.map( this.slide )}
            </Carousel>
        )
    }
}

export default Photos
