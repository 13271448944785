import * as extract from './extract'
import * as cells   from './cells'

/* SIMPLE */

export const compare = ( fact, plan ) => ( fact >= plan ? 'good' : 'bad' )

/* FUNCTIONS */

export const standard = ( record, key, columns ) => {
    if ( record[ key ] < 0 ) {
        return cells.bad( record, key )
    }

    if ( record[ key ] === extract.max( columns[ key ])) {
        return cells.good( record, key )
    }

    return record
}

/*
                       _..----------.._
                  .-=""        _       ""=-.
               .-"    _.--""j _\""""--._    "-.
            .-"  .-i   \   / / \;       ""--.  "-.
          .'  .-"  : ( "  : :                " -.  `.
        .'  .'      `.`.   \ \                  `.  `.
       /  .'      .---" ""--`."-./'---.           `.  \
      /  /      .'                    '-.           \  \
     /  /      /                         `.          \  \
    /  /      /                  ,--._   (            \  \
   ,  /    '-')                  `---'    `.           \  .
  .  :      .'                              "-._.-.     ;  ,
  ;  ;     /            :;         ,-"-.    ,--.   )    :  :
 :  :     :             ::        :_    "-. '-'   `,     ;  ;
 |  |     :              \\     .--."-.    `._ _   ;     |  |
 ;  ;     :              / "---"    "-."-.    l.`./      :  :
:  :      ;             :              `. "-._; \         ;  ;
;  ;      ;             ;                `..___/\\        :  :
;  ;      ;             :                        \\    _  :  :
:  :     /              '.                        ;;.__)) ;  ;
 ;  ; .-'                 "-...______...--._      ::`--' :  :
 |  |  `--'\                                "-.    \`._, |  |
 :  :       \                                  `.   "-"  ;  ;
  ;  ;       `.                                  \      :   '
  '  :        ;                                   ;     ;  '
   '  \    _  : :`.                               :    /  /
    \  \   \`-' ; ; ._                             ;  /  /
     \  \   `--'  : ; "-.                          : /  /
      \  \        ;/     \                         ;/  /
       \  `.              ;                        '  /
        `.  "-.          /                          .'
          `.   "-..__..-"                         .'
            "-.                                .-"
               "-._                        _.-"
                   """---...______...---"""
*/
export const subZero = ( record, key ) => {
    if ( record[ key ] < 0 ) {
        return cells.bad( record, key )
    }

    return record
}

export const goodBad = ( record, key, columns, inverse ) => {
    if ( record[ key ] < 0 ) {
        return inverse ? cells.good( record, key ) : cells.bad( record, key )
    }

    if ( record[ key ] > 0 ) {
        return inverse ? cells.bad( record, key ) : cells.good( record, key )
    }

    return record
}

export const bad = ( record, key, columns, inverse, excludeEmpty ) => {
    const rel = inverse
        ? record[ key ] === extract.max( columns[ key ], excludeEmpty )
        : record[ key ] === extract.min( columns[ key ], excludeEmpty )

    if ( rel ) {
        return cells.bad( record, key )
    }

    return record
}

export const minMax = ( record, key, columns ) => {
    const min = extract.min( columns[ key ]),
          max = extract.max( columns[ key ])

    if ( record[ key ] === min ) {
        return cells.bad( record, key )
    }

    if ( record[ key ] === max ) {
        return cells.good( record, key )
    }

    return record
}

export const thanValue = ( record, key, value, less ) => {
    const rel = less ? record[ key ] > value[ key ] : record[ key ] < value[ key ]

    if ( rel ) {
        return cells.bad( record, key )
    }

    return record
}

/* ITERATORS */

export const iterator = (
    keys,
    handler,
    record,
    columns,
    inverse,
    excludeEmpty
) =>
    keys.reduce(
        ( current, key ) => handler( current, key, columns, inverse, excludeEmpty ),
        record
    )

export const iterate = {
    minmax:  ( record, keys, columns ) => iterator( keys, minMax, record, columns ),
    find:    ( record, keys, columns ) => iterator( keys, standard, record, columns ),
    goodBad: ( record, keys, columns, inverse ) =>
        iterator( keys, goodBad, record, columns, inverse ),
    subZero: ( record, keys, columns ) => iterator( keys, subZero, record, columns ),
    bad:     ( record, keys, columns, inverse, excludeEmpty ) =>
        iterator( keys, bad, record, columns, inverse, excludeEmpty ),
    thanValue: ( record, keys, value, less ) =>
        iterator( keys, thanValue, record, value, less ),
}
