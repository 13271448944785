/* VENDOR */
import { update } from 'reduxigen'

/* APPLICATION */
import * as api   from './api'
import { format } from 'tools'

const asyncUpdate = format.update.asyncu,
      helper = format.helper.basic( 'managers' ),
      thelper = format.helper.basic( 'tasks' )

/* REPORTS */

export const flushTasksReport = update(
    'managers.flushTasks',
    ( value, state ) => ({
        ...state,
        managers: {
            ...state.managers,
            report: null,
        },
    })
)

export const flushFullReport = update(
    'managers.flush.fullReport',
    ( v, state ) => ({
        ...state,
        managers: {
            ...state.managers,
            fullReport: null,
        },
    })
)

export const fetchFullReport = asyncUpdate(
    'managers.fullReport',
    ( params ) => api.getFullReport( params ),
    ( event, state ) => helper( 'fullReport', event.data, state, event.status )
)

export const fetchTasksReport = asyncUpdate(
    'managers.report',
    ( params ) => api.getTasksReport( params ),
    ( event, state ) => helper( 'report', event.data, state, event.status )
)

export const fetchTasksSummary = asyncUpdate(
    'managers.summary',
    ( params ) => api.getTasksSummary( params ),
    ( event, state ) => helper( 'summary', event.data, state, event.status )
)

export const fetchShiftObjectives = asyncUpdate(
    'managers.objectives',
    ( params ) => api.getObjectives( params ),
    ( event, state ) => helper( 'objectives', event.data, state, event.status )
)

export const fetchConsolidatedPeriod = asyncUpdate(
    'managers.consolidated.period',
    ( params ) => api.getConsolidatedPeriod( params ),
    ( event, state ) => helper( 'period', event.data, state, event.status )
)

export const flushConsolidatedPeriod = update(
    'managers.consolidated.period.flush',
    ( n, state ) => helper( 'period', null, state )
)

export const fetchConsolidatedDate = asyncUpdate(
    'managers.consolidated.date',
    ( params ) => api.getConsolidatedDate( params ),
    ( event, state ) => helper( 'date', event.data, state, event.status )
)

export const flushConsolidatedDate = update(
    'managers.consolidated.date.flush',
    ( n, state ) => helper( 'date', null, state )
)

export const flushConsolidatedManager = update(
    'managers.consolidated.manager.flush',
    ( n, state ) => helper( 'manager', null, state )
)

export const fetchManagerTasks = asyncUpdate(
    'managers.consolidated.period.manager',
    ( params ) => api.getManagerTasks( params ),
    ( event, state ) => helper( 'manager', event.data, state, event.status )
)

export const fetchManagerShift = asyncUpdate(
    'managers.consolidated.date.manager',
    ( params ) => api.getManagerShift( params ),
    ( event, state ) => helper( 'manager', event.data, state, event.status )
)

/* TASKS */

export const flushNewTask = update( 'managers.flush.new', ( value, state ) => ({
    ...state,
    managers: {
        ...state.managers,
        task:       null,
        createTask: null,
    },
}))

export const createTask = asyncUpdate(
    'managers.task.create',
    ( params ) => api.createTask( params ),
    ( event, state ) => helper( 'task', event.data, state, event.status )
)

export const updateTask = asyncUpdate(
    'managers.task.update',
    ( params ) => api.updateTask( params ),
    ( event, state ) => ({
        ...state,
        managers: {
            ...state.managers,
            manager: format.copy.deep( state.managers.manager ),
        },
    })
)

export const removeTask = asyncUpdate(
    'managers.task.remove',
    ( id ) => api.removeTask( id ),
    ( event, state ) => ({
        ...state,
        managers: {
            ...state.managers,
            manager: format.copy.deep( state.managers.manager ),
        },
    })
)

export const flushTask = update( 'managers.flush.task', ( value, state ) => ({
    ...state,
    managers: {
        ...state.managers,
        details: null,
    },
}))

export const fetchTask = asyncUpdate(
    'managers.details',
    ( id ) => api.getTask( id ),
    ( event, state ) => helper( 'details', event.data, state )
)

export const flushDeviations = update(
    'managers.flush.deviations',
    ( value, state ) => ({
        ...state,
        managers: {
            ...state.managers,
            deviations: null,
        },
    })
)

export const fetchTaskDeviations = asyncUpdate(
    'managers.deviations',
    ( id ) => api.getTaskDeviations( id ),
    ( event, state ) => helper( 'deviations', event.data.data, state )
)

/* ADMIN */

export const flushAdminTasks = update(
    'managers.flush.tasks',
    ( value, state ) => ({
        ...state,
        tasks: {
            ...state.tasks,
            list: null,
        },
    })
)

export const fetchAdminTasks = asyncUpdate(
    'managers.admin.tasks',
    ( params ) => api.getAdminTasks( params ),
    ( event, state ) => thelper( 'list', event.data, state )
)

export const updateAdminTask = asyncUpdate(
    'managers.admin.tasks.update',
    ( body ) => api.updateAdminTask( body ),
    ( event, state ) => {
        const list = format.copy.array( state.tasks.list.content ),
              found = state.tasks.list.content.find(( t ) => t.id === event.data.id ),
              index = state.tasks.list.content.indexOf( found )

        if ( index > -1 ) {
            list[ index ] = event.data
        }

        return {
            ...state,
            tasks: {
                ...state.tasks,
                list: {
                    ...state.tasks.list,
                    content: list,
                },
            },
        }
    }
)

export const fetchGroupsList = asyncUpdate(
    'managers.admin.tasks.groups',
    ( search ) => api.getGroupsList( search ),
    ( event, state ) => thelper( 'groups', event.data, state )
)
