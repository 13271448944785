import React from 'react';
import {connect} from 'react-redux';
import {Dropdown} from 'antd';
import {DownOutlined} from '@ant-design/icons';
import config from 'config';
import './dategroup-dropdown.scss';
import {allActions, DateDropDropDownStoreProps, mapStateToProps} from './connector';

const items = {
    hour: 'часам',
    day: 'дням',
    week: 'неделям',
    month: 'месяцам',
};

type DropDownKeys = keyof typeof items;
type DateGroupDropDownProps = {
    max: string,
    min: string
}

const DateGroupDropDown = ({max, min, request, ui, setGroup}: DateGroupDropDownProps & DateDropDropDownStoreProps ) => {
    const updateGroup = (key: DropDownKeys) => () => {
        setGroup(key);

        // @ts-ignore
        const datesInStorage = JSON.parse(sessionStorage.getItem('filtersData'));
        datesInStorage.group = key;
        sessionStorage.setItem('filtersData', JSON.stringify(datesInStorage));
    };

    const menu = () => {
        const all = Object.keys(items);
        const allFiltered = all.slice(all.indexOf(min), all.indexOf(max) + 1);
        const menuItems = allFiltered.map((key) => {
            return {key, label: items[ key as DropDownKeys ]};
        });

        return {
            items: menuItems,
            onSelect: ({key}: {key: string}) => {
                updateGroup(key as DropDownKeys)();
            },
            selectable: true,
            defaultSelectedKeys: [ request.dateGroup ],
        };
    };

    const text = ui.width < config.ui.breakpoint ? 'по' : 'Детализация по';
    const menuConfig = menu();

    if (!max || menuConfig.items.length < 2) {
        return null;
    }

    return (
        <Dropdown
            menu={menuConfig}
            trigger={[ 'click' ]}
            getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}>
            <span className="detail-dropdown">
                {text}
                <button className="ant-dropdown-link">
                    {/*@ts-ignore*/}
                    {items[ request.dateGroup ]} <DownOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                </button>
            </span>
        </Dropdown>
    );
};

export default connect(mapStateToProps, allActions)(DateGroupDropDown);
