/* eslint-disable react/prop-types */
import React      from 'react'
import classNames from 'classnames'
import {
    PprRestaurantStructure,
    PprTable
} from 'components'
import './ppr-manager-data.scss'
import config from 'config'

const positions = [ 'RGM', 'ARGM', 'SM', 'Trainer' ]

const Badge = ({ children, type }) => {
    return <div className={classNames([ 'badge', type ])}>{children}</div>
}

const PprManagerData = ( props ) => {
    const bench = props.data.filter( item => item.isManagerBench ),
          noBench = props.data.filter( item => !item.isManagerBench )

    const sortByPosition = ( position, array ) => array.filter(
        item => item.position?.code?.toLowerCase() === position.toLowerCase()
    )

    const sortByPositions = ( array ) => {
        let result = []
        positions.forEach( pos => {
            result = [ ...result, ...sortByPosition( pos, array ) ]
        })
        return result
    }

    const managerRow = ( item, index ) => <React.Fragment key={`manager_row_${item.managerSurnameName}_${index}`}>
        <tr className='manager-row one'>
            <th>{item.position?.name ?? config.ui.nullSymbol}</th>
            <td colSpan={5} className='bold'>{item.managerSurnameName}</td>
            <td></td>
            <td>{item.rclCode?.name ?? config.ui.nullSymbol}</td>
            <td></td>
            <td></td>
        </tr>
        <tr className='manager-row two'>
            <th></th>
            <td colSpan={5}></td>
            <td><Badge type='date'>{item.positionStartDate}</Badge></td>
            <td></td>
            <td>{item.managerLearnings}</td>
            <td>{item.managerPlan}</td>
        </tr>
        <tr className='manager-row three'>
            <th>Статус</th>
            <td colSpan={5}>{item.positionStatus?.name ?? config.ui.nullSymbol}</td>
            <td></td>
            <td>{item.managerRclDate}</td>
            <td></td>
            <td></td>
        </tr>
    </React.Fragment>

    const emptyRow = () => <tr>
        <td colSpan={10}></td>
    </tr>

    const emptyData = () => <tr rowSpan={5} style={{ height: 100 }}>
        <td colSpan={10}>Нет данных</td>
    </tr>

    const managerBench = ( dataBench ) => <React.Fragment>
        {dataBench.map(( item, index ) => {
            return <React.Fragment key={`manager_bench_${index}`}>
                <tr className='manager-row one'>
                    {index === 0 && <th rowSpan={3 * dataBench.length}>BENCH Менеджеров</th>}
                    {index > 0 && <th className='hide'></th>}
                    <td colSpan={5} className='bold'>{item.managerSurnameName}</td>
                    <td></td>
                    <td>{item.rclCode?.name ?? config.ui.nullSymbol}</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr className='manager-row two'>
                    <th className='hide'></th>
                    <td colSpan={5}></td>
                    <td><Badge type='date'>{item.positionStartDate}</Badge></td>
                    <td></td>
                    <td>{item.managerLearnings}</td>
                    <td>{item.managerPlan}</td>
                </tr>
                <tr className='manager-row three'>
                    <th className='hide'></th>
                    <td colSpan={5}>{item.position?.name ?? config.ui.nullSymbol}</td>
                    <td></td>
                    <td>{item.managerRclDate}</td>
                    <td></td>
                    <td></td>
                </tr>
            </React.Fragment>
        })}
    </React.Fragment>

    return (
        <PprTable
            loading={props.loading}
            error={props.error}
            onRetry={() => props.onRetry( 'managers' )}
            customStyle
            className='ppr-manager-data'
        >
            <thead>
                <tr>
                    <PprRestaurantStructure
                        managersStructure={props.managersStructure}
                        restaurant={props.restaurant}
                    />
                    <th>Старт в должности</th>
                    <th>Код/Дата последнего RCL</th>
                    <th>Пройденные обучения</th>
                    <th>План действий</th>
                </tr>
            </thead>
            <tbody>
                {bench.length === 0 && noBench.length === 0 && emptyData()}
                {sortByPositions( noBench ).map( managerRow )}
                {noBench.length > 0 && emptyRow()}
                {managerBench( sortByPositions( bench ))}
                {bench.length > 0 && emptyRow()}
            </tbody>
        </PprTable>
    )
}

export default PprManagerData
