/* VENDOR */
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Layout, Card, Button, Tabs} from 'antd'
import Icon from '@ant-design/icons'
import moment from 'moment'
import ReactExport from 'react-data-export'

/* APPLICATION */
import {
    AppHeader,
    InnerContent,
    BackTitle,
    InnerControls,
    ReportTable,
    Spinner,
    ReportExcelTooltip,
    Icons,
    CustomTab,
} from 'components'

import {format} from 'tools'
import config from 'config'

import {allActions, mapStateToProps} from './connector'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet

const {Content, Header} = Layout,
      prepareKeys = {
          fusion: [ 'order', 'payment', 'handling', 'assembly', 'giveaway' ],
          hybrid: [
              'handlingKiosk',
              'assemblyKiosk',
              'giveawayKiosk',
              'handling',
              'payment',
          ],
          auto: [ 'total', 'order', 'payment', 'handling' ],
      }

class TimeReport extends Component {
    constructor (props) {
        super(props)
        this.state = {
            tabs: {},
            columns: [],
            data: null,
            plan: null,
        }
    }

    componentDidMount () {
        const {request, workHours, fetchWorkHours} = this.props

        if (!workHours) {
            fetchWorkHours(request)
        } else {
            request.serviceType && this.load(this.props)
            this.tabs(this.props)
        }
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps (nextProps) {
        const {request, restaurants, time, workHours} = this.props

        time !== nextProps.time && this.setData(nextProps)
        restaurants !== nextProps.restaurants && this.tabs(nextProps)

        if (request.restaurantId !== nextProps.request.restaurantId) {
            nextProps.fetchWorkHours(nextProps.request)
            this.tabs(nextProps)
        }

        if (nextProps.workHours !== workHours) {
            this.load(nextProps)
        }

        if (format.check.request(request, nextProps.request, [], [ 'serviceType' ])) {
            this.load(nextProps)
        }
    }

    setData = (props) =>
        this.setState({
            data: props.time ? format.generate.noPager(props.time.data) : null,
            plan: props.time ? props.time.plan : null,
            columns: this.columns(
                props.request.serviceType,
                props.time ? props.time.plan : []
            ),
        })

    setService = (tab) => {
        this.props.setServiceType(tab)

        this.setState({columns: this.columns(tab)})
    }

    columns = (tab, splan) => {
        const plan = splan || this.state.plan,
              res = format.copy.array(config.tables.serviceTime[ tab ](this.props.request))

        res.forEach((col) => {
            if (col.render_as === 'with_time') {
                col._title = col._title || col.title
                col.title = (
                    <React.Fragment>
                        {col._title}
                        <span className="timeHead">
                            {format.strings.seconds(plan ? plan[ col.dataIndex ] : null)}
                        </span>
                    </React.Fragment>
                )
            }
        })

        return res
    }

    tabs = (props) => {
        const {restaurants, request} = props

        if (!restaurants) {
            return
        }

        const rest = restaurants.find(
            (r) => r.factsNumber === parseInt(request.restaurantId)
        )

        if (!rest) {
            return
        }

        const available = Object.keys(rest.params).filter(
            (key) => rest.params[ key ]
        ),
              tabs = {}

        available.forEach((key) => (tabs[ key ] = config.tabs.time[ key ]))

        this.setState({tabs})
        this.setService(available[ 0 ])
    }

    load = (props) => {
        if (!format.check.enough(props)) {
            return
        }

        props.flushServiceTime()
        props.fetchServiceTime(props.request)
    }

    update = () => {
        const {serviceType} = this.props.request

        if (!serviceType) {
            return
        }
        this.load(this.props)
    }

    prepare = (record) => {
        return format.goodBad.iterate.thanValue(
            record,
            prepareKeys[ this.props.request.serviceType ],
            this.state.plan,
            true
        )
    }

    set = (data) => this.props.setRequest(data)

    prepareExcel = () => {
        const {columns, data, plan} = this.state
        const font = {name: 'Arial', sz: '10', bold: false}
        const getValue = (value, col) => {
            if (!col.title && !col._title) {
                return value ? `${value}%` : '0%'
            }
            if (col._title) {
                return format.strings.seconds(value)
            }
            return value ?? '-'
        }
        const getTitle = (col) => {
            if (col._title) {
                return `${col._title} ${format.strings.seconds(plan ? plan[ col.dataIndex ] : null)}`
            } else {
                return col.title
            }
        }

        if (!data && !data.content) {
            return []
        }
        return [
            {
                columns: columns.map((col) => ({title: getTitle(col), style: {font: {...font, bold: true}}})),
                data: data.content.map(item => columns.map((col) =>
                    ({value: getValue(item[ col.dataIndex ], col), style: {font}})
                ))
            }
        ]
    }

    showExcelTooltip = () => {
        const {data, columns} = this.state
        const fields = columns.filter(col => col.dataIndex !== 'label').map(col => col.dataIndex)

        if (!data && !data?.content) {
            return true
        }
        return format.check.allContainsNullOrZero(data.content, fields)
    }

    render () {
        const {request, workHours} = this.props,
              {tabs, data, columns} = this.state

        const disabledExcel = this.showExcelTooltip()

        const tabsContent = Object.keys(tabs)
            .filter((key) => key[ 0 ] !== '_' && tabs[ key ]).map(key => {
                return {
                    key: key,
                    label: (<CustomTab
                        key={key}
                        title={tabs[ key ]}
                        icon={
                            disabledExcel ?
                                    <ReportExcelTooltip/>
                                :
                                    <ExcelFile
                                        filename={`Время обслуживания ${tabs[ key ]}_${moment().format('YYYY-MM-DDTHH:mm:ss')}`}
                                        element={
                                            <Button
                                                type='link'
                                                className='button-download'
                                            >
                                                <Icon component={Icons.Download.def}/>
                                            </Button>
                                        }
                                    >
                                        <ExcelSheet dataSet={this.prepareExcel(tabs[ key ])} name={`Время обслуживания ${tabs[ key ]}`}/>
                                    </ExcelFile>
                        }
                    />)
                }
            })

        return (
            <section className="kfc-report kfc-time-report">
                <Layout>
                    <Header>
                        <AppHeader bothTimes timeData={parseInt(request.restaurantId)}/>
                    </Header>
                    <Content>
                        <InnerControls
                            actions={format.extract.actions(this.props)}
                            request={request}
                            workHours={workHours}
                            update={this.update}
                            onChange={this.set}
                        />
                        <InnerContent>
                            <BackTitle
                                text="Время обслуживания"
                            />

                            <Card bordered={false} className="report-table">
                                {!request.serviceType ? (
                                    <Spinner/>
                                ) : (
                                    <React.Fragment>
                                        <div className='view-type-switch'>
                                            <Tabs
                                                activeKey={request.serviceType}
                                                onChange={this.setService}
                                                animated={false}
                                                items={tabsContent}
                                            ></Tabs>
                                        </div>

                                        <div className="report-wrap">
                                            <ReportTable
                                                data={data}
                                                columns={columns}
                                                rowKey={(rec, i) => rec.label + '-' + i}
                                                prepare={this.prepare}
                                            />
                                        </div>
                                    </React.Fragment>
                                )}
                            </Card>
                        </InnerContent>
                    </Content>
                </Layout>
            </section>
        )
    }
}

export default connect(mapStateToProps, allActions)(TimeReport)
