import cn from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Select } from 'antd';

import './guest-vote-filters.scss';
import {
    FiltersConfig,
    FiltersConfigGroup,
    FiltersKeys,
    GuestVoteFiltersObject,
    GuestVoteSearchFilterType
} from './type';
import { guestVoteFiltersConfig } from './config';
import { allActions, GuestVoteFiltersStoreProps, mapStateToProps } from './connector';
import { connect } from 'react-redux';
import { FormatedDateFilters } from 'pages/GuestVote/types';

type GuestVoteFiltersProps = {
    className?: string;
    onChange?: (filters: GuestVoteFiltersObject) => void;
    defaultDisabledFilters?: FiltersKeys[];
    dateFilters: FormatedDateFilters
}

const GuestVoteFilters = (props: GuestVoteFiltersProps & GuestVoteFiltersStoreProps) => {
    const {
        className,
        defaultDisabledFilters,
        onChange,
        setCurrentFilters,
        currentFilters,
        deselectCurrentFilters,
        clearCurrentFilters,
        flushCurrentFilters,
        postFeedbackFiltersSearch,
        categoriesFiltersOptions,
        categoriesFiltersOptionsLoading,
        flushFilterOptions,
        lastSelectedFilter,
        setLastSelectedFilter,
        dateFilters
    } = props;

    const [ disabledFilters, setDisabledFilters ] = useState<FiltersKeys[] | undefined>(defaultDisabledFilters)

    const options = useMemo(() => {
        if (categoriesFiltersOptions.searchFilterOptions) {
            return categoriesFiltersOptions.searchFilterOptions.map(item => {
                return {
                    label: item.name,
                    value: item.id || item.name
                }
            })
        }
        return []
    }, [ categoriesFiltersOptions.searchFilterOptions ])

    useEffect(() => {
        if (options.length === 0 && !categoriesFiltersOptionsLoading) {
            const lastFilterKey = guestVoteFiltersConfig.find(item => item.searchFilterType === categoriesFiltersOptions?.searchFilter)?.key
            if (lastFilterKey) {
                disabledFilters ? setDisabledFilters([ ...disabledFilters, lastFilterKey ]) : setDisabledFilters([ lastFilterKey ])
            }
        }
    }, [ options ]);

    useEffect(() => {
        onChange?.(currentFilters)
        setDisabledFilters(defaultDisabledFilters)
    }, [ currentFilters ])

    useEffect(() => {
        if (disabledFilters?.length) {
            disabledFilters.forEach(name => {
                const group = guestVoteFiltersConfig.find(item => item.key === name)?.group
                clearCurrentFilters({
                    key: name,
                    group
                })
            })
        }
        setDisabledFilters(defaultDisabledFilters)
    }, []);

    const handleSelect = (value: string | number, key: FiltersKeys, group: FiltersConfigGroup, filterType: GuestVoteSearchFilterType) => {
        setCurrentFilters({
            key,
            value,
            group
        })
        if (lastSelectedFilter?.searchFilter === filterType) {
            return
        }
        setLastSelectedFilter(categoriesFiltersOptions)
    }

    const handleDeselect = (value: string | number, key: FiltersKeys, group: FiltersConfigGroup, filterType: GuestVoteSearchFilterType) => {
        deselectCurrentFilters({
            key,
            value,
            group
        })
        if (filterType !== lastSelectedFilter?.searchFilter) {
            setLastSelectedFilter(undefined)
        }
    }

    const handleClearFilter = (key: FiltersKeys, group: FiltersConfigGroup, filterType: GuestVoteSearchFilterType) => {
        clearCurrentFilters({
            key,
            group
        })
        if (filterType !== lastSelectedFilter?.searchFilter) {
            setLastSelectedFilter(undefined)
        }

    }

    const handleOpen = (item: FiltersConfig) => {
        if (lastSelectedFilter?.searchFilter === item.searchFilterType) {
            return
        }
        flushFilterOptions()
        postFeedbackFiltersSearch({
            ...dateFilters,
            searchFilter: item.searchFilterType,
            ...currentFilters
        })
    }

    const sorter = (optionA: { value: number | string }, optionB: { value: number | string }, item: FiltersConfig) => {
        // @ts-ignore
        const currentActiveFilters = item.group ? currentFilters[ item.group ]?.[ item.key ] : currentFilters[ item.key ]
        if (currentActiveFilters) {
            if (currentActiveFilters.includes(optionA.value)) {
                return -1
            }
            if (currentActiveFilters.includes(optionB.value)) {
                return 1
            }
        }
        return 0
    }

    const isButtonVisible = (currentFilters.restaurantFilters && Object.keys(currentFilters.restaurantFilters).length > 0) ||
        (currentFilters.feedbackFilters && Object.keys(currentFilters.feedbackFilters).length > 0) ||
        (currentFilters.saleChannelIds && currentFilters.saleChannelIds.length > 0)

    const savedOptions = lastSelectedFilter?.searchFilterOptions?.map(item => {
        return {
            label: item.name,
            value: item.id || item.name
        }
    })

    return (
        <div
            className={cn(className, 'guest-vote-filters')}
        >
            {guestVoteFiltersConfig.map((item) => (
                <Select
                    key={item.key}
                    className="gust-vote-filter"
                    mode="multiple"
                    allowClear
                    options={lastSelectedFilter?.searchFilter === item.searchFilterType
                        ? savedOptions
                        : categoriesFiltersOptions.searchFilter === item.searchFilterType ? options : []}
                    loading={categoriesFiltersOptionsLoading}
                    getPopupContainer={( trigger ) => trigger.parentNode}
                    // @ts-ignore
                    value={item.group ? currentFilters[ item.group ]?.[ item.key ] || [] : currentFilters[ item.key ] || []}
                    onSelect={(value) => handleSelect(value, item.key, item.group, item.searchFilterType)}
                    onDeselect={(value) => handleDeselect(value, item.key, item.group, item.searchFilterType)}
                    onClear={() => handleClearFilter(item.key, item.group, item.searchFilterType)}
                    placeholder={item.title}
                    filterOption={(input, option) => {
                        const value = input.toLowerCase();
                        const label = option?.label?.toLowerCase() || '';
                        return label.includes(value);
                    }}
                    onDropdownVisibleChange={(open) => {
                        if (open) {
                            handleOpen(item)
                        }
                    }}
                    filterSort={(optionA, optionB) => sorter(optionA, optionB, item)}
                    disabled={disabledFilters?.includes(item.key)}
                    maxTagCount={1}
                />
            ))}
            {isButtonVisible &&
                <Button
                    type='primary'
                    onClick={() => flushCurrentFilters()}
                    className="gust-vote-filter"
                >
                    Сбросить фильтры
                </Button>}
        </div>
    );
};

export default connect(mapStateToProps, allActions)(GuestVoteFilters);

