/* VENDOR */
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Draggable} from '@fullcalendar/interaction'
import {Scrollbars} from 'react-custom-scrollbars'
import {Input} from 'antd'
import {CloseOutlined} from '@ant-design/icons'
import moment from 'moment'

/* APPLICATION */
import {AddButton, CalendarSummary, MultiSelectDropdown, Spinner} from 'components'

import {format} from 'tools'
import config from 'config'

import './calendar-events-list.scss'
import * as helpers from './helpers'

const { Search } = Input,
      listID = 'calendar-events-list'

class CalendarEventsList extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        loading:  PropTypes.bool,

        view:     PropTypes.string,
        date:     PropTypes.object,
        selected: PropTypes.object,

        inbox:   PropTypes.array,
        summary: PropTypes.object,
        restaurants: PropTypes.object,
        factsNumbers: PropTypes.array,

        onDrag:   PropTypes.func,
        onReturn: PropTypes.func,
        onFilter: PropTypes.func,
    }

    /* REACT */

    constructor ( props ) {
        super( props )

        this.state = {
            groups:   [],
            filtered: [],
            search:   '',
            restaurants: null,
        }

        this.set = format.generate.set( this )
    }

    componentDidMount () {
        const { inbox, restaurants } = this.props

        inbox && this.setGroups( inbox )

        if (restaurants) {
            this.setRestaurants(restaurants);
        }

        this.initDrag()
    }

    componentDidUpdate ( prevProps ) {
        const { inbox, selected, view, date, restaurants } = this.props;

        ( selected !== prevProps.selected ||
      inbox !== prevProps.inbox ||
      view !== prevProps.view ||
      date !== prevProps.date ) &&
      this.setGroups()

        if ( restaurants !== prevProps.restaurants ) {
            this.setRestaurants( restaurants )
        }
    }

    /* DATA */

    setGroups = () => {
        const { inbox, selected, view, date } = this.props,
              { search } = this.state,
              groups = this.makeGroups( inbox, selected ),
              filtered = groups.map(
                  helpers.filter.groups( search, selected, date, view )
              )

        this.set.state({
            groups,
            filtered,
        })
    }

    updateFiltered = () => {
        const { selected, date, view } = this.props,
              { groups, search } = this.state

        this.set.filtered(
            groups.map( helpers.filter.groups( search, selected, date, view ))
        )
    }

    makeGroups = ( tasks, selected ) => {
        if ( !tasks ) { return [] }

        const groups = [],
              indexes = {}

        selected
            ? tasks
                .filter( this.isSelected( selected ))
                .forEach( this.getSelection( groups, indexes ))
            : tasks.forEach( this.assignEvent( groups, indexes ))

        groups.forEach(( group ) => {
            Object.keys( group.named ).forEach( this.sortGroupEvents( group, !selected ))
            delete group.named
        })

        return groups.sort( this.sortGroups )
    }

    isSelected = ( date ) => ( event ) =>
        moment( event.startedDate ).isSame( moment( date ), 'day' )

    getSelection = ( groups, indexes ) => ( event ) => {
        const time = moment( event.startedDate ),
              title = event.duration
                  ? event.startedDate + helpers.separator + event.duration
                  : event.startedDate,
              found = indexes[ title ],
              group = groups[ found ] || helpers.timeGroup( title )

        if ( found === void 0 ) {
            indexes[ title ] = groups.length
            groups.push( group )
        }

        !group.named[ time ] && ( group.named[ time ] = [])

        group.named[ time ].push(
            helpers.prepareEvent( event, this.props.helpers.get.classNames( event ))
        )
    }

    assignEvent = ( groups, indexes ) => ( event ) => {
        const found = indexes[ event.groupName ],
              group = groups[ found ] || helpers.defaultGroup( event )

        if ( found === void 0 ) {
            indexes[ event.groupName ] = groups.length
            groups.push( group )
        }

        !group.named[ event.title ] && ( group.named[ event.title ] = [])

        group.named[ event.title ].push( helpers.prepareEvent( event ))
    }

    sortGroupEvents = ( group, pack ) => ( title ) => {
        const { view, date } = this.props,
              sorted = group.named[ title ].sort( helpers.sortEvents( view, date )),
              event = helpers.packEvents( sorted )

        pack
            ? group.items.push( event )
            : sorted.forEach(( e ) => group.items.push( helpers.packEvents([ e ])))
    }

    sortGroups = ( a, b ) =>
        config.ui.eventsSorting.indexOf( a.groupName.toLowerCase()) -
    config.ui.eventsSorting.indexOf( b.groupName.toLowerCase())

    /* EVENTS */

    initDrag = () => {
        let draggableEl = document.getElementById( listID )

        new Draggable( draggableEl, {
            itemSelector: '.calendar-event',
            eventData:    ( eventEl ) => JSON.parse( eventEl.getAttribute( 'data-event' )),
        })
    }

    drag = ( item ) => ( event ) => this.props.onDrag( item, event )

    search = ( e ) => this.set.search( e.target.value, this.updateFiltered )

    /* UI */

    length = ( data ) => data.reduce(( l, g ) => l + g.items.length, 0 )

    header = ( date ) =>
        date ? (
            <React.Fragment>
        Задачи на {date.format( config.format.onlyDayView )}
                <span className="return-to-month" onClick={this.props.onReturn}>
                    <CloseOutlined />
                </span>
            </React.Fragment>
        ) : (
            'Список задач'
        )

    footer = () => {
        const { summary, selected, onOpen } = this.props,
              today = moment(),
              action = () =>
                  onOpen({ date: moment( selected ).set({ hour: moment().add( 1, 'hour' ).hour() }), })

        if ( !selected ) { return <CalendarSummary data={summary} /> }
        if ( selected.isBefore( today.startOf( 'day' ))) { return null }

        return <AddButton text="Добавить" action={action} />
    }

    title = ( item ) =>
        item.events.length > 1
            ? item.events[ 0 ].title + ' (' + item.events.length + ')'
            : item.events[ 0 ].title

    event = ( item ) => {
        const event = item.events[ 0 ],
              title = this.title( item )

        return (
            <li
                key={event.taskId}
                className={
                    event.classNames
                        ? [ ...event.classNames, 'fc-event calendar-event' ].join(' ')
                        : 'fc-event calendar-event'
                }
                data-event={JSON.stringify(item)}
                onMouseDown={this.drag(item)}
            >
                <span>{title}</span>
                <span>{event._duration}</span>
            </li>
        )
    }

    group = ( data ) =>
        data.items && data.items.length > 0 ? (
            <div className="calendar-events-group" key={data.groupName}>
                <h4>{data.groupNameUI}</h4>
                <ul className="events-list">{data.items.map(this.event)}</ul>
            </div>
        ) : null

    groups = () => {
        const { inbox, disabled, date, selected, loading } = this.props,
              { search, filtered } = this.state,
              today = moment()

        if ( !inbox || disabled || loading ) {
            return <Spinner />
        }

        if ( helpers.is.outOfEvents( date, selected, today )) {
            return 'В этом месяце задач не осталось'
        }

        if ( filtered.filter(( g ) => g.items.length > 0 ).length === 0 ) {
            if ( selected ) { return 'На выбранный день задач не запланировано' }
            if ( search !== '' ) { return 'Нет подходящих задач' }
            if ( today.endOf( 'month' ).isBefore( date )) { return 'В этом месяце задач пока нет' }
            return 'В этом месяце задач не осталось'
        }

        return filtered.map( this.group )
    }

    setRestaurants = (raw) => {
        const rests = format.generate.keyval(
            format.generate.options(raw, 'factsNumber', 'restaurantName')
        );
        const restaurants = { ...rests };

        this.setState({restaurants});
    };

    filter = (data, search) => {
        const res = {};
        Object.keys(data)
            .filter((id) => data[ id ].toLowerCase().includes(search.toLowerCase()))
            .forEach((id) => {
                res[ id ] = data[ id ];
            });
        return res;
    };

    handleChangeNumbers = (array) => {
        this.props?.onFilter(array)
        this.setState({ factsNumbers: array })
    };

    render () {
        const { summary, selected, date, factsNumbers } = this.props,
              { groups, restaurants } = this.state,
              isFuture = moment().isBefore( moment( date ).endOf( 'week' ).endOf( 'month' ))

        return (
            <div id={listID} className="calendar-events-list">
                <header>
                    <h3>{this.header( selected )}</h3>

                    <Search placeholder="Поиск по названию" onChange={this.search} />

                    <MultiSelectDropdown
                        placeholder="Выберите рестораны"
                        items={restaurants || {}}
                        current={factsNumbers}
                        filter={true}
                        update={this.handleChangeNumbers}
                    />

                    <span className="plan-summary">
                        {isFuture &&
              summary &&
              ( selected
                  ? format.strings.clearCount( this.length( groups ), [
                      'запланирована',
                      'запланировано',
                      'запланировано',
                      'задач',
                  ])
                  : `${summary.dated} из ${summary.total} запланировано` )}
                    </span>
                </header>

                <Scrollbars
                    {...config.ui.scrolls}
                    ref={( node ) => ( this.scrolls = node )}
                >
                    <div className="calendar-events-content">{this.groups()}</div>
                </Scrollbars>

                <footer>{this.footer()}</footer>
            </div>
        )
    }
}

export default CalendarEventsList
