/* eslint-disable no-unused-vars */
/* VENDOR */
import { update } from 'reduxigen'

/* APPLICATION */
import { format } from 'tools'

function helper ( data, state ) {
    const ui = format.copy.object( state.ui )
    Object.keys( data ).map(( key ) => ( ui[ key ] = data[ key ]))
    return { ...state, ui }
}

export const resize = update( 'ui', ( event, state ) =>
    helper(
        {
            width:  window.innerWidth,
            height: window.innerHeight,
        },
        state
    )
)

export const addServerError = update( 'serverErrorsAdd', ( data, state ) => ({
    ...state,
    serverErrors: {
        ...state.serverErrors,
        [ data.code ]: data.text,
    },
}))

export const flushServerError = update(
    'serverErrorsFlush',
    ( errNum, state ) => ({
        ...state,
        serverErrors: {
            ...state.serverErrors,
            [ errNum ]: null,
        },
    })
)

export const flushServerErrors = update(
    'flushServerErrors',
    ( state ) => ({
        ...state,
        serverErrors: {}
    })
)

export const removeEmptyServerErrors = update(
    'removeEmptyServerErrors',
    ( state ) => ({
        ...state,
        serverErrors: state && state.serverErrors && Object.fromEntries(
            Object.entries( state.serverErrors ).filter(([ _, v ]) => v !== null || v !== undefined )
        )
    })
)
