/* VENDOR */
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Button, Layout} from 'antd'
import Icon from '@ant-design/icons'
import ReactExport from 'react-data-export'

/* APPLICATION */
import {
    AppHeader,
    BackTitle,
    Icons,
    InnerContent,
    InnerControls,
    Report,
    ReportExcelTooltip,
    TurnoverInner,
} from 'components'

import {format} from 'tools'
import config from 'config'

import {allActions, mapStateToProps} from './connector'
import moment from 'moment'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet

const { Content, Header } = Layout

class Turnover extends Component {
    constructor ( props ) {
        super( props )
        this.state = {
            showDetails:    true,
            timeZoneName:   null,
            shiftTimeStart: null,
            shiftTimeEnd:   null,
        }
    }

    componentDidMount () {
        const { workHours, request, turnover, fetchWorkHours } = this.props

        !workHours && fetchWorkHours( request )
        !turnover.requestTime && this.load( this.props )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { workHours, request, restaurants } = this.props

        if ( this.props.restaurants !== nextProps.restaurants ) {
            this.load( nextProps )
            return
        }

        if ( request.restaurantId !== nextProps.request.restaurantId ) {
            this.props.fetchWorkHours( nextProps.request )
            this.props.flushTurnover()
            return
        }

        if ( restaurants ) {
            const currRest = restaurants.find(( rest ) => +rest.factsNumber === +request.restaurantId )
            this.setState({ timeZoneName: currRest.timeZoneName })
        }

        if ( nextProps.workHours !== workHours ) {
            this.load( nextProps )
        }

        if ( nextProps.request.currentPredef && nextProps.request.currentPredef === 'shift' ) {
            this.setState({
                shiftTimeStart: nextProps.request.dateStart,
                shiftTimeEnd:   nextProps.request.dateEnd
            })
        } else if ( nextProps.request.currentPredef && nextProps.request.currentPredef === 'week' ) {
            this.setState({
                weekTimeStart: nextProps.request.dateStart,
                weekTimeEnd:   nextProps.request.dateEnd,
            })
        } else if ( nextProps.request.currentPredef && nextProps.request.currentPredef === 'day' ) {
            this.setState({
                dayTimeStart: nextProps.request.dateStart,
                dayTimeEnd:   nextProps.request.dateEnd,
            })
        }

        if ( format.check.request( request, nextProps.request, [], [ 'growing' ])) {
            format.check.changes( request, nextProps.request )
            this.load( nextProps )
        }
    }

    reload = ( props ) => {
        return () =>
            this.setState({ settings: true }, () => {
                this.load( false, props )
            })
    }

    prepare = ( record, index, columns ) =>
        format.goodBad.iterate.subZero( record, [ 'deltaTurnover', 'deltaTurnoverLastYear' ], columns )

    more = ( req ) => {
        const reqObj = {
            ...req,
            factsNumber: req.restaurantId
        }
        delete reqObj.restaurantId
        delete reqObj.zoneId

        this.props.appendReport( reqObj )
    }

    load = ( rprops ) => {
        const props = rprops || this.props,
              { request, workHours, restaurants, } = props

        const { shiftTimeStart, shiftTimeEnd, timeZoneName } = this.state

        const currentHourDateStart = format.dates.getGroup( 'hour', workHours, null, timeZoneName ).start.startOf( 'hour' ).format( config.format.dayAPI + 'THH:mm' )
        const currentHourDateEnd = format.dates.getGroup( 'hour', workHours, null, timeZoneName ).start.endOf( 'hour' ).format( config.format.dayAPI + 'THH:mm' )
        const dayTimeStart = format.dates.getGroup( 'day', workHours, null, timeZoneName ).start.startOf( 'day' ).format( config.format.dayAPI + 'THH:mm' )
        const dayTimeEnd = format.dates.getGroup( 'day', workHours, null, timeZoneName ).start.endOf( 'day' ).format( config.format.dayAPI + 'THH:mm' )
        const weekTimeStart = format.dates.getGroup( 'week', workHours, null, timeZoneName ).start.startOf( 'week' ).format( config.format.dayAPI + 'THH:mm' )
        const weekTimeEnd = format.dates.getGroup( 'week', workHours, null, timeZoneName ).start.endOf( 'week' ).format( config.format.dayAPI + 'THH:mm' )

        if ( !format.check.enough( props ) || !restaurants ) { return }

        const reqObj = {
            ...request,
            factsNumber: request.restaurantId
        }
        delete reqObj.restaurantId
        delete reqObj.zoneId

        props.flushTurnover()

        props.fetchSummary({ ...reqObj, restaurants, workHours, timeZoneName: this.state.timeZoneName, currentHourDateStart })
        props.fetchDetails({
            ...reqObj, workHours, restaurants, timeZoneName: this.state.timeZoneName,
            shiftTimeStart, shiftTimeEnd,
            weekTimeStart, weekTimeEnd,
            dayTimeStart, dayTimeEnd,
            currentHourDateStart, currentHourDateEnd
        })
        props.fetchTOGoals({ ...reqObj, workHours, restaurants })
        props.fetchPlanAndFact( reqObj )

        props.fetchReport( reqObj )
    }

    set = ( data ) => this.props.setRequest( data )
    showDetails = ( showDetails ) => this.setState({ showDetails })

    prepareExcel = () => {
        const { turnover, request } = this.props
        const currency = format.extract.currency( request.unitOfMeasure )
        const red = '00FF0000'
        const green = '00038E1D'
        const black = '00000000'
        const isTimeText = request?.currentPredef === 'shift' || request?.currentPredef === 'day'

        const getFormattedValue = ( value ) => value !== null ? `${value}${currency === '%' ? ' ' + currency : ''}` : ''

        if ( !turnover || !turnover.report || !turnover.report.content ) { return [] }

        return [ {
            columns: [
                {title: isTimeText ? 'Время' : 'Дата'},
                {title: 'Факт'},
                {title: 'Цель'},
                {title: 'Разница'},
                {title: 'Прош. год'},
                {title: 'Разница'},
                {title: 'Менеджер(ы) смены', width: {wpx: 150}},
            ],
            data: turnover.report.content.map((item => ([
                {value: item.label},
                {value: getFormattedValue(item.factTurnover)},
                {value: getFormattedValue(item.planTurnover)},
                {
                    value: getFormattedValue(item.deltaTurnover),
                    style: {
                        font: {
                            color: {
                                rgb: item.deltaTurnover < 0
                                    ? red
                                    : item.deltaTurnover === 0 ? black : green
                            }
                        }
                    }
                },
                {value: getFormattedValue(item.factTurnoverLastYear)},
                {
                    value: getFormattedValue(item.deltaTurnoverLastYear),
                    style: {
                        font: {
                            color: {
                                rgb: item.deltaTurnoverLastYear < 0
                                    ? red
                                    : item.deltaTurnoverLastYear === 0 ? black : green
                            }
                        }
                    }
                },
                {value: item.manager.map(item => item.managerName).join(', ')},
            ])
            ))
        } ]
    }

    showExcelTooltip = () => {
        const { turnover } = this.props
        const fields = [ 'factTurnover', 'planTurnover', 'deltaTurnover', 'factTurnoverLastYear', 'deltaTurnoverLastYear' ]

        if ( !turnover?.report?.content ) { return true }

        return turnover?.report?.content?.every( item => {
            return Object.entries( item ).filter(
                ([ key ]) => fields.find( k => k === key )
            ).every(([ _, value ]) => value === null )
        })
    }

    render () {
        const { request, workHours, turnover } = this.props,
              { showDetails } = this.state

        const disabledExcel = this.showExcelTooltip()

        return (
            <section className="kfc-report kfc-turnover">
                <Layout>
                    <Header>
                        <AppHeader bothTimes timeData={parseInt( request.restaurantId )} />
                    </Header>
                    <Content>
                        <InnerControls
                            useGrowing
                            actions={format.extract.actions( this.props )}
                            request={request}
                            workHours={workHours}
                            update={this.load}
                            onChange={this.set}
                            onActiveDate={this.showDetails}
                        />

                        <InnerContent>
                            <BackTitle
                                showCurrency
                                showGrowing
                                showDownload
                                text="Товарооборот"
                                request={request}
                                onChange={this.set}
                                download={
                                    disabledExcel ?
                                            <ReportExcelTooltip />
                                        :
                                            <ExcelFile
                                                filename={`Товарооборот_${moment().format( 'YYYY-MM-DDTHH:mm:ss' )}`}
                                                element={
                                                    <Button
                                                        type='link'
                                                        className='button-download'
                                                    >
                                                        <Icon component={Icons.Download.active} />
                                                    </Button>}
                                            >
                                                <ExcelSheet dataSet={this.prepareExcel()} name="Товарооборот"/>
                                            </ExcelFile>
                                }
                            />

                            <TurnoverInner showDetails={showDetails} />

                            <Report
                                onMore={this.more}
                                data={turnover.report}
                                columns={config.tables.turnover( request )}
                                prepare={this.prepare}
                            />
                        </InnerContent>
                    </Content>
                </Layout>
            </section>
        )
    }
}

export default connect( mapStateToProps, allActions )( Turnover )
