import column from './helpers'

export default {
    assignShift: [
        column( 'Сотрудник', 'employeeName', { width: 200, }),
        column( 'Доступное время', 'availableTime', {
            width:  130,
            render: ( text, record ) =>
                record.availabilityTime
                    ? record.availabilityTime.timeStart +
            '–' +
            record.availabilityTime.timeEnd
                    : '—',
        }),
        column( 'Квалификация', 'skill', {
            width:  110,
            render: ( text, record ) => ( record.skill ? 'Есть' : null ),
        }),
        column( 'Несовершеннолетний', 'isUnderage', {
            width:  150,
            render: ( _, record ) => ( record.isUnderage ? 'Да' : 'Нет' ),
        }),
    ],
    schedule: {
        mobile: [
            column( 'Работник', 'employeeName', {
                as:        'label',
                fixed:     'left',
                width:     180,
                allowFull: true,
            }),
            column( 'Время', 'shiftStart', {
                as:    'chartTime',
                fixed: 'left',
                width: 50,
            }),
            column( '', 'shiftEnd', {
                as:    'chartTime',
                fixed: 'left',
                width: 50,
            }),
        ],
        chart: [
            column( '', 'shiftSupervisor', {
                editable: true,
                fixed:    'left',
                width:    32,
            }),
            column( 'Работник', 'employeeName', {
                editable:  true,
                items:     'employees',
                as:        'cell',
                fixed:     'left',
                width:     180,
                allowFull: true,
                search:    true,
            }),
            column( 'Начало', 'shiftStart', {
                editable:   true,
                checkToday: true,
                as:         'chartTime',
                fixed:      'left',
                width:      75,
            }),
            column( 'Окончание', 'shiftEnd', {
                editable:   true,
                checkToday: true,
                as:         'chartTime',
                fixed:      'left',
                width:      75,
            }),
            column( 'Позиция', 'position', {
                editable:  true,
                as:        'select',
                items:     'positions',
                fixed:     'left',
                width:     80,
                starPos:   'right',
                endpoint:  'primary',
                itemKey:   'employeeId',
                className: 'schedule-position',
                search:    true,
            }),
            column( '', null, {
                as:        'remove',
                small:     true,
                fixed:     'left',
                className: 'align-center nopad',
                width:     40,
            }),
        ],
        top: [
            column( '', 'label', {
                editable: true,
                as:       'label',
                fixed:    'left',
                width:    180,
                items:    'positions',
            }),
            column( 'Суммарно', 'value', {
                as:        'cell',
                className: 'align-center',
                fixed:     'left',
                width:     120,
            }),
            column( '', 'subval', {
                as:    'space',
                fixed: 'left',
                width: 182,
            }),
        ],
        summaryReport: {
            hiddenWorker: [
                column( 'Имя', 'employeeName', {
                    as:       'select',
                    editable: true,
                    items:    'employees',
                    width:    180,
                }),
                column( 'Позиция', 'positionName', {
                    as:    'cell',
                    width: 80,
                }),
            ],
            turnoverAverage: [
                column( 'ТО', 'planTurnoverRgm', {
                    as:    'cell',
                    width: 120,
                }),
                column( 'СЧ', 'planAverageCheck', {
                    as:    'cell',
                    width: 120,
                }),
            ],
            spmhTcph: [
                column( 'SPMH', 'planSpmh', {
                    as:    'cell',
                    width: 120,
                }),
                column( 'TCPH', 'planTcph', {
                    as:    'cell',
                    width: 120,
                }),
            ],
            stations: [
                column( 'Станции', 'positionStationName', {
                    as:     'cell',
                    width:  120,
                    render: ( value, row ) => {
                        const obj = {
                            children: value,
                            props:    { rowSpan: row.rowSpan, },
                        }

                        return obj
                    },
                }),
                column( 'Сотрудники', 'employeeName', {
                    as:        'cell',
                    width:     120,
                    dataIndex: 'employees',
                    render:    ( employees, row ) => {
                        if ( row.rowSpan === 0 ) {
                            return { props: { rowSpan: 0 } }
                        }
                        const employeeNames = employees?.map( employee => employee.employeeName ).join( ', ' )
                        console.log( employeeNames )
                        return employeeNames
                    },
                }),
            ],
            daysAndNights: [
                column( 'С ночи', 'night', {
                    as:       'cell',
                    width:    160,
                    children: [
                        column( '', 'timeStart', {
                            as:    'cell',
                            width: 80,
                        }),
                        column( '', 'timeEnd', {
                            as:    'cell',
                            width: 80,
                        }),
                    ],
                }),
                column( '04/02/2024', 'date', {
                    as:       'cell',
                    width:    160,
                    children: [
                        column( 'ПН', 'dayOfWeek', {
                            as:       'cell',
                            width:    240,
                            children: [
                                column( '', 'timeStart', {
                                    as:    'cell',
                                    width: 80,
                                }),
                                column( '', 'timeEnd', {
                                    as:    'cell',
                                    width: 80,
                                }),
                                column( '', 'position', {
                                    as:    'cell',
                                    width: 80,
                                }),
                            ],
                        }),
                    ]
                }),
            ],
            hours: [
                column( 'Часы по позициям', 'planSummaryPositionDuration', { as: 'cell', }),
                column( 'Общие часы', 'planSummaryDuration', { as: 'cell', }),
                column( 'Ознакомлен', 'acknowledged', { as: 'cell', }),
            ]
        }
    },
}
