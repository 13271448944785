/* VENDOR */
import React from 'react'

/* APPLICATION */
import { Icon, IconActive, createIcon } from './Icon'

const svg = ( primary ) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
        <path
            fill={primary}
            d="M14.25 2.25h-.75V1.5c0-.4125-.3375-.75-.75-.75s-.75.3375-.75.75v.75H6V1.5c0-.4125-.3375-.75-.75-.75s-.75.3375-.75.75v.75h-.75c-.8325 0-1.4925.675-1.4925 1.5L2.25 14.25c0 .825.6675 1.5 1.5 1.5h10.5c.825 0 1.5-.675 1.5-1.5V3.75c0-.825-.675-1.5-1.5-1.5zm-.75 12h-9c-.4125 0-.75-.3375-.75-.75V6h10.5v7.5c0 .4125-.3375.75-.75.75zM6 7.5h2.25c.4125 0 .75.3375.75.75v2.25c0 .4125-.3375.75-.75.75H6c-.4125 0-.75-.3375-.75-.75V8.25c0-.4125.3375-.75.75-.75z"
        />
    </svg>
)

class Calendar extends Icon {
    render () {
        return svg( this.primary, this.secondary )
    }
}

class CalendarActive extends IconActive {
    render () {
        return svg( this.primary, this.secondary )
    }
}

export default createIcon( Calendar, CalendarActive )
