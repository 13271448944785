import React, {useState} from 'react'
import {Layout} from 'antd'
import {AppHeader, GuestVoteControls, GuestVoteTabs} from 'components'
import {FilterParams, FormatedDateFilters} from './types';
import {dayAPI} from 'config/formats';
import {PickerType} from 'components/guestVote/GuestVoteControls/types';

const {Content, Header} = Layout


const GuestVote = () => {
    const [ dateFilters, setDateFilters ] = useState<FormatedDateFilters>({dateStart: '', dateEnd: ''});
    const [ currentDatePicker, setCurrentDatePicker ] = useState<PickerType>('month')

    const handleFilterChange = (filters: FilterParams) => {
        if (filters.dateStart && filters.dateEnd) {
            const formatedDateStart = filters.dateStart.format(dayAPI);
            const formatedDateEnd = filters.dateEnd.format(dayAPI);
            setDateFilters(prev => ({
                ...prev,
                dateStart: formatedDateStart,
                dateEnd: formatedDateEnd,
                daysOfWeek: filters.daysOfWeek,
            }))
        } else {
            setDateFilters(prev => ({...prev, daysOfWeek: filters.daysOfWeek}))
        }
        setCurrentDatePicker(filters.picker)
    };

    return (
        <section className="kfc-guest-vote">
            <Layout>
                <Header>
                    <AppHeader hideRestaurants/>
                </Header>
                <Content>
                    <GuestVoteControls onFilterChange={handleFilterChange}/>
                    <GuestVoteTabs
                        dateFilters={dateFilters}
                        picker={currentDatePicker}
                    />
                </Content>
            </Layout>
        </section>
    )
}

export default GuestVote
