import * as copy from './copy'
import * as find from './find'
import * as ups  from './update'

export const basic = ( rootKey ) => {
    return ( key, data, state, status ) => {
        const res = copy.object( state ),
              ext = copy.object( res[ rootKey ])

        let st = status || 200

        if ( st !== 200 ) {
            return res
        }

        ext[ key ] = data
        res[ rootKey ] = ext

        return res
    }
}

export const append = ( rootKey ) => {
    return ( key, event, state ) => {
        const data = copy.object( event.data ),
              old = copy.array( state[ rootKey ][ key ].content ),
              res = copy.object( state ),
              ext = copy.object( res[ rootKey ])

        data.content = [ ...old, ...data.content ]
        ext[ key ] = data
        res[ rootKey ] = ext

        return res
    }
}

export const deep = ( rootKey ) => {
    return ( data, state ) => {
        const res = copy.object( state ),
              ext = copy.object( res[ rootKey ])

        Object.keys( data ).map(( key ) => ( ext[ key ] = data[ key ]))

        res[ rootKey ] = ext

        return res
    }
}

const updFail = ( ev, state, path, key ) =>
    updResult(
        ev,
        state,
        path,
        key,
        // eslint-disable-next-line camelcase
        ( cfg ) => ( cfg.arr[ cfg.index ]._api_error = ev.data.message )
    ),
      updResult = ( ev, state, path, key, handler ) => {
          const // eslint-disable-next-line
      arr = copy.array(eval("state." + path)),
              data = JSON.parse( ev.config.data )

          let index = find.index( arr, key, data[ key ])

          index < 0 && ( index = arr.length )

          handler({ arr, data, index })

          return ups.object( state, path, arr )
      }

export const update = ( path, key ) => {
    return ( ev, state, handler ) => {
        return ev.status !== 200
            ? updFail( ev, state, path, key )
            : updResult( ev, state, path, key, handler )
    }
}

export const dupdate = ( path, search ) => {
    return ( value, state, id ) => {
    // eslint-disable-next-line
    const arr = copy.array(eval("state." + path));
        let index = find.index( arr, search, id )

        index < 0 && ( index = arr.length )
        arr[ index ] = value

        return ups.object( state, path, arr )
    }
}
