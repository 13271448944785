import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import moment from 'moment';
import {
    AppHeader,
    DashboardCards,
    InnerContent,
    InnerControls,
    Spinner,
} from 'components';

import { format } from 'tools';
import config from 'config';

import {allActions, DashboardStoreProps, mapStateToProps} from './connector';
import './dashboard.scss';

const { Content, Header } = Layout;

const Dashboard = (props: DashboardStoreProps) => {
    const [ settings, setSettings ] = useState<boolean>(false);
    const [ showDetails, setShowDetails ] = useState<boolean>(true);
    const [ timeZoneName, setTimeZoneName ] = useState<string| null>(null);
    const [ shiftTimeStart, setShiftTimeStart ] = useState<string | null>(null);
    const [ shiftTimeEnd, setShiftTimeEnd ] = useState<string | null>(null);

    useEffect(() => {
        props.flushWorkHours();
        props.fetchWorkHours(props.request);

        if (props.restaurants && props.restaurants.length) {
            setRestaurantInfo()
        }
    }, []);


    useEffect(() => {
        setSettings(false)
        props.fetchWorkHours(props.request);
    }, [ props.request.restaurantId ])

    useEffect(() => {
        setShiftTimeStart(props.request.dateStart);
        setShiftTimeEnd(props.request.dateEnd);
    }, [ props.request.currentPredef ])

    useEffect(() => {
        load(false);
    }, [ props.request.dateStart, props.request.dateEnd, props.request.dateGroup, props.request.currentPredef, props.request.unitOfMeasure, props.request.growing ])

    useEffect(() => {
        if (props.restaurants && props.restaurants.length) {
            setRestaurantInfo()
        }
    }, [ props.restaurants ])

    useEffect(() => {
        if ((props.workHours && timeZoneName)) {
            load(false);
        }
    }, [ props.workHours, timeZoneName ])

    const setRestaurantInfo = () => {
        const currRest = props.restaurants.find(
            (rest: any) => +rest.factsNumber === Number(props?.request?.restaurantId)
        );
        props.setServiceType(currRest?.restaurantType?.name);
        setTimeZoneName(currRest.timeZoneName);
    }

    const load = (silent: boolean) => {
        const { request, workHours, restaurants } = props

        const currentHourDateStart = format.dates.getGroup('hour', workHours, null, timeZoneName).start.startOf('hour').format(config.format.dayAPI + 'THH:mm');
        const currentHourDateEnd = format.dates.getGroup('hour', workHours, null, timeZoneName).start.endOf('hour').format(config.format.dayAPI + 'THH:mm');
        const dayTimeStart = format.dates.getGroup('day', workHours, null, timeZoneName).start.startOf('day').format(config.format.dayAPI + 'THH:mm');
        const dayTimeEnd = format.dates.getGroup('day', workHours, null, timeZoneName).start.endOf('day').format(config.format.dayAPI + 'THH:mm');
        const weekTimeStart = format.dates.getGroup('week', workHours, null, timeZoneName).start.startOf('week').format(config.format.dayAPI + 'THH:mm');
        const weekTimeEnd = format.dates.getGroup('week', workHours, null, timeZoneName).start.endOf('week').format(config.format.dayAPI + 'THH:mm');

        const reqObj = {
            ...request,
            factsNumber: request.restaurantId
        };

        if (moment(reqObj.dateStart).isSame(moment(reqObj.dateEnd)) || moment(reqObj.dateStart).isAfter(moment(reqObj.dateEnd))) {
            reqObj.dateEnd = moment(reqObj.dateStart).add(1, 'days').format(config.format.date);
        }
        // @ts-ignore
        delete reqObj.restaurantId;
        // @ts-ignore
        delete reqObj.zoneId;
        // @ts-ignore
        delete reqObj.employeeId;

        if (!format.check.enough(props) || !restaurants || !timeZoneName) {
            return;
        }

        setSettings(true)
        if (!silent) {
            props.flushPerformance(request);
            props.flushTurnover();
        }

        props.fetchDetails({
            ...reqObj, workHours, restaurants, timeZoneName,
            shiftTimeStart, shiftTimeEnd,
            weekTimeStart, weekTimeEnd,
            dayTimeStart, dayTimeEnd,
            currentHourDateStart, currentHourDateEnd
        });
        props.fetchSummary({ ...reqObj, restaurants });
        props.fetchTOGoals({ ...reqObj, workHours, restaurants });
        props.fetchPlanAndFact(reqObj);

        const performanceRequest = format.copy.object(reqObj);
        performanceRequest.dateStart = moment(performanceRequest.dateStart, config.format.date).format(config.format.dayAPI + 'T00:00');
        performanceRequest.dateEnd = moment(performanceRequest.dateEnd, config.format.date).format(config.format.dayAPI + 'T23:59');

        const performanceArrayReq = format.copy.object(reqObj);
        performanceArrayReq.factsNumbers = [ parseInt(reqObj.factsNumber as string) ];

        props.fetchPerformanceSummary(performanceArrayReq);
        props.fetchPerformancePlanAndFact(reqObj);
    };

    return (
        <section className="kfc-dashboard">
            <Layout>
                <Content>
                    <Layout>
                        <Header>
                            <AppHeader timeZoneName={timeZoneName} bothTimes timeData={parseInt(props.request.restaurantId as string)} />
                        </Header>
                        <Content>
                            <InnerControls
                                actions={format.extract.actions(props)}
                                request={props.request}
                                workHours={props.workHours}
                                update={load}
                                useGrowing={true}
                                onActiveDate={setShowDetails}
                                timeZoneName={timeZoneName}
                            />
                            <InnerContent>
                                {settings ? (
                                    <DashboardCards
                                        request={props.request}
                                        workHours={props.workHours}
                                        turnover={props.turnover}
                                        performance={props.performance}
                                        rating={props.rating}
                                        showDetails={showDetails}
                                        update={props.setRequest}
                                    />
                                ) : (
                                    <Spinner />
                                )}
                            </InnerContent>
                        </Content>
                    </Layout>
                </Content>
            </Layout>
        </section>
    );
};

export default connect(mapStateToProps, allActions)(Dashboard);
