import {STORAGE_KEY} from '../../GuestVoteTable/GuestVoteTable';
import {DataGroupType} from 'services/guestVote/statistic/type';

export const useGetCurrentDataGroup = (disabledDropTypes?: DataGroupType[]) => {
    let currentDataGroup = (sessionStorage.getItem(STORAGE_KEY) ? sessionStorage.getItem(STORAGE_KEY) : 'RESTAURANTS') as DataGroupType;
    if (disabledDropTypes?.includes(currentDataGroup)) {
        sessionStorage.setItem(STORAGE_KEY, 'RESTAURANTS')
        currentDataGroup = 'RESTAURANTS'
    }
    return currentDataGroup
}
