/* IMPORT */

import { staffActions, } from 'services'

/* EXPORT */

export const mapStateToProps = ( state ) => ({
    areaData:     state.staff.areaData,
    areaCard:     state.staff.areaCard,
    acStatuses:   state.staff.acStatuses,
    ppr:          state.staff.ppr,
    restaurantId: state.request.restaurantId,
})

export const allActions = { ...staffActions, }
