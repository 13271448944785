/* IMPORT */

import { settingsActions } from 'services'

/* EXPORT */

export const mapStateToProps = ( state ) => ({
    showData:      state.settings.showData,
    restaurantId:  state.request.restaurantId,
    showDataError: state.settings.showDataError
})

export const allActions = { ...settingsActions, }
