/* IMPORT */

import { restaurantsRatingActions, requestActions } from 'services'

/* EXPORT */

export const mapStateToProps = ( state ) => ({
    turnover: {
        plan:   state.restaurantsRating.turnoverPlanAndFact?.plan,
        fact:   state.restaurantsRating.turnoverPlanAndFact?.fact,
        all:    state.restaurantsRating.allTurnover,
        report: state.restaurantsRating.turnover,
    },
    average: {
        all:    state.restaurantsRating.allAverage,
        report: state.restaurantsRating.average,
    },
    time: {
        all:    state.restaurantsRating.allServiceTime,
        report: state.restaurantsRating.serviceTime,
    },
    performance: {
        plan:   state.restaurantsRating.performancePlanAndFact?.plan,
        fact:   state.restaurantsRating.performancePlanAndFact?.fact,
        all:    state.restaurantsRating.allPerformance,
        report: state.restaurantsRating.performance,
    },
    categories: {
        partners:     state.restaurantsRating.partners,
        list:         state.restaurantsRating.categories,
        managers:     state.restaurantsRating.managers,
        restaurants:  state.restaurantsRating.restaurants,
        shouldUpdate: state.restaurantsRating.shouldUpdate,
    },
    filters: state.restaurantsRating.filters,
    overall: state.restaurantsRating.overall,
    user:    state.user.info,
    request: state.request,
})

export const allActions = {
    ...requestActions,
    ...restaurantsRatingActions,
}
