/* VENDOR */
import React  from 'react'
import moment from 'moment'

/* APPLICATION */
import { format } from 'tools'
import config     from 'config'

import Template from './Template.js'
import './short-schedule.scss'

const getDate = ( date ) => format.strings.dowDateRS( date ),
      spoiler = ( employee ) => ({
          key:   employee.employeeName,
          day1:  <span>{employee.employeeName}</span>,
          props: { className: 'employee-name', },
      }),
      days = ( employee ) => {
          const row = { key: employee.employeeName + 'Days', }

          config.defs.days.forEach(( dow ) => {
              const day = employee.days.find(
                  ( d ) => moment( d.date ).format( 'E' ) === dow.toString()
              )

              if ( day ) {
                  row[ 'day' + dow ] = (
                      <span className="schedule-day">
                          <b>{getDate( moment( day.date ))}</b>
                          {day.shifts.map(( shift ) => (
                              <em key={shift.timeStart}>
                                  {format.strings.interval( shift.timeStart, shift.timeEnd )}{' '}
                                  {shift.position}
                              </em>
                          ))}
                      </span>
                  )
              }
          })

          return row
      },
      rows = ( data ) => {
          const res = []

          data.employees.forEach(( employee ) => {
              res.push( spoiler( employee ))
              res.push( days( employee ))
          })

          return res
      },
      columns = () =>
          format.generate.rowReader([
              'day1',
              'day2',
              'day3',
              'day4',
              'day5',
              'day6',
              'day7',
          ])

class ShortSchedule extends Template {
    constructor ( props ) {
        super( props )

        this.state = {
            data:      rows( props.data ),
            columns:   columns( props.data ),
            className: 'short-schedule',
        }
    }

    data = ( data ) => rows( data )
    columns = ( data ) => columns( data )
}

export default ShortSchedule
