/* VENDOR */
import React from 'react'

/* APPLICATION */
import { Icon, IconActive, createIcon } from './Icon'

const svg = ( primary ) => (
    <svg width="15" height="15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.74 4.696L10.305.26a.884.884 0 0 0-.63-.26.884.884 0 0 0-.629.26l-.912.912a.89.89 0 0 0 0 1.259l.08.08L4.93 5.33l-.32-.32a.96.96 0 0 0-.677-.274.95.95 0 0 0-.608.215L1.913 6.127a.825.825 0 0 0-.055 1.227l2.337 2.338c-.802.807-1.926 1.94-2.07 2.097a18.65 18.65 0 0 0-.616.708c-.389.47-.73.931-1.004 1.344-.137.206-.259.4-.363.575-.316.554-.075.732.427.426.175-.104.369-.225.575-.363.412-.274.873-.615 1.343-1.004.235-.194.473-.4.709-.616.157-.143 1.29-1.268 2.097-2.07l2.353 2.354c.16.16.37.248.59.248a.83.83 0 0 0 .637-.304l1.176-1.412a.973.973 0 0 0-.059-1.285l-.32-.32 2.819-3.285.08.081c.168.168.392.26.63.26a.884.884 0 0 0 .629-.26l.912-.912a.89.89 0 0 0 0-1.258z"
            fill={primary}
        />
    </svg>
)

class Pin extends Icon {
    render () {
        return svg( this.primary, this.secondary )
    }
}

class PinActive extends IconActive {
    render () {
        return svg( this.primary, this.secondary )
    }
}

export default createIcon( Pin, PinActive )
