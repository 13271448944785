/* VENDOR */
import React, { Component } from 'react'

/* APPLICATION */
import { LoginForm } from 'components'

import './login-card.scss'

class LoginCard extends Component {
    render () {
        return <LoginForm />

        //Old
    /*return (
            <Card className="login-card" bordered={false}>
                <Layout>
                    <Header>
                        <img src={config.ui.imagesURL + 'hello.svg'} alt="" />
                    </Header>
                    <Layout>
                        <Content>
                            <LoginForm />
                        </Content>
                    </Layout>
                </Layout>
            </Card>
        )*/
    }
}

export default LoginCard
