/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'

/* APPLICATION */
import { Value, Spinner } from 'components'
import { format }                from 'tools'
import config                    from 'config'

import ScheduleTableView from './ScheduleTableView'
import * as helpers      from '../helpers'
import logic             from '../../logic'

const rows = {
    def: [
        {
            path:   'planTurnover',
            label:  'План ТО Директора',
            render: 'currency',
        },
        {
            path:   'planTransactions',
            label:  'План транзакции',
            render: 'na',
        },
        {
            path:   'planSpmh',
            label:  'План SPMH',
            render: 'na',
        },
        {
            path:   'planTcph',
            label:  'План TCPH',
            render: 'na',
        },
        {
            path:   'planUnproductiveHours',
            label:  'Запланировано непроизвод. часов',
            render: 'na',
        },
        {
            path:   'planShiftSupervisorHours',
            label:  'Запланировано часов МС',
            render: 'na',
        },
    ],
    paid: [
        {
            path:   'productiveHours.requiredHours',
            label:  'Требуется производ. часов',
            render: 'na',
        },
        {
            path:   'productiveHours.planHours',
            label:  'Запланировано производ. часов',
            render: 'na',
        }

    ],
    delta: {
        path:    'productiveHours.deltaHours',
        label:   'Производительные часы',
        render:  'delta',
        spoiler: true,
    },
}

class ScheduleTable extends Component {
    static propTypes = {
        day:      PropTypes.string,
        position: PropTypes.object,

        from: PropTypes.object,
        to:   PropTypes.object,
        max:  PropTypes.array,

        data:      PropTypes.object,
        helpers:   PropTypes.object,
        open:      PropTypes.object,
        positions: PropTypes.array,
        workHours: PropTypes.object,

        onSelect: PropTypes.func,
    }

    constructor ( props ) {
        super( props )

        this.state = {
            data:      null,
            positions: [],
        }

        this.set = format.generate.set( this )
    }

    componentDidMount () {
        this.setData()
    }

    componentDidUpdate ( prevProps ) {
        const { data, day, open, position, positions } = this.props

        if ( data !== prevProps.data ||
            open !== prevProps.open ||
            position !== prevProps.position ) {
            this.setData()
        }
        positions !== prevProps.positions && this.setPositions()

        if ( day !== prevProps.day ) {
            this.set.data( null )
            setTimeout(() => this.setData(), 0 )
        }
    }

    /* DATA UPDATE METHODS */

    setData = () =>
        this.set.data( this.data(), () => {
            this.setPositions()
        })

    setPositions = () =>
        this.props.positions
            ? this.set.positions(
                format.generate.options(
                    this.props.positions
                        ?.map(( p ) => {
                            p.label = (
                                <span>
                                    {p.position}
                                    {this.getDelta( p )}
                                </span>
                            )
                            return p
                        }),
                    'positionGuid',
                    'label'
                )
            )
            : []

    /* MAIN METHODS */

    generate = ( rows, add, classNames ) => {
        const { day, data } = this.props,
              res = logic.datetime.find( data.days, day )

        return rows.map( this.row( res, add, classNames ))
    }

    /**
     * Генерирует данные и их порядок отображения в верхней панели в Расписании,
     * а также формирует res
     * @returns {res} Массив данных, которые помещают в вер
     */
    data = () => {
        const { open, day, position } = this.props

        let res = []

        res = res.concat( this.generate( rows.def.slice( 0, 4 )))

        res = res.concat( this.generate([ rows.delta ]))

        res = res.concat( this.generate( rows.paid, 'Paid', !open[ 'productiveHours' + day + 'Day' ] ? 'row-hidden' : '' ))

        res = position ? this.position( res ) : this.posSelect( res )

        res = res.concat( this.generate( rows.def.slice( 4 )))

        const TCPHIndex = res.findIndex(item => item.key === 'План TCPH');
        if (TCPHIndex !== -1) {
            res[ TCPHIndex ].value = res[ TCPHIndex ].value !== null && res[ TCPHIndex ].value.toLocaleString()
        }

        return res
    }

    /* CALCULATION METHODS */

    getDelta = ( pos ) => {
        const { data } = this.props
        const day = data.days.find(
            ( d ) => parseInt( d.dayOfWeek ) === parseInt( this.props.day )
        )
        const position = day?.positions?.find(( p ) => p.positionGuid === pos.positionGuid )
        const delta =
            position && position.positionGuid !== config.mcGuid
                ? position.summaryHours?.deltaHours
                : 0

        if ( delta === 0 ) {
            return null
        }

        return (
            <Value
                value={delta}
                format={( val ) =>
                    val === 0 ? '' : val < 0 ? '–' + Math.abs( val ) : '+' + val
                }
            />
        )
    }

    row = ( original, rawAdd, classNames ) => {
        return ( row ) => {
            // if (!original) {
            //     original = { productiveHours: {} }
            // }

            const { from, to } = this.props
            const { path, label, render, spoiler } = row
            const value = eval( `original.${path} ` )

            const data = original.hours || []
            const res = { label, value }
            const add = rawAdd || ''

            if ( original.positionGuid === config.mcGuid ) {
                res.value = 0
            }

            res.key = res.label + add
            res.select = row.select
            res.rowClass = classNames || ''
            original.positionGuid && ( res.positionGuid = original.positionGuid )
            render && ( res.as = render )
            spoiler && this.props.spoiler( path, res )
            res.hideCurrency = true

            res.__inner = !!add
            res.positionGuid !== config.mcGuid &&
            helpers.hours( this.hour( res, path, data ), from, to )

            return res
        }
    }

    hour = ( res, path, data ) => {
        return ( hour ) => ( res[ 'hour-' + hour ] = this.extract( data, hour, path ))
    }

    position = ( res ) => {
        let pos = this.props.position,
            prow = format.copy.object( rows.delta )

        prow.label = 'По ' + pos.position
        prow.select = true

        pos.productiveHours = pos.summaryHours
        pos.hours = pos.hours.map(h => ({productiveHours: h.paidHours}))

        res.push( this.row( pos )( prow ))
        res = res.concat( rows.paid.map( this.row( pos, 'Pos' )))

        return res
    }

    /* TOOLS */

    posSelect = ( res ) => {
        res.push({
            key:    'posselect',
            select: true,
            label:  'Выбрать производ. позицию',
        })

        return res
    }

    extract = ( data, hour, path ) => {
        let found = data.find(
            ( d ) => d.hour === hour
        )

        if (!found) {
            found = data.find((d) => d.productiveHours?.hour === hour)
        }

        return found
            ? // eslint-disable-next-line
            eval("found." + path)
            : null
    }

    render () {
        const { workHours, day, from, to, max } = this.props,
              { positions, data } = this.state

        if ( !data ) {
            return (
                <div className="spinner-container table">
                    <Spinner/>
                </div>
            )
        }

        return (
            <ScheduleTableView
                positions={positions}
                data={data}
                loading={!this.props.data}
                workHours={workHours}
                day={day}
                from={from}
                to={to}
                max={max}
                onSelect={this.props.onSelect}
            />
        )
    }
}

export default ScheduleTable
