/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import Icon from '@ant-design/icons'

/* APPLICATION */
import './icon-link.scss'

class IconLink extends Component {
    static propTypes = { icon: PropTypes.object, }

    render () {
        return (
            <span className="icon-link" {...this.props}>
                <Icon component={this.props.icon.active} />
                {this.props.children}
            </span>
        )
    }
}

export default IconLink
