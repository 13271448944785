/* VENDOR */
import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { Card }             from 'antd'

/* APPLICATION */
import { DiscardChanges, TemplatesList, TemplateCard } from 'components'

import { format } from 'tools'
import config     from 'config'

import { mapStateToProps, allActions } from './connector'

class TemplateEditor extends Component {
    constructor ( props ) {
        super( props )
        this.state = {
            activeOnly: true,
            popup:      false,
            changed:    false,

            current:  null,
            selected: null,
            toSelect: null,

            list:   props.templates.list,
            added:  [],
            groups: null,
        }
    }

    componentDidMount () {
        this.loadList( this.props )
        this.props.templates.current &&
      this.setTemplate( this.props.templates.current, this.props )
        this.props.fetchElementTypes()
        this.props.fetchRestaurantsFZList()
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { reload } = this.state

        reload && this.loadList( nextProps );
        if (nextProps.templates.elementTypes !== this.props.templates.elementTypes) {
            nextProps.templates.elementTypes.forEach(item => {
                if (item.hasAttributes) {
                    setTimeout(() => {
                        this.props.postElementAttributes(item.code)
                    }, 0)
                }
            })
        }

        ( nextProps.templates.list !== this.props.templates.list || reload ) &&
      this.setList( nextProps.templates )
        nextProps.templates.current !== this.props.templates.current &&
      this.setTemplate( nextProps.templates.current, nextProps )
    }

    setFirst = ( first ) => {
        const { current } = this.state

        if ( !current ) {
            this.setTemplate( first, this.props )
        }
    }

    setTemplate = ( current ) => {
        this.setState({
            current,
            changed: false,
        })
    }

    setList = ( templates ) => {
        const { current, activeOnly } = this.state,
              list = activeOnly
                  ? templates.list.filter(( i ) => i.active )
                  : templates.list,
              groups = templates.groups,
              found = current
                  ? templates.list.find(( t ) =>
                      current.templateId
                          ? t.templateId === current.templateId
                          : t.templateName === current.templateName &&
                t.groupName === current.groupName
                  )
                  : null

        this.setState({
            list: this.withCreated( this.state.added, list ),
            groups,
        })
        found && this.setTemplate( found )
    }

    setGroup = ( selected ) =>
        this.setState({
            selected,
            current: null,
        })

    withCreated = ( added, data ) => {
        if (!data) { return null }
        if ( !added.length && !data.length ) { return null }
        const res = added.concat( data )

        return res.filter(
            ( item, index ) =>
                res.indexOf( res.find(( i ) => i.templateId === item.templateId )) === index
        )
    }

    loadList = ( props ) => {
        props.fetchTemplates()
        props.fetchTemplatesGroups()
        this.setState({ reload: false, })
    }

    toggleActive = ( event ) => {
        event.target.checked &&
      this.props.templates.current &&
      !this.props.templates.current.active &&
      this.props.flushTemplate()

        this.setState({ activeOnly: event.target.checked }, () =>
            this.setList( this.props.templates )
        )
    }

    select = ( id ) => {
        if ( this.state.current && id === this.state.current.templateId ) { return }

        this.setState({ changed: false, })

        const template = this.state.list.find(( i ) => i.templateId === id )

        template.__new ? this.setTemplate( template ) : this.props.getTemplate( id )
    }

    approveSelect = () => {
        this.state.toSelect === 'new'
            ? this.add()
            : this.select( this.state.toSelect )

        this.hidePopup()
    }

    askSelect = ( id ) =>
        this.state.changed
            ? this.setState({
                popup:    true,
                toSelect: id,
            })
            : this.select( id )

    askAdd = () =>
        this.state.changed
            ? this.setState({
                popup:    true,
                toSelect: 'new',
            })
            : this.add()

    hidePopup = () =>
        this.setState({
            popup:    false,
            toSelect: null,
        })

    add = () => {
        const { selected } = this.state,
              added = format.copy.array( this.state.added ),
              tmp = format.copy.object( config.defs.template )

        tmp.templateName = 'Новый шаблон ' + this.findNextIndex( added )
        tmp.templateId = format.generate.guid()
        tmp.groupName = selected

        added.push( tmp )

        this.setState({
            added,
            list: this.withCreated( added, this.props.templates.list ),
        })

        this.setTemplate( tmp )
    }

    removeDraft = ( id ) => {
        const { templates } = this.props,
              added = format.copy.array( this.state.added ),
              index = added.indexOf(
                  added.find(( i ) => i.templateId === id || i.id === id )
              )

        added.splice( index, 1 )

        this.setState({
            added,
            list: this.withCreated( added, templates.list ),
        })
    }

    removeTemplate = ( id ) => this.props.removeTemplate( id )

    remove = () => {
        const { added, current } = this.state

        added.find(( i ) => i.templateId === current.templateId )
            ? this.removeDraft( current.templateId )
            : this.removeTemplate( current.templateId )

        this.setTemplate( null )
    }

    found = ( data, index ) =>
        data.find(( item ) => item.templateName.indexOf( index ) > -1 )

    findNextIndex = ( data ) => {
        let i = 1

        while ( this.found( data, i )) {
            i++
        }

        return i
    }

    changed = () =>
        this.setState({ changed: true, })

    reset = () =>
        this.setState({ changed: false, })

    save = ( data ) => {
        if ( data.__new ) {
            data.id = data.templateId
            data.templateId = null
            delete data.__new
            this.removeDraft( data.id )
        }

        this.props.saveTemplate( data )
        this.setTemplate( data )
        this.state.activeOnly && !data.active && this.props.flushTemplate()

        this.setState({
            changed: false,
            reload:  true,
        })
    }

    render () {
        const { list, current, groups, activeOnly, selected } = this.state

        return (
            <div className="section-cards">
                <aside className="section-cards-aside">
                    <Card bordered={false}>
                        <TemplatesList
                            data={list}
                            current={current}
                            selected={selected}
                            groups={groups}
                            activeOnly={activeOnly}
                            toggleActive={this.toggleActive}
                            onGroup={this.setGroup}
                            onSelect={this.askSelect}
                            onAdd={this.askAdd}
                            onFirst={this.setFirst}
                        />
                    </Card>
                </aside>

                <section className="section-cards-content">
                    <Card bordered={false}>
                        {current !== void 0 && (
                            <TemplateCard
                                template={current}
                                groups={groups}
                                onChange={this.changed}
                                onReset={this.reset}
                                onRemove={this.remove}
                                onSave={this.save}
                            />
                        )}
                    </Card>
                </section>

                <DiscardChanges
                    title="Вы действительно хотите перейти на другой шаблон?"
                    ok="Перейти"
                    visible={this.state.popup}
                    onSubmit={this.approveSelect}
                    onCancel={this.hidePopup}
                />
            </div>
        )
    }
}

export default connect( mapStateToProps, allActions )( TemplateEditor )
