/* IMPORT */
import {
    ratingActions,
    requestActions,
    restaurantActions,
    settingsActions,
} from 'services'

/* EXPORT */
export const mapStateToProps = ( state ) => ({
    rating:    state.rating,
    request:   state.request,
    workHours: state.settings.workHours,
})

export const allActions = {
    ...ratingActions,
    ...requestActions,
    ...restaurantActions,
    ...settingsActions,
}
