/* VENDOR */
import React from 'react'

/* APPLICATION */
import { Icon, createIcon } from './Icon'

const svg = ( ) => (
    <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 3.99V16H5V3.99H8L4 0L0 3.99H3Z" fill="#7F8797"/>
    </svg>

)

class Arrow extends Icon {
    render () {
        return svg()
    }
}


export default createIcon(Arrow)
