/* IMPORT */
import { performanceActions, requestActions, settingsActions } from 'services'

/* EXPORT */

export const mapStateToProps = ( state ) => ({
    workHours:   state.settings.workHours,
    report:      state.performance.report,
    plan:        state.performance?.planAndFact?.plan,
    fact:        state.performance?.planAndFact?.fact,
    request:     state.request,
    restaurants: state.restaurants.list,
})

export const allActions = {
    ...performanceActions,
    ...requestActions,
    ...settingsActions,
}
