/* VENDOR */
import React from 'react'

/* APPLICATION */
import { format } from 'tools'

import Template from './Template.js'
import './report-title.scss'

const rows = ( data ) => [ data ],
      columns = () => ({
          title:      ( row ) => <h3>{row.title}</h3>,
          restaurant: ( row ) => row.restaurant,
          dates:      ( row ) => format.strings.range( row.dates ),
      })

class ReportTitle extends Template {
    constructor ( props ) {
        super( props )

        this.state = {
            data:       rows( props.data ),
            columns:    columns( props.data ),
            className:  'print-report-header',
            hideHeader: true,
            hideFooter: true,
        }
    }

    data = ( data ) => rows( data )
    columns = ( data ) => columns( data )
}

export default ReportTitle
