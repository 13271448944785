/* VENDOR */
import React from 'react'

/* APPLICATION */
import { Icon, IconActive, createIcon } from './Icon'

const svg = () => (
    <svg width="12" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10 5.334h-.667V4a3.335 3.335 0 00-6.666 0v1.334H2c-.733 0-1.333.6-1.333 1.333v6.667c0 .733.6 1.333 1.333 1.333h8c.733 0 1.333-.6 1.333-1.333V6.667c0-.733-.6-1.333-1.333-1.333zm-4 6c-.733 0-1.333-.6-1.333-1.334 0-.733.6-1.333 1.333-1.333s1.333.6 1.333 1.333c0 .734-.6 1.334-1.333 1.334zm-2-6V4c0-1.106.893-2 2-2s2 .894 2 2v1.334H4z"
            fill="#000"
            fillOpacity=".6"
        />
    </svg>
)

class Lock extends Icon {
    render () {
        return svg( this.primary, this.secondary )
    }
}

class LockActive extends IconActive {
    render () {
        return svg( this.primary, this.secondary )
    }
}

export default createIcon( Lock, LockActive )
