/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Card }             from 'antd'

/* APPLICATION */
import {
    TasksFullReport,
    TasksOverview,
    TasksSummary,
    ViewTypeSwitch,
    InnerContent,
} from 'components'
import config from 'config'

import './tasks-reports.scss'

class TasksReports extends Component {
    static propTypes = {
        date:         PropTypes.object,
        dates:        PropTypes.array,
        restaurantId: PropTypes.string,

        executors: PropTypes.object,
        assignees: PropTypes.object,
        period:    PropTypes.array,
        manager:   PropTypes.object,
        report:    PropTypes.object,

        setDate:    PropTypes.func,
        setDates:   PropTypes.func,
        onDetails:  PropTypes.func,
        onSelect:   PropTypes.func,
        onAdd:      PropTypes.func,
        onCreateAP: PropTypes.func,
        onSwitch:   PropTypes.func,
    }

    constructor ( props ) {
        super( props )

        this.card = React.createRef()

        this.state = { view: 'shift', }
    }

    update = ( view ) => {
        const { dates, date, setDates, setDate } = this.props

        this.setState({ view })
        this.props.onSwitch( view )
        view === 'period' && setDates( dates )
        view === 'shift' && setDate( date )
    }

    content = () => {
        const { period, manager } = this.props,
              { onDetails } = this.props

        if ( !period || period.length < 1 ) { return null }

        return <TasksOverview data={manager} onDetails={onDetails} />
    }

    columns = ( view ) => {
        switch ( view ) {
            case 'period':
                return config.tables.tasks.period
            case 'shift':
                return config.tables.tasks.date
            default:
                return []
        }
    }

    scroll = ( ref ) => {
        const { period } = this.props

        if ( !period || !ref || !ref.current ) { return }
        if ( period.length < 9 ) { return }

        return { y: ref.current.parentNode.parentNode.offsetHeight - 200 }
    }

    render () {
        const {
            period,
            dates,
            date,
            assignees,
            executors,
            report,
            restaurantId,
            user,
            savingAP
        } = this.props,
              { setDates, setDate, onSelect, onAdd, onCreateAP, onDetails } =
        this.props,
              { view } = this.state,
              tabs = {
                  shift:   'По сменам',
                  period:  'За период',
                  summary: 'Ресторан',
              },
              columns = this.columns( view )

        return (
            <React.Fragment>
                <InnerContent fixed={view !== 'summary'}>
                    <div className={`tabbed-content kfc-tasks-cards tasks-${view}-view`}>
                        <div className="section-cards">
                            <aside className="section-cards-aside" ref={this.card}>
                                <Card bordered={false}>
                                    <ViewTypeSwitch
                                        update={this.update}
                                        tabs={tabs}
                                        current={view}
                                    />
                                    {view === 'summary' ? (
                                        <TasksFullReport
                                            restaurantId={restaurantId}
                                            date={date}
                                            data={report}
                                            user={user}
                                            assignees={assignees}
                                            executors={executors}
                                            savingAP={savingAP}
                                            setDate={setDate}
                                            onCreateAP={onCreateAP}
                                            onDetails={onDetails}
                                        />
                                    ) : (
                                        <TasksSummary
                                            restaurantId={restaurantId}
                                            type={view === 'period' ? 'range' : 'day'}
                                            dates={view === 'period' ? dates : date}
                                            content={view !== 'summary' ? this.content() : null}
                                            columns={columns}
                                            data={period}
                                            executors={executors}
                                            assignees={assignees}
                                            setDates={view === 'period' ? setDates : setDate}
                                            onSelect={onSelect}
                                            onScroll={this.scroll}
                                            onAdd={onAdd}
                                        />
                                    )}
                                </Card>
                            </aside>
                        </div>
                    </div>
                </InnerContent>
            </React.Fragment>
        )
    }
}

export default TasksReports
