/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import moment               from 'moment'
import { Card }             from 'antd'

import FullCalendar      from '@fullcalendar/react'
import dayGridPlugin     from '@fullcalendar/daygrid'
import ruLocale          from '@fullcalendar/core/locales/ru'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin    from '@fullcalendar/timegrid'

/* APPLICATION */
import { Spinner, EventInfo, SubAside } from 'components'

import { format } from 'tools'
import config     from 'config'

import '../CalendarScreen/calendar-screen.scss'

const views = {
    month: 'dayGridMonth',
    week:  'timeGridWeek',
}

const mockSummary = {done: 0, outdated: 0, missed: 0}
const mockInfo = {employeeName: '', totalTasks: 0, datedTasks: 0, userName: ''}

class SubCalendar extends Component {
    static propTypes = {
        restaurant:  PropTypes.number,
        restaurants: PropTypes.array,

        view: PropTypes.string,
        date: PropTypes.object,

        data:    PropTypes.object,
        summary: PropTypes.object,

        onChange: PropTypes.func,
    }

    calendarRef = React.createRef()

    constructor ( props ) {
        super( props )
        this.state = {
            popup: false,
            info:  false,

            evnt:     null,
            position: null,

            raw: null,

            events: null,
            data:   null,
        }
    }

    componentDidMount () {
        const { data } = this.props

        data && this.parseData( this.props )

        document.addEventListener( 'mouseup', this.dragend )
    }

    componentWillUnmount () {
        document.removeEventListener( 'mouseup', this.dragend )
    }

    componentDidUpdate (prevProps) {
        const { data, view, date } = this.props;

        if (data !== prevProps.data) {
            this.parseData(this.props);
        }
        if (view !== prevProps.view) {
            this.setView(this.props);
        }
        if (date !== prevProps.date) {
            this.setDate(this.props.date);
        }
    }

    setDate = ( date ) => this.getApi().gotoDate( date.format( config.format.dayApi ))

    setView = ( props ) => {
        this.getApi().changeView( views[ props.view ])
        this.parseData( props )
    }

    getApi = () => this.calendarRef.current.getApi()

    parseData = ( props ) => {
        if ( !props.data ) {
            this.setState({ events: null, })
            return
        }

        if ( props.view === 'week' ) {
            this.parseWeekData( props.data )
        } else {
            this.parseMonthData( props.data )
        }
    }

    getClassNames = ( event ) => {
        const res = []

        if ( moment( event.startedDate ).isBefore( moment().startOf( 'day' ))) {
            res.push( 'fc-e-past' )

            if ( !event.done ) {
                res.push( 'fc-e-missed' )
            }
        }

        if ( event.done ) {
            res.push( 'fc-e-done' )
        }

        return res
    }

    parseWeekData = ( data ) => {
        const res = []

        data?.tasks.forEach(( event ) => {
            const start = moment( event.startedDate ),
                  end = moment( event.completeDate )

            event.start = start.toDate()
            event.end = end.subtract( 1, 'minute' ).toDate()
            end.diff( start, 'minutes' ) < 29 &&
        ( event.end = moment( start ).add( 29, 'minutes' ).toDate())

            event.classNames = this.getClassNames( event )

            res.push({ ...event })
        })

        this.setState({
            data:   res,
            events: res,
        })
    }

    parseMonthData = ( data ) => {
        const dates = [],
              pre = {},
              res = []

        data?.tasks?.forEach(( event ) => {
            const m = moment( event.startedDate ),
                  date = m.format( 'YYYY-MM-DD' )

            if ( !dates.includes( date )) {
                pre[ date ] = []
                dates.push( date )
            }

            pre[ date ].push( event )
        })

        dates.forEach(( date ) => {
            const classNames = []

            let count = 0,
                duration = 0,
                missed = 0,
                m = moment( date ),
                today = moment()

            pre[ date ].forEach(( event ) => {
                count++
                duration += event.duration
                !event.done && missed++
            })

            if ( m.isBefore( today.startOf( 'day' ))) {
                classNames.push( 'fc-e-past' )
            }

            res.push({
                title:  format.strings.clearCount( count, [ 'задача', 'задачи', 'задач' ]),
                date,
                classNames,
                weight: 0,
            })

            if ( m.isAfter( today.startOf( 'day' )) || m.isSame( today, 'day' )) {
                res.push({
                    title:      format.extract.duration( duration, true ),
                    date,
                    classNames: [ 'fc-e-summary' ],
                    weight:     1,
                })
            }

            if ( m.isBefore( today.startOf( 'day' )) && missed > 0 ) {
                res.push({
                    title: format.strings.clearCount( missed, [
                        'не выполнена',
                        'не выполнены',
                        'не выполнено',
                    ]),
                    date,
                    classNames: [ 'fc-e-past', 'fc-e-summary' ],
                    weight:     1,
                })
            }
        })

        this.setState({
            events: res,
            data:   res,
        })
    }

    close = () =>
        this.setState({
            popup: false,
            info:  false,
            evnt:  null,
        })

    headerDate = ( date ) =>
        moment( date ).format( 'D MMMM, ' ) + moment( date ).format( 'ddd' ).toUpperCase()

    render () {
        const { events, evnt, info, position } = this.state,
              { data, view, restaurant, restaurants, date } = this.props,
              cls = [ 'section-cards calendar-screen' ],
              wheight = window.innerHeight * 0.65,
              mdate = moment( date )

        return (
            <div className={cls.join( ' ' )}>
                <aside className="section-cards-aside">
                    <Card bordered={false}>
                        {data ? (
                            <SubAside
                                date={mdate}
                                current={restaurant}
                                restaurants={restaurants}
                                summary={data.summary}
                                user={data.info}
                                onSelect={this.props.onChange}
                            />
                        ) : this.props.isLoading ? (
                            <Spinner />
                        ) : <SubAside
                          date={mdate}
                          current={restaurant}
                          restaurants={restaurants}
                          summary={mockSummary}
                          user={mockInfo}
                          onSelect={this.props.onChange}
                        />}
                    </Card>
                </aside>

                <section className="section-cards-content" style={{ height: wheight }}>
                    <Card
                        bordered={false}
                        className={'calendar-container fc-view-' + view}
                    >
                        {this.props.isLoading && (
                            <div className="calendar-cover">
                                <Spinner />
                            </div>
                        )}
                        <FullCalendar
                            ref={this.calendarRef}
                            defaultView={views[ view ]}
                            plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin ]}
                            droppable={false}
                            allDaySlot={false}
                            eventStartEditable={false}
                            locale={ruLocale}
                            height={() => wheight}
                            contentHeight={() => wheight}
                            eventOrder="weight"
                            header={{
                                left:   '',
                                center: '',
                                right:  '',
                            }}
                            dragRevertDuration={0}
                            columnHeaderFormat={view === 'month' && { weekday: 'long' }}
                            columnHeaderText={view === 'week' && this.headerDate}
                            slotLabelFormat={{
                                hour:           'numeric',
                                minute:         '2-digit',
                                omitZeroMinute: false,
                                meridiem:       'none',
                            }}
                            events={events ?? []}
                            eventOverlap={view === 'month'}
                        />
                    </Card>
                </section>

                <EventInfo
                    hideActions
                    visible={info}
                    event={evnt}
                    position={position}
                    onClose={this.close}
                />
            </div>
        )
    }
}

export default SubCalendar
