/* IMPORT */
import { planningActions, requestActions, turnoverActions } from 'services'

/* EXPORT */

export const mapStateToProps = ( state ) => ({
    request:         state.request,
    report:          state.planning.report,
    length:          state.user.restaurant.grantedRestaurants.length,
    overview:        state.planning.overview,
    deep:            state.planning.deep,
    error:           state.planning.error,
    user:            state.user,
    turnoverSummary: state.turnover
})

export const allActions = {
    ...planningActions,
    ...requestActions,
    ...turnoverActions,
}
