/* VENDOR */
import React, { useState, useEffect } from 'react';
import moment, { Moment } from 'moment';
import { Form, Modal, Col, Row, TimePicker, Button } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { RangePickerTimeProps } from 'antd/lib/date-picker/generatePicker';
import ruRU from 'antd/es/date-picker/locale/ru_RU';

/* APPLICATION */
import { FormField } from 'components';
import { format } from 'tools';
import config from 'config';
import {locale} from '../../../config/ui';

interface EventProps {
    title: string;
    extendedProps: {
        _duration: string;
    };
}

interface AddEventProps {
    visible: boolean;
    date: Moment | null;
    event: EventProps | null;
    onSubmit: (values: any) => void;
    onCancel: () => void;
}

const AddEvent: React.FC<AddEventProps> = ({ visible, date, event, onSubmit, onCancel }) => {
    const [ form ] = Form.useForm<FormInstance>();
    const [ timeStart, setTimeStart ] = useState<Moment>(
        date ? (date.isSame(moment(), 'day') ? moment().add(1, 'hour').set({ minutes: 0 }) : date) : moment()
    );
    const [ isTimePickerOpen, setTimePickerOpen ] = useState(false);

    useEffect(() => {
        if (date) {
            setTimeStart(date.isSame(moment(), 'day') ? moment().add(1, 'hour').set({ minutes: 0 }) : date);
        }
    }, [ date ]);

    useEffect(() => {
        if (date) {
            const initialTime = date.isSame(moment(), 'day') ? moment().add(1, 'hour').set({ minutes: 0 }) : date;
            setTimeStart(initialTime);
            //@ts-ignore
            form.setFieldsValue({ timeStart: initialTime });
        }
    }, [ date, form ]);

    const getDisabledTime = (): RangePickerTimeProps<Moment>['disabledTime'] => {
        const today = moment();

        if (date && date.isSame(today, 'day')) {
            const disabledHours = today.minutes() > 30
                ? format.generate.range(0, today.hours() + 1)
                : format.generate.range(0, today.hours());

            const disabledMinutes = (hour: number) => {
                return hour === today.hours() ? format.generate.range(0, today.minutes() + 1) : [];
            };

            return {
                // @ts-ignore
                disabledHours: () => disabledHours,
                disabledMinutes: (hour: number) => disabledMinutes(hour),
            };
        }

        return {
            // @ts-ignore
            disabledHours: () => [],
            disabledMinutes: () => [],
        };
    };

    const handleTimeChange = (time: Moment | null) => {
        //@ts-ignore
        setTimeStart(time);
        //@ts-ignore
        form.setFieldsValue({ timeStart: time });
    };

    const handleOk = (time: Moment | null) => {
        if (time) {
            const formattedTime = time.add(1, 'hour').set({ minutes: 0 });
            //@ts-ignore
            setTimeStart(formattedTime);
            //@ts-ignore
            form.setFieldsValue({ timeStart: formattedTime });
        }
    };

    const preSubmit = () => {
        form.validateFields()
            .then((values) => {
                const formattedTimeStart = timeStart;
                onSubmit({
                    ...values,
                    timeStart: formattedTimeStart,
                });
                form.resetFields();
            })
            .catch((errorInfo) => {
                console.error('Ошибка при валидации полей:', errorInfo);
            });
    };

    if (!event || !date) {
        return null;
    }

    return (
        <Modal
            title={event.title}
            className="kfc-popup no-footer kfc-calendar-popup"
            centered={true}
            open={visible}
            okText="Запланировать"
            okButtonProps={{ style: { display: 'none' } }}
            onOk={preSubmit}
            cancelButtonProps={{ style: { display: 'none' } }}
            onCancel={onCancel}
        >
            <p className="text-helper">
                {date.format(config.format.onlyDayView)}, {date.format('ddd').toUpperCase()} • {event.extendedProps._duration}
            </p>
            <Form form={form} {...config.ui.wideForm}>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            name="timeStart"
                            rules={[ { required: true, message: 'Пожалуйста, выберите время' } ]}
                        >
                            <TimePicker
                                value={timeStart}
                                onChange={handleTimeChange}
                                open={isTimePickerOpen}
                                onSelect={handleTimeChange}
                                onOpenChange={setTimePickerOpen}
                                //@ts-ignore
                                locale={locale}
                                minuteStep={15}
                                format="HH:mm"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item>
                            <Button type='primary' onClick={preSubmit}>
                                Запланировать
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default AddEvent;
