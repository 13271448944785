/* VENDOR */
import React from 'react'

/* APPLICATION */
import { Icon, IconActive, createIcon } from './Icon'

const svg = ( primary ) => (
    <svg width="12" height="6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.008 2.25H.75A.752.752 0 0 0 0 3c0 .412.338.75.75.75h8.258v1.342c0 .338.405.503.637.263l2.085-2.093a.385.385 0 0 0 0-.532L9.645.637C9.413.397 9.008.57 9.008.9v1.35z"
            fill={primary}
        />
    </svg>
)

class ArrowRight extends Icon {
    render () {
        return svg( this.primary, this.secondary )
    }
}

class ArrowRightActive extends IconActive {
    render () {
        return svg( this.primary, this.secondary )
    }
}

export default createIcon( ArrowRight, ArrowRightActive )
