/* VENDOR */
import React, { Component }                               from 'react'
import PropTypes                                          from 'prop-types'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Row, Col, List, Divider, Input, Checkbox, Spin } from 'antd'
import { Scrollbars }                                     from 'react-custom-scrollbars'
import moment                                             from 'moment'

import { Modal } from 'antd'

/* APPLICATION */
import { FormField, Spinner } from 'components'
import { format }             from 'tools'
import config                 from 'config'

import './create-action-plan.scss'

const { Search } = Input

class CreateActionPlan extends Component {
    static propTypes = {
        data: PropTypes.array,

        assignees: PropTypes.object,
        executors: PropTypes.object,

        onSubmit: PropTypes.func,
        onCancel: PropTypes.func,

        visible: PropTypes.bool,
    }

    constructor ( props ) {
        super( props )

        this.state = {
            deviations:     false,
            savedSelection: [],
            selection:      [],
            search:         '',
        }
    }

    componentDidUpdate ( prevProps ) {
        const { visible } = this.props

        if ( !visible && visible !== prevProps.visible ) {
            setTimeout(() => {
                this.setState({ deviations: false, selection: [] })
                this.props.form.resetFields()
            }, 200 )
        }

    }

    fields = () => [
        {
            field: 'taskName',
            label: 'Что сделать',
            type:  'text',
            rules: [ config.rules.required ],
        },
        {
            field:         'dueTime',
            label:         'Выполнить до',
            type:          'datetime',
            disabledHours: () =>
                this.props.form.getFieldValue( 'dueTime' ).isAfter( moment().endOf( 'day' ))
                    ? []
                    : format.generate.range( 0, moment().add( 1, 'hour' ).hour()),
            value:        moment().add( 1, 'hour' ),
            disabledDate: ( current ) => current && current < moment(),
            rules:        [ config.rules.required ],
        },
        {
            field:   'assigneeName',
            label:   'Ответственный',
            type:    'select',
            options: this.props.assignees,
            search:  ( search, opt ) =>
                format.find.strInProp( opt.props, 'children', search ),
            rules: [ config.rules.required ],
        },
        {
            field: 'isPhotoRequired',
            label: 'Сфотографировать результат',
            type:  'checkbox',
        },
        {
            field: '_add',
            label: 'Дополнительные сведения',
            type:  'checkbox',
        },
        {
            field:  'executorName',
            label:  'Исполнитель',
            type:   'select',
            search: ( search, opt ) =>
                format.find.strInProp( opt.props, 'children', search ),
            options: this.props.executors,
            hide:    ( data ) => !data.form.getFieldValue( '_add' ),
        },
        {
            field: 'taskDescription',
            label: 'Комментарий',
            type:  'text',
            hide:  ( data ) => !data.form.getFieldValue( '_add' ),
        },
        {
            field: 'deviationsRow',
            type:  'row',
            items: [
                {
                    field:   'deviationsLink',
                    label:   <span className="list-icon">Связанные отклонения</span>,
                    type:    'link',
                    handler: () =>
                        this.setState({ deviations: true, }),
                    span: 14,
                },
                {
                    field:  'deviationsInfo',
                    type:   'info',
                    span:   10,
                    render: () =>
                        this.state.selection.length > 0 ? (
                            <span className="repeat-sign">
                                {this.state.selection.length} выбрано
                            </span>
                        ) : null,
                },
            ],
        },
    ]

    row = ( cfg ) => {
        const res = []

        let hidden = false

        if ( cfg.hide ) {
            if ( typeof cfg.hide === 'function' ) {
                if ( cfg.hide()) {
                    return null
                }
            } else {
                return null
            }
        }

        if ( cfg.hidden ) {
            hidden = typeof cfg.hidden === 'function' ? cfg.hidden() : cfg.hidden
        }

        cfg.items.map(( item ) =>
            res.push(
                <Col span={item.span} key={item.field}>
                    {this.field( item )}
                </Col>
            )
        )

        return (
            <Row
                gutter={24}
                key={cfg.field}
                className={hidden ? `${cfg.field} fi-hidden` : cfg.field}
            >
                {res}
            </Row>
        )
    }

    field = ( cfg ) => {
        if ( cfg.type === 'row' ) {
            return this.row( cfg )
        }

        return (
            <FormField
                {...cfg}
                value={cfg.value}
                key={cfg.field}
                form={this.props.form}
            />
        )
    }

    preSubmit = () => {
        this.props.form.validateFieldsAndScroll(( err, values ) => {
            const { selection } = this.state

            if ( !err ) {
                this.props.onSubmit({
                    ...values,
                    selection,
                })
            }
        })
    }

    cancel = () => {
        const { onCancel, form } = this.props

        form.resetFields()
        onCancel()
    }

    saveDeviations = () =>
        this.setState({
            deviations:     false,
            savedSelection: format.copy.array( this.state.selection ),
        })

    cancelDeviations = () =>
        this.setState({
            deviations: false,
            selection:  [],
        })

    search = ( e ) => {
        const str = e.target.value

        if ( str.length >= 2 ) {
            this.scrolls && this.scrolls.scrollToTop()
        }

        this.setState({ search: str })
    }

    toggle = ( item ) => {
        return () => {
            const { selection } = this.state,
                  found = this.getAdded( item )

            found
                ? selection.splice( selection.indexOf( found ), 1 )
                : selection.push( item.id )

            this.setState({ selection })
        }
    }

    getAdded = ( item ) => this.state.selection.find(( d ) => d === item.id )

    item = ( item ) => {
        const cls = [ 'deviation-item' ]

        return (
            <List.Item className={cls.join( ' ' ).trim()}>
                <Checkbox checked={!!this.getAdded( item )} onChange={this.toggle( item )}>
                    <span className="item-title">{item.name}</span>
                </Checkbox>
            </List.Item>
        )
    }

    list = () => {
        const { data } = this.props,
              { search, savedSelection } = this.state,
              filtered = data
                  .filter(( item ) =>
                      item.name.split( ' ' ).reduce(( found, word ) => {
                          return (
                              found || word.toLowerCase().indexOf( search.toLowerCase()) > -1
                          )
                      }, false )
                  )
                  .sort(
                      ( a, b ) => savedSelection.indexOf( b.id ) - savedSelection.indexOf( a.id )
                  )

        return (
            <React.Fragment>
                <div className="filter-container">
                    <Search
                        placeholder="Поиск по ключевому слову"
                        value={search}
                        onChange={this.search}
                    />
                </div>

                <Divider />

                {data ? (
                    <div className="deviations-list">
                        <Scrollbars
                            {...config.ui.scrolls}
                            ref={( node ) => ( this.scrolls = node )}
                        >
                            <List
                                size="small"
                                dataSource={filtered}
                                renderItem={this.item}
                                locale={{ emptyText: 'Ничего не найдено' }}
                            />
                        </Scrollbars>

                        <Divider />
                    </div>
                ) : (
                    <Spinner />
                )}
            </React.Fragment>
        )
    }

    render () {
        const { visible, saving } = this.props,
              { deviations } = this.state,
              disabledBtn = { disabled: saving }

        return (
            <Modal
                title={deviations ? 'Связанные отклонения' : 'Новая задача в рамках AP'}
                className="kfc-popup create-action-plan-popup"
                centered={true}
                open={visible}
                okText={deviations ? 'Готово' : 'Создать'}
                onOk={deviations ? this.saveDeviations : this.preSubmit}
                cancelText="Отменить"
                onCancel={deviations ? this.cancelDeviations : this.cancel}
                okButtonProps={disabledBtn}
                cancelButtonProps={disabledBtn}
            >
                <Spin spinning={saving}>
                    <Form
                        name='CreateActionPlan'
                        {...config.ui.wideForm}
                    >
                        {deviations ? (
                            this.list()
                        ) : (
                            <div className="form-inner">{this.fields().map( this.field )}</div>
                        )}
                    </Form>
                </Spin>

            </Modal>
        )
    }
}

export default CreateActionPlan
