/* VENDOR */
import axios from 'axios'

/* APPLICATION */
import { format }  from 'tools'
import config      from 'config'
import { request } from '../../tools/format/check.js'

const service = 'dashboard',
      ext = [ 'dateStart', 'dateEnd', 'franchiseeGroupCodes', 'userId', 'zoneId' ]

export const getTurnoverPlanAndFact = ( params ) =>
    format.generate.get( service, 'restaurants-rating/turnover-plan-fact', params )
export const getAllTurnover = ( params ) =>
    format.generate.get( service, 'restaurants-rating/turnover', params )
export const getTurnover = ( params ) =>
    format.generate.get( service, 'restaurants-rating/turnover/page', params )
export const getAllAverageCheck = ( params ) =>
    format.generate.get( service, 'restaurants-rating/average-check', params )
export const getAverageCheck = ( params ) =>
    format.generate.get( service, 'restaurants-rating/average-check/page', params )
export const getPerformancePlanAndFact = ( params ) =>
    format.generate.get( service, 'restaurants-rating/performance-plan-fact', params )
export const getPerformance = ( params ) =>
    format.generate.get( service, 'restaurants-rating/performance/page', params )

export const searchPerformance = ( body ) =>
    axios.post(
        config.api.dashboard + 'performance/search',
        body,
        config.api.options
    )

export const searchPerformancePagination = ( body ) =>
    axios.post(
        config.api.dashboard + 'performance/search',
        body,
        {
            ...config.api.options,
            params: { page: body.page },
        }
    )

export const getSummaryPerformance = ( body ) =>
    axios.post(
        config.api.dashboard + 'performance/summary',
        body,
        config.api.options
    )

export const getAllServiceTime = ( params ) =>
    format.generate.get( service, 'restaurants-rating/service-time', params )
export const getServiceTime = ( params ) =>
    format.generate.get( service, 'restaurants-rating/service-time/page', params )

export const getPartners = ( body ) =>
    axios.post(
        config.api.restaurant + 'restaurants/restaurants-filters/search',
        body,
        config.api.options
    )
export const getCategoriesRating = ( params ) =>
    format.generate.get(
        service,
        '/dish-categories',
        params,
        // null,
        // ext
    )
export const getCategoryManagers = ( params ) =>
    format.generate.get(
        service,
        '/restaurants-rating/categories/' + params.categoryId + '/linked-users',
        params,
        null,
        ext
    )
export const getManagerRestaurants = ( params ) =>
    format.generate.get(
        service,
        '/dish-categories/' + params.categoryId + '/restaurants',
        params,
    )

export const updateCategoryRating = ( body ) =>
    axios.post(
        config.api.restaurant + 'target/categories',
        body,
        config.api.options
    )

export const getOverall = ( params ) =>
    format.generate.get( 'business', 'restaurants/rating', params )

export const updateOverall = ( body ) =>
    axios.post(
        config.api.business + 'restaurants/rating',
        body,
        config.api.options
    )
