export const required = {
    transform: ( val ) => {
        if ( !val ) { return val }
        if ( typeof val === 'string' ) { return val.trim() }
        return val
    },
    validator: ( rule, val, cb ) => {
        if ( !rule.transform( val )) {
            cb( 'Alarma' )
            return
        }

        cb()
    },
    message: 'Обязательное поле',
}

export const requiredTurnover = {
    transform: ( val ) => {
        if ( !val ) { return val }
        if ( typeof val === 'string' ) { return val.trim() }
        return val
    },
    validator: ( rule, val, cb ) => {
        if ( rule.transform( val ) < 24 ) {
            cb( 'Alarma' )
            return
        }

        cb()
    },
    message: 'Не менее 24',
}

export const resolver = ( cond, msg ) =>
    cond ? Promise.resolve() : Promise.reject( msg )

export const inUse = ( data, current, msg ) => ({
    validator: ( rule, value ) =>
        resolver( data.indexOf( value ) < 0 || value === current, msg ),
})

export const onlyChars = ( include, msg ) => ({
    validator: ( rule, value ) =>
        resolver(
            value.replace( new RegExp( `[${include}]`, 'g' ), '' ).length === 0,
            msg
        ),
})

export const firstChar = ( include, msg ) => ({
    validator: ( rule, value ) =>
        resolver( new RegExp( `[${include}]` ).test( value[ 0 ]), msg ),
})

export const lastChar = ( include, msg ) => ({
    validator: ( rule, value ) =>
        resolver( new RegExp( `[${include}]` ).test( value[ value.length - 1 ]), msg ),
})

export const length = ( min, max, msg, canBeNull ) => ({
    validator: ( rule, value ) =>
        resolver(
            canBeNull
                ? !value
                        ? true
                        : value.length >= min && value.length <= ( max || Infinity )
                : value.length >= min && value.length <= ( max || Infinity ),
            msg
        ),
})

export const contain = ( chars, count, msg, canBeNull ) => ({
    validator: ( rule, value ) =>
        resolver(
            canBeNull
                ? !value
                        ? true
                        : new RegExp( `[${chars}]{${count}}` ).test( value )
                : new RegExp( `[${chars}]{${count}}` ).test( value ),
            msg
        ),
})

export const phoneRule = { 
    pattern: /^\+7[0-9]{10}$/, 
    message: 'Некорректный формат' 
};
