/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import Icon from '@ant-design/icons'

/* APPLICATION */
import {} from 'components'
import './page-controls.scss'

class PageControls extends Component {
    static propTypes = {
        icon:    PropTypes.oneOfType([ PropTypes.func, PropTypes.string ]),
        disable: PropTypes.bool,

        count: PropTypes.number,
        page:  PropTypes.number,
        last:  PropTypes.number,

        onNext: PropTypes.func,
        onPrev: PropTypes.func,
        action: PropTypes.func,
        disabledNext: PropTypes.bool,
        disabledPrev: PropTypes.bool,
    }

    next = () => {
        if (this.props.disabledNext) { 
            return; 
        }
        this.props.onNext &&
            this.props.onNext(Math.min(this.props.last, this.props.page + 1));
    }

    prev = () => {
        if (this.props.disabledPrev) { 
            return; 
        }
        this.props.onNext && this.props.onPrev(Math.max(0, this.props.page - 1));
    }

    render () {
        const { page, last, action, icon, disable, disabledNext, disabledPrev } = this.props,
              cls = [ 'pager-action' ],
              show = action || disable;

        disable && !action && cls.push( 'no-action' );

        if ( last < 1 ) { return null }

        return (
            <div className="controls-pager">
                <span
                    className={
                        'pager-arrow arrow-prev' +
                        ( page && page === 0 ? '' : ' active' ) +
                        ( disabledPrev ? ' disabled' : '' )
                    }
                    onClick={this.prev}
                />
                {show && icon && (
                    <span className={cls.join( ' ' )} onClick={action}>
                        {typeof icon === 'string' ? icon : <Icon component={icon} />}
                    </span>
                )}
                <span
                    className={
                        'pager-arrow arrow-next' +
                        ( page && page === last ? '' : ' active' ) +
                        ( disabledNext ? ' disabled' : '' )
                    }
                    onClick={this.next}
                />
            </div>
        )
    }
}

export default PageControls
