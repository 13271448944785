import moment from 'moment'

import config from 'config'

export const abbr = ( str ) =>
    str
        ? str
            .trim()
            .split( ' ' )
            .map(( w ) => ( w ? w[ 0 ].toUpperCase() : '' ))
            .join( '' )
        : ''

export const capitalize = ( str ) => str[ 0 ].toUpperCase() + str.substr( 1 ).toLowerCase()
export const capitalizeAll = ( str ) => str.split( ' ' ).map( capitalize ).join( ' ' )

export const normalize = ( str ) => capitalizeAll( str.replace( '_', ' ' ))
export const normalizeArray = ( arr ) =>
    arr.map(( item ) => normalize( item )).join( ', ' )

export const dbl = ( num ) => ( num >= 10 ? num : '0' + num )
export const mktime = ( num ) =>
    num % 1
        ? dbl( Math.floor( num )) + ':' + ( num - Math.floor( num )) * 60
        : dbl( num ) + ':00'

export const percent = ( arr, i ) => ( i / ( arr.length - 1 )) * 100
export const percentage = ( num ) => thousand( float( num ), '%' )
export const intPercentage = ( num ) => thousand( Math.round( num ), '%' )
export const realPercentage = ( num ) => thousand( Math.round( num * 100 ), '%' )
export const int = ( num ) => thousand( Math.round( num ))

export const interval = ( start, end ) => start + ' – ' + end

export const dowDate = ( date ) =>
    moment( date ).format( 'D MMMM, ' ) + moment( date ).format( 'ddd' ).toUpperCase()

export const dowDateY = ( date ) =>
    moment( date ).format( 'D MMM YYYY, ' ) +
  moment( date ).format( 'ddd' ).toUpperCase()

export const dowDateR = ( date ) =>
    moment( date ).format( 'ddd' ).toUpperCase() +
  ', ' +
  moment( date ).format( config.format.onlyDayView )

export const dowDateRS = ( date ) =>
    moment( date ).format( 'ddd' ).toUpperCase() +
  ', ' +
  moment( date ).format( config.format.onlyDayViewShort )

export const dowDateRC = ( date ) =>
    moment( date ).format( 'ddd' ).toUpperCase() +
    ', ' +
    moment( date ).format( config.format.day ).toUpperCase()

export const toCamelCase = ( str ) =>
    str
        .split( /[ -]/g )
        .map(( word ) => word[ 0 ].toUpperCase() + word.substr( 1 ).toLowerCase())
        .join( '' )

export const noZeroFloat = ( num, fixed ) => {
    return parseFloat( num ) === parseInt( num )
        ? ( '' + num ).split( '' ).pop() === '.'
                ? num
                : float( num, 0 )
        : float( num, fixed )
}

export const float = ( num, fixed ) => {
    return checkNull( num, ( n ) => {
        if ( typeof num === 'string' ) {
            const isEmptyDot = !num.split( '.' ).pop() || !num.split( ',' ).pop()
            const isSingleDot = num.split( '.' ).length === 2 || num.split( ',' ).length === 2
            if ( isEmptyDot && isSingleDot ) {
                return n
            }
        }

        return parseFloat( n )
            .toFixed(!fixed && fixed !== 0 ? 2 : fixed)
            .replace(/([^.])0+$/, '$1')
            .split( '.' )
            .map(( p, i ) => ( i === 0 ? p : p.substr( 0, 2 )))
            .join( ',' )
    })
}



export const readableTime = ( str ) => {
    const split = str.split( ':' )

    if ( split.length === 2 ) {
        return `${parseInt( split[ 0 ])} ч ${split[ 1 ]} мин`
    }

    return `${parseInt( split[ 0 ])} ч ${split[ 1 ]} мин ${split[ 2 ]} сек`
}

export const clearCount = ( count, variants ) =>
    variants
        ? ( count === 0 ? 'Нет' : count ) + ' ' + ending( count, variants )
        : count

export const count = ( count, variants ) =>
    ' (' + clearCount( count, variants ) + ')'

export const ending = ( num, variants ) => {
    if ( !variants ) {
        return ''
    }

    const inum = parseInt( num ),
          rnum = inum % 100 < 20 ? inum % 100 : inum % 10

    if ( rnum === 0 ) {
        return variants[ 3 ] ? variants[ 3 ] : variants[ 2 ]
    }
    if ( rnum > 4 ) {
        return variants[ 2 ]
    }
    if ( rnum > 1 ) {
        return variants[ 1 ]
    }

    return variants[ 0 ]
}

export const checkNull = ( num, cb, nullSymbol ) => {
    const ns = nullSymbol || config.ui.nullSymbol,
          frmt = cb || (( t ) => t )

    if ( !num && num !== 0 ) {
        return ns
    }

    if ( num === nullSymbol || num === config.ui.nullSymbol ) {
        return ns
    }

    return frmt( num )
}

export const delta = ( num, after, nullSymbol ) => {
    return num > 0
        ? '+' + thousand( num, after, nullSymbol )
        : thousand( num, after, nullSymbol )
}

export const thousand = ( num, after, nullSymbol ) => {
    after = after || ''
    num = num && num.toString().indexOf( '.' ) > -1 ? float( num ) : num
    return checkNull(
        num,
        ( num ) => num.toString().replace( /\B(?=(\d{3})+(?!\d))/g, ' ' ) + after,
        nullSymbol
    )
}

export const seconds = ( sec ) => {
    if ( !sec && sec !== 0 && sec !== '0' ) {
        return config.ui.nullSymbol
    }

    const sign = sec === 0 ? 1 : sec / Math.abs( sec ),
          minutes = Math.floor( Math.abs( sec ) / 60 ),
          seconds = dbl( Math.abs( sec ) - minutes * 60 )

    return ( sign < 0 ? '-' : '' ) + minutes + ':' + seconds
}

export const percByKey = ( arr, i, key ) => {
    const total = arr[ arr.length - 1 ][ key ] - arr[ 0 ][ key ]
    return (( arr[ i ][ key ] - arr[ 0 ][ key ]) / total ) * 100
}

export const range = ( dates, short ) => {
    const delim = ' — ',
          start = dates[ 0 ],
          end = dates[ 1 ],
          shortView = config.format.dayView.replace( 'MMMM', 'MMM' )

    if ( start.isSame( end, 'day' )) {
        return start.format( config.format.dayView )
    }

    if ( start.isSame( end, 'month' )) {
        return start.format( 'D' ) + delim + end.format( config.format.dayView )
    }

    if ( start.isSame( end, 'year' )) {
        return short
            ? start.format( config.format.onlyDayViewShort ) +
          delim +
          end.format( shortView )
            : start.format( config.format.onlyDayView ) +
          delim +
          end.format( config.format.dayView )
    }

    return short
        ? start.format( shortView ) + delim + end.format( shortView )
        : start.format( config.format.dayView ) +
        delim +
        end.format( config.format.dayView )
}

export const join = ( arr ) =>
    arr.map(( item ) => item.toLowerCase().replace( / +/g, '-' )).join( '-' )

export const joinAnd = ( arr, key ) =>
    arr
        .slice( 0, -1 )
        .map(( item ) => item[ key ])
        .join( ', ' ) +
  ' и ' +
  arr.slice( -1 ).map(( item ) => item[ key ])

export const toFixed = ( num, fixed ) => {
    fixed = fixed || 0
    fixed = Math.pow( 10, fixed )
    return ( Math.floor( num * fixed ) / fixed ).toString()
}

export const removeGetParams = ( str ) => str.split( '?' )[ 0 ]

export const parseJwt = ( token ) => {
    if ( !token ) { return {} }

    const base64Url = token.split( '.' )[ 1 ]
    const base64 = base64Url.replace( /-/g, '+' ).replace( /_/g, '/' )
    const jsonPayload = decodeURIComponent( window.atob( base64 ).split( '' ).map( function ( c ) {
        return '%' + ( '00' + c.charCodeAt( 0 ).toString( 16 )).slice( -2 )
    }).join( '' ))

    return JSON.parse( jsonPayload )
}

export const num2str = ( n, textForms ) => {
    n = Math.abs( n ) % 100
    let n1 = n % 10
    if ( n > 10 && n < 20 ) {
        return textForms[ 2 ]
    }
    if ( n1 > 1 && n1 < 5 ) {
        return textForms[ 1 ]
    }
    if ( n1 == 1 ) {
        return textForms[ 0 ]
    }
    return textForms[ 2 ]
}
