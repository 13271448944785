/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Link }             from 'react-router-dom'

/* APPLICATION */
import { CurrencySwitch } from 'components'

import './turnover-controls.scss'

class TurnoverControls extends Component {
    static propTypes = {
        request: PropTypes.object,
        update:  PropTypes.func,
    }

    render () {
        const { update, request } = this.props

        return (
            <div className="card-controls turnover-controls">
                <CurrencySwitch update={update} request={request} showGrowing={true} />
                <div className="control-links">
          Отчёт
                    <Link to="/dashboard/turnover">по товарообороту</Link>,
                    <Link to="/dashboard/average-check">по среднему чеку</Link>
                </div>
            </div>
        )
    }
}

export default TurnoverControls
