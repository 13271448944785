/* VENDOR */
import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { Card }             from 'antd'
import moment               from 'moment'

/* APPLICATION */
import { ReportTable, AdminTasksControls, ClassicPager } from 'components'
import { format }                                        from 'tools'
import config                                            from 'config'

import { mapStateToProps, allActions } from './connector'
import './tasks-editor.scss'

const statuses = {
    CREATED:   'Created',
    COMPLETED: 'Completed',
    TODO:      'Todo',
    PAUSED:    'Paused',
    CANCELED:  'Canceled',
    OUTDATED:  'Outdated',
}

class TasksEditor extends Component {
    constructor ( props ) {
        super( props )
        this.state = {
            search:     '',
            restaurant: 'all',
            group:      'all',
            status:     'all',
            dates:      [ moment(), moment() ],

            restaurants: props.restaurants ? props.restaurants.content : null,
            groups:      props.groups ? props.groups.content : null,
            employees:   props.staff ? props.staff.content : null,

            restSearch:   '',
            groupsSearch: '',

            tasks: null,
            page:  0,
            total: 0,
        }
    }

    componentDidMount () {
        this.load()
        this.getRestaurants()
        this.getGroups()
        this.props.tasks && this.setTasks( this.props.tasks )

        const selectElements = document.querySelectorAll('.ant-select-borderless');
        selectElements.forEach(el => el.classList.remove('ant-select-borderless'));
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { tasks, restaurants, groups, staff } = this.props

        tasks !== nextProps.tasks && this.setTasks( nextProps.tasks )
        staff !== nextProps.staff && this.setStaff( nextProps.staff )
        restaurants !== nextProps.restaurants &&
      this.setRestaurants(
          nextProps.restaurants && nextProps.restaurants.content.length
              ? nextProps.restaurants.content
              : null
      )
        groups !== nextProps.groups &&
      this.setGroups(
          nextProps.groups && nextProps.groups.length ? nextProps.groups : null
      )
    }

    setRestaurants = ( restaurants ) => this.setState({ restaurants })
    setGroups = ( groups ) => this.setState({ groups })

    getRestaurants = () => this.props.fetchRestaurantsList( this.state.restSearch )
    getGroups = () => this.props.fetchGroupsList( this.state.groupsSearch )

    load = () => {
        const { group, status, search, restaurant, dates, page } = this.state,
              params = {
                  search,
                  page,
                  dateStart: dates[ 0 ].format( config.format.dayAPI ),
                  dateEnd:   dates[ 1 ].format( config.format.dayAPI ),
              }

        restaurant !== 'all' && ( params.restaurantId = restaurant )
        group !== 'all' && ( params.group = group )
        status !== 'all' && ( params.status = status )

        this.props.fetchAdminTasks( params )
    }

    setTasks = ( data ) => {
        if ( !data ) {
            this.setState({ tasks: null })
            return
        }

        const tasks = format.generate.noPager( data.content?.map( this.prepare ))

        if ( data.content.length > 0 ) {
            this.fetchRestaurantsData( data.content )
        }

        this.setState({
            tasks,
            page:  data.number,
            total: data.totalPages,
        })
    }

    setStaff = ( data ) => {
        if ( !data ) {
            this.setState({ employees: null, })
            return
        }

        this.setState({ employees: data.content, })
    }

    fetchRestaurantsData = ( tasks ) => {
        const res = tasks.reduce(( all, task ) => [ ...all, task.restaurantId ], []),
              filtered = res.filter(( r, i ) => r && res.indexOf( r ) === i )

        filtered.length > 0 && this.props.getRestaurantsData( filtered )
    }

    getStatus = ( task ) => {
        if ( statuses[ task.status ]) { return statuses[ task.status ] }
        return task.status
    }

    getSuitableDate = ( task ) => {
        if ( task.finishedDate ) {
            return moment( task.finishedDate ).format( config.format.dateView )
        }

        return null
    }

    prepare = ( task ) => {
        const { restaurants } = this.state,
              found = restaurants
                  ? restaurants.find(( r ) => r.restaurantId === task.restaurantId )
                  : null

        return {
            ...task,
            restaurant: found
                ? found.restaurantName
                : task.restaurantId
                    ? task.restaurantId
                    : 'Не назначен',
            status: this.status( task ),
            title:  this.title( task ),
            creator:
        task.creator && ( task.creator.scheduleName || task.creator.fullName ),
            assignee:
        task.assignee && ( task.assignee.scheduleName || task.assignee.fullName ),
            executor:
        task.executor && ( task.executor.scheduleName || task.executor.fullName ),
        }
    }

    status = ( task ) => (
        <span>
            {this.getStatus( task )}
            <br />
            {this.getSuitableDate( task )}
        </span>
    )

    title = ( task ) => (
        <span>
            {task.title}
            <br />
            {task.description}
        </span>
    )

    reload = ( data ) => {
        this.props.flushAdminTasks()

        data.page = data.page || 0

        this.setState( data, this.load )
    }

    onDates = ( dates ) => this.reload({ dates })
    onSearch = ( search ) => this.reload({ search })

    onRestaurant = ( restaurant ) => {
        this.reload({ restaurant })
        this.listRestaurants( restaurant.restaurantName )
    }

    listRestaurants = ( restSearch ) =>
        this.setState({ restSearch }, this.getRestaurants )

    onGroup = ( group ) => {
        this.reload({ group })
        this.listGroups( group.categoryName )
    }

    listGroups = ( groupsSearch ) =>
        this.setState({ groupsSearch }, this.getGroups )

    onStatus = ( status ) => this.reload({ status })
    onPage = ( page ) => this.reload({ page })

    change = ( original, key, value ) => {
        const tasks = format.copy.array( this.state.tasks.content ),
              found = tasks.find(( t ) => t.id === original.id ),
              index = tasks.indexOf( found ),
              raw = this.props.tasks.content.find(( t ) => t.id === original.id )

        if ( found ) {
            found[ key ] = value
            tasks[ index ] = found

            this.setState({ tasks: format.generate.noPager( tasks ), })

            let executor = found.executor,
                assignee = found.assignee

            switch ( key ) {
                case 'executor':
                    assignee = raw && raw.assignee ? raw.assignee.userId : null
                    break
                case 'assignee':
                    executor =
            raw && raw.executor
                ? raw.executor.userId ||
                raw.executor.scheduleName ||
                raw.executor.fullName
                : null
                    break
                default:
                    break
            }

            this.props.updateAdminTask({
                id:             found.id,
                restaurantId:   original.restaurantId,
                executorUserId: executor,
                assigneeUserId: assignee,
            })
        }
    }

    getStaff = ( row, col ) => {
        if ( !row.restaurantId ) {
            this.setState({ employees: [], })
            return
        }

        this.props.flushRestaurantStaff()

        this.props.fetchRestaurantStaff({
            restaurantId: row.restaurantId,
            search:       '',
            employeeType: col.dataIndex === 'executor' ? 'all' : 'assignee',
        })
    }

    cellSearch = ( row, col ) => {
        return ( search ) => {
            if ( !row.restaurantId ) {
                this.setState({ employees: [], })
                return
            }

            this.props.flushRestaurantStaff()

            this.props.fetchRestaurantStaff({
                restaurantId: row.restaurantId,
                employeeType: col.dataIndex === 'executor' ? 'all' : 'assignee',
                search,
            })
        }
    }

    render () {
        const { search, restaurant, group, status, dates, tasks, page, total } =
        this.state,
              { restaurants, groups, employees } = this.state,
              { restData } = this.props,
              select = {
                  restaurants: format.generate.options(
                      restData,
                      'factsNumber',
                      'restaurantName'
                  ),
                  employees: employees
                      ? format.generate.options(
                          employees,
                          ( item ) => item.userId || item.scheduleName || item.fullName,
                          ( item ) => item.scheduleName || item.fullName
                      )
                      : 'spin',
              }

        return (
            <Card bordered={false} className="tasks-editor">
                <AdminTasksControls
                    search={search}
                    restaurant={restaurant || 'Ресторан'}
                    group={group || 'Группа'}
                    status={status}
                    dates={dates}
                    restaurants={restaurants}
                    groups={groups}
                    statuses={statuses}
                    onListRestaurants={this.listRestaurants}
                    onListGroups={this.listGroups}
                    onSearch={this.onSearch}
                    onRestaurant={this.onRestaurant}
                    onGroup={this.onGroup}
                    onStatus={this.onStatus}
                    onDates={this.onDates}
                />
                <div className="report-table">
                    <ReportTable
                        rowKey="id"
                        data={tasks}
                        columns={config.tables.admin.tasks}
                        select={select}
                        cellActions={{
                            focus:  this.getStaff,
                            search: this.cellSearch,
                        }}
                        onChange={this.change}
                    />
                </div>
                <ClassicPager page={page} total={total} onChange={this.onPage} />
            </Card>
        )
    }
}

export default connect( mapStateToProps, allActions )( TasksEditor )
