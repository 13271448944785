import React, { useEffect, useState } from 'react'
import { FormField }                  from 'components'
import { Card, Col, Form, Row, Spin } from 'antd'
import config                         from 'config'

import './ppr-restaurant.scss'


const options = [ ...Array( 101 ).keys() ].map(( key ) => ({
    value: key,
    label: key,
}))

const PprRestaurant = ({ ppr, ...props }) => {
    const [ loading, setLoading ] = useState( true )
    const [ form ] = Form.useForm()
    useEffect(() => {
        update()
    }, [ props.restaurantId ])

    const update = () => {
        setLoading( true )
        props.fetchPprRestaurant({ restaurantId: props.restaurantId }).finally(() => {
            props.form.resetFields()
            setLoading( false )
        })
    }

    const fields = () => [
        {
            type:  'row',
            field: 'main',
            items: [
                {
                    type:  'block',
                    field: 'staff',
                    span:  6,
                    items: [
                        {
                            field: 'title',
                            type:  'title',
                            label: 'Сотрудники',
                        },
                        {
                            field:       'hrbpNameSurname',
                            label:       'HR BP',
                            type:        'string',
                            placeholder: 'Фамилия Имя',
                            value:       ppr?.hrbpNameSurname || '',
                            rules:       [
                                config.rules.required,
                                config.rules.length(
                                    0,
                                    128,
                                    'Поле не может быть длиннее 128 символов',
                                    false
                                ),
                            ],
                        },
                        {
                            field:       'hrtmNameSurname',
                            label:       'HRM',
                            type:        'string',
                            placeholder: 'Фамилия Имя',
                            value:       ppr?.hrtmNameSurname || '',
                            rules:       [
                                config.rules.required,
                                config.rules.length(
                                    0,
                                    128,
                                    'Поле не может быть длиннее 128 символов',
                                    false
                                ),
                            ],
                        },
                    ],
                },
                {
                    type:  'block',
                    field: 'optimisation',
                    span:  6,
                    items: [
                        {
                            field:    'title',
                            type:     'title',
                            label:    'Таблица оптимизации',
                            sublabel: 'Данные по структуре ресторана',
                        },
                        {
                            field: 'restaurantStructureRgmCount',
                            label: 'RGM',
                            type:  'select',
                            options,
                            byKey: true,
                            value: ppr?.restaurantStructureRgmCount ?? 1,
                        },
                        {
                            field: 'restaurantStructureArgmCount',
                            label: 'ARGM',
                            type:  'select',
                            options,
                            byKey: true,
                            value: ppr?.restaurantStructureArgmCount ?? 1,
                        },
                        {
                            field: 'restaurantStructureSmCount',
                            label: 'SM',
                            type:  'select',
                            options,
                            byKey: true,
                            value: ppr?.restaurantStructureSmCount ?? 1,
                        },
                    ],
                },
                {
                    type:  'block',
                    field: 'bench',
                    span:  12,
                    items: [
                        {
                            field: 'title',
                            type:  'title',
                            label: 'План по Bench Менеджеров',
                        },
                        {
                            field: 'rgmBenchPlan',
                            label: 'RGM',
                            type:  'select',
                            options,
                            byKey: true,
                            value: ppr?.rgmBenchPlan ?? 1,
                        },
                        {
                            field: 'argmBenchPlan',
                            label: 'ARGM',
                            type:  'select',
                            options,
                            byKey: true,
                            value: ppr?.argmBenchPlan ?? 1,
                        },
                        {
                            field: 'smBenchPlan',
                            label: 'SM',
                            type:  'select',
                            options,
                            byKey: true,
                            value: ppr?.smBenchPlan ?? 1,
                        },
                    ],
                },
            ],
        },
        {
            type:  'separator',
            field: 'sep-1',
        },
        {
            type:  'row',
            field: 'buttons',
            items: [
                {
                    type:    'button',
                    style:   'ghost',
                    field:   'unsave',
                    span:    6,
                    text:    'Не сохранять',
                    handler: onCancel,
                },
                {
                    type:  'submit',
                    field: 'submit',
                    text:  'Сохранить',
                    span:  6,
                },
            ],
        },
    ]

    const field = ( cfg ) => {
        let val = props.ppr ? props.ppr[ cfg.field ] : null;

        ( !val || val === 'null' ) && ( val = cfg.value )

        if ( cfg.type === 'row' ) {
            return row( cfg )
        }

        if ( cfg.type === 'title' ) {
            return (
                <React.Fragment key={cfg.field}>
                    <h2>{cfg.label}</h2>
                    <div className="ppr-subtitle">{cfg.sublabel}</div>
                </React.Fragment>
            )
        }

        if ( cfg.type === 'block' ) {
            return cfg.items.map(( item ) => field( item ))
        }

        return (
            <FormField
                {...cfg}
                value={val}
                key={cfg.field}
                form={props.form}
                data={props.ppr}
            />
        )
    }

    const row = ( cfg ) => {
        const res = []

        if ( cfg.hide && cfg.hide( props.ppr )) { return null }

        for ( let i = 0; i < cfg.items.length; i++ ) {
            if ( !cfg.items[ i ].hide ) {
                res.push(
                    <Col span={cfg.items[ i ].span} key={cfg.items[ i ].field}>
                        {field( cfg.items[ i ])}
                    </Col>
                )
            }
        }

        return (
            <Row gutter={24} key={cfg.field} className={cfg.field}>
                {res}
            </Row>
        )
    }

    const savePpr = ( e ) => {
        e.preventDefault()
        props.form.validateFieldsAndScroll(( err, values ) => {
            if ( err ) { return }

            const body = {
                ...values,
                restaurantStructureRgmCount:  +values.restaurantStructureRgmCount,
                restaurantStructureArgmCount: +values.restaurantStructureArgmCount,
                restaurantStructureSmCount:   +values.restaurantStructureSmCount,
                rgmBenchPlan:                 +values.rgmBenchPlan,
                argmBenchPlan:                +values.argmBenchPlan,
                smBenchPlan:                  +values.smBenchPlan
            }

            if ( ppr?.pprRestaurantUuid ) {
                props.onUpdate( body, ppr.pprRestaurantUuid )
                return
            }

            props.onCreate( body )
        })
    }

    const onCancel = () => {
        props.form.resetFields()
    }

    return (
        <Card className="ppr-restaurant" bordered={false}>
            <Spin spinning={loading || props.sendingPpr}>
                <Form
                    name='PprRestaurant'
                    form={form}
                    onFinish={savePpr}
                >
                    {fields().map( field )}
                </Form>
            </Spin>
        </Card>
    )
}

export default PprRestaurant
