/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Scrollbars }       from 'react-custom-scrollbars'

import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'

import { Row, Col, Modal, Popover } from 'antd'
import Icon, { InfoCircleOutlined } from '@ant-design/icons'

/* APPLICATION */
import { FormField, Spinner }   from 'components'
import { format }                    from 'tools'
import config                         from 'config'

import './staff-card.scss'

const check = ( variants, transform ) => {
    return ( value ) =>
        variants.indexOf( value ) > -1
            ? transform
                ? transform( value )
                : value
            : null
}

class StaffCard extends Component {
    static propTypes = {
        restaurant: PropTypes.string,
        roles:      PropTypes.object,
        employee:   PropTypes.object,
        companies:  PropTypes.array,
        rates:      PropTypes.array,

        onSave:   PropTypes.func,
        onRemove: PropTypes.func,
        onError:  PropTypes.func,
        getRates: PropTypes.func,
    }

    constructor ( props ) {
        super( props )
        this.state = {
            confirm:    false,
            checkRates: {
                gender:       'MALE',
                isStudies:    false,
                isUnderage2130:   false,
                isUnderage2200:   false,
                factsNumber: null,
            },
            initialCheckRates: null,
        }

        this.formRef = React.createRef()
    }

    componentDidUpdate ( prevProps, prevState ) {
        const { employee, restaurant } = this.props,
              { checkRates, initialCheckRates } = this.state;

        if (employee.id && prevProps.employee.id !== employee.id) {
          this.setState({ initialCheckRates: null });
          const newCheckRates = {
            gender:
              employee.gender && employee.gender !== 'null'
                ? employee.gender?.toUpperCase()
                : 'MALE',
            isStudies: employee.isStudies ?? false,
            isUnderage2130: employee.isUnderage2130 ?? false,
            isUnderage2200: employee.isUnderage2200 ?? false,
            factsNumber: +restaurant,
          };
          this.setState({ checkRates: newCheckRates });
          this.props.form.resetFields();
          this.scrolls && this.scrolls.scrollToTop();
        }
        if (prevState.checkRates !== checkRates) {
          this.props.getRates(checkRates);
        }

        if (
          (prevState.checkRates.isStudies !== checkRates.isStudies ||
            prevState.checkRates.isUnderage2130 !== checkRates.isUnderage2130 ||
            prevState.checkRates.isUnderage2200 !==
              checkRates.isUnderage2200) &&
          !initialCheckRates
        ) {
          this.setState({ initialCheckRates: checkRates });
        }
    }

    removeEmployee = () => {
        this.hidePopup()
        this.props.onRemove( this.props.employee )
    }

    askRemove = () => this.setState({ confirm: true })
    hidePopup = () => this.setState({ confirm: false })

    formatDates = ( obj, keys ) =>
        keys.map(( key ) => ( obj[ key ] = key === 'birthDate' ? this.formatBirthDate(obj[ key ]) : this.formatDate( obj[ key ] )))

    formatDate = ( date ) => ( date ? date.format( config.format.dayAPI ) : null )

    formatBirthDate = (date) => ( date ? date.format(config.format.dayMonth) : null)

    reset = () => { 
        const { initialCheckRates } = this.state;

        this.props.form.resetFields();
        this.setState({ checkRates: initialCheckRates });
    }

    rates = ( rates ) => {
        const arr = []
        rates.forEach(( item ) =>
            arr.push({
                value: item.rate,
                label: item.rate === 1 ? '1 (полная)' : item.rate,
            })
        )
        return arr.sort(( a, b ) => b.value - a.value )
    }

    remember = ( key, value ) => {
        if ( key === 'isUnderage2130' || key === 'isUnderage2200' || key === 'isStudies' || key === 'gender' ) {
            this.props.form.setFieldsValue({ rateDictionary: null })
            if (key === 'isUnderage2130' || key === 'isUnderage2200') {
                if (value === false) {       
                    this.props.form.setFieldsValue({ isStudies: 'false' })
                }
            }
            const { restaurant } = this.props,
                  { checkRates } = this.state,
                  obj = {
                      gender: ( key === 'gender' ? value : checkRates.gender ).toUpperCase(),
                      isStudies:
            key === 'isStudies'
                ? value
                : key === 'isUnderage2130' || key === 'isUnderage2200' && value === false
                    ? false
                    : checkRates.isStudies,
                      isUnderage2130:   key === 'isUnderage2130' ? value : checkRates.isUnderage2130,
                      isUnderage2200:   key === 'isUnderage2200' ? value : checkRates.isUnderage2200,
                      factsNumber: +restaurant,
                  }

            this.setState({ checkRates: obj })
        }
        if ( this.props.employee.__new ) {
            this.props.employee[ key ] = value
        }
    }

    saveEmployee = ( e ) => {
        e.preventDefault()

        this.props.form.validateFieldsAndScroll(( err, values ) => {
            if ( !err ) {
                const id = values.id,
                      { restaurant } = this.props,
                      { checkRates } = this.state

                if ( id === 'new' ) {
                    delete values.employeeId
                    delete values.id
                }

                delete values.remove
                delete values.submit

                values.outstaff = values.outstaff === 'true'
                values.birthDate && values.birthDate.set({ year: 2000 })

                this.formatDates( values, [ 'dateStartWork', 'dateEndWork', 'birthDate' ])

                for ( const rate of this.props.rates ) {
                    if ( rate.rate === +values.rateDictionary ) {
                        values.rateDictionary = rate
                        break
                    }
                }

                values.isStudies =
          values.isStudies === true || values.isStudies === 'true'
              ? true
              : false

                values.factsNumber = restaurant
                values.employeeName = values.lastName + ' ' + values.firstName

                this.props.onSave( values )
                this.props.form.resetFields()
                this.setState({initialCheckRates: checkRates})
            } else {
                this.props.onError()
            }
        })
    }

    fromRole = ( val ) => ( this.props.roles[ val ] ? this.props.roles[ val ] : val )

    toRole = ( val ) =>
        Object.keys( this.props.roles ).find(( r ) => this.props.roles[ r ] === val )

    fields = () => [
        {
            field: 'id',
            type:  'hidden',
        },

        {
            field: 'employeeId',
            type:  'hidden',
        },

        {
            field: 'outstaff',
            type:  'hidden',
        },

        {
            field: '__new',
            type:  'hidden',
        },

        {
            type:  'row',
            field: 'main',
            items: [
                {
                    field:    'jobRole',
                    label:    'Должность',
                    type:     'string',
                    disabled: true,
                    span:     12,
                    prepare:  this.fromRole,
                    parse:    this.toRole,
                },
                {
                    field:     'userId',
                    label:    'Логин пользователя',
                    type:     'string',
                    disabled: true,
                    span:     12,
                },
                {
                    field:     'externalSystemName',
                    label:    'Имя в WP GEM',
                    type:     'string',
                    disabled: '!outstaff',
                    span:     12,
                },
            ],
        },
        {
            type:  'row',
            field: 'name',
            items: [
                {
                    field: 'lastName',
                    label: 'Фамилия',
                    type:  'string',
                    span:  8,
                    rules: [
                        config.rules.required,
                        { max: 50, message: 'Не больше 50 символов' },
                    ],
                },
                {
                    field: 'firstName',
                    label: 'Имя',
                    type:  'string',
                    span:  8,
                    rules: [
                        config.rules.required,
                        { max: 50, message: 'Не больше 50 символов' },
                    ],
                },
                {
                    field:   'middleName',
                    label:   'Отчество',
                    tooltip: '12312312',
                    type:    'string',
                    span:    8,
                    rules:   [ { max: 50, message: 'Не больше 50 символов' } ],
                },
            ],
        },
        {
            type:  'row',
            field: 'personal',
            items: [
                {
                    field:    'birthDate',
                    label:    'Дата рождения',
                    type:     'date',
                    disabled: '!outstaff',
                    format:   config.format.onlyDayView,
                    transform: (dateString) => typeof dateString === 'string' ? '2000' + dateString.slice(1) : dateString,
                    span:     8,
                },
                {
                    field: 'gender',
                    label: 'Пол',
                    type:  'radio',
                    span:  16,
                    rules: [
                        {
                            ...config.rules.required,
                            transform: check([ 'MALE', 'FEMALE' ], ( str ) => str.toUpperCase()),
                        },
                    ],
                    options: [
                        { label: 'Мужской', value: 'MALE' },
                        { label: 'Женский', value: 'FEMALE' },
                    ],
                },
            ],
        },
        {
            type:  'row',
            field: 'company',
            hide:  ( row ) => !row.outstaff,
            items: [
                {
                    field:   'companyUuid',
                    label:   'Компания',
                    type:    'select',
                    span:    16,
                    byKey:   true,
                    options: format.generate.optionsr(
                        this.props.companies,
                        'companyUuid',
                        'companyName'
                    ),
                    disabled: this.props.employee._protect_company,
                },
            ],
        },
        {
            type:  'separator',
            field: 'sep-1',
        },
        {
            type:  'row',
            field: 'dates',
            items: [
                {
                    field:    'dateStartWork',
                    label:    'Зачисление',
                    type:     'date',
                    format:   config.format.dayView,
                    span:     8,
                    disabled: '!outstaff',
                },
                {
                    field:    'dateEndWork',
                    label:    'Увольнение',
                    type:     'date',
                    format:   config.format.dayView,
                    span:     8,
                    disabled: '!outstaff',
                },
                {
                    field: 'salary',
                    label: 'Зарплата',
                    type:  'number',
                    addOn: '₽',
                    span:  8,
                    rules: [ config.rules.required ],
                },
            ],
        },
        {
            type:  'row',
            field: 'payment',
            items: [
                {
                    field:   'paymentType',
                    label:   'Тариф',
                    type:    'radio',
                    value:   'hourly',
                    span:    24,
                    options: [
                        { label: 'Почасовой', value: 'hourly' },
                        { label: 'Фиксированный', value: 'salary' },
                    ],
                    rules: [
                        {
                            ...config.rules.required,
                            transform: check([ 'hourly', 'salary' ]),
                        },
                    ],
                },
            ],
        },
        {
            type:  'row',
            field: 'salary',
            items: [
                {
                    field:   'rateDictionary',
                    label:   'Ставка',
                    type:    'select',
                    value:   this.props.employee?.rateDictionary?.rate,
                    byKey:   true,
                    span:    12,
                    options: this.props.rates ? this.rates( this.props.rates ) : [],
                    rules:   [ config.rules.required ],
                },
                {
                    type:        'infoIcon',
                    field:       'info',
                    iconForInfo: InfoCircleOutlined,
                    span:        1,
                },
                {
                    field: 'isUnderage2130',
                    label: 'Несовершеннолетний 21:30',
                    disabled: this.state.checkRates.isUnderage2200,
                    type:  'checkbox',
                    span:  12,
                },
                {
                    field: 'isUnderage2200',
                    label: 'Несовершеннолетний 22:00',
                    type:  'checkbox',
                    disabled: this.state.checkRates.isUnderage2130,
                    span:  12,
                },
                {
                    field:  'isStudies',
                    label:  'Учёба',
                    type:   'radio',
                    hidden: !this.state.checkRates.isUnderage2130 && !this.state.checkRates.isUnderage2200,
                    value:  this.state.checkRates.isStudies?.toString(),
                    span:   9,
                    rules:  [
                        {
                            ...config.rules.required,
                            transform: check([ 'true', 'false' ]),
                        },
                    ],
                    options: [
                        { label: 'Учится', value: 'true' },
                        { label: 'Не учится', value: 'false' },
                    ],
                },
            ],
        },
        {
            type:  'separator',
            field: 'sep-2',
        },
        {
            type:  'row',
            field: 'comtacts',
            items: [
                {
                    field: 'phone',
                    label: 'Телефон',
                    type:  'phone',
                    placeholder: 'Введите в формате +7 без пробелов',
                    span:  12,
                    rules: [
                        config.rules.required,
                        config.rules.phoneRule,
                        { max: 20, message: 'Не больше 20 символов' },
                    ],
                },
                {
                    field:    'email',
                    label:    'E-mail',
                    type:     'email',
                    span:     12,
                    disabled: '!outstaff',
                    rules:    [
                        {
                            type:    'email',
                            message: 'Введите e-mail',
                        },
                    ],
                },
            ],
        },
        {
            type:  'row',
            field: 'address',
            items: [
                {
                    field:    'city',
                    label:    'Населённый пункт',
                    type:     'string',
                    disabled: '!outstaff',
                    span:     10,
                },
                {
                    field: 'address',
                    label: 'Адрес',
                    type:  'string',
                    span:  14,
                },
            ],
        },
        {
            field: 'comment',
            label: 'Примечания',
            type:  'text',
        },
        {
            type:  'separator',
            field: 'sep-3',
        },
        {
            type:  'row',
            field: 'buttons',
            items: [
                {
                    type:    'button',
                    style:   'remove',
                    field:   'remove',
                    text:    'Удалить из базы',
                    hide:    this.props.employee.__new,
                    handler: this.askRemove,
                    span:    6,
                    hidden:  !this.props.employee.outstaff,
                },
                {
                    type:  'space',
                    field: 'space',
                    span:  this.props.employee.__new ? 12 : 6,
                },
                {
                    type:    'button',
                    style:   'ghost',
                    field:   'unsave',
                    span:    6,
                    text:    'Не сохранять',
                    handler: this.reset,
                    hide:    this.props.employee.__new,
                },
                {
                    type:  'submit',
                    field: 'submit',
                    text:  this.props.employee.__new ? 'Добавить сотрудника' : 'Сохранить',
                    span:  this.props.employee.__new ? 12 : 6,
                },
            ],
        },
    ]

    row = ( cfg ) => {
        const res = []

        if ( cfg.hide && cfg.hide( this.props.employee )) { return null }

        for ( let i = 0; i < cfg.items.length; i++ ) {
            if ( !cfg.items[ i ].hide ) {
                res.push(
                    <Col span={cfg.items[ i ].span} key={cfg.items[ i ].field}>
                        {this.field( cfg.items[ i ])}
                    </Col>
                )
            }
        }

        return (
            <Row gutter={24} key={cfg.field} className={cfg.field}>
                {res}
            </Row>
        )
    }

    infoIcon = ( cfg ) => {
        let content

        if ( this.props.rates && this.props.rates.length > 0 ) {
            content = this.props.rates.map(( rate ) => {
                return (
                    <div key={rate.rate}>
                        {format.strings.float( rate.rate ).replace( ',', '.' )} &ndash;{' '}
                        {rate.hours} ч.
                    </div>
                )
            })
        } else {
            content = <div>Нет ставок</div>
        }

        return (
            <Col className={'infoIcon'} span={1} key={cfg.field}>
                <Popover content={content} trigger="hover">
                    <Icon component={InfoCircleOutlined} />
                </Popover>
            </Col>
        )
    }

    field = ( cfg ) => {
        let val = this.props.employee ? this.props.employee[ cfg.field ] : null;

        ( !val || val === 'null' ) && ( val = cfg.value )

        if ( cfg.type === 'row' ) {
            return this.row( cfg )
        }

        if ( cfg.type === 'infoIcon' ) {
            return this.infoIcon( cfg )
        }

        return (
            <FormField
                {...cfg}
                value={val}
                key={cfg.field}
                form={this.props.form}
                data={this.props.employee}
                onChange={this.remember}
            />
        )
    }

    render () {
        if ( this.props.loading ) {
            return <Spinner />
        }

        return (
            <Scrollbars {...config.ui.scrolls} ref={( node ) => ( this.scrolls = node )}>
                <Form
                    ref={this.formRef}
                    {...config.ui.wideForm}
                    onSubmit={this.saveEmployee}
                    className="staff-card"
                >
                    {this.fields().map( this.field )}
                </Form>

                <Modal
                    title="Вы действительно хотите удалить сотрудника из списка?"
                    className="kfc-popup"
                    centered={true}
                    open={this.state.confirm}
                    onOk={this.removeEmployee}
                    okButtonProps={{ className: 'wide-btn' }}
                    okText={'Да, удалить'}
                    onCancel={this.hidePopup}
                    cancelButtonProps={{ style: { display: 'none' } }}
                />
            </Scrollbars>
        )
    }
}

export default Form.create({ name: 'StaffCard' })( StaffCard )
