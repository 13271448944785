/* VENDOR */
import React from 'react'

/* APPLICATION */
import { Icon, IconActive, createIcon } from './Icon'

const svg = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 7C6.44772 7 6 7.44772 6 8C6 8.55228 6.44772 9 7 9H16C17.6569 9 19 10.3431 19 12C19 13.6569 17.6569 15 16 15H7C6.99946 15 6.99892 15 6.99838 15V12.9657C6.99838 12.6094 6.56752 12.4309 6.31554 12.6829L3.28122 15.7172C3.12501 15.8734 3.12501 16.1267 3.28122 16.2829L6.31553 19.3172C6.56752 19.5692 6.99838 19.3907 6.99838 19.0344V17C6.99892 17 6.99946 17 7 17H16C18.7614 17 21 14.7614 21 12C21 9.23858 18.7614 7 16 7H7Z"
            fill="#1F86FF"
        />
    </svg>
)

class GetBack extends Icon {
    render () {
        return svg( this.primary, this.secondary )
    }
}

class GetBackActive extends IconActive {
    render () {
        return svg( this.primary, this.secondary )
    }
}

export default createIcon( GetBack, GetBackActive )
