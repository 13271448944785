/* VENDOR */
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Select, Modal, Button} from 'antd'
import ReactExport from 'react-data-export'
import moment from 'moment'
import Icon from '@ant-design/icons'

/* APPLICATION */
import {ReportTable, Link, SyncScrollV, Spinner, ReportExcelTooltip} from 'components'
import {Download} from 'components/layout/icons'
import {format} from 'tools'
import config from 'config'

import './categories-rating.scss'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet

const {Option} = Select,
      chevron =
    'data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTkuMTQwOTQgNS40MTQzMkw2LjU1NDI3IDguMDAwOTlMOS4xNDA5NCAxMC41ODc3QzkuNDAwOTQgMTAuODQ3NyA5LjQwMDk0IDExLjI2NzcgOS4xNDA5NCAxMS41Mjc3QzguODgwOTQgMTEuNzg3NyA4LjQ2MDk0IDExLjc4NzcgOC4yMDA5NCAxMS41Mjc3TDUuMTQwOTQgOC40Njc2NkM0Ljg4MDk0IDguMjA3NjYgNC44ODA5NCA3Ljc4NzY2IDUuMTQwOTQgNy41Mjc2Nkw4LjIwMDk0IDQuNDY3NjZDOC40NjA5NCA0LjIwNzY2IDguODgwOTQgNC4yMDc2NiA5LjE0MDk0IDQuNDY3NjZDOS4zOTQyNyA0LjcyNzY2IDkuNDAwOTQgNS4xNTQzMiA5LjE0MDk0IDUuNDE0MzJaIiBmaWxsPSIjMUY4NkZGIi8+PC9zdmc+'

class CategoriesRating extends Component {
    static propTypes = {
        partner: PropTypes.any,
        manager: PropTypes.any,

        user: PropTypes.object,

        partners: PropTypes.array,
        categories: PropTypes.object,
        managers: PropTypes.array,
        rests: PropTypes.array,
        restaurants: PropTypes.object,

        onPartner: PropTypes.func,
        onCategory: PropTypes.func,
        onManager: PropTypes.func,
        onUpdate: PropTypes.func,
    }

    constructor (props) {
        super(props)

        this.refCats = React.createRef()
        this.refMans = React.createRef()
        this.refRests = React.createRef()

        this.state = {
            confirm: false,
            categories: props.categories,
            managers: props.managers,
            scrollUpdate: true,
            focused: null,
        }
    }

    componentDidMount () {
        const {categories, restaurants} = this.props
        restaurants?.summary && restaurants?.elements && this.setRestaurants(restaurants)
        categories?.summary && categories?.elements && this.setCategories(categories)
    }

    componentDidUpdate () {
        this.fixHeader(this.refCats)
        this.fixHeader(this.refMans)
        this.fixHeader(this.refRests)
        this.state.scrollUpdate &&
    setTimeout(() => this.setState({scrollUpdate: false}), 100)
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps (nextProps) {
        const {categories, managers, restaurants} = this.props


        categories !== nextProps.categories && this.setCategories(nextProps.categories)
        managers !== nextProps.managers && this.setManagers(nextProps.managers)
        restaurants !== nextProps.restaurants && this.setRestaurants(nextProps.restaurants)
    }

    fixHeader = (ref) => {
        if (ref.current) {
            const tds = ref.current.querySelectorAll('tbody tr:first-child td')

            if (tds) {
                [].slice.call(tds).forEach((td, i) => {
                    ref.current.querySelector(
                        `thead th:nth-child(${i + 1})`
                    ).style.width = ''
                });
                [].slice.call(tds).forEach((td, i) => {
                    ref.current.querySelector(
                        `thead th:nth-child(${i + 1})`
                    ).style.width = td.offsetWidth - 4 + 'px'
                })
            }
        }
    }

    convertColumnValue = (column) => {
        if (typeof column === 'number') {
            return column + ' %'
        }
        return column ?? '-'
    }

    setCategories = (categories) => {
        if (!categories || !categories.summary || !categories.elements) {
            this.setState({categories: null})
            return
        }

        const newCategories = format.copy.object(categories)
        const elements = format.copy.array(newCategories.elements)
        // allRow = format.copy.object( newCategories.summary )

        // allRow.restaurant = 'Все категории блюд'
        // allRow.name = 'Все категории блюд'
        // allRow.disabled = true

        // elements?.unshift(allRow)

        // newCategories.elements = elements

        this.setState({categories: newCategories, scrollUpdate: true})
    }

    setRestaurants = (restaurants) => {
        if (!restaurants || !restaurants.summary || !restaurants.elements) {
            this.setState({restaurants: null})
            return
        }

        const newRestaurants = format.copy.object(restaurants)
        const elements = format.copy.array(newRestaurants.elements),
              allRow = format.copy.object(newRestaurants.summary)

        allRow.restaurant = 'Все рестораны'
        allRow.restaurantName = 'Все рестораны'
        allRow.disabled = true

        elements?.unshift(allRow)

        newRestaurants.elements = elements

        this.setState({restaurants: newRestaurants, scrollUpdate: true})
    }
    setManagers = (managers) => this.setState({managers, scrollUpdate: true})


    options = (items) =>
        items.map((item) => (
            <Option value={item.value} key={item.value}>
                {item.label}
            </Option>
        ))

    columns = (state, title, dataIndex, isRestaurant) => {
        const res = isRestaurant ?
            format.copy.array(config.tables.restaurants.detailedCategories(this))
            : format.copy.array(config.tables.restaurants.categories(this))

        !isRestaurant && title && ( res[ 0 ].title = title )

        return state ? [ ...res.slice(0, 2), ...res.slice(3) ] : res
    }

    prepare = (record, index, columns) =>
        format.goodBad.iterate.goodBad(record, [ 'deltaCategoryRating' ], columns)

    noPlan = (arr, current, key) => {
        if (!arr) {
            return format.generate.noPager(arr)
        }

        const res = format.copy.array(arr)

        res.forEach((item) => {
            if (current) {
                item._current = item[ key ] === current[ key ]
                item.disabled = item[ key ] !== current[ key ]
            } else if (item.name === 'Все категории блюд') {
                item.disabled = true
            } else {
                item._current = false
                item.disabled = false
            }
        })

        return format.generate.noPager(res)
    }

    flushCategory = () => this.props.onCategory(null)
    flushManager = () => this.props.onManager(null)

    top = (ref, alt) => {
        if (!ref.current && !alt.current) {
            return '50%'
        }

        const cur = ref.current ? ref.current : alt.current,
              offset = cur.offsetTop,
              add = cur.querySelector('.ant-table-fixed-header') ? 30 : 0,
              row = cur.querySelector('.row-current'),
              scroll = cur.querySelector('.ant-table-body'),
              scrld = scroll ? scroll.scrollTop : 0

        if (!scroll) {
            return
        }

        scroll.removeEventListener('scroll', this.force)
        scroll.addEventListener('scroll', this.force)

        return row
            ? Math.max(70, offset + row.offsetTop + add - scrld) + 'px'
            : '50%'
    }

    force = () => this.forceUpdate()

    prepareExcel = (restaurants, columns) => {
        const red = '00FF0000'
        const green = '00038E1D'
        const black = '00000000'

        return [
            {
                columns: columns.map((item) => ({...item, width: {wpx: item?.width || 120}})),
                data: restaurants.elements.map((item) => [
                    {value: item?.factsNumber ?? ''},
                    {value: this.convertColumnValue(item?.restaurantName)},
                    {value: this.convertColumnValue(item?.planCategoryRating)},
                    {value: this.convertColumnValue(item?.factCategoryRating)},
                    {
                        value: this.convertColumnValue(item?.deltaCategoryRating),
                        style: {font: {color: {rgb: item?.deltaCategoryRating < 0 ? red : (item?.deltaCategoryRating > 0 ? green : black)}}}
                    },
                ]
                ),
            }
        ]
    }
    scroll = (ref) => {
        const cur = ref && ref.current

        if (!cur) {
            return
        }

        const from = cur.parentNode.parentNode.parentNode.parentNode,
              table = cur.querySelector('.ant-table-body table'),
              fixed = cur.querySelector('.ant-table-hide-scrollbar')

        if (!from || !table) {
            return
        }

        const foff = fixed ? fixed.offsetHeight : 0,
              show = cur.offsetHeight - 38 < table.offsetHeight - foff

        if (!show) {
            return
        }

        return {y: from.offsetHeight - 128}
    }

    lockOthers = (focused) => {
        this.setState({focused})
    }

    askChange = (record, key, value) => {
        if (record[ key ] === value) {
            this.setState({focused: null})
            return
        }

        this.setState({
            focused: null,
            confirm: true,
            askData: {
                record,
                key,
                value,
            },
        })
    }

    changeApprove = () => {
        const {askData} = this.state,
              {record, key, value} = askData

        this.change(record, key, value)
        this.hideConfirm()
    }

    hideConfirm = () => {
        this.setState({
            confirm: false,
            askData: null,
        })
    }

    dropChange = () => {
        const {categories, managers} = this.props

        this.setState(
            {
                categories: null,
                managers: null,
                confirm: false,
                askData: null,
            },
            () =>
                this.setState({
                    categories: format.copy.object(categories),
                    managers: managers ? format.copy.array(managers) : null,
                })
        )
    }

    change = (r, key, value) => {
        const {category, manager} = this.props,
              update = {
                  categoryId: category ? category.categoryId : r.categoryId,
                  rating: value,
              }

        category && manager && (update.userId = manager.userId)
        r.userId && (update.userId = r.userId)
        r.factsNumber && (update.factsNumber = r.factsNumber)

        this.props.onUpdate(update)
    }

    onCategory = (raw) => {
        if (this.state.focused) {
            return
        }
        this.props.onCategory(raw)
    }

    render () {
        const {
            partner,
            category,
            partners,
            rests,
            manager,
            user,
            areaCoach,
            onPartner,
            onManager,
            facilityType,
            city,
            regionCoach,
            marketCoach,
            factsNumbers,
            facilityTypes,
            cities,
            regionCoaches,
            marketCoaches,
            areaCoaches,
            onFilter,
            onFocus
        } = this.props,
              {categories, managers, restaurants} = this.state,
              cls = [ 'rating-inner rating-categories' ],
              scrolls = {
                  cats: this.scroll(this.refCats),
                  mans: this.scroll(this.refMans),
                  rests: this.scroll(this.refRests),
              }

        if ( user.marketManager ) {
            if ( category && manager ) {
                cls.push( 'count-3' )
            } else if ( category ) {
                cls.push( 'count-2' )
            }
        } else {
            if ( category || manager ) {
                cls.push( 'count-2' )
            }
        }

        let restaurantsData
        if (restaurants) {
            restaurantsData = format.generate.noPager(restaurants.elements)
            restaurantsData.content[ 0 ]._alwaysOnTop = true
        }

        return (
            <div className={cls.join( ' ' )}>
                <div className="rating-performance">
                    <div className="rat-cats rating-performance__filters" ref={this.refCats}>
                        <Select
                            placeholder="Партнеры"
                            mode='multiple'
                            maxTagCount={1}
                            value={partner}
                            onChange={onPartner}
                            onFocus={onFocus}
                            style={{ width: '100%', maxWidth: 250 }}
                            getPopupContainer={( trigger ) => trigger.parentNode}
                            disabled={!partners.length}
                            filterOption={( input, option ) =>
                                option.props.children.toLowerCase().indexOf( input.toLowerCase()) >= 0
                            }
                            allowClear
                        >
                            {this.options([
                                ...(partners || []),
                            ])}
                        </Select>
                        <Select
                            placeholder="Типы ресторанов"
                            mode='multiple'
                            maxTagCount={1}
                            style={{width: 500}}
                            value={facilityType}
                            onFocus={onFocus}
                            onChange={(value) => onFilter({value, name: 'facilityTypes'})}
                            getPopupContainer={(trigger) => trigger.parentNode}
                            disabled={!facilityTypes.length}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            allowClear
                        >
                            {this.options([
                                ...(facilityTypes || []),
                            ])}
                        </Select>
                        <Select
                            placeholder="Города"
                            mode='multiple'
                            maxTagCount={1}
                            value={city}
                            style={{width: 500}}
                            onChange={(value) => onFilter({value, name: 'cities'})}
                            onFocus={onFocus}
                            getPopupContainer={(trigger) => trigger.parentNode}
                            disabled={!cities.length}
                            allowClear
                        >
                            {this.options([
                                ...(cities || []),
                            ])}
                        </Select>
                        <Select
                            placeholder="Region coaches"
                            mode='multiple'
                            maxTagCount={1}
                            style={{width: 500}}
                            value={regionCoach}
                            onFocus={onFocus}
                            onChange={(value) => onFilter({value, name: 'regionCoaches'})}
                            getPopupContainer={(trigger) => trigger.parentNode}
                            disabled={!regionCoaches.length}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            allowClear
                        >
                            {this.options([
                                ...(regionCoaches || []),
                            ])}
                        </Select>
                        <Select
                            placeholder="Market coaches"
                            mode='multiple'
                            maxTagCount={1}
                            style={{width: 500}}
                            value={marketCoach}
                            onChange={(value) => onFilter({value, name: 'marketCoaches'})}
                            onFocus={onFocus}
                            getPopupContainer={(trigger) => trigger.parentNode}
                            disabled={!marketCoaches.length}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            allowClear
                        >
                            {this.options([
                                ...(marketCoaches || []),
                            ])}
                        </Select>
                        <Select
                            placeholder="Area coaches"
                            mode='multiple'
                            maxTagCount={1}
                            style={{width: 600}}
                            value={areaCoach}
                            onChange={(value) => onFilter({value, name: 'areaCoaches'})}
                            onFocus={onFocus}
                            getPopupContainer={(trigger) => trigger.parentNode}
                            disabled={!areaCoaches.length}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            allowClear
                        >
                            {this.options([
                                ...(areaCoaches || []),
                            ])}
                        </Select>
                        <Select
                            placeholder="Рестораны"
                            maxTagCount={1}
                            mode='multiple'
                            style={{ width: 700 }}
                            value={factsNumbers}
                            onChange={( value ) => onFilter({ value, name: 'factsNumbers' })}
                            onFocus={onFocus}
                            getPopupContainer={( trigger ) => trigger.parentNode}
                            disabled={ !rests.length }
                            filterOption={( input, option ) =>
                                option.props.children.toLowerCase().indexOf( input.toLowerCase()) >= 0
                            }
                            allowClear
                        >
                            {this.options([
                                ...( rests || []),
                            ])}
                        </Select>
                    </div>


                    {categories ? (
                        categories.elements?.length > 0 ? (
                            <div className="report-table category-table">
                                <ReportTable
                                    rowKey="categoryId"
                                    unit="percent"
                                    styleInactive={false}
                                    data={this.noPlan( categories.elements, category, 'categoryId' )}
                                    columns={this.columns( category || manager, null, 'categoryName', false )}
                                    scroll={scrolls.cats}
                                    prepare={this.prepare}
                                    onCellClick={this.onCategory}
                                    onCellBlur={this.askChange}
                                    onCellFocus={this.lockOthers}
                                />
                                {scrolls.cats && (
                                    <SyncScrollV
                                        container={this.refCats}
                                        watch=".ant-table-body"
                                        width={24}
                                        offset={30}
                                    />
                                )}
                            </div>
                        ) : (
                            <div className="report-table">
                                <p>В системе пока нет статистики по категориям</p>
                            </div>
                        )
                    ) : (
                        <Spinner />
                    )}
                </div>

                {category && user.marketManager && (
                    <div className="rat-mans" ref={this.refMans}>
                        <span
                            className="rat-arrow"
                            style={{ top: this.top( this.refCats ) }}
                        ></span>
                        <Link onClick={this.flushCategory}>
                            <img src={chevron} alt="" />
                            {category.name}
                        </Link>
                        {/*{managers ? (*/}
                        {/*    managers.length > 0 ? (*/}
                        {/*        <div className="report-table">*/}
                        {/*            <ReportTable*/}
                        {/*                rowKey="userId"*/}
                        {/*                unit="percent"*/}
                        {/*                styleInactive={false}*/}
                        {/*                data={this.noPlan( managers, manager, 'userId' )}*/}
                        {/*                columns={this.columns(*/}
                        {/*                    manager,*/}
                        {/*                    manager ? 'ТУ' : 'Территориальный управляющий',*/}
                        {/*                    'employeeName'*/}
                        {/*                )}*/}
                        {/*                scroll={scrolls.mans}*/}
                        {/*                prepare={this.prepare}*/}
                        {/*                onCellClick={onManager}*/}
                        {/*                onCellBlur={this.askChange}*/}
                        {/*            />*/}
                        {/*            {scrolls.mans && (*/}
                        {/*                <SyncScrollV*/}
                        {/*                    container={this.refMans}*/}
                        {/*                    watch=".ant-table-body"*/}
                        {/*                    width={24}*/}
                        {/*                    offset={10}*/}
                        {/*                />*/}
                        {/*            )}*/}
                        {/*        </div>*/}
                        {/*    ) : (*/}
                        {/*        <div className="report-table">*/}
                        {/*            <p>Нет статистики по этой категории</p>*/}
                        {/*        </div>*/}
                        {/*    )*/}
                        {/*) : (*/}
                        {/*    <Spinner />*/}
                        {/*)}*/}
                    </div>
                )}

                {manager && (
                    <div className="rat-rest" ref={this.refRests}>
                        <span
                            className="rat-arrow"
                            style={{ top: this.top( this.refMans, this.refCats ) }}
                        ></span>
                        <div className='category-title'>
                            <Link onClick={this.flushManager}>
                                <img src={chevron} alt="" />
                                {user.marketManager ? manager.employeeName : category.categoryName}
                            </Link>
                            {
                                !restaurants ?
                                        <ReportExcelTooltip />
                                    :
                                        <ExcelFile
                                            filename={`${category.categoryName}_${moment().format( 'YYYY-MM-DDTHH:mm:ss' )}`}
                                            element={
                                                <Button
                                                    type='link'
                                                    className='button-download'
                                                >
                                                    <Icon component={Download.def} />
                                                </Button>
                                            }
                                        >
                                            <ExcelSheet
                                                dataSet={this.prepareExcel(restaurants, this.columns(false, '', 'restaurantName', true, 'Код', 'factsNumber'))}
                                                name="Категория блюд"/>
                                        </ExcelFile>
                            }
                        </div>
                        {restaurants ? (
                            <div className="report-table">
                                <ReportTable
                                    rowKey="factsNumber"
                                    unit="percent"
                                    styleInactive={false}
                                    data={restaurantsData}
                                    columns={this.columns(false, '', 'restaurantName', true)}
                                    scroll={scrolls.rests}
                                    prepare={this.prepare}
                                    onChange={this.change}
                                />
                                {scrolls.rests && (
                                    <SyncScrollV
                                        container={this.refRests}
                                        watch=".ant-table-body"
                                        width={24}
                                        offset={10}
                                    />
                                )}
                            </div>
                        ) : (
                            <Spinner />
                        )}
                    </div>
                )}

                <Modal
                    title="Изменение цели"
                    className="kfc-popup"
                    centered={true}
                    open={this.state.confirm}
                    okText="Изменить"
                    onOk={this.changeApprove}
                    cancelText={'Отмена'}
                    onCancel={this.dropChange}
                >
          Целевой показатель изменится у всех ресторанов
                </Modal>
            </div>
        )
    }
}

export default CategoriesRating
