/* VENDOR */
import React from 'react'

/* APPLICATION */
import { Icon, IconActive, createIcon } from './Icon'

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
        <path
            fill="#1F86FF"
            d="M15.3826 5.30508L10.4959.374414c-.0791-.079101-.167-.145898-.2602-.202148-.0246-.014063-.0509-.028125-.0773-.04043-.0158-.008789-.0334-.01582-.0492-.022852C9.94922.0386719 9.7752 0 9.59766 0H3.51562C2.81602 0 2.25.566016 2.25 1.26562V16.7344C2.25 17.434 2.81602 18 3.51562 18h3.72657c.3498 0 .63281-.283.63281-.6328 0-.3498-.28301-.6328-.63281-.6328H3.51562V1.26562H9v4.21876C9 6.18398 9.56602 6.75 10.2656 6.75h4.2188v2.74219c0 .3498.283.63281.6328.63281.3498 0 .6328-.28301.6328-.63281v-3.2959c0-.33399-.1318-.65391-.3674-.89121zm-5.117.1793V1.94063l3.5121 3.54375h-3.5121z"
        />
        <path
            fill="#1F86FF"
            stroke="#1F86FF"
            strokeWidth=".5"
            d="M10.9479 17.25c-.0176 0-.0342-.0009-.0518-.0034-.1212-.0147-.2268-.0775-.2874-.1706l-1.55695-2.4c-.10751-.1653-.04203-.3754.14563-.4702.18766-.0947.42615-.037.53366.1283l1.30186 2.0082 4.799-4.2411c.1525-.1344.3998-.1352.5533-.0009.1524.1344.1534.3522.0009.4874l-5.143 4.5452-.0186.0163c-.0743.0655-.174.1008-.2766.1008z"
        />
    </svg>
)

class Doc extends Icon {
    render () {
        return svg( this.primary, this.secondary )
    }
}

class DocActive extends IconActive {
    render () {
        return svg( this.primary, this.secondary )
    }
}

export default createIcon( Doc, DocActive )
