import { format } from 'tools'

const service = 'dashboard',
      gp = [ 'dateStart', 'dateEnd', 'unitOfMeasure', 'factsNumber' ]

export const getAverageCheck = ( params ) =>
    format.generate.get( service, 'average-check/page', params )
export const getReport = ( params ) =>
    format.generate.get( service, 'turnover-report/page', params )
export const getPlanAndFact = ( params ) =>
    format.generate.get( service, 'turnover-plan-fact', params )
export const getGoals = ( params ) =>
    format.generate.get( service, 'turnover-goal', params, '', gp )
export const getSummary = ( params, append ) =>
    format.generate.get( service, 'turnover-summary', params, append )
