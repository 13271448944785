/* VENDOR */
import React from 'react'

/* APPLICATION */
import { Icon, IconActive, createIcon } from './Icon'

const svg = ( primary ) => (
    <svg width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M22.167 3.5H21V2.333a1.17 1.17 0 00-1.167-1.166 1.17 1.17 0 00-1.166 1.166V3.5H9.333V2.333a1.17 1.17 0 00-1.166-1.166A1.17 1.17 0 007 2.333V3.5H5.833a2.323 2.323 0 00-2.321 2.333L3.5 22.167A2.333 2.333 0 005.833 24.5h16.334a2.34 2.34 0 002.333-2.333V5.833A2.34 2.34 0 0022.167 3.5zM21 22.167H7A1.17 1.17 0 015.833 21V9.333h16.334V21A1.17 1.17 0 0121 22.167zm-11.667-10.5h3.5A1.17 1.17 0 0114 12.833v3.5a1.17 1.17 0 01-1.167 1.167h-3.5a1.17 1.17 0 01-1.166-1.167v-3.5a1.17 1.17 0 011.166-1.166z"
            fill={primary}
        />
    </svg>
)

class CalendarView extends Icon {
    render () {
        return svg( this.primary, this.secondary )
    }
}

class CalendarViewActive extends IconActive {
    render () {
        return svg( this.primary, this.secondary )
    }
}

export default createIcon( CalendarView, CalendarViewActive )
