/* VENDOR */
import React, {Component} from 'react'
import {connect} from 'react-redux'
import moment from 'moment'
import {Card, DatePicker, Layout} from 'antd'
import { CalendarOutlined, CloseCircleOutlined } from '@ant-design/icons'
import ruRU from 'antd/es/date-picker/locale/ru_RU'

/* APPLICATION */
import {Alert, AppHeader, BackTitle, InnerContent, PlanningControls, Report, Spinner} from 'components'

import {format} from 'tools'
import config from 'config'

import {allActions, mapStateToProps} from './connector'
import '../Planning/planning.scss'
import './planning-deep.scss'

const { Content, Header } = Layout,
      { MonthPicker } = DatePicker

class Planning extends Component {
    constructor ( props ) {
        super( props )
        this.state = {
            data: false,

            reportChanged: false,

            month: props.request.month
                ? moment( props.request.month )
                : moment(),
            turnover:          0,
            planTurnoverRgm:   0,
            average:           0,
            deltaPlanTurnover: 0,
            factTurnover:      0,
            deltaTurnover:     0,
            planAverageCheck:  0,

            turnoverSummary: null,
            currDate:        null,

            uploading:    false,
            columns:      [],
            changedDays:  [],
            downloadLoad: false,
        }
    }

    reportLoading = false

    componentDidMount () {
        this.load( this.props )
        !this.props.request.month &&
        this.props.setMonth( moment().format( config.format.monthAPI ))
        this.configColumns()
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { request, report, turnoverSummary } = this.props
        const month = request.month ?
            moment( request.month ).format( config.format.monthAPI ) :
            moment().format( config.format.monthAPI )

        if ( month !== nextProps.request.month ) {
            this.setState(
                { month: moment( nextProps.request.month ), }
            )
            this.load( nextProps )
        }

        report !== nextProps.report && this.updateReport( nextProps )
        report !== nextProps.report && this.getDeep( nextProps )
        turnoverSummary !== nextProps.turnoverSummary && this.updatePlanTurnoverRgm( nextProps )

        if ( nextProps.error ) {
            this.setState({
                uploading:         false,
                reportChanged:     false,
                turnover:          nextProps.deep.planTurnoverAreaCoach,
                planTurnoverRgm:   nextProps.deep.planTurnoverRgm,
                // average: nextProps.deep?.planAverageCheck,
                report:            report,
                turnoverSummary:   nextProps.turnoverSummary,
                deltaPlanTurnover: nextProps.deep.deltaPlanTurnover,
                factTurnover:      nextProps.deep.factTurnover,
                deltaTurnover:     nextProps.deep.deltaTurnover,
                planAverageCheck:  nextProps.deep.planAverageCheck,
            })
        }
    }

    capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    deepId = () => this.props.match.params.deep

    updateReport = ( props ) => {
        if ( props.report ) {
            this.state.uploading &&
            ( props.deep.planValue = this.summTurnover( props.report[ 0 ].days ))
            const newReport = format.copy.array( props.report )
            newReport[ 0 ].days = newReport[ 0 ].days.map((day) => {
                return {
                    ...day,
                    planTurnoverRgmChanged: day.planTurnoverRgm !== day.planTurnoverAreaCoach &&
                  !day.predictDate &&
                  !moment(day.date).isBefore(moment())
                }
            })

            this.setState({
                report:            newReport,
                turnover:          props.deep?.planValue,
                planTurnoverRgm:   this.summPlanTurnoverRgm( props.report[ 0 ].days ),
                deltaPlanTurnover: this.summDeltaPlanTurnover( props.report[ 0 ].days ),
                factTurnover:      this.summFactTurnover( props.report[ 0 ].days ),
                deltaTurnover:     this.summDeltaTurnover( props.report[ 0 ].days ),
                planAverageCheck:  props.deep?.planAverageCheck,
                average:           props.deep?.planAverageCheck,
                uploading:         false,
                reportChanged:     false,
            })
        }
    }

    updatePlanTurnoverRgm = ( props ) => {
        const report = format.copy.array( this.state.report ),
              index = report[ 0 ].days.findIndex(( day ) => day.date === this.state.currDate )

        report[ 0 ].days[ index ].planTurnoverRgm = props.turnoverSummary?.summary?.factTurnover
        this.setState({
            report,
            planTurnoverRgm: this.summPlanTurnoverRgm( report[ 0 ].days ),
        })
    }

    setTurnover = ( turnover ) =>
        this.setState({ turnover })

    setMonth = ( month ) =>
        this.setState(
            { deep: null, },
            () => this.props.setMonth( month.format( config.format.monthAPI ))
        )

    download = () => {
        this.setState({ downloadLoad: true }, () => {
            const { deep, user } = this.props,
                  { month } = this.state,
                  date = month.format( config.format.date ),
                  url = `${process.env.REACT_APP_DASHBOARD_API}goals/${deep.factsNumber}/excel?date=${date}`


            fetch( url, { headers: { 'Authorization': `Bearer ${localStorage.getItem( 'access_token' )}` } })
                .then(( response ) => response.blob())
                .then(( blob ) => {
                    const _url = window.URL.createObjectURL( blob )
                    window.open( _url, '_blank' ).focus()
                })
                .catch(( error ) => {
                    if ( error.status === 401 || error.status === 403 ) {
                        format.update.goToLogin( user.endpoints )
                    }
                })
                .finally(() => {
                    this.setState({ downloadLoad: false })
                })
        })
    }

    prepareDeep = ( record, index, columns ) =>
        format.goodBad.iterate.goodBad(
            record,
            [ 'deltaTurnoverLastYear', 'deltaTurnoverLastYear', 'deltaAverageCheck', 'deltaPlanTurnover' ],
            columns
        )

    change = ( record, key, value ) => {
        const nr = format.copy.object( record ),
              report = format.copy.array( this.state.report ),
              index = format.find.index( report[ 0 ].days, 'date', record.date ),
              changedDays = format.copy.array( this.state.report[ 0 ].days )

        nr[ key ] = value
        report[ 0 ].days[ index ] = nr

        if ( format.find.index( changedDays, 'date', record.date ) !== -1 ) {
            changedDays[ format.find.index( changedDays, 'date', record.date ) ] = report[ 0 ].days[ index ]
        } else {
            changedDays.push( report[ 0 ].days[ index ])
        }

        if ( key === 'planTurnoverRgm' ) {
            nr.planTurnoverRgmChanged = true
            report[ 0 ].days[ index ] = nr
        }

        let turnover = this.summTurnover( report[ 0 ].days )

        this.setState({
            report,
            turnover,
            reportChanged:     true,
            changedDays,
            planTurnoverRgm:   this.summPlanTurnoverRgm( report[ 0 ].days ),
            deltaPlanTurnover: this.summDeltaPlanTurnover( report[ 0 ].days ),
            factTurnover:      this.summFactTurnover( report[ 0 ].days ),
            deltaTurnover:     this.summDeltaTurnover( report[ 0 ].days ),
            planAverageCheck:  this.props.deep?.planAverageCheck,
        })
    }

    summTurnover = ( report ) =>
        report.reduce(( summ, r ) => summ + r.planTurnoverRgm, 0 )

    summPlanTurnoverRgm = ( report ) =>
        report?.reduce(( summ, row ) => summ + row.planTurnoverRgm, 0 )
    summDeltaTurnover = ( overview ) =>
        overview?.reduce(( summ, row ) => summ + row.deltaTurnover, 0 )

    summDeltaPlanTurnover = ( overview ) =>
        overview?.reduce(( summ, row ) => summ + row.deltaPlanTurnover, 0 )

    summFactTurnover = ( overview ) =>
        overview?.reduce(( summ, row ) => summ + row.factTurnover, 0 )

    summPlanAverageCheck = ( overview ) =>
        overview?.reduce(( summ, row ) => summ + row.planAverageCheck, 0 )

    update = ( props ) =>
        this.setState({
            data: props.report.find(
                ( r ) => r.factsNumber.toString() === this.deepId()
            ),
        })

    load = ( props ) => {
        const { uploading } = this.state

        !uploading && props.flushPlanningReport()

        const month = props.request.month ?
            moment( props.request.month ).format( config.format.monthAPI ) :
            moment().format( config.format.monthAPI )
        this.reportLoading = true
        props.fetchPlanning({
            factsNumbers: [ this.deepId() ],
            month:        month,
        }).finally(() => this.reportLoading = false )
    }

    getDeep = ( props ) => {
        if ( !props.report ) {
            return
        }

        const deep = props.report.find(
            ( r ) => r.factsNumber === parseInt( this.deepId())
        )

        props.setDeep( deep )

        this.setState({
            turnover:          deep?.planValue,
            planTurnoverRgm:   deep.planTurnoverRgm,
            factTurnover:      deep.factTurnover,
            deltaTurnover:     deep.deltaTurnover,
            planAverageCheck:  deep.planAverageCheck,
            deltaPlanTurnover: deep.deltaPlanTurnover,
            average:           deep?.planAverageCheck,
        })
    }

    undeep = () => this.props.history.push( '/planning' )

    cancel = () => {
        const { deep } = this.props,
              { originalDeep } = this.state

        this.updateReport( this.props )
        this.setState({
            turnover:          deep?.planValue,
            planTurnoverRgm:   deep?.planTurnoverRgm,
            factTurnover:      deep?.factTurnover,
            deltaTurnover:     deep?.deltaTurnover,
            planAverageCheck:  deep?.planAverageCheck,
            deltaPlanTurnover: deep?.deltaPlanTurnover,
            average:           deep?.planAverageCheck,
            reportChanged:     false,
            deep:              originalDeep,
        })
    }

    getChanged = () => {
        if ( !this.state.report ) {
            return []
        }

        const { report } = this.state
        return format.copy.array(report[ 0 ].days).filter((row) => {
            const original = this.props.report[ 0 ].days.find(
                (r) => r.date === row.date
            )
            return (
                original.planTurnoverAreaCoach !== row.planTurnoverAreaCoach ||
            original.planTurnoverRgm !== row.planTurnoverRgm ||
            original.predictDate !== row.predictDate
            )
        })
    }

    save = () => {
        const { deep } = this.props
        const { report, reportChanged } = this.state
        const days = this.getChanged()

        const reqData = days.map(( row ) => {
            return {
                date:            row.date.split( 'T' )[ 0 ],
                planTurnoverRgm: row.planTurnoverRgm,
                predictDate:     row.predictDate
            }
        })

        this.props.flushPlanningError()

        if ( reportChanged ) {
            deep.planValue = this.summTurnover( report )
            this.props.updateRestaurantTargets({
                restaurantId: deep.factsNumber,
                body:         reqData,
            }).finally(() => this.setState({ uploading: false, }))
        }

        this.setState({
            reportChanged: false,
            changed:       false,
            uploading:     true,
        })
    }

    upload = ( info ) => {
        if ( info.file.status === 'uploading' ) {
            this.setState({ uploading: true, })
        }

        if ( info.file.status === 'error' ) {
            if ( info.file.response.status === 401 || info.file.response.status === 403 ) {
                format.update.goToLogin( this.props.user.endpoints )
                return
            }
            this.props.applyError({
                status:    parseInt( info.file.response.code.toString().substr( 0, 3 )),
                errorData: info.file.response,
            })
            this.load( this.props )
        }

        if ( info.file.status === 'done' ) {
            this.load( this.props )
        }
    }

    changed = () => {
        const { reportChanged } = this.state

        if ( !reportChanged ) {
            return null
        }

        return (
            <Alert
                text={'Изменения вступят в силу после сохранения'}
                button={true}
                buttonText="Сохранить"
                action={this.save}
                cancel={true}
                cancelText="Отменить изменения"
                onCancel={this.cancel}
            />
        )
    }

    configColumns = () => {
        const columns = format.copy.array( config.tables.planning ),
              prevyear = columns.find(( c ) => c.dataIndex === 'factTurnoverLastYear' ),
              predictDate = columns.find(( c ) => c.dataIndex === 'predictDate' ),
              plan = columns.find(( c ) => c.dataIndex === 'planTurnoverRgm' ),
              planTurnoverRgmChanged = columns.find(( c ) => c.dataIndex === 'planTurnoverRgmChanged' ),
              check = columns.find(( c ) => c.dataIndex === 'planAverageCheck' )

        prevyear.render = ( text, record ) => (
            <div className="with-origin">
                {record.origin && <span className="origin-indicator"></span>}
                {format.strings.thousand( text )}
            </div>
        )

        predictDate.render = ( text, record ) => (
            <div className="predict-date-picker">
                <DatePicker
                    format="D MMMM YYYY"
                    disabled={moment( record.date ).isBefore( moment().subtract( 1, 'day' ))}
                    locale={ruRU}
                    disabledDate={( date ) =>
                        date.isBefore( moment().subtract( 1, 'year' ).subtract( 1, 'day' )) ||
                        date.isAfter( moment().subtract( 1, 'day' ))
                    }
                    allowClear={true}
                    onChange={( value ) => {
                        this.changePredictDate( value, record )
                        if ( value ) {
                            const nr = format.copy.object( record ),
                                  report = format.copy.array( this.state.report ),
                                  index = format.find.index( report[ 0 ].days, 'date', record.date )
                            nr.predictDate = moment( value ).format( 'YYYY-MM-DD' )
                            report[ 0 ].days[ index ] = nr
                            this.setState({
                                reportChanged: true,
                                report
                            })
                        }
                    }}
                    placeholder=""
                    value={text ? moment( text ) : null}
                    suffixIcon={<CalendarOutlined className={moment( record.date ).isBefore( moment().subtract( 1, 'day' )) ? '' : 'accent-color'} />}
                />
            </div>
        )
        predictDate.disabled = ( record ) => moment( record.date ).isBefore( moment().subtract( 1, 'day' ))

        plan.disabled = ( record ) => moment( record.date ).isBefore( moment().subtract( 1, 'day' ))

        planTurnoverRgmChanged.render = ( value, record ) => value === true && (
            <span onClick={() => {
                const nr = format.copy.object( record ),
                      report = format.copy.array( this.state.report ),
                      index = format.find.index( report[ 0 ].days, 'date', record.date )
                nr.planTurnoverRgm = report[ 0 ].days[ index ].planTurnoverAreaCoach
                nr.planTurnoverRgmChanged = false
                report[ 0 ].days[ index ] = nr
                this.setState({
                    report,
                    reportChanged:   true,
                    planTurnoverRgm: this.summPlanTurnoverRgm( report[ 0 ].days ),
                })
            }}>
                <CloseCircleOutlined className='accent-color'/>
            </span>
        )

        check.disabled = ( record ) =>
            !record.factTurnoverLastYear || moment( record.date ).isBefore( moment())

        this.setState({ columns })
    }

    changePredictDate = ( value, record ) => {
        const { report } = this.state
        this.setState({ currDate: record.date })
        if ( value ) {
            this.props.fetchSummary({
                dateStart:     `${moment( value ).format( 'YYYY-MM-DD' )}T00:00`,
                dateEnd:       `${moment( value ).format( 'YYYY-MM-DD' )}T23:59`,
                unitOfMeasure: 'currency',
                factsNumber:   report[ 0 ].factsNumber,
            })
        } else {
            const nr = format.copy.object( record ),
                  report = format.copy.array( this.state.report ),
                  index = format.find.index( report[ 0 ].days, 'date', record.date )
            nr.planTurnoverRgm = report[ 0 ].days[ index ].planTurnoverAreaCoach
            nr.predictDate = null
            report[ 0 ].days[ index ] = nr
            this.setState({
                report,
                planTurnoverRgm: this.summPlanTurnoverRgm( report[ 0 ].days ),
                reportChanged:   true
            })
        }
    }

    render () {
        const { error, length, deep } = this.props,
              {
                  report,
                  turnover,
                  month,
                  reportChanged,
                  uploading,
                  columns,
                  downloadLoad,
                  planTurnoverRgm,
                  deltaPlanTurnover,
                  factTurnover,
                  deltaTurnover,
                  planAverageCheck,
              } = this.state

        if ( !deep ) {
            return <Spinner/>
        }

        return (
            <section className="kfc-planning kfc-planning-deep">
                <Layout>
                    <Header>
                        <AppHeader
                            hideRestaurants
                            ready={!!report}
                        />
                    </Header>
                    <Content>
                        <InnerContent
                            bigOffset={turnover !== deep?.planValue || reportChanged || error}
                        >
                            <Card>
                                {uploading && (
                                    <div className="planning-cover">
                                        <Spinner/>
                                    </div>
                                )}
                                <h2 className="calendar-switch">
                                    {length > 1 ? (
                                        <BackTitle
                                            text={deep.restaurantName}
                                            url="/planning"
                                            action={this.undeep}
                                        />
                                    ) : (
                                        deep.restaurantName
                                    )}

                                    <MonthPicker
                                        format={(date) => this.capitalizeFirstLetter(moment(date).format('MMMM YYYY'))}
                                        value={month}
                                        onChange={this.setMonth}
                                        disabled={!report}
                                        locale={ruRU}
                                        disabledDate={( current ) =>
                                            current.isBefore( moment(), 'month' )
                                        }
                                        getCalendarContainer={( trigger ) => trigger.parentNode}
                                    />
                                </h2>

                                {report && (
                                    <PlanningControls
                                        date={month}
                                        hideOnMonth={false}
                                        restaurant={deep.factsNumber}
                                        turnover={deep.planTurnoverAreaCoach}
                                        planTurnoverRgm={ planTurnoverRgm ? planTurnoverRgm : null }
                                        planAverageCheck={ planAverageCheck ? planAverageCheck : null }
                                        deltaPlanTurnover={ deltaPlanTurnover ? deltaPlanTurnover : null }
                                        factTurnover={factTurnover ? factTurnover : null}
                                        deltaTurnover={ deltaTurnover ? deltaTurnover : null}
                                        disabled={!report}
                                        disabledTO={true}
                                        downloadLoad={downloadLoad}
                                        onTurnover={this.setTurnover}
                                        onDownload={this.download}
                                        onUpload={this.upload}
                                    />
                                )}

                                <hr/>

                                {!report ? (
                                    <div></div>
                                ) : (
                                    <div className="planning-deep-scroll-table">
                                        <Report
                                            unit={'currency'}
                                            rowKey={'date'}
                                            data={format.generate.noPager( report[ 0 ].days )}
                                            columns={columns}
                                            prepare={this.prepareDeep}
                                            onChange={this.change}
                                        />
                                    </div>
                                )}

                                {this.changed()}

                                {error && (
                                    <Alert
                                        text={error}
                                        button={true}
                                        buttonText="Закрыть"
                                        action={this.props.flushPlanningError}
                                    />
                                )}
                            </Card>
                        </InnerContent>
                    </Content>
                </Layout>
            </section>
        )
    }
}

export default connect( mapStateToProps, allActions )( Planning )
