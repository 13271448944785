import React, {ReactNode, useState} from 'react';
import {Button, Card, Tabs} from 'antd';
import ReportExcelTooltip from '../../layout/ReportExcelTooltip/ReportExcelTooltip';
import InnerContent from '../../layout/InnerContent/InnerContent';
import StatisticsTab from '../statistics/StatisticsTab/StatisticsTab';
import ConsolidatedStatisticsTab from '../statistics/ConsolidatedStatisticsTab/ConsolidatedStatisticsTab';
import CustomTab from '../../layout/CustomTab/CustomTab';
import moment from 'moment';
// @ts-ignore
import ReactExport from 'react-data-export'
import {Download} from 'components/layout/icons';
import Icon from '@ant-design/icons';
import {FormatedDateFilters} from 'pages/GuestVote/types';
import {DataGroupType} from 'services/guestVote/statistic/type';
import {PickerType} from '../GuestVoteControls/types';
import {STORAGE_KEY} from '../GuestVoteTable/GuestVoteTable';
import {GustVoteTabsStoreProps} from './connector';
import {connect} from 'react-redux';
import {mapStateToProps} from './connector';
import {prepareConsolidatedStatisticsExcel, prepareHappinessIndexesExcel} from './helpers/prepareExcel';

const ExcelFile = ReactExport.ExcelFile

type GuestVoteTabsProps = {
    dateFilters: FormatedDateFilters,
    picker: PickerType
}

type GuestVoteControlsDateRange = {
    dateRange: [string, string];
}

export const GuestVoteTabs = (props: GuestVoteTabsProps & GustVoteTabsStoreProps) => {
    const {
        dateFilters,
        picker,
        happinessIndexesSummary,
        happinessIndexesSearch,
        happinessIndexesSummaryIsLoading,
        happinessIndexesSearchIsLoading,
        feedbacksSearch,
        feedbacksSearchIsLoading,
    } = props;

    const [ activeTab, setActiveTab ] = useState('statistics');
    const currentTypesFilter = (sessionStorage.getItem(STORAGE_KEY) ? sessionStorage.getItem(STORAGE_KEY) : 'RESTAURANTS') as DataGroupType;

    const prepareExcel = (name: string) => {
        switch (name) {
            case 'statistics':
                return prepareHappinessIndexesExcel(happinessIndexesSummary, happinessIndexesSearch, currentTypesFilter);
            case 'consolidated statistics':
                return prepareConsolidatedStatisticsExcel(feedbacksSearch, currentTypesFilter);
            default:
                return null
        }
    };

    const tabs: {key: 'statistics' | 'consolidated statistics'; label: string; children: ReactNode}[] = [
        // {
        //     key: 'HI',
        //     label: 'Рейтинг HI',
        //     children: 'Рейтинг HI'
        // },
        {
            key: 'statistics' as const,
            label: 'Статистика',
            children: <StatisticsTab disabledDropTypes={[ 'HEAD_OF_EMPLOYEE', 'FEEDBACK_EXECUTOR' ]}
                                     dateFilters={dateFilters} picker={picker}/>
        },
        // {
        //     key: 'request rating',
        //     label: 'Рейтинг по обращениям',
        //     children: 'Рейтинг по обращениям'
        // },
        {
            key: 'consolidated statistics' as const,
            label: 'Консолидированная статистика',
            children: <ConsolidatedStatisticsTab
                disabledDropTypes={[ 'HEAD_OF_EMPLOYEE', 'FEEDBACK_EXECUTOR', 'SALE_CHANNELS' ]}
                dateFilters={dateFilters}/>
        }
    ]

    const tabExcelBlockerConditions = {
        'HI': true,
        'statistics': happinessIndexesSummaryIsLoading || happinessIndexesSearchIsLoading,
        'request rating': true,
        'consolidated statistics': feedbacksSearchIsLoading
    }

    const getCorrectedDateRange = (picker: PickerType, dateRange: [string, string]): [string, string] => {
        const [ startDate, endDate ] = dateRange;

        if (picker === 'year') {
            const startYear = moment(startDate).year();
            const correctedStartDate = moment().set('year', startYear).startOf('year').format('YYYY-MM-DD');
            return [ correctedStartDate, endDate ];
        } else if (picker === 'month') {
            const startMonth = moment(startDate).month();
            const endMonth = moment(endDate).month();
            const correctedStartDate = moment().set('month', startMonth).startOf('month').format('YYYY-MM-DD');
            const correctedEndDate = moment().set('month', endMonth).endOf('month').format('YYYY-MM-DD');
            return [ correctedStartDate, correctedEndDate ];
        }

        return [ startDate, endDate ];
    };

    const getCurrentExcelDate = (): string => {
        const storedData = sessionStorage.getItem('guestVoteControls');
        const currentDate: GuestVoteControlsDateRange | null = storedData ? JSON.parse(storedData) : null;

        if (currentDate && currentDate.dateRange) {
            const correctedDateRange = getCorrectedDateRange(picker, currentDate.dateRange);
            const [ startDate, endDate ] = correctedDateRange;

            if (startDate !== endDate) {
                return `с ${moment(startDate).format('DD.MM.YYYY')} по ${moment(endDate).format('DD.MM.YYYY')}`;
            }

            return moment(startDate).format('DD.MM.YYYY');
        }

        return moment().format('DD.MM.YYYY');
    };


    const filteredTabs = tabs.map((tab) => ({
        ...tab,
        label:
            <CustomTab
                title={tab.label}
                icon={
                    activeTab !== tab.key || tabExcelBlockerConditions[tab.key] ?
                        <ReportExcelTooltip/> :
                        <ExcelFile
                            filename={`${tab.key}_${getCurrentExcelDate()}`}
                            element={<Button
                                type='link'
                                className='button-download'
                                disabled={activeTab !== tab.key}
                            >
                                <Icon
                                    component={Download.def}
                                    onPointerEnterCapture={undefined}
                                    onPointerLeaveCapture={undefined}/>
                            </Button>}
                        >
                            {prepareExcel(tab.key)}
                        </ExcelFile>}
                onClickIcon={undefined}
            />,
    }))

    return (
        <InnerContent>
            <Card bordered={false}>
                <Tabs destroyInactiveTabPane animated={false} items={filteredTabs}
                      onChange={(tab) => setActiveTab(tab)}/>
            </Card>
        </InnerContent>
    );
};

export default connect(mapStateToProps)(GuestVoteTabs)
