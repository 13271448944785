/* VENDOR */
import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { Layout }           from 'antd'
import moment               from 'moment'

/* APPLICATION */
import {
    Absence,
    Alert,
    AppHeader,
    StaffCards,
    FixedShifts,
    InnerContent,
    ViewTypeSwitch,
    EmployeeTransfer,
    Spinner,
    PprDirector,
    PprDirectorHeader,
    PprAreaHeader,
    PprArea,
    PprPartnerHeader,
    PprPartner
} from 'components'

import { format } from 'tools'
import config     from 'config'

import { mapStateToProps, allActions } from './connector'
import './staff.scss'


const { Content, Header } = Layout

class Staff extends Component {
    constructor ( props ) {
        super( props )
        this.state = {
            view:               'list',
            cardTab:            'main',
            setOnLoad:          null,
            list:               props.staff.list,
            employee:           props.staff.employee,
            rating:             props.staff.rating,
            periodPpr:          null,
            periodPprDirector:  null,
            periodPprArea:      null,
            periodPprPartner:   null,
            areaManager:        undefined,
            added:              [],
            errors:             false,
            loading:            false,
            saving:             false,
            transfer:           false,
            trainingLoading:    true,
            areaFiltersChanged: true
        }
    }

    componentDidMount () {
        const { request: { restaurantId }, ppr } = this.props
        this.loadList( this.props )
        this.props.fetchRoles()
        this.props.fetchCompanies()
        this.props.getSameFranchisee( restaurantId )
        if ( ppr?.periods ) {
            this.setPprPeriods()
        }
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { staff, request } = this.props

        staff.list !== nextProps.staff.list && this.setList( nextProps.staff )
        staff.employee !== nextProps.staff.employee &&
      this.setEmployee( nextProps.staff.employee )
        staff.rating !== nextProps.staff.rating &&
      this.setRating( nextProps.staff.rating )
        format.check.request( request, nextProps.request, [], [ 'inactive' ]) &&
      this.loadList( nextProps )

        if ( request.restaurantId !== nextProps.request.restaurantId ) {
            this.props.flushEmployee()
            this.props.fetchAbsence( ( { ...nextProps.request, factsNumber: nextProps.request.restaurantId ?? nextProps.request.factsNumber } ) )
            this.props.fetchFixedShifts( nextProps.request )
            this.props.fetchPositions( nextProps.request )
            this.props.fetchStaff( { ...nextProps.request, factsNumber: nextProps.request.restaurantId ?? nextProps.request.factsNumber } )
            this.props.fetchCompanies()
            this.props.getSameFranchisee( nextProps.request.restaurantId ?? nextProps.request.factsNumber )
        }
    }

    componentDidUpdate ( prevProps, prevState ) {
        const { staff, request, transfer, ppr } = this.props,
              { cardTab, view } = this.state

        if ( request.employeeId !== prevProps.request.employeeId ) {
            const found = staff.list.find(( e ) => e.id === request.employeeId )

            this.load( request )

            if ( found && cardTab === 'training' ) {
                this.loadPpr()
            }
        }

        if ( transfer !== prevProps.transfer ) {
            const found = staff.list.find(( e ) => e.id === request.employeeId )

            if ( found ) {
                this.props.fetchEmployee( request )
            }

            this.props.fetchStaff( request )
        }

        if ( cardTab !== prevState.cardTab && cardTab === 'training' ) {
            this.loadPpr()
        }

        if ( ppr?.periods !== prevProps.ppr?.periods ) {
            this.setPprPeriods()
        }

        if ( view !== prevState.view && view === 'list' ) {
            this.props.flushEmployee()
            this.props.fetchEmployee( request )
        }
    }

    setPprPeriods = () => {
        const { ppr } = this.props
        const lastPeriod = ppr?.periods?.[ 0 ],
              periodPpr = lastPeriod ?
                  moment( lastPeriod, config.format.monthAPI ).format( config.format.monthView )
                  :
                  moment().format( config.format.monthView )
        if ( !this.state.periodPpr ) { this.setState({ periodPpr }) }
        if ( !this.state.periodPprDirector ) { this.setState({ periodPprDirector: periodPpr }) }
        if ( !this.state.periodPprArea ) { this.setState({ periodPprArea: periodPpr }) }
        if ( !this.state.periodPprPartner ) { this.setState({ periodPprPartner: periodPpr }) }
    }

    componentWillUnmount () {
        this.props.flushEmployee()
        this.props.setEmployee(null)
    }

    setView = ( view ) => this.setState({ view })

    setEmployee = ( employee ) => {
        const { saving, setOnLoad } = this.state

        this.setState({
            employee,
            loading: !employee,
            saving:  employee ? false : saving,
        })
        setOnLoad && !!employee && this.props.fetchStaff( this.props.request )

        if ( employee && employee.main.id !== 'new' ) {
            this.props.fetchEmployeeRating( employee.main.id )
            this.props.fetchEmployeeRatingList({
                id:   employee.main.id,
                page: 0,
            })
        }
    }

    setRating = ( rating ) =>
        this.setState({ rating, })

    setList = ( staff ) => {
        const { setOnLoad } = this.state

        let newSetOnLoad = null

        if ( setOnLoad ) {
            const found = staff.list.find(
                ( e ) => e.id === setOnLoad || e.name === setOnLoad
            )

            if ( found ) {
                setTimeout(() => this.props.setEmployee( found.id ), 0 )
            } else {
                setTimeout(() => this.setList( staff ), 100 )
                newSetOnLoad = setOnLoad
            }
        }

        const list = this.withCreated( this.state.added, staff.list )

        this.setState({
            setOnLoad: newSetOnLoad,
            list,
        })
    }

    withCreated = ( added, data ) => {
        if ( !data ) { return null }
        if ( added.length < 1 && data.length < 1 ) { return [] }

        const res = added.concat( data )

        return res.filter(( item, index ) => {
            if ( res.indexOf( item ) !== index ) { return false }

            const found = res.find(
                ( s ) => s.id === item.id && s.fullName === item.fullName
            )

            return res.indexOf( found ) === index
        })
    }

    load = ( request ) => {
        const eid = request.employeeId,
              found = this.state.list.find(( e ) => e.id === eid )

        if ( !eid ) {
            this.setEmployee( null )
            return
        }

        if ( eid && !found ) {
            console.log( 'not found', eid )
            return
        }

        if ( !found.__new ) {
            this.props.fetchEmployee( request )
            return
        }

        this.props.newEmployee( found )
    }

    loadList = ( props ) => {
        const { staff, request } = props,
              { employee } = staff

        employee &&
      !employee?.main?.status &&
      !request.inactive &&
      !this.state.employee.__new &&
      props.flushEmployee()
        props.request.factsNumber = props.request.restaurantId

        props.flushList()
        props.fetchStaff( props.request )
    }

    loadPpr = () => {
        const {
            request,
            user,
            fetchStaffPprPositions,
            fetchStaffPprRcl,
            fetchStaffPprPositionStatuses,
            fetchStaffPprAvailablePeriods,
            fetchEmployeePpr
        } = this.props

        if ( !config.access.checkRole( 'ppr', user.info?.jobRole )) {
            return
        }
        this.setState({ trainingLoading: true }, () => {
            const getStaffPpr = () => {
                Promise.all([
                    fetchStaffPprPositions(),
                    fetchStaffPprRcl(),
                    fetchStaffPprPositionStatuses(),
                    fetchEmployeePpr({
                        employeeUuid: request.employeeId,
                        period:       moment( this.state.periodPpr, config.format.monthView ).format( config.format.monthAPI )
                    })
                ]).finally(() => this.setState({ trainingLoading: false }))
            }

            if ( !this.state.periodPpr ) {
                fetchStaffPprAvailablePeriods({ emloyeeUuid: request.employeeId }).finally(() => {
                    getStaffPpr()
                })
                return
            }

            getStaffPpr()

        })

    }

    reload = () =>
        setTimeout(() => {
            this.props.fetchStaff( this.props.request )
            this.props.setEmployee( null )
        }, 500 )

    onRemove = ( employee ) => {
        this.props.flushEmployee()

        if ( employee.__new ) {
            const added = format.copy.array( this.state.added )

            added.splice( added.indexOf( employee ), 1 )

            this.setState({ added })
        } else {
            this.props.removeEmployee( employee.id )
        }

        this.offError()
        this.reload()
    }

    onSelect = ( id ) => {
        if ( id === this.props.request.employeeId ) { return }

        this.setState({ loading: true }, () => {
            this.props.flushEmployee()
            this.props.flushEmployeeRating()
            this.props.setEmployee( id )
            this.offError()
        })
    }

    onSave = ( data ) => {
        const { employee } = this.props.staff,
              emp = format.copy.object( data )

        if ( emp.__new ) {
            const added = format.copy.array( this.state.added ),
                  index = format.find.index( added, 'id', emp.id )

            added.splice( index, 1 )
            emp.id = 'new'
            delete emp.__new

            //this.props.flushEmployee()

            this.setState({
                added,
                saving: true,
            })
        }

        if ( !emp.fullName ) {
            emp.fullName = emp.firstName + ' ' + emp.lastName
        }

        emp.scheduleName = emp.scheduleName || emp.fullName
        delete emp._protect_employee

        emp.salary = parseFloat( emp.salary )
        emp.rate = parseFloat( emp.rate )
        emp.gender = emp.gender ? emp.gender.toUpperCase() : emp.gender

        this.offError()

        const toSaveData = format.compare.diffext(
            format.compare.diff( employee.main, emp, [
                'firstName',
                'lastName',
                'middleName',
                'salary',
                'gender',
                'phone',
                'address',
                'comment',
                'employeeName',
                'dateStartWork',
                'dateEndWork',
                'birthDate',
                'email',
                'city',
                'externalSystemName',
                'paymentType',
                'isStudies',
                'isUnderage2200',
                'isUnderage2130',
            ]),
            'b'
        )

        toSaveData.rateDictionary = emp.rateDictionary

        if ( Object.keys( toSaveData ).length > 0 ) {
            emp.id === 'new'
                ? this.props.addEmployee({
                    data:   emp,
                    params: { employeeId: emp.id, },
                })
                : this.props.saveEmployee({
                    data:   toSaveData,
                    params: { employeeId: emp.id, },
                })

            this.setState({
                employee: {
                    ...this.state.employee,
                    main: emp,
                },
                setOnLoad: emp.id === 'new' ? emp.scheduleName : emp.id,
                saving:    true,
            })
        }
    }

    onAdd = () => {
        const added = format.copy.array( this.state.added ),
              emp = format.copy.object( config.defs.employee )

        emp.scheduleName = 'Новый сотрудник ' + this.findNextIndex( added )
        emp.id = format.generate.guid()

        added.push({
            __new:        true,
            name:         emp.scheduleName,
            id:           emp.id,
            main:         emp,
            oppotunities: [],
            positions:    [],
        })

        this.setState({
            added,
            errors: false,
            list:   this.withCreated( added, this.state.list ),
        })

        this.props.setEmployee( emp.id )
    }

    onTransfer = () =>
        this.setState({ transfer: true, })

    found = ( data, index ) => data.find(( item ) => item.name.indexOf( index ) > -1 )

    findNextIndex = ( data ) => {
        let i = 1

        while ( this.found( data, i )) {
            i++
        }

        return i
    }

    onDismissed = ( data ) => this.props.setInactive( data )

    onError = () =>
        this.setState({ errors: true, })

    offError = () =>
        this.setState({ errors: false, })

    appendRating = () => {
        const { staff } = this.props

        this.props.appendEmployeeRatingList({
            id:   staff.employee.main.id,
            page: staff.rating.list.number + 1,
        })
    }

    close = () =>
        this.setState({ transfer: false, })

    transfer = ( data ) => {
        this.props.transferEmployee({
            userId:                 data.employee,
            transferredFactsNumber: data.restaurant,
            originalFactsNumber:    this.props.request.restaurantId,
            dateTo:                 data.date.format( 'YYYY-MM-DD' ),
        })
    }

    cancelTransfer = data => e => {
        ( e ) && ( e.preventDefault())
        this.props.cancelTransferEmployee( data.id )
    }

    onPprSave = ( params ) => {
        const data = format.copy.object( params ),
              id = params.employeeUuid,
              pprId = params.pprUuid,
              trainings = Object.keys( config.defs.pprStudies )
        delete data.employeeUuid
        delete data.prUuid
        delete data.period
        data.isBench = !!data.isBench
        trainings.forEach( key => {
            data[ key ] = !!data.finishedTrainings?.find( item => item === key )
        })

        data.position = { id: +data.position }
        data.positionStatus = { id: +data.positionStatus }
        if ( data.rclCode ) { data.rclCode = { id: +data.rclCode } }
        data.positionStartDate = moment( data.positionStartDate ).format( 'YYYY-MM-DDTHH:mm:ss' )
        if ( data.rclDate ) { data.rclDate = moment( data.rclDate ).format( 'YYYY-MM-DDTHH:mm:ss' ) }

        this.setState({ saving: true }, () => {
            if ( pprId ) {
                this.props.updateEmployeePpr({
                    id,
                    pprId,
                    data
                }).finally(() => this.setState({ saving: false }))
            } else {
                this.props.addEmployeePpr({
                    id,
                    data
                }).finally(() => this.setState({ saving: false }))
            }
        })
    }

    onChangePeriod = ( periodPpr ) => {
        this.props.flushEmployeePpr()
        this.setState({ periodPpr }, () => {
            this.loadPpr()
        })
    }

    onChangeDirectorPeriod = ( periodPprDirector ) => this.setState({ periodPprDirector })

    onChangeArea = ({ periodPprArea, areaManager }) => this.setState({ periodPprArea, areaManager })

    onChangePartnerPeriod = ( periodPprPartner ) => this.setState({ periodPprPartner })

    onChangeCardTab = ( cardTab ) => this.setState({ cardTab })



    content = () => {
        const { loading, list, employee, rating, periodPpr, periodPprDirector, trainingLoading } = this.state,
              { periodPprArea, areaManager, periodPprPartner, areaFiltersChanged } = this.state,
              { request, roles, companies, rates, getRates, staff, user } = this.props

        switch ( this.state.view ) {
            case 'absence':
                return <Absence />
            case 'fixed':
                return <FixedShifts />
            case 'ppr':
                return <PprDirector
                    period={periodPprDirector}
                />
            case 'pprArea':
                return <PprArea
                    period={periodPprArea}
                    userId={areaManager}
                    areaFiltersChanged={areaFiltersChanged}
                />
            case 'pprPartner':
                return <PprPartner
                    period={periodPprPartner}
                />
            case 'list':
            default:
                return (
                    <StaffCards
                        loading={loading}
                        inactive={request.inactive}
                        restaurant={request.restaurantId}
                        current={request.employeeId}
                        roles={roles}
                        list={list}
                        employee={employee}
                        rating={rating}
                        companies={companies}
                        user={user}
                        onSave={this.onSave}
                        onSelect={this.onSelect}
                        onAdd={this.onAdd}
                        onTransfer={this.onTransfer}
                        onRemove={this.onRemove}
                        onError={this.onError}
                        onDismissed={this.onDismissed}
                        onMore={this.appendRating}
                        getRates={getRates}
                        rates={rates}
                        trainingLoading={trainingLoading}
                        ppr={staff.ppr}
                        periodPpr={periodPpr}
                        onCancelTransfer={this.cancelTransfer}
                        onPprSave={this.onPprSave}
                        onChangePeriod={this.onChangePeriod}
                        onChangeCardTab={this.onChangeCardTab}
                    />
                )
        }
    }

    pprHeader = () => {
        const { view, periodPprDirector, periodPprArea, areaManager, periodPprPartner } = this.state,
              { ppr } = this.props
        switch ( view ) {
            case 'ppr':
                return <PprDirectorHeader
                    date={periodPprDirector}
                    periods={ppr?.periods}
                    periodsError={ppr?.periods_error}
                    onChange={this.onChangeDirectorPeriod}
                />
            case 'pprArea':
                return <PprAreaHeader
                    date={periodPprArea}
                    onChange={this.onChangeArea}
                    areaManager={areaManager}
                    onChanged={this.onAreaFiltersChanged}
                />
            case 'pprPartner': {
                return <PprPartnerHeader
                    date={periodPprPartner}
                    periods={ppr?.periods}
                    periodsError={ppr?.periods_error}
                    onChange={this.onChangePartnerPeriod}
                />
            }
            default: return null
        }
    }

    onAreaFiltersChanged = ( areaFiltersChanged ) => {
        if ( areaFiltersChanged !== this.state.areaFiltersChanged ) {
            this.setState({ areaFiltersChanged })
        }
    }

    render () {
        const
            { request, user } = this.props,
            { list, transfer, saving } = this.state,
            tabs = {
                list:    'Карточки сотрудников',
                fixed:   'Фиксированные смены',
                absence: 'Отсутствующие',
                //...( config.access.checkRole( 'ppr', user.info?.jobRole ) && { ppr: 'PPR Ресторан' }),
                //...( config.access.checkRole( 'pprArea', user.info?.jobRole ) && { pprArea: 'PPR Территория' }),
                //...( config.access.checkRole( 'pprPartner', user.info?.jobRole ) && { pprPartner: 'PPR Партнер' }),
            }

        return (
            <section className="kfc-staff kfc-tabbed-page">
                <Layout>
                    <Header>
                        <AppHeader
                            ready={!!list}
                            timeData={parseInt( request.restaurantId )}
                        />
                    </Header>
                    <Content>
                        <ViewTypeSwitch
                            update={this.setView}
                            tabs={tabs}
                            current={this.state.view}
                        />

                        {this.pprHeader()}

                        <InnerContent
                            bigOffset={!!this.state.errors}
                            fixed={this.state.view === 'list'}
                        >
                            <div className="tabbed-content">{this.content()}</div>
                            <EmployeeTransfer
                                employees={this.props.staff.list}
                                restaurants={this.props.franchisee}
                                visible={transfer}
                                onSubmit={this.transfer}
                                onCancel={this.close}
                            />
                        </InnerContent>

                        {this.state.errors && (
                            <Alert text="Для добавления в систему нужно заполнить обязательные поля" />
                        )}

                        {saving && (
                            <div className="saving-overlay">
                                <Spinner />
                            </div>
                        )}
                    </Content>
                </Layout>
            </section>
        )
    }
}

export default connect( mapStateToProps, allActions )( Staff )
