/* VENDOR */
import React, { Component }                               from 'react'
import { connect }                                        from 'react-redux'
import moment                                             from 'moment'
import 'moment/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU'
import { Layout, Card, DatePicker, Popover, Modal, Spin } from 'antd'

/* APPLICATION */
import {
    Alert,
    AppHeader,
    InnerContent,
    Link,
    OriginModal,
    PlanningControls,
    Report,
    SaveProgress,
    Spinner,
} from 'components'

import { format } from 'tools'
import config     from 'config'

import { mapStateToProps, allActions } from './connector'
import './planning.scss'

const { Content, Header } = Layout,
      { MonthPicker } = DatePicker

let spmhTimer = null

class Planning extends Component {
    constructor ( props ) {
        super( props )

        this.state = {
            changed: false,

            month: props.request.month
                ? moment( props.request.month )
                : moment(),
            turnover:          0,
            planTurnoverRgm:   0,
            deltaPlanTurnover: 0,
            factTurnover:      0,
            deltaTurnover:     0,
            planAverageCheck:  0,

            overview:     null,
            uploading:    false,
            origin:       false,
            originTarget: null,
            confirm:      false,
            save:         false,
            canClose:     false,
            saving:       false,
            requestLoad:  !!props.request.month,
            downloadLoad: false,
            lastYear:     null,

            columns: [],
        }
    }

    componentDidMount () {
        let datesInStorage = JSON.parse( sessionStorage.getItem( 'planningData' ))
        if ( datesInStorage ) {
            this.setState({ month: moment( datesInStorage.start ).format( config.format.monthAPI ), })
        } else {
            sessionStorage.setItem( 'planningData', JSON.stringify({
                start: moment( this.state.month ).format( config.format.monthAPI ),
                end:   moment( this.state.month ).format( config.format.monthAPI ),
            }))
        }
        this.load( this.props )
        !this.props.request.month &&
        this.props.setMonth( datesInStorage ? moment( datesInStorage.start ).format( config.format.monthAPI ) : moment().format( config.format.monthAPI ))
        this.configColumns()
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { request, overview, lastYear } = this.props,
              { requestLoad } = this.state

        if ( lastYear !== nextProps.lastYear ) {
            this.setState({ lastYear })
        }

        if ( request.month !== nextProps.request.month ) {
            this.setState(
                {
                    month:       moment( nextProps.request.month ),
                    requestLoad: true,
                },
                () => requestLoad && this.load( nextProps )
            )
        }

        overview?.number !== nextProps.overview?.number && this.setOverview( nextProps.overview )
    }

    componentDidUpdate ( prevProps ) {
        const { errors, updated, lastYear } = this.props,
              { saving } = this.state

        if ( saving && lastYear !== prevProps.lastYear ) {
            this.change(
                saving.origin,
                [ '_oldLastYearValue', '_oldLastYearDelta', 'factTurnoverLastYear', 'origin' ],
                [
                    saving.origin.factTurnoverLastYear,
                    saving.origin.deltaTurnoverLastYear,
                    lastYear?.data,
                    {
                        originIdent:       saving.found.restaurantIdent || lastYear?.ident,
                        originFactsNumber: saving.found.factsNumber,
                        originName:        saving.found.restaurantName,
                    },
                ]
            )
            this.setState({ saving: null })
        }

        errors !== prevProps.errors &&
        this.setState(
            {
                save:     false,
                canClose: true,
            }
        )
        updated !== prevProps.updated && this.setState({ canClose: true })
    }

    showPopup = ( originTarget ) => {
        return () => {
            this.props.flushOrigins()
            this.props.getSameFranchiseeOrigins( originTarget.factsNumber )
            this.setState({
                origin: true,
                originTarget,
            })
        }
    }

    configColumns = () => {
        const columns = format.copy.array( config.tables.overview ),
              prevyear = columns.find(( c ) => c.dataIndex === 'factTurnoverLastYear' ),
              plan = columns.find(( c ) => c.dataIndex === 'planTurnoverAreaCoach' ),
              rawPlan = format.copy.object( plan ),
              noOrigin = ( r ) => !r.factTurnoverLastYear && !r.origin,
              popupLink = ( record ) =>
                  record.factsNumber === this.state.saving?.origin?.factsNumber ? (
                      <Spin size="small"/>
                  ) : (
                      <Link onClick={this.showPopup( record )}>Выбрать аналог</Link>
                  )

        delete rawPlan.render

        prevyear.render = ( text, record ) => {
            if ( noOrigin( record )) {
                return popupLink( record )
            }
            if ( record.origin ) {
                return (
                    <Popover
                        overlayClassName="lastyear-popover"
                        content={
                            <div>
                                <p>
                                    Прошлогодние показатели ресторана{' '}
                                    {record.origin.originName} ({record.origin.originFactsNumber})
                                </p>
                                <Link onClick={this.showPopup( record )}>Сменить</Link>
                                <Link onClick={this.askRemove( record )} className="remove">
                                    Убрать
                                </Link>
                            </div>
                        }
                    >
                        <div className="with-origin">
                            <span className="origin-indicator"></span>
                            {format.strings.thousand( text )}
                        </div>
                    </Popover>
                )
            }

            return (
                <span className="hover-origin">
                    {popupLink( record )}
                    <em>
                        {record.origin
                            ? format.strings.thousand( record.factTurnoverLastYear )
                            : format.strings.thousand( text )}
                    </em>
                </span>
            )
        }

        this.setState({ columns })
    }

    setOverview = ( data ) => {
        if ( !data ) {
            this.setState({ overview: null, })

            return
        }

        const overview = data.content,
              isDeep = overview.length < 2

        if ( isDeep ) {
            this.deep( overview[ 0 ], overview )
        }

        this.setState({
            overview: {
                ...data,
                content: overview
            },
            uploading:         false,
            turnover:          this.summTurnover( overview ),
            deltaPlanTurnover: this.summDeltaPlanTurnover( overview ),
            planTurnoverRgm:   this.summPlanTurnoverRgm( overview ),
            factTurnover:      this.summFactTurnover( overview ),
            deltaTurnover:     this.summDeltaTurnover( overview ),
            planAverageCheck:  this.summPlanAverageCheck( overview ),
        })
    }

    summTurnover = ( overview ) =>
        overview?.reduce(( summ, row ) => summ + row.planTurnoverAreaCoach, 0 )

    summPlanTurnoverRgm = ( overview ) =>
        overview?.reduce(( summ, row ) => summ + row.planTurnoverRgm, 0 )

    summDeltaTurnover = ( overview ) =>
        overview?.reduce(( summ, row ) => summ + row.deltaTurnover, 0 )

    summDeltaPlanTurnover = ( overview ) =>
        overview?.reduce(( summ, row ) => summ + row.deltaPlanTurnover, 0 )

    summFactTurnover = ( overview ) =>
        overview?.reduce(( summ, row ) => summ + row.factTurnover, 0 )

    summPlanAverageCheck = ( overview ) =>
        overview?.reduce(( summ, row ) => summ + row.planAverageCheck, 0 )

    load = ( rprops ) => {
        const props = rprops || this.props,
              { user } = props,
              { month, uploading } = this.state,
              datesInStorage = JSON.parse( sessionStorage.getItem( 'planningData' ))

        !uploading && props.flushPlanningOverview()

        props.fetchOverview({
            page:         0,
            factsNumbers: user.restaurant.grantedRestaurants,
            month:        datesInStorage ? moment( datesInStorage.start ).format( config.format.monthAPI ) : month.format( config.format.monthAPI ),
        })

        if ( datesInStorage && datesInStorage.lastPage === 'planning' ) {
            this.setState({ month: moment( datesInStorage.start ).format( config.format.monthAPI ), })
        }
    }

    loadMore = () => {
        const props = this.props,
              { user } = props,
              { month } = this.state
        let datesInStorage = JSON.parse( sessionStorage.getItem( 'planningData' ))
        const formatedMonth = month && typeof month === 'string' ? month : month?.format( config.format.monthAPI )
        props.appendOverview({
            page:         this.props.overview.number + 1,
            factsNumbers: user.restaurant.grantedRestaurants,
            month:        month ? formatedMonth : moment(datesInStorage.start).format( config.format.monthAPI ),
        })
    }

    reload = () => {
        this.load( this.props )
        this.setState({ save: false, })
    }

    setTurnover = ( turnover ) => {
        const overview = format.copy.array( this.state.overview ),
              val = Math.floor( turnover / overview.length )

        overview.forEach(( row ) => {
            row.planTurnoverAreaCoach = val
        })

        this.setState({
            turnover,
            overview: {
                ...this.state.overview,
                content: overview
            },
            changed: true,
        })
    }

    setMonth = ( month ) => {
        const datesInStorage = JSON.parse( sessionStorage.getItem( 'planningData' ))

        datesInStorage.start = month.startOf( 'month' ).format( config.format.date )
        datesInStorage.end = month.endOf( 'month' ).format( config.format.date )
        datesInStorage.predef = 'month'
        datesInStorage.group = 'month'
        datesInStorage.lastPage = 'planning'
        sessionStorage.setItem( 'planningData', JSON.stringify( datesInStorage ))

        this.props.setMonth( month.format( config.format.monthAPI ))
    }

    download = () => {
        this.setState({ downloadLoad: true }, () => {
            const { user } = this.props
            let { month } = this.state
            if ( !month ) { return }
            if (typeof month === 'string' && month.length) {
                month = moment( month )
            }
            const rests = user.restaurant.grantedRestaurants.join( ',' ),
                  date = month.format( config.format.date ),
                  url = `${process.env.REACT_APP_DASHBOARD_API}goals/excel?restaurants=${rests}&date=${date}`

            fetch( url, { headers: { 'Authorization': `Bearer ${localStorage.getItem( 'access_token' )}` } })
                .then(( response ) => response.blob())
                .then(( blob ) => {
                    const _url = window.URL.createObjectURL( blob )
                    window.open( _url, '_blank' ).focus()
                })
                .catch(( error ) => {
                    if ( error.status === 401 || error.status === 403 ) {
                        format.update.goToLogin( user.endpoints )
                    }
                })
                .finally(() => {
                    this.setState({ downloadLoad: false })
                })
        })

    }

    prepare = ( record, index, columns ) =>
        format.goodBad.iterate.goodBad(
            record,
            [ 'deltaTurnover', 'deltaTurnoverLastYear', 'deltaPlanTurnover' ],
            columns
        )

    applyOrigin = ( record ) => {
        const { origins, overview } = this.props,
              { originTarget, month } = this.state,
              found = origins.find(( r ) => r.factsNumber === record.factsNumber ),
              foundInAccess = overview.content.find(
                  ( r ) => r.factsNumber === record.factsNumber
              )
        let datesInStorage = JSON.parse( sessionStorage.getItem( 'planningData' ))
        const formatedMonth = month && typeof month === 'string' ? month : month?.format( config.format.monthAPI )

        if ( foundInAccess ) {
            this.change(
                originTarget,
                [ '_oldLastYearValue', '_oldLastYearDelta', 'factTurnoverLastYear', 'origin' ],
                [
                    originTarget.factTurnoverLastYear,
                    originTarget.deltaTurnoverLastYear,
                    foundInAccess.factTurnoverLastYear,
                    {
                        originIdent:       foundInAccess.restaurantIdent,
                        originFactsNumber: foundInAccess.factsNumber,
                        originName:        foundInAccess.restaurantName,
                    },
                ]
            )
            if ( foundInAccess.factTurnover ) {
                this.setState({ changed: true })
            }
        } else {
            this.props.getUnknownLastYear({
                month:        month ? formatedMonth : moment(datesInStorage.start).format( config.format.monthAPI ),
                factsNumbers: [ found.factsNumber ],
            })
            this.setState({
                saving: {
                    origin: originTarget,
                    found,
                },
            })
        }
    }

    askRemove = ( record ) => {
        return () =>
            this.setState({
                originTarget: record,
                confirm:      true,
            })
    }

    hidePopup = () =>
        this.setState({
            originTarget: null,
            confirm:      false,
            origin:       false,
        })

    removeOrigin = () => {
        this.change(
            this.state.originTarget,
            [ 'factTurnoverLastYear', 'deltaTurnoverLastYear', 'origin' ],
            [
                this.state.originTarget._oldLastYearValue || null,
                this.state.originTarget._oldLastYearDelta || null,
                null,
            ]
        )
        this.hidePopup()
    }

    change = ( record, key, value ) => {
        const { month } = this.state,
              nr = format.copy.object( record ),
              overview = format.copy.array( this.state.overview.content ),
              index = format.find.index( overview, 'factsNumber', record.factsNumber )

        // if (key === 'planTurnoverAreaCoach' && (value === 0 || value === '')) {
        //     return
        // }

        // console.log('change', record, key, value)

        Array.isArray( key )
            ? key.map(( k, i ) => ( nr[ k ] = value[ i ]))
            : ( nr[ key ] = value )

        overview[ index ] = nr

        if ( key === 'planSpmh' ) {
            spmhTimer && clearTimeout( spmhTimer )
            spmhTimer = setTimeout(() => {
                this.props.updateSPMHGoal({
                    restaurantId: record.factsNumber,
                    date:         moment( month ).format( config.format.dayAPI ),
                    goal:         value,
                })
            }, 1000 )
        }

        this.setState({
            overview: {
                ...this.state.overview,
                content: overview
            },
            changed: key === 'planSpmh' ? this.state.changed : !format.compare.arrays(overview, this.props.overview.content),
        })
        // console.log(format.compare.arrays(overview, this.props.overview.content))

        // if (key === 'planSpmh') {
        //     return
        // }

    }

    deep = ( deep, list ) => {
        const overview = list || this.props.overview.content

        this.props.setDeep( deep )
        this.props.setLength( overview.length )
        this.props.history.push( '/planning/' + deep.factsNumber.toString())
    }

    cancel = () => {
        this.setOverview( this.props.overview )
        this.setState({
            changed:      false,
            originTarget: null,
        })
    }

    save = () => {
        const { month, overview } = this.state,
              values = this.getChanged()

        if ( !values.length ) {
            const req = overview.content.map(( row ) => {
                return {
                    origin:                row.origin,
                    factsNumber:           row.factsNumber,
                    planTurnoverAreaCoach: row.planTurnoverAreaCoach
                }
            })
            this.props.updateTargets({
                body:  req,
                query: { month: moment( month ).startOf( 'month' ).format( config.format.monthAPI ), },
            })
        } else {
            const body = values.map(( row ) => {
                return {
                    origin:                row.origin,
                    factsNumber:           row.factsNumber,
                    planTurnoverAreaCoach: row.planTurnoverAreaCoach
                }
            })

            this.props.updateTargets({
                body,
                query: { month: moment( month ).startOf( 'month' ).format( config.format.monthAPI ), },
            })
        }

        this.props.flushPlanningError()

        this.setState({
            changed:  false,
            save:     true,
            canClose: false,
        })
    }

    upload = ( info ) => {
        if ( info.file.status === 'uploading' ) {
            this.setState({ uploading: true, })
        }

        if ( info.file.status === 'error' ) {
            if ( info.file.response.status === 401 || info.file.response.status === 403 ) {
                format.update.goToLogin( this.props.user.endpoints )
                return
            }
            this.props.applyError({
                status:    parseInt( info.file.response.code.toString().substr( 0, 3 )),
                errorData: info.file.response,
            })
            this.load( this.props )
        }

        if ( info.file.status === 'done' ) {
            this.load( this.props )
        }
    }

    capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };


    noPlan = () => {
        const { changed } = this.state

        if ( !changed ) {
            return false
        }

        const data = this.getChanged()

        return data
            .filter(( r ) => !r.planTurnoverAreaCoach )
            .map(( rest ) => `${rest.restaurantName} (${rest.factsNumber})` )
    }

    getChanged = () => {
        if ( !this.state.overview ) {
            return []
        }

        const { overview } = this.state,
              body = format.copy.array( overview?.content ).filter(( row ) => {
                  const original = this.props.overview.content.find(
                      ( r ) => r.factsNumber === row.factsNumber
                  )
                  if (original.planTurnoverAreaCoach === null && row.planTurnoverAreaCoach === 0) {
                      row.planTurnoverAreaCoach = null
                  }
                  return (
                      original.planTurnoverAreaCoach !== row.planTurnoverAreaCoach ||
                    original.origin?.originIdent !== row.origin?.originIdent
                  )
              })

        return body
    }

    errors = ( data ) => {
        const noPlan = this.getChanged().filter(( r ) => !r.planTurnoverAreaCoach ),
              res = format.copy.array( data )

        res.forEach(( row ) => ( row._error = false ))
        noPlan.forEach(
            ( row ) =>
                ( res.find(( r ) => r.factsNumber === row.factsNumber )._error = true )
        )

        return res
    }

    render () {
        const { error, origins } = this.props,
              {
                  turnover,
                  planTurnoverRgm,
                  average,
                  month,
                  overview,
                  columns,
                  save,
                  downloadLoad,
                  deltaPlanTurnover,
                  factTurnover,
                  deltaTurnover,
                  planAverageCheck,
              } = this.state,
              { changed, uploading, origin, originTarget, canClose } = this.state,
              noPlan = this.noPlan(),
              someChanged = this.getChanged().length

        return (
            <section className="kfc-planning">
                <Layout>
                    <Header>
                        <AppHeader hideRestaurants={true}/>
                    </Header>
                    <Content>
                        <InnerContent bigOffset={changed || error}>
                            <Card>
                                {uploading && (
                                    <div className="planning-cover">
                                        <Spinner/>
                                    </div>
                                )}
                                <h2 className="calendar-switch">
                                    Общий товарооборот
                                    <MonthPicker
                                        format={(date) => this.capitalizeFirstLetter(moment(date).format('MMMM YYYY'))}
                                        value={moment( month )}
                                        onChange={this.setMonth}
                                        disabled={!overview?.content}
                                        disabledDate={( current ) =>
                                            current.isBefore( moment(), 'month' )
                                        }
                                        getCalendarContainer={( trigger ) => trigger.parentNode}
                                        locale={locale}
                                    />
                                </h2>

                                <PlanningControls
                                    hideAverage={true}
                                    hideOnMonth={true}
                                    turnover={overview?.content ? turnover : null}
                                    downloadLoad={downloadLoad}
                                    planTurnoverRgm={overview?.content ? planTurnoverRgm : null}
                                    planAverageCheck={overview?.content ? planAverageCheck : null}
                                    deltaPlanTurnover={overview?.content ? deltaPlanTurnover : null}
                                    factTurnover={overview?.content ? factTurnover : null}
                                    deltaTurnover={overview?.content ? deltaTurnover : null}
                                    disabled={!overview?.content}
                                    onTurnover={this.setTurnover}
                                    onDownload={this.download}
                                    onUpload={this.upload}
                                />

                                <hr/>

                                <h2>По филиалам</h2>

                                {overview?.content ? (
                                    <Report
                                        unit={'currency'}
                                        rowKey={'factsNumber'}
                                        data={overview ? overview : null}
                                        columns={columns}
                                        prepare={this.prepare}
                                        onChange={this.change}
                                        cellActions={{ restaurantName: this.deep, }}
                                        onMore={this.loadMore}
                                    />
                                ) : (
                                    <Spinner/>
                                )}

                                <OriginModal
                                    visible={origin}
                                    data={origins}
                                    selected={originTarget ? originTarget.origin : null}
                                    onSubmit={this.applyOrigin}
                                    onCancel={this.hidePopup}
                                />

                                <Modal
                                    title="Удаление аналога"
                                    className="kfc-popup"
                                    centered={true}
                                    open={this.state.confirm}
                                    onOk={this.removeOrigin}
                                    okText={'Удалить'}
                                    okButtonProps={{ className: 'danger' }}
                                    cancelText="Отмена"
                                    onCancel={this.hidePopup}
                                >
                                    <p>
                                        Если у ресторана нет фактического товарооборота за последние 28 дней ,
                                        после удаление аналога нельзя будет спланировать товарооборот.
                                    </p>
                                </Modal>

                                {!!someChanged &&
                                    ( noPlan && noPlan.length > 0 ? (
                                        <Alert
                                            text={
                                                'Чтобы сохранить изменения задайте план для ' +
                                                noPlan.join( ', ' )
                                            }
                                        />
                                    ) : (
                                        <Alert
                                            text="Изменения вступят в силу после сохранения"
                                            button={true}
                                            buttonText="Сохранить"
                                            action={this.save}
                                            cancel={true}
                                            cancelText="Отменить изменения"
                                            onCancel={this.cancel}
                                        />
                                    ))
                                }

                                {error && (
                                    <Alert
                                        text={error}
                                        button={true}
                                        buttonText="Закрыть"
                                        action={this.props.flushPlanningError}
                                    />
                                )}

                                <SaveProgress
                                    active={save}
                                    canClose={canClose}
                                    count={this.getChanged().length}
                                    onDone={this.reload}
                                />
                            </Card>
                        </InnerContent>
                    </Content>
                </Layout>
            </section>
        )
    }
}

export default connect( mapStateToProps, allActions )( Planning )
