/* VENDOR */
import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { Layout }           from 'antd'

/* APPLICATION */
import {
    AppHeader,
    InnerContent,
    ViewTypeSwitch,
    TemplateEditor,
    CategoriesEditor,
    TasksEditor,
    UsersEditor,
    ChartEditor,
    PositionsEditor
} from 'components'

import './admin.scss'
import { mapStateToProps } from './connector'

const { Content, Header } = Layout

class Admin extends Component {
    constructor ( props ) {
        super( props )

        const tabs = this.access( props )

        this.state = {
            tabs,
            view: tabs._default,
        }
    }

    setView = ( view ) => this.setState({ view })

    view = () => {
        switch ( this.state.view ) {
            case 'templates':
                return <TemplateEditor />
            case 'categories':
                return <CategoriesEditor />
            case 'tasks':
                return <TasksEditor />
            case 'users':
                return <UsersEditor />
            case 'chart':
                return <ChartEditor />
            case 'positions':
                return <PositionsEditor />

            default:
                return null
        }
    }

    access = ( props ) => {
        const { areas, info } = props,
              access = areas
                  .filter(( a ) => a.indexOf( 'admin' ) > -1 )
                  .map(( a ) => a.replace( '_admin', '' )),
              defFallback = [
                  'positions',
                  'chart',
                  'partners',
                  'users',
                  'tasks',
                  'categories',
                  'templates',
                  'banners',
              ],
              res = {}

        let def = 0

        access.includes( 'templates' ) && ( res[ 'templates' ] = 'Шаблоны задач' )
        access.includes( 'categories' ) && ( res[ 'categories' ] = 'Категории блюд' )
        access.includes( 'tasks' ) && ( res[ 'tasks' ] = 'Задачи' )
        access.includes( 'users' ) && ( res[ 'users' ] = 'Пользователи' )
        info?.supervisor && ( res[ 'chart' ] = 'Чарт' )
        access.includes( 'users' ) && ( res[ 'positions' ] = 'Позиции' )

        while ( !res._default && def < defFallback.length ) {
            const key = defFallback[ def ]

            res[ key ] && ( res._default = key )
            def++
        }

        return res
    }

    render () {
        const { view, tabs } = this.state

        return (
            <section className="kfc-admin kfc-tabbed-page fixed-container">
                <Layout>
                    <Header>
                        <AppHeader hideRestaurants={view !== 'banners'} hideTime />
                    </Header>
                    <Content>
                        <ViewTypeSwitch update={this.setView} tabs={tabs} current={view} />

                        <InnerContent fixed={[ 'templates', 'categories' ].includes( view )}>
                            <div className="tabbed-content">{this.view()}</div>
                        </InnerContent>
                    </Content>
                </Layout>
            </section>
        )
    }
}

export default connect( mapStateToProps )( Admin )
