/* VENDOR */
import React from 'react'

/* APPLICATION */
import { Icon, IconActive, createIcon } from './Icon'

const svg = ( primary ) => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7.99203 1.33203C4.31203 1.33203 1.33203 4.3187 1.33203 7.9987C1.33203 11.6787 4.31203 14.6654 7.99203 14.6654C11.6787 14.6654 14.6654 11.6787 14.6654 7.9987C14.6654 4.3187 11.6787 1.33203 7.99203 1.33203ZM7.9987 13.332C5.05203 13.332 2.66536 10.9454 2.66536 7.9987C2.66536 5.05203 5.05203 2.66536 7.9987 2.66536C10.9454 2.66536 13.332 5.05203 13.332 7.9987C13.332 10.9454 10.9454 13.332 7.9987 13.332ZM7.85203 4.66536H7.81203C7.54536 4.66536 7.33203 4.8787 7.33203 5.14536V8.29203C7.33203 8.52536 7.45203 8.74537 7.6587 8.86537L10.4254 10.5254C10.652 10.6587 10.9454 10.592 11.0787 10.3654C11.2187 10.1387 11.1454 9.8387 10.912 9.70537L8.33203 8.17203V5.14536C8.33203 4.8787 8.1187 4.66536 7.85203 4.66536Z"
        />
    </svg>
)

class Temporary extends Icon {
    render () {
        return svg( this.primary, this.secondary )
    }
}

class TemporaryActive extends IconActive {
    render () {
        return svg( this.primary, this.secondary )
    }
}

export default createIcon( Temporary, TemporaryActive )
