/* VENDOR */
import React from 'react'

/* APPLICATION */
import { Icon, IconActive, createIcon } from './Icon'

const svg = ( primary ) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
        <path
            fill={primary}
            d="M10.8331 1.70996V22.29c.0009.1744-.0375.3467-.1122.5043-.0747.1576-.1839.2964-.3194.4061-.1356.1098-.294.1876-.46373.2279-.1697.0402-.34626.0418-.51666.0047-2.58402-.5984-4.88916-2.0544-6.53964-4.1307C1.23099 17.226.33252 14.6518.33252 11.9995c0-2.65242.89847-5.22659 2.54895-7.3029C4.53195 2.62029 6.83709 1.16429 9.42111.565963c.17048-.037179.34714-.035555.5169.004751.16979.040306.32829.118262.46389.228104.1356.109841.2447.248752.3193.406472.0747.15771.1129.33019.1119.50467zm2.368 0v7.945c.0008.30927.124.60564.3427.82434s.515.3419.8243.3427h7.922c.1753.0003.3483-.0389.5064-.1145.1581-.0756.2972-.1858.4069-.3225.1098-.1366.1874-.2962.2272-.46685.0397-.17068.0406-.34812.0025-.51919-.487-2.15282-1.5721-4.12432-3.1304-5.68754-1.5583-1.56322-3.5263-2.65459-5.6776-3.148457-.1711-.038079-.3485-.037209-.5192.002547-.1707.039756-.3302.117379-.4669.227127-.1366.109749-.2468.248813-.3224.406903-.0757.1581-.1148.33117-.1145.50642l-.001.004zm0 12.63504v7.945c-.0003.1752.0388.3483.1145.5064.0756.1581.1858.2971.3224.4069.1367.1097.2962.1874.4669.2271.1707.0398.3481.0406.5192.0026 2.1538-.4938 4.1243-1.5857 5.6847-3.1502 1.5604-1.5645 2.6472-3.5378 3.1353-5.6928.0367-.1712.0348-.3483-.0055-.5186-.0403-.1704-.1179-.3296-.2274-.4662-.1094-.1366-.2478-.2471-.4052-.3236-.1574-.0765-.3299-.117-.5049-.1186h-7.921c-.3123.0028-.611.1284-.8315.3495-.2206.2212-.3454.5201-.3475.8325z"
        />
    </svg>
)

class Chart extends Icon {
    render () {
        return svg( this.primary, this.secondary )
    }
}

class ChartActive extends IconActive {
    render () {
        return svg( this.primary, this.secondary )
    }
}

export default createIcon( Chart, ChartActive )
