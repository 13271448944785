import React from 'react';
import cn from 'classnames';
import styles from './guest-vote-pie-charts.module.scss';
import {PieChart} from '../charts/PieChart/PieChart';

type GuestVotePieChartsProps = {
  className?: string;
  data?: Record<string, any>;
};

export const GuestVotePieCharts = (props: GuestVotePieChartsProps) => {
  const {className, data} = props;


  return (
    <div className={cn(className, styles.charts)}>
      {data?.feedbackSources && <PieChart title="По источникам" content={data?.feedbackSources}/>}
      {data?.saleChannels && <PieChart title="По каналам" content={data?.saleChannels}/>}
      {data?.feedbackCategories && <PieChart title="По категориям" content={data?.feedbackCategories}/>}
      {data?.feedbackSubcategories && <PieChart
        title="По подкатегориям"
        content={data?.feedbackSubcategories}
      />}
    </div>
  );
};
