export function chunk ( arr, len ) {
    let chunks = [],
        i = 0,
        n = arr.length
    while ( i < n ) {
        chunks.push( arr.slice( i, ( i += len )))
    }
    return chunks
}

export function isArraysEqual (array1, array2) {
    return JSON.stringify(array1) === JSON.stringify(array2);
}
