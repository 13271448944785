export const object = ( obj ) => Object.assign({}, obj )
export const to = ( from, to ) => Object.assign( to, from )

export const include = ( obj, list, append ) => {
    if ( !list ) {
        return obj
    }

    const res = {}

    Array.isArray( list )
        ? list.map(( key ) => ( res[ key ] = obj[ key ]))
        : Object.keys( list ).map(( key ) => ( res[ list[ key ] ] = obj[ key ]))

    return append ? { ...res, ...append } : res
}

export const deep = ( obj ) => {
    const res = {}

    Object.keys( obj ).forEach(( key ) => {
        if ( !obj[ key ]) {
            res[ key ] = obj[ key ]
        } else if ( Array.isArray( obj[ key ])) {
            res[ key ] = array( obj[ key ], true )
        } else if ( typeof obj[ key ] === 'object' ) {
            res[ key ] = deep( obj[ key ])
        } else {
            res[ key ] = obj[ key ]
        }
    })

    return res
}

export const array = ( arr, d ) =>
    arr?.map(( i ) => {
        if ( !i ) {
            return i
        }
        if ( Array.isArray( i )) {
            return array( i )
        }
        if ( typeof i === 'object' ) {
            return d ? deep( i ) : object( i )
        }
        return i
    })

export const toClipboard = ( data ) => {
    return ( e ) => {
        e.preventDefault()

        const el = document.createElement( 'textarea' )

        el.value = data
        el.setAttribute( 'readonly', '' )
        el.style.position = 'absolute'
        el.style.left = '-9999px'

        document.body.appendChild( el )

        el.select()
        document.execCommand( 'copy' )

        document.body.removeChild( el )
    }
}
