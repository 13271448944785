/* VENDOR */
import React, { Component }                           from 'react'
import PropTypes                                      from 'prop-types'
import { Scrollbars }                                 from 'react-custom-scrollbars'
import { List, Checkbox, Dropdown, Menu, Spin } from 'antd'
import Icon from '@ant-design/icons'


/* APPLICATION */
import { FilterInput, AddButton, Icons, Spinner, UserAvatar } from 'components'

import { format } from 'tools'
import config     from 'config'

import './staff-list.scss'

class StaffList extends Component {
    static propTypes = {
        data:       PropTypes.array,
        current:    PropTypes.string,
        inactive:   PropTypes.bool,
        resaturant: PropTypes.string,

        onSelect:    PropTypes.func,
        onAdd:       PropTypes.func,
        onTransfer:  PropTypes.func,
        onDismissed: PropTypes.func,
    }

    constructor ( props ) {
        super( props )

        const drafts = window.localStorage.getItem(
            config.storage_prefix + 'show_drafts'
        ),
              outstaff = window.localStorage.getItem(
                  config.storage_prefix + 'show_outstaff'
              )

        this.state = {
            data:          props.data || [],
            filtered:      props.data || [],
            allFiltered:   props.data || [],
            drafts:        drafts === null ? true : drafts === 'true',
            outstaff:      outstaff === null ? true : outstaff === 'true',
            preventScroll: false,
            filter:        false,
        }
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        if ( nextProps.data !== this.props.data ) {
            this.setData( nextProps.data )
        }
    }

    componentDidUpdate ( prevProps, prevState ) {
        const { current, inactive } = this.props,
              { filtered, drafts, outstaff } = this.state

        if (
            prevProps.current !== current ||
      prevProps.inactive !== inactive ||
      prevState.filtered !== filtered
        ) {
            this.scroll()
        }

        localStorage.setItem( config.storage_prefix + 'show_drafts', drafts )
        localStorage.setItem( config.storage_prefix + 'show_outstaff', outstaff )
    }

    setData = ( data ) =>
        this.setState({ data: data, })

    filter = ( data ) =>
        this.setState({
            filtered:    this.applyFilter( data, this.state.drafts, this.state.outstaff ),
            allFiltered: data,
        })

    isDraft = ( e ) => !( e.salary && e.rateDictionary )

    applyFilter = ( data, drafts, outstaff ) => {
        const { current } = this.props

        let res = data

        !drafts && ( res = res.filter(( i ) => !this.isDraft( i )))
        !outstaff && ( res = res.filter(( i ) => !i.outstaff ))

        let final = res.sort(( a, b ) => {
            if (
                ( this.isDraft( a ) && this.isDraft( b )) ||
        ( !this.isDraft( a ) && !this.isDraft( b ))
            ) {
                const aname = a.name || a.fullName,
                      bname = b.name || b.fullName

                return aname.localeCompare( bname, [ 'en-EN', 'ru-RU' ])
            } else {
                return this.isDraft( a ) ? -1 : 1
            }
        })

        if ( !current || ( current && !final.find(( s ) => s.id === current ))) {
            final[ 0 ] && this.props.onSelect( final[ 0 ].id )
        }

        return final
    }

    scroll = () => {
        const prevent = this.state.preventScroll

        this.setState({ preventScroll: false, })

        if ( !this.scrolls || prevent ) { return }

        const found = format.find.index(
            this.state.filtered,
            'id',
            this.props.current
        )

        this.scrolls.scrollTop(( found - 2 ) * 40 )
    }

    select = ( item ) => {
        return () => {
            this.setState({ preventScroll: true, })

            this.props.onSelect && this.props.onSelect( item.id )
        }
    }

    icon = ( item ) => {
        const { restaurant } = this.props

        let icon = null

        this.isDraft( item ) && ( icon = <Icon component={Icons.ArrowRight.def} /> )
        item.__new && ( icon = <Icon component={Icons.Edit.def} /> )

        if ( item.transfer ) {
            item.transfer.originalFactsNumber.toString() === restaurant &&
        ( icon = <Icon component={Icons.Transfer.def} /> )
            item.transfer.transferredRestFactsNumber.toString() === restaurant &&
        ( icon = <Icon component={Icons.Temporary.def} /> )
        }

        return (
            <UserAvatar
                name={item.name || item.fullName}
                icon={icon}
                userId={icon ? null : item.userId}
            />
        )
    }

    item = ( item ) => {
        const { restaurant } = this.props

        let cls = []

        item.outstaff && cls.push( 'outstaff' )
        !item.status && item.status !== undefined && cls.push( 'dismissed' )
        item.id === this.props.current && cls.push( 'current' )
        item.__new && cls.push( 'new' )
        this.isDraft( item ) && cls.push( 'draft' )
        !!item.transfer && cls.push( 'transfer' )

        return (
            <List.Item className={cls.join( ' ' ).trim()} onClick={this.select( item )}>
                <span className="staff-item-inner">
                    {this.icon( item )}
                    <span className="staff-item-text">{item.name || item.fullName}</span>
                </span>
                {item.transfer &&
          item.transfer.originalFactsNumber.toString() === restaurant && (
                    <span
                        className="link cancel-transfer"
                        onClick={this.props.onCancelTransfer( item )}
                    >
                        <Icon component={Icons.GetBack.def} />
                    </span>
                )}
            </List.Item>
        )
    }

    showDismissed = ( e ) => this.props.onDismissed( e.target.checked )

    showDrafts = ( e ) =>
        this.setState({
            drafts:   !e.target.checked,
            filtered: this.applyFilter(
                this.state.allFiltered,
                !e.target.checked,
                this.state.outstaff
            ),
        })

    showOutstaff = ( e ) =>
        this.setState({
            outstaff: !e.target.checked,
            filtered: this.applyFilter(
                this.state.allFiltered,
                this.state.drafts,
                !e.target.checked
            ),
        })

    spin = (
        <React.Fragment>
      (
            <Spin size="small" />)
        </React.Fragment>
    )

    total = () =>
        this.props.data
            ? this.state.allFiltered.filter(( i ) => this.isDraft( i )).length
            : '...'

    count = () => ( this.props.data ? '(' + this.total() + ')' : this.spin )

    filterVisible = ( filter ) => this.setState({ filter })

    render () {
        const { data, inactive, onAdd, onTransfer } = this.props,
              { filter, drafts, outstaff } = this.state,
              fcount = [ inactive, !drafts, !outstaff ].filter(( t ) => !!t ).length,
              frcount = fcount === 0 ? '' : `(${fcount})`,
              filterMenu = (
                  <Menu>
                      <Menu.Item>
                          <Checkbox onChange={this.showOutstaff} checked={!outstaff}>
              Скрыть внештатных
                          </Checkbox>
                      </Menu.Item>

                      <Menu.Item>
                          <Checkbox
                              onChange={this.showDrafts}
                              checked={!drafts && !!this.total()}
                              disabled={!this.total()}
                          >
              Скрыть незаполненных
                          </Checkbox>
                      </Menu.Item>

                      <Menu.Item>
                          <Checkbox onChange={this.showDismissed} checked={inactive}>
              Показать уволенных
                          </Checkbox>
                      </Menu.Item>
                  </Menu>
              )

        return (
            <div className="staff-list">
                <FilterInput
                    data={this.state.data}
                    update={this.filter}
                    placeholder="Имя или фамилия"
                    rowKey={[ 'name', 'fullName' ]}
                />

                <header className="list-header">
                    <Dropdown
                        overlay={filterMenu}
                        trigger={[ 'click' ]}
                        getPopupContainer={( t ) => t.parentElement}
                        onOpenChange={this.filterVisible}
                        open={filter}
                    >
                        <span className="link w-drop">
                            {<Icon component={Icons.Filter.def} />}
              Фильтр {frcount}
                            {<Icon component={Icons.Chevron.def} />}
                        </span>
                    </Dropdown>
                    {/*


                    */}
                </header>

                {this.state.filtered && data ? (
                    <Scrollbars
                        {...config.ui.scrolls}
                        ref={( node ) => ( this.scrolls = node )}
                    >
                        <List
                            size="small"
                            dataSource={this.state.filtered}
                            renderItem={this.item}
                            locale={{ emptyText: 'Ничего не найдено' }}
                        />
                    </Scrollbars>
                ) : (
                    <Spinner />
                )}

                <div className="list-footer">
                    <AddButton
                        text="Добавить внештатного сотрудника"
                        action={onAdd}
                        icon={
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                            >
                                <path
                                    fill="#1F86FF"
                                    d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2Zm-3 10h-3v3c0 .55-.45 1-1 1s-1-.45-1-1v-3H8c-.55 0-1-.45-1-1s.45-1 1-1h3V8c0-.55.45-1 1-1s1 .45 1 1v3h3c.55 0 1 .45 1 1s-.45 1-1 1Z"
                                />
                            </svg>
                        }
                    />
                    <AddButton
                        text="Перевести в другой ресторан"
                        action={onTransfer}
                        icon={
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                            >
                                <path
                                    fill="#1F86FF"
                                    d="m21.65 7.65003-2.79-2.79c-.32-.32-.86-.1-.86.35v1.79H4c-.55 0-1 .45-1 1s.45 1 1 1h14V10.79c0 .45.54.67.85.35l2.79-2.78997c.2-.19.2-.51.01-.7Z"
                                />
                                <path
                                    fill="#1F86FF"
                                    d="m2.34831 16.35 2.79 2.79c.32.32.86.1.86-.35V17H19.9983c.55 0 1-.45 1-1s-.45-1-1-1H5.99831v-1.79c0-.45-.54-.67-.85-.35l-2.79 2.79c-.2.19-.2.51-.01.7Z"
                                />
                            </svg>
                        }
                    />
                </div>
            </div>
        )
    }
}

export default StaffList
