/* VENDOR */
import React                                   from 'react'
import { Popover, Checkbox, DatePicker, Spin } from 'antd'
import moment                                  from 'moment'

/* APPLICATION */
import { format }   from 'tools'
import * as formats from '../formats'
import * as ui      from '../ui'

import column from './helpers'

const small = {
    person: (
        <svg width="8" height="8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4 4C5.105 4 6 3.105 6 2C6 0.895 5.105 0 4 0C2.895 0 2 0.895 2 2C2 3.105 2.895 4 4 4ZM4 5C2.665 5 0 5.67 0 7V7.5C0 7.775 0.225 8 0.5 8H7.5C7.775 8 8 7.775 8 7.5V7C8 5.67 5.335 5 4 5Z"
                fill="#000"
                fillOpacity=".38"
            />
        </svg>
    ),
    home: (
        <svg width="10" height="9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.00078 8.50035V6.00035H6.00078V8.50035C6.00078 8.77535 6.22578 9.00035 6.50078 9.00035H8.00078C8.27578 9.00035 8.50078 8.77535 8.50078 8.50035V5.00035H9.35078C9.58078 5.00035 9.69078 4.71535 9.51578 4.56535L5.33578 0.800352C5.14578 0.630352 4.85578 0.630352 4.66578 0.800352L0.485781 4.56535C0.315781 4.71535 0.420781 5.00035 0.650781 5.00035H1.50078V8.50035C1.50078 8.77535 1.72578 9.00035 2.00078 9.00035H3.50078C3.77578 9.00035 4.00078 8.77535 4.00078 8.50035Z"
                fill="#000"
                fillOpacity=".38"
            />
        </svg>
    ),
}

export default {
    admin: {
        partners: {
            clients: [
                column( 'Имя клиента (логин)', 'clientName', {
                    render: ( name, record ) => (
                        <React.Fragment>
                            {name}
                            {record.authHeader && (
                                <span
                                    className="link copy-cb"
                                    onClick={format.copy.toClipboard( record.authHeader )}
                                >
                  Скопировать аутентификационный заголовок
                                </span>
                            )}
                        </React.Fragment>
                    ),
                }),
                column( 'Grant types', 'grantTypes', {
                    width:  250,
                    render: ( arr ) => {
                        if ( arr.length === 0 ) { return ui.nullSymbol }
                        return format.strings.normalizeArray( arr )
                    },
                }),
                column( 'Scope', 'scopes', {
                    width:  200,
                    render: ( arr ) => {
                        if ( arr.length === 0 ) { return ui.nullSymbol }
                        return format.strings.normalizeArray( arr )
                    },
                }),
                column( 'Блокировка', 'active', {
                    as:    'lock',
                    width: 100,
                }),
                column( 'Активность', 'activeDates', {
                    width:      150,
                    as:         'range',
                    itemKey:    [ 'activeFrom', 'activeTo' ],
                    dateFormat: formats.onlyDayViewShort,
                }),
            ],
            users: [
                column( 'Название партнера', 'name' ),
                column( 'Логин', 'username' ),
                column( 'Блокировка', 'enabled', {
                    as:         'lock',
                    width:      100,
                    dateFormat: formats.onlyDayViewShort,
                }),
            ],
        },
        tasks: [
            column( 'Задача', 'title', {
                width:  300,
                render: ( text, record ) => (
                    <p>
                        {text}
                        <small>{record.group.groupNameUi}</small>
                    </p>
                ),
            }),
            column( 'Создана', 'createdDate', {
                render: ( text ) => {
                    const date = moment( text ).format( formats.dateViewShort ).split( ', ' )

                    return (
                        <span>
                            {date[ 0 ]}
                            <br />
                            {date[ 1 ]}
                        </span>
                    )
                },
            }),
            column( 'Статус', 'status' ),
            column( 'Ресторан', 'restaurant', {
                as:    'select',
                items: 'restaurants',
            }),
            column( 'Создатель', 'creator' ),
            column( 'Ответственный', 'assignee', {
                as:       'select',
                editable: 'true',
                items:    'employees',
                search:   true,
            }),
            column( 'Исполнитель', 'executor', {
                as:       'select',
                editable: 'true',
                items:    'employees',
                search:   true,
            }),
        ],
        users: [
            column( 'Имя', 'fullName', {
                render: ( text, record ) => {
                    const content = (
                        <p>
              Имя в анкете: {record.scheduleName || '(Не назначено)'}
                            <br />
              Логин: {record.userId || '(Не назначено)'}
                            <br />
              Департамент: {record.department || '(Не назначено)'}
                            <br />
              Страна: {record.country || '(Не назначено)'}
                        </p>
                    )

                    return (
                        <Popover placement="bottom" content={content}>
                            {text}
                        </Popover>
                    )
                },
            }),
            column( 'Роль', 'jobRole', {
                as:       'select',
                editable: 'true',
                items:    'roles',
                className: 'select-wrapper'
            }),
            column( 'Основной ресторан', 'restaurantId', {
                as:       'select',
                editable: 'true',
                items:    'restaurants',
                className: 'select-wrapper',
                search:   true,
            }),
            column( 'Связанные', '_linked', {
                render: ( n, record ) => {
                    if ( record.marketManager ) {
                        return record.linkedUsers.length > 0
                            ? record.linkedUsers.length
                            : ''
                    }

                    return record.grantedRestaurants.length > 0
                        ? record.grantedRestaurants.length
                        : ''
                },
                as:      'showPopup',
                popup:   'granted',
                prepare: ( n, record ) =>
                    record.marketManager ? record.linkedUsers : record.grantedRestaurants,
                icon: ( record ) =>
                    record.marketManager && record.linkedUsers.length > 0
                        ? small.person
                        : !record.marketManager && record.grantedRestaurants.length > 0
                                ? small.home
                                : null,
            }),
            column( 'Права доступа', '_rights', {
                as:     'handlers',
                render: ( n, r, i, cfg ) => {
                    let text = 'Стандартные'

                    Object.values( r.admin ).includes( true ) &&
            ( text = 'Админ и планирование' )
                    r.supervisor && ( text = 'Супервизор' )
                    r.marketManager && ( text = 'Маркет менеджер' )

                    return (
                        <span
                            className="link"
                            onClick={
                                cfg && cfg.actions && cfg.actions.popup( 'permissions', r )
                            }
                        >
                            {text}
                        </span>
                    )
                },
            }),
            /*column(
                'Админ',
                'admin',
                {
                    render: arr =>
                        {
                            if ( arr.length === 0 ) return ''
                            return arr.length
                        },
                    as: 'showPopup',
                    check: true,
                    popup: 'permissions',
                    prepare: arr => Object.keys( arr ).filter( key => arr[key] ),
                    popover: ( record, cfg ) => {
                        const
                            perm = format.generate.optionsr( permissions, 'key', 'title' ),
                            selected = Object.keys( record.admin )
                                .filter( key => record.admin[key] )
                                .map( key => (
                                    <React.Fragment key={key}>
                                        { perm[key] || key }
                                        <br/>
                                    </React.Fragment>
                                ))

                        return (
                            <span>
                                { selected }
                                <span className="link off-top" onClick={ cfg && cfg.actions && cfg.actions.popup( 'permissions', record )}>Настроить</span>
                            </span>
                        )
                    }
                }
            ),
            column(
                'Планы',
                'planAdmin',
                {
                    editable: true,
                    as: 'boolean'
                }
            ),*/
            column( 'Не синхронизировать до', 'stopSyncUntil', {
                as:     'handlers',
                width:  220,
                render: ( _, record, index, cfg ) => {
                    if ( record.stopSync?.loading ) { return <Spin size="small" /> }

                    return (
                        <div className="check-date">
                            <Checkbox
                                checked={!!record.stopSync}
                                onChange={cfg.onCheck( record, 'stopSyncModal', null, index )}
                            />
                            <DatePicker
                                value={
                                    record.stopSync
                                        ? moment( record.stopSync.stopSyncUntil, formats.dayAPI )
                                        : moment().add( 1, 'day' )
                                }
                                format="YYYY-MM-DD"
                                disabled={true}
                                disabledDate={( date ) => date.isBefore( moment().endOf( 'day' ))}
                            />
                        </div>
                    )
                },
            }),
            column( 'Отключил от синхронизации', 'stopSync', {
                as:     'handlers',
                width:  330,
                render: ( data, record, _, cfg ) => {
                    if ( !record.stopSync ) { return null }

                    return (
                        <div className="stopsync-info">
                            <p className="stopsync-user">{data.userId}</p>
                            <p className="stopsync-comment">
                                {data.comment?.length >= 36 ? (
                                    <>
                                        {data.comment.substr( 0, 30 )}...{' '}
                                        <a onClick={cfg.actions.popup( 'stopSync', record )}>
                      Подробнее
                                        </a>
                                    </>
                                ) : (
                                    <>
                                        {data.comment}{' '}
                                        {cfg.actions.canEdit( record ) && (
                                            <a onClick={cfg.actions.popup( 'stopSync', record )}>
                        Изменить
                                            </a>
                                        )}
                                    </>
                                )}
                            </p>
                        </div>
                    )
                },
            }),
            column( '', 'access', { as: 'lock', }),
        ],
        positions: [
            column( '', 'order', {
                width:  60,
                render: ( text, record ) => ( record.deleted ? null : text ),
            }),
            column( 'Позиция', 'positionFullName', {
                as:       'text',
                editable: true,
                width:    200,
            }),
            column( 'Сокращение', 'positionName', {
                as:       'text',
                editable: true,
                width:    120,
            }),
            column( 'Описание', 'description', {
                as:       'textArea',
                editable: true,
                width:    450,
            }),
            column( 'Станция', 'positionStationUuid', {
                as:       'select',
                editable: true,
                items:    'catops',
                width:    230,
            }),
            column( '', null, {
                as:     'handlers',
                render: ( text, record, index, cfg, handlers ) => {
                    const col = {
                        width:     60,
                        editable:  true,
                        className: 'align-center',
                        small:     true,
                    }

                    return record.deleted
                        ? handlers.restore( col, cfg )( text, record, index )
                        : handlers.remove( col, cfg )( text, record, index )
                },
            }),
        ],
    },
}
