/* VENDOR */
import moment from 'moment'

/* APPLICATION */
import config       from 'config'
import * as search  from './find'
import * as strings from './strings'
import { format }   from '../index'

export const hierarchy = [ 'hour', 'shift', 'day', 'week', 'month', 'quarter' ]
export const getNextHierarchy = ( predef ) =>
    hierarchy[ hierarchy.indexOf( predef ) - 1 ]

export const fixDateOffset = ( date, rid, restaurants ) => {
    const rest = restaurants.find(( r ) => r.factsNumber === parseInt( rid )),
          offset = parseInt( rest.timeZoneName.replace( 'GMT', '' )),
          stroff =
      offset < 0
          ? '-' + strings.dbl( Math.abs( offset )) + ':00'
          : '+' + strings.dbl( offset ) + ':00'

    return moment( date + stroff ).format( config.format.date )
}

export const fixRestOffset = ( request, restaurants, workHours ) => {
    if ( !restaurants ) {
        return request
    }

    const fixedTimeRequest = { ...request },
          rest = restaurants.find(
              ( r ) => r.factsNumber === parseInt( request.restaurantId )
          ),
          offset = parseInt( rest.timeZoneName.replace( 'GMT', '' )),
          stroff =
      offset < 0
          ? '-' + strings.dbl( Math.abs( offset )) + ':00'
          : '+' + strings.dbl( offset ) + ':00'

    fixedTimeRequest.dateStart = moment( request.dateStart + stroff ).format(
        config.format.date
    )
    fixedTimeRequest.dateEnd = moment( request.dateEnd + stroff ).format(
        config.format.date
    )

    return fixedTimeRequest
}

export const nrelative = ( group ) => {
    switch ( group ) {
        case 'hour':
            return 'час'
        case 'shift':
            return 'смену'
        case 'day':
            return 'сутки'
        case 'week':
            return 'неделю'
        case 'month':
            return 'месяц'
        case 'quarter':
            return 'квартал'
        default:
            return 'период'
    }
}

export const relative = ( group ) => {
    switch ( group ) {
        case 'hour':
            return 'часу'
        case 'shift':
            return 'смене'
        case 'day':
            return 'суткам'
        case 'week':
            return 'неделе'
        case 'month':
            return 'месяцу'
        case 'quarter':
            return 'кварталу'
        default:
            return '...'
    }
}

export const duration = ( count, key ) => {
    if ( !key ) {
        key = count
        count = 1
    }

    return moment.duration( count, key ).asMilliseconds()
}

export const startEnd = ({ start, end }, type ) => {
    // eslint-disable-next-line no-unused-vars
    start = start.startOf( type )
    // eslint-disable-next-line no-unused-vars
    end = end.endOf( type )
}

export const extractShift = ( shift ) => {
    if ( !shift ) {
        return null
    }

    const res = {
        start: moment( shift.timeStart, config.format.time ),
        end:   moment( shift.timeEnd, config.format.time ),
    }

    if ( shift.timeEnd === '00:00' ) {
        res.end = res.end.endOf( 'day' )
    }

    if ( res.end.isBefore( res.start )) {
        res.end = moment( res.end.add( 1, 'day' ))
    }
    return res
}

export const getPreviousShift = ( hours, timeZoneName ) => {
    const day = getToday( hours ),
          prevDow = day.dayOfWeek === 1 ? 7 : day.dayOfWeek - 1,
          prevDay = hours.days.find(( d ) => d.dayOfWeek === prevDow ),
          shift = getGroup( 'shift', hours, null, timeZoneName ),
          found = day.shifts.find(( s ) => extractShift( s ).start.isSame( shift.start )),
          index = found ? day.shifts.indexOf( found ) : -1,
          start = moment(),
          end = moment()

    let prev

    //Last previous day shift
    if ( index < 1 ) {
        prev = extractShift( prevDay.shifts[ prevDay.shifts.length - 1 ])
        !prev && ( prev = extractShift( prevDay ))

        start.subtract( 1, 'day' )
        end.subtract( 1, 'day' )
    } else {
        prev = extractShift( day.shifts[ index - 1 ])
    }

    return {
        dateStart: start
            .set({ hours: prev.start.hours(), minutes: prev.start.minutes() })
            .format( config.format.date ),
        dateEnd: end
            .set({ hours: prev.end.hours(), minutes: prev.end.minutes() })
            .format( config.format.date ),
    }
}



export const getCurrShift = ( day, requestTime ) => {
    let now = moment().set( 'hour', requestTime.split( ':' )[ 0 ]).set( 'minute', requestTime.split( ':' )[ 1 ]),
        endCurrDay = moment().endOf( 'day' ),
        i,
        shift

    for ( i = 0; i < day.shifts.length; i++ ) {
        shift = format.dates.extractShift( day.shifts[ i ])

        if ( shift.start.isBefore( now ) && shift.end.isAfter( now )) {
            return shift
        }
    }
}

export const getShift = ( day, timeZoneName ) => {
    //If no shifts
    if ( !day.shifts || day.shifts.length === 0 ) {
        if ( day.noctidial ) {
            return {
                start: moment().startOf( 'day' ),
                end:   moment().endOf( 'day' ),
            }
        }

        const start = moment( day.timeStart, config.format.time ),
              end = moment( day.timeEnd, config.format.time )

        return end.isBefore( start ) ? { start, end: moment( end.add( 1, 'day' )) } : { start, end }
    }

    //If only one shift
    if ( day.shifts.length === 1 ) {
        return extractShift( day.shifts[ 0 ])
    }

    //Trying to find a current shift
    const roffset = -new Date().getTimezoneOffset() / 60
    const doffset = parseInt( timeZoneName?.replace( 'GMT', '' )) || 3
    const offset = doffset - roffset

    let now = moment().add( offset, 'hours' ),
        i,
        shift

    for ( i = 0; i < day.shifts.length; i++ ) {
        shift = extractShift( day.shifts[ i ])

        if ( shift.start.isBefore( now ) && shift.end.isAfter( now )) {
            return shift
        }
    }

    //Get nearest shift
    const first = extractShift( day.shifts[ 0 ]),
          last = extractShift( day.shifts[ day.shifts.length - 1 ])

    if ( now.isBefore( first.start )) {
        return first
    }

    if ( now.isAfter( last.end )) {
        return last
    }

    //Returning default shift
    return extractShift( day.shifts[ 0 ])
}

export const getToday = ( hours, date ) => {
    let dow = ( date || moment()).day()
    dow === 0 && ( dow = 7 )

    return hours
        ? hours.days.find(( d ) => d.dayOfWeek === dow )
        : {
                timeEnd:   '23:59',
                timeStart: '00:00',
                noctidial: false,
            }
}

export const getShiftLength = ( hours, timeZoneName ) => {
    if ( !hours ) {
        return {
            start: moment().startOf( 'day' ),
            end:   moment().endOf( 'day' ),
        }
    }

    if ( timeZoneName ) {
        const day = getToday( hours ),
              shift = getShift( day, timeZoneName ),
              start = shift.start,
              end = shift.end

        return { start, end }
    }

    const day = getToday( hours ),
          shift = getShift( day, timeZoneName ),
          start = shift.start,
          end = shift.end

    return { start, end }
}

export const getGroup = ( predef, hours, date, timeZoneName ) => {
    const roffset = -new Date().getTimezoneOffset() / 60
    const doffset = parseInt( timeZoneName?.replace( 'GMT', '' )) || 3
    const offset = doffset - roffset,
          datesInStorage = JSON.parse( sessionStorage.getItem( 'filtersData' )),
          newDatesInStorage = format.copy.object(datesInStorage)

    let group = 'hour',
        start = date ? moment( date ).add( offset, 'hours' ) : moment().add( offset, 'hours' ),
        end = date ? moment( date ).add( offset, 'hours' ) : moment().add( offset, 'hours' ),
        today = getToday( hours, date )

    switch ( predef ) {
        case 'hour':
            start = start.startOf( 'hour' )
            end = end.startOf( 'hour' ).add( 1, 'hour' )
            break
        case 'day':
            if ( today.noctidial ) {
                start = start.startOf( 'day' )
                end = end.endOf( 'day' )
            } else {
                start = moment( `${start.format( 'YYYY-MM-DD' )}T00:00` )
                end = moment( `${start.format( 'YYYY-MM-DD' )}T23:59` )

                if ( start.isAfter( end )) {
                    end.add( 1, 'day' )
                }
            }
            break
        case 'week':
            group = 'day'
            startEnd({ start, end }, 'week' )
            break
        case 'month':
            group = 'day'
            startEnd({ start, end }, 'month' )
            break
        case 'quarter':
            group = 'week'
            startEnd({ start, end }, 'quarter' )
            break
        case 'shift':
            group = 'hour'
            const resShift = getShiftLength( hours, timeZoneName )
            start = resShift.start
            end = resShift.end
            break
        default:
            // eslint-disable-next-line no-case-declarations
            const res = getShiftLength( hours, timeZoneName )
            start = res.start
            end = res.end
            break
    }

    newDatesInStorage.workHours = hours
    sessionStorage.setItem( 'filtersData', JSON.stringify( newDatesInStorage ))

    return { start, end, group }
}

export const setGroup = ( predef, actions, callback, workHours, date, timeZoneName ) => {
    const { setGroup, setPredef, setStartDate, setEndDate } = actions,
          { group, start, end } = getGroup( predef, workHours, date, timeZoneName )

    setPredef( predef )
    setGroup( group )
    setStartDate( start.format( config.format.date ))
    setEndDate( end.format( config.format.date ))

    callback( start, end )
}

export const getPredef = ( start, end ) => {
    let predef = '',
        group = '',
        diff = end.diff( start )

    if ( diff < duration( 2, 'days' )) {
        predef = 'day'
        group = 'hour'
    } else if ( diff < duration( 6, 'days' )) {
        predef = 'day'
        group = 'day'
    } else if ( diff < duration( 29, 'days' )) {
        predef = 'week'
        group = 'day'
    } else if ( diff <= duration( 3.5, 'months' )) {
        predef = 'month'
        group = 'day'
    } else {
        predef = 'month'
        group = 'month'
    }

    return { predef, group }
}

export const extractDate = ( str ) => {
    return str.indexOf( '-' ) > -1
        ? moment( str, config.format.dayAPI )
        : moment( str, config.format.day )
}

export const prepare = ( str ) => {
    const date = str ? extractDate( str ) : moment()

    return date.format( config.format.dayAPI )
}

export const def = ( record ) => {
    record.dateStart = prepare( record.dateStart )
    record.dateEnd = prepare( record.dateEnd )
}

export const flush = ( record ) => {
    record.dateStart = ''
    record.dateEnd = ''
}

export const find = ( arr ) => {
    let dow = 0,
        found = false

    while ( dow < 7 && !found ) {
        dow++
        found = search.index( arr, 'dayOfWeek', dow ) === -1
    }

    return dow
}

export const withYear = ( date, day ) =>
    day
        ? strings.capitalizeAll( date.format( 'D MMM YYYY' ))
        : strings.capitalizeAll( date.format( 'MMM YYYY' ))
