/* eslint-disable camelcase */
/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import Moment               from 'moment'
import { extendMoment }     from 'moment-range'

/* APPLICATION */
import { AssignShift } from 'components'
import { format }      from 'tools'
import config          from 'config'

import ScheduleChartView from './ScheduleChartView'

const moment = extendMoment( Moment ),

      keys = {
          position: [ 'positionGuid', 'position' ],
          shift:    [
              '_temp_id',
              'workScheduleId',
              'employeeName',
              'employeeId',
              'shiftStart',
              'shiftEnd',
              '_error',
              '_mc_error',
              '_outstaff_error',
              '_warning',
              'guid',
              'fixed',
              'confirmed',
              'shiftSupervisor',
              '_was_supervisor',
          ],
      }

class ScheduleChart extends Component {
    static propTypes = {
        day:       PropTypes.string,
        from:      PropTypes.object,
        to:        PropTypes.object,
        compact:   PropTypes.bool,
        fixedHead: PropTypes.bool,

        date:    PropTypes.object,
        data:    PropTypes.object,
        helpers: PropTypes.object,
        open:    PropTypes.object,

        workHours: PropTypes.object,
        positions: PropTypes.array,
        employees: PropTypes.array,

        onAdd:    PropTypes.func,
        onChange: PropTypes.func,
        onRemove: PropTypes.func,
        onError:  PropTypes.func,
        onAssign: PropTypes.func,
        onTop:    PropTypes.func,
    }

    constructor ( props ) {
        super( props )
        this.state = {
            data:     null,
            toAssign: null,
            assign:   false,
        }
    }

    componentDidMount () {
        this.setData( this.props )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { open, data, day } = this.props

        if ( nextProps.day !== day ) {
            this.setState({ data: null })
            setTimeout(() => this.setData( nextProps ), 0 )
        }

        if ( nextProps.data !== data || nextProps.open !== open ) {
            this.setData( nextProps )
        }
    }

    setData = ( props ) =>
        this.setState({ data: this.data( props ), })

    empty = ( pos, str ) => ({
        as:           'label',
        employeeName: pos.positionName + str,
        positionGuid: pos.positionGuid,
        full:         true,
        open:         true,
    })

    spoiler = ( pos ) => {
        const count = pos.shifts.length,
              sp = this.empty( pos, format.strings.count( count, config.defs.shifts ))

        this.props.helpers.spoiler( 'details', sp )

        return sp
    }

    cell = ( col, cfg, text, record, index ) => {
        switch ( col.dataIndex ) {
            case 'employeeName':
                return record._warning ? (
                    <span className="assign-btn" onClick={this.showPopup( record )}></span>
                ) : (
                    cfg.handlers.select( col, cfg )( text, record, index )
                )
            default:
                return text
        }
    }

    hour = ( row, date ) => {
        return ( index ) =>
            ( row[ 'hour-' + index ] =
        row.shiftStart && row.shiftEnd
            ? format.generate.greenLineExt(
                index,
                row.shiftStart,
                row.shiftEnd,
                row.fixed,
                date,
                row.lowRest,
                row.muchWork
            )
            : console.log( row ))

    }

    noMCDay = () => {
        const { data, helpers } = this.props,
              day = helpers.get.day( this.props.day, data )

        if (
            data.dateStart !== moment().startOf( 'week' ).format( config.format.dayAPI )
        ) { return false }

        const tomorrow = parseInt( moment().add( 1, 'day' ).format( 'd' ))

        if ( tomorrow === day.dayOfWeek && tomorrow < 7 ) {
            const position = day.positions.filter(
                ( p ) => p.positionGuid === config.mcGuid
            )[ 0 ]

            if ( !position ) { return false }

            const supervisors = position.shifts.filter(( s ) => s.shiftSupervisor )

            return supervisors.length === 0
        }

        return false
    }

    isOutstaffMC = ( row, position ) => {
        if ( row.notAssignedToTheRestaurant ) {
            row._outstaff_error = true
        }

        if ( position.positionGuid !== config.mcGuid || !row.employeeId ) { return }

        const { employees } = this.props,
              found = employees?.find(( e ) => e.id === row.employeeId )

        row._outstaff_error = !!found?.outstaff
    }

    isNoMC = ( row, position, props ) => {
        const { date } = props

        if ( position.positionGuid !== config.mcGuid ) { return }

        if (
            !date.startOf( 'day' ).isSame( moment().startOf( 'day' )) &&
      !date.startOf( 'day' ).isSame( moment().startOf( 'day' ).add( 1, 'day' ))
        ) { return }

        row._mc_error = position._mc_error
        if ( position._mc_error ) { return }

        const supervisors = position.shifts.filter(( s ) => s.shiftSupervisor )

        if ( supervisors.length === 0 ) {
            position._mc_error = true
            row._mc_error = true
        }
    }

    isEmpty = ( row ) => {
        const check = [ 'employeeId' ]

        if ( format.check.anyEmpty( row, check )) {
            row._warning = true
        }
    }

    flush = ( position ) => {
        position._mc_error = false
        position.shifts.forEach(( shift ) => ( shift._error = false ))
    }

    errors = ( props ) => ( position ) =>
        position.shifts.forEach(( shift ) => {
            this.isEmpty( shift )
            this.isNoMC( shift, position, props )
            this.isOutstaffMC( shift, position )
        })

    prepare = ( original ) => format.copy.object( original )

    shifts = ( res, pos, date ) => {
        return ( original ) => {
            const { helpers, from, to } = this.props,
                  row = {},
                  shift = this.prepare( original )

            row.lowRest = shift.lowRest
            row.muchWork = shift.muchWork

            format.extract.selection( shift, row, keys.shift )
            format.extract.selection( pos, row, keys.position )

            helpers.hours( this.hour( row, date ), from, to )

            row.as = this.cell
            row.__real_date = date

            res.push( row )
        }
    }

    position = ( res, props, date ) => {
        return ( pos ) => {
            res.push( this.spoiler( pos ))

            if ( !props.open[ 'details' + props.day + pos.positionGuid ]) {
                pos.shifts.sort( this.byTime ).map( this.shifts( res, pos, date ))
            }
        }
    }

    byTime = ( a, b ) => moment( a.shiftStart ).unix() - moment( b.shiftStart ).unix()

    data = ( props ) => {
        if ( !props.data ) { return }

        const { day, data, date, helpers, onError, clearError } = props,
              current = helpers.get.day( day, data ),
              res = []

        current.positions.forEach( this.flush )
        current.positions.forEach( this.errors( props ))
        current.positions.forEach(
            helpers.find.deepError(( row, shift ) => {
                row._error = true
                shift._error = true
            })
        )

        current.positions.forEach( this.position( res, props, date ))

        if ( onError ) {
            if ( this.noMCDay()) {
                onError( 'tomorrow_mc_error' )
            } else {
                clearError()
                res.map(( r ) => r._mc_error ).includes( true )
                    ? onError( 'mc_error' )
                    : res.map(( r ) => r._outstaff_error ).includes( true )
                        ? onError( 'outstaff_error' )
                        : onError( res.map(( row ) => row._error ).includes( true ))
            }
        }

        res.forEach(( row ) => {
            const start = moment( row.shiftStart ),
                  end = moment( row.shiftEnd )

            if ( row.confirmed || start.isBefore( date ) || end.isBefore( moment())) {
                row.disabled = true
                row.disableRemove = true
            }
        })

        return res
    }

    assign = ( data ) => {
        this.props.onAssign( data, this.state.toAssign )
        this.hidePopup()
    }

    showPopup = ( shift ) => {
        return ( e ) => {
            e.preventDefault()
            this.setState({
                toAssign: shift,
                assign:   true,
            })
        }
    }

    hidePopup = () =>
        this.setState({
            toAssign: null,
            assign:   false,
        })

    render () {
        const {
            from,
            to,
            compact,
            date,
            day,
            helpers,
            positions,
            employees,
            fixedHead,
            workHours,
        } = this.props,
              { data, assign, toAssign } = this.state

        return (
            <React.Fragment>
                <ScheduleChartView
                    fixedHead={fixedHead}
                    loading={!data}
                    from={from}
                    to={to}
                    compact={compact}
                    date={date}
                    day={day}
                    data={data}
                    workHours={workHours}
                    helpers={helpers}
                    positions={positions}
                    employees={employees}
                    onAdd={this.props.onAdd}
                    onChange={this.props.onChange}
                    onRemove={this.props.onRemove}
                    onTop={this.props.onTop}
                />

                {!this.props.compact && (
                    <AssignShift
                        visible={assign}
                        onSubmit={this.assign}
                        onCancel={this.hidePopup}
                        date={date}
                        shift={toAssign}
                    />
                )}
            </React.Fragment>
        )
    }
}

export default ScheduleChart
