/* VENDOR */
import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { Card, Alert }      from 'antd'

/* APPLICATION */
import { CategoriesList, CategoryCard, AddDishModal, Link } from 'components'
import { format }                                           from 'tools'
import config                                               from 'config'

import { mapStateToProps, allActions } from './connector'

const dishPage = 15

class CategoriesEditor extends Component {
    constructor ( props ) {
        super( props )
        this.state = {
            current: null,
            list:    null,
            adding:  false,
        }
    }

    componentDidMount () {
        this.loadList()
        this.props.list && this.setList( this.props.list )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { list, category } = this.props

        nextProps.list !== list && this.setList( nextProps.list )
        if ( nextProps.category !== category ) {
            nextProps.category
                ? nextProps.category.dishes
                    ? this.setCurrent( nextProps.category )
                    : this.loadCat( nextProps.category )
                : this.setCurrent( nextProps.category )
        }
    }

    loadList = ( search ) =>
        this.props.fetchCategories({ search, })

    loadCat = ( cat ) => {
        this.props.flushCategory()
        this.props.fetchCategory({
            id:   cat.categoryId,
            page: 0,
            size: 1000,
        })
    }

    setList = ( list ) => this.setState({ list })
    setCurrent = ( current ) => this.setState({ current })

    search = ( search ) => {
        this.props.flushCategoriesList()
        this.loadList( search )
    }

    add = () => {
        const ncat = format.copy.object( config.defs.category )
        this.props.addCategory( ncat )
    }

    change = ( key, val ) => {
        const ncat = format.copy.object( this.props.category )
        ncat[ key ] = val
        this.props.updateCategory( ncat )
    }

    remove = ( cat ) => this.props.removeCategory( cat )

    removeDish = ( dish ) =>
        this.props.removeDish({
            categoryId: this.props.category.categoryId,
            productUid: dish.productUid,
        })

    showPopup = () => this.setState({ adding: true })
    hidePopup = () => this.setState({ adding: false })
    addDish = () => {
        this.props.flushDishList()
        this.props.fetchDishList({
            page:   0,
            size:   dishPage,
            search: '',
        })
        this.showPopup()
    }

    loadDishes = ( search ) =>
        this.props.fetchDishList({
            page: 0,
            size: dishPage,
            search,
        })

    loadMore = ( search ) =>
        this.props.appendDishList({
            page: this.props.dishes.number + 1,
            size: dishPage,
            search,
        })

    appendDishes = ( dishes ) => {
        dishes.map(( d ) =>
            this.props.addDishToCategory({
                body:       d,
                categoryId: this.props.category.categoryId,
                productUid: d.productUid,
            })
        )
        this.hidePopup()
    }

    render () {
        const { list, current, adding } = this.state,
              { category, dishes } = this.props

        return (
            <div className="section-cards">
                <aside className="section-cards-aside">
                    <Card bordered={false}>
                        <CategoriesList
                            data={list}
                            current={current}
                            onSelect={this.loadCat}
                            onSearch={this.search}
                            onAdd={this.add}
                        />
                    </Card>
                </aside>

                <section className="section-cards-content">
                    <Card bordered={false}>
                        {!list || list.length > 0 ? (
                            <CategoryCard
                                category={category}
                                onChange={this.change}
                                onRemove={this.remove}
                                onDishAdd={this.addDish}
                                onDishRemove={this.removeDish}
                            />
                        ) : (
                            <Alert
                                message={
                                    <span>
                    Нет ни одной категории.{' '}
                                        <Link onClick={this.add}>Добавьте первую</Link>.
                                    </span>
                                }
                                type="warning"
                            />
                        )}
                    </Card>

                    <AddDishModal
                        visible={adding}
                        data={dishes}
                        selected={
                            category && category.dishes
                                ? format.copy.array( category.dishes )
                                : []
                        }
                        onCancel={this.hidePopup}
                        onSubmit={this.appendDishes}
                        onSearch={this.loadDishes}
                        onMore={this.loadMore}
                    />
                </section>
            </div>
        )
    }
}

export default connect( mapStateToProps, allActions )( CategoriesEditor )
