/* VENDOR */
import React, { Component }     from 'react'
import { connect }              from 'react-redux'
import {Button, Layout} from 'antd'
import Icon from '@ant-design/icons'

import ReactExport              from 'react-data-export'
import moment                   from 'moment'

/* APPLICATION */
import {
    AppHeader,
    InnerContent,
    BackTitle,
    InnerControls,
    Report,
    Icons,
    ReportExcelTooltip,
} from 'components'

import { format } from 'tools'
import config     from 'config'

import { allActions, mapStateToProps } from './connector'
import './average-check.scss'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet

const { Content, Header } = Layout

class AverageCheck extends Component {
    componentDidMount () {
        const { average, workHours, request, fetchWorkHours } = this.props

        !workHours && fetchWorkHours( request )
        !average && this.load( this.props )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { workHours, request, fetchWorkHours } = this.props
        let workHoursLoad = false

        if ( nextProps.request.restaurantId !== request.restaurantId && nextProps.workHours === workHours ) {
            fetchWorkHours( nextProps.request )
            workHoursLoad = true
        }

        if ( nextProps.workHours !== this.props.workHours ) {
            this.load( nextProps )
        }

        if ( format.check.request( this.props.request, nextProps.request ) && !workHoursLoad ) {
            this.load( nextProps )
        }
    }

    prepare = ( record, index, columns ) =>
        format.goodBad.iterate.subZero( record, [ 'deltaAverageCheck', 'deltaAverageCheckLastYear' ], columns )

    more = ( req ) => {
        const reqObj = {
            ...req,
            factsNumber: req.restaurantId
        }

        delete reqObj.restaurantId
        delete reqObj.zoneId
        this.props.appendAverageCheck( reqObj )
    }

    load = ( rprops ) => {
        const props = rprops || this.props,
              { request } = props

        if ( !format.check.enough( props )) { return }

        const reqObj = {
            ...request,
            factsNumber: request.restaurantId
        }

        delete reqObj.restaurantId
        delete reqObj.zoneId
        const performanceRequest = format.copy.object( reqObj )

        props.flushTurnover()
        props.fetchAverageCheck( performanceRequest )
    }

    set = ( data ) => this.props.setRequest( data )

    prepareExcel = () => {
        const { average, request } = this.props
        const currency = format.extract.currency( request.unitOfMeasure )
        const red = '00FF0000'
        const green = '00038E1D'
        const isTimeText = request?.currentPredef === 'shift' || request?.currentPredef === 'day'

        const getFormattedValue = ( value, digits = null ) =>
            value !== null ?
                `${digits !== null ? ( +value ).toFixed( digits ) : value}${currency === '%' ? ' ' + currency : ''}`
                :
                ''

        if ( !average || !average.content ) { return [] }

        const result = [ {
            columns: [
                { title: isTimeText ? 'Время' : 'Дата' },
                { title: 'Факт' },
                { title: 'Цель' },
                { title: 'Разница' },
                { title: 'Прош. год' },
                { title: 'Разница' },
                { title: 'Транзакции факт' },
                { title: 'Транзакции Прош. год' },
                { title: 'Менеджер(ы) смены' },
            ],
            data: average.content.map(( item => ([
                { value: item.label },
                { value: getFormattedValue( item.factAverageCheck, 0 ) },
                { value: getFormattedValue( item.planAverageCheck, 0 ) },
                { value: getFormattedValue( item.deltaAverageCheck, 0 ), style: { font: { color: { rgb: item.deltaAverageCheck < 0 ? red : green } } } },
                { value: getFormattedValue( item.factAverageCheckLastYear, 0 ) },
                { value: getFormattedValue( item.deltaAverageCheckLastYear, 0 ), style: { font: { color: { rgb: item.deltaAverageCheckLastYear < 0 ? red : green } } } },
                { value: getFormattedValue( item.factTransactions, 0 ) },
                { value: getFormattedValue( item.factTransactionsLastYear, 0 ) },
                { value: item.manager.map( item => item.managerName ).join( ', ' ) },
            ])
            ))
        } ]

        return result
    }

    showExcelTooltip = () => {
        const { average } = this.props
        const fieldsNull = [ 'factAverageCheck', 'planAverageCheck', 'deltaAverageCheck', 'factAverageCheckLastYear', 'deltaAverageCheckLastYear' ]
        const fieldsZero = [ 'factTransactions', 'factTransactionsLastYear' ]

        if ( !average?.content ) { return true }

        const allContainsNull = average?.content?.every( item => {
            return Object.entries( item ).filter(
                ([ key ]) => fieldsNull.find( k => k === key )
            ).every(([ _, value ]) => value === null )
        })

        const allContainsZero = average?.content?.every( item => {
            return Object.entries( item ).filter(
                ([ key ]) => fieldsZero.find( k => k === key )
            ).every(([ _, value ]) => value === 0 )
        })

        return allContainsNull && allContainsZero
    }

    render () {
        const { request, workHours, average } = this.props

        const disabledExcel = this.showExcelTooltip()


        return (
            <section className="kfc-report kfc-average-check">
                <Layout>
                    <Header>
                        <AppHeader bothTimes timeData={parseInt( request.restaurantId )} />
                    </Header>
                    <Content>
                        <InnerControls
                            actions={format.extract.actions( this.props )}
                            request={request}
                            workHours={workHours}
                            update={this.load}
                            onChange={this.set}
                        />

                        <InnerContent>
                            <BackTitle
                                showCurrency
                                showDownload
                                text="Средний чек"
                                request={request}
                                onChange={this.set}
                                download={
                                    disabledExcel ?
                                            <ReportExcelTooltip />
                                        :
                                            <ExcelFile
                                                filename={`Средний чек_${moment().format( 'YYYY-MM-DDTHH:mm:ss' )}`}
                                                element={<Button
                                                    type='link'
                                                    className='button-download'
                                                >
                                                    <Icon component={Icons.Download.active} />
                                                </Button>}
                                            >
                                                <ExcelSheet dataSet={this.prepareExcel()} name="Средний чек"/>
                                            </ExcelFile>
                                }
                            />

                            <Report
                                data={average}
                                columns={config.tables.averageCheck( request )}
                                prepare={this.prepare}
                                onMore={this.more}
                            />
                        </InnerContent>
                    </Content>
                </Layout>
            </section>
        )
    }
}

export default connect( mapStateToProps, allActions )( AverageCheck )
