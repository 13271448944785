/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Card }             from 'antd'

/* APPLICATION */
import { StaffList, StaffInfo } from 'components'
import { format }               from 'tools'
import config                   from 'config'

import './staff-cards.scss'

class StaffCards extends Component {
    static propTypes = {
        list: PropTypes.array,

        roles:     PropTypes.object,
        employee:  PropTypes.object,
        rating:    PropTypes.object,
        companies: PropTypes.array,
        rates:     PropTypes.array,

        current:    PropTypes.string,
        restaurant: PropTypes.string,

        loading:  PropTypes.bool,
        inactive: PropTypes.bool,

        onSave:           PropTypes.func,
        onRemove:         PropTypes.func,
        onDone:           PropTypes.func,
        onSelect:         PropTypes.func,
        onError:          PropTypes.func,
        onDismissed:      PropTypes.func,
        onMore:           PropTypes.func,
        getRates:         PropTypes.func,
        onTransfer:       PropTypes.func,
        onCancelTransfer: PropTypes.func,
        onChangeCardTab:  PropTypes.func
    }

    empty = () => {
        const main = format.copy.object( config.defs.employee )

        main.guid = format.generate.guid()

        return {
            main:          main,
            opportunities: [],
            positions:     [],
        }
    }

    render () {
        const {
            list,
            employee,
            rating,
            loading,
            current,
            inactive,
            restaurant,
            roles,
            companies,
            user
        } = this.props,
              {
                  onSelect,
                  onAdd,
                  onTransfer,
                  onSave,
                  onError,
                  onRemove,
                  onDismissed,
                  onMore,
                  onCancelTransfer,
                  rates,
                  getRates,
                  ppr,
                  onPprSave,
                  onPprError,
                  onChangePeriod,
                  periodPpr,
                  trainingLoading,
                  onChangeCardTab
              } = this.props
        return (
            <div className="section-cards">
                <aside className="section-cards-aside">
                    <Card bordered={false}>
                        <StaffList
                            data={list}
                            current={current}
                            inactive={inactive}
                            restaurant={restaurant}
                            onSelect={onSelect}
                            onAdd={onAdd}
                            onTransfer={onTransfer}
                            onCancelTransfer={onCancelTransfer}
                            onDismissed={onDismissed}
                        />
                    </Card>
                </aside>

                <section className="section-cards-content">
                    <Card bordered={false}>
                        <StaffInfo
                            roles={roles}
                            restaurant={restaurant}
                            loading={loading}
                            employee={employee}
                            rating={rating}
                            companies={companies}
                            onSave={onSave}
                            onRemove={onRemove}
                            onError={onError}
                            onMore={onMore}
                            rates={rates}
                            user={user}
                            getRates={getRates}
                            ppr={ppr}
                            periodPpr={periodPpr}
                            trainingLoading={trainingLoading}
                            onPprSave={onPprSave}
                            onPprError={onPprError}
                            onChangePeriod={onChangePeriod}
                            onChangeTab={onChangeCardTab}
                        />
                    </Card>
                </section>
            </div>
        )
    }
}

export default StaffCards
