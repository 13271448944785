/* VENDOR */
import { Component } from 'react'
import { connect }   from 'react-redux'
import '@ant-design/compatible/assets/index.css'

/* APPLICATION */
import { userActions } from 'services'
import config          from 'config'

import './login-form.scss'

const errors = {
    once: 'Извините, имя пользователя или пароль неверны — пожалуйста, попробуйте еще раз',
    many: 'Извините, имя пользователя или пароль неверны — пожалуйста, попробуйте еще раз или обратитесь к администратору',
},

      minLoginAttempts = 2

class LoginForm extends Component {
    constructor ( props ) {
        super( props )

        localStorage.setItem( config.keys.auth, 'true' )
        localStorage.setItem( config.keys.splash, 'no' )

        window.location.href = process.env.REACT_APP_LOGIN

        this.state = {
            message: '',
            count:   0,
        }
    }

    componentDidMount () {
        this.props.form.validateFields()
    }

    componentDidUpdate ( prevProps ) {
        const user = this.props.user,
              state = this.state

        if (
            user.progress !== prevProps.user.progress ||
      user.error !== prevProps.user.error
        ) {
            if ( user.error ) {
                this.setState({
                    message: state.count < minLoginAttempts ? errors.once : errors.many,
                    count:   state.count + 1,
                })
            }
        }
    }

    hasErrors = ( fieldsError ) =>
        Object.keys( fieldsError ).some(( field ) => fieldsError[ field ])

    handleSubmit = ( e ) => {
        e.preventDefault()

        this.props.form.validateFields(( err ) => {
            if ( !err ) {
                const login = this.props.form.getFieldValue( 'userName' ),
                      pwd = this.props.form.getFieldValue( 'password' )

                this.props.setAuthProgress( true )
                this.props.auth({ login, pwd })
            }
        })
    }

    render () {
        if ( this.props.user.auth ) {
            //setTimeout( () => window.location.href = '/' , 100 )
            return
        }
        // const [ form ] = Form.useForm()

        return null

        //Maybe for future

    /*const
            {
                getFieldDecorator,
                getFieldsError,
                getFieldError,
                isFieldTouched,
            } = this.props.form,
            userNameError = isFieldTouched('userName') && getFieldError('userName'),
            passwordError = isFieldTouched('password') && getFieldError('password')

        return (
            <Form form={form} onFinish={this.handleSubmit} className="login-form" name="login_form">

                <Form.Item
                    validateStatus={userNameError ? 'error' : ''}
                    help=''
                >
                    {
                        getFieldDecorator( 'userName', {
                            rules: [{
                                required: true,
                                message: 'Нужно ввести логин!'
                            }],
                        })(
                            <Input
                                placeholder="Введите логин"
                                disabled={this.props.user.progress}
                            />
                        )
                    }
                </Form.Item>

                <Form.Item
                    validateStatus={passwordError ? 'error' : ''}
                    help=''
                >
                    {
                        getFieldDecorator( 'password', {
                            rules: [{
                                required: true,
                                message: 'Необходимо указать пароль'
                            }],
                        })(
                            <Input
                                type="password"
                                placeholder="Введите пароль"
                                disabled={this.props.user.progress}
                            />
                        )
                    }
                </Form.Item>

                <div className="form-message has-error">
                    <div className="ant-form-explain">{this.state.message}</div>
                </div>

                <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    disabled={this.hasErrors(getFieldsError()) || this.props.user.progress}
                >
                    { this.props.user.progress
                        ? ( <Spinner /> )
                        : ( 'Войти' )
                    }
                </Button>
            </Form>
        )*/
    }
}

const mapStateToProps = ( state ) => ({ user: state.user, })

export default connect( mapStateToProps, userActions)( LoginForm )
