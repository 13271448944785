/* eslint-disable no-case-declarations */
/* VENDOR */
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import {Form} from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'

import {Col, Modal, Row} from 'antd'

/* APPLICATION */
import {FormField} from 'components'
import {format} from 'tools'
import config from 'config'

class AddEmployeeShift extends Component {
    static propTypes = {
        employees: PropTypes.object,
        positions: PropTypes.object,

        from: PropTypes.object,
        to:   PropTypes.object,

        onSubmit: PropTypes.func,
        onCancel: PropTypes.func,

        visible: PropTypes.bool,
    }

    days = () =>
        config.defs.days.map(( val ) => ({
            value: val,
            label: moment().day( val ).format( 'ddd' ),
        }))

    restrictHour = ( type ) => {
        return ( hour ) => {
            const { form } = this.props,
                  res = []

            switch ( type ) {
                case 'end':
                    const start = parseInt( form.getFieldValue( 'shiftStart' ).format( 'H' )),
                          sm = parseInt( form.getFieldValue( 'shiftStart' ).format( 'm' ))

                    if ( start === hour ) {
                        for ( let i = 0; i <= sm / 15; i++ ) {
                            res.push( i * 15 )
                        }
                    }
                    break
                case 'start':
                    const end = parseInt( form.getFieldValue( 'shiftEnd' ).format( 'H' )),
                          em = parseInt( form.getFieldValue( 'shiftEnd' ).format( 'm' ))

                    if ( end === hour ) {
                        for ( let i = em / 15; i < 60; i++ ) {
                            res.push( i * 15 )
                        }
                    }
                    break
                default:
                    break
            }

            return res
        }
    }

    restrict = ( type ) => {
        return () => {
            const { form, from, to } = this.props,
                  res =
          from && to
              ? format.generate.exrange( 0, 24, from.hours(), to.hours())
              : []

            switch ( type ) {
                case 'end':
                    const start = parseInt( form.getFieldValue( 'shiftStart' ).format( 'H' ))

                    for ( let i = 0; i < start; i++ ) {
                        res.push( i )
                    }
                    break
                case 'start':
                    let end = parseInt( form.getFieldValue( 'shiftEnd' ).format( 'H' ))

                    form.getFieldValue( 'shiftEnd' ).format( 'm' ) > 0 && end++

                    for ( let i = end; i < 24; i++ ) {
                        res.push( i )
                    }
                    break
                default:
                    break
            }

            return res
        }
    }

    checkTime = ( key, val ) => {
        if ( key === 'shiftEnd' ) {
            const start = this.props.form.getFieldValue( 'shiftStart' )

            if ( start.isSame( val )) {
                setTimeout(
                    () =>
                        this.props.form.setFieldsValue({ shiftEnd: moment( start ).add( 15, 'minutes' ), }),
                    100
                )
            }
        }
    }

    checkUnderage = ( _, val ) => {
        const emp = this.getEmployee( val )

        if ( !emp.isUnderage2130 && !emp.isUnderage2200 ) { return }

        const start = this.props.form.getFieldValue( 'shiftStart' ),
              end = this.props.form.getFieldValue( 'shiftEnd' )

        start.hours() < 7 &&
      this.props.form.setFieldsValue({ shiftStart: moment( start ).set({ hours: 7 }), });
        if (emp.isUnderage2130 && end.hours() > 21 || ( end.hours() === 21 && end.minutes() > 30 )) {
            this.props.form.setFieldsValue({ shiftEnd: moment( end ).set({ hours: 21, minutes: 30 }), })
        }
        if (emp.isUnderage2200 && end.hours() > 22 || ( end.hours() === 22 && end.minutes() > 0 )) {
            this.props.form.setFieldsValue({ shiftEnd: moment( end ).set({ hours: 22 }), })
        }
    }

    getEmployee = ( rid ) => {
        const id = rid ?? this.props.form.getFieldValue( 'name' )
        return this.props.fullEmployees.find((e) => e.id === id)
    }

    fields = () => [
        {
            field:   'name',
            label:   'Сотрудник',
            type:    'select',
            options: this.props.employees,
            search:  ( search, opt ) =>
                format.find.strInProp( opt.props, 'children', search ),
            onChange: this.checkUnderage,
            rules:    [ config.rules.required ],
        },
        {
            field:   'position',
            label:   'Позиция',
            type:    'select',
            options: this.props.positions,
            rules:   [ config.rules.required ],
        },
        {
            field: 'restrict',
            type:  'row',
            items: [
                {
                    field:         'shiftStart',
                    label:         'Начало работы',
                    type:          'chartTime',
                    span:          12,
                    value:         moment().set({ hour: 0, minutes: 0 }),
                    bordered:      true,
                    disabledHours: () => {
                        const data = this.getEmployee()

                        if ( data?.isUnderage2130 ) {
                            return format.generate.exrange( 0, 24, 7, 21 )
                        }
                        if (data?.isUnderage2200) {
                            return format.generate.exrange( 0, 24, 7, 22 )
                        }
                    },
                    disabledMinutes: ( hour ) => {
                        const data = this.getEmployee()

                        if (data?.isUnderage2130 && hour === 21) {
                            return format.generate.range(45, 60)
                        }

                        if (data?.isUnderage2200 && hour === 22) {
                            return format.generate.range(15, 60)
                        }

                        return []
                    },
                },
                {
                    field:         'shiftEnd',
                    label:         'Окончание работы',
                    type:          'chartTime',
                    span:          12,
                    bordered:      true,
                    value:         moment().set({ hour: 23, minutes: 0 }),
                    disabledHours: () => {
                        const data = this.getEmployee()

                        if ( data?.isUnderage2130 ) {
                            return format.generate.exrange( 0, 24, 7, 21 )
                        }
                        if (data?.isUnderage2200) {
                            return format.generate.exrange( 0, 24, 7, 22 )
                        }

                    },
                    disabledMinutes: ( hour ) => {
                        const data = this.getEmployee()

                        if (data?.isUnderage2130 && hour === 21) {
                            return format.generate.range(45, 60)
                        }

                        if (data?.isUnderage2200 && hour === 22) {
                            return format.generate.range(15, 60)
                        }

                        return []
                    },
                    onChange: this.checkTime,
                },
            ],
        },
    ]

    row = ( cfg ) => {
        const res = []

        cfg.items.map(( item ) =>
            res.push(
                <Col span={item.span} key={item.field}>
                    {this.field( item )}
                </Col>
            )
        )

        return (
            <Row gutter={24} key={cfg.field} className={cfg.field}>
                {res}
            </Row>
        )
    }

    field = ( cfg ) => {
        if ( cfg.type === 'row' ) {
            return this.row( cfg )
        }

        return (
            <FormField
                {...cfg}
                value={cfg.value}
                key={cfg.field}
                form={this.props.form}
                data={config.defs.fixed}
            />
        )
    }
    preSubmit = () => {
        this.props.form.validateFieldsAndScroll(( err, values ) => {
            if ( !err ) {
                const emp = this.getEmployee()

                this.props.onSubmit({
                    ...values,
                    isUnderage:     emp.isUnderage,
                    rateDictionary: emp.rateDictionary,
                })

                this.props.form.resetFields()
            }
        })
    }

    render () {
        return (
            <Modal
                title="Добавить смену сотрудника"
                className="kfc-popup"
                centered={true}
                open={this.props.visible}
                okText="Добавить"
                okButtonProps={{ className: 'wide-btn' }}
                onOk={this.preSubmit}
                cancelButtonProps={{ style: { display: 'none' } }}
                onCancel={this.props.onCancel}
            >
                <Form
                    {...config.ui.wideForm}
                >
                    {this.fields().map( this.field )}
                </Form>
            </Modal>
        )
    }
}

export default Form.create({ name: 'AddEmployeeShift' })( AddEmployeeShift )
