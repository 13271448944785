import moment              from 'moment'
import React               from 'react'
import { Spin }            from 'antd'
import classNames          from 'classnames'
import { ReportsControls } from 'components'
import config              from 'config'
import './ppr-director-header.scss'


const formatView = config.format.monthView
const formatAPI = config.format.monthAPI

const PprDirectorHeader = ( props ) => {
    const date = props.date ? moment( props.date, formatView ) : null

    const arrow = ( key, handler, disabled ) => {
        return <span
            className={classNames([ 'pager-arrow', `arrow-${key}`, !disabled && 'active' ])}
            onClick={() => !disabled && props.onChange( handler())}
        ></span>
    }

    return (
        <header className="ppr-director-header">
            <Spin spinning={!props.periods && !props.periodsError}>
                <div className="ppr-director-header-inner">
                    <div className="header-title">{props.periods && moment( date ).format( formatView )}</div>
                    <div className="header-picker controls-pager">
                        {arrow(
                            'prev',
                            () => moment( date ).subtract( 1, 'month' ),
                            props.periodsError || !props.periods?.find( period => moment( date ).subtract( 1, 'month' ).isSame( period, 'month' ))
                        )}
                        <ReportsControls
                            type="month"
                            date={date ? moment( date ).startOf( 'month' ) : undefined}
                            onChange={props.onChange}
                            showDate={false}
                            disabledDate={( date ) => !props.periods?.some( period => moment( period, formatAPI ).isSame( date ))}
                            disabled={!!props.periodsError}
                        />
                        {arrow(
                            'next',
                            () => moment( date ).add( 1, 'month' ),
                            props.periodsError || !props.periods?.find( period => moment( date ).add( 1, 'month' ).isSame( period, 'month' ))
                        )}
                    </div>
                </div>
            </Spin>

        </header>
    )
}

export default PprDirectorHeader
