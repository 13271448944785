import { format } from 'tools'
import moment     from 'moment'

const service = 'dashboard'

export const getServiceTime = ( params ) => {
    const paramObj = {
        ...params,
        factsNumbers: params.restaurantId,
    }
    delete paramObj.restaurantId
    delete paramObj.zoneId

    if ( params.serviceType ) {
        return format.generate.get( service, 'service-time/' + params.serviceType, paramObj )
    }
}

export const getServiceTimeSummary = ( params ) => {
    const paramObj = {
        ...params,
        dateStart:    moment( params.dateStart ).format( 'YYYY-MM-DD' ),
        dateEnd:      moment( params.dateEnd ).format( 'YYYY-MM-DD' ),
        factsNumbers: params.restaurantId,
    }
    delete paramObj.restaurantId
    delete paramObj.zoneId

    return format.generate.get(
        service,
        'service-time/summary/' + params.serviceType,
        paramObj
    )
}
