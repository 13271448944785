/* VENDOR */
import React from 'react'

/* APPLICATION */
import { Icon, IconActive, createIcon } from './Icon'

const svg = ( primary ) => (
    <svg width="22" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.089 8.278l.207-.206V12c0 .39.315.703.706.703.39 0 .705-.314.705-.703V6.375c0-.286-.17-.539-.437-.651a.698.698 0 0 0-.767.154L9.09 7.284a.697.697 0 0 0 0 .994c.278.277.72.277.998 0z"
            fill={primary}
        />
        <path
            d="M11.002 0C5.944 0 1.828 4.148 1.828 9.187c0 5.04 4.116 9.14 9.174 9.14s9.175-4.1 9.175-9.14C20.177 4.148 16.06 0 11.002 0zm0 15.515c-3.5 0-6.351-2.84-6.351-6.328C4.65 5.7 7.5 2.86 11.002 2.86c3.5 0 6.352 2.84 6.352 6.328 0 3.488-2.851 6.328-6.352 6.328zM21.936 20.6l-2.419-5.17a10.59 10.59 0 0 1-5.928 3.97l1.953 4.19a.71.71 0 0 0 .64.408h.033a.707.707 0 0 0 .63-.464l1.12-3.098 3.091 1.12a.704.704 0 0 0 .88-.956zM2.49 15.432l-2.423 5.17a.705.705 0 0 0 .88.96l3.091-1.124 1.125 3.098c.099.267.348.45.63.464h.033a.71.71 0 0 0 .64-.408l1.953-4.19a10.59 10.59 0 0 1-5.929-3.97z"
            fill={primary}
        />
    </svg>
)

class Rating extends Icon {
    render () {
        return svg( this.primary, this.secondary )
    }
}

class RatingActive extends IconActive {
    render () {
        return svg( this.primary, this.secondary )
    }
}

export default createIcon( Rating, RatingActive )
