/* VENDOR */
import React, { Component } from 'react'
import { Layout }           from 'antd'
import { connect }          from 'react-redux'

/* APPLICATION */
import { Center, Logo, Alert } from 'components'
import { userActions }         from 'services'

import './auth-error.scss'
import { format } from 'tools'



const { Content, Header } = Layout

class AuthError extends Component {
    render () {
        const { text, hideDeskMsg, showLogout, title, user } = this.props,
              message = text || 'Ошибка авториации',
              deskMsg = hideDeskMsg ? '' : 'Обратитесь в service desk.'

        return (
            <section className="kfc-auth-error-message">
                <Layout>
                    <Header>
                        <Logo full />
                    </Header>
                    <Layout>
                        <Content>
                            <Center>
                                <Alert
                                    text={
                                        <>
                                            {title && <h3>{title}</h3>}
                                            {`${message}. ${deskMsg}`}
                                        </>
                                    }
                                />
                                {showLogout && (
                                    <a onClick={() => format.update.getLogout( user?.endpoints )} className="logout-button">
                    Выйти
                                    </a>
                                )}
                            </Center>
                        </Content>
                    </Layout>
                </Layout>
            </section>
        )
    }
}

const mapStateToProps = ( state ) => ({ user: state.user, })

export default connect( mapStateToProps, userActions )( AuthError )
