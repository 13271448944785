import React from 'react'

/* APPLICATION */
import { Icon, IconActive, createIcon } from './Icon'

const svg = ( primary ) => (
    <svg width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.989 2.333C7.549 2.333 2.322 7.56 2.322 14c0 6.44 5.227 11.666 11.667 11.666 6.44 0 11.666-5.226 11.666-11.666S20.43 2.333 13.99 2.333zM18.2 9.73a2.246 2.246 0 012.252 2.251 2.246 2.246 0 01-2.252 2.252 2.246 2.246 0 01-2.251-2.252A2.238 2.238 0 0118.2 9.73zm-7-1.844a2.758 2.758 0 012.754 2.754 2.758 2.758 0 01-2.754 2.753 2.758 2.758 0 01-2.753-2.753A2.744 2.744 0 0111.2 7.886zm0 10.652v4.375a9.341 9.341 0 01-5.996-5.787c1.225-1.306 4.281-1.971 5.996-1.971.619 0 1.4.093 2.217.256-1.913 1.015-2.217 2.357-2.217 3.127zm2.789 4.795c-.315 0-.619-.012-.922-.047v-4.748c0-1.657 3.43-2.485 5.133-2.485 1.249 0 3.407.455 4.48 1.342-1.365 3.465-4.736 5.938-8.691 5.938z"
            fill={primary}
        />
    </svg>
    /* VENDOR */
)

class Admin extends Icon {
    render () {
        return svg( this.primary, this.secondary )
    }
}

class AdminActive extends IconActive {
    render () {
        return svg( this.primary, this.secondary )
    }
}

export default createIcon( Admin, AdminActive )
