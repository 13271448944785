/* VENDOR */
import React from 'react'

/* APPLICATION */
import { Icon, IconActive, createIcon } from './Icon'

const svg = ( primary ) => (
    <svg
        width="18"
        height="18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ transform: 'translateY(-1px)' }}
    >
        <path
            d="M9 6H13C13.55 6 14 5.55 14 5C14 4.45 13.55 4 13 4H9C8.45 4 8 4.45 8 5C8 5.55 8.45 6 9 6ZM9 10H13C13.55 10 14 9.55 14 9C14 8.45 13.55 8 13 8H9C8.45 8 8 8.45 8 9C8 9.55 8.45 10 9 10ZM9 14H13C13.55 14 14 13.55 14 13C14 12.45 13.55 12 13 12H9C8.45 12 8 12.45 8 13C8 13.55 8.45 14 9 14ZM4 4H6V6H4V4ZM4 8H6V10H4V8ZM4 12H6V14H4V12ZM17 0H1C0.45 0 0 0.45 0 1V17C0 17.55 0.45 18 1 18H17C17.55 18 18 17.55 18 17V1C18 0.45 17.55 0 17 0ZM16 16H2V2H16V16Z"
            fill={primary}
        />
    </svg>
)

class Table extends Icon {
    render () {
        return svg( this.primary, this.secondary )
    }
}

class TableActive extends IconActive {
    render () {
        return svg( this.primary, this.secondary )
    }
}

export default createIcon( Table, TableActive )
