/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Button }     from 'antd'
import { CloseOutlined } from '@ant-design/icons'

/* APPLICATION */
import { format } from 'tools'
import './alert.scss'

class Alert extends Component {
    static propTypes = {
        text: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),

        button:     PropTypes.bool,
        buttonText: PropTypes.string,
        action:     PropTypes.func,

        cancel:     PropTypes.bool,
        cancelText: PropTypes.string,
        onCancel:   PropTypes.func,
    }
    render () {
        return (
            <div
                className="unsaved"
                style={{ right: format.check.scrollbarWidth(), }}
            >
                {this.props.text}

                {this.props.button && (
                    <Button type="default" onClick={this.props.action}>
                        {this.props.buttonText}
                    </Button>
                )}

                {this.props.cancel && (
                    <span className="alert-cancel" onClick={this.props.onCancel}>
                        {this.props.cancelText}
                        <CloseOutlined />
                    </span>
                )}
            </div>
        )
    }
}

export default Alert
