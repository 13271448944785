/* VENDOR */
import React from 'react'

/* APPLICATION */
import { Icon, IconActive, createIcon } from './Icon'

const svg = ( primary ) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="17" fill="none">
        <path
            fill={primary}
            d="M13.1154 12.7977h-1.5433v-1.5434c0-.3498-.2848-.6346-.6346-.6346-.3498 0-.6346.2848-.6346.6346v1.5434H8.75957c-.3498 0-.63457.2847-.63457.6345 0 .3498.28477.6346.63457.6346h1.54333v1.5434c0 .3498.2848.6345.6346.6345.3498 0 .6346-.2847.6346-.6345v-1.5434h1.5433c.3498 0 .6346-.2848.6346-.6346 0-.3515-.2848-.6345-.6346-.6345zm-2.1779-8.35141C10.9375 2.2666 9.17441.5 7 .5S3.0625 2.2666 3.0625 4.44629 4.82559 8.39258 7 8.39258s3.9375-1.7666 3.9375-3.94629zM7 7.12344c-1.4748 0-2.67188-1.19883-2.67188-2.67715S5.5252 1.76914 7 1.76914c1.4748 0 2.67188 1.19883 2.67188 2.67715S8.4748 7.12344 7 7.12344zm3.6914 2.36953C9.62969 8.79688 8.3623 8.39258 7 8.39258c-3.72832 0-6.75 3.02872-6.75 6.76582h.001758c0 .0123-.001758.0228-.001758.0352 0 .3498.283008.6345.632812.6345.349808 0 .632808-.2847.632808-.6345 0-.0124 0-.0229-.00175-.0352h.00175c0-3.0357 2.45567-5.49668 5.48438-5.49668.68906 0 1.34824.12656 1.95469.36038.51679-.30237 1.10571-.49046 1.73671-.52913z"
        />
    </svg>
)

class Add extends Icon {
    render () {
        return svg( this.primary, this.secondary )
    }
}

class AddActive extends IconActive {
    render () {
        return svg( this.primary, this.secondary )
    }
}

export default createIcon( Add, AddActive )
