/* VENDOR */
import React from 'react'

/* APPLICATION */
import { Icon, IconActive, createIcon } from './Icon'

const svg = ( primary ) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
        <path
            fill={primary}
            d="M10 20c.6625-.0018 1.2973-.2658 1.7657-.7343.4685-.4684.7325-1.1032.7343-1.7657v-15c0-.66304-.2634-1.29893-.7322-1.767767C11.2989.263392 10.663 0 10 0c-.66304 0-1.29893.263392-1.76777.732233C7.76339 1.20107 7.5 1.83696 7.5 2.5v15c.00185.6625.26583 1.2973.73427 1.7657.46844.4685 1.10326.7325 1.76573.7343zm-7.5 0c.66247-.0018 1.29729-.2658 1.76573-.7343.46844-.4684.73242-1.1032.73427-1.7657v-5c0-.663-.26339-1.2989-.73223-1.7678C3.79893 10.2634 3.16304 10 2.5 10c-.66304 0-1.29893.2634-1.767767.7322C.263392 11.2011 0 11.837 0 12.5v5c.00184718.6625.265832 1.2973.734273 1.7657C1.20271 19.7342 1.83753 19.9982 2.5 20zM15 8.75v8.75c0 .663.2634 1.2989.7322 1.7678.4689.4688 1.1048.7322 1.7678.7322s1.2989-.2634 1.7678-.7322C19.7366 18.7989 20 18.163 20 17.5V8.75c0-.66304-.2634-1.29893-.7322-1.76777C18.7989 6.51339 18.163 6.25 17.5 6.25s-1.2989.26339-1.7678.73223C15.2634 7.45107 15 8.08696 15 8.75z"
        />
    </svg>
)

class Dashboard extends Icon {
    render () {
        return svg( this.primary, this.secondary )
    }
}

class DashboardActive extends IconActive {
    render () {
        return svg( this.primary, this.secondary )
    }
}

export default createIcon( Dashboard, DashboardActive )
