/* VENDOR */
import React from 'react'

/* APPLICATION */
import { Icon, IconActive, createIcon } from './Icon'

const svg = ( primary ) => (
    <svg width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M22.167 3.5H17.29C16.8 2.147 15.517 1.167 14 1.167c-1.517 0-2.8.98-3.29 2.333H5.833A2.34 2.34 0 003.5 5.833v16.334A2.34 2.34 0 005.833 24.5h16.334a2.34 2.34 0 002.333-2.334V5.834A2.34 2.34 0 0022.167 3.5zM14 3.5a1.17 1.17 0 011.167 1.167A1.17 1.17 0 0114 5.832a1.17 1.17 0 01-1.167-1.167A1.17 1.17 0 0114 3.5zm-3.162 15.505l-3.021-3.022a1.162 1.162 0 010-1.645 1.162 1.162 0 011.645 0l2.205 2.194 6.86-6.86a1.162 1.162 0 011.645 0c.455.454.455 1.19 0 1.644l-7.689 7.689a1.162 1.162 0 01-1.645 0z"
            fill={primary}
        />
    </svg>
)

class Tasks extends Icon {
    render () {
        return svg( this.primary, this.secondary )
    }
}

class TasksActive extends IconActive {
    render () {
        return svg( this.primary, this.secondary )
    }
}

export default createIcon( Tasks, TasksActive )
