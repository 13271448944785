import {
    PostFeedbacksSearchBody,
    PostStatisticsFeedbacksSummaryPayload,
} from './type';
import {format} from 'tools';

const service = 'feedback'

export const postStatisticsFeedbacksSummary = (body: PostStatisticsFeedbacksSummaryPayload) =>
    format.generate.post(service, 'feedbacks/statistics/summary', body)

export const postFeedbacksSearch = ({body, params}: {body: PostFeedbacksSearchBody, params?: { page?: number }}) =>
    format.generate.post(service, 'feedbacks/search', body, '', '', '', '', {params})
