import {IState} from './type';
// @ts-ignore
import {update} from 'reduxigen'
import {AxiosResponse} from 'axios';
import {format} from 'tools';

const asyncUpdate = format.update.asyncu

export function storeUpdate<Payload = void> (name: string, func: (data: Payload, state: IState) => IState): (data: Payload) => void {
    return update(name, func)
}

export function storeAsyncUpdate<Payload, Response> (name: string, requester: (data: Payload) => void, parser: (event: AxiosResponse<Response>, state: IState) => IState): (data: Payload) => void {
    return asyncUpdate(name, requester, parser)
}
