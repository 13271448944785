export const day = 'DD.MM.YYYY'

export const dayView = 'D MMMM YYYY'

export const dayViewShort = 'D MMM YYYY'

export const dayAPI = 'YYYY-MM-DD'

export const date = 'YYYY-MM-DDTHH:mm'

export const dateView = 'D MMMM YYYY, HH:mm'

export const dateViewShort = 'D MMM YYYY, HH:mm'

export const dateFull = 'YYYY-MM-DDTHH:mm:00'

export const dateFullWithSeconds = 'YYYY-MM-DDTHH:mm:ss'

export const time = 'HH:mm'

export const onlyDayView = 'D MMMM'

export const onlyDayViewShort = 'D MMMM'

export const dayTabView = 'D MMM'

export const monthView = 'MMMM YYYY'

export const monthAPI = 'YYYY-MM'

export const dayMonth = '--MM-DD';
