import React, { useEffect, useState } from 'react'
import { Row }                        from 'antd'
import {
    Spinner,
    PprPartnerAreaInfo,
    PprPartnerInfo
} from 'components'
import { connect }                     from 'react-redux'
import { allActions, mapStateToProps } from './connector'
import './ppr-partner.scss'

const PprPartner = ({ ppr, period, restaurantId, ...props }) => {
    const [ loading, setLoading ] = useState( false )

    useEffect(() => {
        getPeriods()
    }, [])

    useEffect(() => {
        if ( Array.isArray( ppr?.periods ) && period ) {
            //update
        }
    }, [ ppr, period, restaurantId ])

    const getPeriods = () => {
        setLoading( true )
        props.fetchStaffPprAvailablePeriods().finally(() => setLoading( false ))
    }

    return (
        <div className="ppr-area">
            {
                loading ? <Spinner />
                    :
                        <React.Fragment>
                            <Row gutter={16} style={{ marginBottom: 24 }}>
                                <PprPartnerInfo />
                            </Row>
                            <Row gutter={16} style={{ marginBottom: 60 }}>
                                <PprPartnerAreaInfo />
                                <PprPartnerAreaInfo />
                                <PprPartnerAreaInfo />
                                <PprPartnerAreaInfo />
                            </Row>
                        </React.Fragment>
            }
        </div>
    )
}

export default connect( mapStateToProps, allActions )( PprPartner )
