import React, {Component} from 'react'
import classNames from 'classnames'
import moment from 'moment'
import {Select, Table} from 'antd'
import Icon from '@ant-design/icons'

import {ChartTime, Icons, Spinner} from 'components'
import {format} from 'tools'
import config from 'config'

import * as helpers from '../helpers'

class ScheduleChartTable extends Component {
    constructor ( props ) {
        super( props )

        this.state = {
            columns:      [],
            open:         {},
            scroll:       {},
            tmpEmpNames:  {},
            tmpPositions: {},
        }

        this.set = format.generate.set( this )
    }

    componentDidMount () {
        const { columns } = this.props

        !!columns && this.set.columns( this.apply( columns ))
    }

    componentDidUpdate ( prevProps ) {
        const { columns, employees } = this.props

        if ( columns !== prevProps.columns || employees !== prevProps.employees ) {
            this.set.state(
                {
                    columns:      this.apply( columns ),
                    tmpEmpNames:  {},
                    tmpPositions: {},
                },
                () => this.onDone()
            )
        }

        if (
            format.compare.changed( this.props, prevProps, [
                'columns',
                'data',
                'from',
                'to',
            ])
        ) {
            this.updateScroll()
        }
    }

    onDone = () => {
        this.props.onDone && this.props.onDone()
    }

    updateScroll = () => {
        const { from, to, compact, inlineHours } = this.props

        this.set.scroll({ x: helpers.width( from, to, compact, inlineHours ) })
    }

    cellSearch = () => ( search, opt ) =>
        format.find.strInProp( opt.props, 'children', search )

    apply = ( cols ) => {
        const { employees, positions, inlineHours } = this.props,
              res = cols.map(( col ) => {
                  switch ( col.dataIndex ) {
                      case 'employeeName':
                          col.items = employees || 'spin'
                          col.tmpName = 'tmpEmpNames'

                          return {
                              ...col,
                              className: ( row ) => ( row.__spoiler ? 'spoiler-row' : '' ),
                              render:    ( data, row ) => {
                                  if ( row.__spoiler ) {
                                      return (
                                          <span
                                              className={classNames(
                                                  'render-as-label spoiler',
                                                  !this.state.open[ row.__spkey ] && 'open'
                                              )}
                                          >
                                              {data}
                                          </span>
                                      )
                                  }

                                  if ( row._warning ) {
                                      return (
                                          <span
                                              className="assign-btn"
                                              onClick={this.props.onAssign( row )}
                                          ></span>
                                      )
                                  }

                                  return this.select( col, data, row )
                              },
                          }

                      case 'shiftStart':
                      case 'shiftEnd':
                          return {
                              ...col,
                              render: this.chartTime( col ),
                          }

                      case 'position':
                          col.items = positions || 'spin'
                          col.tmpName = 'tmpPositions'

                          return {
                              ...col,
                              render: ( data, row ) => this.select( col, data, row ),
                          }

                      default:
                          if ( col.render_as === 'remove' ) {
                              return {
                                  ...col,
                                  render: this.remove( col ),
                              }
                          } else if ( col.dataIndex.indexOf( 'hour-' ) === 0 ) {
                              return {
                                  ...col,
                                  render: ( _, row ) => {
                                      return row.__spoiler && inlineHours
                                          ? col.dataIndex.replace( 'hour-', '' )
                                          : _
                                  },
                              }
                          }

                          return col
                  }
              })

        return [ ...res ]
    }

    onTime = ( record, key, index ) => {
        return ( e ) =>
            this.props.onChange( record, key, e.format( config.format.time ), index )
    }

    onSelect = ( record, key, tmpName, index ) => {
        return ( val ) => {
            this.set[ tmpName ]({ ...this.state[ tmpName ], [ record.guid ]: val }, () =>
                this.props.onChange( record, key, val, index )
            )
        }
    }

    disabled = ( col, record ) => {
        if ( !record ) { return true }
        if ( record.disabled === true || record.disabled === 'true' ) { return true }
        if ( record.disabled && record.disabled[ col.dataIndex ]) { return true }
        if ( col.disabled ) {
            return typeof col.disabled === 'function'
                ? col.disabled( record )
                : col.disabled
        }
        return false
    }

    remove = ( col ) => {
        return ( _, record, index ) => {
            if ( !col.editable || record.disableRemove || record.__spoiler ) { return null }

            return (
                <span
                    className="remove-row-control"
                    onClick={() => this.props.onRemove( record, index )}
                >
                    <Icon component={Icons.Remove.active} />
                    {!col.small && 'Удалить'}
                </span>
            )
        }
    }

    select = ( col, text, record, index ) => {
        if ( record.__spoiler ) {
            return null
        }

        if ( !col.editable || this.disabled( col, record )) {
            /*
                const
                    found = cfg.items && cfg.items[col.items]
                        ? cfg.items[col.items].find( opt => text && opt.value && opt.value.toString() === text.toString() )
                        : null,
                    value = found
                        ? found.label
                        : text
                        */

            return <span className={col.className}>{text}</span>
        }

        const tmp = this.state[ col.tmpName ],
              value = tmp[ record.guid ] || text

        return (
            <span
                className={col.className}
                draggable={true}
                onDragStart={( e ) => {
                    e.preventDefault()
                    e.stopPropagation()
                }}
            >
                <Select
                    value={value}
                    showSearch={true}
                    bordered={false}
                    popupClassName={col.className + '-dropdown'}
                    filterOption={this.cellSearch( record, col )}
                    onChange={this.onSelect( record, col.dataIndex, col.tmpName, index )}
                    getPopupContainer={( node ) => node.parentNode.parentNode.parentNode.parentNode.parentNode}
                >
                    {col.items &&
            ( col.items === 'spin' ? (
                <Select.Option value="spin" key="spin">
                    <Spinner size="small" />
                </Select.Option>
            ) : (
                col.items.map(( d ) => (
                    <Select.Option value={d.value} key={d.key || d.value}>
                        {d.label}
                    </Select.Option>
                ))
            ))}
                </Select>
            </span>
        )
    }

    getDisabledHours = (dataIndex, record, min, max) => {
        if (record.isUnderage2200) {
            return format.generate.exrange(
                0,
                24,
                Math.max( 7, min ),
                Math.min( 22, max )
            )
        } else if (record.isUnderage2130) {
            return format.generate.exrange(
                0,
                24,
                Math.max( 7, min ),
                Math.min( 21, max )
            )
        } else {
            return null;
        }
    }

    getDisabledMinutes = ( dataIndex, record, now ) => {
        return ( hour ) => {
            if ( hour === 22 && record.isUnderage2200 ) {
                return format.generate.range( 15, 60 )
            } else if ( hour === 21 && record.isUnderage2130 ) {
                return format.generate.range( 45, 60 )
            } else {
                return []
            }
        }

    }

    chartTime = ( col ) => {
        const { isToday } = this.props,
              isEnd = ( m ) => m.minutes() > 45,
              nowHours = ( m ) => ( isEnd( m ) ? m.add( 1, 'hour' ).hours() : m.hours()),
              nowMinutes = ( m, date ) => ( hour ) =>
                  isEnd( m ) || hour !== nowHours( m ) || !m.isSame( date, 'day' )
                      ? []
                      : format.generate.range( 0, m.minutes()),
              min =
        col.checkToday && isToday
            ? ( m ) => Math.max( col.min || 0, nowHours( m ))
            : () => col.min || 0,
              max = col.max || 24

        return ( text, record, index ) => {
            if ( record.__spoiler ) { return null }
            if ( !col.editable ) { return moment( text ).format( config.format.time ) }

            const now = moment()

            return (
                <ChartTime
                    minuteStep={15}
                    value={moment( text )}
                    format={config.format.time}
                    disabled={this.disabled( col, record )}
                    disabledHours={this.getDisabledHours(col.dataIndex, record, min(now), max)}
                    disabledMinutes={this.getDisabledMinutes(col.dataIndex, record, nowMinutes( now, record.__real_date ))}
                    onChange={this.onTime( record, col.dataIndex, index )}
                    getPopupContainer={( node ) => node.parentNode.parentNode.parentNode.parentNode}
                />
            )
        }
    }

    toggle = ( row ) => {
        const open = { ...this.state.open }

        open[ row.__spkey ] = !open[ row.__spkey ]

        this.set.open( open )
    }

    rowAtts = ( row ) => ({ onClick: () => row.__spoiler && this.toggle( row ), })

    rowClass = ( record ) => {
        let { styleInactive } = this.props,
            cls = record.rowClass ? [ record.rowClass ] : []

        !record.__spoiler &&
      this.state.open[ `details${this.props.day}${record.positionGuid}` ] &&
      cls.push( 'row-hidden' );
        ( record._api_error ||
      record._error ||
      record._mc_error ||
      record._outstaff_error ) &&
      cls.push( 'row-error' )
        record._warning && cls.push( 'row-warning' )
        record._current && cls.push( 'row-current' )
        record.fixed && cls.push( 'row-fixed' );
        ( record.lowRest || record.muchWork ) && cls.push( 'row-overworked' )
        if (record.isUnderage2130 || record.isUnderage2200) {
            cls.push( 'row-underage' )
        }

        if ( styleInactive ) {
            !record.status &&
        !record.active &&
        !record.enabled &&
        cls.push( 'row-inactive' )
        } else {
            ( record.disabled || record.deleted ) && cls.push( 'row-inactive' )
        }
        cls.push('schedule-row')

        return classNames( cls )
    }

    render () {
        const { data } = this.props,
              { columns, scroll } = this.state

        return (
            <div className="report-wrap" ref={( node ) => ( this._container = node )}>
                {data ? (
                    <Table
                        rowKey="guid"
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        scroll={scroll}
                        onRow={this.rowAtts}
                        rowClassName={this.rowClass}
                    />
                ) : (
                    <div className="spinner-container chart">
                        <Spinner />
                    </div>
                )}
            </div>
        )
    }
}

export default ScheduleChartTable
