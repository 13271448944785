/* VENDOR */
import React, { Component }               from 'react'
import { connect }                        from 'react-redux'
import { Layout, Checkbox, Button } from 'antd'
import ReactExport                        from 'react-data-export'
import moment                             from 'moment'

/* APPLICATION */
import {
    AppHeader,
    InnerContent,
    BackTitle,
    InnerControls,
    TabbedReport,
    Link,
    CustomTab,
    Icons,
    ReportExcelTooltip,
} from 'components'
import Icon from '@ant-design/icons'

import { format } from 'tools'
import config     from 'config'

import { allActions, mapStateToProps } from './connector'
import * as calculate                  from './calculations'
import './rating-report.scss'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet

const { Content, Header } = Layout,
      intervals = {}

class RatingReport extends Component {
    constructor ( props ) {
        super( props )
        this.state = {
            view:       'categories',
            tableWidth: '70%',

            cashiers:    {},
            cashColumns: [],
            deltas:      [],

            dict:        false,
            hideCombo:   false,
            emptySearch: false,
            cashdish:    false,

            dishes:     null,
            categories: null,
        }
    }

    componentDidMount () {
        const { rating, workHours, request, fetchWorkHours } = this.props

        if ( format.check.empty( rating ) || !rating.dishes ) {
            this.load( this.props )
        }

        rating && rating.dishes && this.setDishes( rating.dishes )
        rating && rating.modifiers && this.setModifiers( rating.modifiers )
        rating && rating.categories && this.setCategories( rating.categories )
        rating && rating.cashiers && this.setCashiers( rating.cashiers )
        !workHours && fetchWorkHours( request )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { rating, workHours, request, fetchWorkHours } = this.props
        let workHoursLoad = false

        nextProps.rating.cashiers !== rating.cashiers &&
      this.setCashiers( nextProps.rating.cashiers )
        nextProps.rating.dishes !== rating.dishes &&
      this.setDishes( nextProps.rating.dishes )
        nextProps.rating.modifiers !== rating.modifiers &&
      this.setModifiers( nextProps.rating.modifiers )
        nextProps.rating.categories !== rating.categories &&
      this.setCategories( nextProps.rating.categories )

        if ( nextProps.request.restaurantId !== request.restaurantId && nextProps.workHours === workHours ) {
            fetchWorkHours( nextProps.request )
            workHoursLoad = true
        }

        if ( nextProps.workHours !== workHours ) {
            this.load( nextProps )
        }

        if ( format.check.request( request, nextProps.request ) && !workHoursLoad ) {
            this.load( nextProps )
        }
    }

    componentDidUpdate ( prevProps, prevState ) {
        if (
            prevProps.rating !== this.props.rating ||
      prevState.view !== this.state.view
        ) {
            this.setTableWidth()
        }
    }

    setDishes = ( dishes ) => {
        this.setState(
            { dishes, },
            this.setTableWidth
        )
    }

    setModifiers = ( modifiers ) => this.setState({ modifiers })
    setCategories = ( categories ) => this.setState({ categories })

    setCashiers = ( data ) =>
        this.setState({
            cashiers:    calculate.cashiers( data, this.state.cashdish ),
            cashColumns: calculate.cashColumns( data, this.state.cashdish ),
            deltas:      calculate.deltas( data, this.state.cashdish ),
        })

    toggleCashDish = ( e ) =>
        this.setState(
            { cashdish: e.target.checked, },
            this.load
        )

    updateDishView = ( view ) => {
        this.setState({ view })
        this.load( this.props, view )
    }

    preparePersons = ( record, index, columns ) =>
        format.goodBad.iterate.goodBad( record, this.state.deltas, columns )

    prepareDishes = ( record, index, columns ) =>
        format.goodBad.iterate.goodBad( record, [ 'deltaValue' ], columns )

    load = ( rprops, rview ) => {
        const { dict, hideCombo, cashdish } = this.state,
              props = rprops || this.props,
              req = format.generate.pager( props.request, 0 ),
              view = rview || this.state.view

        if ( !format.check.enough( props )) { return }

        props.flushRating()

        const reqObj = {
            ...req,
            factsNumber: req.restaurantId
        }

        delete reqObj.restaurantId
        delete reqObj.zoneId

        switch ( view ) {
            case 'categories':
                props.fetchCategories( reqObj )
                break
            case 'modifiers':
                props.fetchModifiers( reqObj )
                break
            case 'managers':
                // props.fetchManagers( reqObj )
                break
            case 'cashiers':
                cashdish ? props.fetchCashiersByDish( reqObj ) : props.fetchCashiers( reqObj )
                break
            case 'dishes':
            default:
                props.fetchDishes({ ...reqObj, all: dict, hideCombo })
                break
        }
    }

    more = {
        dishes: ( req ) => {
            const reqObj = {
                ...req,
                factsNumber: req.restaurantId
            }

            delete reqObj.restaurantId
            delete reqObj.zoneId
            this.props.appendDishes({
                ...reqObj,
                all:       this.state.dict,
                hideCombo: this.state.hideCombo,
            })
        },
        modifiers: ( req ) => {
            const reqObj = {
                ...req,
                factsNumber: req.restaurantId
            }

            delete reqObj.restaurantId
            delete reqObj.zoneId
            this.props.appendModifiers( reqObj )
        },
        categories: ( req ) => {
            const reqObj = {
                ...req,
                factsNumber: req.restaurantId
            }

            delete reqObj.restaurantId
            delete reqObj.zoneId
            this.props.appendCategories( reqObj )
        },
        // managers:   ( req ) => this.props.appendManagers( req ),
        cashiers: ( req ) => {
            const reqObj = {
                ...req,
                factsNumber: req.restaurantId
            }

            delete reqObj.restaurantId
            delete reqObj.zoneId
            this.state.cashdish
                ? this.props.appendCashiersByDish( reqObj )
                : this.props.appendCashiers( reqObj )
        },
    }

    setPlan = ( dish, uidKey, type ) => {
        if ( intervals[ dish[ uidKey ] ]) {
            clearTimeout( intervals[ dish[ uidKey ] ])
        }

        intervals[ dish[ uidKey ] ] = setTimeout(() => {
            this.props.savePlan({
                id:          null,
                [ uidKey ]:  dish[ uidKey ],
                factsNumber: this.props.request.restaurantId,
                rating:      dish.planValue,
                _type:       type,
            })
            clearTimeout( intervals[ dish[ uidKey ] ])
        }, 1000 )
    }

    change = ( dataKey, uidKey ) => {
        return ( original, key, val ) => {
            const items = format.copy.object( this.state[ dataKey ]),
                  dish = format.copy.object( original ),
                  index = format.find.index( items.content, uidKey, original[ uidKey ])

            let type = 'dish'

            dataKey === 'categories' && ( type = 'category' )
            dataKey === 'modifiers' && ( type = 'modifier' )

            dish[ key ] = val
            dish.deltaValue = dish.factValue - dish.planValue
            key === 'planValue' && this.setPlan( dish, uidKey, type )

            items.content[ index ] = dish

            this.setState({ [ dataKey ]: items, })
        }
    }

    toggleDictionary = () => this.setState({ dict: !this.state.dict }, this.load )

    toggleCombo = ( e ) =>
        this.setState({ hideCombo: e.target.checked }, this.load )

    set = ( data ) => this.props.setRequest( data )

    deep = ( data ) => {
        this.props.selectCategory( data )

        this.props.flushCategoryDishes()

        this.props.history.push( '/dashboard/rating/' + data.categoryId )
    }

    setTableWidth = () => {
        const table = document.querySelector(
            '.ant-tabs-tabpane-active .ant-table table'
        )

        table && this.setState({ tableWidth: table.offsetWidth + 24 })
    }

    setEmpty = ( filtered ) =>
        this.setState({ emptySearch: !filtered || filtered.length < 1, })

    toggleTop = ( type ) => {
        return ( item ) => {
            return ( top ) => {
                const { request } = this.props,
                      params = {
                          ...item,
                          top,
                          _type:         type,
                          restaurantUid: request.restaurantId,
                      }

                let key = 'productUid'

                type === 'category' && ( key = 'categoryId' )

                params[ key ] = item[ key ]

                switch ( type ) {
                    case 'category':
                        params.endpoint = 'top-categories'
                        break
                    case 'modifier':
                        params.endpoint = 'top-modifiers'
                        break
                    default:
                    case 'dish':
                        params.endpoint = 'top-products'
                        break
                }

                this.props.toggleTop( params )
            }
        }
    }

    placeholder = ( view ) => {
        switch ( view ) {
            case 'modifiers':
                return 'Введите название модификатора'
            case 'categories':
                return 'Введите категорию'
            case 'dishes':
            default:
                return 'Введите название блюда'
        }
    }

    isEmpty = ( view ) => this.state[ view ] && this.state[ view ].length < 1

    prepareExcel = ( tab ) => {
        const red = '00FF0000'
        const green = '00038E1D'
        const getFormattedValue = ( value, digits = null ) =>
            value !== null ?
                `${digits !== null ? ( +value ).toFixed( digits ) : value} %`
                :
                ''

        if ( !tab.data || !tab.data.content ) { return [] }

        if ( tab.name === 'cashiers' ) {
            const excludeFields = [ 'name', 'avgNet', 'sumNet' ]
            const columns = tab.columns.filter( item =>
                !excludeFields.find( f => f === item.dataIndex )
            )

            const result = [ {
                columns: [
                    { title: 'ФИО', width: { wpx: 150 } },
                    ...columns.map(( col ) => ({
                        title: col.title,
                        width: { wpx: col.dataIndex.indexOf( 'Delta' ) > -1 ? 80 : 120 },
                        style: {
                            border:    { color: { rgb: '00FFFFFF' } },
                            alignment: { wrapText: true },
                            font:      { bold: true }
                        }
                    })),
                    { title: 'Средний чек', width: { wpx: 120 } },
                    { title: 'Общая выручка', width: { wpx: 120 } },
                ],
                data: tab.data.content.map(( item => ([
                    { value: item.name },
                    ...columns.map( col => ({
                        value: col.dataIndex.indexOf( 'Delta' ) > -1 ? item[ col.dataIndex ] : getFormattedValue( item[ col.dataIndex ]),
                        ...( col.dataIndex.indexOf( 'Delta' ) > -1 && { style: { font: { color: { rgb: item[ col.dataIndex ] < 0 ? red : green } } } })
                    })),
                    { value: item.avgNet || '' },
                    { value: item.sumNet || '' },
                ])
                ))
            } ]

            return result
        } else {
            const colNames = {
                categories: 'Категория',
                dishes:     'Блюдо',
                modifiers:  'Модификатор'
            }
            return [ {
                columns: [
                    { title: 'Топ' },
                    { title: colNames[ tab.name ], width: { wpx: 150 } },
                    { title: 'Доля' },
                    { title: 'Цель' },
                    { title: 'Разница' },
                ],
                data: tab.data.content.map(( item => ([
                    { value: item.top ? 'Да' : 'Нет' },
                    { value: item.name, style: { alignment: { wrapText: true } } },
                    { value: getFormattedValue( item.factValue, 2 ) },
                    { value: getFormattedValue( item.planValue, 2 ) },
                    { value: getFormattedValue( item.deltaValue, 2 ), style: { font: { color: { rgb: item.deltaValue < 0 ? red : green } } } },
                ])
                ))
            } ]
        }
    }

    showExcelTooltip = ( tab ) => {
        let fieldsNull = []
        let fieldsZero = []
        let fieldsNullOrZero = []

        if ( !tab?.data?.content ) { return true }

        switch ( tab.name ) {
            case 'cashiers': {
                if ( tab?.data?.content?.length === 0 ) { return true }

                fieldsNullOrZero = [
                    'avgNet',
                    'sumNet',
                    ...tab.columns.filter( item => item.dataIndex.includes( 'Delta' ) || item.dataIndex.includes( 'Fact' ))
                        .map( item => item.dataIndex )
                ]
                break
            }
            case 'categories': {
                fieldsNull = [ 'factValue', 'deltaValue', 'planValue' ]
                fieldsZero = []
                break
            }
            case 'dishes':
            case 'modifiers': {
                fieldsNull = [ 'factValue' ]
                fieldsZero = [ 'planValue' ]
                fieldsNullOrZero = [ 'deltaValue' ]
                break
            }
        }

        const allContainsNull = fieldsNull.length > 0 && tab?.data?.content?.every( item => {
            return Object.entries( item ).filter(
                ([ key ]) => fieldsNull.find( k => k === key )
            ).every(([ _, value ]) => value === null )
        })

        const allContainsZero = fieldsZero.length > 0 && tab?.data?.content?.every( item => {
            return Object.entries( item ).filter(
                ([ key ]) => fieldsZero.find( k => k === key )
            ).every(([ _, value ]) => value === 0 )
        })

        const allContainsNullOrZero = fieldsNullOrZero.length > 0 && tab?.data?.content?.every( item => {
            return Object.entries( item ).filter(
                ([ key ]) => fieldsNullOrZero.find( k => k === key )
            ).every(([ _, value ]) => value === 0 || value === null )
        })

        return allContainsNull && allContainsZero && allContainsNullOrZero
    }

    render () {
        const { request, workHours } = this.props,
              { view, dishes, dict, hideCombo, tableWidth, emptySearch, modifiers } =
        this.state,
              { categories } = this.state,
              { cashiers, cashColumns, cashdish } = this.state,
              placeholder = this.placeholder( view ),
              backTitle = 'Рейтинги',
              tabs = [
                  {
                      name:      'categories',
                      title:     'Категории',
                      data:      categories,
                      columns:   config.tables.categories,
                      rowKey:    'categoryId',
                      searchKey: 'name',
                      emptyText: (
                          <div className="rating-empty-search">Ничего не найдено</div>
                      ),

                      filter:      true,
                      placeholder: placeholder,
                      cellActions: { toggle: this.toggleTop( 'category' ), },

                      onMore:       this.more.categories,
                      onCellChange: this.change( 'categories', 'categoryId' ),
                      onCellClick:  this.deep,
                      prepare:      this.prepareDishes,
                  },
                  {
                      name:      'dishes',
                      title:     'Блюда',
                      data:      dishes,
                      columns:   dict ? config.tables.dishlist : config.tables.dishes,
                      rowKey:    'productUid',
                      searchKey: 'name',

                      filter:      true,
                      placeholder: placeholder,
                      emptyText:   dishes ? (
                          dict ? (
                              <div className="rating-empty-search">Нет такого блюда</div>
                          ) : (
                              <div className="rating-empty-search">
                                  <p>
                  Такого блюда в рейтинге за выбранный период нет. Попробуйте
                  поискать в{' '}
                                      <Link onClick={this.toggleDictionary}>полном списке</Link>.
                                  </p>
                              </div>
                          )
                      ) : (
                          '...'
                      ),

                      cellActions: { toggle: this.toggleTop( 'dish' ), },

                      onMore:       this.more.dishes,
                      onCellChange: this.change( 'dishes', 'productUid' ),
                      onSearch:     this.setEmpty,
                      prepare:      this.prepareDishes,
                      children:     dishes ? (
                          <React.Fragment>
                              <Checkbox checked={hideCombo} onChange={this.toggleCombo}>
                Скрыть купоны и комбо-блюда
                              </Checkbox>
                              {!emptySearch && (
                                  <div className="rating-aside" style={{ left: tableWidth }}>
                                      <p>
                    В рейтинге участвуют блюда, реализованные за выбранный
                    период.
                                      </p>
                                      <Link onClick={this.toggleDictionary}>
                                          {dict
                                              ? 'Показать только актуальные'
                                              : 'Показать полный список'}
                                      </Link>
                                  </div>
                              )}
                          </React.Fragment>
                      ) : null,
                  },
                  {
                      name:      'modifiers',
                      title:     'Допы',
                      data:      modifiers,
                      columns:   config.tables.modifiers,
                      rowKey:    'productUid',
                      searchKey: 'name',

                      filter:      true,
                      placeholder: placeholder,
                      emptyText:   (
                          <div className="rating-empty-search">Нет такого блюда</div>
                      ),

                      cellActions: { toggle: this.toggleTop( 'modifier' ), },

                      onMore:       this.more.modifiers,
                      onCellChange: this.change( 'modifiers', 'productUid' ),
                      onSearch:     this.setEmpty,
                      prepare:      this.prepareDishes,
                  },
                  /*{
                        title: 'Рейтинг менеджеров',
                        data: this.props.rating.managers,
                        columns: config.tables.managers,
                        rowKey: 'name',
                        searchKey: 'name',

                        filter: true,
                        placeholder: 'Введите фамилию сотрудника',

                        onMore: this.more.managers,
                        prepare: this.preparePersons
                    },*/
                  {
                      name:     'cashiers',
                      title:    'Кассиры',
                      data:     cashiers,
                      columns:  cashColumns,
                      rowKey:   'employeeId',
                      unit:     'currency',
                      children: (
                          <Checkbox checked={cashdish} onChange={this.toggleCashDish}>
              Показать по блюдам
                          </Checkbox>
                      ),

                      onMore:  this.more.cashiers,
                      prepare: this.preparePersons,
                  },
              ]

        const fileExcelNames = {
            categories: 'Категории',
            dishes:     'Блюда',
            modifiers:  'Модификаторы',
            cashiers:   'Кассиры'
        }

        return (
            <section className={'kfc-report kfc-rating-report kfc-view-' + view}>
                <Layout>
                    <Header>
                        <AppHeader bothTimes timeData={parseInt( request.restaurantId )} />
                    </Header>
                    <Content>
                        <InnerControls
                            hideDetalization
                            actions={format.extract.actions( this.props )}
                            request={request}
                            workHours={workHours}
                            update={this.load}
                            onChange={this.set}
                        />
                        <InnerContent fixed={this.isEmpty( view )}>
                            <BackTitle
                                text={backTitle}
                            />
                            <TabbedReport
                                {...format.generate.report( tabs )}
                                tabs={tabs.map( tab => {
                                    const disabledExcel = this.showExcelTooltip( tab )
                                    return <CustomTab
                                        key={tab.name}
                                        title={tab.title}
                                        icon={
                                            disabledExcel ?
                                                    <ReportExcelTooltip />
                                                :
                                                    <ExcelFile
                                                        filename={`Рейтинг_${fileExcelNames[ tab.name ]}_${moment().format( 'YYYY-MM-DDTHH:mm:ss' )}`}
                                                        element={
                                                            <Button
                                                                type='link'
                                                                className='button-download'
                                                            >
                                                                <Icon component={Icons.Download.def} />
                                                            </Button>
                                                        }
                                                    >
                                                        <ExcelSheet dataSet={this.prepareExcel( tab )} name={`Рейтинг ${tab.title}`}/>
                                                    </ExcelFile>
                                        }
                                    />
                                })}
                                onChange={this.updateDishView}
                                defaultTab={view}
                            />
                        </InnerContent>
                    </Content>
                </Layout>
            </section>
        )
    }
}

export default connect( mapStateToProps, allActions )( RatingReport )
