/* VENDOR */
import React, { Component }        from 'react'
import PropTypes                   from 'prop-types'
import { Row, Col, Input, Select } from 'antd'

/* APPLICATION */
import { ReportsControls } from 'components'
import { format }          from 'tools'
import './admin-tasks-controls.scss'

const { Search } = Input,
      { Option } = Select

class AdminTasksControls extends Component {
    static propTypes = {
        search:     PropTypes.string,
        restaurant: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
        group:      PropTypes.string,
        status:     PropTypes.string,
        dates:      PropTypes.array,

        restaurants: PropTypes.array,
        groups:      PropTypes.array,
        statuses:    PropTypes.object,

        onListRestaurants: PropTypes.func,
        onListGroups:      PropTypes.func,

        onSearch:     PropTypes.func,
        onRestaurant: PropTypes.func,
        onGroup:      PropTypes.func,
        onStatus:     PropTypes.func,
        onDates:      PropTypes.func,
    }

    option = ( opt ) => (
        <Option value={opt.value} key={opt.value}>
            {opt.label}
        </Option>
    )

    options = ( data, keys, vals, allLabel ) =>
        ( data
            ? vals
                ? [
                        { value: 'all', label: allLabel },
                        ...format.generate.options( data, keys, vals ),
                    ]
                : [ { value: 'all', label: keys }, ...format.generate.keyvalrs( data ) ]
            : [ { value: 'all', label: allLabel } ]
        ).map( this.option )

    search = ( e ) => this.props.onSearch( e.target.value )

    searchRestaurants = ( str ) => this.props.onListRestaurants( str )
    searchGroups = ( str ) => this.props.onListGroups( str )

    flushRestaurants = () => this.props.onListRestaurants( '' )
    flushGroups = () => this.props.onListGroups( '' )

    render () {
        const { restaurants, groups, statuses } = this.props,
              { search, restaurant, group, status, dates } = this.props,
              { onRestaurant, onGroup, onStatus, onDates } = this.props

        return (
            <div className="admin-tasks-controls view-type-switch">
                <Row>
                    <Col span={5}>
                        <div className="filter-container">
                            <Search
                                placeholder="Задача, имя или ID сотрудника"
                                value={search}
                                onChange={this.search}
                            />
                        </div>
                    </Col>
                    <Col span={4}>
                        <Select
                            showSearch
                            onSearch={this.searchRestaurants}
                            onFocus={this.flushRestaurants}
                            onChange={onRestaurant}
                            value={restaurant}
                            byKey={true}
                            placeholder="Ресторан"
                            notFoundContent="Ничего не найдено"
                            filterOption={() => true}
                            getPopupContainer={( trigger ) => trigger.parentNode}
                        >
                            {this.options(
                                restaurants,
                                'factsNumber',
                                'restaurantName',
                                'Все рестораны'
                            )}
                        </Select>
                    </Col>
                    <Col span={4}>
                        <Select
                            showSearch
                            onSearch={this.searchGroups}
                            onFocus={this.flushGroups}
                            onChange={onGroup}
                            value={group}
                            byKey={true}
                            placeholder="Группа"
                            notFoundContent="Ничего не найдено"
                            filterOption={() => true}
                            getPopupContainer={( trigger ) => trigger.parentNode}
                        >
                            {this.options( groups, 'groupName', 'categoryName', 'Все группы' )}
                        </Select>
                    </Col>
                    <Col span={4}>
                        <Select
                            showSearch
                            onChange={onStatus}
                            value={status}
                            byKey={true}
                            placeholder="Статус"
                            notFoundContent="Ничего не найдено"
                            filterOption={( input, option ) =>
                                option.key === 'all' ||
                option.props.children
                    .toLowerCase()
                    .indexOf( input.toLowerCase()) >= 0
                            }
                            getPopupContainer={( trigger ) => trigger.parentNode}
                        >
                            {this.options( statuses, 'Все статусы' )}
                        </Select>
                    </Col>
                    <Col span={7}>
                        <ReportsControls type="range" date={dates} onChange={onDates} />
                    </Col>
                </Row>
            </div>
        )
    }
}

export default AdminTasksControls
