export const appendClass = ( record, key, value ) => {
    record.className = record.className || {}
    record.className[ key ] = value
    return record
}

export const bad = ( record, key ) => appendClass( record, key, 'cell-bad' )
export const good = ( record, key ) => appendClass( record, key, 'cell-good' )

export const test = ( record, key, val ) =>
    val ? good( record, key ) : bad( record, key )
export const isBad = ( record, key, val ) => ( val ? bad( record, key ) : record )
export const isGood = ( record, key, val ) => ( val ? good( record, key ) : record )

export const addClass = ( props, cls ) => {
    props.className = props.className || ''
    props.className += ' ' + cls

    return props
}

export const className = ( record, key, props ) => {
    props = props || {}

    if ( record.className && record.className[ key ]) {
        return addClass( props, record.className[ key ])
    }

    return props
}
