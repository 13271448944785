/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Modal, Progress }  from 'antd'

/* APPLICATION */
import './save-progress.scss'

let _timer = null

const interval = 1000

class SaveProgress extends Component {
    static propTypes = {
        active: PropTypes.bool,
        count:  PropTypes.number,

        onDone: PropTypes.func,
    }

    constructor ( props ) {
        super( props )

        this.state = {
            step:    0,
            percent: 0,
        }
    }

    componentDidMount () {
        this.update( this.props.active )
    }

    componentDidUpdate ( prevProps ) {
        const { active, canClose, count } = this.props,
              { step } = this.state

        prevProps.active !== active && this.update( active )
        prevProps.canClose !== canClose &&
      canClose &&
      step + 1 > count + 1 &&
      this.onDone()
    }

    update = ( status ) => ( status ? this.start() : this.stop())

    step = () => {
        const { count, canClose } = this.props,
              { step } = this.state,
              percent = ( step / count ) * 100,
              nextStep = step + 1

        this.setState({
            percent,
            step: nextStep,
        })

        if ( nextStep > count + 1 ) {
            this.stop()
            canClose && this.onDone()
        }
    }

    start = () => {
        _timer = setInterval( this.step, interval )
        this.setState({
            percent: 0,
            step:    0,
        })
    }
    stop = () => clearInterval( _timer )

    onDone = () => {
        setTimeout(
            () =>
                this.setState({
                    step:    0,
                    percent: 0,
                }),
            interval + interval / 2
        )

        this.props.onDone()
    }

    render () {
        const { active } = this.props,
              { percent } = this.state

        return (
            <Modal
                title="Обновление плана"
                className="kfc-popup kfc-save-progress"
                closable={false}
                centered={true}
                open={active}
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <Progress percent={percent} strokeColor="#8FC2FF" showInfo={false} />
            </Modal>
        )
    }
}

export default SaveProgress
