/* VENDOR */
import { update } from 'reduxigen'

/* APPLICATION */
import * as api   from './api'
import { format } from 'tools'

const asyncUpdate = format.update.asyncu,
      helper = format.helper.basic( 'time' )

/* BASIC */

export const flushServiceTime = update( 'time.flush', ( value, state ) => ({
    ...state,
    time: {},
}))

/* REPORTS */

export const fetchServiceTime = asyncUpdate(
    'time.report',
    ( params ) => api.getServiceTime( params ),
    ( event, state ) =>
        event.data.data && event.data.plan
            ? helper( 'report', fixPlan( event.data ), state )
            : helper( 'report', { data: [], plan: null }, state )
)

const fixPlan = ( data ) => {
    //Не спрашивай меня, зачем такой костыль. Скажи спасибо бекенду
    data.plan.handlingKiosk = data.plan.totalKiosk
    return data
}

/* REPORTS */

export const fetchServiceTimeSummary = asyncUpdate(
    'time.summary',
    ( params ) => api.getServiceTimeSummary( params ),
    ( event, state ) => helper( 'summary', event.data, state )
)
