/* VENDOR */
import React, { Component } from 'react'

/* APPLICATION */
import { LoginCard } from 'components'

class Login extends Component {
    render () {
        return <LoginCard />

        //Old
    /*return (
            <section className="kfc-login">
                <Layout>
                    <Header>
                        <Logo full />
                    </Header>
                    <Layout>
                        <Content>
                            <Center>
                                <LoginCard />
                            </Center>
                        </Content>
                    </Layout>
                    <Footer>
                        <div className="copyright">{config.copyright}</div>
                    </Footer>
                </Layout>
            </section>
        )*/
    }
}

export default Login
