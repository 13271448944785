import moment from 'moment'

import column from './helpers'

export default {
    origins: [
        column( 'Ресторан', 'restaurantName', { width: 280, }),
        column( 'Тип', 'facilityTypeName', { width: 140, }),
        column( 'Начало', 'weekdaysOpeningHour', {
            width:  128,
            render: ( time, row ) =>
                row.noctidial
                    ? 'Круглосуточно'
                    : time
                        ? moment( time, 'HH:mm:ss' ).format( 'HH:mm' )
                        : 'Не указано',
        }),
        column( 'Завершение', 'weekdaysClosingHour', {
            width:  128,
            render: ( time, row ) =>
                row.noctidial
                    ? ''
                    : time
                        ? moment( time, 'HH:mm:ss' ).format( 'HH:mm' )
                        : 'Не указано',
        }),
        column( '', '', { width: '100%', }),
    ],
}
