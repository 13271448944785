import moment from 'moment'

import { format } from 'tools'
import * as check from './check'

export const groups = ( search, selected, date, view ) => ( group ) => ({
    ...group,
    items: group.items
        .filter( groupEvent( selected, date, view ))
        .filter( searchEvent( search )),
})

export const searchEvent = ( search ) => ( item ) =>
    item.events[ 0 ] && format.find.strInProp( item.events[ 0 ], 'title', search )

export const groupEvent = ( selected, date, view ) => ( item ) => {
    if ( selected ) { return true }

    item.events = item.events.filter( itemEvent( date, view ))

    return item.events.length > 0
}

export const itemEvent = ( date, view ) => ( ev ) => {
    if ( ev.groupName.indexOf( 'Year' ) > -1 || ev.groupName.indexOf( 'Quarterly' ) > -1 ) {
        return true
    }

    return checkEvent( ev, date, view )
}

export const checkEvent = ( ev, date, view ) => {
    const deadline = moment( ev.deadline ),
          show = shouldBeVisible( ev, deadline, date, view ),
          isActual = moment( date ).startOf( 'month' ).isBefore( deadline )

    return moment( deadline ).endOf( 'day' ).isAfter( moment()) && show && isActual
}

export const shouldBeVisible = ( event, deadline, date, view ) =>
    view === 'month'
        ? check.month( deadline, date, event.groupName.indexOf( 'Weekly' ) > -1 )
        : check.moreThanWeekly( event, view, date ) || check.week( deadline, date )
