/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'

/* APPLICATION */
import { RestaurantTitle, PageTitle, LocalTime } from 'components'

import './app-header.scss'

class AppHeader extends Component {
    static propTypes = {
        timeData: PropTypes.number,
        ready:    PropTypes.bool,

        hideTime:            PropTypes.bool,
        hideRestaurants:     PropTypes.bool,
        bothTimes:           PropTypes.bool,
        allowAllRestaurants: PropTypes.bool,
        timeZoneName       : PropTypes.string
    }

    render () {
        const { hideTime, hideRestaurants, allowAllRestaurants, timeData, ready, bothTimes } =
      this.props

        return (
            <div className="app-header">
                <PageTitle />
                {( !hideTime || timeData ) && <LocalTime bothTimes={bothTimes} ready={ready} rid={timeData} />}
                {!hideRestaurants && (
                    <RestaurantTitle timeZoneName={this.props.timeZoneName} allowAllRestaurants={allowAllRestaurants} />
                )}
            </div>
        )
    }
}

export default AppHeader
