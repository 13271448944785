/* eslint-disable camelcase */
/* VENDOR */
import {update} from 'reduxigen'

/* APPLICATION */
import * as api from './api'
import {format} from 'tools'

const asyncUpdate = format.update.asyncu,
      helper = format.helper.basic( 'staff' ),
      listHelper = format.helper.update( 'staff.list', 'id' ),
      oppHelper = format.helper.update(
          'staff.employee.opportunities',
          'opportunityId'
      ),
      posHelper = format.helper.update( 'staff.employee.positions', 'guid' ),
      fixHelper = format.helper.update( 'staff.fixed', 'fixedShiftId' ),
      absHelper = format.helper.update( 'staff.absence', 'absenceId' ),
      fixedHelper = format.helper.dupdate( 'staff.fixed', 'fixedShiftId' ),
      absenceHelper = format.helper.dupdate( 'staff.absence', 'absenceId' ),
      uhelper = format.helper.basic( 'users' )

export const flushStaff = update( 'staff.flush', ( value, state ) => ({
    ...state,
    staff: {},
}))

/* LIST */

export const fetchStaff = asyncUpdate(
    'staff.list',
    ( params ) => api.getStaffList( params ),
    ( event, state ) => helper( 'list', event.data?.employees, state )
)

export const fetchAssignStaff = asyncUpdate(
    'staff.assignees',
    ( rid ) => api.getAssignList( rid ),
    ( event, state ) => helper( 'assignees', event.data, state )
)

export const fetchExecutorStaff = asyncUpdate(
    'staff.executors',
    ( rid ) => api.getExecutorList( rid ),
    ( event, state ) => helper( 'executors', event.data, state )
)

export const flushList = update( 'staff.flush.list', ( value, state ) => ({
    ...state,
    staff: {
        ...state.staff,
        list: null,
    },
}))

/* COMPANIES */

export const fetchCompanies = asyncUpdate(
    'staff.companies',
    () => api.getCompanies(),
    ( event, state ) => helper( 'companies', event.data, state )
)

/* EMPLOYEE */

export const getRates = asyncUpdate(
    'staff.get.rates',
    ( params ) => api.getRates( params ),
    ( event, state ) => {
        return {
            ...state,
            staff: {
                ...state.staff,
                rates: event.data,
            },
        }
    }
)

export const newEmployee = update( 'staff.newEmployee', ( value, state ) => ({
    ...state,
    staff: {
        ...state.staff,
        employee: value,
    },
}))

export const flushEmployee = update( 'staff.flush.employee', ( value, state ) => ({
    ...state,
    staff: {
        ...state.staff,
        employee: null,
    },
}))

export const fetchEmployee = asyncUpdate(
    'staff.employee',
    ( params ) => api.getEmployee( params ),
    ( event, state ) => {
        if ( event.data?.main ) {
            event.data.main.outstaff &&
        ( event.data.main.jobRole = event.data.main.jobRole || 'Outstaff' )
        }

        return helper('employee', event.data, state)
    }
)

export const addEmployee = asyncUpdate(
    'staff.add',
    ( params ) => api.addEmployee( params ),
    ( event, state ) => {
        const status = event.status,
              update = JSON.parse( event.config.data ),
              original = state.staff.employee ? state.staff.employee.main : {},
              data = { ...original, ...update },
              employee = state.staff.employee
                  ? {
                          ...state.staff.employee,
                          main: data,
                      }
                  : { main: data }

        if ( status !== 200 ) {
            return state
        }

        return {
            ...state,
            staff: {
                ...state.staff,
                employee,
            },
        }
    }
)

export const saveEmployee = asyncUpdate(
    'staff.save',
    ( params ) => api.saveEmployee( params ),
    ( event, state ) => {
        const status = event.status,
              update = JSON.parse( event.config.data ),
              original = state.staff.employee.main,
              data = { ...original, ...update }

        if ( status !== 200 ) {
            return state
        }

        return {
            ...state,
            staff: {
                ...state.staff,
                employee: {
                    ...state.staff.employee,
                    main: {
                        ...state.staff.employee.main,
                        ...data,
                        gender: data.gender
                            ? data.gender.toUpperCase()
                            : state.staff.employee.main.gender,
                    },
                },
            },
        }
    }
)

export const removeEmployee = asyncUpdate(
    'staff.remove',
    ( id ) => api.removeEmployee( id ),
    ( event, state ) =>
        listHelper( event, state, ( cfg ) => cfg.arr.splice( cfg.index, 1 ))
)

/* RATING */

export const flushEmployeeRating = update(
    'staff.flush.rating',
    ( value, state ) => ({
        ...state,
        staff: {
            ...state.staff,
            rating: {},
        },
    })
)

export const fetchEmployeeRating = asyncUpdate(
    'staff.employee.rating',
    ( id ) => api.getRating( id ),
    ( event, state ) => ({
        ...state,
        staff: {
            ...state.staff,
            rating: {
                ...state.staff.rating,
                data: event.data,
            },
        },
    })
)

export const fetchEmployeeRatingList = asyncUpdate(
    'staff.employee.rating.list',
    ( params ) => api.getRatingList( params ),
    ( event, state ) => ({
        ...state,
        staff: {
            ...state.staff,
            rating: {
                ...state.staff.rating,
                list: event.data,
            },
        },
    })
)

export const appendEmployeeRatingList = asyncUpdate(
    'staff.employee.rating.append',
    ( params ) => api.getRatingList( params ),
    ( event, state ) => ({
        ...state,
        staff: {
            ...state.staff,
            rating: {
                ...state.staff.rating,
                list: {
                    ...event.data,
                    content: [ ...state.staff.rating.list.content, ...( event.data?.content || []) ],
                },
            },
        },
    })
)

/* OPPORTUNITIES */

export const updateOpportunity = asyncUpdate(
    'staff.updateOppotunity',
    ( opp ) => api.setOpportunity( format.generate.dowRequest( opp )),
    ( event, state ) =>
        oppHelper( event, state, ( cfg ) => ( cfg.arr[ cfg.index ] = event.data ))
)

export const removeOpportunity = asyncUpdate(
    'staff.removeOpportunity',
    ( req ) => api.removeOpportunity( req ),
    ( event, state ) =>
        oppHelper( event, state, ( cfg ) => cfg.arr.splice( cfg.index, 1 ))
)

/* POSITIONS */

export const updatePosition = asyncUpdate(
    'staff.updatePosition',
    ( opp ) => api.setPosition( opp ),
    ( event, state ) =>
        posHelper( event, state, ( cfg ) => ( cfg.arr[ cfg.index ] = cfg.data ))
)

export const removePosition = asyncUpdate(
    'staff.removePosition',
    ( req ) => api.removePosition( req ),
    ( event, state ) =>
        posHelper( event, state, ( cfg ) => cfg.arr.splice( cfg.index, 1 ))
)

/* FIXED SHIFTS */

export const flushFixedShifts = update(
    'staff.fixed.flush',
    ( _, state ) => helper( 'fixed', undefined, state )
)

export const fetchFixedShifts = asyncUpdate(
    'staff.fixed',
    ( params ) => api.getFixedShifts( params ),
    ( event, state ) => helper( 'fixed', event.data?.days, state )
)

export const updateFixedShift = asyncUpdate(
    'staff.updateFixed',
    ( shift ) => api.setFixedShift( format.generate.dowRequest( shift )),
    ( event, state ) => {
        const data = JSON.parse( event.config.data )

        return event.status === 200
            ? fixedHelper( event.data, state, data.fixedShiftId )
            : fixedHelper( data, state, data.fixedShiftId )
    }
)

export const removeFixedShift = asyncUpdate(
    'staff.removeFixed',
    ( req ) => api.removeFixedShift( req ),
    ( event, state ) =>
        fixHelper( event, state, ( cfg ) => cfg.arr.splice( cfg.index, 1 ))
)

/* ABSENCE */

export const fetchAbsence = asyncUpdate(
    'staff.absence',
    ( params ) => api.getAbsence( params ),
    ( event, state ) => helper( 'absence', event.data?.days, state )
)

export const updateAbsence = asyncUpdate(
    'staff.updateAbsence',
    ( item ) => api.setAbsence( format.generate.dowRequest( item )),
    ( event, state ) => {
        const data = JSON.parse( event.config.data )

        return event.status === 200
            ? absenceHelper( event.data, state, data.absenceId )
            : absenceHelper( data, state, data.absenceId )
    }
)

export const removeAbsence = asyncUpdate(
    'staff.removeAbsence',
    ( req ) => api.removeAbsence( req ),
    ( event, state ) =>
        absHelper( event, state, ( cfg ) => cfg.arr.splice( cfg.index, 1 ))
)

/* ADMIN */

export const flushAdminUsers = update(
    'staff.admin.flush.users',
    ( value, state ) => ({
        ...state,
        users: {
            ...state.users,
            list: null,
        },
    })
)

export const flushRestaurantStaff = update(
    'staff.admin.flush.staff',
    ( value, state ) => ({
        ...state,
        staff: {
            ...state.staff,
            search: null,
        },
    })
)

export const flushLinkedUsers = update(
    'staff.admin.linked.flush',
    ( value, state ) => ({
        ...state,
        staff: {
            ...state.staff,
            linked: null,
        },
    })
)

export const fetchAdminUsers = asyncUpdate(
    'staff.admin.users',
    ( params ) => api.getAdminUsers( params ),
    ( event, state ) => uhelper( 'list', event.data, state )
)

export const updateAdminUser = asyncUpdate(
    'staff.admin.update',
    ( body ) => api.updateAdminUser( body ),
    ( event, state ) => {
        const user = event.data,
              nstate = format.copy.object( state ),
              users = format.copy.object( nstate.users ),
              list = format.copy.object( users.list ),
              content = format.copy.array( list.content ),
              index = content.indexOf(
                  content.find(( r ) => r.userUuid === user.userUuid )
              )

        if ( index > -1 ) {
            content[ index ] = user
            list.content = content
            users.list = list
            nstate.users = users
        }

        return nstate
    }
)

export const fetchRestaurantStaff = asyncUpdate(
    'staff.admin.restaurant',
    ( params ) => api.getRestaurantStaff( params ),
    ( event, state ) => helper( 'search', event.data, state )
)

export const fetchCanBeLinked = asyncUpdate(
    'staff.admin.linked',
    ( search ) => api.getCanBeLinked({ search }),
    ( event, state ) => helper( 'linked', event.data, state )
)

export const setCanBeLinked = update( 'staff.admin.linked.set', ( data, state ) =>
    helper( 'linked', data, state )
)

/* TRANSFER */

export const transferEmployee = asyncUpdate(
    'staff.transfer.employee',
    ( req ) => api.transferEmployee( req ),
    ( event, state ) => helper( 'transfer', event.config, state )
)

export const cancelTransferEmployee = asyncUpdate(
    'staff.transfer.employee.cancel',
    ( id ) => api.cancelTransferEmployee( id ),
    ( _, state ) => helper( 'transfer', false, state )
)

/* PPR DIRECTOR */

export const flushPprDirectorData = update(
    'staff.director.flush',
    ( value, state ) => ({
        ...state,
        staff: {
            ...state.staff,
            director: {}
        }
    })
)

export const flushPprDirectorItem = update(
    'staff.director.flush.item',
    ( value, state ) => ({
        ...state,
        staff: {
            ...state.staff,
            director: {
                ...state.staff.director,
                [ value ]: null
            }
        }
    })
)

export const fetchPprHr = asyncUpdate(
    'staff.director.hr',
    params => api.getPprHr( params ),
    ( event, state ) => helper( 'director', {
        ...state.staff.director,
        hr:       event.data,
        hr_error: format.update.getPprError( event )
    }, state )
)

export const fetchPprManagersStructure = asyncUpdate(
    'staff.director.managers_structure',
    ( params ) => api.getPprManagersStructure( params ),
    ( event, state ) => helper( 'director', {
        ...state.staff.director,
        managersStructure:        event.data,
        managers_structure_error: format.update.getPprError( event )
    }, state )
)

export const fetchPprRestaurant = asyncUpdate(
    'staff.director.restaurant',
    ( params ) => api.getPprRestaurant( params ),
    ( event, state ) => helper( 'director', {
        ...state.staff.director,
        restaurant:       event.data,
        restaurant_error: format.update.getPprError( event )
    }, state )
)

export const fetchPprEmployeesCount = asyncUpdate(
    'staff.director.employees_count',
    ( params ) => api.getPprEmployeesCount( params ),
    ( event, state ) => helper( 'director', {
        ...state.staff.director,
        employeesCount:        event.data,
        employees_count_error: format.update.getPprError( event )
    }, state )
)

export const fetchPprData = asyncUpdate(
    'staff.director.data',
    params => api.getPprData( params ),
    ( event, state ) => helper( 'director', {
        ...state.staff.director,
        data:       event.data,
        data_error: format.update.getPprError( event )
    }, state )
)

export const fetchPprManagersBench = asyncUpdate(
    'staff.director.managers_bench',
    ( params ) => api.getPprManagersBench( params ),
    ( event, state ) => helper( 'director', {
        ...state.staff.director,
        managersBench:        event.data,
        managers_bench_error: format.update.getPprError( event )
    }, state )
)

export const fetchPprEmployees = asyncUpdate(
    'staff.director.employees',
    ( params ) => api.getPprEmployees( params ),
    ( event, state ) => helper( 'director', {
        ...state.staff.director,
        employees:       event.data,
        employees_error: format.update.getPprError( event )
    }, state )
)

export const fetchPprManagers = asyncUpdate(
    'staff.director.managers',
    ( params ) => api.getPprManagers( params ),
    ( event, state ) => helper( 'director', {
        ...state.staff.director,
        managers:       event.data,
        managers_error: format.update.getPprError( event )
    }, state )
)

/* PPR */

export const fetchStaffPprPositions = asyncUpdate(
    'staff.ppr.positions',
    ( params ) => api.getStaffPprPositions( params ),
    ( event, state ) => ({
        ...state,
        staff: {
            ...state.staff,
            ppr: {
                ...state.staff.ppr,
                positions: event.data
            }
        }
    })
)

export const fetchStaffPprRcl = asyncUpdate(
    'staff.ppr.rcl',
    ( params ) => api.getStaffPprRcl( params ),
    ( event, state ) => ({
        ...state,
        staff: {
            ...state.staff,
            ppr: {
                ...state.staff.ppr,
                rcl: event.data
            }
        }
    })
)

export const fetchStaffPprPositionStatuses = asyncUpdate(
    'staff.ppr.statuses',
    ( params ) => api.getStaffPprPositionStatuses( params ),
    ( event, state ) => ({
        ...state,
        staff: {
            ...state.staff,
            ppr: {
                ...state.staff.ppr,
                statuses: event.data
            }
        }
    })
)

export const fetchStaffPprAvailablePeriods = asyncUpdate(
    'staff.ppr.periods',
    ( params ) => api.getPprAvailablePeriods( params ),
    ( event, state ) => ({
        ...state,
        staff: {
            ...state.staff,
            ppr: {
                ...state.staff.ppr,
                periods:       event.data,
                periods_error: format.update.getPprError( event )
            }
        }
    })
)

export const flushEmployeePpr = update( 'staff.flush.ppr', ( value, state ) => ({
    ...state,
    staff: {
        ...state.staff,
        ppr: { periods: state.staff.ppr.periods }
    },
}))

export const fetchEmployeePpr = asyncUpdate(
    'staff.ppr.get',
    ( params ) => api.getEmployeePpr( params ),
    ( event, state ) => ({
        ...state,
        staff: {
            ...state.staff,
            ppr: {
                ...state.staff.ppr,
                data: event.data
            }
        }
    })
)

export const addEmployeePpr = asyncUpdate(
    'staff.ppr.create',
    ( params ) => api.createEmployeePpr( params ),
    ( event, state ) => ({
        ...state,
        staff: {
            ...state.staff,
            ppr: {
                ...state.staff.ppr,
                data: event.data
            }
        }
    })
)

export const updateEmployeePpr = asyncUpdate(
    'staff.ppr.update',
    ( params ) => api.updateEmployeePpr( params ),
    ( event, state ) => ({
        ...state,
        staff: {
            ...state.staff,
            ppr: {
                ...state.staff.ppr,
                data: event.data
            }
        }
    })
)

/* PPR AREA */

export const fetchAreaPprUsers = asyncUpdate(
    'staff.area.users',
    ( params, getParams ) => api.getAreaPprUsers( params, getParams ),
    ( event, state ) => ({
        ...state,
        staff: {
            ...state.staff,
            areaUsers: event.data
        }
    })
)

export const flushAreaPprUsers = update(
    'staff.area.users.flush',
    ( event, state ) => ({
        ...state,
        staff: {
            ...state.staff,
            areaUsers: undefined
        }
    })
)

export const fetchPprAcStatuses = asyncUpdate(
    'staff.area.acStatuses',
    () => api.getPprAcStatuses(),
    ( event, state ) => ({
        ...state,
        staff: {
            ...state.staff,
            acStatuses:       event.data,
            acStatuses_error: format.update.getPprError( event )
        }
    })
)

export const fetchPprArea = asyncUpdate(
    'staff.area.data',
    ( params ) => api.getPprArea( params ),
    ( event, state ) => ({
        ...state,
        staff: {
            ...state.staff,
            areaData:       event.data,
            areaData_error: format.update.getPprError( event )
        }
    })
)

export const fetchAreaCard = asyncUpdate(
    'staff.area.card',
    ( params ) => api.getAreaCard( params ),
    ( event, state ) => ({
        ...state,
        staff: {
            ...state.staff,
            areaCard:       event.data,
            areaCard_error: format.update.getPprError( event )
        }
    })
)

export const createAreaCard = asyncUpdate(
    'staff.area.card.create',
    ( params ) => api.createAreaCard( params ),
    ( event, state ) => ({
        ...state,
        staff: {
            ...state.staff,
            areaCard:       event.data,
            areaCard_error: format.update.getPprError( event )
        }
    })
)

export const updateAreaCard = asyncUpdate(
    'staff.area.card.update',
    ( params ) => api.updateAreaCard( params ),
    ( event, state ) => ({
        ...state,
        staff: {
            ...state.staff,
            areaCard:       event.data,
            areaCard_error: format.update.getPprError( event )
        }
    })
)

export const updateAcComment = update(
    'staff.acComment.update',
    ( params, state ) => {
        const managerCallback = ( manager ) => {
            if ( manager.employeeUuid === params.employeeUuid ) {
                return {
                    ...manager,
                    acComment: params.acComment
                }
            }
            return manager
        }

        const restaurantCallback = ( res ) => {
            if ( res.factsNumber === params.factsNumber ) {
                return {
                    ...res,
                    managers: res.managers.map( managerCallback )
                }
            }
            return res
        }

        return {
            ...state,
            staff: {
                ...state.staff,
                areaData: {
                    ...state.staff.areaData,
                    restaurants: state.staff.areaData?.restaurants?.map( restaurantCallback )
                }
            }
        }
    }
)
