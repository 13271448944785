/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Tag }              from 'antd'

/* APPLICATION */
import './time-tag.scss'

class Relation extends Component {
    static propTypes = { text: PropTypes.string, }

    render () {
        return <Tag className="time-tag">{this.props.text}</Tag>
    }
}

export default Relation
