/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import moment               from 'moment'

import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'

import { Modal, Row, Col, Spin } from 'antd'

/* APPLICATION */
import { FormField } from 'components'
import { format }    from 'tools'
import config        from 'config'

class EditIssue extends Component {
    static propTypes = {
        data: PropTypes.object,
        user: PropTypes.object,

        assignees: PropTypes.object,
        executors: PropTypes.object,

        onSubmit: PropTypes.func,
        onCancel: PropTypes.func,

        visible: PropTypes.bool,
    }

    componentDidUpdate ( prevProps ) {
        if ( prevProps.visible !== this.props.visible && this.props.visible === false ) {
            this.props.form.resetFields()
        }
    }

    checkDate = (field, value) => {
        this.props.form.setFieldsValue({ [ field ]: value})
    }

    fields = ( data, restricted ) => [
        {
            field: 'id',
            type:  'hidden',
            value: data.id,
        },
        {
            field:    'taskName',
            label:    'Что сделать',
            type:     'text',
            value:    data.title,
            disabled: restricted,
            rules:    [ config.rules.required ],
        },
        {
            field:         'dueTime',
            label:         'Выполнить до',
            type:          'datetime',
            disabled:      restricted,
            disabledHours: () => {
                return this.props.form.getFieldValue('dueTime')?.isAfter(moment()?.endOf('day'))
                    ? []
                    : format.generate.range(0, moment().add(1, 'hour').hour())
            },
            value: data.completeDate
                ? moment( data.completeDate )
                : moment().add( 1, 'hour' ),
            disabledDate: ( current ) => current && current < moment(),
            rules:        [ config.rules.required ],
            onChange: this.checkDate,
        },
        /*{
                field: 'taskType',
                label: 'Тип задачи',
                type: 'select',
                options: format.generate.keyvalr( this.props.types ),
                rules: [
                    config.rules.required
                ]
            },*/
        {
            field:    'assigneeName',
            label:    'Ответственный',
            type:     'select',
            options:  this.props.assignees,
            disabled: restricted,
            value:    data.assignee ? data.assignee.userId : '',
            search:   ( search, opt ) =>
                format.find.strInProp( opt.props, 'children', search ),
            rules: [ config.rules.required ],
        },
        {
            field: 'isPhotoRequired',
            value:
        data.components &&
        data.components[ 0 ] &&
        data.components[ 0 ].type === 'PROOF',
            disabled: () => !!data.id,
            label:    'Сфотографировать результат',
            type:     'checkbox',
        },
        {
            field: '_add',
            label: 'Дополнительные сведения',
            type:  'checkbox',
            value: !!data.assignee || !!data.description,
        },
        {
            field:  'executorName',
            label:  'Исполнитель',
            type:   'select',
            byKey:  true,
            value:  data.executor ? data.executor.replace( / +/, ' ' ) : null,
            search: ( search, opt ) =>
                format.find.strInProp( opt.props, 'children', search ),
            options: this.props.executors,
            hide:    ( data ) => !data.form.getFieldValue( '_add' ),
        },
        {
            field:    'taskDescription',
            label:    'Комментарий',
            type:     'text',
            disabled: restricted,
            value:    data.description,
            hide:     ( data ) => !data.form.getFieldValue( '_add' ),
        },
        {
            field: 'actions',
            type:  'row',
            items: [
                {
                    field:   'submit',
                    text:    data.id ? 'Сохранить' : 'Добавить',
                    type:    'button',
                    style:   'primary',
                    span:    10,
                    handler: this.preSubmit,
                },
                {
                    field:   'cancel',
                    text:    'Отменить',
                    type:    'button',
                    style:   'ghost',
                    span:    10,
                    handler: this.cancel,
                },
            ],
        },
    ]

    row = ( cfg ) => {
        const res = []

        cfg.items.map(( item ) =>
            res.push(
                <Col span={item.span} key={item.field}>
                    {this.field( item )}
                </Col>
            )
        )

        return (
            <Row gutter={24} key={cfg.field} className={cfg.field}>
                {res}
            </Row>
        )
    }

    field = ( cfg ) => {
        if ( cfg.type === 'row' ) {
            return this.row( cfg )
        }

        return (
            <FormField
                {...cfg}
                value={cfg.value}
                key={cfg.field}
                form={this.props.form}
            />
        )
    }

    preSubmit = () => {
        this.props.form.validateFieldsAndScroll(( err, values ) => {
            if ( !err ) {
                this.props.onSubmit( values )
            }
        })
    }

    cancel = () => {
        const { onCancel, form } = this.props

        setTimeout(() => form.resetFields(), 300 )
        onCancel()
    }

    render () {
        const { visible, data, user, saving } = this.props,
              rdata = data || {}
        return (
            <Modal
                title={data ? 'Редактирование задачи' : 'Новое поручение'}
                className="kfc-popup no-footer no-margin"
                centered={true}
                open={visible}
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }}
                onCancel={this.cancel}
            >
                <Spin spinning={saving}>
                    <Form
                        {...config.ui.wideForm}
                    >
                        {this.fields(
                            rdata,
                            data ? user.userId !== rdata.runnedBy : false
                        ).map( this.field )}
                    </Form>
                </Spin>

            </Modal>
        )
    }
}

export default Form.create({ name: 'EditIssue' })( EditIssue )
