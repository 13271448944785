import {DatePicker} from 'antd';

export type PickerType = 'date' | 'month' | 'year';

export type RangeValue<DateType> = [ DateType | null, DateType | null ] | null;
export enum DaysOfWeek {
    MONDAY = 'Понедельник',
    TUESDAY = 'Вторник',
    WEDNESDAY = 'Среда',
    THURSDAY = 'Четверг',
    FRIDAY = 'Пятница',
    SATURDAY = 'Суббота',
    SUNDAY = 'Воскресенье',
}

export const { RangePicker } = DatePicker;
