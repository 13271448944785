const
    lightMod = 0.6,
    deepMod = 1 / 3,

    equal = ( key ) => ' overlap-' + key + '-equal',
    side = ( key ) => ' overlap-' + key,
    deep = ( key ) => ' overlap-' + key + '-deep',
    diff = ( plan, a, b ) => plan[ b ].time - plan[ a ].time,

    isDeep = ( key, plan, point ) => {
        const a = point,
              b = point + 1,
              c = point + 2

        let res = ''

        diff( plan, b, c ) < diff( plan, a, b ) * lightMod && ( res += side( key ))
        diff( plan, b, c ) < diff( plan, a, b ) * deepMod && ( res += deep( key ))

        return res
    },

    start = ( plan ) =>
        plan[ 1 ].time === plan[ 0 ].time ? equal( 'start' ) : isDeep( 'start', plan, 0 ),

    end = ( plan ) => {
        const last = plan.length - 1

        return plan[ last ].time === plan[ last - 1 ].time
            ? equal( 'end' )
            : isDeep( 'end', plan, last - 2 )
    }

export default ( plan ) => {
    let res = ''

    if ( !plan ) { return res }
    if ( plan.length < 3 ) { return res }

    res = start( plan )
    res += end( plan )

    return res
}
