import { format } from 'tools'
import column     from 'config/tables/helpers'

const info = ( isDish ) =>
    isDish
        ? {
                key:  'dishes',
                id:   'productUid',
                type: 'dish',
            }
        : {
                key:  'categories',
                id:   'categoryId',
                type: 'category',
            }

export const deltas = ( data, isDish ) => {
    const { key, id } = info( isDish )

    if ( !data || !data.content || data.content.length < 1 ) { return [] }

    return data.content[ 0 ][ key ].map(( item ) => item[ id ] + 'Delta' )
}

export const cashiers = ( data, isDish ) => {
    if ( !data || !data.content ) { return null }
    if ( data.content.length < 1 ) { return format.generate.noPager([]) }

    const res = format.copy.object( data ),
          cashiers = []

    data.content.forEach(( cashier ) => {
        const row = {
            employeeId: cashier.employeeId,
            name:       cashier.name,
        },
              { key, id } = info( isDish )

        cashier[ key ].forEach(( item ) => {
            row[ item[ id ] + 'Fact' ] = item.fact
            row[ item[ id ] + 'Delta' ] = item.delta
        })

        row.avgNet = cashier.avgNet
        row.sumNet = cashier.sumNet

        cashiers.push( row )
    })

    res.content = cashiers

    return res
}

const itemColumn = ( item, id, type ) =>
    column( item[ type ], item[ id ] + 'Fact', {
        numeric: true,
        sorter:  'bynull',
        format:  'percentage',
        colSpan: 2,
        title:   `${item[ type ]} — ${format.strings.percentage( item.plan )}`,
    }),

      deltaColumn = ( item, id ) =>
          column( '', item[ id ] + 'Delta', {
              as:      'delta',
              numeric: true,
              colSpan: 0,
              cell:    [ 'cellclass' ],
          })

export const cashColumns = ( data, isDish ) => {
    if ( !data || !data.content || data.content.length < 1 ) { return [] }

    const res = [ column( '', 'name' ) ],
          { key, id, type } = info( isDish )

    data.content[ 0 ][ key ].forEach(( item ) => {
        res.push( itemColumn( item, id, type ))
        res.push( deltaColumn( item, id ))
    })

    res.push(
        column( 'Средний чек', 'avgNet', {
            as:       'currency',
            currency: 'currency',
            sorter:   'bynull',
        })
    )

    res.push(
        column( 'Общая выручка', 'sumNet', {
            as:       'currency',
            currency: 'currency',
            sorter:   'bynull',
        })
    )

    return res
}
