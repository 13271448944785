/* VENDOR */
import moment from 'moment'

/* APPLICATION */

export const days = [ 1, 2, 3, 4, 5, 6, 7 ]
export const shifts = [ 'смена', 'смены', 'смен' ]

export const category = {
    categoryId:   null,
    categoryName: 'Новая категория',
}

export const component = {
    id:       null,
    type:     'confirm',
    desc:     '',
    weight:   1,
    required: true,
}

export const template = {
    templateId:         null,
    templateName:       'Новый шаблон ',
    templateBody:       '{"components":[]}',
    version:            1,
    active:             true,
    public:             true,
    needShowAttributes: false,
    groupName:          '',
    __new:              true,
}

export const workHours = days.map(( day ) => ({
    dayOfWeek: day,
    holiday:   false,
    noctidial: false,
    timeStart: '07:00',
    timeEnd:   '18:00',
    shifts:    [
        {
            timeStart: '07:00',
            timeEnd:   '18:00',
        },
    ],
}))

export const opportunity = {
    dayOfWeek: 1,
    startTime: '00:00',
    endTime:   '23:00',
    timed:     false,
    dateStart: '',
    dateEnd:   '',
}

export const position = {
    guid:        'new',
    name:        '',
    description: '',
    rate:        1,
    blocked:     false,
    dateStart:   '',
    dateEnd:     '',
    dateModify:  moment(),
}

export const employee = {
    __new:         true,
    id:            null,
    employeeId:    null,
    name:          '',
    scheduleName:  '',
    restaurantId:  process.env.REACT_APP_RID,
    outstaff:      true,
    dateStartWork: '',
    dateEndWork:   '',
    rate:          null,
    paymentType:   null,
    salary:        0,
    birthDate:     '',
    gender:        '',
    phone:         '',
    email:         '',
    city:          '',
    address:       '',
    comment:       '',
}

export const fixed = {
    fixedShiftId: null,
    dayOfWeek:    1,
    positionGuid: null,
    positionName: '',
    employeeGuid: '',
    employeeName: '',
    timeStart:    '00:00',
    timeEnd:      '23:00',
}

export const absence = {
    absenceId:    null,
    dateEnd:      null,
    dateStart:    null,
    dayOfWeek:    1,
    employeeGuid: '',
    employeeName: '',
    reason:       '',
}

export const manning = {
    turnoverChartId: null,
    turnover:        0,
    positions:       [],
    total:           0,
    spmh:            0,
    transactions:    0
}

export const duration = {
    durationId: null,
    duration:   1,
    priority:   20,
}

export const rates = [
    {
        rate:   1,
        breaks: [],
    },
    {
        rate:   1.5,
        breaks: [],
    },
]

export const breaks = {
    breakId:  null,
    duration: 10,
    rate:     1,
    name:     'Без названия',
    min:      0,
    max:      24,
    paid:     false,
}

export const schedulePosition = {
    positionGuid: 'bb3da2af-c16c-474d-bd4d-f08b75286a01',
    position:     'КАСС',
    positionName: 'Касса',
    shifts:       [],
    summaryHours: {
        planHours:     0,
        requiredHours: 0,
        deltaHours:    0,
    },
    hours: Array( 24 )
        .fill( 0 )
        .map(( z, i ) => ({
            summaryHours: {
                hour:          i,
                planHours:     null,
                requiredHours: null,
                deltaHours:    0,
            },
        })),
}

export const pprStudies = {
    isHall:        'Зал',
    isService:     'Обслуживание',
    isKitchen:     'Кухня',
    isChick:       'Цыпл',
    isAuto:        'Авто',
    isScout:       'Скаут',
    isBrandChef:   'Бренд-шеф',
    isKlikun:      'Кликун',
    isHostess:     'Хостес',
    isInstService: 'Инст. Обслуживание',
    isInstKitchen: 'Инст. Кухня',
    isInstChick:   'Инст. Цыпл'
}

export const pprStudiesShort = {
    isHall:        'Зал',
    isService:     'Обсл.',
    isKitchen:     'Кухн.',
    isChick:       'Цыпл.',
    isAuto:        'Авто',
    isScout:       'Скаут',
    isBrandChef:   'Бренд-шеф',
    isKlikun:      'Кликун',
    isHostess:     'Хостес',
    isInstService: 'Инст. Обсл.',
    isInstKitchen: 'Инст. Кухн.',
    isInstChick:   'Инст. Цыпл.'
}

export const timeZones = {
    '+0000': 'Среднее время по Гринвичу',
    '+0100': 'Великобритания',
    '+0200': 'Калининград',
    '+0300': 'Москва',
    '+0400': 'Самарское',
    '+0500': 'Екатеринбург',
    '+0600': 'Омск',
    '+0700': 'Красноярск',
    '+0800': 'Иркутск',
    '+0900': 'Якутск',
    '+0930': 'Центральная Австралия',
    '+1000': 'Владивосток',
    '+1100': 'Магадан',
    '+1200': 'Анадырь',
    '+1300': 'Новая Зеландия',
    '+1400': 'Острова Лайн',
    '-1200': 'Бейкер и Хауленд',
    '-1100': 'Самоа',
    '-1000': 'Магадан',
    '-0900': 'Анадырь',
    '-0800': 'Аляска',
    '-0700': 'Тихоокеанское время',
    '-0600': 'Центральная Америка',
    '-0500': 'Центральная Америка',
    '-0400': 'Восточная Америка',
    '-0300': 'Бразилия',
    '-0200': 'Гренландия',
    '-0100': 'Кабо-верде',
    '-0000': 'Среднее время по Гринвичу'
}
