/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'

/* APPLICATION */
import { Spinner } from 'components'

import {
    DailyTemplate,
    ManagersReport,
    ReadyReport,
    ReportTitle,
    ScheduleSummary,
    ScheduleTemplate,
    ShortSchedule,
    StatisticsDays,
    StatisticsSummary,
    Stations,
    WorkedTime,
} from './templates'

import './print-report.scss'

class PrintReport extends Component {
    static propTypes = {
        templates: PropTypes.array,
        workHours: PropTypes.object,

        data:  PropTypes.any,
        dates: PropTypes.array,

        title:      PropTypes.string,
        restaurant: PropTypes.string,
    }

    content = () => this.props.templates.map( this.table )

    table = ( template, index ) => {
        const { title, dates, data, restaurant, workHours } = this.props,
              props = {
                  key:  template + index,
                  workHours,
                  data: {
                      title:      title,
                      restaurant: restaurant,
                      dates:      dates,
                      ...data,
                  },
              }

        switch ( template ) {
            case 'statistics-summary':
                return <StatisticsSummary {...props} rowKey="key" />
            case 'statistics-report':
                return <StatisticsDays {...props} rowKey="time" />

            case 'ready-report':
                return <ReadyReport {...props} rowKey="key" />

            case 'managers-report':
                return <ManagersReport {...props} rowKey="employee" />

            case 'report-title':
                return <ReportTitle {...props} rowKey="title" />

            case 'short-schedule':
                return <ShortSchedule {...props} rowKey="key" />

            case 'schedule-table':
                return <ScheduleTemplate {...props} rowKey="key" />

            case 'schedule-summary':
                return <ScheduleSummary {...props} rowKey="hours" />

            case 'stations':
                return <Stations {...props} rowKey="stationName" />

            case 'workedtime':
                return <WorkedTime {...props} rowKey="key" />

            case 'daily-schedule':
            default:
                return <DailyTemplate {...props} rowKey="key" />
        }
    }

    render () {
        if ( this.props.error ) { return <></> }

        if ( !this.props.data ) { return <Spinner /> }

        return <div className="print-report">{this.content()}</div>
    }
}

export default PrintReport
