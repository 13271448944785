/* VENDOR */
import axios from 'axios'

/* APPLICATION */
import { format } from 'tools'
import config     from 'config'

const service = 'restaurant'

export const getCategories = ( params ) =>
    format.generate.get( service, 'admin/dish-categories', params )
export const getCategory = ( params ) =>
    format.generate.get( service, 'admin/dish-categories/' + params.id, params, [
        'page',
    ])
export const getDishList = ( params ) =>
    format.generate.get( service, 'admin/dishes', params )

export const addCategory = ( body ) =>
    axios.post(
        config.api[ service ] + 'admin/dish-categories',
        body,
        config.api.options
    )

export const updateCategory = ( body ) =>
    axios.put(
        config.api[ service ] + 'admin/dish-categories/' + body.categoryId,
        body,
        config.api.options
    )

export const removeCategory = ( body ) =>
    axios.delete(
        config.api[ service ] + 'admin/dish-categories/' + body.categoryId,
        config.api.options
    )

export const addDish = ( params ) =>
    axios.post(
        config.api[ service ] +
      'admin/dish-categories/' +
      params.categoryId +
      '/dishes/' +
      params.productUid,
        params.body,
        config.api.options
    )

export const removeDish = ( params ) =>
    axios.delete(
        config.api[ service ] +
      'admin/dish-categories/' +
      params.categoryId +
      '/dishes/' +
      params.productUid,
        config.api.options
    )
