/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Alert }            from 'antd'

/* APPLICATION */
import { AddButton, Link, ReportTable, SyncScroll, Spinner } from 'components'

import { format } from 'tools'
import column     from 'config/tables/helpers'
import config     from 'config'

import './manning-table.scss'

const cellWidth = 100

class ManningTableOld extends Component {
    static propTypes = {
        data:      PropTypes.array,
        positions: PropTypes.array,

        onRemove: PropTypes.func,
        onAdd:    PropTypes.func,
        onChange: PropTypes.func,
    }

    constructor ( props ) {
        super( props )
        this.state = {
            data:    null,
            columns: [],
            width:   1000,
        }
    }

    componentDidMount () {
        this.setData( this.props.data )
        this.setColumns( this.props.positions )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { data, positions } = this.props

        nextProps.data !== data && this.setData( nextProps.data )
        nextProps.positions !== positions && this.setColumns( nextProps.positions )
    }

    componentDidUpdate () {
        window.dispatchEvent( new Event( 'resize' ))
    }

    setColumns = ( positions ) =>
        this.setState({
            columns: this.columns( positions ),
            width:   this.width( positions ),
        })

    setData = ( data ) =>
        this.setState({ data: this.prepare( data ), })

    preprow = ( row ) => {
        row.positions.map(( pos ) => ( row[ pos.positionGuid ] = pos.positionCount ))
        return row
    }

    position = ( pos ) =>
        column( pos.position, pos.positionGuid, {
            editable: true,
            as:       'number',
            width:    cellWidth,
        })

    prepare = ( data ) => ( data ? format.copy.array( data ).map( this.preprow ) : null )

    width = ( positions ) =>
        this.columns( positions ).reduce(( w, p ) => w + p.width, 0 )

    columns = ( original ) => {
        const positions = original
            ? original
                .filter(( pos ) => pos.positionGuid !== config.mcGuid )
                .map( this.position )
            : []

        return [
            column( 'Товарооборот', 'turnover', {
                width:         200,
                editable:      true,
                as:            'currency',
                focusOnCreate: true,
                fixed:         'left',
            }),

            ...positions,

            column( 'Всего', 'total', {
                width: cellWidth,
                fixed: 'right',
            }),

            column( 'SPMH', 'spmh', {
                width: 130,
                as:    'currency',
                fixed: 'right',
            }),

            column( '', '', {
                editable:  true,
                as:        'remove',
                className: 'align-center',
                fixed:     'right',
                width:     150,
            }),
        ]
    }

    render () {
        const { data, columns, width } = this.state

        if ( !data ) { return <Spinner /> }

        return (
            <div className="staff-manning-table">
                <div className="report-table manning-table">
                    {data.length < 1 && (
                        <Alert
                            message={
                                <span>
                  Чтобы сформировать чарт,{' '}
                                    <Link onClick={this.props.onAdd}>добавьте цель</Link> по
                  товарообороту
                                </span>
                            }
                            type="warning"
                        />
                    )}
                    <ReportTable
                        data={format.generate.noPager( data )}
                        columns={columns}
                        emptyText=" "
                        rowKey="turnoverChartId"
                        scroll={{ x: width }}
                        loading={false}
                        onRemove={this.props.onRemove}
                        onCellBlur={this.props.onChange}
                    />
                </div>
                <div className="tabbed-footer left sticky">
                    <div className="middle-footer">
                        <AddButton text="Добавить" action={this.props.onAdd} />
                    </div>

                    <SyncScroll connect="manning" apply=".manning-table" height={20} />
                </div>
            </div>
        )
    }
}

export default ManningTableOld
