import React, { useEffect, useState } from 'react'
import { Col, Form, Row, Spin }       from 'antd'
import moment                         from 'moment'
import { connect }                    from 'react-redux'
import config                         from 'config'
import {
    PprAreaInfo,
    PprManagerStructure,
    PprManagerBench,
    PprAreaManagerStructure,
    PprAreaRestaurantInfo,
    PprTableError,
    Spinner,
    Alert
} from 'components'

import { allActions, mapStateToProps } from './connector'
import './ppr-area.scss'

const formatView = config.format.monthView
const formatAPI = config.format.monthAPI

const PprArea = ({ period, restaurantId, ppr, areaData, areaCard, userId, acStatuses, ...rest }) => {
    const [ loading, setLoading ] = useState( false )
    const [ saving, setSaving ] = useState( false )
    const isFieldsTouched = rest.form.isFieldsTouched()
    const [ form ] = Form.useForm()

    useEffect(() => {
        if ( !loading && !ppr?.periods ) {
            getLists()
        }
    }, [])

    useEffect(() => {
        if ( Array.isArray( ppr?.periods ) && period && userId && !loading ) {
            setLoading( true )
            update()
        }
    }, [ ppr, period, restaurantId, userId ])

    const getLists = () => {
        setLoading( true )
        rest.fetchPprAcStatuses()
        rest.fetchStaffPprAvailablePeriods().finally(() => setLoading( false ))
    }

    const getPprParams = () => ({
        userId,
        period: moment( period, formatView ).format( formatAPI )
    })

    const update = () => {
        const pprParams = getPprParams()
        Promise.all([
            rest.fetchPprArea( pprParams ),
            rest.fetchAreaCard({
                ...pprParams,
                factsNumber: restaurantId,
            })
        ]).finally(() => {
            rest.form.resetFields()
            setLoading( false )
        })
    }

    const reloadPprArea = () => {
        const pprParams = getPprParams()
        rest.fetchPprArea( pprParams )
            .finally(() => {
                rest.form.resetFields()
                setSaving( false )
            })
    }

    const handleSave = () => {
        if ( saving ) { return }
        rest.form.validateFieldsAndScroll(( err, values ) => {
            if ( !err ) {
                setSaving( true )
                if ( areaCard?.areaCardUuid ) {
                    rest.updateAreaCard({
                        ...values,
                        areaCardUuid:     areaCard?.areaCardUuid,
                        acPositionStatus: { id: values.acPositionStatus }
                    }).catch(() => {
                        setSaving( false )
                    }).finally(() => {
                        reloadPprArea()
                    })
                } else {
                    rest.createAreaCard({
                        period:           moment( period, formatView ).format( formatAPI ),
                        userId,
                        ...values,
                        acPositionStatus: { id: values.acPositionStatus }
                    }).catch(() => {
                        setSaving( false )
                    }).finally(() => {
                        reloadPprArea()
                    })
                }
            }
        })
    }

    const handleCancel = () => {
        rest.form.resetFields()
    }

    if ( !loading && rest.areaFiltersChanged && !ppr?.periods_error ) { return null }

    return (
        <div className="ppr-area">
            {
                loading ? <Spinner />
                    :
                        <Spin spinning={saving}>
                            <Form
                                name="ppr_area_card"
                                form={form}
                                onFinish={( e ) => e.preventDefault()}
                            >
                                <Row gutter={16} style={{ marginBottom: 16 }}>
                                    <Col span={8}>
                                        <PprAreaInfo
                                            acStatuses={acStatuses}
                                            form={rest.form}
                                            card={areaCard}
                                            data={{
                                                period:        areaData?.period,
                                                region:        areaData?.region,
                                                acSurnameName: areaData?.acSurnameName
                                            }}
                                            isEditable={areaData?.isEditable}
                                        />
                                    </Col>
                                    <Col span={16}>
                                        <Row>
                                            <PprManagerStructure
                                                data={areaData?.areaManagersStructureShort}
                                                isArea
                                            />
                                            <PprManagerBench
                                                form={rest.form}
                                                isArea
                                                data={{
                                                    ...areaData?.areaBenchStructureShort,
                                                    ...areaCard,
                                                }}
                                                isEditable={areaData?.isEditable}
                                            />
                                        </Row>
                                    </Col>
                                </Row>
                            </Form>
                            <Row style={{ marginBottom: 56 }}>
                                <PprAreaManagerStructure
                                    areaBenchStructureLong={areaData?.areaBenchStructureLong}
                                    areaManagersStructureLong={areaData?.areaManagersStructureLong}
                                />
                            </Row>
                            <Row>
                                <PprAreaRestaurantInfo
                                    restaurants={areaData?.restaurants}
                                    updateAcComment={rest.updateAcComment}
                                />
                            </Row>
                        </Spin>
            }
            {isFieldsTouched && <Alert
                text={'Изменения вступят в силу после сохранения'}
                button
                buttonText="Сохранить"
                action={handleSave}
                cancel
                cancelText="Отменить изменения"
                onCancel={handleCancel}
            />}
            {ppr?.periods_error && <PprTableError
                error={ppr?.periods_error}
                onRetry={getLists}
                centered={false}
            />}
        </div>
    )
}

export default connect( mapStateToProps, allActions )( PprArea )
