/* eslint-disable camelcase */
/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Tabs, Spin } from 'antd'
import Icon from '@ant-design/icons'
import moment               from 'moment'

/* APPLICATION */
import {
    StaffCard,
    StaffOpportunities,
    StaffPositions,
    StaffRating,
    UserAvatar,
    Icons,
} from 'components'

import './staff-info.scss'

class StaffInfo extends Component {
    static propTypes = {
        restaurant: PropTypes.string,
        loading:    PropTypes.bool,

        roles:     PropTypes.object,
        employee:  PropTypes.object,
        rating:    PropTypes.object,
        rates:     PropTypes.array,
        companies: PropTypes.array,
        ppr:       PropTypes.object,

        onSave:      PropTypes.func,
        onRemove:    PropTypes.func,
        onError:     PropTypes.func,
        onMore:      PropTypes.func,
        getRates:    PropTypes.func,
        onChangeTab: PropTypes.func,
    }

    prepare = ( data ) => ({
        ...data,
        _protect_company: data ? !!data.companyUuid : false,
    })

    render () {
        const { loading, rating, restaurant, roles, companies } = this.props,
              { onSave, onRemove, onError, onMore, rates, getRates, onChangeTab } = this.props,
              { onPprSave, onPprError, onChangePeriod, periodPpr, user, trainingLoading } = this.props,
              { info: { jobRole } } = user

        let employee = this.props.employee
        let ppr = this.props.ppr || {}

        if ( !employee && !loading ) { return null }
        loading && ( employee = {})

        const tabs = [
            {
                key: 'main',
                label: 'Анкета',
                children:
                    <StaffCard
                        loading={loading}
                        employee={this.prepare(employee.main)}
                        roles={roles}
                        restaurant={restaurant}
                        companies={companies}
                        onSave={onSave}
                        onRemove={onRemove}
                        onError={onError}
                        rates={rates}
                        getRates={getRates}
                    />
            },
        ];

        if (!employee.__new) {
            tabs.push(
                {
                    key: 'op',
                    label: 'Возможности',
                    children: (
                        <StaffOpportunities
                            data={employee.opportunities}
                            outstaff={employee?.main?.outstaff}
                            draft={
                                employee.main &&
                  !(employee.main.salary && employee.main.rateDictionary)
                            }
                        />
                    ),
                },
                {
                    key: 'positions',
                    label: 'Позиции',
                    children: (
                        <StaffPositions
                            data={employee.positions}
                            outstaff={employee?.main?.outstaff}
                            draft={
                                employee.main &&
                  !(employee.main.salary && employee.main.rateDictionary)
                            }
                        />
                    ),
                },
                {
                    key: 'rating',
                    label: 'Оценка',
                    children: (
                        <StaffRating
                            data={rating && rating.list}
                            add={rating && rating.data}
                            draft={
                                employee.main &&
                  !(employee.main.salary && employee.main.rateDictionary)
                            }
                            more={onMore}
                        />
                    ),
                }
            );
        }

        return (
            <div className="view-type-switch fixed">
                <header className="staff-head">
                    <UserAvatar
                        loading={!employee.main}
                        name={
                            employee.main
                                ? employee.main.scheduleName || employee.main.fullName
                                : ''
                        }
                        userId={
                            employee.main &&
              employee.main.salary &&
              employee.main.rateDictionary
                                ? employee.main.userId
                                : null
                        }
                    />
                    <h2 className="staff-name">
                        <span className="staff-name-inner">
                            {employee.main ? (
                                employee.main.scheduleName || employee.main.fullName
                            ) : (
                                <Spin size="small" />
                            )}
                            {employee.main && employee.main.outstaff && (
                                <span className="badge">Внештатный сотрудник</span>
                            )}
                        </span>
                        {employee.transfer && (
                            <span className="transfer-line">
                                <Icon
                                    component={
                                        employee.transfer.mainFactsNumber.toString() === restaurant
                                            ? Icons.Transfer.def
                                            : Icons.Temporary.def
                                    }
                                />
                                {employee.transfer.mainFactsNumber.toString() === restaurant
                                    ? employee.transfer.transferredRestaurantName
                                    : employee.transfer.mainRestaurantName}
                                {'・до '}
                                {moment( employee.transfer.dateTo ).format( 'DD MMM YY' )}
                                {' (Включительно)'}
                            </span>
                        )}
                    </h2>
                </header>

                <Tabs animated={false} onChange={onChangeTab} items={tabs} />
            </div>
        )
    }
}

export default StaffInfo
