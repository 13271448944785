import React, {useEffect, useMemo, useState} from 'react';
import Report from '../../layout/Report/Report';
import ViewTypeDrop from '../../layout/ViewTypeDrop/ViewTypeDrop';
import {
    GuestVoteFiltersObject,
} from '../GuestVoteFilters/type';
import {dropTypesMap} from './const';
import {TableProps} from 'antd';

import './guest-vote-table.scss';
import {LoadMoreButton} from '../../index';
import {allActions, GuestVoteFiltersStoreProps, mapStateToProps} from './connector';
import {connect} from 'react-redux';
import {ColumnsType} from 'antd/es/table';
import {FormatedDateFilters} from 'pages/GuestVote/types';
import {DataGroupType} from 'services/guestVote/statistic/type';
import cn from 'classnames';

export const STORAGE_KEY = 'guestVoteTableGroup';

type GuestVoteTableProps = {
    columnsRender: (dataGroup: DataGroupType) => ColumnsType<any>,
    rowKey: string;
    currentFilters: GuestVoteFiltersObject,
    dateFilters: FormatedDateFilters,
    isFirstLineBold?: boolean,
    buttonLoading?: boolean,
    disabledDropTypes?: DataGroupType[],
    data?: Record<string, any>,
    postDataQuery?: Function,
    appendDataQuery?: Function,
} & TableProps<any>;



export const GuestVoteTable = ({data, columnsRender, ...props}: GuestVoteTableProps & GuestVoteFiltersStoreProps) => {
    const [ dropState, setDropState ] = useState<DataGroupType>('RESTAURANTS');
    const [ currentPage, setCurrentPage ] = useState(1);
    const {
        currentFilters,
        dateFilters,
        postDataQuery,
        appendDataQuery,
        disabledDropTypes,
        isFirstLineBold,
        loading,
        buttonLoading,
        setDataGroup
    } = props;

    const handleSetDropState = (state: DataGroupType) => {
        sessionStorage.setItem(STORAGE_KEY, state);
        setDropState(state);
        setCurrentPage(1)

        postDataQuery?.({
            dataGroup: state,
            ...currentFilters,
            ...dateFilters,
        })
    }

    useEffect(() => {
        const currentDropState = sessionStorage.getItem(STORAGE_KEY) as DataGroupType

        if (currentDropState) {
            setDropState(currentDropState);
        } else {
            sessionStorage.setItem(STORAGE_KEY, 'RESTAURANTS');
        }
    })

    useEffect(() => {
        setDataGroup(dropState)
    }, [ dropState ]);


    const filteredDropTypes = useMemo(() => {
        if (disabledDropTypes) {
            let keys = Object.keys(dropTypesMap) as DataGroupType[]
            keys = keys.filter(key => !disabledDropTypes.includes(key))
            return keys.reduce<Partial<Record<DataGroupType, string>>>((acc, key) => {
                acc[key] = dropTypesMap[key]
                return acc
            }, {})
        }
        return dropTypesMap
    }, [ disabledDropTypes ])

    //todo: сделать состояние загрузки таблицы и кнопки "Добавить ещё" пропсом, прокидываемым сверху
    return (
        <div className={cn(
            'guest-vote-table',
            { 'guest-vote-table--bold': isFirstLineBold }
        )}>
            {!!data?.content.length && <div className='dropdown_wrapper'>
                <ViewTypeDrop
                    current={dropState}
                    placeholder={dropState}
                    update={handleSetDropState}
                    items={filteredDropTypes}
                />
                <span className='support_text'>Выберите, в каком разрезе отобразить данные в таблице</span>
            </div>
            }
            <Report
                unit="none"
                scroll={{y: 500}}
                loading={loading}
                data={data ?? {}}
                columns={columnsRender(dropState as DataGroupType)}
                {...props}
            />
            {
                (currentPage !== data?.totalPages) && data?.totalPages !== 0 && !loading && (
                    <LoadMoreButton
                        loading={buttonLoading}
                        onClick={() => {
                            setCurrentPage(currentPage + 1);
                            appendDataQuery?.({
                                body: {
                                    dataGroup: dropState,
                                    ...currentFilters,
                                    ...dateFilters,
                                },
                                params: {page: currentPage}
                            });
                        }}/>
                )
            }
        </div>
    );
};

export default connect(mapStateToProps, allActions)(GuestVoteTable)
