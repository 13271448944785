import { Col }      from 'antd'
import React        from 'react'
import classNames   from 'classnames'
import { PprTable } from 'components'
import './ppr-manager-structure.scss'
import config       from 'config'

const prefixes = [ 'rgm', 'argm', 'sm' ]

const PprManagerStructure = ({ data, loading, error, onRetry, isArea }) => {
    const isRedColor = ( content, isNumber ) => {
        return ( isNumber && content < 100 ) || ( !isNumber && content < 0 )
    }

    const getKey = ( prefix, postfix ) => {
        return `${prefix}${isArea ? 'Area' : ''}${postfix}`
    }

    const td = ({ content, isNumber = false, isBold = false, isTotal = false, isDelta = false }) => {
        return <td className={
            classNames(
                isDelta && content > 0 && 'color-blue',
                isRedColor( content, isNumber ) && ( isTotal ? 'bg-color-red' : 'color-red' ),
                isBold && 'bold'
            )
        }>{content ?? config.ui.nullSymbol}{isNumber && typeof content === 'number' && '%'}</td>
    }

    const tr = ( prefix ) => <tr key={prefix}>
        {td({ content: prefix.toUpperCase() })}
        {td({ content: data?.[ getKey( prefix, 'Relative' ) ], isNumber: true })}
        {td({ content: data?.[ getKey( prefix, 'Delta' ) ], isDelta: true })}
    </tr>

    return (
        <Col span={12}>
            <PprTable
                loading={loading}
                error={error}
                onRetry={() => onRetry( 'managersStructure' )}
                vertical
                className='ppr-manager-structure'
            >
                <thead>
                    <tr>
                        <th colSpan={3}>Структура менеджеров</th>
                    </tr>
                    <tr>
                        <th>Позиция</th>
                        <th>%</th>
                        <th>+/–</th>
                    </tr>
                </thead>
                <tbody>
                    {prefixes.map( tr )}
                    <tr className="result-row">
                        {td({ content: 'Итого', isBold: true })}
                        {td({ content: data?.[ getKey( 'total', 'Relative' ) ], isBold: true, isNumber: true, isTotal: true })}
                        {td({ content: data?.[ getKey( 'total', 'Delta' ) ], isBold: true, isDelta: true })}
                    </tr>
                </tbody>
            </PprTable>
        </Col>
    )
}

export default PprManagerStructure
