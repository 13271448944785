/* IMPORT */

import { staffActions, } from 'services'

/* EXPORT */

export const mapStateToProps = ( state ) => ({
    director:     state.staff.director,
    ppr:          state.staff.ppr,
    restaurantId: state.request.restaurantId,
})

export const allActions = { ...staffActions, }
