/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Icon, Popover }    from 'antd'

/* APPLICATION */
import { Value, Icons } from 'components'
import { format }       from 'tools'
import config           from 'config'

import ScheduleTableView from './ScheduleTableView'

const rows = {
    def: [
        {
            path:   'planTurnover',
            label:  'Плановый товарооборот, ₽',
            render: 'currency',
        },
        {
            path:   'transactions',
            label:  'Количество чеков',
            render: 'na',
        },
    ],
    paid: [
        {
            path:   'productiveHours.requiredHours',
            label:  'Требуется производ. часов',
            render: 'na',
        },
        {
            path:   'productiveHours.planHours',
            label:  'Запланировано производ. часов',
            render: 'na',
        },
    ],
    delta: {
        path:    'productiveHours.deltaHours',
        label:   'Рабочие часы',
        render:  'delta',
        spoiler: true,
    },
}

class ScheduleTable extends Component {
    static propTypes = {
        day:      PropTypes.string,
        position: PropTypes.object,

        from: PropTypes.object,
        to:   PropTypes.object,
        max:  PropTypes.array,

        data:      PropTypes.object,
        helpers:   PropTypes.object,
        open:      PropTypes.object,
        positions: PropTypes.array,
        workHours: PropTypes.object,

        onSelect: PropTypes.func,
    }

    constructor ( props ) {
        super( props )
        this.state = {
            data:      null,
            positions: [],
        }
    }

    componentDidMount () {
        this.setData( this.props )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { data, day, open, position, positions } = this.props

        if ( nextProps.day !== day ) {
            this.setState({ data: null })

            setTimeout(() => this.setData( nextProps ), 0 )
        }

        if (
            nextProps.data !== data ||
            nextProps.open !== open ||
            nextProps.position !== position
        ) {
            this.setData( nextProps )
        }

        if ( nextProps.positions !== positions ) {
            this.setPositions( nextProps )
        }
    }

    setData = ( props ) =>
        this.setState(
            { data: this.data( props ), },
            () => this.setPositions( props )
        )

    setPositions = ( props ) =>
        props.positions
            ? this.setState({
                positions: format.generate.options(
                    props.positions
                        .filter(( p ) => p.chart )
                        .map(( p ) => {
                            p.label = (
                                <span>
                                    {p.position}
                                    {this.getDelta( p, props )}
                                </span>
                            )
                            return p
                        }),
                    'positionGuid',
                    'label'
                ),
            })
            : []

    getDelta = ( pos, props ) => {
        const day = props.data.days.find(
            ( d ) => parseInt( d.dayOfWeek ) === parseInt( props.day )
        ),
              position = day.positions.find(( p ) => p.positionGuid === pos.positionGuid ),
              delta =
                position && position.positionGuid !== config.mcGuid
                    ? position.summaryHours?.deltaHours
                    : 0

        console.log( position )
        if ( delta === 0 ) { return null }

        return (
            <Value
                value={delta}
                format={( val ) =>
                    val === 0 ? '' : val < 0 ? '–' + Math.abs( val ) : '+' + val
                }
            />
        )
    }

    generate = ( rows, props, add ) => {
        const { helpers, day, data } = props,
              res = helpers.get.day( day, data )

        return rows.map( this.row( res, add ))
    }

    row = ( original, add ) => {
        return ( row ) => {
            const { helpers, from, to } = this.props,
                  { path, label, render, spoiler } = row,
                  value =
                    path === 'turnover'
                        ? original.originalTurnover
                        : // eslint-disable-next-line
                        eval("original." + path),
                  data = original.hours,
                  res = { label, value }

            add = add || ''

            if ( original.positionGuid === config.mcGuid ) {
                res.value = 0
            }

            res.key = res.label + add
            res.select = row.select
            original.positionGuid && ( res.positionGuid = original.positionGuid )
            render && ( res.as = render )
            spoiler && helpers.spoiler( path, res )
            res.hideCurrency = true

            res.__inner = !!add

            res.positionGuid !== config.mcGuid &&
            helpers.hours( this.hour( res, path, data ), from, to )

            return res
        }
    }

    hour = ( res, path, data ) => {
        return ( hour ) => ( res[ 'hour-' + hour ] = this.extract( data, hour, path ))
    }

    extract = ( data, hour, path ) => {
        const found = data.find(
            ( d ) => d.hour === hour || d.paidHours.hour === hour
        )

        return found
            ? // eslint-disable-next-line
            eval("found." + path)
            : null
    }

    position = ( res, props ) => {
        const pos = props.position,
              prow = format.copy.object( rows.delta )

        prow.label = 'По ' + pos.position
        prow.select = true

        res.push( this.row( pos )( prow ))
        res = res.concat( rows.paid.map( this.row( pos, 'Pos' )))

        return res
    }

    posSelect = ( res ) => {
        res.push({
            key:    'posselect',
            select: true,
            label:  'Выбрать позицию',
        })

        return res
    }

    data = ( props ) => {
        let res = []

        res = res.concat( this.generate( rows.def, props ))
        res = res.concat( this.generate([ rows.delta ], props ))
        props.open[ 'paidHours' + props.day + 'Day' ] &&
        ( res = res.concat( this.generate( rows.paid, props, 'Paid' )))

        res = props.position ? this.position( res, props ) : this.posSelect( res )

        return res
    }

    render () {
        const { helpers, workHours, day, from, to, max } = this.props,
              { positions, data } = this.state

        return (
            <ScheduleTableView
                positions={positions}
                data={data}
                loading={!this.props.data}
                helpers={helpers}
                workHours={workHours}
                day={day}
                from={from}
                to={to}
                max={max}
                onSelect={this.props.onSelect}
            />
        )
    }
}

export default ScheduleTable
