/* VENDOR */
import React from 'react'

/* APPLICATION */
import { Icon, IconActive, createIcon } from './Icon'

const svg = ( primary ) => (
    <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M30.08 21.7333L19.8533 4.77325C19.4398 4.12634 18.87 3.59395 18.1966 3.22517C17.5232 2.85639 16.7678 2.66309 16 2.66309C15.2322 2.66309 14.4768 2.85639 13.8033 3.22517C13.1299 3.59395 12.5602 4.12634 12.1466 4.77325L1.91997 21.7333C1.55871 22.3355 1.36236 23.0222 1.35065 23.7243C1.33895 24.4265 1.51231 25.1193 1.8533 25.7333C2.24754 26.4243 2.81818 26.9982 3.50691 27.3965C4.19563 27.7947 4.97774 28.003 5.7733 27.9999H26.2266C27.017 28.0084 27.7956 27.8082 28.4838 27.4196C29.1721 27.031 29.7456 26.4677 30.1466 25.7866C30.4976 25.1663 30.6765 24.4635 30.6648 23.7508C30.6531 23.0382 30.4512 22.3417 30.08 21.7333Z"
            fill={primary}
        />
        <path
            className="ignore-color"
            d="M16.0001 22.6667C16.7365 22.6667 17.3334 22.0697 17.3334 21.3333 17.3334 20.597 16.7365 20 16.0001 20 15.2637 20 14.6667 20.597 14.6667 21.3333 14.6667 22.0697 15.2637 22.6667 16.0001 22.6667zM16.0001 10.6667C15.6465 10.6667 15.3073 10.8072 15.0573 11.0573 14.8072 11.3073 14.6667 11.6465 14.6667 12.0001V17.3334C14.6667 17.687 14.8072 18.0262 15.0573 18.2762 15.3073 18.5263 15.6465 18.6667 16.0001 18.6667 16.3537 18.6667 16.6928 18.5263 16.9429 18.2762 17.1929 18.0262 17.3334 17.687 17.3334 17.3334V12.0001C17.3334 11.6465 17.1929 11.3073 16.9429 11.0573 16.6928 10.8072 16.3537 10.6667 16.0001 10.6667z"
            fill="#fff"
        />
    </svg>
)

class Danger extends Icon {
    render () {
        return svg( this.primary, this.secondary )
    }
}

class DangerActive extends IconActive {
    render () {
        return svg( this.primary, this.secondary )
    }
}

export default createIcon( Danger, DangerActive )
