/* VENDOR */
import React from 'react'

/* APPLICATION */
import { Icon, IconActive, createIcon } from './Icon'

const svg = ( primary ) => (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 6.49993C14.76 6.49993 17 8.73993 17 11.4999C17 12.0099 16.9 12.4999 16.76 12.9599L19.82 16.0199C21.21 14.7899 22.31 13.2499 23 11.4899C21.27 7.10993 17 3.99993 12 3.99993C10.73 3.99993 9.51 4.19993 8.36 4.56993L10.53 6.73993C11 6.59993 11.49 6.49993 12 6.49993ZM2.71 3.15993C2.32 3.54993 2.32 4.17993 2.71 4.56993L4.68 6.53993C3.06 7.82993 1.77 9.52993 1 11.4999C2.73 15.8899 7 18.9999 12 18.9999C13.52 18.9999 14.97 18.6999 16.31 18.1799L19.03 20.8999C19.42 21.2899 20.05 21.2899 20.44 20.8999C20.83 20.5099 20.83 19.8799 20.44 19.4899L4.13 3.15993C3.74 2.76993 3.1 2.76993 2.71 3.15993ZM12 16.4999C9.24 16.4999 7 14.2599 7 11.4999C7 10.7299 7.18 9.99993 7.49 9.35993L9.06 10.9299C9.03 11.1099 9 11.2999 9 11.4999C9 13.1599 10.34 14.4999 12 14.4999C12.2 14.4999 12.38 14.4699 12.57 14.4299L14.14 15.9999C13.49 16.3199 12.77 16.4999 12 16.4999ZM14.97 11.1699C14.82 9.76993 13.72 8.67993 12.33 8.52993L14.97 11.1699Z"
            fill={primary}
        />
    </svg>
)

class Invisible extends Icon {
    render () {
        return svg( this.primary, this.secondary )
    }
}

class InvisibleActive extends IconActive {
    render () {
        return svg( this.primary, this.secondary )
    }
}

export default createIcon( Invisible, InvisibleActive )
