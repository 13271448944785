/* VENDOR */
import { update } from 'reduxigen'
import moment     from 'moment'

/* APPLICATION */
import * as api   from './api'
import { format } from 'tools'
import config     from 'config'

const asyncUpdate = format.update.asyncu,
      helper = format.helper.basic( 'turnover' ),
      appendHelper = format.helper.append( 'turnover' )

function summaryHelper ( key, event, state ) {
    const data = event.data
    data.group = event.config.params.currentPredef
    return helper( key, data, state )
}

/* BASIC */

export const flushTurnover = update( 'turnover.flush', ( value, state ) => ({
    ...state,
    turnover: {},
}))

export const flushPreviousSummary = update(
    'turnover.flushPrevious',
    ( value, state ) => ({
        ...state,
        turnover: {
            ...state.turnover,
            previousShift: null,
        },
    })
)

/* REPORTS */

export const fetchReport = asyncUpdate(
    'turnover.report',
    ( params ) => api.getReport( params ),
    ( event, state ) => helper( 'report', event.data, state )
)

export const appendReport = asyncUpdate(
    'turnover.append',
    ( params ) => api.getReport( params ),
    ( event, state ) => appendHelper( 'report', event, state )
)

export const fetchAverageCheck = asyncUpdate(
    'turnover.average',
    ( params ) => api.getAverageCheck( params ),
    ( event, state ) => helper( 'average', event.data, state )
)

export const appendAverageCheck = asyncUpdate(
    'turnover.appendaverage',
    ( params ) => api.getAverageCheck( params ),
    ( event, state ) => appendHelper( 'average', event, state )
)

/* SUMMARY */

export const fetchPreviousSummary = asyncUpdate(
    'turnover.previousShift',
    ( params ) => api.getSummary( params, 'previous' ),

    ( event, state ) => summaryHelper( 'previousShift', event, state )
)

export const fetchSummary = asyncUpdate(
    'turnover.summary',
    // eslint-disable-next-line no-unused-vars
    ({ restaurants, ...params }) => {
        return api.getSummary( params )
    },
    ( event, state ) => summaryHelper( 'summary', event, state )
)

export const fetchDetails = asyncUpdate(
    'turnover.details',
    // eslint-disable-next-line no-unused-vars
    ({ workHours, restaurants, timeZoneName, shiftTimeStart, shiftTimeEnd, ...params }) => {

        const np = format.copy.object( params ),
              predef = format.dates.getNextHierarchy( params.currentPredef ),
              data = format.dates.getGroup( params.currentPredef, workHours, null, timeZoneName )
        np.dateStart =
            np.currentPredef !== 'shift'
                ? format.dates.fixDateOffset(
                    data.start.format( config.format.date ),
                    np.factsNumber,
                    restaurants
                )
                : data.start.format( config.format.date )


        np.dateEnd = data.end
            ? data.end.format( config.format.date )
            : moment().format( config.format.date )

        np.dateGroup = data.group
        np.currentPredef = predef

        const predefMapping = {
            'shift': { start: shiftTimeStart, end: shiftTimeEnd },
            'hour':  { start: params.currentHourDateStart, end: params.currentHourDateEnd },
            'week':  { start: params.weekTimeStart, end: params.weekTimeEnd },
            'day':   { start: params.dayTimeStart, end: params.dayTimeEnd }
        }

        const currentPredef = predefMapping[ np.currentPredef ]

        if ( currentPredef ) {
            if ( moment( currentPredef.start ).isSame( currentPredef.end ) || moment( currentPredef.start ).isAfter( currentPredef.end )) {
                currentPredef.end = moment( currentPredef.start ).add( 1, 'days' ).format( config.format.date )
            }

            np.dateStart = currentPredef.start || data.start.format( config.format.date )
            np.dateEnd = currentPredef.end || data.end.format( config.format.date )
        }

        delete np.currentHourDateStart
        delete np.currentHourDateEnd
        delete np.weekTimeStart
        delete np.weekTimeEnd
        delete np.dayTimeStart
        delete np.dayTimeEnd

        return api.getSummary( np, 'details' )
    },
    ( event, state ) => summaryHelper( 'details', event, state )
)

export const fetchTOGoals = asyncUpdate(
    'turnover.goals',
    ( params ) => api.getGoals( params ),
    ( event, state ) => summaryHelper( 'goals', event, state )
)

/* CHARTS */

export const fetchPlanAndFact = asyncUpdate(
    'turnover.planAndFact',
    ( params ) => api.getPlanAndFact( params ),
    ( event, state ) =>
        helper( 'planAndFact', {
            plan: format.extract.graph( event.data.data, 'time', 'planTurnover' ),
            fact: format.extract.graph( event.data.data, 'time', 'factTurnover' ),
        }, state )
)
