/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Row, Col, Select } from 'antd'

/* APPLICATION */
import { Report, Pager } from 'components'
import { format }        from 'tools'
import config            from 'config'

import './time-rating.scss'

class TimeRating extends Component {
    static propTypes = {
        fusion: PropTypes.object,
        hybrid: PropTypes.object,
        auto:   PropTypes.object,
        data:   PropTypes.object,

        partner:  PropTypes.any,
        partners: PropTypes.array,

        onSelect:   PropTypes.func,
        onPartner:  PropTypes.func,
        onControls: PropTypes.func,
        onMore:     PropTypes.func,
        onAppend:   PropTypes.func,
    }

    prepare = ( record, index, columns ) =>
        format.goodBad.iterate.goodBad( record, [ 'deltaServiceTime' ], columns, true )

    head = ( data ) =>
        data &&
    data.all &&
    format.strings.seconds( data.all.factServiceTime ) +
      ' / ' +
      format.strings.seconds( data.all.planServiceTime ) +
      ' ' +
      ( data.all.hit || 0 ) +
      '%'

    options = ( items ) =>
        items.map(( item ) => (
            <Select.Option value={item.value} key={item.value}>
                {item.label}
            </Select.Option>
        ))

    render () {
        const {
            fusion,
            hybrid,
            auto,
            partner,
            areaCoach,
            facilityType,
            city,
            regionCoach,
            marketCoach,
            factsNumbers,
            partners,
            facilityTypes,
            cities,
            regionCoaches,
            marketCoaches,
            areaCoaches,
            restaurants,
            onPartner,
            onFilter,
            onFocus,
        } = this.props,
              loaded = !!( fusion?.report && hybrid?.report && auto?.report )

        const hybridData = hybrid && hybrid.all ? hybrid.report === undefined ? [] : hybrid.report : null
        const fusionData = fusion && fusion.all ? fusion.report === undefined ? [] : fusion.report : null
        const autoData = auto && auto.all ? auto.report === undefined ? [] : auto.report : null

        return (
            <div className="rating-inner rating-time rating-performance">
                <Pager
                    onMore={this.props.onMore}
                    check={{
                        last:
              !loaded ||
              ( fusion.report?.last && hybrid.report?.last && auto.report?.last ),
                        number: Math.max(
                            fusion.report?.number,
                            hybrid.report?.number,
                            auto.report?.number
                        ),
                    }}
                >
                    <div className="rating-performance__filters">
                        <Select
                            placeholder="Партнеры"
                            mode='multiple'
                            maxTagCount={1}
                            value={partner}
                            onChange={onPartner}
                            onFocus={onFocus}
                            style={{ width: '100%', maxWidth: 250 }}
                            getPopupContainer={( trigger ) => trigger.parentNode}
                            disabled={!partners.length}
                            filterOption={( input, option ) =>
                                option.props.children.toLowerCase().indexOf( input.toLowerCase()) >= 0
                            }
                            allowClear
                        >
                            {this.options([
                                ...( partners || []),
                            ])}
                        </Select>
                        <Select
                            placeholder="Типы ресторанов"
                            mode='multiple'
                            maxTagCount={1}
                            style={{ width: 500 }}
                            value={ facilityType }
                            onFocus={ onFocus }
                            onChange={( value ) => onFilter({ value, name: 'facilityTypes' })}
                            getPopupContainer={( trigger ) => trigger.parentNode}
                            disabled={!facilityTypes.length}
                            filterOption={( input, option ) =>
                                option.props.children.toLowerCase().indexOf( input.toLowerCase()) >= 0
                            }
                            allowClear
                        >
                            {this.options([
                                ...( facilityTypes || []),
                            ])}
                        </Select>
                        <Select
                            placeholder="Города"
                            mode='multiple'
                            maxTagCount={1}
                            value={city}
                            style={{ width: 500 }}
                            onChange={( value ) => onFilter({ value, name: 'cities' })}
                            onFocus={onFocus}
                            getPopupContainer={( trigger ) => trigger.parentNode}
                            disabled={!cities.length}
                            allowClear
                        >
                            {this.options([
                                ...( cities || []),
                            ])}
                        </Select>
                        <Select
                            placeholder="Region coaches"
                            mode='multiple'
                            maxTagCount={1}
                            style={{ width: 500 }}
                            value={regionCoach}
                            onFocus={onFocus}
                            onChange={( value ) => onFilter({ value, name: 'regionCoaches' })}
                            getPopupContainer={( trigger ) => trigger.parentNode}
                            disabled={!regionCoaches.length}
                            filterOption={( input, option ) =>
                                option.props.children.toLowerCase().indexOf( input.toLowerCase()) >= 0
                            }
                            allowClear
                        >
                            {this.options([
                                ...( regionCoaches || []),
                            ])}
                        </Select>
                        <Select
                            placeholder="Market coaches"
                            mode='multiple'
                            maxTagCount={1}
                            style={{ width: 500 }}
                            value={marketCoach}
                            onChange={( value ) => onFilter({ value, name: 'marketCoaches' })}
                            onFocus={onFocus}
                            getPopupContainer={( trigger ) => trigger.parentNode}
                            disabled={!marketCoaches.length}
                            filterOption={( input, option ) =>
                                option.props.children.toLowerCase().indexOf( input.toLowerCase()) >= 0
                            }
                            allowClear
                        >
                            {this.options([
                                ...( marketCoaches || []),
                            ])}
                        </Select>
                        <Select
                            placeholder="Area coaches"
                            mode='multiple'
                            maxTagCount={1}
                            style={{ width: 600 }}
                            value={areaCoach}
                            onChange={( value ) => onFilter({ value, name: 'areaCoaches' })}
                            onFocus={onFocus}
                            getPopupContainer={( trigger ) => trigger.parentNode}
                            disabled={!areaCoaches.length}
                            filterOption={( input, option ) =>
                                option.props.children.toLowerCase().indexOf( input.toLowerCase()) >= 0
                            }
                            allowClear
                        >
                            {this.options([
                                ...( areaCoaches || []),
                            ])}
                        </Select>
                        <Select
                            placeholder="Рестораны"
                            maxTagCount={1}
                            mode='multiple'
                            style={{ width: 700 }}
                            value={factsNumbers}
                            onChange={( value ) => onFilter({ value, name: 'factsNumbers' })}
                            onFocus={onFocus}
                            getPopupContainer={( trigger ) => trigger.parentNode}
                            disabled={!restaurants.length}
                            filterOption={( input, option ) =>
                                option.props.children.toLowerCase().indexOf( input.toLowerCase()) >= 0
                            }
                            allowClear
                        >
                            {this.options([
                                ...( restaurants || []),
                            ])}
                        </Select>
                    </div>

                    <Row>
                        <Col span={7}>
                            <h2>
                                <span>Fusion в среднем</span> <span>{this.head( fusion )}</span>
                            </h2>
                            <Report
                                rowKey="restaurantName"
                                data={fusionData}
                                columns={config.tables.restaurants.time}
                                prepare={this.prepare}
                                onChange={this.onChange}
                            />
                        </Col>

                        <Col span={1} />

                        <Col span={7}>
                            <h2>
                                <span>Hybrid в среднем</span> <span>{this.head( hybrid )}</span>
                            </h2>
                            <Report
                                rowKey="restaurantName"
                                data={hybridData}
                                columns={config.tables.restaurants.time}
                                prepare={this.prepare}
                                onChange={this.onChange}
                            />
                        </Col>

                        <Col span={1} />

                        <Col span={7}>
                            <h2>
                                <span>Auto в среднем</span> <span>{this.head( auto )}</span>
                            </h2>
                            <Report
                                rowKey="restaurantName"
                                data={autoData}
                                columns={config.tables.restaurants.time}
                                prepare={this.prepare}
                                onChange={this.onChange}
                            />
                        </Col>
                    </Row>
                </Pager>
            </div>
        )
    }
}

export default TimeRating
