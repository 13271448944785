/* VENDOR */
import React, { Component }                  from 'react'
import PropTypes                             from 'prop-types'
import { Row, Col, Input, Select, Checkbox } from 'antd'

/* APPLICATION */
import { format } from 'tools'
import './admin-staff-controls.scss'

const { Search } = Input,
      { Option } = Select

class AdminStaffControls extends Component {
    static propTypes = {
        role:     PropTypes.string,
        active:   PropTypes.bool,
        admin:    PropTypes.bool,
        planning: PropTypes.bool,

        restaurants: PropTypes.array,
        roles:       PropTypes.object,

        onListRestaurants: PropTypes.func,

        onSearch:     PropTypes.func,
        onRestaurant: PropTypes.func,
        onRole:       PropTypes.func,
        onActive:     PropTypes.func,
        onAdmin:      PropTypes.func,
        onPlan:       PropTypes.func,
    }

    option = ( opt ) => (
        <Option value={opt.value} key={opt.value}>
            {opt.label}
        </Option>
    )

    options = ( data, keys, vals, allLabel ) =>
        ( data
            ? vals
                ? [
                        { value: 'all', label: allLabel },
                        ...format.generate.options( data, keys, vals ),
                    ]
                : [ { value: 'all', label: keys }, ...format.generate.keyvalrs( data ) ]
            : [ { value: 'all', label: allLabel } ]
        ).map( this.option )

    search = ( e ) => this.props.onSearch( e.target.value )

    searchRestaurants = ( str ) => this.props.onListRestaurants( str )
    flushRestaurants = ( state ) => !state && this.props.onListRestaurants( '' )

    toggle = ( cb ) => {
        return ( e ) => cb( e.target.checked )
    }

    render () {
        const { restaurants, roles } = this.props,
              { search, restaurant, role, active, admin, planning, stopSyncUntil } =
        this.props,
              { onRestaurant, onRole, onActive, onAdmin, onPlan, onSync } = this.props

        return (
            <div className="admin-staff-controls view-type-switch">
                <Row>
                    <Col span={8}>
                        <div className="filter-container">
                            <Search
                                placeholder="Имя или ID сотрудника"
                                value={search}
                                onChange={this.search}
                            />
                        </div>
                    </Col>
                    <Col span={8}>
                        <Select
                            placeholder="Роль"
                            notFoundContent="Ничего не найдено"
                            getPopupContainer={( trigger ) => trigger.parentNode}
                            value={role}
                            byKey={true}
                            onChange={onRole}
                        >
                            {this.options( roles, 'Любая роль' )}
                        </Select>
                    </Col>
                    <Col span={8}>
                        <Select
                            showSearch
                            value={restaurant}
                            byKey={true}
                            placeholder="Ресторан"
                            notFoundContent="Ничего не найдено"
                            filterOption={() => true}
                            onChange={onRestaurant}
                            onSearch={this.searchRestaurants}
                            onDropdownVisibleChange={this.flushRestaurants}
                            getPopupContainer={( trigger ) => trigger.parentNode}
                        >
                            {this.options(
                                restaurants,
                                'factsNumber',
                                'restaurantName',
                                'Все рестораны'
                            )}
                        </Select>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <div className="inline-row">
                            <Checkbox checked={active} onChange={this.toggle( onActive )}>
                Действующие
                            </Checkbox>
                            <Checkbox checked={admin} onChange={this.toggle( onAdmin )}>
                Админы
                            </Checkbox>
                            <Checkbox checked={planning} onChange={this.toggle( onPlan )}>
                Планировщики
                            </Checkbox>
                            <Checkbox checked={stopSyncUntil} onChange={this.toggle( onSync )}>
                Не синхронизируются
                            </Checkbox>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default AdminStaffControls
