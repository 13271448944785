import column from './helpers'
import shared from './shared'

export default {
    serviceTime: {
        fusion: ( request ) => [
            shared.label( request ),

            column( 'Приём заказа (без киосков)', 'order', {
                sorter:  'bydate',
                format:  'seconds',
                cell:    [ 'cellclass' ],
                as:      'with_time',
                colSpan: 2,
            }),

            column( '', 'orderHit', {
                as:      'hitPercentage',
                colSpan: 0,
            }),

            column( 'Оплата (без киосков)', 'payment', {
                sorter:  'bydate',
                format:  'seconds',
                cell:    [ 'cellclass' ],
                as:      'with_time',
                colSpan: 2,
            }),

            column( '', 'paymentHit', {
                as:      'hitPercentage',
                colSpan: 0,
            }),

            column( 'Сбор и выдача', 'handling', {
                sorter:  'bydate',
                format:  'seconds',
                cell:    [ 'cellclass' ],
                as:      'with_time',
                colSpan: 2,
            }),

            column( '', 'handlingHit', {
                as:      'hitPercentage',
                colSpan: 0,
            }),

            column( 'Сбор', 'assembly', {
                sorter:  'bydate',
                format:  'seconds',
                cell:    [ 'cellclass' ],
                as:      'with_time',
                colSpan: 2,
            }),

            column( '', 'assemblyHit', {
                as:      'hitPercentage',
                colSpan: 0,
            }),

            column( 'Выдача', 'giveaway', {
                sorter:  'bydate',
                format:  'seconds',
                cell:    [ 'cellclass' ],
                as:      'with_time',
                colSpan: 2,
            }),

            column( '', 'giveawayHit', {
                as:      'hitPercentage',
                colSpan: 0,
            }),

            column( 'Закрытых заказов', 'transactions', { sorter: 'bydate', }),
        ],

        hybrid: ( request ) => [
            shared.label( request ),

            column( 'Общее время (киоск)', 'handlingKiosk', {
                sorter:  'bydate',
                format:  'seconds',
                cell:    [ 'cellclass' ],
                as:      'with_time',
                colSpan: 2,
            }),

            column( '', 'handlingKioskHit', {
                as:      'hitPercentage',
                colSpan: 0,
            }),

            column( 'Сбор (киоск)', 'assemblyKiosk', {
                sorter:  'bydate',
                format:  'seconds',
                cell:    [ 'cellclass' ],
                as:      'with_time',
                colSpan: 2,
            }),

            column( '', 'assemblyKioskHit', {
                as:      'hitPercentage',
                colSpan: 0,
            }),

            column( 'Выдача (киоск)', 'giveawayKiosk', {
                sorter:  'bydate',
                format:  'seconds',
                cell:    [ 'cellclass' ],
                as:      'with_time',
                colSpan: 2,
            }),

            column( '', 'giveawayKioskHit', {
                as:      'hitPercentage',
                colSpan: 0,
            }),

            column( 'Общее время', 'handling', {
                sorter:  'bydate',
                format:  'seconds',
                cell:    [ 'cellclass' ],
                as:      'with_time',
                colSpan: 2,
            }),

            column( '', 'handlingHit', {
                as:      'hitPercentage',
                colSpan: 0,
            }),

            column( 'Прием / Оплата (Касса)', 'payment', {
                sorter:  'bydate',
                format:  'seconds',
                cell:    [ 'cellclass' ],
                as:      'with_time',
                colSpan: 2,
            }),

            column( '', 'paymentHit', {
                as:      'hitPercentage',
                colSpan: 0,
            }),

            column( 'Закрытых заказов', 'transactions', { sorter: 'bydate', }),
        ],

        auto: ( request ) => [
            shared.label( request ),

            column( 'Общее время', 'total', {
                sorter:  'bydate',
                format:  'seconds',
                cell:    [ 'cellclass' ],
                as:      'with_time',
                colSpan: 2,
            }),

            column( '', 'totalHit', {
                as:      'hitPercentage',
                colSpan: 0,
            }),

            column( 'Приём заказа', 'order', {
                sorter:  'bydate',
                format:  'seconds',
                cell:    [ 'cellclass' ],
                as:      'with_time',
                colSpan: 2,
            }),

            column( '', 'orderHit', {
                as:      'hitPercentage',
                colSpan: 0,
            }),

            column( 'Оплата', 'payment', {
                sorter:  'bydate',
                format:  'seconds',
                cell:    [ 'cellclass' ],
                as:      'with_time',
                colSpan: 2,
            }),

            column( '', 'paymentHit', {
                as:      'hitPercentage',
                colSpan: 0,
            }),

            column( 'Сбор/выдача', 'handling', {
                sorter:  'bydate',
                format:  'seconds',
                cell:    [ 'cellclass' ],
                as:      'with_time',
                colSpan: 2,
            }),

            column( '', 'handlingHit', {
                as:      'hitPercentage',
                colSpan: 0,
            }),

            column( 'Закрытых заказов', 'transactions', { sorter: 'bydate', }),
        ],
    },
}
