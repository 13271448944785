/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Modal }            from 'antd'

/* APPLICATION */

class DiscardChanges extends Component {
    static propTypes = {
        onSubmit: PropTypes.func,
        onCancel: PropTypes.func,

        visible: PropTypes.bool,
        title:   PropTypes.string.isRequired,
        ok:      PropTypes.string.isRequired,
    }

    render () {
        return (
            <Modal
                className="kfc-popup"
                centered={true}
                open={this.props.visible}
                title={this.props.title}
                okText={this.props.ok}
                okButtonProps={{ className: 'wide-btn' }}
                onOk={this.props.onSubmit}
                cancelButtonProps={{ style: { display: 'none' } }}
                onCancel={this.props.onCancel}
            >
        Все изменения потеряются.
            </Modal>
        )
    }
}

export default DiscardChanges
