/* VENDOR */
import React, { Component } from 'react'
import { Spin }             from 'antd'

/* APLICATION */
import { Center } from 'components'

class Spinner extends Component {
    render () {
        return (
            <Center className={this.props.className}>
                <Spin size={this.props.size} />
            </Center>
        )
    }
}

export default Spinner
