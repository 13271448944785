import React, { useRef } from 'react'
import classNames        from 'classnames'
import {
    PprTable,
    EditAcCommentModal,
    Icons
} from 'components'
import { Button } from 'antd'
import Icon from '@ant-design/icons'
import { connect }      from 'react-redux'
import { uiActions }    from 'services'
import { format }       from 'tools'
import './ppr-area-restaurant-info.scss'

const filters = {
    rgm:     ( item ) => item?.position?.name.toLowerCase() === 'rgm',
    argm:    ( item ) => item?.position?.name.toLowerCase() === 'argm',
    sm:      ( item ) => item?.position?.name.toLowerCase() === 'sm',
    bench:   ( item ) => item.isBench,
    noBench: ( item ) => !item.isBench,
    smIt:    ( item ) => item?.position?.name.toLowerCase() === 'sm it',
}

const PprAreaRestaurantInfo = ( props ) => {
    const editCommentModalRef = useRef( null )

    const rowsCount = Object.fromEntries(
        Object.keys( filters ).map( key => [ key, props.restaurants ? Math.max(
            ...( props.restaurants || []).map(
                ( res ) => key === 'bench' ?
                    res.managers.filter( filters.bench ).length
                    :
                    res.managers.filter( filters[ key ]).filter( filters.noBench )
                        .length
            )
        ) : 0
        ])
    )

    const renderStatusRows = ( status ) => {
        const countRows = rowsCount[ status ],
              isUnionStatusRow = [ 'rgm', 'argm', 'sm' ].some(
                  ( st ) => status.toLowerCase() === st
              ),
              statusCol =
                status === 'bench'
                    ? 'BENCH менеджеров'
                    : 'SM IT Кандидаты и тп',
              rowSpan =
                status === 'bench' ? rowsCount[ 'bench' ] * 2 : rowsCount[ 'smIt' ] * 2

        return [ ...Array( countRows ) ].map(( _, indexRow ) => {
            return (
                <React.Fragment key={`rgm_row_${indexRow}`}>
                    <tr
                        className={classNames(
                            'manager-row first',
                            !isUnionStatusRow && 'union-row'
                        )}
                    >
                        {isUnionStatusRow && (
                            <td className="status-top bold">
                                {status.toUpperCase()} {format.number.convertToRoman( indexRow + 1 )}
                            </td>
                        )}
                        {!isUnionStatusRow && indexRow === 0 && (
                            <td rowSpan={rowSpan} className="bold">
                                {statusCol}
                            </td>
                        )}
                        {props.restaurants?.map(( res, indexRes ) => {
                            const manager = res.managers
                                .filter( filters[ status ])
                                .filter( status === 'bench' ? () => true : filters.noBench )[ indexRow ]
                            return (
                                <React.Fragment
                                    key={`rgm_${indexRow}_${indexRes}_1`}
                                >
                                    <td
                                        colSpan={2}
                                        className="manager-field name bold"
                                    >
                                        {manager?.managerSurnameName}
                                    </td>
                                    <th className="manager-field rcl">
                                        {manager?.rclCode?.name}
                                    </th>
                                </React.Fragment>
                            )
                        })}
                    </tr>
                    <tr
                        className={classNames(
                            'manager-row last',
                            !isUnionStatusRow && 'union-row'
                        )}
                    >
                        {isUnionStatusRow && (
                            <th className="status-bottom">Статус</th>
                        )}
                        {props.restaurants.map(( res, indexRes ) => {
                            const manager = res.managers
                                .filter( filters[ status ])
                                .filter( status === 'bench' ? () => true : filters.noBench )[ indexRow ]
                            return (
                                <React.Fragment
                                    key={`rgm_${indexRow}_${indexRes}_2`}
                                >
                                    <th className="manager-field position">
                                        {manager?.positionStatus?.name}
                                    </th>
                                    <th
                                        className="manager-field comment"
                                        colSpan={2}
                                    >
                                        {manager && <Button
                                            type="link"
                                            onClick={() => editCommentModalRef.current?.open( manager, res.factsNumber )}
                                        >
                                            {manager?.acComment ?
                                                    <Icon component={Icons.CommentPpr.def} />
                                                :
                                                    <Icon component={Icons.CommentPlusPpr.def} />
                                            }
                                        </Button>}
                                    </th>
                                </React.Fragment>
                            )
                        })}
                    </tr>
                </React.Fragment>
            )
        })
    }

    const emptyRow = () => (
        <tr>
            <td colSpan={( props.restaurants?.length ?? 0 ) * 3 + 1}></td>
        </tr>
    )

    if ( !props.restaurants ) { return null }

    return (
        <>
            <PprTable
                customStyle
                className="ppr-area-restaurant-info"
                showScroll
            >
                <thead>
                    <tr>
                        <th>Ресторан</th>
                        {props.restaurants?.map(({ factsNumber, restaurantName }) => (
                            <React.Fragment key={`name_${factsNumber}`}>
                                <td colSpan={3} className="restaurant-name">
                                    {restaurantName}
                                    <span className="restaurant-id">
                                        ({factsNumber})
                                    </span>
                                </td>
                            </React.Fragment>
                        ))}
                    </tr>
                    <tr className="structure-row">
                        <th rowSpan={2}>Структура менеджеров</th>
                        {props.restaurants?.map(( item ) => (
                            <React.Fragment
                                key={`structure_${item.factsNumber}_1`}
                            >
                                <td>RGM</td>
                                <td>ARGM</td>
                                <td>SM</td>
                            </React.Fragment>
                        ))}
                    </tr>
                    <tr className="structure-row">
                        {props.restaurants?.map(( item ) => (
                            <React.Fragment
                                key={`structure_${item.factsNumber}_2`}
                            >
                                <td>{item.restaurantStructureRgmCount}</td>
                                <td>{item.restaurantStructureArgmCount}</td>
                                <td>{item.restaurantStructureSmCount}</td>
                            </React.Fragment>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {Object.keys( filters ).filter( item => item !== 'noBench' ).map(( status ) => {
                        return <React.Fragment key={`area-rest-info-${status}`}>
                            {renderStatusRows( status )}
                            {rowsCount[ status ] > 0 && emptyRow()}
                        </React.Fragment>
                    })}
                </tbody>
            </PprTable>
            <EditAcCommentModal
                ref={editCommentModalRef}
                addServerError={props.addServerError}
                updateAcComment={props.updateAcComment}
            />
        </>
    )
}

export default connect(() => ({}), uiActions )( PprAreaRestaurantInfo )
