import moment from 'moment'

export const month = ( deadline, date, isWeekly ) =>
    isWeekly
        ? ( deadline.isAfter( moment( date ).startOf( 'month' )) ||
        deadline.isSame( moment( date ).startOf( 'month' ), 'day' )) &&
      ( deadline.isBefore( moment( date ).endOf( 'month' ).endOf( 'week' )) ||
        deadline.isSame( moment( date ).endOf( 'month ' ).endOf( 'week' ), 'day' ))
        : ( deadline.isAfter( moment( date ).startOf( 'month' )) ||
        deadline.isSame( moment( date ).startOf( 'month' ), 'day' )) &&
      ( deadline.isBefore( moment( date ).endOf( 'month' )) ||
        deadline.isSame( moment( date ).endOf( 'month ' ), 'day' ))

export const week = ( deadline, date ) =>
    ( deadline.isAfter( date ) || deadline.isSame( date, 'day' )) &&
  ( deadline.isBefore( moment( date ).endOf( 'week' )) ||
    deadline.isSame( moment( date ).endOf( 'week ' ), 'day' ))

export const moreThanWeekly = ( ev, view, date ) => {
    if ( ev.groupName.indexOf( 'Weekly' ) > -1 || ev.groupName.indexOf( 'Daily' ) > -1 ) { return false }

    if ( view === 'week' ) {
        return (
            moment( ev.deadline ).isSame( moment( date ), 'month' ) ||
      moment( ev.deadline ).isSame( moment( date ).endOf( 'week' ), 'month' )
        )
    }

    return moment( ev.deadline ).isSame( moment( date ), 'month' )
}

export const outOfEvents = ( date, selected, today ) =>
    !selected &&
  date.isBefore( moment( today ).startOf( 'month' )) &&
  moment( date ).endOf( 'week' ).isBefore( moment( today ).startOf( 'month' ))
