import { Col }      from 'antd'
import React        from 'react'
import { PprTable } from 'components'
import config       from 'config'
import './ppr-line-staff-structure.scss'

const employeePositionStatuses = {
    universal:   'ТМ унив.',
    hall:        'Зал',
    service:     'Обсл.',
    kitchen:     'Кухн.',
    chick:       'Цыпл.',
    auto:        'Авто.',
    scout:       'Скаут',
    brandChef:   'Бренд-шеф',
    klikun:      'Кликун',
    hostess:     'Хостес',
    instChick:   'Инст. Цыпл.',
    instKitchen: 'Инст. Кухн.',
    instService: 'Инст. Обсл.'
}

const PprLineStaffStructure = ({ data, loading, error, onRetry }) => {
    return (
        <Col xs={7} sm={7} md={7} lg={7} xl={6} xxl={6}>
            <PprTable
                vertical
                loading={loading}
                error={error}
                onRetry={() => onRetry( 'employees' )}
                className='ppr-line-staff-structure'
            >
                <thead>
                    <tr>
                        <th>Позиция</th>
                        <th>Факт</th>
                        <th>%</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries( employeePositionStatuses ).map(([ key, status ]) => <tr key={`line_staff_${key}`}>
                        <td>{status}</td>
                        <td>{data?.[ `${key}Fact` ] ?? config.ui.nullSymbol}</td>
                        <td>{data?.[ `${key}Relative` ] ?? config.ui.nullSymbol}%</td>
                    </tr> )}
                </tbody>
            </PprTable>
        </Col>
    )
}

export default PprLineStaffStructure
