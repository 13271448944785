/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import moment               from 'moment'
import { Modal }      from 'antd'
import Icon from '@ant-design/icons'

/* APPLICATION */
import {
    AddButton,
    ReportTable,
    SyncScroll,
    AddEmployeeShift,
} from 'components'

import { StarIcon } from 'components/layout/TopStar/TopStar'

import { format } from 'tools'
import config     from 'config'

import './schedule-chart.scss'

class ScheduleChartView extends Component {
    static propTypes = {
        data:      PropTypes.array,
        positions: PropTypes.array,
        employees: PropTypes.array,
        helpers:   PropTypes.object,
        workHours: PropTypes.object,

        loading:   PropTypes.bool,
        compact:   PropTypes.bool,
        fixedHead: PropTypes.bool,

        day:  PropTypes.string,
        date: PropTypes.object,
        from: PropTypes.object,
        to:   PropTypes.object,

        onAdd:    PropTypes.func,
        onChange: PropTypes.func,
        onRemove: PropTypes.func,
        onTop:    PropTypes.func,
    }

    view = React.createRef()
    sync = React.createRef()

    constructor ( props ) {
        super( props )
        this.state = {
            data: props.data,

            popup:      false,
            unassign:   false,
            toUnassign: null,

            columns:   [],
            employees: [],
            positions: [],
        }
    }

    componentDidMount () {
        this.setColumns( this.props )
        this.setEmployees( this.props.employees )
        this.setPositions( this.props.positions )

    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { data, employees, positions } = this.props,
              useScroll = !this.props.data && this.props.date.isSame( nextProps.date )

        if ( nextProps.data !== data ) {
            this.update( nextProps.data, useScroll )
            this.setColumns( nextProps )
        }

        if ( nextProps.employees !== employees ) {
            this.setEmployees( nextProps.employees )
        }

        if ( nextProps.positions !== positions ) {
            this.setPositions( nextProps.positions )
        }

    }

    componentDidUpdate () {
        window.dispatchEvent( new Event( 'resize' ))
    }

    setColumns = ( props ) =>
        this.setState({ columns: this.columns( props ), })

    setEmployees = ( data ) =>
        this.setState({ employees: this.employees( data ), })

    setPositions = ( data ) =>
        this.setState({ positions: this.positions( data ), })

    update = ( data, useScroll ) =>
        this.setState({ data }, () => {
            const { workHours, day } = this.props,
                  view = this.view.current,
                  scroll = view.querySelector( '.ant-table-body' ),
                  hours = [].slice.call( view.querySelectorAll( '.ant-table-scroll th' )),
                  cday = workHours.days.find(( d ) => d.dayOfWeek === parseInt( day )),
                  start = cday.timeStart,
                  end = cday.timeEnd,
                  found = hours.reduce(
                      ( found, h ) => ( h.innerText === start ? h : found ),
                      hours[ 0 ]
                  )

            let started = false,
                previous = 0

            hours.forEach(( h ) => {
                h.innerText === start && ( started = true )
                h.style.fontWeight = started ? 'bold' : 'normal'
                h.innerText === end && ( started = false )
            })

            if ( useScroll && found ) {
                found.scrollIntoView({
                    behavior: 'smooth',
                    block:    'nearest',
                    inline:   'center',
                })

                const sync = () => {
                    const current = scroll.scrollLeft

                    if ( current !== previous ) {
                        previous = current
                        setTimeout( sync, 100 )
                    } else {
                        this.sync.current &&
              this.sync.current.scroll({ target: scroll, })
                    }
                }

                setTimeout( sync, 500 )
            }
        })

    showPopup = () =>
        this.setState({ popup: true, })

    showUnassign = ( row ) =>
        this.setState({
            unassign:   true,
            toUnassign: row,
        })

    hidePopup = () =>
        this.setState({
            popup:    false,
            unassign: false,
        })

    defCols = ( props ) =>
        props.compact
            ? config.tables.schedule.mobile
            : config.tables.schedule.chart

    canEdit = ( props ) =>
        moment( props.date ).isAfter( moment().startOf( 'day' )) ||
    moment( props.date ).isSame( moment().startOf( 'day' ))

    columns = ( props ) => {
        const { helpers, from, to, compact, date } = props,
              def = format.copy.array( this.defCols( props )),
              start = def.find(( c ) => c.dataIndex === 'shiftStart' ),
              end = def.find(( c ) => c.dataIndex === 'shiftEnd' )

        start.min = end.min = from.hours()
        start.max = end.max = to.hours()

        if ( !compact ) {
            const editable = this.canEdit( props )

            def.forEach(( c ) => ( c.editable = editable ))

            def[ 0 ].className = 'first-star'
            def[ 0 ].render = ( text, record ) => {
                if ( !record.employeeId ) { return null }
                if ( record.positionGuid !== config.mcGuid ) { return null }

                if (
                    ( !def[ 0 ].editable || record.disabled === true ) &&
          record.shiftSupervisor
                ) { return <Icon component={StarIcon} className={'active'} /> }

                if ( def[ 0 ].editable && !record.disabled ) {
                    if (
                        moment( date ).isAfter( moment()) ||
            moment( moment( record.shiftStart )).isAfter( moment())
                    ) {
                        return (
                            <Icon
                                component={StarIcon}
                                className={record.shiftSupervisor ? 'active' : ''}
                                onClick={this.setSupervisor( record )}
                            />
                        )
                    }

                    return record.shiftSupervisor ? (
                        <Icon component={StarIcon} className={'active'} />
                    ) : null
                }

                return null
            }
        }

        return [ ...def, ...helpers.hourscols( from, to ) ]
    }

    setSupervisor = ( row ) => {
        return ( e ) => {
            e.preventDefault()
            row.shiftSupervisor && row._was_supervisor
                ? this.showUnassign( row )
                : this.props.onChange( row, 'shiftSupervisor', !row.shiftSupervisor )
        }
    }

    unassign = () => {
        this.props.onChange( this.state.toUnassign, 'shiftSupervisor', false )
        this.hidePopup()
    }

    positions = ( data ) =>
        format.generate.options( data, 'positionGuid', 'position' )


    employees = ( data ) => format.generate.options( data, 'id', 'name' )

    add = ( data ) => {
        this.props.onAdd( data )
        this.hidePopup()
    }

    scroll = () => {
        const table = document.querySelector( '.staff-schedule-chart .report-wrap' ),
              { helpers, from, to, compact } = this.props,
              res = { x: helpers.width( from, to, compact ) }

        if ( this.props.fixedHead && table ) {
            res.y = table.offsetHeight
        }

        return res
    }

    cellSearch = () => ( search, opt ) =>
        format.find.strInProp( opt.props, 'children', search )

    render () {
        const { data, loading, date } = this.props,
              { columns, popup, employees, positions, unassign } = this.state

        return (
            <div className="staff-schedule-chart report-table" ref={this.view}>
                <ReportTable
                    rowKey="guid"
                    data={format.generate.noPager( this.state.data )}
                    columns={columns}
                    select={{
                        employees: employees,
                        positions: positions,
                    }}
                    scroll={this.scroll()}
                    loading={!data || loading}
                    isToday={date.isSame( moment(), 'day' )}
                    onChange={this.props.onChange}
                    onRemove={this.props.onRemove}
                    cellActions={{
                        click:    this.props.onTop,
                        insearch: this.cellSearch,
                    }}
                />
                <div className="tabbed-footer left sticky">
                    {data && this.props.onAdd && this.canEdit( this.props ) && (
                        <div className="middle-footer">
                            <AddButton text="Добавить смену" action={this.showPopup} />

                            <AddEmployeeShift
                                employees={format.generate.keyval( employees )}
                                positions={format.generate.keyval( positions )}
                                visible={popup}
                                from={this.props.from}
                                to={this.props.to}
                                onCancel={this.hidePopup}
                                onSubmit={this.add}
                            />
                        </div>
                    )}

                    <SyncScroll
                        ref={this.sync}
                        connect="schedule"
                        apply=".staff-schedule-chart"
                        height={20}
                    />

                    <Modal
                        className="kfc-popup"
                        centered={true}
                        title="Вы уверены?"
                        open={unassign}
                        onOk={this.unassign}
                        onCancel={this.hidePopup}
                        okText="Переназначить"
                        cancelText="Оставить старшим"
                    >
            Задачи будут переназначены на другого менеджера.
                    </Modal>
                </div>
            </div>
        )
    }
}

export default ScheduleChartView
