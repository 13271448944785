/* VENDOR */
import moment from 'moment'

/* APPLICATION */
import { format } from 'tools'

import Template from './Template.js'
import './workedtime.scss'

const rows = ( data ) => {
    const res = []

    data.employees?.forEach(( employee ) => {
        employee?.days?.filter(( day ) => day.factWorkHours?.length ).forEach(( day, dindex ) => {
            const date = moment( day.date )

            if ( !day.date ) { return }

            day?.factWorkHours.forEach(( factWorkHour, findex ) => {
                res.push({
                    employee:            dindex === 0 && findex === 0 ? employee.employeeName || '' : '',
                    date:                findex > 0 ? '' : format.strings.dowDateRC( date ),
                    factWorkHours:       format.strings.interval( factWorkHour.timeStart, factWorkHour.timeEnd ),
                    factSummaryDuration: factWorkHour.factSummaryDuration || '',
                    factDayDuration:     factWorkHour.factDayDuration || '',
                    factNightDuration:   factWorkHour.factNightDuration || '',
                })
            })
        })
    })
    return res
},
      heads = () => [
          {
              employee:            'Сотрудник',
              date:                'Дни',
              factWorkHours:       'Фактически отработанное время',
              factSummaryDuration: 'Итого часы',
              factDayDuration:     'Дневные',
              factNightDuration:   'Ночные',
          },
      ],
      columns = () =>
          format.generate.rowReader([
              'employee',
              'date',
              'factWorkHours',
              'factSummaryDuration',
              'factDayDuration',
              'factNightDuration',
          ])

class WorkedTime extends Template {
    constructor ( props ) {
        super( props )

        this.state = {
            data:       rows( props.data ),
            heads:      heads( props.data ),
            columns:    columns( props.data ),
            className:  'workedtime',
            hideFooter: true,
        }
    }

    data = ( data ) => rows( data )
    columns = ( data ) => columns( data )
}

export default WorkedTime
