/* IMPORT */

import { reportsActions, staffActions, settingsActions } from 'services'

/* EXPORT */

export const mapStateToProps = ( state ) => ({
    request:      state.request,
    reports:      state.reports,
    restaurants:  state.restaurants.list,
    restaurantId: state.request.restaurantId,
    companies:    state.staff.companies,
    workHours:    state.settings.workHours,
})

export const allActions = {
    ...reportsActions,
    ...staffActions,
    ...settingsActions,
}
