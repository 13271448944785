import moment       from 'moment'
import React        from 'react'
import { PprTable } from 'components'
import './ppr-employee-data.scss'
import config       from 'config'

const formatView = config.format.day
const formatAPI = config.format.monthAPI

const columns = [
    'Team Members',
    'Фамилия Имя',
    'Старт в должности',
    'Статус',
    'Пройденные обучения',
    'Прогресс',
    'План действий',
]

const PprEmployeeData = ({ data, loading, error, onRetry }) => {
    const getLearningCells = ( learnings ) => {
        return <>
            {Object.entries( config.defs.pprStudiesShort ).map(([ key, value ]) => <td key={`employee_study_${key}`}>
                {learnings[ key ] ? value : ''}
            </td> )}
        </>
    }

    const emptyData = () => <tr style={{ height: 100 }}>
        <td colSpan={18}>Нет данных</td>
    </tr>

    return (
        <PprTable
            vertical
            small
            className='ppr-employee-data'
            loading={loading}
            error={error}
            onRetry={() => onRetry( 'employees' )}
        >
            <thead>
                <tr>
                    {columns.map(( item, index ) => (
                        <th key={`${item}_${index}`} colSpan={index === 4 ? 12 : 1}>{item}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {!loading && ( !data || data?.length === 0 ) && emptyData()}
                {data?.map(( item, index ) =>
                    <tr key={`employee_${index}_${item.employeeSurnameName}`}>
                        <td >{index + 1}</td>
                        <td>{item.employeeSurnameName}</td>
                        <td>{item.positionStartDate ? moment( item.positionStartDate, formatAPI ).format( formatView ) : config.ui.nullSymbol}</td>
                        <td>{item.employeePositionStatus ?? config.ui.nullSymbol}</td>
                        {getLearningCells( item )}
                        <td>{item.employeeProgress}</td>
                        <td>{item.plan ?? config.ui.nullSymbol}</td>
                    </tr>
                )}
            </tbody>
        </PprTable>
    )
}

export default PprEmployeeData
