/* VENDOR */
import { update } from 'reduxigen'

/* APPLICATION */
import * as api   from './api'
import { format } from 'tools'

const asyncUpdate = format.update.asyncu,
      helper = format.helper.basic( 'rating' ),
      appendHelper = format.helper.append( 'rating' )

/* GLOBAL */

export const flushRating = update( 'rating.flush', ( value, state ) => ({
    ...state,
    rating: {},
}))

export const flushPreviousDishes = update(
    'rating.flushPrevious',
    ( value, state ) => ({
        ...state,
        rating: {
            ...state.rating,
            previousDishes: null,
        },
    })
)

export const flushCategoryDishes = update(
    'rating.flush.catdishes',
    ( n, state ) => helper( 'categoryDishes', null, state )
)

/* DISHES */

export const fetchDishes = asyncUpdate(
    'rating.dishes',
    ( params ) => api.getDishes( params ),
    ( event, state ) => helper( 'dishes', event.data, state )
)

export const fetchModifiers = asyncUpdate(
    'rating.modifiers',
    ( params ) => api.getModifiers( params ),
    ( event, state ) => helper( 'modifiers', event.data, state )
)

export const fetchCategoryDishes = asyncUpdate(
    'rating.categoryDishes',
    ( params ) => api.getCategoryDishes( params ),
    ( event, state ) => helper( 'categoryDishes', event.data, state )
)

export const fetchPreviousDishes = asyncUpdate(
    'rating.previousDishes',
    ( params ) => api.getAllDishes( params ),
    ( event, state ) =>
        helper(
            'previousDishes',
            event.data.data.filter(( d ) => d.top ),
            state
        )
)

export const appendDishes = asyncUpdate(
    'rating.appendDishes',
    ( params ) => api.getDishes( params ),
    ( event, state ) => appendHelper( 'dishes', event, state )
)

export const appendModifiers = asyncUpdate(
    'rating.appendModifiers',
    ( params ) => api.getModifiers( params ),
    ( event, state ) => appendHelper( 'modifiers', event, state )
)

export const fetchCategories = asyncUpdate(
    'rating.categories',
    ( params ) => api.getCategories( params ),
    ( event, state ) => helper( 'categories', event.data, state )
)

export const appendCategories = asyncUpdate(
    'rating.appendCategories',
    ( params ) => api.getCategories( params ),
    ( event, state ) => appendHelper( 'categories', event, state )
)

export const selectCategory = update( 'rating.set.category', ( data, state ) =>
    helper( 'category', data, state )
)

export const fetchDishCategory = asyncUpdate(
    'rating.fetch.category',
    ( id ) => api.getDishCategory( id ),
    ( event, state ) => helper( 'category', event.data, state )
)

/* PERSONS */

// export const fetchManagers = asyncUpdate(
//     'rating.managers',
//     ( params ) => api.getManagers( params ),
//     ( event, state ) => helper( 'managers', event.data, state )
// )
//
// export const appendManagers = asyncUpdate(
//     'rating.appendManagers',
//     ( params ) => api.getManagers( params ),
//     ( event, state ) => appendHelper( 'managers', event, state )
// )

export const fetchCashiers = asyncUpdate(
    'rating.cashiers',
    ( params ) => api.getCashiers( params ),
    ( event, state ) => helper( 'cashiers', event.data, state )
)

export const fetchCashiersByDish = asyncUpdate(
    'rating.cashiers.dish',
    ( params ) => api.getCashiersByDish( params ),
    ( event, state ) => helper( 'cashiers', event.data, state )
)

export const appendCashiers = asyncUpdate(
    'rating.appendCashiers',
    ( params ) => api.getCashiers( params ),
    ( event, state ) => appendHelper( 'cashiers', event, state )
)

export const appendCashiersByDish = asyncUpdate(
    'rating.appendCashiers.dish',
    ( params ) => api.getCashiersByDish( params ),
    ( event, state ) => appendHelper( 'cashiers', event, state )
)
