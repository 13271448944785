import moment from 'moment'

export const prepare = ( data ) => {
    const res = {}

    res.date = moment( data.date )
    res.turnover = []

    Object.keys( data ).forEach(( key ) => {
        if ( key !== 'date' ) {
            const dayOfWeek = parseInt( key.replace( 'day-', '' ))

            res.turnover.push({
                dayOfWeek,
                turnover: data[ key ],
            })
        }
    })

    return res
}
