export const dropTypesMap = {
    RESTAURANTS: 'По ресторанам',
    FRANCHISEE_GROUPS: 'По партнерам',
    FACILITY_TYPES: 'По типам ресторанов',
    CITIES: 'По городам',
    REGION_COACHES: 'По region coaches',
    MARKET_COACHES: 'По market coaches',
    AREA_COACHES: 'По area coaches',
    SALE_CHANNELS: 'По каналам продаж',
    HEAD_OF_EMPLOYEE: 'По руководителям',
    FEEDBACK_EXECUTOR: 'По исполнителям',
};

export enum dropTypesFilter {
    RESTAURANTS = 'рестораны',
    FRANCHISEE_GROUPS = 'партнеры',
    FACILITY_TYPES = 'типы ресторана',
    CITIES = 'города',
    REGION_COACHES = 'region coaches',
    MARKET_COACHES = 'market coaches',
    AREA_COACHES = 'area coaches',
    SALE_CHANNELS = 'каналы продаж',
    HEAD_OF_EMPLOYEE = 'руководители',
    FEEDBACK_EXECUTOR = 'исполнители',
}

export enum dropTypesColumns {
    RESTAURANTS = 'Ресторан',
    FRANCHISEE_GROUPS = 'Партнер',
    FACILITY_TYPES = 'Тип ресторана',
    CITIES = 'Город',
    REGION_COACHES = 'Region coach',
    MARKET_COACHES = 'Market coach',
    AREA_COACHES = 'Area coach',
    SALE_CHANNELS = 'Канал продаж',
    HEAD_OF_EMPLOYEE = 'Руководитель',
    FEEDBACK_EXECUTOR = 'Исполнитель',
}

export enum dropTypesKeys {
    RESTAURANTS = 'restaurantName',
    FRANCHISEE_GROUPS = 'franchiseeGroupName',
    FACILITY_TYPES = 'facilityTypeName',
    CITIES = 'city',
    REGION_COACHES = 'regionCoachName',
    MARKET_COACHES = 'marketCoachName',
    AREA_COACHES = 'areaCoachName',
    SALE_CHANNELS = 'saleChannelName',
    HEAD_OF_EMPLOYEE = 'headOfEmployee',
    FEEDBACK_EXECUTOR = 'feedbackExecutor',
}
