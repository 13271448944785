/* VENDOR */
import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { Layout, Card }     from 'antd'

/* APPLICATION */
import { TurnoverChart, TurnoverSummary } from 'components'

import { format } from 'tools'

const { Sider, Content } = Layout

class TurnoverInner extends Component {
    render () {
        const { request, showDetails, turnover } = this.props,
              currency = format.extract.currency( request.unitOfMeasure )

        return (
            <Card bordered={false} className="dashboard-card">
                <Layout>
                    <Sider className="auto-sider">
                        <TurnoverSummary
                            showDetails={showDetails}
                            data={turnover}
                            currency={currency}
                        />
                    </Sider>
                    <Content>
                        <Layout>
                            <Content>
                                <div style={{ paddingTop: '20px' }}>
                                    <TurnoverChart turnover={turnover} currency={currency} />
                                </div>
                            </Content>
                        </Layout>
                    </Content>
                </Layout>
            </Card>
        )
    }
}

const mapStateToProps = ( state ) => ({
    turnover: state.turnover,
    request:  state.request,
})

export default connect( mapStateToProps )( TurnoverInner )
