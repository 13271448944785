/* IMPORT */

import { turnoverActions, requestActions, settingsActions } from 'services'

/* EXPORT */

export const mapStateToProps = ( state ) => ({
    workHours:   state.settings.workHours,
    turnover:    state.turnover,
    request:     state.request,
    restaurants: state.restaurants.list,
})

export const allActions = {
    ...requestActions,
    ...turnoverActions,
    ...settingsActions,
}
