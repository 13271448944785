import React from 'react'

import * as format from './formats'

export const popupWidth = 390
export const siderWidth = 88
export const hourWidth = 60
export const mobileHourWidth = 55
export const breakpoint = 1300
export const chartHeight = 350
export const riskSize = 94
export const tasksWidth = 364
export const splashDuration = 3000

export const imagesURL = '/assets/images/'
export const nullSymbol = '—'
export const shifts = [ 'смена', 'смены', 'смен' ]

export const colors = {
    def: {
        a: 'rgba(255,255,255,.6)',
        b: 'rgba(255,255,255,.6)',
    },
    active: {
        a: '#fff',
        b: '#fff',
    },
}

export const scrolls = {
    renderTrackVertical:   ( props ) => <div {...props} className="v-scroll-track" />,
    renderThumbVertical:   ( props ) => <div {...props} className="v-scroll-thumb" />,
    renderTrackHorizontal: ( props ) => (
        <div {...props} className="h-scroll-track" />
    ),
    renderThumbHorizontal: ( props ) => (
        <div {...props} className="h-scroll-thumb" />
    ),
    hideTracksWhenNotNeeded: true,
}

export const wideForm = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
}

export const locale = {
    lang: {
        placeholder:      'Выберите дату',
        rangePlaceholder: [ 'Начало', 'Конец' ],
        today:            'Сегодня',
        now:              'Текущее время',
        backToToday:      'Вернуться',
        ok:               'Ок',
        clear:            'Очистить',
        month:            'Месяц',
        year:             'Год',
        timeSelect:       'Выбрать время',
        dateSelect:       'Выбрать дату',
        monthSelect:      'Выбрать месяц',
        yearSelect:       'Выбрать год',
        decadeSelect:     'Выбрать квартал',
        yearFormat:       'YYYY',
        dateFormat:       'M/D/YYYY',
        dayFormat:        'D',
        dateTimeFormat:   'M/D/YYYY HH:mm:ss',
        monthFormat:      'MMMM',
        monthBeforeYear:  true,
        previousMonth:    'Предыдущий месяц (PageUp)',
        nextMonth:        'Следующий месяц (PageDown)',
        previousYear:     'Предыдущий год (Control + left)',
        nextYear:         'Следующий год (Control + right)',
        previousDecade:   'Предыдущий квартал',
        nextDecade:       'Следующий квартал',
        previousCentury:  'Предыдущий век',
        nextCentury:      'Следующий век',
        locale: 'ruRU'
    },
    timePickerLocale: { placeholder: 'Выбрать время', },
    dateFormat:       format.dayView,
    dateTimeFormat:   'YYYY-MM-DD HH:mm:ss',
    weekFormat:       'YYYY-wo',
    monthFormat:      'YYYY-MM',
}

export const eventsSorting = [
    'coachdaily',
    'rgmdaily',

    'coachweekly',
    'rgmweekly',

    'coachtwicemonthly',
    'rgmtwicemonthly',

    'coachsivmonthly',
    'rgmsivmonthly',
    'coachmonthly',
    'rgmmonthly',
    'coachmonthlyrest',

    'coachquarterly',
    'rgmquarterly',
    'coachquarterlyrest',

    'coachyearly',
    'rgmyearly',
    'coachyearrest',

    'coachsivpeople',
    'coachsivproduct',
    'coachsivpromo',
    'coachsivplace',
]
