/* VENDOR */
import React, { Component }           from 'react'
import { connect }                    from 'react-redux'
import { Card, Popover, Modal } from 'antd'
import Icon from '@ant-design/icons'
import moment                         from 'moment'

/* APPLICATION */
import {
    ReportTable,
    AdminStaffControls,
    ClassicPager,
    GrantedRestaurants,
    AdminPermissions,
    LinkedUsers,
    Icons,
    StopSyncModal,
} from 'components'

import { format } from 'tools'
import config     from 'config'
import column     from 'config/tables/helpers'

import { mapStateToProps, allActions } from './connector'
import './users-editor.scss'

class UsersEditor extends Component {
    constructor ( props ) {
        super( props )
        this.state = {
            restaurant: 'all',
            role:       'all',

            users:            null,
            restaurants:      [],
            restNames:        [],
            filteredRestData: [],

            page:  0,
            total: 0,

            active:        false,
            admin:         false,
            planAdmin:     false,
            stopSyncUntil: false,

            search:     '',
            restSearch: '',
            userSearch: '',
            filterType: 'restaurants',

            granted:         false,
            grantedData:     null,
            permissions:     false,
            permissionsData: null,
            confirm:         false,
            onlyGranted:     false,
            updateCurrent:   false,
            stopSync:        false,
            stopSyncData:    null,
            loading:         false
        }
    }

    componentDidMount () {
        this.props.removeEmptyServerErrors()
        this.load()
        this.props.users && this.setUsers( this.props.users )
    }

    componentDidUpdate ( prevProps ) {
        const { users, restaurants } = this.props,
              { updateCurrent, } = this.state


        restaurants !== prevProps.restaurants &&
      this.setRestaurants(
          restaurants && restaurants.content.length ? restaurants.content : []
      )
        users !== prevProps.users && this.setUsers( users )
        updateCurrent && !this.props.userUpdateIsLoading && this.updateCurrent()
    }

    componentWillUnmount () {
        this.props.flushAdminUsers()
    }

    updateCurrent = () => {
        this.props.loadUser()
        this.setState({ updateCurrent: false, })
    }

    load = () => {
        const {
            search,
            restaurant,
            role,
            page,
            active,
            admin,
            planAdmin,
            stopSyncUntil,
        } = this.state,
              params = {
                  search,
                  page,
              }
        restaurant !== 'all' && ( params.restaurantId = restaurant )
        role !== 'all' && ( params.jobRole = role )
        active && ( params.status = true )
        admin && ( params.admin = true )
        planAdmin && ( params.planAdmin = true )
        stopSyncUntil && ( params.stopSyncUntil = true )
        this.getRestaurants()
        this.props.fetchCanBeLinked()
        this.props.fetchRoles()
        //this.props.flushServerError( '408' )
        this.setState({ loading: true }, () => {
            this.props.fetchAdminUsers( params ).finally(() => {
                this.setState({ loading: false })
            })
        })
    }

    setRestaurants = ( restaurants ) => this.setState({ restaurants })

    getRestaurants = () => {
        const { restSearch, filterType } = this.state,
              handlers = {
                  areacoach:   this.props.fetchRestaurantsUsersList,
                  fz:          this.props.fetchRestaurantsFZList,
                  restaurants: this.props.fetchRestaurantsList,
              }

          handlers[ filterType ]( restSearch )

        setTimeout(() => {
            Object.keys( handlers ).forEach(( key ) => {
                key !== filterType && handlers[ key ]( '' )
            })
        })
    }

    getVisibleRests = ( users ) => {
        const res = users.reduce(( all, user ) => [ ...all, user.restaurantId ], []),
              filtered = res.filter(( r, i ) => r && res.indexOf( r ) === i )

        this.setState({ filteredRestData: filtered, restLoading: filtered.length > 0 }, () => {
            filtered.length > 0 && this.props.getRestaurantsData( filtered ).finally(() => {
                this.setState({ restLoading: false })
            })
        })
    }

    setUsers = ( data ) => {
        if ( !data ) {
            this.setState({ users: null })
            return
        }

        const users = format.generate.noPager( data.content.map( this.prepare ))

        this.getVisibleRests( data.content )
        this.props.fetchCanBeLinked()

        this.setState({
            users,
            page:  data.number,
            total: data.totalPages,
        })
    }

    prepare = ( user ) => ({ ...user, })

    reload = ( data ) => {
        this.props.flushAdminUsers()

        data.page = data.page || 0

        this.setState( data, this.load )
    }

    onPage = ( page ) => this.reload({ page })
    onSearch = ( search ) => this.reload({ search })
    onRole = ( role ) => this.reload({ role })
    onActive = ( active ) => this.reload({ active })
    onAdmin = ( admin ) => this.reload({ admin })
    onPlan = ( planAdmin ) => this.reload({ planAdmin })
    onSync = ( stopSyncUntil ) => this.reload({ stopSyncUntil })

    onRestaurant = ( restaurant ) => {
        this.reload({ restaurant })
        this.listRestaurants( restaurant.restaurantName )
    }

    grantedSearch = ( type, search ) => this.listRestaurants( search, type )

    listRestaurants = ( restSearch, filterType ) =>
        this.setState(
            {
                restSearch,
                filterType: filterType || this.state.filterType,
            },
            this.getRestaurants
        )

    appendRestaurants = ( search ) => {
        const { filterType } = this.state

        switch ( filterType ) {
            case 'areacoach':
                this.props.appendRestaurantsUsersList({
                    page: this.props.restUsers.number + 1,
                    search,
                })
                break

            case 'fz':
                this.props.appendRestaurantsFZList({
                    page: this.props.restFZ.number + 1,
                    search,
                })
                break

            case 'restaurants':
            default:
                this.props.appendRestaurantsList({
                    page: this.props.restaurants.number + 1,
                    search,
                })
        }
    }

    listLinked = ( userSearch ) => this.setState({ userSearch }, this.getLinked )

    appendLinked = ( search ) =>
        !this.props.linked.last &&
    this.props.appendLinkedList({
        page: this.props.linked.number + 1,
        search,
    })

    cellSearch = () => this.listRestaurants
    clearRestSearch = () => this.listRestaurants( '' )
    clearUserSearch = () => this.listLinked( '' )

    clearSync = () => {
        const { uncheckSyncData } = this.state

        this.change( uncheckSyncData, 'stopSync', null )
        this.hidePopup( 'uncheckSync' )()
    }

    updateSync = ( values ) => {
        const { stopSyncData } = this.state,
              payload = {
                  ...values,
                  stopSyncUntil: values.stopSyncUntil.format( 'YYYY-MM-DD' ),
              }

        this.change( stopSyncData, 'stopSyncUpdateRequest', payload )
        this.hidePopup( 'stopSync' )()
    }

    change = ( original, key, value ) => {
        const { user } = this.props,
              users = format.copy.array( this.state.users.content ),
              found = users.find(( t ) => t.userUuid === original.userUuid ),
              index = users.indexOf( found )

        if ( found ) {
            if ( key === 'stopSyncModal' ) {
                if ( !value ) {
                    this.setState({
                        uncheckSync:     true,
                        uncheckSyncData: original,
                    })
                } else {
                    this.setState({
                        stopSync:     true,
                        stopSyncData: {
                            ...original,
                            stopSync: {
                                userId:        user.info.userId,
                                comment:       null,
                                stopSyncUntil: moment().add( 1, 'day' ),
                            },
                        },
                    })
                }
            } else {
                switch ( key ) {
                    case '_full':
                        users[ index ] = value
                        ;( !!users[ index ].stopSync ) && ( users[ index ].stopSyncUpdateRequest = users[ index ].stopSync )
                        break

                    case 'stopSyncUpdateRequest':
                        if ( found.userId ) {
                            found[ key ] = value
                            found.stopSync = {
                                ...value,
                                userId:  user.info.userId,
                                loading: true,
                            }
                            users[ index ] = { ...found }
                            delete found.stopSync
                        } else {
                            this.props.addServerError({
                                code: 999001,
                                text: 'Невозможно отключить от синхронизации внештатного сотрудника',
                            })
                            return
                        }
                        break

                    case 'stopSync':
                        found[ key ] = value
                        users[ index ] = { ...found, stopSync: { loading: true } }
                        break

                    default:
                        found[ key ] = value
                        ;( !!found.stopSync ) && ( found.stopSyncUpdateRequest = found.stopSync )
                        users[ index ] = found
                }

                this.setState({ users: { ...this.state.users, content: users }, })
                key === 'restaurantId' && this.clearRestSearch()
                if (user.info.userId === original.userId && key === 'grantedRestaurants') {
                  this.setState({ updateCurrent: true });
                }

                [ 'stopSyncUpdateRequest', 'stopSync' ].includes( key )
                    ? this.props.updateAdminUser( found )
                    : this.props.updateAdminUser( users[ index ])
            }
        }
    }

    popup = ( key, data ) => {
        return () =>
            this.setState({
                [ key ]:          true,
                [ key + 'Data' ]: data,
            })
    }

    hidePopup = ( key ) => {
        return () => {
            this.setState({
                [ key ]:          false,
                [ key + 'Data' ]: null,
                onlyGranted:      false,
            })
            this.clearRestSearch()
            this.clearUserSearch()
            this.props.flushGrantedRestaurants()
        }
    }

    getGrantedList = () => {
        const { granted, restaurants, restUsers, restFZ } = this.props,
              { onlyGranted, filterType } = this.state

        switch ( filterType ) {
            case 'areacoach':
                return onlyGranted
                    ? format.generate.noPager( granted )
                    : restUsers || null

            case 'fz':
                return onlyGranted ? format.generate.noPager( granted ) : restFZ || null

            case 'restaurants':
            default:
                return onlyGranted
                    ? format.generate.noPager( granted )
                    : restaurants || null
        }
    }

    getGranted = ( onlyGranted, list ) => {
        const { grantedData } = this.state

        this.setState({ onlyGranted })
        onlyGranted
            ? this.props.getGrantedRestaurants( list || grantedData.grantedRestaurants )
            : this.props.flushGrantedRestaurants()
    }

    getLinked = ( onlyGranted ) => {
        const { userSearch } = this.state

        this.setState({ onlyGranted })
        !onlyGranted && this.props.fetchCanBeLinked( userSearch )
    }

    updateGranted = ( data ) => {
        this.change(
            this.state.grantedData,
            'grantedRestaurants',
            data.filter(( r, i ) => data.indexOf( r ) === i )
        )
        this.hidePopup( 'granted' )()
    }

    updatePermissions = ( data ) => {
        this.change( data, '_full', data )
        this.hidePopup( 'permissions' )()
    }

    updateLinked = ( data ) => {
        this.change(
            this.state.grantedData,
            'linkedUsers',
            data.filter(( r, i ) => data.indexOf( r ) === i )
        )
        this.hidePopup( 'granted' )()
    }

    lock = ( record ) => {
        return () => this.change( record, 'access', !record.access )
    }

    columns = () => {
        const { user } = this.props,
              def = config.tables.admin.users

        return [
            ...def,
            column( '', '', {
                render: ( text, record ) => (
                    <div className="user-controls">
                        {user.info.userId === record.userId ? (
                            <Popover
                                content="Вы не можете заблокировать сами себя"
                                placement="topLeft"
                                mouseLeaveDelay={0}
                                mouseEnterDelay={0}
                                overlayStyle={{ pointerEvents: 'none' }}
                            >
                                <Icon component={Icons.Lock.def} className="disabled" />
                            </Popover>
                        ) : (
                            <Icon component={Icons.Lock.def} onClick={this.lock( record )} />
                        )}
                    </div>
                ),
            }),
        ]
    }

    render () {
        const
            { page, total, users, restaurants, onlyGranted, loading, restLoading } = this.state,
            {
                search,
                restaurant,
                role,
                active,
                admin,
                planAdmin,
                stopSyncUntil,
                restSearch,
            } = this.state,
            {
                granted,
                grantedData,
                permissions,
                permissionsData,
                filterType,
                stopSync,
                stopSyncData,
                uncheckSync,
            } = this.state,
            { restData, roles, linked, errors, user } = this.props,
            allRests = restData
                ? [ ...restaurants, ...restData ].filter(
                        ( r, i, arr ) =>
                            arr.indexOf(
                                arr.find(( rr ) => rr.factsNumber === r.factsNumber )
                            ) === i &&
              ( r.restaurantName && restSearch
                  ? r.restaurantName
                      .toLowerCase()
                      .indexOf( restSearch.toLowerCase()) > -1
                  : true )
                    )
                : restaurants,
            select = {
                restaurants: format.generate.options(
                    allRests,
                    'factsNumber',
                    'restaurantName'
                ),
                roles: format.generate.keyvalrs( roles ),
            },
            hasErrors = errors && (
                Object.keys( errors ).filter(
                    ( key ) => ![ '404', '999001' ].includes( key )
                ).length > 0
            ),
            showEmptyData = ( hasErrors && !users?.content )

        return (
            <Card bordered={false} className="users-editor">
                <AdminStaffControls
                    search={search}
                    restaurant={restaurant}
                    role={role}
                    active={active}
                    admin={admin}
                    planning={planAdmin}
                    stopSyncUntil={stopSyncUntil}
                    restaurants={restaurants}
                    roles={roles}
                    onSearch={this.onSearch}
                    onRestaurant={this.onRestaurant}
                    onRole={this.onRole}
                    onActive={this.onActive}
                    onAdmin={this.onAdmin}
                    onPlan={this.onPlan}
                    onSync={this.onSync}
                    onListRestaurants={this.listRestaurants}
                />

                <div className="report-table">
                    <ReportTable
                        rowKey="userUuid"
                        styleInactive={true}
                        data={showEmptyData ? { content: [] } : users}
                        loading={loading}
                        columns={this.columns()}
                        select={select}
                        cellActions={{
                            popup:   this.popup,
                            search:  this.cellSearch,
                            blur:    this.clearRestSearch,
                            canEdit: ( rec ) => rec?.stopSync?.userId === user.info.userId,
                        }}
                        onChange={this.change}
                    />
                </div>

                <ClassicPager page={page} total={total} onChange={this.onPage} />

                <GrantedRestaurants
                    visible={
                        grantedData ? !grantedData.marketManager && granted : granted
                    }
                    data={hasErrors ? { content: [] } : this.getGrantedList()}
                    selected={grantedData ? grantedData.grantedRestaurants : []}
                    filterType={filterType}
                    onSearch={this.grantedSearch}
                    onSubmit={this.updateGranted}
                    onCancel={this.hidePopup( 'granted' )}
                    onMore={this.appendRestaurants}
                    onGranted={this.getGranted}
                    isError={hasErrors}
                    onFilterType={( e ) => this.setState({ filterType: e.target.value })}
                />

                <LinkedUsers
                    visible={grantedData ? grantedData.marketManager && granted : granted}
                    data={
                        onlyGranted
                            ? grantedData
                                ? format.generate.noPager( grantedData.linkedUsers )
                                : format.generate.noPager([])
                            : linked
                                ? linked
                                : null
                    }
                    selected={grantedData ? grantedData.linkedUsers : []}
                    onSearch={this.listLinked}
                    onSubmit={this.updateLinked}
                    onCancel={this.hidePopup( 'granted' )}
                    onMore={this.appendLinked}
                    onLinked={this.getLinked}
                />

                <AdminPermissions
                    visible={permissions}
                    top={config.permissions.top}
                    sub={config.permissions.sub}
                    data={permissionsData ? permissionsData : {}}
                    onSubmit={this.updatePermissions}
                    onCancel={this.hidePopup( 'permissions' )}
                />

                <StopSyncModal
                    visible={stopSync}
                    editable={user.info.userId === stopSyncData?.stopSync?.userId}
                    data={stopSyncData?.stopSync}
                    onSave={this.updateSync}
                    onClose={this.hidePopup( 'stopSync' )}
                />

                <Modal
                    className="kfc-popup kfc-stopsync-clear"
                    centered={true}
                    open={uncheckSync}
                    title="Отключение от синхронизации"
                    okText="Подтвердить"
                    onOk={this.clearSync}
                    onCancel={this.hidePopup( 'uncheckSync' )}
                    cancelText="Отмена"
                >
          Синхронизация будет включена. Дата и комментарий отключения будут
          стерты
                </Modal>
            </Card>
        )
    }
}

export default connect( mapStateToProps, allActions )( UsersEditor )
