import React, {useEffect, useState} from 'react';
import {Datum, PieConfig} from '@ant-design/charts';
import cn from 'classnames';
import styles from './pie-chart.module.scss';
import {Pie} from '@ant-design/plots';

type chartData = {
  type: string;
  value: number;
};

type PieChartProps = {
  title: string;
  content: { name: string; factFeedbacks: number; percentageFactFeedbacks: number }[];
};

const COLORS = [ '#3aa8bf', '#165f99', '#9c7fc8', '#AFA8E6', '#6C8FCD', '#99C3D8', '#D3ABD2', '#C28DD0', '#6E61B4', '#1476A3', '#AA4A8A', '#7A2476', '#8F0062', '#003675', '#003F51' ]

export const PieChart = React.memo(({title, content}: PieChartProps) => {
  const [ data, setData ] = useState<chartData[]>([]);
  const [ legendData, setLegendData ] = useState<chartData[]>([])
  const [ disabledLegendItems, setDisabledLegendItems ] = useState<chartData[]>([])
  const [ currentColors, setCurrentColors ] = useState<string[]>(COLORS)
  const [ disabledColors, setDisabledColors ] = useState<string[]>([])

  const handleDisableLegendItem = (item: chartData, idx: number) => {
      setDisabledLegendItems(prev => {
        if (prev.find((i) => i.type === item.type)) {
          return prev.filter((i) => i.type !== item.type)
        } else {
          return [ ...prev, item ]
        }
      })

      const color = COLORS[idx]
      setDisabledColors(prev => {
        if (prev.find((i) => i === color)) {
          return prev.filter((i) => i !== color)
        } else {
          return [ ...prev, color ]
        }
      })
  }

  useEffect(() => {
    const data = content?.map((item) => {
      return {
        type: `${item.name} - ${item.percentageFactFeedbacks}%`,
        value: item.percentageFactFeedbacks,
      };
    })

    setData(data);
    setLegendData(data)
  }, [ content ]);

  useEffect(() => {
    if (disabledLegendItems.length) {
      setData(legendData.filter((item) => !disabledLegendItems.find((i) => i.type === item.type)))
    } else {
      setData(legendData)
    }
  }, [ disabledLegendItems, legendData ]);

  useEffect(() => {
    setCurrentColors(COLORS.filter((color) => !disabledColors.includes(color)))
  }, [ disabledColors ]);

  const config: PieConfig = {
    color: currentColors,
    data,
    angleField: 'value',
    colorField: 'type',
    width: 350,
    renderer: 'svg',
    radius: 1,
    legend: false,
    label: false,
    tooltip: {
      formatter: (datum: Datum) => {
        let name = datum.type.split(' ');
        const value = name.pop()
        name.pop()
        name = name.join(' ')
        return {name, value};
      },
    }
  };

  return (
    <div className={cn(styles.chart)}>
      <span className={styles.title}>{title}</span>
      <Pie {...config} />

      <ul className={styles.legend}>
        {legendData.map((item, idx) => {
          return (
            <li
              onClick={() => handleDisableLegendItem(item, idx)}
              className={cn(styles.legend_item, {[styles.disabled]: disabledLegendItems.find((i) => i.type === item.type)})}
              key={item.type}
            >
              <div
                style={{backgroundColor: COLORS[idx]}}
                className={styles.legend_circle}
              />
              <span className={styles.legend_text}>{item.type}</span>
            </li>
          )
        })}
      </ul>

    </div>
  );
});
