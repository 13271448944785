/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'

/* APPLICATION */
import { format } from 'tools'

import './report-title.scss'

class Template extends Component {
    static propTypes = {
        data:   PropTypes.any,
        rowKey: PropTypes.string,
    }

    constructor ( props ) {
        super( props )
        this.state = {
            data:       [],
            heads:      [],
            columns:    [],
            hideHeader: false,
            hideFooter: false,
        }
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        if ( nextProps.data !== this.props.data ) {
            this.setState({
                data:    this.data( nextProps.data ),
                columns: this.columns( nextProps.data ),
            })
        }
    }

    title = () => {
        const { data } = this.props

        return (
            <tr>
                <td colSpan={Object.keys( this.state.columns ).length}>
                    <table className="print-report-header">
                        <tbody>
                            <tr>
                                <td>
                                    <h3>{data.title}</h3>
                                </td>
                                <td>{data.restaurant}</td>
                                <td>{format.strings.range( data.dates )}</td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        )
    }

    small = () => {
        if ( !this.props.data.smallTitle ) { return null }

        return (
            <tr>
                <td colSpan={Object.keys( this.state.columns ).length}>
                    {this.props.data.smallTitle}
                </td>
            </tr>
        )
    }

    footer = () => ''

    heads = () => ( this.state.heads ? this.state.heads.map( this.row ) : null )

    rows = () => ( this.state.data ? this.state.data.map( this.row ) : null )

    row = ( row ) => <tr key={row[ this.props.rowKey ]}>{this.genColumns( row )}</tr>
    genColumns = ( row ) => Object.keys( this.state.columns ).map( this.column( row ))

    column = ( row ) => {
        return ( dataIndex ) =>
            this.state.columns[ dataIndex ] ? (
                <td key={dataIndex} {...row.props}>
                    {this.state.columns[ dataIndex ]( row )}
                </td>
            ) : null
    }

    cols = () => (
        <colgroup>
            {this.state.width.map(( w, i ) => (
                <col style={{ width: w, minWidth: w }} key={i} />
            ))}
        </colgroup>
    )

    render () {
        const { className, hideHeader, hideFooter, columns, width } = this.state

        return (
            <>
                <table className={'print-report-template ' + className}>
                    {width && this.cols()}
                    {!hideHeader && (
                        <thead>
                            {this.title()}
                            {this.small()}
                            {this.heads()}
                        </thead>
                    )}
                    <tbody>{this.rows()}</tbody>
                    {!hideFooter && (
                        <tfoot>
                            <tr>
                                <td colSpan={Object.keys( columns ).length}>{this.footer()}</td>
                            </tr>
                        </tfoot>
                    )}
                </table>
                {className === 'stations' && <div className='print-report-comment'>
                    *Отчет сформирован на основании смен R-Keeper
                </div>}
            </>

        )
    }
}

export default Template
