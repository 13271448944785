/* VENDOR */
import React, { Component } from 'react'
import { connect }          from 'react-redux'
import moment               from 'moment'

/* APPLICATION */
import { ScheduleChart, Spinner }            from 'components'
import { schedulesActions, settingsActions } from 'services'
import { format }                            from 'tools'
import config                                from 'config'
import column                                from 'config/tables/helpers'

import * as helpers from '../Schedule/helpers'
import './schedule-mobile.scss'

const hour = ( res ) => {
    return ( hour ) =>
        res.push(
            column( hour, 'hour-' + hour, {
                as:        'cell',
                width:     config.ui.mobileHourWidth,
                className: 'col-sep nopad numeric align-center',
            })
        )
}

let previousScroll = -1

class ScheduleMobile extends Component {
    constructor ( props ) {
        super( props )

        const query = {}

        window.location.search
            .replace( '?', '' )
            .split( '&' )
            .forEach(( pair ) => {
                const res = pair.split( '=' )
                query[ res[ 0 ] ] = res[ 1 ]
            })


        this.state = {
            ...query,
            dayOfWeek:    moment( query.dateStart ).format( 'd' ),
            open:         {},
            restaurantId: query.factsNumber, // TODO: Убрать потом restrauntId после переезда всех методов на бэке на factsNumber
        }

        this.helpers = {
            ...helpers,

            spoiler: ( path, obj ) => {
                let key = path.split( '.' )[ 0 ] + this.state.dayOfWeek

                key += obj.positionGuid ? obj.positionGuid : 'Day'

                obj.__open = obj.open ? !this.state.open[ key ] : this.state.open[ key ]

                obj.__spoiler = true
                obj.guid = format.generate.guid()
                //obj.rowClick = this.toggle( key, handler )
            },

            hourscols: ( from, to, hoverable ) => {
                const res = []
                this.helpers.hours( hour( res, hoverable ), from, to )
                return res
            },

            width: ( from, to ) => {
                const hours = to.hours() - from.hours() + 1
                let res = 0

                res = config.tables.schedule.mobile.reduce(( w, c ) => w + c.width, res )
                res += hours * config.ui.mobileHourWidth

                return res
            },
        }
    }

    componentDidMount () {
        this.props.getSchedule( this.state )
        this.props.fetchWorkHours( this.state, )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        if ( nextProps.schedule !== this.props.schedule ) {
            this.setData( nextProps.schedule )
        }
    }

    setData = ( schedule ) => {
        const hour = moment().hour(),
              dayDate = moment( schedule.dateStart )

        schedule.days.forEach(( day ) => {
            day.positions.forEach(( pos ) => {
                pos.shifts = this.helpers.prepareShifts( pos.shifts, pos, dayDate )
            })
        })

        this.setState({ data: schedule, })

        setTimeout(() => {
            const found = [].slice
                .call( document.querySelectorAll( '.ant-table-thead th' ))
                .find(( th ) => parseInt( th.innerText ) === hour )

            if ( !found ) {
                return
            }

            found.style.fontWeight = 'bolder'
            this.scrollToCurrent(
                found,
                document.querySelector( '.ant-table-body' ),
                document.querySelector( '.ant-table-fixed-left' )
            )
        }, 400 )
    }

    scrollToCurrent = ( th, container, offseter ) => {
        if ( container.scrollLeft === previousScroll ) {
            return
        }

        if ( container.scrollLeft < th.offsetLeft - offseter.offsetWidth ) {
            previousScroll = container.scrollLeft
            container.scrollLeft = container.scrollLeft + 50
            setTimeout(() => this.scrollToCurrent( th, container, offseter ), 10 )
        } else if ( container.scrollLeft > th.offsetLeft - offseter.offsetWidth ) {
            container.scrollLeft = th.offsetLeft - offseter.offsetWidth
        }
    }

    render () {
        const { dayOfWeek, dateStart, data, open } = this.state,
              { hours } = this.props,
              max = this.helpers.find.max( data, dayOfWeek, hours )

        if ( !hours ) {
            return <Spinner/>
        }

        return (
            <div className="kfc-schedule-mobile">
                {max && (
                    <style
                        dangerouslySetInnerHTML={{
                            __html: this.helpers.highlightColumns(
                                max,
                                '.kfc-schedule-mobile',
                                -3
                            ),
                        }}
                    />
                )}
                <ScheduleChart
                    fixedHead
                    day={dayOfWeek}
                    date={moment( dateStart )}
                    from={moment().startOf( 'day' )}
                    to={moment().endOf( 'day' )}
                    compact={true}
                    data={data}
                    helpers={this.helpers}
                    workHours={hours}
                    open={open}
                />
            </div>
        )
    }
}

const mapStateToProps = ( state ) => ({
    schedule: state.schedules.current,
    hours:    state.settings.workHours,
}),

      allActions = {
          ...schedulesActions,
          ...settingsActions,
      }

export default connect( mapStateToProps, allActions )( ScheduleMobile )
