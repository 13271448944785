/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { DatePicker }       from 'antd'
import moment               from 'moment'
import ruRU                 from 'antd/es/date-picker/locale/ru_RU'

/* APPLICATION */
import { ViewTypeSwitch, PageControls, Icons } from 'components'
import { format }                              from 'tools'
import config                                  from 'config'

import './calendar-controls.scss'

const { MonthPicker, WeekPicker } = DatePicker

class CalendarControls extends Component {
    static propTypes = {
        view: PropTypes.oneOf([ 'month', 'week' ]),
        date: PropTypes.object,

        onDateChange: PropTypes.func,
        onViewChange: PropTypes.func,
    }

    constructor ( props ) {
        super( props )

        this.state = { open: false, }

        this.set = format.generate.set( this )
    }

    toggle = ( open ) => this.set.open( open )
    close = () => this.set.open( false )
    open = () => this.set.open( true )

    next = () =>
        this.props.onDateChange(
            moment( this.props.date ).add( 1, this.props.view ).format( config.format.date )
        )

    prev = () => {
        const newDate = moment(this.props.date).subtract(1, this.props.view);
        if (newDate.isBefore(moment().subtract(1, 'year'))) {
            return;
        }
        this.props.onDateChange(newDate.format(config.format.date));
    }

    onDate = (date) => {
        if (moment(date).isBefore(moment().subtract(1, 'year'))) {
            return;
        }
        this.props.onDateChange(date);
    };

    title = () =>
        this.props.view === 'week'
            ? format.strings.range(
                [
                    moment( this.props.date ).startOf( 'week' ),
                    moment( this.props.date ).endOf( 'week' ),
                ],
                true
            )
            : moment( this.props.date ).isSame( moment(), 'week' ) &&
        moment( this.props.date ).isSame( moment(), 'month' )
                ? moment()
                    .format( 'MMMM YYYY' )
                    .split( '' )
                    .map(( l, i ) => ( i === 0 ? l.toUpperCase() : l ))
                    .join( '' )
                : moment( this.props.date )
                    .format( 'MMMM YYYY' )
                    .split( '' )
                    .map(( l, i ) => ( i === 0 ? l.toUpperCase() : l ))
                    .join( '' )

    disabledDateMonth = ( current ) => {
        // Проверяем, что конец месяца не раньше, чем год назад
        return current && current.endOf('month').isBefore(moment().subtract(1, 'year').startOf('day'));
    };

    disabledWeekDate = ( current ) => {
        // Получить понедельник текущей недели
        const mondayOfWeek = moment(current).startOf('week');

        // Проверить, что понедельник этой недели удовлетворяет условию date.isBefore(moment().subtract(1, 'year'));
        return mondayOfWeek.isBefore(moment().subtract(1, 'year'));
    };

    render () {
        const { view, date, onViewChange } = this.props,
              { open } = this.state,
              tabs = {
                  week:  'Неделя',
                  month: 'Месяц',
              }

        return (
            <div
                className={'calendar-controls view-type-switch calendar-view-' + view}
            >
                <h2>{this.title()}</h2>

                <PageControls
                    icon={Icons.Calendar.active}
                    action={this.open}
                    onNext={this.next}
                    onPrev={this.prev}
                    disabledPrev={moment(this.props.date).subtract(1, this.props.view).isBefore(moment().subtract(1, 'year').startOf('month'))}
                />

                {view === 'week' ? (
                    <WeekPicker
                        value={date}
                        open={open}
                        onChange={this.onDate}
                        onBlur={this.close}
                        onOpenChange={this.toggle}
                        locale={ruRU}
                        disabledDate={this.disabledWeekDate}
                    />
                ) : (
                    <MonthPicker
                        value={date}
                        open={open}
                        onChange={this.onDate}
                        onBlur={this.close}
                        locale={ruRU}
                        onOpenChange={this.toggle}
                        disabledDate={this.disabledDateMonth}
                    />
                )}

                <ViewTypeSwitch tabs={tabs} current={view} update={onViewChange} />
            </div>
        )
    }
}

export default CalendarControls
