/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Hint }             from 'react-vis'

/* APPLICATION */
import { Value }  from 'components'
import { format } from 'tools'
import config     from 'config'

class ChartHint extends Component {
    static propTypes = {
        hint: PropTypes.object,
        unit: PropTypes.string,

        compare: PropTypes.func,
    }

    unit = () => this.props.unit || '₽'

    isNull = ( success, fail, srcFact ) =>
        this.props.hint[ srcFact ] === config.ui.nullSymbol ? success : fail

    value = {
        fact: ( srcPlan, srcFact ) => (
            <Value
                value={this.props.hint[ srcFact ]}
                refValue={this.isNull(
                    this.props.hint[ srcFact ],
                    this.props.hint[ srcPlan ],
                    srcFact
                )}
                prepend="Факт"
                addon={this.isNull( '', this.props.unit, srcFact )}
                format={format.strings.thousand}
                compare={this.props.compare}
            />
        ),

        plan: ( srcPlan ) => (
            <Value
                value={this.props.hint[ srcPlan ]}
                prepend="План"
                addon={this.props.unit}
                format={format.strings.thousand}
                noClass
            />
        ),
    }

    emptyPlan = () =>
        this.isNull( null, <Value value="не задан" prepend="План" noClass /> )

    getPlan = () => {
        if ( this.props.hint[ 'planSpmh' ]) {

            return this.value.plan( 'planSpmh' )

        } else if ( this.props.hint[ 'planTurnover' ]) {

            return this.value.plan( 'planTurnover' )

        } else if ( this.props.hint[ 'planValue' ]) {

            return this.value.plan( 'planValue' )
        }

        return this.emptyPlan()
    }

    getFact = () => {
        if ( this.props.hint[ 'factSpmh' ]) {

            return this.value.fact( 'planSpmh', 'factSpmh' )

        } else if ( this.props.hint[ 'factTurnover' ]) {

            return this.value.fact( 'planTurnover', 'factTurnover' )

        } else if ( this.props.hint[ 'factValue' ]) {

            return this.value.fact( 'planValue', 'factValue' )
        }

        return null
    }

    cls = ( planValue = null, factValue = null ) => {
        const { compare } = this.props,
              cls = [ 'hint' ]

        if ( factValue === null || factValue === config.ui.nullSymbol ) { return cls[ 0 ] }

        if ( compare ) {
            compare( factValue, planValue )
                ? cls.push( 'good' )
                : cls.push( 'bad' )
        } else {
            cls.push( format.goodBad.compare( factValue, planValue ))
        }

        return cls.join( ' ' )
    }

    render () {
        if ( !this.props.hint ) { return null }

        const plan = this.getPlan(),
              fact = this.getFact()

        if ( !plan && !fact ) { return null }

        return (
            <Hint
                value={this.props.hint}
                align={{ horizontal: 'auto', vertical: 'bottom' }}
                {...this.props}
            >
                <div className={this.cls( plan?.props?.value, fact?.props?.value )}>
                    {fact}
                    {plan}
                </div>
            </Hint>
        )
    }
}

export default ChartHint
