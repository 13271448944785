/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Row }              from 'antd'

/* APPLICATION */
import {
    TurnoverCard,
    TimeCard,
    RatingCard,
    PerformanceCard,
} from 'components'

import './dashboard-cards.scss'

class DashboardCards extends Component {
    static propTypes = {
        request:   PropTypes.object,
        workHours: PropTypes.object,
        offset:    PropTypes.string,

        turnover:    PropTypes.object,
        performance: PropTypes.object,
        rating:      PropTypes.object,

        showDetails: PropTypes.bool,

        update: PropTypes.func,
    }

    render () {
        return (
            <section className="dashboard-cards-container">
                <Row gutter={16}>
                    <TurnoverCard {...this.props} />
                    <TimeCard {...this.props} />
                    <RatingCard />
                    <PerformanceCard {...this.props} />
                </Row>
            </section>
        )
    }
}

export default DashboardCards
