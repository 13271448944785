import * as Icons from 'components/layout/icons'

export const admin = {
    title:    'Управление',
    icon:     Icons.Admin,
    route:    '/admin',
    position: 8,
}

export const feedback = {
    title:    'Гостевой опыт',
    icon:     Icons.GuestVote,
    route:    '/guest',
    position: -1,
}

export const myTime = {
    title: 'One Time',
    icon: Icons.MyTime,
    route: 'https://ft.irb.rest',
    position: 3,
    redirect: true,
    openOnNewTab: true,
}

export const calendar = {
    title:    'Календарь',
    icon:     Icons.CalendarView,
    route:    '/calendar',
    position: 9,
}

export const dashboard = {
    title:    'Показатели',
    icon:     Icons.Dashboard,
    route:    '/dashboard',
    def:      true,
    position: 1,
}

export const planning = {
    title:    'Планирование',
    icon:     Icons.Planning,
    route:    '/planning',
    position: 7,
}

export const rating = {
    title:    'Рейтинг ресторанов',
    short:    'Рейтинг',
    icon:     Icons.Rating,
    route:    '/rating',
    position: 0,
}

export const reports = {
    title:    'Отчёты',
    icon:     Icons.Reports,
    route:    '/reports',
    position: 6,
}

export const schedule = {
    title:    'Расписание',
    icon:     Icons.Schedule,
    route:    '/schedule',
    position: 4,
}

export const settings = {
    title:    'Настройки',
    icon:     Icons.Settings,
    bottom:   true,
    route:    '/settings',
    position: 10,
}

export const staff = {
    title:    'Сотрудники',
    icon:     Icons.Employees,
    route:    '/staff',
    position: 2,
}

export const tasks = {
    title:    'Задачи',
    icon:     Icons.Tasks,
    route:    '/tasks',
    position: 5,
}
