/* VENDOR */
import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { Layout, Card }     from 'antd'

/* APPLICATION */
import {
    AppHeader,
    InnerContent,
    BackTitle,
    InnerControls,
    ReportTable,
} from 'components'

import { format } from 'tools'
import config     from 'config'

import { allActions, mapStateToProps } from './connector'
import '../RatingReport/rating-report.scss'

const { Content, Header } = Layout,
      intervals = {}

class RatingDeep extends Component {
    constructor ( props ) {
        super( props )
        this.state = {
            category: props.category
                ? {
                        categoryId: props.category.categoryId,
                        name:       props.category.name || props.category.categoryName,
                    }
                : null,
            dishes: props.dishes ? format.generate.noPager( props.dishes.data ) : null,
        }
    }

    componentDidMount () {
        const { request, workHours, category, match, dishes } = this.props

        !workHours && this.props.fetchWorkHours( request )
        !category && this.props.fetchDishCategory( match.params.deep )
        !dishes && request.dateStart && this.load()
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { workHours, request, category, dishes } = this.props

        if ( nextProps.workHours !== workHours ) {
            format.dates.setGroup(
                nextProps.request.currentPredef,
                nextProps,
                () => this.load( nextProps ),
                nextProps.workHours
            )
        }

        if ( format.check.request( request, nextProps.request )) {
            this.load( nextProps )
        }

        category !== nextProps.category && this.setCategory( nextProps.category )
        dishes !== nextProps.dishes && this.setDishes( nextProps.dishes )
    }

    setDishes = ( data ) =>
        this.setState({ dishes: format.generate.noPager( data.data ), })

    setCategory = ( data ) =>
        this.setState({
            category: {
                categoryId: data.categoryId,
                name:       data.name || data.categoryName,
            },
        })

    load = ( raw ) => {
        const props = raw || this.props,
              { request, match } = props

        request.factsNumber = request.restaurantId

        props.fetchCategoryDishes({
            ...request,
            categoryId: match.params.deep,
            hideCombo:  false,
        })
    }

    isEmpty = () => !this.state.category

    setPlan = ( dish ) => {
        if ( intervals[ dish.productUid ]) {
            clearTimeout( intervals[ dish.productUid ])
        }

        intervals[ dish.productUid ] = setTimeout(() => {
            this.props.savePlan({
                id:          null,
                productUid:  dish.productUid,
                factsNumber: this.props.request.restaurantId,
                rating:      dish.planValue,
                _type:       'dish',
            })
            clearTimeout( intervals[ dish.productUid ])
        }, 1000 )
    }

    change = ( original, key, val ) => {
        const { dishes } = this.state,
              items = format.copy.object( dishes ),
              dish = format.copy.object( original ),
              index = format.find.index(
                  items.content,
                  'productUid',
                  original.productUid
              )

        dish[ key ] = val
        dish.deltaValue = dish.factValue - dish.planValue
        key === 'planValue' && this.setPlan( dish )

        items.content[ index ] = dish

        this.setState({ dishes: items, })
    }

    set = ( data ) => this.props.setRequest( data )

    toggleTop = ( item ) => {
        return ( top ) => {
            const { request } = this.props,
                  params = {
                      ...item,
                      top,
                      _type:         'categoryDish',
                      restaurantUid: request.restaurantId,
                      productUid:    item.productUid,
                      endpoint:      'top-products',
                  }

            this.props.toggleTop( params )
        }
    }

    render () {
        const { request, workHours } = this.props,
              { category, dishes } = this.state,
              backTitle = category ? 'Категория «' + category.name + '»' : '...'

        return (
            <section className="kfc-report kfc-rating-report kfc-view-categories">
                <Layout>
                    <Header>
                        <AppHeader />
                    </Header>
                    <Content>
                        <InnerControls
                            hideDetalization
                            actions={format.extract.actions( this.props )}
                            request={request}
                            workHours={workHours}
                            update={this.load}
                            onChange={this.set}
                        />
                        <InnerContent fixed={this.isEmpty()}>
                            <BackTitle text={backTitle} url="/dashboard/rating" />
                            <Card bordered={false} className="report-table">
                                <ReportTable
                                    data={dishes ? dishes : format.generate.noPager([])}
                                    columns={config.tables.dishes}
                                    unit={request.unitOfMeasure}
                                    rowKey={( item ) =>
                                        '' + item.productUid + item.rkeeperCode + item.categoryId
                                    }
                                    loading={!dishes}
                                    prepare={this.prepareDishes}
                                    onChange={this.change}
                                    cellActions={{ toggle: this.toggleTop, }}
                                />
                            </Card>
                        </InnerContent>
                    </Content>
                </Layout>
            </section>
        )
    }
}

export default connect( mapStateToProps, allActions )( RatingDeep )
