/* VENDOR */
import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { withRouter }       from 'react-router-dom'

/* APPLICATION */
import { ViewTab, UserInfo, SessionTimeout } from 'components'
import config                                from 'config'

import './view-tabs.scss'

const MY_TIME_FRANCHISEE_CODES = [ 42, 65, 128, 5 ]

class ViewTabs extends Component {
    isActive = ( atts, props ) =>
        this.isRoute( atts, props ) || this.isDef( atts, props )

    isRoute = ( atts, props ) => props.location.pathname.indexOf( atts.route ) === 0

    isDef = ( atts, props ) => props.location.pathname === '/' && atts.def

    strictAccess = ( props, key ) => {
        if (key === 'myTime') {
            if (this.props.request && this.props.restaurants) {
                const factsNumber = Number(this.props.request.restaurantId)
                const restaurant = this.props.restaurants.find(rest => rest.factsNumber === factsNumber)
                const franchiseeGroupCode = restaurant?.franchiseeGroupCode
                return MY_TIME_FRANCHISEE_CODES.includes(franchiseeGroupCode)
            }
        }

        return props.areas && props.areas.includes( key )
    }
    anyAccess = ( props, key ) =>
        props.areas &&
    props.areas.filter(( area ) => area.indexOf( key ) > -1 ).length > 0

    customAccess = ( props, key ) => {
        let areas = []

        switch ( key ) {
            case 'admin':
                areas = [ 'banners' ]
                break
            default:
                areas = []
        }

        return (
            props.areas &&
      props.areas.filter(( a ) => areas.indexOf( a.toLowerCase()) > -1 ).length > 0
        )
    }

    access = ( props ) => {
        return ( key ) =>
            key === 'admin'
                ? this.anyAccess( props, key ) || this.customAccess( props, key )
                : this.strictAccess( props, key )
    }

    tab = ( key, atts, props ) => (
        <ViewTab key={key} {...atts} active={this.isActive( atts, props )} />
    )

    tabs = ( props ) => {
        return Object.keys( config.left )
            .filter( this.access( props ))
            .sort(( a, b ) => config.left[ a ].position - config.left[ b ].position )
            .map(( key ) => this.tab( key, config.left[ key ], props ))
    }
    render () {
        return (
            <div className="view-tabs">
                {this.props.areas ? this.tabs( this.props ) : null}
                <UserInfo />
                <SessionTimeout />
            </div>
        )
    }
}

const mapStateToProps = ( state ) => ({
    areas: state.user.areas,
    request: state.request,
    restaurants: state.restaurants.list
})

export default withRouter( connect( mapStateToProps )( ViewTabs ))
