import React from 'react'
import './style.scss'

interface CustomTabProps {
    title: string;
    icon: React.ReactNode;
    onClickIcon?: () => void;
}

const CustomTab = ({ title, icon, onClickIcon }: CustomTabProps) => {
    return (
        <div className={'custom-tab'}>
            <div className={'custom-tab-title'}>{title}</div>
            {icon && <div className={'custom-tab-icon'} onClick={onClickIcon}>{icon}</div>}
        </div>
    )
}

export default CustomTab
