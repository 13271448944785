import moment from 'moment'
import config from 'config'

export const all = ( event, data, view, date ) => {
    const res = []

    after( event, res, view, date );
    ( event.groupName.indexOf( 'Weekly' ) > -1 || event.groupName.indexOf( 'Daily' ) > -1 ) &&
    before( event, res, view, date )
    event.groupName.indexOf( 'Daily' ) > -1 &&
    event.deadlines &&
    days( event, res, view )
    event.groupName.indexOf( 'Weekly' ) > -1 &&
    event.deadlines &&
    weeks( event, res, view, data )

    return res
}

export const after = ( event, disabled, view, date ) => {
    const deadend = event.deadlines ? event.deadlines.end : event.deadline,
          deadline = moment( deadend ).add( 1, 'day' ).startOf( 'day' ),
          end = moment( date ).endOf( view ),
          diff = end.diff( deadline, 'days' )

    for ( let i = 0; i <= diff; i++ ) {
        add( view, disabled, moment( deadline ).add( i, 'days' ))
    }
}

export const before = ( event, disabled, view, date ) => {
    const offset = event.groupName.indexOf( 'Weekly' ) > -1 ? 'week' : 'day',
          beforeStart = moment( date ).startOf( view ),
          end = event.deadlines ? event.deadlines.start : event.deadline,
          beforeEnd = moment( end ).startOf( offset ),
          bdiff = beforeEnd.diff( beforeStart, 'days' )

    for ( let i = 0; i < bdiff; i++ ) {
        add( view, disabled, moment( beforeStart ).add( i, 'days' ))
    }
}

export const days = ( event, disabled, view ) => {
    const start = event.deadlines.start,
          end = event.deadlines.end,
          bdiff = moment( end ).diff( moment( start ), 'days' )

    if ( bdiff <= 1 ) { return }

    for ( let i = 0; i < bdiff; i++ ) {
        if (
            event.deadlines.all.indexOf(
                moment( start ).add( i, 'days' ).format( config.format.dateFullWithSeconds )
            ) < 0
        ) {
            add( view, disabled, moment( start ).add( i, 'days' ))
        }
    }
}

export const weeks = ( event, disabled, view, data ) => {
    const start = moment( event.deadlines.start ).startOf( 'week' ),
          end = moment( event.deadlines.end ),
          bdiff = moment( end ).diff( moment( start ), 'days' )

    let events

    for ( let i = 0; i < bdiff; i += 7 ) {
        events = data.filter(
            ( e ) =>
                e.title === event.title &&
        moment( e.startedDate ).isSame( moment( start ).add( i, 'days' ), 'week' ) &&
        e.taskId !== event.taskId &&
        moment( e.startedDate ).isAfter( moment())
        )

        if ( events.length > 0 ) {
            for ( let j = 0; j < 7; j++ ) {
                add( view, disabled, moment( start ).add( i, 'days' ).add( j, 'days' ))
            }
        }
    }
}

export const add = ( view, disabled, date ) =>
    view === 'month'
        ? disabled.push( monthDay( date ))
        : disabled.push( weekDay( date ))

export const monthDay = ( date ) => ({
    date:      date.format( config.format.dayAPI ),
    rendering: 'background',
    color:     '#edbbbb',
})

export const weekDay = ( date ) => ({
    start:     date.startOf( 'day' ).format( config.format.date ),
    end:       date.endOf( 'day' ).format( config.format.date ),
    rendering: 'background',
    color:     '#edbbbb',
})
