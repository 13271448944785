/* VENDOR */
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Layout, Tabs, Card, Button} from 'antd'
import Icon from '@ant-design/icons'
import ReactExport from 'react-data-export'
import moment from 'moment'

/* APPLICATION */
import {
    AppHeader,
    AverageCheckRating,
    CategoriesRating,
    InnerControls,
    InnerContent,
    OverallRating,
    PerformanceRating,
    TurnoverRating,
    TimeRating,
    CustomTab,
    ReportExcelTooltip,
    Icons,
} from 'components'

import {format} from 'tools'

import {allActions, mapStateToProps} from './connector'

import './rating.scss'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet

const {Content, Header} = Layout,
      {TabPane} = Tabs,
      serviceTimeKeys = {
          fusion: 'fusion',
          hybrid: 'hybrid',
          auto: 'auto',
      }

const tabs = [ {
    key: 'turnover',
    title: 'Товарооборот'
}, {
    key: 'average',
    title: 'Средний чек'
}, {
    key: 'performance',
    title: 'Производительность'
}, {
    key: 'time',
    title: 'Время обслуживания'
}, {
    key: 'categories',
    title: 'Категории блюд'
}, {
    key: 'rating',
    title: 'Оценка'
} ]

const filterCodes = {
    cities: 'CITIES',
    regionCoaches: 'REGION_COACHES',
    marketCoaches: 'MARKET_COACHES',
    areaCoaches: 'AREA_COACHES',
    factsNumbers: 'RESTAURANTS',
    facilityTypes: 'FACILITY_TYPES',
}

class Rating extends Component {
    constructor (props) {
        super(props)

        this.state = {
            view: 'turnover',
            selected: [],
            time: {
                fusion: null,
                hybrid: null,
                auto: null,
            },
            partner: [],
            cities: [],
            regionCoaches: [],
            marketCoaches: [],
            areaCoaches: [],
            factsNumbers: [],
            facilityTypes: [],
            category: null,
            manager: null,
            restaurantId: null,
            lastModifiedFilter: null,
        }
    }

    componentDidMount () {
        const {request, time} = this.props,
              predef =
        request.currentPredef === 'shift' ? 'day' : request.currentPredef

        format.dates.setGroup( predef, this.props, () => {
            this.load( this.props )
            this.loadChart( this.props )
        })
        time && this.setTime(time)
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps (nextProps) {
        const {request, time, categories} = this.props,
              {partners} = categories,
              {shouldUpdate} = nextProps.categories

        format.check.request(
            request,
            nextProps.request,
            [],
            [ 'dateStart', 'dateEnd' ]
        ) && this.load(nextProps)

        format.check.request(request, nextProps.request, [], [ 'growing' ]) &&
    this.loadChart(nextProps)
        time !== nextProps.time && this.setTime(nextProps.time)

        if (partners !== nextProps.categories.partners) {
            nextProps.categories.partners &&
      nextProps.categories.partners.length === 1 &&
      this.setPartner(nextProps.categories.partners[ 0 ].franchiseeGroupCode)
        }

        if (shouldUpdate) {
            this.props.flushShouldUpdate()
            this.load(nextProps, true)
        }
    }

    setTime = (time) => {
        const fusion = {},
              hybrid = {},
              auto = {},
              af =
        time.all &&
        time.all.find(
            (r) => r.restaurantType.toLowerCase() === serviceTimeKeys.fusion
        ),
              ah =
        time.all &&
        time.all.find(
            (r) => r.restaurantType.toLowerCase() === serviceTimeKeys.hybrid
        ),
              aa =
        time.all &&
        time.all.find(
            (r) => r.restaurantType.toLowerCase() === serviceTimeKeys.auto
        ),
              rf =
        time.report &&
        time.report.find(
            (r) => r.restaurantType.toLowerCase() === serviceTimeKeys.fusion
        ),
              rh =
        time.report &&
        time.report.find(
            (r) => r.restaurantType.toLowerCase() === serviceTimeKeys.hybrid
        ),
              ra =
        time.report &&
        time.report.find(
            (r) => r.restaurantType.toLowerCase() === serviceTimeKeys.auto
        )

        fusion.all = af
        fusion.report = rf && rf.rating

        hybrid.all = ah
        hybrid.report = rh && rh.rating

        auto.all = aa
        auto.report = ra && ra.rating

        this.setState({
            time: {
                fusion,
                hybrid,
                auto,
            },
        })
    }

    getRequest = (props) => ({
        ...props.request,
        restaurantId: null,
        restaurantIds:
      this.state.selected && this.state.selected.length > 0
          ? this.state.selected.join(',')
          : null,
    })

    appendAverage = (params) => {
        const req = {
            ...params,
            factsNumbers: params.restaurantIds,
        }

        if (this.state.partner.length) {
            req.franchiseeGroupCodes = this.state.partner
        }
        if (this.state.cities.length) {
            req.cities = this.state.cities
        }
        if (this.state.regionCoaches.length) {
            req.regionCoachCodes = this.state.regionCoaches
        }
        if (this.state.marketCoaches.length) {
            req.marketCoachCodes = this.state.marketCoaches
        }
        if (this.state.areaCoaches.length) {
            req.areaCoachCodes = this.state.areaCoaches
        }
        if (this.state.factsNumbers.length) {
            req.factsNumbers = this.state.factsNumbers
        }
        if (this.state.facilityTypes.length) {
            req.facilityTypeIds = this.state.facilityTypes
        }

        delete req.restaurantId
        delete req.employeeId
        delete req.zoneId

        this.props.appendRatingAverageCheck(req)
    }
    appendTurnover = (params) => {
        event.preventDefault()
        const {partner} = this.state
        const req = {
            ...params,
            factsNumbers: params.restaurantIds,
            franchiseeGroupCodes: partner !== 'all' ? partner : null
        }

        if (this.state.partner.length) {
            req.franchiseeGroupCodes = this.state.partner
        }
        if (this.state.cities.length) {
            req.cities = this.state.cities
        }
        if (this.state.regionCoaches.length) {
            req.regionCoachCodes = this.state.regionCoaches
        }
        if (this.state.marketCoaches.length) {
            req.marketCoachCodes = this.state.marketCoaches
        }
        if (this.state.areaCoaches.length) {
            req.areaCoachCodes = this.state.areaCoaches
        }
        if (this.state.factsNumbers.length) {
            req.factsNumbers = this.state.factsNumbers
        }
        if (this.state.facilityTypes.length) {
            req.facilityTypeIds = this.state.facilityTypes
        }

        delete req.restaurantId
        delete req.employeeId
        delete req.zoneId

        return this.props.appendRatingTurnover(req)
    }

    appendPerformance = (params) => {
        const req = {
            ...params,
            factsNumbers: params.restaurantIds,
        }

        if (this.state.partner.length) {
            req.franchiseeGroupCodes = this.state.partner
        }
        if (this.state.cities.length) {
            req.cities = this.state.cities
        }
        if (this.state.regionCoaches.length) {
            req.regionCoachCodes = this.state.regionCoaches
        }
        if (this.state.marketCoaches.length) {
            req.marketCoachCodes = this.state.marketCoaches
        }
        if (this.state.areaCoaches.length) {
            req.areaCoachCodes = this.state.areaCoaches
        }
        if (this.state.factsNumbers.length) {
            req.factsNumbers = this.state.factsNumbers
        }
        if (this.state.facilityTypes.length) {
            req.facilityTypeIds = this.state.facilityTypes
        }

        delete req.restaurantId
        delete req.employeeId
        delete req.zoneId

        this.props.appendRatingPerformance(req)
    }
    appendServiceTime = (params) => {
        const req = {
            ...params,
            factsNumbers: params.restaurantIds,
        }

        if (this.state.partner.length) {
            req.franchiseeGroupCodes = this.state.partner
        }
        if (this.state.cities.length) {
            req.cities = this.state.cities
        }
        if (this.state.regionCoaches.length) {
            req.regionCoachCodes = this.state.regionCoaches
        }
        if (this.state.marketCoaches.length) {
            req.marketCoachCodes = this.state.marketCoaches
        }
        if (this.state.areaCoaches.length) {
            req.areaCoachCodes = this.state.areaCoaches
        }
        if (this.state.factsNumbers.length) {
            req.factsNumbers = this.state.factsNumbers
        }
        if (this.state.facilityTypes.length) {
            req.facilityTypeIds = this.state.facilityTypes
        }

        delete req.restaurantId
        delete req.employeeId
        delete req.zoneId

        this.props.appendServiceTime(req)
    }

    load = (rprops, silent) => {
        const props = rprops || this.props,
              {partner} = this.state,
              request = {...props.request}
        if (request.restaurantIds) {
            request.factsNumbers = request.restaurantIds
            delete request.restaurantIds
        }

        if (this.state.partner.length) {
            request.franchiseeGroupCodes = this.state.partner
        }
        if (this.state.cities.length) {
            request.cities = this.state.cities
        }
        if (this.state.regionCoaches.length) {
            request.regionCoachCodes = this.state.regionCoaches
        }
        if (this.state.marketCoaches.length) {
            request.marketCoachCodes = this.state.marketCoaches
        }
        if (this.state.areaCoaches.length) {
            request.areaCoachCodes = this.state.areaCoaches
        }
        if (this.state.factsNumbers.length) {
            request.factsNumbers = this.state.factsNumbers
        }
        if (this.state.facilityTypes.length) {
            request.facilityTypeIds = this.state.facilityTypes
        }

        delete request.restaurantId
        delete request.employeeId
        delete request.zoneId

        switch (this.state.view) {
            case 'average':
                props.flushAverageRating()
                props.fetchAllAverageCheck(request)
                props.fetchRatingAverageCheck(request)
                break
            case 'performance':
                props.flushPerformanceRating()
                props.fetchRatingPerformance(request)
                props.fetchSummaryPerformance(request)
                break
            case 'time':
                props.flushTimeRating()
                props.fetchAllServiceTime({...request, serviceType: null})
                props.fetchServiceTime({...request, serviceType: null})
                break
            case 'categories':
                !silent && props.flushCategoriesRating()
                this.props.fetchFilters()
                this.props.fetchCategoriesRating({
                    ...request,
                    franchiseeGroupCodes: partner,
                })

                if (silent) {
                    const {category, manager} = this.state

                    category &&
          this.props.fetchCategoryManagers({
              ...request,
              categoryId: category.categoryId,
          })
                    manager &&
          this.props.fetchManagerRestaurants({
              ...request,
              categoryId: category.categoryId,
              userId: manager.userId,
          })
                }
                break
            case 'turnover':
                // eslint-disable-next-line no-case-declarations
                const req = {
                    ...request,
                    franchiseeGroupCodes: partner !== 'all' ? partner : null,
                }

                this.props.fetchFilters()
                this.props.flushTurnoverRating()
                this.props.fetchAllTurnover( req )
                this.props.fetchRatingTurnover( req )
                break
            default:
                // props.fetchFilters()
                props.flushTurnoverRating()
                // props.fetchAllTurnover( req )
                // props.fetchRatingTurnover( req )
                break
        }
    }

    loadChart = (rprops) => {
        const props = rprops || this.props,
              request = this.getRequest(props),
              {view} = this.state,
              req = {...request}

        if ( sessionStorage.getItem( 'filtersData' )) {
            const filtersDataObj = JSON.parse( sessionStorage.getItem( 'filtersData' ))
            req.dateStart = filtersDataObj.start
            req.dateEnd = filtersDataObj.end
            req.currentPredef = filtersDataObj.predef
            req.dateGroup = filtersDataObj.group
        }

        if ( this.state.partner.length ) { req.franchiseeGroupCodes = this.state.partner }
        if ( this.state.cities.length ) { req.cities = this.state.cities }
        if ( this.state.regionCoaches.length ) { req.regionCoachCodes = this.state.regionCoaches }
        if ( this.state.marketCoaches.length ) { req.marketCoachCodes = this.state.marketCoaches }
        if ( this.state.areaCoaches.length ) { req.areaCoachCodes = this.state.areaCoaches }
        if ( this.state.factsNumbers.length ) { req.factsNumbers = this.state.factsNumbers }
        if ( this.state.facilityTypes.length ) { req.facilityTypeIds = this.state.facilityTypes }
        if ( request.restaurantIds ) { req.factsNumbers = request.restaurantIds }

        delete req.restaurantIds
        delete req.restaurantId
        delete req.zoneId
        delete req.employeeId

        if (!format.check.enough(props, true)) {
            return
        }

        switch (view) {
            case 'performance':
                props.flushPerformanceChart()
                props.fetchPerformancePlanAndFact(req)
                break
            case 'turnover':
                props.flushTurnoverChart()
                props.fetchTurnoverPlanAndFact(req)
                break
            default:
      //Do nothhing
        }
    }

    loadOverall = (date) => {
        this.props.flushOverall()
        this.props.fetchOverall({date})
    }

    updateOverall = (body) => this.props.updateOverall(body)

    select = (selected) => {
        const rids = selected && selected.length > 0 ? selected : null

        this.setState({selected: rids || []}, () =>
            this.loadChart({
                ...this.props,
                request: this.getRequest(this.props),
            })
        )
    }

    set = (data) => {
        this.props.setRequest(data)
    }

    setView = (view) =>
        this.setState({view}, () => {
            this.load()
            this.loadChart()
        })

    setPartner = (partner) => {
        const {view} = this.state
        if (partner === this.state.partner) {
            return
        }

        this.setState(
            {
                ...this.state,
                partner,
                category: null,
                manager: null,
                restaurantId: null,
                selected: [],
                lastModifiedFilter: 'FRANCHISEE_GROUPS'
            })

        setTimeout(() => {
            this.setState(
                {
                    ...this.state,
                    partner,
                    category: null,
                    manager: null,
                    restaurantId: null,
                    selected: [],
                },
                () => {
                    const request = {
                        ...this.props.request,
                        franchiseeGroupCodes: partner,
                        cities: this.state.cities,
                        regionCoachCodes: this.state.regionCoaches,
                        marketCoachCodes: this.state.marketCoaches,
                        areaCoachCodes: this.state.areaCoaches,
                        factsNumbers: this.state.factsNumbers,
                        facilityTypeIds: this.state.facilityTypes,
                    }

                    if (request.restaurantIds?.length) {
                        request.factsNumbers = request.restaurantIds
                        delete request.restaurantIds
                    }

                    delete request.restaurantId
                    delete request.employeeId
                    delete request.zoneId


                    this.props.flushCategoriesRating()
                    this.props.flushTurnoverRating()
                    this.props.flushTurnoverChart()

                    if (view === 'turnover') {
                        this.props.fetchRatingTurnover(request)
                        this.props.fetchAllTurnover(request)
                        this.props.fetchTurnoverPlanAndFact(request)
                    }

                    if (view === 'average') {
                        this.props.flushAverageRating()
                        this.props.fetchAllAverageCheck(request)
                        this.props.fetchRatingAverageCheck(request)
                    }

                    if (view === 'time') {
                        this.props.flushTimeRating()
                        this.props.fetchAllServiceTime({...request, serviceType: null})
                        this.props.fetchServiceTime({...request, serviceType: null})
                    }

                    if (view === 'categories') {
                        this.props.fetchCategoriesRating(request)
                    }

                    if (view === 'performance') {
                        this.props.fetchRatingPerformance(request)
                        this.props.fetchPerformancePlanAndFact(request)
                        this.props.fetchSummaryPerformance(request)
                    }
                }
            )
        }, 1000)
    }

    setFilter = (filter) => {
        const {view} = this.state
        if (filter.name === this.state[ filter.value ]) {
            return
        }

        this.setState(
            {
                [ filter.name ]: filter.value,
                category: null,
                manager: null,
                restaurantId: null,
                selected: [],
                lastModifiedFilter: filterCodes[ filter.name ]
            })

        setTimeout(() => {
            this.setState(
                {
                    [ filter.name ]: filter.value,
                    category: null,
                    manager: null,
                    restaurantId: null,
                    selected: [],
                },
                () => {
                    const request = {
                        ...this.props.request,
                        franchiseeGroupCodes: this.state.partner,
                        cities: this.state.cities,
                        regionCoachCodes: this.state.regionCoaches,
                        marketCoachCodes: this.state.marketCoaches,
                        areaCoachCodes: this.state.areaCoaches,
                        factsNumbers: this.state.factsNumbers,
                        facilityTypeIds: this.state.facilityTypes,
                    }

                    if (request.restaurantIds?.length) {
                        request.factsNumbers = request.restaurantIds
                        delete request.restaurantIds
                    }

                    delete request.restaurantId
                    delete request.employeeId
                    delete request.zoneId

                    this.props.flushCategoriesRating()
                    this.props.flushTurnoverRating()
                    this.props.flushTurnoverChart()

                    if (view === 'turnover') {
                        this.props.fetchRatingTurnover(request)
                        this.props.fetchAllTurnover(request)
                        this.props.fetchTurnoverPlanAndFact(request)
                    }

                    if (view === 'average') {
                        this.props.flushAverageRating()
                        this.props.fetchAllAverageCheck(request)
                        this.props.fetchRatingAverageCheck(request)
                    }

                    if (view === 'time') {
                        this.props.flushTimeRating()
                        this.props.fetchAllServiceTime({...request, serviceType: null})
                        this.props.fetchServiceTime({...request, serviceType: null})
                    }

                    if (view === 'categories') {
                        this.props.fetchCategoriesRating(request)
                    }

                    if (view === 'performance') {
                        this.props.fetchRatingPerformance(request)
                        this.props.fetchPerformancePlanAndFact(request)
                        this.props.fetchSummaryPerformance(request)
                    }
                }
            )
        }, 1000)
    }

    setCategory = (raw) => {
        const {category} = this.state,
              cat =
        !raw || (category && category.categoryId === raw.categoryId)
            ? null
            : raw

        this.setState(
            {
                category: cat,
                manager: null,
                restaurantId: null,
            },
            () => {
                const {user} = this.props


                const reqObj = {...this.props.request}
                delete reqObj.zoneId

                if (user.marketManager) {
                    this.props.flushCategoryManagers()
                    cat &&
          this.props.fetchCategoryManagers({
              ...reqObj,
              categoryId: cat.categoryId,
          })
                } else {
                    cat && this.setManager(user)
                }
            }
        )
    }

    setManager = (raw) => {
        const {manager} = this.state,
              man = !raw || (manager && manager.userId === raw.userId) ? null : raw

        this.setState(
            {manager: man},
            () => {
                const {user} = this.props
                const request = {
                    ...this.props.request,
                    franchiseeGroupCodes: this.state.partner,
                    cities: this.state.cities,
                    regionCoachCodes: this.state.regionCoaches,
                    marketCoachCodes: this.state.marketCoaches,
                    areaCoachCodes: this.state.areaCoaches,
                    factsNumbers: this.state.factsNumbers,
                    facilityTypeIds: this.state.facilityTypes,
                }


                const reqObj = {...request}
                delete reqObj.zoneId


                this.props.flushManagerRestaurants()
                man &&
        this.props.fetchManagerRestaurants({
            ...reqObj,
            categoryId: this.state.category.categoryId,
            userId: man.userId,
        })

                if (!user.marketManager && !man) {
                    this.setCategory(null)
                }
            }
        )
    }

    tab = (key) => {
        const {
            selected,
            partner,
            time,
            manager,
            category,
            cities,
            regionCoaches,
            marketCoaches,
            areaCoaches,
            factsNumbers,
            facilityTypes
        } = this.state
        const {request, turnover, categories, filters, user, overall, average, performance} = this.props
        const currency = format.extract.currency(request.unitOfMeasure)

        switch (key) {
            case 'turnover':
                return <TurnoverRating
                    request={request}
                    data={turnover}
                    currency={currency}
                    selected={selected}
                    partner={partner}
                    facilityType={facilityTypes}
                    areaCoach={areaCoaches}
                    marketCoach={marketCoaches}
                    factsNumbers={factsNumbers}
                    city={cities}
                    regionCoach={regionCoaches}
                    partners={format.generate.options(
                        filters?.franchiseeGroups,
                        'code',
                        'name'
                    )}
                    facilityTypes={format.generate.options(
                        filters?.facilityTypes,
                        'id',
                        'name'
                    )}
                    areaCoaches={format.generate.options(
                        filters?.areaCoaches,
                        'code',
                        'name'
                    )}
                    marketCoaches={format.generate.options(
                        filters?.marketCoaches,
                        'code',
                        'name'
                    )}
                    cities={format.generate.optionsStr(
                        filters?.cities
                    )}
                    regionCoaches={format.generate.options(
                        filters?.regionCoaches,
                        'code',
                        'name'
                    )}
                    restaurants={format.generate.options(
                        filters?.restaurants,
                        'factsNumber',
                        'restaurantName'
                    )}
                    onControls={this.set}
                    onSelect={this.select}
                    onPartner={this.setPartner}
                    onMore={this.appendTurnover}
                    onFilter={this.setFilter}
                    onFocus={() => {
                        const req = {}
                        if (this.state.partner.length) {
                            req.franchiseeGroupCodes = this.state.partner
                        }
                        if (this.state.cities.length) {
                            req.cities = this.state.cities
                        }
                        if (this.state.regionCoaches.length) {
                            req.regionCoachCodes = this.state.regionCoaches
                        }
                        if (this.state.marketCoaches.length) {
                            req.marketCoachCodes = this.state.marketCoaches
                        }
                        if (this.state.areaCoaches.length) {
                            req.areaCoachCodes = this.state.areaCoaches
                        }
                        if (this.state.factsNumbers.length) {
                            req.factsNumbers = this.state.factsNumbers
                        }
                        if (this.state.facilityTypes.length) {
                            req.facilityTypeIds = this.state.facilityTypes
                        }
                        req.lastModifiedFilter = this.state.lastModifiedFilter

                        this.props.fetchFilters(req)
                    }
                    }
                />
            case 'average':
                return <AverageCheckRating
                    data={average}
                    partner={partner}
                    facilityType={facilityTypes}
                    regionCoach={regionCoaches}
                    marketCoach={marketCoaches}
                    areaCoach={areaCoaches}
                    city={cities}
                    factsNumbers={factsNumbers}
                    partners={format.generate.options(
                        filters?.franchiseeGroups,
                        'code',
                        'name'
                    )}
                    facilityTypes={format.generate.options(
                        filters?.facilityTypes,
                        'id',
                        'name'
                    )}
                    cities={format.generate.optionsStr(
                        filters?.cities
                    )}
                    regionCoaches={format.generate.options(
                        filters?.regionCoaches,
                        'code',
                        'name'
                    )}
                    marketCoaches={format.generate.options(
                        filters?.marketCoaches,
                        'code',
                        'name'
                    )}
                    areaCoaches={format.generate.options(
                        filters?.areaCoaches,
                        'code',
                        'name'
                    )}
                    restaurants={format.generate.options(
                        filters?.restaurants,
                        'factsNumber',
                        'restaurantName'
                    )}
                    onMore={this.appendAverage}
                    onPartner={this.setPartner}
                    onFilter={this.setFilter}
                    onSelect={this.select}
                    onControls={this.set}
                    onFocus={() => {
                        const req = {}
                        if (this.state.partner.length) {
                            req.franchiseeGroupCodes = this.state.partner
                        }
                        if (this.state.cities.length) {
                            req.cities = this.state.cities
                        }
                        if (this.state.regionCoaches.length) {
                            req.regionCoachCodes = this.state.regionCoaches
                        }
                        if (this.state.marketCoaches.length) {
                            req.marketCoachCodes = this.state.marketCoaches
                        }
                        if (this.state.areaCoaches.length) {
                            req.areaCoachCodes = this.state.areaCoaches
                        }
                        if (this.state.factsNumbers.length) {
                            req.factsNumbers = this.state.factsNumbers
                        }
                        if (this.state.facilityTypes.length) {
                            req.facilityTypeIds = this.state.facilityTypes
                        }
                        req.lastModifiedFilter = this.state.lastModifiedFilter

                        this.props.fetchFilters(req)
                    }
                    }
                />
            case 'performance':
                return <PerformanceRating
                    currency={currency}
                    request={request}
                    data={performance}
                    selected={selected}
                    partner={partner}
                    facilityType={facilityTypes}
                    regionCoach={regionCoaches}
                    marketCoach={marketCoaches}
                    areaCoach={areaCoaches}
                    city={cities}
                    factsNumbers={factsNumbers}
                    partners={format.generate.options(
                        filters?.franchiseeGroups,
                        'code',
                        'name'
                    )}
                    facilityTypes={format.generate.options(
                        filters?.facilityTypes,
                        'id',
                        'name'
                    )}
                    cities={format.generate.optionsStr(
                        filters?.cities
                    )}
                    regionCoaches={format.generate.options(
                        filters?.regionCoaches,
                        'code',
                        'name'
                    )}
                    marketCoaches={format.generate.options(
                        filters?.marketCoaches,
                        'code',
                        'name'
                    )}
                    areaCoaches={format.generate.options(
                        filters?.areaCoaches,
                        'code',
                        'name'
                    )}
                    restaurants={format.generate.options(
                        filters?.restaurants,
                        'factsNumber',
                        'restaurantName'
                    )}
                    onPartner={this.setPartner}
                    onFilter={this.setFilter}
                    onSelect={this.select}
                    onMore={this.appendPerformance}
                    onControls={this.set}
                    onFocus={() => {
                        const req = {}
                        if (this.state.partner.length) {
                            req.franchiseeGroupCodes = this.state.partner
                        }
                        if (this.state.cities.length) {
                            req.cities = this.state.cities
                        }
                        if (this.state.regionCoaches.length) {
                            req.regionCoachCodes = this.state.regionCoaches
                        }
                        if (this.state.marketCoaches.length) {
                            req.marketCoachCodes = this.state.marketCoaches
                        }
                        if (this.state.areaCoaches.length) {
                            req.areaCoachCodes = this.state.areaCoaches
                        }
                        if (this.state.factsNumbers.length) {
                            req.factsNumbers = this.state.factsNumbers
                        }
                        if (this.state.facilityTypes.length) {
                            req.facilityTypeIds = this.state.facilityTypes
                        }
                        req.lastModifiedFilter = this.state.lastModifiedFilter

                        this.props.fetchFilters(req)
                    }
                    }
                />
            case 'time':
                return <TimeRating
                    data={time}
                    fusion={time.fusion}
                    hybrid={time.hybrid}
                    auto={time.auto}
                    partner={partner}
                    facilityType={facilityTypes}
                    regionCoach={regionCoaches}
                    marketCoach={marketCoaches}
                    areaCoach={areaCoaches}
                    city={cities}
                    factsNumbers={factsNumbers}
                    partners={format.generate.options(
                        filters?.franchiseeGroups,
                        'code',
                        'name'
                    )}
                    facilityTypes={format.generate.options(
                        filters?.facilityTypes,
                        'id',
                        'name'
                    )}
                    cities={format.generate.optionsStr(
                        filters?.cities
                    )}
                    regionCoaches={format.generate.options(
                        filters?.regionCoaches,
                        'code',
                        'name'
                    )}
                    marketCoaches={format.generate.options(
                        filters?.marketCoaches,
                        'code',
                        'name'
                    )}
                    areaCoaches={format.generate.options(
                        filters?.areaCoaches,
                        'code',
                        'name'
                    )}
                    restaurants={format.generate.options(
                        filters?.restaurants,
                        'factsNumber',
                        'restaurantName'
                    )}
                    onPartner={this.setPartner}
                    onFilter={this.setFilter}
                    onSelect={this.select}
                    onMore={this.appendServiceTime}
                    onControls={this.set}
                    onFocus={() => {
                        const req = {}
                        if (this.state.partner.length) {
                            req.franchiseeGroupCodes = this.state.partner
                        }
                        if (this.state.cities.length) {
                            req.cities = this.state.cities
                        }
                        if (this.state.regionCoaches.length) {
                            req.regionCoachCodes = this.state.regionCoaches
                        }
                        if (this.state.marketCoaches.length) {
                            req.marketCoachCodes = this.state.marketCoaches
                        }
                        if (this.state.areaCoaches.length) {
                            req.areaCoachCodes = this.state.areaCoaches
                        }
                        if (this.state.factsNumbers.length) {
                            req.factsNumbers = this.state.factsNumbers
                        }
                        if (this.state.facilityTypes.length) {
                            req.facilityTypeIds = this.state.facilityTypes
                        }
                        req.lastModifiedFilter = this.state.lastModifiedFilter

                        this.props.fetchFilters(req)
                    }
                    }
                />
            case 'categories':
                return <CategoriesRating
                    category={category}
                    manager={manager}
                    user={user}
                    partner={partner}
                    facilityType={facilityTypes}
                    regionCoach={regionCoaches}
                    marketCoach={marketCoaches}
                    areaCoach={areaCoaches}
                    city={cities}
                    factsNumbers={factsNumbers}
                    restaurants={categories.restaurants}
                    partners={format.generate.options(
                        filters?.franchiseeGroups,
                        'code',
                        'name'
                    )}
                    facilityTypes={format.generate.options(
                        filters?.facilityTypes,
                        'id',
                        'name'
                    )}
                    cities={format.generate.optionsStr(
                        filters?.cities
                    )}
                    regionCoaches={format.generate.options(
                        filters?.regionCoaches,
                        'code',
                        'name'
                    )}
                    marketCoaches={format.generate.options(
                        filters?.marketCoaches,
                        'code',
                        'name'
                    )}
                    areaCoaches={format.generate.options(
                        filters?.areaCoaches,
                        'code',
                        'name'
                    )}
                    rests={format.generate.options(
                        filters?.restaurants,
                        'factsNumber',
                        'restaurantName'
                    )}
                    categories={categories.list}
                    managers={categories.managers}
                    onCategory={this.setCategory}
                    onPartner={this.setPartner}
                    onManager={this.setManager}
                    onUpdate={this.props.updateCategoryRating}
                    onFilter={this.setFilter}
                    onSelect={this.select}
                    onMore={this.appendServiceTime}
                    onControls={this.set}
                    onFocus={() => {
                        const req = {}
                        if (this.state.partner.length) {
                            req.franchiseeGroupCodes = this.state.partner
                        }
                        if (this.state.cities.length) {
                            req.cities = this.state.cities
                        }
                        if (this.state.regionCoaches.length) {
                            req.regionCoachCodes = this.state.regionCoaches
                        }
                        if (this.state.marketCoaches.length) {
                            req.marketCoachCodes = this.state.marketCoaches
                        }
                        if (this.state.areaCoaches.length) {
                            req.areaCoachCodes = this.state.areaCoaches
                        }
                        if (this.state.factsNumbers.length) {
                            req.factsNumbers = this.state.factsNumbers
                        }
                        if (this.state.facilityTypes.length) {
                            req.facilityTypeIds = this.state.facilityTypes
                        }
                        req.lastModifiedFilter = this.state.lastModifiedFilter

                        this.props.fetchFilters(req)
                    }
                    }

                />
            case 'rating':
            default:
                return <OverallRating
                    data={overall}
                    fetchData={this.loadOverall}
                    update={this.updateOverall}
                />
        }
    }

    showExcelTooltip = (tab) => {
        const {turnover, categories, average, performance, time} = this.props

        const sum = (array, field) => array.reduce((acc, cur) => acc + (typeof cur[ field ] === 'number' ? cur[ field ] : 0), 0)

        switch (tab.key) {
            case 'turnover': {
                if (!turnover?.report?.content || turnover?.report?.content?.length === 0) {
                    return true
                }

                return format.check.allContainsNullOrZero([
                    {
                        restaurantName: 'Все рестораны',
                        ...turnover.all
                    },
                    ...turnover.report.content
                ], [ 'factTurnover' ])
            }
            case 'average': {
                if (!average?.report?.content || average?.report?.content?.length === 0 || !average?.all) {
                    return true
                }

                return format.check.allContainsNullOrZero([
                    {
                        restaurantName: 'В среднем по всем ресторанам',
                        ...average.all
                    },
                    ...average.report.content
                ], [ 'factAverageCheck', 'planAverageCheck' ])
            }
            case 'performance': {
                if (!performance?.report?.content || performance?.report?.content?.length === 0) {
                    return true
                }

                const fields = [ 'factSpmh', 'planSpmh', 'factTcph', 'planTcph', 'factValue', 'planValue', 'factTransactions', 'factProductiveHours', 'planProductiveHours' ]

                return format.check.allContainsNullOrZero([
                    {
                        restaurant: 'Все рестораны',
                        ...performance.all
                    },
                    ...performance.report.content
                ], fields)
            }
            case 'categories': {
                if (!categories?.list || performance?.list?.length === 0) {
                    return true
                }

                return format.check.allContainsNullOrZero([
                    {
                        categoryName: 'Все категории блюд',
                        ...categories.list.summary,
                    },
                    categories.list.elements ], [ 'factCategoryRating', 'planCategoryRating', 'deltaCategoryRating' ])
            }
            case 'time': {
                if (!time || !time.all || !time.report || time.report.length === 0) {
                    return true
                }

                return format.check.allContainsNullOrZero(time.all, [ 'deltaServiceTime', 'factServiceTime', 'hit' ]) &&
          [
              ...time.report.map(
                  report => format.check.allContainsNullOrZero(report.rating.content, [ 'deltaTurnover', 'factServiceTime', 'hit' ])
              )
          ]?.every(check => check)
            }
        }
    }

    prepareExcel = (tab) => {
        const {request, turnover, categories, average, performance, time} = this.props
        const currency = format.extract.currency(request.unitOfMeasure)
        const red = '00FF0000'
        const green = '00038E1D'
        const black = '00000000'
        const sum = (array, field) => array.reduce((acc, cur) => acc + (typeof cur[ field ] === 'number' ? cur[ field ] : 0), 0)
        const getFormattedValue = (value, postfix, digits = null) => {
            if (value !== null) {
                return `${digits !== null ? (+value).toFixed(digits) : value}${postfix === '%' ? postfix : ''}`
            }
            return '-'
        }

        switch (tab.key) {
            case 'turnover': {
                if (!turnover?.report?.content || turnover?.report?.content?.length === 0) {
                    return []
                }

                const result = [ {
                    columns: [
                        {title: 'Код'},
                        {title: 'Ресторан', width: {wpx: 200}},
                        {title: '% выполнения ТО'},
                        {title: '% выполнения ТО текущий'},
                        {title: 'План ТО ТУ'},
                        {title: 'План ТО Директора'},
                        {title: 'Разница План ТО'},
                        {title: 'ТО Директора текущий'},
                        {title: 'Факт ТО'},
                        {title: 'Разница Факт и План ТО ТУ'},
                        {title: 'Разница Факт и План ТО Директора'},
                        {title: 'Разница Факт и План ТО Директора текущий'},
                    ],
                    data: [
                        {
                            restaurantName: 'Все Рестораны',
                            ...turnover.all
                        },
                        ...turnover.report.content
                    ].map(( item => ([
                        {value: item.factsNumber || ''},
                        {value: item.restaurantName},
                        {value: getFormattedValue(item.percentage ?? 0, '%', 0)},
                        {value: getFormattedValue(item.currentPercentage ?? 0, '%', 0)},
                        {value: getFormattedValue(item.planTurnoverAreaCoach, currency, 0)},
                        {value: getFormattedValue(item.planTurnoverRgm, currency, 0)},

                        {
                            value: getFormattedValue(item.deltaPlanTurnover, currency, 0),
                            style: {font: {color: {rgb: item.deltaPlanTurnover < 0 ? green : (item.deltaPlanTurnover > 0 ? red : black)}}}
                        },

                        {value: getFormattedValue(item.planTurnoverRgmCurrent, currency, 0)},
                        {value: getFormattedValue(item.factTurnover, currency, 0)},

                        {
                            value: getFormattedValue(item.deltaTurnoverAreaCoach, currency, 0),
                            style: {font: {color: {rgb: item.deltaTurnoverAreaCoach < 0 ? red : (item.deltaTurnoverAreaCoach > 0 ? green : black)}}}
                        },

                        {
                            value: getFormattedValue(item.deltaTurnoverRgm, currency, 0),
                            style: {font: {color: {rgb: item.deltaTurnoverRgm < 0 ? red : (item.deltaTurnoverRgm > 0 ? green : black)}}}
                        },

                        {
                            value: getFormattedValue(item.deltaTurnoverRgmCurrent, currency, 0),
                            style: {font: {color: {rgb: item.deltaTurnoverRgmCurrent < 0 ? red : (item.deltaTurnoverRgmCurrent > 0 ? green : black)}}}
                        },

                    ])
                    ))
                } ]

                return <ExcelSheet dataSet={result} name={tab.title}/>
            }
            case 'average': {
                if ( !average?.report?.content || average?.report?.content?.length === 0 || !average?.all ) {
                    return []
                }

                const result = [ {
                    columns: [
                        {title: 'Код'},
                        {title: 'Ресторан', width: {wpx: 200}},
                        {title: 'Средний чек, руб'},
                        {title: 'Цель, руб'},
                        {title: 'Разница, руб'},
                    ],
                    data: [
                        {
                            factsNumber:       '',
                            restaurantName:    'В среднем по всем ресторанам',
                            factAverageCheck:  average.all?.factAverageCheck,
                            planAverageCheck:  average.all?.planAverageCheck,
                            deltaAverageCheck: average.all?.deltaAverageCheck,
                        },
                        ...average.report.content
                    ].map(( item => ([
                        { value: item.factsNumber },
                        { value: item.restaurantName },
                        { value: getFormattedValue( item.factAverageCheck, currency, 0 ) },
                        { value: getFormattedValue( item.planAverageCheck, currency, 0 ) },
                        {
                            value: getFormattedValue(item.deltaAverageCheck, currency, 0),
                            style: {font: {color: {rgb: item.deltaAverageCheck < 0 ? red : (item.deltaAverageCheck > 0 ? green : black)}}}
                        },
                    ])
                    ))
                } ]

                return <ExcelSheet dataSet={result} name={tab.title}/>
            }
            case 'performance': {
                if (!performance?.report?.content || performance?.report?.content?.length === 0) {
                    return []
                }

                const result = [ {
                    columns: [
                        { title: 'Код' },
                        { title: 'Ресторан', width: { wpx: 200 } },
                        { title: 'План ТО ТУ' },
                        { title: 'План ТО Директора' },
                        { title: 'Разница План ТО' },
                        { title: 'Факт ТО' },
                        { title: 'Разница Факт и План ТО ТУ' },
                        { title: 'Разница Факт и План ТО Директора' },
                        { title: 'План ТРЗ' },
                        { title: 'Факт ТРЗ' },
                        { title: 'План СЧ' },
                        { title: 'Факт СЧ' },
                        { title: 'Цель SPMH ТУ' },
                        { title: 'План SPMH Директора' },
                        { title: 'Факт SPMH' },
                        { title: 'План TCPH' },
                        { title: 'Факт TCPH' },
                        { title: 'Items Per Hour' },
                        { title: 'Количество блюд' },
                        { title: 'Запланировано часов' },
                        { title: 'Отработано часов' },
                    ],
                    data: [
                        {
                            restaurantName: 'Все рестораны',
                            ...performance.all
                        },
                        ...performance.report.content
                    ].map((item => ([
                        {value: item.factsNumber !== undefined ? `${item.factsNumber}` : ''},
                        {value: item.restaurantName},
                        {value: getFormattedValue(item.planTurnoverAreaCoach ?? 0, currency, 0)},
                        {value: getFormattedValue(item.planTurnoverRgm ?? 0, currency, 0)},

                        {
                            value: getFormattedValue(item.deltaPlanTurnover ?? 0, currency, 0),
                            style: {font: {color: {rgb: item.deltaPlanTurnover < 0 ? green : (item.deltaPlanTurnover > 0 ? red : black)}}}
                        },


                        {value: getFormattedValue(item.factTurnover ?? 0, currency, 0)},

                        {
                            value: getFormattedValue(item.deltaTurnoverAreaCoach ?? 0, currency, 0),
                            style: {font: {color: {rgb: item.deltaTurnoverAreaCoach < 0 ? red : (item.deltaTurnoverAreaCoach > 0 ? green : black)}}}
                        },

                        {
                            value: getFormattedValue(item.deltaTurnoverRgm ?? 0, currency, 0),
                            style: {font: {color: {rgb: item.deltaTurnoverRgm < 0 ? red : (item.deltaTurnoverRgm > 0 ? green : black)}}}
                        },

                        {value: getFormattedValue(item.planTransactions ?? 0, currency, 0)},
                        {
                            value: getFormattedValue(item.factTransactions ?? 0, currency, 0),
                            style: {font: {color: {rgb: item.factTransactions < item.planTransactions ? red : (item.factTransactions >= item.planTransactions ? green : black)}}}
                        },

                        {value: getFormattedValue(item.planAverageCheck ?? 0, currency, 0)},
                        {
                            value: getFormattedValue(item.factAverageCheck ?? 0, currency, 0),
                            style: {font: {color: {rgb: item.factAverageCheck < item.planAverageCheck ? red : (item.factAverageCheck >= item.planAverageCheck ? green : black)}}}
                        },

                        {value: getFormattedValue(item.goalSpmh ?? 0, currency, 0)},
                        {value: getFormattedValue(item.planSpmh ?? 0, currency, 0)},
                        {value: getFormattedValue(item.factSpmh ?? 0, currency, 0)},

                        {value: parseFloat(item.planTcph ?? 0)},
                        {value: parseFloat(item.factTcph ?? 0 )},

                        {value: item.factItemsPerHour ?? 0},
                        {value: getFormattedValue(item.allProductsInChecks ?? 0, currency, 0)},

                        {value: parseFloat(item.planProductiveUnproductiveHours ?? 0)},
                        {value: parseFloat(item.factProductiveUnproductiveHours ?? 0)},
                    ])
                    ))
                } ]


                return <ExcelSheet dataSet={result} name={tab.title}/>
            }
            case 'categories': {
                if (!categories?.list || performance?.list?.length === 0) {
                    return []
                }

                const result = [ {
                    columns: [
                        { title: 'Категория блюд', width: { wpx: 200 } },
                        { title: 'План' },
                        { title: 'Факт' },
                        { title: 'Разница' },
                    ],
                    data: categories.list.elements.map(item => [
                        {value: item.categoryName},
                        {value: getFormattedValue(item.planCategoryRating, '%', 2)},
                        {value: getFormattedValue(item.factCategoryRating, '%', 2)},
                        {
                            value: getFormattedValue(item.deltaCategoryRating, '%', 2),
                            style: {font: {color: {rgb: item?.deltaCategoryRating < 0 ? red : (item?.deltaCategoryRating > 0 ? green : black)}}}
                        },
                    ])
                } ]

                return <ExcelSheet dataSet={result} name={tab.title}/>
            }
            case 'time': {
                if (!time || !time.all || !time.report || time.report.length === 0) {
                    return []
                }

                const font = {name: 'Arial', sz: '10', bold: false}
                const emptyCol = {title: '', style: {font}}
                const emptyData = {value: '', style: {font}}

                const results = {}

                time.all.forEach((report) => {
                    const colsHeader = []
                    const dataHeader = []
                    const cols = []
                    const data = []

                    colsHeader.push({
                        title:
              `${format.strings.capitalize(report.restaurantType)} в среднем ${format.strings.seconds(report.factServiceTime)} / ${format.strings.seconds(report.planServiceTime)} ${(report.hit || 0)}%`,
                        style: {
                            font: {
                                ...font,
                                sz: '14'
                            }
                        }
                    })
                    dataHeader.push(emptyData)
                    cols.push({
                        title: 'Код',
                        style: {font: {...font, bold: true}}
                    })
                    cols.push({
                        title: 'Ресторан',
                        width: {wpx: 240},
                        style: {font: {...font, bold: true}}
                    })
                    cols.push({title: 'Время', style: {font: {...font, bold: true}}})
                    cols.push({title: '', width: {wpx: 40}, style: {font: {...font, bold: true}}})
                    cols.push({title: '', width: {wpx: 40}, style: {font: {...font, bold: true}}})

                    ;[ 1, 2, 3 ].forEach(() => {
                        colsHeader.push(emptyCol)
                        dataHeader.push(emptyData)
                    })

                    const content = time.report.find(item => report.restaurantType === item.restaurantType)?.rating?.content

                    content?.forEach(item => {
                        data.push([])
                        data[ data.length - 1 ].push({value: item.factsNumber})
                        data[ data.length - 1 ].push({value: item.restaurantName, style: {font}})
                        data[ data.length - 1 ].push({value: format.strings.seconds(item.factServiceTime), style: {font}})
                        data[ data.length - 1 ].push({
                            value: item.deltaServiceTime !== null ? format.strings.seconds(item.deltaServiceTime) : '—',
                            style: {font: {...font, color: {rgb: item.deltaServiceTime < 0 ? green : ( item.deltaServiceTime > 0 ? red : black)}}}
                        })
                        data[ data.length - 1 ].push({value: `${(item.hit ?? 0)}%`, style: {font}})
                    })

                    results[ report.restaurantType ] = [
                        {
                            columns: colsHeader,
                            data: [ dataHeader ]
                        },
                        {
                            ySteps: -1,
                            columns: cols,
                            data: data
                        }
                    ]
                })

                return time.report.map((report) => <ExcelSheet
                    key={report.restaurantType}
                    dataSet={results[ report.restaurantType ]}
                    name={`${tab.title}.${format.strings.capitalize(report.restaurantType)}`}/>
                )
            }
        }

        return []
    }

    getTabItems = (tabs) => {
        const {view} = this.state

        return tabs.map(tab => {
            const disabledExcel = this.showExcelTooltip(tab)
            return {
                key: tab.key,
                children: this.tab(tab.key),
                label: <CustomTab
                    key={tab.key}
                    title={tab.title}
                    icon={
                        tab.key === 'rating' ? false :
                            disabledExcel ?
                                    <ReportExcelTooltip/>
                                :
                                    <ExcelFile
                                        filename={`${tab.title}_${moment().format('YYYY-MM-DDTHH:mm:ss')}`}
                                        element={
                                            <Button
                                                type='link'
                                                className='button-download'
                                                disabled={view !== tab.key}
                                            >
                                                <Icon component={Icons.Download.def}/>
                                            </Button>
                                        }
                                    >
                                        {this.prepareExcel(tab)}
                                    </ExcelFile>
                    }
                />
            }
        })
    }

    render () {
        const {view} = this.state,
              {request} = this.props

        return (
            <section className="kfc-report kfc-rating">
                <Layout>
                    <Header>
                        <AppHeader hideRestaurants/>
                    </Header>
                    <Content>
                        <InnerControls
                            hide={[ 'shift' ]}
                            actions={format.extract.actions(this.props)}
                            request={request}
                            update={this.load}
                            onChange={this.set}
                        />

                        <InnerContent fixed={view === 'categories'}>
                            <Card bordered={false}>
                                <Tabs
                                    className='extended-tabs'
                                    animated={false}
                                    onChange={this.setView}
                                    activeKey={view}
                                    items={this.getTabItems(tabs)}
                                >
                                </Tabs>
                            </Card>
                        </InnerContent>
                    </Content>
                </Layout>
            </section>
        )
    }
}

export default connect(mapStateToProps, allActions)(Rating)
