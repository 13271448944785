/* VENDOR */
import { update } from 'reduxigen'

/* APPLICATION */
import { format } from 'tools'

const set = ( path ) => {
    const key = path.split( '.' ).pop()

    return update( path, ( value, state ) => ({
        ...state,
        request: {
            ...state.request,
            [ key ]: value,
        },
    }))
}
/*
const setlog = path =>
    {
        const key = path.split( '.' ).pop()

        return update(
            path,
            ( value, state ) => {
                console.error( key, value)
                return {
                    ...state,
                    request: {
                        ...state.request,
                        [key]: value
                    }
                }
            }
        )
    }
*/

export const setGrowing = set( 'request.growing' )
export const setCurrency = set( 'request.unitOfMeasure' )
export const setGroup = set( 'request.dateGroup' )
export const setStartDate = set( 'request.dateStart' )
export const setMonth = set( 'request.month' )
export const setEndDate = set( 'request.dateEnd' )
export const setPredef = set( 'request.currentPredef' )
export const setServiceType = set( 'request.serviceType' )
export const setEmployee = set( 'request.employeeId' )
export const setRestaurant = set( 'request.restaurantId' )
export const setInactive = set( 'request.inactive' )

export const setRequest = update( 'request.set', ( data, state ) => ({
    ...state,
    request: {
        ...state.request,
        ...data,
    },
}))

export const applyError = update( 'request.error', ( event, state ) =>
    format.update.checkServerErrors( event, state )
)
