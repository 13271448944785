/* VENDOR */
import React from 'react'

/* APPLICATION */
import { Icon, IconActive, createIcon } from './Icon'

const svg = ( primary ) => (
    <svg width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.667 17.5h7a1.17 1.17 0 001.166-1.167 1.17 1.17 0 00-1.166-1.166h-7A1.17 1.17 0 003.5 16.333 1.17 1.17 0 004.667 17.5zm0 4.667h7A1.17 1.17 0 0012.833 21a1.17 1.17 0 00-1.166-1.167h-7A1.17 1.17 0 003.5 21a1.17 1.17 0 001.167 1.167zm0-9.334h7a1.17 1.17 0 001.166-1.166 1.17 1.17 0 00-1.166-1.167h-7A1.17 1.17 0 003.5 11.667a1.17 1.17 0 001.167 1.166zM3.5 7a1.17 1.17 0 001.167 1.167h7A1.17 1.17 0 0012.833 7a1.17 1.17 0 00-1.166-1.167h-7A1.17 1.17 0 003.5 7zm12.833-1.167h7A1.17 1.17 0 0124.5 7v14a1.17 1.17 0 01-1.167 1.167h-7A1.17 1.17 0 0115.167 21V7a1.17 1.17 0 011.166-1.167z"
            fill={primary}
        />
    </svg>
)

class Templates extends Icon {
    render () {
        return svg( this.primary, this.secondary )
    }
}

class TemplatesActive extends IconActive {
    render () {
        return svg( this.primary, this.secondary )
    }
}

export default createIcon( Templates, TemplatesActive )
