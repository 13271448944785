/* eslint-disable camelcase */
import tables from './tables'
import api    from './api'
import access from './access'

import * as defs   from './defs'
import * as lists  from './lists'
import * as format from './formats'
import * as left   from './pages'
import * as rules  from './rules'
import * as ui     from './ui'

export const storage_prefix = '_kfc_'

const config = {
    /* TEMPORARY */
    test: {
        creds: {
            login: 'surf',
            pass:  'sdr12345',
        },
        restaurantId:   process.env.REACT_APP_RID,
        userName:       'Александра Амелина',
        restaurantName: 'KFC Семеновская Москва',
    },

    title:     'Pro Business',
    copyright: '© 2018 KFC International Holdings Inc.',
    mcGuid:    '44081e01-c6d1-4e00-b51c-0a86f7ac69b6',

    calendar: { minimalDuration: 15, },

    storage_prefix,

    keys: {
        auth:             storage_prefix + 'auth_success',
        restaurant:       storage_prefix + 'selected_restaurant',
        splash:           storage_prefix + 'splash',
        countReload:      storage_prefix + 'reload_auth',
        countReloadError: storage_prefix + 'reload_auth_error'
    },

    refreshInterval: 60,

    ...lists,
    api,
    rules,
    format,
    ui,
    left,
    tables,
    defs,
    access
}

export default Object.freeze( Object.assign({}, config ))
