/* VENDOR */
import React from 'react'

/* APPLICATION */
import { Icon, IconActive, createIcon } from './Icon'

const svg = ( primary ) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="18" fill="none">
        <path
            fill={primary}
            d="M17.667 7.83301c.6922 0 1.3689-.20527 1.9445-.58986.5756-.38458 1.0242-.93121 1.2891-1.57075.2649-.63954.3342-1.34327.1991-2.02221-.135-.67893-.4684-1.30257-.9578-1.79205-.4895-.48949-1.1132-.82283-1.7921-.957879-.6789-.135048-1.3827-.065737-2.0222.199169-.6395.26491-1.1862.71351-1.5708 1.28908-.3845.57558-.5898 1.25226-.5898 1.9445-.002.46018.0872.9162.2624 1.34174.1752.42553.4329.81216.7583 1.13756.3254.3254.712.58313 1.1376.75832.4255.17519.8815.26436 1.3417.26238zm-9.33301 0c.69224 0 1.36893-.20527 1.94451-.58986.5756-.38458 1.0242-.93121 1.2891-1.57075.2649-.63954.3342-1.34327.1991-2.02221-.135-.67893-.4684-1.30257-.9578-1.79205-.4895-.48949-1.11316-.82283-1.79209-.957879C8.33788.765213 7.63414.834524 6.9946 1.09943c-.63954.26491-1.18617.71351-1.57075 1.28908-.38459.57558-.58986 1.25226-.58986 1.9445-.00198.46018.08719.9162.26238 1.34174.17519.42553.43292.81216.75832 1.13756.3254.3254.71203.58313 1.13757.75832.42553.17519.88155.26436 1.34173.26238zm0 2.33299c-2.719 0-8.166998 1.367-8.166998 4.084V16c.000792.3093.123998.6056.342681.8243.218684.2187.515057.3419.824317.3427H15.334c.3093-.0008.6056-.124.8243-.3427s.3419-.515.3427-.8243v-1.75c-.001-2.717-5.449-4.084-8.16701-4.084zm9.33301 0c-.338 0-.723.023-1.132.058.023.012.035.035.047.047.6732.4271 1.2309 1.0134 1.6237 1.7073.3928.6938.6086 1.4737.6283 2.2707v1.75c-.0032.3981-.0742.7928-.21 1.167h6.043c.3093-.0008.6056-.124.8243-.3427s.3419-.515.3427-.8243v-1.75c0-2.716-5.449-4.083-8.167-4.083z"
        />
    </svg>
)

class Employees extends Icon {
    render () {
        return svg( this.primary, this.secondary )
    }
}

class EmployeesActive extends IconActive {
    render () {
        return svg( this.primary, this.secondary )
    }
}

export default createIcon( Employees, EmployeesActive )
