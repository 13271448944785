import React             from 'react'
import classNames        from 'classnames'
import { Spin }          from 'antd'
import { PprTableError } from 'components'
import './ppr-table.scss'


const PprTable = ({ vertical, horizontal, small, className, error, loading, children, onRetry, customStyle, showScroll }) => {
    const clickRetry = () => onRetry && onRetry()

    return <Spin spinning={!!loading} className='spin-container-ppr-table'>
        <table className={classNames(
            !customStyle && 'ppr-table',
            vertical && 'table-vertical',
            horizontal && 'table-horizontal',
            small && 'small',
            showScroll && 'table-show-scroll',
            className
        )}>
            {children}
            {!loading && error && <PprTableError
                error={error}
                onRetry={clickRetry}
            />}
        </table>
    </Spin>

}

export default PprTable
