import admin       from './admin'
import chart       from './chart'
import employee    from './employee'
import origins     from './origins'
import performance from './performance'
import planning    from './planning'
import ratings     from './ratings'
import reports     from './reports'
import restaurants from './restaurants'
import schedule    from './schedule'
import settings    from './settings'
import tasks       from './tasks'
import timing      from './timing'
import turnover    from './turnover'
import guestVote   from './guestVote'

export default {
    ...admin,
    ...chart,
    ...employee,
    ...performance,
    ...planning,
    ...ratings,
    ...reports,
    ...restaurants,
    ...schedule,
    ...settings,
    ...tasks,
    ...timing,
    ...turnover,
    ...origins,
    ...guestVote
}
