import shared            from './shared'
import column            from './helpers'
import React             from 'react'
import { Tooltip } from 'antd'
import {InfoCircleOutlined} from '@ant-design/icons'

export default {
    performance: ( request ) => [
        shared.label( request ),
        column( 'SPMH План', 'planSpmh', {
            numeric: true,
            as:      'number',
            sorter:  'bynull',
        }),
        column( 'SPMH Факт', 'factSpmh', {
            numeric: true,
            as:      'number',
            sorter:  'bynull',
        }),
        column( 'TCPH План', 'planTcph', {
            numeric: true,
            as:      'number',
            sorter:  'bynull',
        }),
        column( 'TCPH Факт', 'factTcph', {
            numeric: true,
            as:      'number',
            sorter:  'bynull',
        }),
        column(
            <div className="delta-turnover">
                <span>Items Per Hour</span>
                <Tooltip title='Количество блюд в чеках, деленное на количество отработанных часов'>
                    <InfoCircleOutlined />
                </Tooltip>
            </div>
            , 'factItemsPerHour', {
                numeric: true,
                as:      'number',
                sorter:  'bynull',
            }),
        column(
            <div className="delta-turnover">
                <span>Количество блюд</span>
                <Tooltip title='Количество блюд (в том числе блюда из комбо)'>
                    <InfoCircleOutlined />
                </Tooltip>
            </div>
            , 'allProductsInChecks', {
                numeric: true,
                as:      'number',
                sorter:  'bynull',
            }),
        column( 'ТО факт', 'factTurnover', {
            numeric: true,
            as:      'currency',
            sorter:  'bynull',
        }),
        column( 'Транзакций факт', 'factTransactions', {
            numeric: true,
            sorter:  'bynull',
        }),
        column( 'Отработано часов', 'factProductiveUnproductiveHours', {
            numeric: true,
            as:      'float',
            sorter:  'bynull',
        }),
        column( 'Запланировано часов', 'planProductiveUnproductiveHours', {
            format:  'float',
            numeric: true,
            as:      'float',
            sorter:  'bynull',
        }),
    ],
}
