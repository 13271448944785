/* eslint-disable camelcase */
import moment from 'moment'

import { format } from 'tools'
import config     from 'config'

import * as calculate from './calculate'
import * as datetime  from './datetime'
import * as positions from './positions'
import * as twins     from './twins'

/* GLOBAL METHODS */

export const add = ( list, pos, shift ) => {
    const emp = list.find(( e ) => e.id === shift.name ),
          s = {
              guid:           format.generate.guid(),
              _temp_id:       shift._temp_id,
              employeeId:     emp.id,
              employeeName:   emp.name,
              shiftStart:     shift.shiftStart.format( config.format.dateFull ),
              shiftEnd:       shift.shiftEnd.format( config.format.dateFull ),
              isUnderage:     shift.isUnderage,
              rateDictionary: shift.rateDictionary,
              _double:        !!shift._double,
          }

    pos.shifts.push( s )
}

export const prepare = ( shifts, pos, dayDate ) =>
    shifts
        .filter(( s ) => s === equal( s, pos ))
        .filter(( s ) => !s._length )
        .map(( s, index ) => {
            s._was_supervisor = s.shiftSupervisor
            s.guid = format.generate.guid()
            s.positionGuid = pos.positionGuid
            s._double = !moment( s.shiftStart ).isSame( dayDate, 'day' )

            fixOverlaping( pos, s, index, dayDate )

            return s
        })

export const excludeUnassigned = ( data ) => {
    data.positions.map(( pos ) => {
        pos.shifts = pos.shifts.filter(( shift ) => !!shift.employeeId )
        return pos
    })

    return data
}

export const setTime = ( shift, key, time, date, res ) => {
    const tstart =
      key === 'shiftStart' ? time : format.extract.time( shift.shiftStart ),
          tend = key === 'shiftEnd' ? time : format.extract.time( shift.shiftEnd ),
          start = format.extract.intTime( tstart ),
          end = format.extract.intTime( tend ),
          sh = format.extract.hours( tstart ),
          sm = format.extract.minutes( tstart ),
          eh = format.extract.hours( tend ),
          original = format.copy.object( shift )

    let em = format.extract.minutes( tend )

    if ( start >= end && ( end > 0 || ( end === 0 && em === 0 ))) {
        if ( shift._double ) {
            shift.shiftStart = moment( date )
                .subtract( 1, 'day' )
                .set({ hour: sh, minutes: sm })
            shift.shiftEnd = moment( date ).set({ hour: eh, minutes: em })
            twins.changeOriginal( shift, date, res )
        } else {
            shift.shiftStart = moment( date ).set({ hour: sh, minutes: sm })
            shift.shiftEnd = moment( date )
                .add( 1, 'day' )
                .set({ hour: eh, minutes: em })
            twins.change( shift, date, res )
        }
    } else {
        shift.shiftStart = moment( date ).set({ hour: sh, minutes: sm })
        shift.shiftEnd = moment( date ).set({ hour: eh, minutes: em })

        if ( shift._double ) {
            const twin = format.copy.object( shift )

            twin.shiftStart = moment( date )
                .subtract( 1, 'day' )
                .set({ hour: sh, minutes: sm })
            twin.shiftEnd = moment( date )
                .subtract( 1, 'day' )
                .set({ hour: eh, minutes: em })

            twins.changeOriginal( twin, date, res )
        }

        twins.remove( original, date, res )
    }

    shift.shiftStart = shift.shiftStart.format( config.format.dateFull )
    shift.shiftEnd = shift.shiftEnd.format( config.format.dateFull )
}

/* SPECIAL METHODS */

export const addUnassigned = ( position, unassignedShift ) => {
    const shift = {
        _temp_id:     format.generate.guid(),
        _warning:     true,
        _length:      unassignedShift.duration,
        guid:         format.generate.guid(),
        employeeId:   null,
        employeeName: '',
        shiftStart:   unassignedShift.shiftStart,
        shiftEnd:     unassignedShift.shiftEnd,
        positionGuid: position.positionGuid,
    }

    position.shifts.push( shift )
}

export const fixOverlaping = ( pos, shift, index, dayDate ) => {
    const startTime = moment( shift.shiftStart ).format( 'HH:mm' ),
          endTime = moment( shift.shiftEnd ).format( 'HH:mm' )

    if ( format.extract.intTime( startTime ) < format.extract.intTime( endTime )) {
        if ( !moment( shift.shiftStart ).isSame( dayDate, 'day' )) {
            pos.shifts.splice( index, 1 )
        } else if (
            !moment( shift.shiftStart ).isSame( moment( shift.shiftEnd ), 'day' )
        ) {
            const start = moment( shift.shiftStart ),
                  end = moment( shift.shiftEnd )

            shift.shiftEnd = start
                .set({ hour: end.hours(), minutes: end.minutes() })
                .format( config.format.dateFull )
        }
    }
}

export const extract = ( orig, dow, position, employee, poss, guid ) => {
    const res = format.copy.object( orig ),
          day = format.copy.object( datetime.find( orig.days, dow )),
          pos = positions.get( position, day, poss ),
          shift = guid ? byGuid( guid, pos ) : find( employee, pos )

    return {
        res,
        day,
        pos,
        shift,
    }
}

/* TOOLS */

export const equal = ( shift, pos ) =>
    pos.shifts.find(
        ( s ) =>
            s.employeeId === shift.employeeId &&
      s.shiftStart === shift.shiftStart &&
      s.shiftEnd === shift.shiftEnd
    )

export const errors = ( cb ) => ( position, pi, positions ) =>
    position.shifts.every(( shift ) =>
        positions.every(( pos ) =>
            pos.shifts
                .filter(( row ) => !!row.employeeId )
                .every(( row ) => intersection( row, shift, cb ))
        )
    )

export const intersection = ( row, shift, cb ) => {
    if ( row === shift ) { return true }

    if (
        row.positionGuid === config.mcGuid &&
    shift.positionGuid === config.mcGuid
    ) {
        if ( !row.shiftSupervisor || !shift.shiftSupervisor ) { return true }
        if ( row.shiftStart !== shift.shiftStart ) { return true }
    } else {
        if ( row.employeeId !== shift.employeeId ) { return true }
    }

    if ( moment( row.shiftEnd ).isBefore( moment())) { return true }

    const start_a = moment( row.shiftStart ),
          end_a = moment( row.shiftEnd ),
          start_b = moment( shift.shiftStart ),
          end_b = moment( shift.shiftEnd ),
          a = moment.range( start_a, end_a ),
          b = moment.range( start_b, end_b )

    if ( a.overlaps( b )) {
        cb( row, shift )
        return false
    }

    return true
}

export const find = ( id, pos ) => pos.shifts.find(( s ) => s.employeeId === id )

export const byGuid = ( guid, pos ) => pos.shifts.find(( s ) => s.guid === guid )
