function checkRole ( key = 'ppr', field ) {
    return !!this[ key ].find( role => role.toLocaleLowerCase() === field.toLocaleLowerCase())
}

export default {
    ppr: [
        'RGM',
        'RGM trainee',
        'RGM Trainee',
        'Area Coach',
        'Area Coach Trainee',
        'Assistant Manager',
        'Assistant Manager Trainee',
        'Restaurant role 5'
    ],
    pprArea: [
        'Area Coach',
        'Area Coach Trainee',
        'Restaurant role 5'
    ],
    pprPartner: [
        'Restaurant role 5'
    ],
    showData: [
        'RGM',
        'RGM Trainee',
        'Assistant Manager',
        'Assistant Manager Trainee',
        'Area Coach',
        'Area Coach Trainee'
    ],
    checkRole
}
