/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import classNames           from 'classnames'

/* APPLICATION */
import './add-button.scss'

class AddButton extends Component {
    static propTypes = {
        action:   PropTypes.func,
        text:     PropTypes.string,
        icon:     PropTypes.any,
        disabled: PropTypes.bool
    }

    render () {
        const { icon, text, action, disabled } = this.props

        return (
            <span className={classNames( 'add-row-control', disabled && 'disabled' )} onClick={!disabled && action}>
                {icon ? (
                    <span className="add-custom-icon">{icon}</span>
                ) : (
                    <span className="add-icon">+</span>
                )}
                {text}
            </span>
        )
    }
}

export default AddButton
