/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'

/* APPLICATION */
import { CompareTable, Spinner } from 'components'
import { format }                from 'tools'

class ShiftSummary extends Component {
    static propTypes = {
        turnover:  PropTypes.object,
        dishes:    PropTypes.array,
        currency:  PropTypes.string,
        className: PropTypes.string,
    }

    getTurnover = () => {
        if ( !this.props.turnover ) { return }

        const { currency, turnover } = this.props,
              predef = [
                  {
                      title:    'Оборот',
                      currency: currency,
                      plan:     turnover.planTurnover,
                      fact:     turnover.factTurnover,
                  },
                  {
                      title:    'Разница',
                      currency: currency,
                      value:    turnover.deltaTurnover,
                  },
                  {
                      title:    'Транзакции',
                      currency: format.check.onlyIf( currency, '%' ),
                      plan:     turnover.planTransactions,
                      fact:     turnover.factTransactions,
                  },
                  {
                      title:    'Средний чек',
                      currency: currency,
                      plan:     turnover.planAverageCheck,
                      fact:     turnover.factAverageCheck,
                  },
              ]

        return predef.map( format.generate.summary )
    }

    getDishes = () => {
        const { dishes } = this.props

        if ( !dishes ) { return }

        return dishes.map(( dish ) => ({
            title:    dish.name,
            type:     'relation',
            currency: '%',
            plan:     dish.planValue,
            fact:     dish.factValue,
        }))
    }

    render () {
        const { turnover, dishes } = this.props

        if ( !turnover || !dishes ) { return <Spinner /> }

        return (
            <div className={'compare-container ' + this.props.className}>
                <CompareTable
                    key="top"
                    title="Товарооборот"
                    rows={this.getTurnover()}
                />
                <CompareTable
                    key="bottom"
                    title="Блюда / Категории"
                    rows={this.getDishes()}
                />
            </div>
        )
    }
}

export default ShiftSummary
