/* VENDOR */
import React, { Component } from 'react'
import { Layout }           from 'antd'

/* APPLICATION */
import { AppHeader, InnerContent } from 'components'

const { Content, Header } = Layout

class Stub extends Component {
    render () {
        return (
            <section className="kfc-stub">
                <Layout>
                    <Header>
                        <AppHeader />
                    </Header>
                    <Content>
                        <InnerContent>
                            <h2>Page is under construction</h2>
                        </InnerContent>
                    </Content>
                </Layout>
            </section>
        )
    }
}

export default Stub
