/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'

/* APPLICATION */
import { ViewTypeDrop } from 'components'

import './currency-switch.scss'

class CurrencySwitch extends Component {
    static propTypes = {
        request:      PropTypes.object,
        update:       PropTypes.func,
        showGrowing:  PropTypes.bool,
        hideCurrency: PropTypes.bool,

        after:  PropTypes.any,
        before: PropTypes.any,
    }

    static defaultProps = { showGrowing: false, }

    componentDidMount () {
        this.forceUpdate()
    }

    setGrowing = ( gr ) => this.props.update({ growing: gr === 'growing' })
    setUnit = ( unitOfMeasure ) => this.props.update({ unitOfMeasure })

    render () {
        const { showGrowing, hideCurrency, after, request, before } = this.props

        return (
            <div className="currency-switch">
                {before && <div className="cswitch-before">{before}</div>}

                {showGrowing && (
                    <ViewTypeDrop
                        current={request.growing ? 'growing' : 'split'}
                        placeholder={request.growing ? 'Суммарно' : 'По периодам'}
                        update={this.setGrowing}
                        items={{
                            growing: 'Суммарно',
                            split:   'По периодам',
                        }}
                    />
                )}

                {!hideCurrency && (
                    <ViewTypeDrop
                        current={request.unitOfMeasure}
                        placeholder={
                            request.unitOfMeasure === 'currency' ? 'В рублях' : 'В процентах'
                        }
                        update={this.setUnit}
                        items={{
                            currency: 'В рублях',
                            percent:  'В процентах',
                        }}
                    />
                )}

                {after && <div className="cswitch-after">{after}</div>}
            </div>
        )
    }
}

export default CurrencySwitch
