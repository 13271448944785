/* VENDOR */
import React from 'react'

/* APPLICATION */
import { Icon, IconActive, createIcon } from './Icon'

const svg = ( primary ) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" fill="none">
        <path
            fill={primary}
            d="M14.8334 4.66667H3.16675c-1.38334 0-2.500002 1.11666-2.500002 2.5v5.00003H4.00008V15.5H14.0001v-3.3333h3.3333V7.16667c0-1.38334-1.1167-2.5-2.5-2.5zm-2.5 9.16663H5.66675V9.66667h6.66665v4.16663zM14.8334 8c-.4583 0-.8333-.375-.8333-.83333 0-.45834.375-.83334.8333-.83334.4583 0 .8333.375.8333.83334 0 .45833-.375.83333-.8333.83333zM14.0001.5H4.00008v3.33333H14.0001V.5z"
        />
    </svg>
)

class Print extends Icon {
    render () {
        return svg( this.primary, this.secondary )
    }
}

class PrintActive extends IconActive {
    render () {
        return svg( this.primary, this.secondary )
    }
}

export default createIcon( Print, PrintActive )
