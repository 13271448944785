/* VENDOR */
import React from 'react'

/* APPLICATION */
import { Icon, IconActive, createIcon } from './Icon'

const svg = ( primary ) => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14.4333 5.10165L12.5733 3.24165C12.36 3.02831 12 3.17498 12 3.47498V4.66831H2.66667C2.3 4.66831 2 4.96831 2 5.33498C2 5.70165 2.3 6.00165 2.66667 6.00165H12V7.19498C12 7.49498 12.36 7.64165 12.5667 7.42832L14.4267 5.56831C14.56 5.44165 14.56 5.22831 14.4333 5.10165Z"
        />
        <path
            d="M1.56724 10.9003L3.42724 12.7603C3.64057 12.9737 4.00057 12.827 4.00057 12.527V11.3337H13.3339C13.7006 11.3337 14.0006 11.0337 14.0006 10.667C14.0006 10.3003 13.7006 10.0003 13.3339 10.0003H4.00057V8.80701C4.00057 8.50701 3.64057 8.36035 3.4339 8.57368L1.5739 10.4337C1.44057 10.5603 1.44057 10.7737 1.56724 10.9003Z"
        />
    </svg>
)

class Transfer extends Icon {
    render () {
        return svg( this.primary, this.secondary )
    }
}

class TransferActive extends IconActive {
    render () {
        return svg( this.primary, this.secondary )
    }
}

export default createIcon( Transfer, TransferActive )
