/* VENDOR */
import React from 'react'

/* APPLICATION */
import { Icon, IconActive, createIcon } from './Icon'

const svg = ( primary ) => (
    <svg width="18" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16 0H2a2 2 0 00-2 2v12c0 1.1.9 2 2 2h3c.55 0 1-.45 1-1s-.45-1-1-1H2V4h14v10h-3c-.55 0-1 .45-1 1s.45 1 1 1h3c1.1 0 2-.9 2-2V2a2 2 0 00-2-2zM8.65 6.35L5.86 9.14c-.32.32-.1.86.35.86H8v5c0 .55.45 1 1 1s1-.45 1-1v-5h1.79c.45 0 .67-.54.35-.85L9.35 6.36c-.19-.2-.51-.2-.7-.01z"
            fill={primary}
        />
    </svg>
)

class Import extends Icon {
    render () {
        return svg( this.primary, this.secondary )
    }
}

class ImportActive extends IconActive {
    render () {
        return svg( this.primary, this.secondary )
    }
}

export default createIcon( Import, ImportActive )
