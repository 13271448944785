import React  from 'react'
import moment from 'moment'

import column      from './helpers'
import * as format from '../formats'

export default {
    tasks: {
        group: [ column( '', 'time' ), column( '', 'content' ) ],

        period: [
            column( 'Сотрудник', 'name', {
                render: ( text, r ) =>
                    r.isRGM ? <span className="rgm">{text}</span> : text,
            }),
            column( 'Обходы', 'total', {
                as:    'currency',
                width: 65,
            }),
            column( 'Задачи', 'tasksTotal', {
                as:           'currency',
                width:        65,
                hideCurrency: true,
                rawFormat:    'intPercentage',
            }),
            column( 'Вовремя', 'tasksInTime', {
                as:           'currency',
                width:        65,
                hideCurrency: true,
                rawFormat:    'intPercentage',
            }),
            column( 'Отменено', 'tasksCanceled', {
                as:           'currency',
                width:        65,
                hideCurrency: true,
                rawFormat:    'intPercentage',
            }),
            column( 'ТО', 'turnover', {
                as:           'currency',
                width:        65,
                hideCurrency: true,
                rawFormat:    'intPercentage',
            }),
            column( 'SPMH', 'spmh', {
                as:           'currency',
                width:        65,
                hideCurrency: true,
                rawFormat:    'intPercentage',
            }),
        ],

        date: [
            column( 'Сотрудник', 'userImage', {
                as:      'avatar',
                colSpan: 2,
                width:   80,
            }),
            column( '', 'name', {
                width:   1000,
                colSpan: 0,
                render:  ( text, r ) => (
                    <span>
                        {r.isRGM ? <span className="rgm">{text}</span> : text}
                        <small>
                            {moment( r.timeStart, format.date ).format( format.time ) +
                ' – ' +
                moment( r.timeEnd, format.date ).format( format.time )}
                        </small>
                    </span>
                ),
            }),
            column( 'Обходы', 'total', {
                as:    'currency',
                width: 120,
            }),
            column( 'Задачи', 'tasksTotal', {
                as:    'currency',
                width: 120,
            }),
            column( 'Цель по ТО', 'turnover', {
                as:    'currency',
                width: 120,
            }),
            column( 'Цель по произв.', 'spmh', {
                as:    'currency',
                width: 120,
            }),
        ],
    },
}
