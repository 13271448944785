/* VENDOR */
import React from 'react'

/* APPLICATION */
import { Icon, IconActive, createIcon } from './Icon'

const svg = () => (
    <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.00001 0.332031C3.32001 0.332031 0.333344 3.3187 0.333344 6.9987C0.333344 10.6787 3.32001 13.6654 7.00001 13.6654C10.68 13.6654 13.6667 10.6787 13.6667 6.9987C13.6667 3.3187 10.68 0.332031 7.00001 0.332031ZM7.00001 12.332C4.06001 12.332 1.66668 9.9387 1.66668 6.9987C1.66668 4.0587 4.06001 1.66536 7.00001 1.66536C9.94001 1.66536 12.3333 4.0587 12.3333 6.9987C12.3333 9.9387 9.94001 12.332 7.00001 12.332ZM6.33334 9.66537H7.66668V10.9987H6.33334V9.66537ZM7.40668 3.02536C6.03334 2.82536 4.82001 3.67203 4.45334 4.88536C4.33334 5.27203 4.62668 5.66536 5.03334 5.66536H5.16668C5.44001 5.66536 5.66001 5.47203 5.75334 5.2187C5.96668 4.62536 6.60001 4.2187 7.28668 4.36537C7.92001 4.4987 8.38668 5.1187 8.33334 5.76536C8.26668 6.6587 7.25334 6.85203 6.70001 7.68536C6.70001 7.69203 6.69334 7.69203 6.69334 7.6987C6.68668 7.71203 6.68001 7.7187 6.67334 7.73203C6.61334 7.83203 6.55334 7.94536 6.50668 8.06536C6.50001 8.08537 6.48668 8.0987 6.48001 8.1187C6.47334 8.13203 6.47334 8.14536 6.46668 8.16536C6.38668 8.39203 6.33334 8.66537 6.33334 8.9987H7.66668C7.66668 8.7187 7.74001 8.48536 7.85334 8.28537C7.86668 8.26537 7.87334 8.24537 7.88668 8.22536C7.94001 8.13203 8.00668 8.04536 8.07334 7.96536C8.08001 7.9587 8.08668 7.94536 8.09334 7.9387C8.16001 7.8587 8.23334 7.78536 8.31334 7.71203C8.95334 7.10536 9.82001 6.61203 9.64001 5.3387C9.48001 4.1787 8.56668 3.1987 7.40668 3.02536Z"
            fill="black"
            fillOpacity=".6"
        />
    </svg>
)

class Info extends Icon {
    render () {
        return svg( this.primary, this.secondary )
    }
}

class InfoActive extends IconActive {
    render () {
        return svg( this.primary, this.secondary )
    }
}

export default createIcon( Info, InfoActive )
