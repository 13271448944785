/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Modal }            from 'antd'

/* APPLICATION */
import { ReportTable } from 'components'
import { format }      from 'tools'
import config          from 'config'

import './overall-modal.scss'

class OverallModal extends Component {
    static propTypes = {
        data:  PropTypes.object,
        date:  PropTypes.object,
        month: PropTypes.number,

        visible: PropTypes.bool,

        onClose: PropTypes.func,
    }

    prepare = ( data ) => {
        const { month } = this.props

        if ( !data ) { return null }
        if ( !data.months[ month ]) { return [] }

        return data.months[ month ].restaurant.visits.filter(( v ) => !!v )
    }

    render () {
        const { visible, data, date, onClose } = this.props,
              restaurant = data ? data.restaurantName : '...',
              table = this.prepare( data )

        return (
            <Modal
                className="kfc-popup kfc-overall-modal"
                centered={true}
                open={visible}
                title={`${restaurant} • ${format.strings.capitalize(
                    date.format( 'MMMM YYYY' )
                )}`}
                okText="Закрыть"
                onOk={onClose}
                onCancel={onClose}
                cancelText="Отмена"
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <div className="report-table">
                    <ReportTable
                        rowKey={( r ) => r.date + r.result}
                        unit="percent"
                        data={format.generate.noPager( table )}
                        columns={config.tables.restaurants.visits}
                    />
                </div>
            </Modal>
        )
    }
}

export default OverallModal
