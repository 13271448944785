/* eslint-disable camelcase */
/* VENDOR */
import { update }       from 'reduxigen'
import Moment           from 'moment'
import { extendMoment } from 'moment-range'

/* APPLICATION */
import * as api         from './api'
import { format }       from 'tools'
import config           from 'config'

const moment = extendMoment( Moment ),
      asyncUpdate = format.update.asyncu,
      helper = format.helper.basic( 'schedules' ),
      prepare = ( event, state ) => {
          const res = format.copy.object( event.data ),
                d = event.data.dateStart + 'T00:00:00',
                list = format.copy.array( state.schedules.list || [])

          list.indexOf( d ) < 0 && list.push( d )

          res.days = res.days.sort(( a, b ) => {
              const astart = moment( a.workStart ),
                    bstart = moment( b.workStart )

              if ( astart.isBefore( bstart )) {
                  return -1
              }
              if ( astart.isAfter( bstart )) {
                  return 1
              }
              return 0
          })

          res.days.forEach( setDow )

          return {
              ...state,
              schedules: {
                  ...state.schedules,
                  list:    list.sort(( a, b ) => a.localeCompare( b )),
                  current: res,
              },
          }
      },
      setDow = ( day ) => {
          const start = moment( day.workStart ),
                end = moment( day.workEnd ),
                range = moment.range( start, end ),
                center = moment( range.center())

          day.dayOfWeek = parseInt( center.format( 'd' ))
          day.dayOfWeek === 0 && ( day.dayOfWeek = 7 )
      }

/* LIST */

export const flushSchedules = update( 'schedules.flush', ( data, state ) => ({
    ...state,
    schedules: {},
}))

export const fetchSchedules = asyncUpdate(
    'schedules.list',
    ( params ) => api.getSchedules( params ),
    ( event, state ) => helper( 'list', event.data, state )
)

/* SCHEDULES */

export const getSchedule = asyncUpdate(
    'schedules.current',
    ( params ) => api.getSchedule( params ),
    ( event, state ) => {
    /* TMP MOCKS! */
        const fake = { data: format.copy.object( event.data ) }
        fake.data.days.forEach(( d ) => {
            d.originalTurnover = d.turnover
        })

        //;( day ) && ( day.updatedTurnover = Math.round( Math.random() * 10000 ) )
        /* /TMP */
        return prepare( fake, state )
    }
)

export const fetchReportsSchedulesSummary = asyncUpdate(
    'schedules.dataSummaryReport',
    ( params ) =>
        api.getReportsSchedulesSummary({
            dayTwo: {
                dateStart:   params.dateStart,
                dateEnd:     params.dateEnd,
                factsNumber: params.factsNumber,
            },
        }),
    ( event, state ) => {
        const data =
            event.data[ 0 ]
                ? {
                        currentWeek:  event.data[ 0 ],
                    }
                : null

        return helper( 'dataSummaryReport', data, state )
    }
)

export const fetchReportsSchedulesSummaryFromSunToSun = asyncUpdate(
    'schedules.dataSummaryReportSunToSun',
    ( params ) => api.getReportsSchedulesSummary( params ),
    ( event, state ) => helper( 'dataSummaryReportSunToSun', event.data, state )
)

export const saveScheduleDay = asyncUpdate(
    'schedules.saveDay',
    ( data ) => api.setScheduleDay( data ),
    ( event, state ) => ({
        ...state,
        schedules: {
            ...state.schedules,
            saved:      true,
            daysToSave: Math.max( state.schedules.daysToSave - 1, 0 ),
        },
    })
)

export const setSaved = update( 'schedules.saved', ( saved, state ) =>
    helper( 'saved', saved, state )
)

export const setDaysToSave = update(
    'schedules.daysToSave',
    ( daysToSave, state ) => helper( 'daysToSave', daysToSave, state )
)

export const generateSchedule = asyncUpdate(
    'schedules.generated',
    ( params ) => api.newSchedule( params ),
    ( event, state ) => {
        if ( event.status === 200 ) {
            return prepare( event, state )
        }

        return {
            ...state,
            schedules: {
                ...state.schedules,
                generateError: event,
            },
        }
    }
)

export const flushGenerateError = update(
    'schedules.generated.error.flush',
    ( n, state ) => ({
        ...state,
        schedules: {
            ...state.schedules,
            generateError: null,
        },
    })
)

export const getSideDays = asyncUpdate(
    'schedules.sidedays.get',
    ( params ) =>
        api.getSideDays({
            dayOne: {
                dateStart: params.dateStart.startOf( 'day' ).format( config.format.date ),
                dateEnd:   moment( params.dateStart )
                    .endOf( 'day' )
                    .format( config.format.date ),
                dateGroup:     'hour',
                unitOfMeasure: 'currency',
                factsNumber:   params.factsNumber,
            },
            dayTwo: {
                dateStart:     params.dateEnd.startOf( 'day' ).format( config.format.date ),
                dateEnd:       moment( params.dateEnd ).endOf( 'day' ).format( config.format.date ),
                dateGroup:     'hour',
                unitOfMeasure: 'currency',
                factsNumber:   params.factsNumber,
            },
        }),
    ( event, state ) => {
        const data =
      event.data[ 0 ]?.data && event.data[ 1 ]?.data
          ? {
                  prev: event.data[ 0 ],
                  next: event.data[ 1 ],
              }
          : null

        return helper( 'sideDays', data, state )
    }
)

/* UNASSIGNED */

export const flushAssignment = update( 'assignshift.flush', ( data, state ) => ({
    ...state,
    assignment: null,
}))

export const fetchAssignment = asyncUpdate(
    'assignshift.get',
    ( params ) => api.getAssignment( params ),
    ( event, state ) => {
        const status = event.status,
              assignment = event.data

        if ( status !== 200 ) {
            return state
        }

        return {
            ...state,
            assignment,
        }
    }
)

export const fetchWeekTurnover = asyncUpdate(
    'schedules.weekTurnover',
    ( params ) => api.getSchedule( params ),
    ( event, state ) => {
        if ( event.status === 408 ) {
            return {
                ...state,
                schedules: {
                    ...state.schedules,
                    weekTurnover_error: true,
                },
            }
        }
        return helper( 'weekTurnover', event.data.days, state )
    }
)

export const flushTurnover = update(
    'schedules.weekTurnover.flush',
    ( _, state ) => ({
        ...state,
        schedules: {
            ...state.schedules,
            weekTurnover:       undefined,
            saveTurnover:       false,
            weekTurnover_error: false,
            errorSetTurnover:   false,
        },
    })
)

export const flushTurnoverError = update(
    'schedules.weekTurnoverError.flush',
    ( _, state ) => ({
        ...state,
        schedules: {
            ...state.schedules,
            weekTurnover_error: false,
            errorSetTurnover:   false,
        },
    })
)

export const setTurnover = asyncUpdate(
    'schedules.set.turnover',
    ( params ) => api.setTurnover( params ),
    ( event, state ) => {
        if ( event.status === 200 ) {
            return {
                ...state,
                schedules: {
                    ...state.schedules,
                    saveTurnover:       true,
                    weekTurnover_error: false,
                    errorSetTurnover:   false,
                },
            }
        }

        if ( event.status === 408 ) {
            return {
                ...state,
                schedules: {
                    ...state.schedules,
                    weekTurnover_error: true,
                },
            }
        }

        return {
            ...state,
            schedules: {
                ...state.schedules,
                errorSetTurnover: true,
                generateError:    event,
            },
        }
    }
)

export const postSchedulesEmployeesHided = asyncUpdate(
    'hidded',
    ( body ) => {
        return api.setHiddenEmployees( body )
    },
    ( event, state ) => {
        if (event.status === 200) {
            return {
                ...state,
                schedules: {
                    ...state.schedules,
                    dataSummaryReport: {
                        ...state.schedules.dataSummaryReport,
                        fromSunToSun: {
                            ...state.schedules.dataSummaryReport.fromSunToSun,
                            currentWeek: [ ...state.schedules.dataSummaryReport.fromSunToSun.hidedEmployees, event.data ],
                        },
                    },

                }
            }
        } else {
            return state
        }
    }
)

let deletingUuid
export const deleteSchedulesEmployeesHided = asyncUpdate(
    'schedules.delete',
    ( uuid ) => {
        deletingUuid = uuid
        return api.deleteHiddenEmployees( uuid )
    },
    ( event, state ) => {
        if (event.status === 200) {
            console.log('uuid', deletingUuid)
            return {
                ...state,
                schedules: {
                    ...state.schedules,
                    dataSummaryReport: {
                        ...state.schedules.dataSummaryReport,
                        fromSunToSun: {
                            ...state.schedules.dataSummaryReport.fromSunToSun,
                            hidedEmployees: state.schedules.dataSummaryReport.fromSunToSun.hidedEmployees.filter(( emp ) => emp.hidedEmployeeUuid !== deletingUuid ),
                        },
                    },
                },
            }
        } else {
            return state
        }
    }
)
