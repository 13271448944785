import Moment           from 'moment'
import { extendMoment } from 'moment-range'

import config from 'config'

import * as datetime from './datetime'

const moment = extendMoment( Moment )

export const schedule = ( res, real, dow, workHours, positions, onDone ) => {
    const day = datetime.find( res.days, dow )

    day.hours.forEach(( val ) => {
        val.planUnproductiveHours = 0
        val.planShiftSupervisorHours = 0
        val.productiveHours.planHours = 0
        val.productiveHours.deltaHours = 0 - val.productiveHours.requiredHours

    })

    const productivePositions = positions.reduce(( acc, position ) => {
        if ( position.isProductivePosition && position.positionGuid !== config.mcGuid ) {
            acc.push( position.positionGuid )
        }
        return acc
    }, [])


    const unproductivePositions = positions.reduce(( acc, position ) => {
        if ( !position.isProductivePosition && position.positionGuid !== config.mcGuid ) {
            acc.push( position.positionGuid )
        }
        return acc
    }, [])

    if ( !day ) {
        return
    }


    day.planUnproductiveHours = day.positions.reduce(( phd, pos ) => {
        const positionString = unproductivePositions.includes( pos.positionGuid )
            ? 'planUnproductiveHours'
            : undefined
        const totalHours = position( pos, real, workHours, day, positionString )

        return unproductivePositions.includes( pos.positionGuid ) ? phd + totalHours : phd
    }, 0 )

    day.productiveHours.planHours = day.positions.reduce(( phd, pos ) => {
        const positionString = productivePositions.includes( pos.positionGuid ) ? 'productiveHours' : undefined
        const totalHours = position( pos, real, workHours, day, positionString )
        return productivePositions.includes( pos.positionGuid ) ? phd + totalHours : phd
    }, 0 )

    day.planShiftSupervisorHours = day.positions.reduce(( phd, pos ) => {
        const positionString = pos.positionGuid === config.mcGuid ? 'planShiftSupervisorHours' : undefined
        const totalHours = position( pos, real, workHours, day, positionString )
        return pos.positionGuid === config.mcGuid ? phd + totalHours : phd
    }, 0 )

    day.productiveHours.deltaHours = day.productiveHours.planHours - day.productiveHours.requiredHours
    onDone && onDone()

}

export const position = ( pos, real, workHours, day, position ) => {
    if ( real ) {
        return delta(
            pos.summaryHours,
            pos.hours,
            pos.shifts.filter(( s ) => !s._temp_id || s.employeeId ),
            workHours,
            day,
            position
        )
    }
    return delta( pos.summaryHours, pos.hours, pos.shifts, workHours )
}

export const delta = ( paid, hours, shifts, workHours, day, position ) => {
    return shifts.reduce(( hours, shift ) => {

        const shiftStart = moment( shift.shiftStart )
        const shiftEnd = moment( shift.shiftEnd )

        const array = Array.from({ length: 24 }, () => -1 )

        const workHoursStart = moment().set({
            year:    shiftStart.year(),
            month:   shiftStart.month(),
            date:    shiftStart.date(),
            hours:   workHours.timeStart.split( ':' )[ 0 ],
            minutes: workHours.timeStart.split( ':' )[ 1 ],
            seconds: 0
        })
        const workHoursEnd = moment().set({
            year:    shiftStart.year(),
            month:   shiftStart.month(),
            date:    shiftStart.date(),
            hours:   workHours.timeEnd.split( ':' )[ 0 ],
            minutes: workHours.timeEnd.split( ':' )[ 1 ],
            seconds: 0
        })

        let startHours = shiftStart.hours()
        let endHours = shiftEnd.hours()

        if ( startHours >= endHours ) {
            endHours = 23
        } else {
            endHours -= 1
        }

        if ( moment( day?.workStart ).isAfter( shiftStart )) {
            startHours = 0
            endHours = shiftEnd.hours() - 1
        }

        for ( let i = startHours; i <= endHours + 1; i++ ) {
          if (i === startHours) {
              array[ i ] += (60 - shiftStart.minutes()) / 60
              continue
          }
          if (i === endHours + 1) {
              array[ i ] += shiftEnd.minutes() / 60
              continue
          }
              array[ i ] += 1
        }

        if ( workHoursEnd.minutes() > 0 ) {
            workHoursEnd.add( 1, 'hour' )
        }

        let workHoursStartHours = workHoursStart.hours()
        let workHoursEndHours = workHoursEnd.hours()
        workHoursEndHours -= 1

        if ( workHoursStartHours > workHoursEndHours ) {
            for ( let i = 0; i <= workHoursEndHours; i++ ) {
                array[ i ] += 1
            }
            for ( let i = workHoursStartHours; i <= 23; i++ ) {
                array[ i ] += 1
            }
        } else if ( workHoursStartHours === workHoursEndHours ) {
            for ( let i = 0; i <= 23; i++ ) {
                array[ i ] += 1
            }
        } else {
            for ( let i = workHoursStartHours; i <= workHoursEndHours; i++ ) {
                array[ i ] += 1
            }
        }

        for ( let i = workHoursStartHours; i <= workHoursEndHours; i++ ) {
            array[ i ] += 1
        }

        let total = 0

        array.forEach(( val ) => {
            if ( val > 0 ) {
                total += val
            }
        })

        if ( position ) {
            if ( position === 'productiveHours' ) {
                day.hours.forEach(( val, index ) => {
                    if ( array[ index ] >= 0.5 ) {
                    val[ position ].planHours += 1
                        val[ position ].deltaHours = val[ position ].planHours - val[ position ].requiredHours
                    }
                })
            } else {
                day.hours.forEach(( val, index ) => {
                    if ( array[ index ] >= 0.5 ) {
                        val[ position ] += 1
                    }
                })
            }
        }

        return hours + total
    }, 0 )
}
