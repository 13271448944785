/* VENDOR */
import React, { Component }                      from 'react'
import PropTypes                                 from 'prop-types'
import { Modal, Checkbox, Radio, Divider, List } from 'antd'
import { Scrollbars }                            from 'react-custom-scrollbars'

/* APPLICATION */
import { format } from 'tools'
import config     from 'config'

import './admin-permissions.scss'

const itemCls = 'restaurant-item'

class AdminPermissions extends Component {
    static propTypes = {
        top:  PropTypes.array,
        sub:  PropTypes.array,
        data: PropTypes.object,

        onSubmit: PropTypes.func,
        onCancel: PropTypes.func,

        visible: PropTypes.bool,
    }

    constructor ( props ) {
        super( props )

        this.state = {
            selected:    this.getSelected( props.data ),
            restaurants: [],
            data:        this.copyData( props.data ),
        }
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { data } = this.props

        data !== nextProps.data && this.setData( nextProps.data )
    }

    copyData = ( data ) => {
        const res = format.copy.object( data )

        res.admin = format.copy.object( data.admin )

        return res
    }

    getSelected = ( data ) =>
        data && Object.keys( data ).length > 0
            ? data.marketManager
                ? 'marketManager'
                : data.supervisor
                    ? 'supervisor'
                    : data._admin || Object.values( data.admin ).includes( true )
                        ? 'admin'
                        : 'standard'
            : null

    setData = ( list ) =>
        this.setState({
            data:     this.copyData( list ),
            selected: this.getSelected( list ),
        })

    submit = () => this.props.onSubmit( this.state.data )

    toggle = ( item ) => {
        return () => {
            const { data } = this.state

            data.admin[ item.key ] = !data.admin[ item.key ]

            this.setState({ data })
        }
    }

    select = ( item ) => {
        return () => {
            const { data } = this.state

            switch ( item.key ) {
                case 'marketManager':
                    data.marketManager = true
                    data.supervisor = false
                    data._admin = false
                    break
                case 'supervisor':
                    data.marketManager = false
                    data.supervisor = true
                    data._admin = false
                    break
                case 'admin':
                    data.marketManager = false
                    data.supervisor = false
                    data._admin = true
                    break
                default:
                    data.marketManager = false
                    data.supervisor = false
                    data._admin = false
                    Object.keys( data.admin ).forEach(( key ) => ( data.admin[ key ] = false ))
            }

            this.setState({
                data,
                selected: item.key,
            })
        }
    }

    getAdded = ( item ) =>
        this.state.data && this.state.data.admin && this.state.data.admin[ item.key ]

    item = ( item ) => {
        const cls = [ itemCls ]

        return (
            <List.Item className={cls.join( ' ' ).trim()}>
                <Checkbox checked={!!this.getAdded( item )} onChange={this.toggle( item )}>
                    <span className="item-title">{item.title}</span>
                </Checkbox>
            </List.Item>
        )
    }

    radio = ( item ) => {
        const { selected } = this.state,
              cls = [ itemCls, 'item-radio' ]

        return (
            <List.Item className={cls.join( ' ' ).trim()}>
                <Radio checked={selected === item.key} onChange={this.select( item )}>
                    <span className="item-title">{item.title}</span>
                </Radio>
            </List.Item>
        )
    }

    render () {
        const { visible, top, sub } = this.props,
              { selected } = this.state

        return (
            <Modal
                className="kfc-popup kfc-admin-permissions"
                centered={true}
                open={visible}
                title="Права администратора"
                okText="Сохранить"
                onOk={this.submit}
                onCancel={this.props.onCancel}
                cancelText="Отмена"
            >
                <div className="filter-container">
                    <p>
            Пользователи с правами администратора могут вносить необратимые
            изменения.
                    </p>
                </div>

                <Divider />

                <div className="popup-columns">
                    <div className="popup-column permissions-list">
                        <List
                            size="small"
                            dataSource={top}
                            renderItem={this.radio}
                            locale={{ emptyText: 'Ничего не найдено' }}
                        />
                    </div>
                    <div className="popup-column">
                        {selected === 'admin' && (
                            <div className="permissions-list">
                                <Scrollbars
                                    {...config.ui.scrolls}
                                    ref={( node ) => ( this.scrolls = node )}
                                >
                                    <List
                                        size="small"
                                        dataSource={sub}
                                        renderItem={this.item}
                                        locale={{ emptyText: 'Ничего не найдено' }}
                                    />
                                </Scrollbars>
                            </div>
                        )}
                    </div>
                </div>

                <Divider />
            </Modal>
        )
    }
}

export default AdminPermissions
