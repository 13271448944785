import { update } from 'reduxigen'

import * as api   from './api'
import { format } from 'tools'

const asyncUpdate = format.update.asyncu,
      helper = format.helper.basic( 'chart' ),
      manHelper = format.helper.dupdate( 'chart.manning.data', 'turnoverChartId' ),
      durHelper = format.helper.dupdate( 'chart.duration.data', 'durationId' ),
      manningHelper = format.helper.update( 'chart.manning.data', 'turnoverChartId' ),
      durationHelper = format.helper.update( 'chart.duration.data', 'durationId' )

/* MANNING */

export const flushManning = update( 'chart.flushManning', ( data, state ) => ({
    ...state,
    chart: {
        ...state.chart,
        manning: null,
    },
}))

export const fetchManning = asyncUpdate(
    'chart.manning',
    ( params ) => api.getManning( params ),
    ( event, state ) => helper( 'manning', event.data, state )
)

export const updateManning = asyncUpdate(
    'chart.updateManning',
    ( data ) => api.setManning( data ),
    ( event, state ) => {
        const data = JSON.parse( event.config.data )

        return event.status === 200
            ? manHelper( event.data, state, data.turnoverChartId )
            : manHelper( data, state, data.turnoverChartId )
    }
)

export const removeManning = asyncUpdate(
    'chart.removeManning',
    ( id ) => api.removeManning( id ),
    ( event, state ) =>
        manningHelper( event, state, ( cfg ) => cfg.arr.splice( cfg.index, 1 ))
)

/* DURATION */

export const flushDuration = update( 'chart.flushDuration', ( data, state ) => ({
    ...state,
    chart: {
        ...state.chart,
        duration: null,
    },
}))

export const fetchDuration = asyncUpdate(
    'chart.duration',
    ( params ) => api.getDuration( params ),
    ( event, state ) => {
        let data = format.copy.array( event.data.data )

        data = data.filter(( dur, index ) => {
            const found = data.find(
                ( d ) => d.duration === dur.duration && d.priority === dur.priority
            )
            return index === data.indexOf( found )
        })

        return helper( 'duration', { data }, state )
    }
)

export const updateDuration = asyncUpdate(
    'chart.updateDuration',
    ( data ) => api.setDuration( data ),
    ( event, state ) => {
        const data = JSON.parse( event.config.data )

        return event.status === 200
            ? durHelper( event.data, state, data.durationId )
            : durHelper( data, state, data.durationId )
    }
)

export const removeDuration = asyncUpdate(
    'chart.removeDuration',
    ( data ) => api.removeDuration( data ),
    ( event, state ) =>
        durationHelper( event, state, ( cfg ) => cfg.arr.splice( cfg.index, 1 ))
)

/* BREAKS */

export const flushBreaks = update( 'chart.flushBreaks', ( data, state ) => ({
    ...state,
    chart: {
        ...state.chart,
        breaks: null,
    },
}))

export const fetchBreaks = asyncUpdate(
    'chart.breaks',
    ( params ) => api.getBreaks( params ),
    ( event, state ) => {
        const rates = format.copy.array( event.data.rates )

        return helper( 'breaks', rates, state )
    }
)

export const updateBreaks = asyncUpdate(
    'chart.updateBreaks',
    ( data ) => api.setBreaks( data ),
    ( event, state ) => {
        const original = JSON.parse( event.config.data ),
              updated = event.data,
              data = event.status === 200 ? updated : original,
              rates = format.copy.array( state.chart.breaks )

        let rindex = format.find.index( rates, 'rate', data.rate )

        if ( rindex < 0 ) {
            rindex = rates.length
            rates.push({
                rate:   original.rate,
                breaks: [],
            })
        }

        const rate = format.copy.object( rates[ rindex ]),
              breaks = format.copy.array( rate.breaks )

        let index = format.find.index( breaks, 'breakId', original.breakId )

        index < 0 && ( index = breaks.length )

        breaks[ index ] = data
        rate.breaks = breaks
        rates[ rindex ] = rate

        return {
            ...state,
            chart: {
                ...state.chart,
                breaks: rates,
            },
        }
    }
)

export const removeBreaks = asyncUpdate(
    'chart.removeBreaks',
    ( data ) => api.removeBreaks( data ),
    ( event, state ) => {
        if ( event.status !== 200 ) {
            return state
        }

        const req = JSON.parse( event.config.data ),
              rates = format.copy.array( state.chart.breaks ),
              rindex = format.find.index( rates, 'rate', req.rate ),
              rate = format.copy.object( rates[ rindex ]),
              breaks = format.copy.array( rate.breaks ),
              index = format.find.index( breaks, 'breakId', req.breakId )

        breaks.splice( index, 1 )
        rate.breaks = breaks
        rates[ rindex ] = rate

        return {
            ...state,
            chart: {
                ...state.chart,
                breaks: rates,
            },
        }
    }
)

export const fetchPositionsChartedByType = asyncUpdate(
    'planning.positionsChartedByType',
    ( params ) => api.getPositionsChartedByType( params ),
    ( event, state ) => ({
        ...state,
        planning: {
            ...state.planning,
            positionsChartedByType:      event.data?.positions ?? [],
            positionsChartedByTypeError: event.status !== 200
        }
    })
)

export const fetchPositionsChartedByTypeisSelectedFalse = asyncUpdate(
    'planning.positionsChartedByTypeIsSelectedFalse',
    ( params ) => api.getPositionsChartedByTypeIsSelectedFalse( params ),
    ( event, state ) => ({
        ...state,
        planning: {
            ...state.planning,
            positionsChartedByTypeIsSelectedFalse:      event.data?.positions ?? [],
            positionsChartedByTypeErrorIsSelectedFalse: event.status !== 200
        }
    })
)

export const flushPositionsChartedByType = update(
    'planning.positionsChartedByType.flush',
    ( _, state ) => ({
        ...state,
        planning: {
            ...state.planning,
            positionsChartedByType: null
        }
    })
)

export const flushChartByType = update(
    'planning.chartByType.flush',
    ( _, state ) => ({
        ...state,
        planning: {
            ...state.planning,
            chartByType: undefined
        }
    })
)

export const addNewChartByType = update(
    'planning.positionsChartedByType.addNew',
    ( value, state ) => ({
        ...state,
        planning: {
            ...state.planning,
            chartByType: [ ...state.planning.chartByType, value ]
        }
    })
)

export const removeNewChartByType = update(
    'planning.positionsChartedByType.removeNew',
    ( i, state ) => ({
        ...state,
        planning: {
            ...state.planning,
            chartByType: state.planning.chartByType.filter(( _, j ) => i !== j )
        }
    })
)

export const fetchChartByType = asyncUpdate(
    'planning.chartByType',
    ( params ) => api.getChartByType( params ),
    ( event, state ) => ({
        ...state,
        planning: {
            ...state.planning,
            chartByType:      event.data,
            chartByTypeError: event.status !== 200
        }
    })
)

export const createPositionsChartedByType = asyncUpdate(
    'planning.createPositionsChartedByType',
    ( params ) => api.createPositionsChartedByType( params ),
    ( event, state ) => {
        const positionUuid = JSON.parse( event.config.data )?.positionUuid
        return ({
            ...state,
            planning: {
                ...state.planning,
                positionsChartedByType: state.planning.positionsChartedByType.map( item =>
                    item.positionUuid === positionUuid ? ({
                        ...item,
                        uuidChartPosition: event.status === 200 ? event.data : null,
                        error:             event.status !== 200,
                    }) : item
                )
            }
        })
    }
)

export const deletePositionChartedByType = asyncUpdate(
    'planning.deletePositionChartedByType',
    ( params ) => api.deletePositionChartedByType( params ),
    ( event, state ) => {
        if ( event.status !== 200 ) { return state }
        const uuidChartPosition = event.config.url.split( '/' ).pop()
        return ({
            ...state,
            planning: {
                ...state.planning,
                positionsChartedByType: state.planning.positionsChartedByType.map( item =>
                    item.uuidChartPosition === uuidChartPosition ? ({
                        ...item,
                        uuidChartPosition: event.status === 200 ? null : item.uuidChartPosition,
                        error:             event.status !== 200,
                    }) : item
                )
            }
        })
    }
)

export const createTransactionChartByType = asyncUpdate(
    'planning.createTransactionChartByType',
    ( params ) => api.createTransactionChartByType( params ),
    ( event, state ) => {
        if ( event.status !== 200 ) { return state }
        return {
            ...state,
            planning: {
                ...state.planning,
                chartByType: [ ...state.planning.chartByType, event.data ]
            }
        }
    }
)

export const editTransactionChartByType = update(
    'planning.editTransactionChartByType',
    ( value, state ) => ({
        ...state,
        planning: {
            ...state.planning,
            chartByType: state.planning.chartByType.map( item =>
                item.plannedTransactionNumberUuid === value.plannedTransactionNumberUuid ?
                    value
                    :
                    item
            )
        }
    })
)

export const deleteChartByType = asyncUpdate(
    'planning.deleteChartByType',
    ( params ) => api.deleteChartByType( params ),
    ( event, state ) => {
        if ( event.status !== 200 ) { return state }
        const plannedTransactionChartUuid = event.config.url.split( '/' ).pop()
        const filterCallback = item => item.plannedTransactionNumberUuid !== plannedTransactionChartUuid
        return ({
            ...state,
            planning: {
                ...state.planning,
                chartByType: state.planning.chartByType.filter( filterCallback )
            }
        })
    }
)

export const createPositionChartByType = asyncUpdate(
    'planning.createPositionChartByType',
    ( params ) => api.createPositionChartByType( params ),
    ( event, state ) => ({
        ...state,
        planning: {
            ...state.planning,
            chartByType: state.planning.chartByType.map( item =>
                item.plannedTransactionNumberUuid === event.data.plannedTransactionNumberUuid ?
                    event.data
                    :
                    item
            )
        }
    })
)

export const updatePositionChartByType = asyncUpdate(
    'planning.updatePositionChartByType',
    ( params ) => api.updatePositionChartByType( params ),
    ( event, state ) => ({
        ...state,
        planning: {
            ...state.planning,
            chartByType: state.planning.chartByType.map( item =>
                item.plannedTransactionNumberUuid === event.data.plannedTransactionNumberUuid ?
                    event.data
                    :
                    item
            )
        }
    })
)

export const updatePlannedTransaction = asyncUpdate(
    'planning.updatePlannedTransaction',
    ( params ) => api.updatePlannedTransaction( params ),
    ( event, state ) => {
        if ( event.status !== 200 ) { return state }
        return ({
            ...state,
            planning: {
                ...state.planning,
                chartByType: state.planning.chartByType.map( item =>
                    item.plannedTransactionNumberUuid === event.data.plannedTransactionNumberUuid ?
                        event.data
                        :
                        item
                )
            }
        })
    }
)

export const getRestaurantFilterInfo = asyncUpdate(
    'planning.restaurantInfo',
    ( params ) => api.getRestaurantsFilterInfo( params ),
    ( event, state ) => ({
        ...state,
        planning: {
            ...state.planning,
            restaurantInfo: event.data[ 0 ]
        }
    })
)
