import { Card, Checkbox, Spin }        from 'antd'
import { Spinner }                     from 'components'
import React, { useEffect, useState }  from 'react'
import { connect }                     from 'react-redux'
import { allActions, mapStateToProps } from './connector'
import './show-data.scss'

const TEXT = 'Использовать gross показатели для отображения данных по фактическим продажам'

const ShowData = ( props ) => {
    const [ sending, setSending ] = useState( false )
    const [ loading, setLoading ] = useState( true )

    const update = () => {
        props.searchRestaurantOptions({ factsNumbers: [ props.restaurantId ] }).finally(() => {
            setLoading( false )
        })
    }

    useEffect(() => {
        update()
    }, [])

    useEffect(() => {
        setSending( false )
    }, [ props.showData?.isGrossAccounting ])

    useEffect(() => {
        update()
    }, [ props.restaurantId ])

    const onChange = ( e ) => {
        setSending( true )
        if ( props.showData?.uuid ) {
            props.updateRestaurantOptions({
                uuid:              props.showData.uuid,
                factsNumber:       props.restaurantId,
                isGrossAccounting: e.target.checked
            }).finally(() => setSending( false ))
        } else {
            props.postRestaurantOptions({
                factsNumber:       props.restaurantId,
                isGrossAccounting: e.target.checked
            }).finally(() => setSending( false ))
        }
    }

    return (
        <div className='show-data'>
            <Card bordered={false}>
                <h2>Отображение данных</h2>
                {!props.showDataError && ( loading ?
                        <Spinner />
                    :
                        <div className='checkbox-wrapper'>
                            {sending ?
                                    <>
                                        <Spin size="small" style={{ minWidth: 16 }} />
                                        <span style={{ paddingLeft: 8 }}>{TEXT}</span>
                                    </>
                                :
                                    <Checkbox
                                        onChange={onChange}
                                        checked={!!props.showData?.isGrossAccounting}
                                    >{TEXT}</Checkbox>}
                        </div>
                )}

            </Card>
        </div>
    )
}

export default connect( mapStateToProps, allActions )( ShowData )
