/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Layout }           from 'antd'

/* APPLICATION */
import {
    DashboardCard,
    TurnoverControls,
    TurnoverChart,
    TurnoverSummary,
} from 'components'

import { format } from 'tools'

const { Sider, Header, Content } = Layout

class TurnoverCard extends Component {
    static propTypes = {
        request:   PropTypes.object,
        workHours: PropTypes.object,
        turnover:  PropTypes.object,
        offset:   PropTypes.string,

        update: PropTypes.func,
    }

    constructor ( props ) {
        super( props )
        this.state = { low: false, }
    }

    componentDidMount () {
        this.props.turnover.summary &&
      this.setLow( this.props.turnover.summary.lowTurnover )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { turnover } = this.props

        if ( nextProps.turnover.summary !== turnover.summary ) {
            nextProps.turnover.summary
                ? this.setLow( nextProps.turnover.summary.lowTurnover )
                : this.setLow( false )
        }
    }

    setLow = ( low ) => low !== undefined && this.setState({ low })

    render () {
        const { turnover, request, update, offset } = this.props,
              { low } = this.state,
              currency = format.extract.currency( request.unitOfMeasure )

        return (
            <DashboardCard spans={[ 24, 24 ]} info="Показатели  указаны “факт/план”.">
                <Layout>
                    <Sider className="auto-sider">
                        <h2>Товарооборот</h2>
                        <TurnoverSummary
                            showDetails={format.check.now( request.dateStart, request.dateEnd )}
                            data={turnover}
                            currency={currency}
                        />
                    </Sider>
                    <Content>
                        <Layout>
                            <Header>
                                <TurnoverControls update={update} request={request} />
                            </Header>
                            <Content>
                                <TurnoverChart
                                    turnover={turnover}
                                    currency={currency}
                                    low={request.currentPredef === 'shift' && low}
                                    offset={offset}
                                />
                            </Content>
                        </Layout>
                    </Content>
                </Layout>
            </DashboardCard>
        )
    }
}

export default TurnoverCard
