/* VENDOR */
import React from 'react'

/* APPLICATION */
import * as formats from '../formats'

import column            from './helpers'
import { Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

/*const list = ( visits, key ) =>
    {
        const
            all = visits.reduce(
                ( list, row ) => {
                    ;( row && list.indexOf( row[key] ) < 0 ) && ( list.push( row[key] ) )
                    return list
                },
                []
            )

        if ( all.length === 0 ) return '—'

        return all.length > 1
            ? `${all[0]} +${all.length-1}`
            : all[0]
    }*/

const rgmpop = () => ( text ) => {
    return text

    /*
        if ( !record ) return text
        if ( !record.months[index] ) return text
        if ( !record.months[index].restaurant ) return text
        if ( !record.months[index].restaurant.visits || record.months[index].restaurant.visits.length < 1 ) return text

        const
            visits = record.months[index].restaurant.visits,
            info = (
                <React.Fragment>
                    <p key="rgm">RGM: { list( visits, 'rgm' ) }</p>
                    <p key="area">ТУ: { list( visits, 'areaCoach' ) }</p>
                </React.Fragment>
            )

        return (
            <Popover
                content = { info }
                overlayClassName = "table-cell-popover"
            >
                { text }
            </Popover>
        )*/
}

export default {
    restaurants: {
        categories: ( cmp ) => [
            column( 'Категория блюд', 'categoryName', {
                render: ( text ) => <span>{text}</span>,
                sorter: 'locale'
            }),
            column( 'План', 'planCategoryRating', {
                editable:  true,
                as:        'currency',
                numeric:   true,
                sorter:    'bynull',
                disabled:  ( rec ) =>
                    cmp.state.focused && cmp.state.focused.categoryId !== rec.categoryId,
            }),
            column( 'Факт', 'factCategoryRating', {
                as:        'currency',
                sorter:    'bynull',
                numeric:   true,
            }),
            column( 'Разница', 'deltaCategoryRating', {
                as:      'deltaCurrency',
                sorter:    'bynull',
                cell:    [ 'cellclass' ],
                numeric: true,
            }),
        ],
        detailedCategories: ( cmp ) => [
            column('Код', 'factsNumber', {sorter: 'bynull', width: 120}),
            column( 'Ресторан', 'restaurantName', {
                render: ( text ) => <span>{text}</span>,
                sorter: 'locale',
                width: 450
            }),
            column( 'План', 'planCategoryRating', {
                editable:  true,
                as:        'currency',
                numeric:   true,
                sorter:    'bynull',
                disabled:  ( rec ) =>
                    cmp.state.focused && cmp.state.focused.categoryId !== rec.categoryId,
            }),
            column( 'Факт', 'factCategoryRating', {
                as:        'currency',
                sorter:    'bynull',
                numeric:   true,
            }),
            column( 'Разница', 'deltaCategoryRating', {
                as:      'deltaCurrency',
                sorter:    'bynull',
                cell:    [ 'cellclass' ],
                numeric: true,
            }),
        ],
        turnover: [
            column( '', '_selected', {
                as:   'boolean',
                wait: false,
            }),
            column( 'Код', 'factsNumber', { sorter: 'bynull', }),
            column( 'Ресторан', 'restaurantName', {
                as:     'restaurantWithTime',
                sorter: 'locale',
            }),
            column(
                <div className="delta-turnover delta-header-turnover-center">
                    <span>% выполнения ТО</span>
                    <Tooltip title='"Факт ТО" деленный на "План ТО Директора"'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>,
                'percentage', {
                    as:         'percent',
                    sorter:     'bynull',
                    colorIndex: 'currentPercentage',
                }),
            column(
                <div className="delta-turnover">
                    <span>% выполнения ТО текущий</span>
                    <Tooltip title='"Факт ТО" деленный на "План ТО Директора текущий". Зеленый ≥ 100, желтый < 100, оранжевый < 95, красный < 90'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>,
                'currentPercentage', {
                    as:         'percentBar',
                    sorter:     'bynull',
                    width:      250,
                    colorIndex: 'currentPercentage',
                }),

            column(
                <div className="delta-turnover">
                    <span>План ТО ТУ</span>
                    <Tooltip title='"План ТО ТУ" за весь день/неделю/месяц/произвольный период'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>,
                'planTurnoverAreaCoach', {
                    numeric: true,
                    as:      'currency',
                    sorter:  'bynull',
                }),

            column(
                <div className="delta-turnover">
                    <span>План ТО Директора</span>
                    <Tooltip title='"План ТО Директора" за весь день/неделю/месяц/произвольный период'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>,
                'planTurnoverRgm', {
                    numeric: true,
                    as:      'currency',
                    sorter:  'bynull',
                }),

            column(
                <div className="delta-turnover">
                    <span>Разница План ТО</span>
                    <Tooltip title='Разница между “План ТО ТУ“ и “План ТО Директора“'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>,
                'deltaPlanTurnover', {
                    numeric:       true,
                    as:            'deltaCurrency',
                    sorter:        'bynull',
                    isRedPositive: true,
                }),

            column(
                <div className="delta-turnover">
                    <span>План ТО Директора <br/>текущий</span>
                    <Tooltip title='План ТО Директора, накопленный на текущее время работы ресторана (вплоть до минут)'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>,
                'planTurnoverRgmCurrent', {
                    numeric: true,
                    as:      'currency',
                    sorter:  'bynull',
                }),

            column( 'Факт ТО', 'factTurnover', {
                numeric: true,
                as:      'currency',
                sorter:  'bynull',
            }),

            column(
                <div className="delta-turnover">
                    <span>Разница Факт<br/> и План ТО ТУ</span>
                    <Tooltip title='Разница между "Факт ТО" и "План ТО ТУ"'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>,
                'deltaTurnoverAreaCoach', {
                    numeric: true,
                    as:      'deltaCurrency',
                    sorter:  'bynull',
                }),

            column(
                <div className="delta-turnover">
                    <span>Разница Факт и План<br/> ТО Директора</span>
                    <Tooltip title='Разница между "Факт ТО" и "План ТО Директора"'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>,
                'deltaTurnoverRgm', {
                    numeric: true,
                    as:      'deltaCurrency',
                    sorter:  'bynull',
                    cell:    [ 'cellclass' ],
                }),

            column(
                <div className="delta-turnover">
                    <span>Разница Факт и План ТО<br/> Директора текущий</span>
                    <Tooltip title='Разница между "Факт ТО" и "План ТО Директора текущий"'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>,
                'deltaTurnoverRgmCurrent', {
                    numeric: true,
                    as:      'deltaCurrency',
                    sorter:  'bynull',
                    cell:    [ 'cellclass' ],
                }),
        ],
        average: [
            column( 'Код', 'factsNumber', { sorter: 'bynull', }),
            column( 'Ресторан', 'restaurantName', { sorter: 'locale', }),
            column( 'Средний чек', 'factAverageCheck', {
                numeric: true,
                as:      'currency',
                sorter:  'bynull',
            }),
            column( 'Цель', 'planAverageCheck', {
                numeric: true,
                as:      'currency',
                sorter:  'bynull',
            }),
            column( 'Разница', 'deltaAverageCheck', {
                numeric: true,
                as:      'deltaCurrency',
                sorter:  'bynull',
                cell:    [ 'cellclass' ],
            }),
        ],
        time: [
            column( 'Код', 'factsNumber', { sorter: 'bynull', }),
            column( 'Ресторан', 'restaurantName', {
                sorter: 'locale',
                render: ( text ) => <span title={text}>{text}</span>,
            }),
            column( 'Время', 'factServiceTime', {
                format: 'seconds',
                as:     'time',
                sorter: 'bydate',
                cell:   [ 'cellclass' ],
            }),
            column( '', 'deltaServiceTime', {
                format: 'seconds',
                as:     'time',
                cell:   [ 'cellclass' ],
            }),
            column( '', 'hit', { as: 'hitPercentage', }),
        ],
        performance: [
            column( '', '_selected', {
                as:   'boolean',
                wait: false,
            }),
            column( 'Код', 'factsNumber', { sorter: 'bynull', }),
            column( 'Ресторан', 'restaurantName', {
                as:     'restaurantWithTime',
                sorter: 'locale',
            }),
            column( 'План ТО ТУ', 'planTurnoverAreaCoach', {
                as:      'number',
                numeric: true,
                sorter:  'bynull',
            }),
            column( 'План ТО Директора', 'planTurnoverRgm', {
                format:  'thousand',
                numeric: true,
                sorter:  'bynull',
            }),
            column(
                <div className="delta-turnover">
                    <span>Разница План ТО</span>
                    <Tooltip title='Разница между “План ТО ТУ“ и “План ТО Директора“'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>
                , 'deltaPlanTurnover', {
                    numeric:       true,
                    as:            'deltaCurrency',
                    sorter:        'bynull',
                    isRedPositive: true,
                }),
            column( 'Факт ТО', 'factTurnover', {
                format:  'thousand',
                numeric: true,
                sorter:  'bynull',
            }),
            column(
                <div className="delta-turnover">
                    <span>Разница Факт<br/> и План ТО ТУ</span>
                    <Tooltip title='Разница между "Факт ТО" и "План ТО ТУ"'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>
                , 'deltaTurnoverAreaCoach', {
                    numeric: true,
                    as:      'deltaCurrency',
                    sorter:  'bynull',
                }),
            column(
                <div className="delta-turnover">
                    <span>Разница Факт и План<br/> ТО Директора</span>
                    <Tooltip title='Разница между "Факт ТО" и "План ТО Директора"'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>
                , 'deltaTurnoverRgm', {
                    numeric: true,
                    as:      'deltaCurrency',
                    sorter:  'bynull',
                }),
            column(
                <div className="delta-turnover">
                    <span>План ТРЗ</span>
                    <Tooltip title='Транзакции План'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>
                , 'planTransactions', {
                    as:      'number',
                    numeric: true,
                    sorter:  'bynull',
                }),
            column(
                <div className="delta-turnover">
                    <span>Факт ТРЗ</span>
                    <Tooltip title='Транзакции Факт'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>
                , 'factTransactions', {
                    as:      'deltaNumbers',
                    numeric: true,
                    sorter:  'bynull',
                    objForDelta:
                    { fact: 'factTransactions', plan: 'planTransactions' },
                }),
            column( 'План СЧ', 'planAverageCheck', {
                numeric: true,
                as:      'currency',
                sorter:  'bynull',
            }),
            column( 'Факт СЧ', 'factAverageCheck', {
                numeric: true,
                as:      'deltaNumbers',
                sorter:  'bynull',
                objForDelta:
                    { fact: 'factAverageCheck', plan: 'planAverageCheck' },
            }),
            column(
                <div className="delta-turnover">
                    <span>Цель SPMH ТУ</span>
                    <Tooltip title='Целевой SPMH на месяц, добавляемый ТУ в разделе "Планирование"'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>
                , 'goalSpmh', {
                    numeric: true,
                    as:      'currency',
                    sorter:  'bynull',
                }),
            column(
                <div className="delta-turnover">
                    <span>План SPMH<br/> Директора</span>
                </div>, 'planSpmh', {
                    as:      'number',
                    numeric: true,
                    sorter:  'bynull',
                }),
            column( 'Факт SPMH', 'factSpmh', {
                as:      'number',
                numeric: true,
                sorter:  'bynull',
            }),
            column( 'План TCPH', 'planTcph', {
                format:  'float',
                numeric: true,
                sorter:  'bynull',
            }),
            column( 'Факт TCPH', 'factTcph', {
                format:  'float',
                numeric: true,
                sorter:  'bynull',
            }),
            column(
                <div className="delta-turnover">
                    <span>Items Per Hour</span>
                    <Tooltip title='Количество блюд в чеках, деленное на количество отработанных часов'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>
                , 'factItemsPerHour', {
                    numeric: true,
                    format:  'float',
                    sorter:  'bynull',
                }),
            column(
                <div className="delta-turnover">
                    <span>Количество блюд</span>
                    <Tooltip title='Количество блюд (в том числе блюда из комбо)'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>
                , 'allProductsInChecks', {
                    numeric: true,
                    format:  'float',
                    sorter:  'bynull',
                }),
            column(
                <div className="delta-turnover">
                    <span>Запланировано<br/> часов</span>
                </div>
                , 'planProductiveUnproductiveHours', {
                    format:  'float',
                    numeric: true,
                    sorter:  'bynull',
                }),
            column(
                <div className="delta-turnover">
                    <span>Отработано<br/> часов</span>
                </div>
                , 'factProductiveUnproductiveHours', {
                    format:  'float',
                    numeric: true,
                    sorter:  'bynull',
                }),
        ],

        visits: [
            column( 'Дата', 'date', {
                as:         'date',
                dateFormat: formats.onlyDayView,
            }),
            column( 'Оценка', 'result', { as: 'currency', }),
            column( 'ТУ', 'areaCoach' ),
            column( 'RGM', 'rgm' ),
            column( 'МС', 'shiftSupervisor' ),
        ],

        overall: [
            column( 'Quarter Placeholder', '', {
                children: [
                    column( 'Ресторан', 'restaurantName', { sorter: 'locale', }),
                ],
            }),
            column( 'Итог', 'total', {
                as:        'currency',
                rowSpan:   2,
                sorter:    'bynull',
                className: 'collapsed',
            }),
        ],

        month: ( index ) => [
            column( 'Оценка RGM', `month-${index}-total`, {
                as:     'currency',
                render: rgmpop( index ),
            }),
            column( 'Ресторан', `month-${index}-restaurant`, { as: 'currency', }),
            column( 'People', `month-${index}-people`, {
                as:     'currency',
                render: rgmpop( index ),
            }),
            column( 'Products', `month-${index}-product`, {
                as:     'currency',
                render: rgmpop( index ),
            }),
            column( 'Promo', `month-${index}-promo`, {
                as:     'currency',
                render: rgmpop( index ),
            }),
            column( 'Place', `month-${index}-place`, {
                as:     'currency',
                render: rgmpop( index ),
            }),
            column( 'Рутина', `month-${index}-routine`, {
                as:       'currency',
                max:      100,
                editable: true,
            }),
            column( 'AP BSC', `month-${index}-apbsc`, {
                as:       'currency',
                max:      100,
                editable: true,
            }),
            column( 'BSC', `month-${index}-bsc`, {
                editable:      true,
                max:           5,
                step:          0.1,
                rawFormat:     'noZeroFloat',
                fixedDecimals: 1,
                as:            'number',
            }),
        ],
    },
}
