/* VENDOR */
import React, { Component } from 'react'

/* APPLICATION */
import './inner-content.scss'

class InnerContent extends Component {
    cls = () => {
        const cls = [ 'inner-content' ]

        this.props.className && cls.push( this.props.className )
        this.props.fixed && cls.push( 'fixed' )
        this.props.bigOffset && cls.push( 'big-offset' )

        return cls.join( ' ' )
    }

    render () {
        return <div className={this.cls()}>{this.props.children}</div>
    }
}

export default InnerContent
