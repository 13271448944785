/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Button }           from 'antd'

/* APPLICATION */
import { Spinner } from 'components'
import './load-more-button.scss'

class LoadMoreButton extends Component {
    static propTypes = {
        onClick: PropTypes.func,
        text:    PropTypes.string,
        loading: PropTypes.bool,
    }

    static defaultProps = { text: 'Показать еще', }

    render () {
        if ( this.props.loading ) { return <Spinner className="offset" /> }

        return (
            <Button className="load-more-button" onClick={this.props.onClick}>
                {this.props.text}
            </Button>
        )
    }
}

export default LoadMoreButton
