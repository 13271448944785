/* IMPORT */

import { settingsActions } from 'services'

/* EXPORT */

export const mapStateToProps = ( state ) => ({
    categories: state.settings.poscats,
    positions:  state.settings.adminPositions,
})

export const allActions = { ...settingsActions, }
