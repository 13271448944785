/* VENDOR */
import React from 'react'

/* APPLICATION */
import { Icon, IconActive, createIcon } from './Icon'

const svg = () => (
    <svg width="8" height="5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.71 1.71002L3.3 4.30002C3.48736 4.48627 3.74081 4.59081 4.005 4.59081C4.26919 4.59081 4.52264 4.48627 4.71 4.30002L7.3 1.71002C7.44103 1.57015 7.53721 1.39145 7.57628 1.1967C7.61535 1.00195 7.59554 0.799979 7.51937 0.616535C7.4432 0.433091 7.31413 0.276486 7.14861 0.166685C6.98309 0.0568838 6.78862 -0.00113818 6.59 1.69169e-05L1.41 1.69169e-05C1.21223 0.000846148 1.01914 0.060301 0.855158 0.170864C0.691174 0.281427 0.563658 0.438133 0.488732 0.621165C0.413806 0.804198 0.394837 1.00534 0.434222 1.19915C0.473607 1.39296 0.569578 1.57075 0.71 1.71002Z"
            fill="#1F86FF"
        />
    </svg>
)

class Chevron extends Icon {
    render () {
        return svg( this.primary, this.secondary )
    }
}

class ChevronActive extends IconActive {
    render () {
        return svg( this.primary, this.secondary )
    }
}

export default createIcon( Chevron, ChevronActive )
