/* IMPORT */
import {
    ratingActions,
    requestActions,
    restaurantActions,
    categoriesActions,
    settingsActions,
} from 'services'

/* EXPORT */
export const mapStateToProps = ( state ) => ({
    category:  state.rating.category,
    dishes:    state.rating.categoryDishes,
    rating:    state.rating,
    request:   state.request,
    workHours: state.settings.workHours,
})

export const allActions = {
    ...ratingActions,
    ...requestActions,
    ...restaurantActions,
    ...settingsActions,
    ...categoriesActions,
}
