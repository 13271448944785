/* VENDOR */
import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { Layout, Modal }    from 'antd'
import moment               from 'moment'

/* APPLICATION */
import { AppHeader, TasksReports, TaskDetails, EditIssue } from 'components'

import { format } from 'tools'
import config     from 'config'

import { mapStateToProps, allActions } from './connector'
import './tasks.scss'

const { Content, Header } = Layout

class Reports extends Component {
    constructor ( props ) {
        super( props )
        this.state = {
            date:      moment(),
            dates:     [ moment().startOf( 'week' ), moment().endOf( 'week' ) ],
            assignees: {},
            selected:  null,
            dueTime:   '',

            dGroup:      null,
            popup:       false,
            edit:        null,
            open:        false,
            remove:      false,
            issueSaving: false,
            forceFull:   false,
        }
    }

    componentWillUnmount () {
        this.props.flushConsolidatedDate()
        this.props.flushConsolidatedPeriod()
    }

    componentDidMount () {
        const { day, restaurantId } = this.props,
              { date } = this.state

        this.props.fetchFullReport({
            restaurantId,
            date: date.format( config.format.dayAPI ),
        })
        day ? this.selectFirst( day ) : this.load( restaurantId, date )

        this.props.flushStaff()
        this.props.fetchAssignStaff( restaurantId )
        this.props.fetchExecutorStaff( restaurantId )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { restaurants, restaurantId, assignees, executors, period, day } =
        this.props,
              { dates, date } = this.state

        if (
            restaurantId !== nextProps.restaurantId ||
      restaurants !== nextProps.restaurants
        ) {
            this.props.flushStaff()
            this.props.flushFullReport()

            this.props.fetchAssignStaff( nextProps.restaurantId )
            this.props.fetchExecutorStaff( nextProps.restaurantId )
            this.props.fetchFullReport({
                restaurantId: nextProps.restaurantId,
                date:         date.format( config.format.dayAPI ),
            })
            nextProps.day && this.load( nextProps.restaurantId, date )
            nextProps.period && this.loadByDates( dates, nextProps.restaurantId )
        }

        ( day !== nextProps.day || period !== nextProps.period ) &&
      this.selectFirst( nextProps.day || nextProps.period )
        assignees !== nextProps.assignees && this.setAssignees( nextProps.assignees )
        executors !== nextProps.executors && this.setExecutors( nextProps.executors )
    }

    setforceFull = ( view ) =>
        this.setState({ forceFull: view === 'summary', })

    selectFirst = ( data ) => {
        const { selected } = this.state

        this.select(
            data
                ? selected
                    ? data.find(( r ) => r.userId === selected.userId )
                    : data.filter(( r ) => !!r.userId )[ 0 ]
                : null
        )
    }

    select = ( selected ) => {
        const old = this.state.selected;

        ( !selected || ( old && old.userId !== selected.userId )) &&
      this.props.flushConsolidatedManager()
        this.setState({ selected }, this.loadSelected )
    }

    loadSelected = () => {
        const { selected, dates, date } = this.state,
              { restaurantId, day } = this.props

        if ( !selected ) { return }
        day
            ? this.props.fetchManagerShift({
                date:   date.format( config.format.dayAPI ),
                restaurantId,
                userId: selected.userId,
            })
            : this.props.fetchManagerTasks({
                dateStart: dates[ 0 ].format( config.format.date ),
                dateEnd:   dates[ 1 ].format( config.format.date ),
                restaurantId,
                userId:    selected.userId,
            })
    }

    setAssignees = ( list ) => {
        if ( !list || !Array.isArray( list )) {
            this.setState({ assignees: {}, })
            return
        }

        this.setState({
            assignees: format.generate.keyval(
                format.generate.options(
                    list
                        .sort(( a, b ) => {
                            if ( !a.lastName && !b.lastName ) { return 0 }
                            if ( !a.lastName && b.lastName ) { return 1 }
                            if ( a.lastName && !b.lastName ) { return -1 }
                            return a.lastName.localeCompare( b.lastName )
                        })
                        .filter(( e ) => e.firstName || e.lastName )
                        .map(( e ) => {
                            e.fullName = e.lastName + ' ' + e.firstName
                            return e
                        }),
                    'userId',
                    'fullName'
                )
            ),
        })
    }

    setExecutors = ( list ) => {
        if ( !list || !Array.isArray( list )) {
            this.setState({ executors: {}, })
            return
        }

        this.setState({
            executors: format.generate.keyval(
                format.generate.options(
                    list
                        .sort(( a, b ) => {
                            if ( !a.lastName && !b.lastName ) { return 0 }
                            if ( !a.lastName && b.lastName ) { return 1 }
                            if ( a.lastName && !b.lastName ) { return -1 }
                            return a.lastName.localeCompare( b.lastName )
                        })
                        .filter(( e ) => e.firstName || e.lastName )
                        .map(( e ) => {
                            e.fullName = e.lastName + ' ' + e.firstName
                            return e
                        }),
                    'fullName',
                    'fullName'
                )
            ),
        })
    }

    loadByDates = ( dates, restaurantId, silent ) => {
        !silent && this.props.flushConsolidatedPeriod()
        this.props.fetchConsolidatedPeriod({
            dateStart: dates[ 0 ].format( config.format.date ),
            dateEnd:   dates[ 1 ].format( config.format.date ),
            restaurantId,
        })
    }

    load = ( restaurantId, date, silent ) => {
        !silent && this.props.flushConsolidatedDate()
        this.props.fetchConsolidatedDate({
            date: date.format( config.format.dayAPI ),
            restaurantId,
        })
    }

    setDate = ( date ) => {
        const { restaurantId } = this.props

        if ( !date ) { return }

        this.setState({ date })
        this.props.flushConsolidatedPeriod()
        this.props.flushFullReport()
        this.load( this.props.restaurantId, date )
        this.props.fetchFullReport({
            restaurantId,
            date: date.format( config.format.dayAPI ),
        })
    }

    setDates = ( raw ) => {
        const dates = [ raw[ 0 ].startOf( 'day' ), raw[ 1 ].endOf( 'day' ) ]

        this.setState({ dates })
        this.props.flushConsolidatedDate()
        this.loadByDates( dates, this.props.restaurantId )
    }

    getDetails = ( group ) => ( item ) => {
        setTimeout(() => this.props.fetchTask( item.id ), 100 )
        this.setState({
            dueTime: item.dueTime,
            dGroup:  group,
            popup:   true,
        })
    }

    hideDetails = () => {
        setTimeout(() => {
            this.props.flushTask()
            this.setState({
                dueTime: '',
                dGroup:  null,
            })
        }, 300 )

        this.setState({ popup: false, })
    }

    open = () =>
        this.setState({ open: true, })

    cancel = ( showPopup ) => () => {
        this.setState({
            remove: false,
            popup:  !!showPopup,
        })
    }

    close = () => {
        this.setState({
            open:        false,
            remove:      false,
            issueSaving: false
        })

        setTimeout(() => {
            this.props.flushTask()
            this.setState({
                dGroup:  null,
                edit:    null,
                dueTime: '',
            })
        }, 400 )
    }

    beforeAdd = ( data ) => {
        this.setState({ issueSaving: true }, () => {
            data.id ? this.save( data ) : this.add( data )
        })
    }

    remove = () => {
        const { restaurantId, day } = this.props,
              { dates, date, edit, forceFull } = this.state

        this.props.removeTask( edit.id )
        this.close()

        setTimeout(() => {
            forceFull
                ? this.props.fetchFullReport({
                    restaurantId,
                    date: date.format( config.format.dayAPI ),
                })
                : day
                    ? this.load( restaurantId, date, true )
                    : this.loadByDates( dates, restaurantId, true )
        }, 500 )
    }

    save = ( data ) => {
        const { restaurantId, day } = this.props,
              { dates, date, forceFull } = this.state

        this.props.updateTask({
            restaurantId,
            id:           data.id,
            assigneeName: data.assigneeName,
            executorName: data.executorName,
            title:        data.taskName,
            description:  data.taskDescription,
            completeDate: data.dueTime,
            needPhoto:    !!data.isPhotoRequired,
            templateName: data.isPhotoRequired ? 'defaultTaskProof' : 'defaultTask',
        }).then(() => {
            this.close()

            forceFull
                ? this.props.fetchFullReport({
                    restaurantId,
                    date: date.format( config.format.dayAPI ),
                })
                : day
                    ? this.load( restaurantId, date, true )
                    : this.loadByDates( dates, restaurantId, true )
        }).catch(() => this.setState({ issueSaving: false }))
    }

    add = ( data ) => {
        const { restaurantId, day } = this.props,
              { dates, date, forceFull } = this.state,
              res = {
                  restaurantId,
                  assigneeName: data.assigneeName,
                  executorName: data.executorName,
                  title:        data.taskName,
                  description:  data.taskDescription,
                  completeDate: data.dueTime,
                  needPhoto:    !!data.isPhotoRequired,
                  templateName: data.isPhotoRequired ? 'defaultTaskProof' : 'defaultTask',
              }

        data.actionPlanInfo && ( res.actionPlanInfo = data.actionPlanInfo )

        this.props.createTask( res ).then(() => {
            this.props.flushNewTask()
            this.close()

            forceFull
                ? this.props.fetchFullReport({
                    restaurantId,
                    date: date.format( config.format.dayAPI ),
                })
                : day
                    ? this.load( restaurantId, date, true )
                    : this.loadByDates( dates, restaurantId, true )
        }).catch(() => this.setState({ issueSaving: false }))
    }

    prepare = ( data ) =>
        data
            ? data
                .filter(( r ) => !!r.userId )
                .map(( item ) => {
                    const { selected } = this.state

                    item._current =
              selected &&
              item.userId === selected.userId &&
              item.timeStart === selected.timeStart
                    return item
                })
            : null

    edit = ( data ) =>
        this.setState({
            popup: false,
            open:  true,
            edit:  data,
        })

    askRemove = ( data ) =>
        this.setState({
            popup:  false,
            edit:   data,
            remove: true,
        })

    render () {
        const {
            details,
            period,
            day,
            manager,
            restaurantId,
            report,
            user,
            deviations,
        } = this.props,
              {
                  date,
                  dates,
                  executors,
                  assignees,
                  popup,
                  dueTime,
                  edit,
                  remove,
                  dGroup,
                  issueSaving,
                  open
              } = this.state,
              rdetails = details ? { ...details, dueTime } : null,
              data = this.prepare( period || day )

        return (
            <section className="kfc-tasks kfc-tabbed-page scroll-container">
                <Layout>
                    <Header>
                        <AppHeader ready={!!data} timeData={parseInt( restaurantId )} />
                    </Header>
                    <Content>
                        <TasksReports
                            date={date}
                            dates={dates}
                            restaurantId={restaurantId}
                            period={data}
                            manager={manager}
                            report={report}
                            assignees={assignees}
                            executors={executors}
                            user={user}
                            savingAP={issueSaving}
                            onSwitch={this.setforceFull}
                            setDate={this.setDate}
                            setDates={this.setDates}
                            onDetails={this.getDetails}
                            onSelect={this.select}
                            onAdd={this.open}
                            onCreateAP={this.beforeAdd}
                        />

                        <TaskDetails
                            task={rdetails}
                            group={dGroup}
                            deviations={deviations}
                            visible={popup}
                            canEdit={
                                rdetails &&
                ( rdetails.runnedBy === user.info.userId ||
                  user.info.jobRole === 'RGM' )
                            }
                            canRemove={
                                rdetails &&
                rdetails.runnedBy === user.info.userId &&
                rdetails.status !== 'COMPLETED' &&
                rdetails.status !== 'CANCELED'
                            }
                            onClose={this.hideDetails}
                            onEdit={this.edit}
                            onRemove={this.askRemove}
                            getDeviations={this.props.fetchTaskDeviations}
                            flushDeviations={this.props.flushDeviations}
                        />

                        <EditIssue
                            data={edit}
                            user={user.info}
                            assignees={assignees}
                            executors={executors}
                            visible={open}
                            saving={issueSaving}
                            onCancel={this.close}
                            onSubmit={this.beforeAdd}
                        />

                        <Modal
                            title="Вы действительно хотите удалить задачу? Это действие нельзя отменить"
                            className="kfc-popup"
                            centered={true}
                            open={remove}
                            onOk={this.remove}
                            okText={'Да, удалить'}
                            okButtonProps={{ className: 'wide-btn' }}
                            onCancel={this.cancel( true )}
                            cancelButtonProps={{ style: { display: 'none' } }}
                        />
                    </Content>
                </Layout>
            </section>
        )
    }
}

export default connect( mapStateToProps, allActions )( Reports )
