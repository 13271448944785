/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import moment               from 'moment'

/* APPLICATION */
import {
    AddButton,
    PageControls,
    ReportsControls,
    ReportTable,
    SyncScrollV,
} from 'components'
import { format } from 'tools'

import './tasks-summary.scss'

class TasksSummary extends Component {
    static propTypes = {
        type:         PropTypes.oneOf([ 'range', 'day' ]),
        restaurantId: PropTypes.string,
        content:      PropTypes.any,

        data:  PropTypes.array,
        dates: PropTypes.oneOfType([ PropTypes.array, PropTypes.object ]),

        setDates: PropTypes.func,
        onSelect: PropTypes.func,
        onScroll: PropTypes.func,
        onAdd:    PropTypes.func,
    }

    constructor ( props ) {
        super( props )
        this.container = React.createRef()
    }

    today = () => this.props.setDates( moment())
    next = () => this.props.setDates( moment( this.props.dates ).add( 1, 'day' ))
    prev = () => this.props.setDates( moment( this.props.dates ).subtract( 1, 'day' ))

    render () {
        const { data, dates, type, columns, content, executors, assignees } = this.props,
              { setDates, onSelect, onScroll } = this.props,
              scroll = onScroll( this.container )

        const disabled = !executors || !assignees

        return (
            <div className="report-table tasks-summary">
                <div className="section-cards">
                    <aside className="section-cards-aside" ref={this.card}>
                        <header className="tasks-summary-header">
                            <ReportsControls type={type} date={dates} onChange={setDates} />
                            {type === 'day' && (
                                <PageControls
                                    icon="сегодня"
                                    disable={true}
                                    action={dates.isSame( moment(), 'day' ) ? null : this.today}
                                    onNext={this.next}
                                    onPrev={this.prev}
                                />
                            )}
                        </header>
                        <div className="report-wrap" ref={this.container}>
                            <ReportTable
                                rowKey={( r ) => r.userId + r.name + r.timeStart}
                                unit="percent"
                                data={data ? format.generate.noPager( data ) : null}
                                columns={columns}
                                scroll={scroll}
                                onCellClick={onSelect}
                            />
                            {scroll && (
                                <SyncScrollV
                                    container={this.container}
                                    watch=".ant-table-body"
                                    width={24}
                                    offset={48}
                                />
                            )}
                        </div>
                    </aside>
                    <section className="section-cards-content">{content}</section>
                </div>

                <AddButton text="Новое поручение" action={this.props.onAdd} disabled={disabled} />
            </div>
        )
    }
}

export default TasksSummary
