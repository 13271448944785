/* VENDOR */
import React from 'react'

/* APPLICATION */
import { Icon, IconActive, createIcon } from './Icon'

const svg = ( primary ) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none">
        <path
            fill={primary}
            d="M.25 11.095v2.28c0 .21.165.375.375.375h2.28c.0975 0 .195-.0375.2625-.1125l8.19-8.18246-2.8125-2.8125L.3625 10.825c-.075.075-.1125.165-.1125.27zm13.2825-7.81496c.2925-.2925.2925-.765 0-1.0575L11.7775.467544c-.2925-.2925-.765-.2925-1.0575 0L9.3475 1.84004l2.8125 2.8125 1.3725-1.3725z"
        />
    </svg>
)

class Edit extends Icon {
    render () {
        return svg( this.primary, this.secondary )
    }
}

class EditActive extends IconActive {
    render () {
        return svg( this.primary, this.secondary )
    }
}

export default createIcon( Edit, EditActive )
