/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Select, Alert }    from 'antd'

/* APPLICATION */
import { CurrencySwitch, TurnoverChart, Report } from 'components'
import { format }                                from 'tools'
import config                                    from 'config'
import moment from 'moment';

class TurnoverRating extends Component {
    static propTypes = {
        request:  PropTypes.object,
        currency: PropTypes.string,

        data:     PropTypes.object,
        selected: PropTypes.array,

        partner:  PropTypes.any,
        partners: PropTypes.array,

        onControls: PropTypes.func,
        onSelect:   PropTypes.func,
        onPartner:  PropTypes.func,
    }

    constructor ( props ) {
        super( props )
        this.state = {
            mixed:    {},
            selected: [],
        }
    }

    componentDidMount () {
        const { data } = this.props
        data.all && data.report && this.setData( this.props )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { data, selected } = this.props

        if (
            nextProps.data.all !== data.all ||
            nextProps.data.report !== data.report
        ) {
            this.setData( nextProps )
        }

        if ( nextProps.selected !== selected ) {
            this.setData( nextProps )
        }
    }

    setData = ( props ) => {
        if ( !props.data || !props.data.report || !props.data.all ) {
            this.setState({ mixed: null, })
            return
        }

        const mixed = format.copy.object( props.data.report ),
              content = format.copy.array( mixed.content ),
              allRow = format.copy.object( props.data.all )

        content?.forEach(( row ) => {
            row._selected = props.selected.indexOf( row.factsNumber ) > -1
        })

        allRow.restaurant = 'Все рестораны'
        allRow.restaurantName = 'Все рестораны'
        allRow._selected = props.selected.length < 1
        allRow._alwaysOnTop = true

        content?.unshift( allRow )

        mixed.content = content

        mixed?.content?.forEach(( item, idx ) => {
            item.idx = idx
        })

        this.setState({ mixed })
    }

    applySelection = ( data, record, value ) => {
        const all = data.find(( r ) => !r.factsNumber )

        if ( value ) {
            if ( all.restaurant === record.restaurant ) {
                data.forEach(( r ) => ( r._selected = false ))
                all._selected = true
            } else {
                all._selected = false
            }
        } else {
            if ( !data.reduce(( res, r ) => res || r._selected, false )) {
                all._selected = true
            }
        }

        return all._selected
            ? []
            : data
                .filter(( r ) => r.factsNumber && r._selected )
                .map(( r ) => r.factsNumber )
    }

    onChange = ( record, key, value ) => {
        const mixed = format.copy.object( this.state.mixed ),
              nr = format.copy.object( record ),
              index = format.find.index( mixed.content, 'restaurantName', record.restaurantName )

        let selected = format.copy.array( this.props.selected )

        nr[ key ] = value
        mixed.content[ index ] = nr

        if ( key === '_selected' ) {
            selected = this.applySelection( mixed.content, record, value )
            this.props.onSelect( selected )
        }

        this.setState({
            mixed,
            selected,
        })
    }

    prepare = ( record, index, columns ) =>
        format.goodBad.iterate.goodBad( record, [ 'diff' ], columns )

    options = ( items ) =>
        items.map(( item ) => (
            <Select.Option value={item.value} key={item.value}>
                {item.label}
            </Select.Option>
        ))

    render () {
        const {
            request,
            data,
            currency,
            partner,
            city,
            regionCoach,
            marketCoach,
            facilityType,
            factsNumbers,
            partners,
            cities,
            regionCoaches,
            marketCoaches,
            areaCoaches,
            areaCoach,
            restaurants,
            facilityTypes,
            onFilter,
            onFocus,
            onControls,
            onPartner
        } = this.props,
              { mixed } = this.state

        return (
            <div className="rating-inner">
                <div className="rating-turnover">
                    <div className="rating-turnover__filters ">
                        <Select
                            disabled={!partners.length}
                            placeholder="Партнеры"
                            mode='multiple'
                            maxTagCount={1}
                            value={partner}
                            onChange={onPartner}
                            style={{ width: '100%', maxWidth: 250 }}
                            getPopupContainer={( trigger ) => trigger.parentNode}
                            filterOption={( input, option ) =>
                                option.props.children.toLowerCase().indexOf( input.toLowerCase()) >= 0
                            }
                            allowClear
                        >
                            {this.options([
                                ...( partners || []),
                            ])}
                        </Select>
                        <Select
                            placeholder="Типы ресторанов"
                            mode='multiple'
                            maxTagCount={1}
                            style={{ width: 500 }}
                            value={ facilityType }
                            onFocus={ onFocus }
                            onChange={( value ) => onFilter({ value, name: 'facilityTypes' })}
                            getPopupContainer={( trigger ) => trigger.parentNode}
                            disabled={!facilityTypes.length}
                            filterOption={( input, option ) =>
                                option.props.children.toLowerCase().indexOf( input.toLowerCase()) >= 0
                            }
                            allowClear
                        >
                            {this.options([
                                ...( facilityTypes || []),
                            ])}
                        </Select>
                        <Select
                            placeholder="Города"
                            mode='multiple'
                            maxTagCount={1}
                            value={city}
                            style={{ width: 500 }}
                            onChange={( value ) => onFilter({ value, name: 'cities' })}
                            onFocus={onFocus}
                            getPopupContainer={( trigger ) => trigger.parentNode}
                            disabled={!cities.length}
                            allowClear
                        >
                            {this.options([
                                ...( cities || []),
                            ])}
                        </Select>
                        <Select
                            placeholder="Region coaches"
                            mode='multiple'
                            maxTagCount={1}
                            style={{ width: 500 }}
                            value={regionCoach}
                            onFocus={onFocus}
                            onChange={( value ) => onFilter({ value, name: 'regionCoaches' })}
                            getPopupContainer={( trigger ) => trigger.parentNode}
                            disabled={!regionCoaches.length}
                            filterOption={( input, option ) =>
                                option.props.children.toLowerCase().indexOf( input.toLowerCase()) >= 0
                            }
                            allowClear
                        >
                            {this.options([
                                ...( regionCoaches || []),
                            ])}
                        </Select>
                        <Select
                            placeholder="Market coaches"
                            mode='multiple'
                            maxTagCount={1}
                            style={{ width: 500 }}
                            value={marketCoach}
                            onChange={( value ) => onFilter({ value, name: 'marketCoaches' })}
                            onFocus={onFocus}
                            getPopupContainer={( trigger ) => trigger.parentNode}
                            disabled={!marketCoaches.length}
                            filterOption={( input, option ) =>
                                option.props.children.toLowerCase().indexOf( input.toLowerCase()) >= 0
                            }
                            allowClear
                        >
                            {this.options([
                                ...( marketCoaches || []),
                            ])}
                        </Select>
                        <Select
                            placeholder="Area coaches"
                            mode='multiple'
                            maxTagCount={1}
                            style={{ width: 600 }}
                            value={areaCoach}
                            onChange={( value ) => onFilter({ value, name: 'areaCoaches' })}
                            onFocus={onFocus}
                            getPopupContainer={( trigger ) => trigger.parentNode}
                            disabled={!areaCoaches.length}
                            filterOption={( input, option ) =>
                                option.props.children.toLowerCase().indexOf( input.toLowerCase()) >= 0
                            }
                            allowClear
                        >
                            {this.options([
                                ...( areaCoaches || []),
                            ])}
                        </Select>
                        <Select
                            placeholder="Рестораны"
                            maxTagCount={1}
                            mode='multiple'
                            style={{ width: 700 }}
                            value={factsNumbers}
                            onChange={( value ) => onFilter({ value, name: 'factsNumbers' })}
                            onFocus={onFocus}
                            getPopupContainer={( trigger ) => trigger.parentNode}
                            disabled={!restaurants.length}
                            filterOption={( input, option ) =>
                                option.props.children.toLowerCase().indexOf( input.toLowerCase()) >= 0
                            }
                            allowClear
                        >
                            {this.options([
                                ...( restaurants || []),
                            ])}
                        </Select>
                    </div>
                </div>
                {!mixed || !mixed.content || mixed.content.length > 1 ? (
                    <React.Fragment>
                        <TurnoverChart offset={moment().utcOffset()} turnover={data} currency={currency} />
                        <CurrencySwitch
                            request={request}
                            update={onControls}
                            showGrowing={true}
                            hideCurrency={true}
                            after={'Показаны общие фактические и плановые значения выбранных ресторанов'}
                        />
                        <div className="rating-height-limit-report-table">
                            <Report
                                unit="currency"
                                rowKey="idx"
                                data={mixed}
                                columns={config.tables.restaurants.turnover}
                                prepare={this.prepare}
                                onMore={this.props.onMore}
                                onChange={this.onChange}
                            />
                        </div>
                    </React.Fragment>
                ) : (
                    <Alert
                        message="Рестораны этого партнёра пока не подключены к DSR."
                        type="warning"
                    />
                )}
            </div>
        )
    }
}

export default TurnoverRating
