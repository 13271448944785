import React, {
    useEffect,
    useState
}  from 'react'
import { Checkbox, Select, Spin } from 'antd'
import {
    allActions,
    mapStateToProps
} from './connector'
import { connect } from 'react-redux'
import './restaurant-chart-filter.scss'

const { Option } = Select

const defaultFilters = {
    isExpressWindow: false,
    type:            undefined,
    facilityType:    undefined
}

const RestaurantChartFilter = ( props ) => {
    const [ filters, setFilters ] = useState( defaultFilters )
    const [ loading, setLoading ] = useState( false )

    useEffect(() => {
        if ( !Array.isArray( props.types ) || !Array.isArray( props.facilityTypes )) {
            setLoading( true )

            const requests = [
                props.fetchRestaurantTypes(),
                props.fetchFacilityTypes(),
            ]

            if ( props.disabled ) {
                requests.push( props.getRestaurantFilterInfo({ search: props.restaurantId }))
            }

            Promise.all( requests ).finally(() => {
                setLoading( false )
            })
        }
    }, [])

    useEffect(() => {
        if ( !loading && Array.isArray( props.types ) && Array.isArray( props.facilityTypes ) && !props.disabled ) {
            setFilters({
                isExpressWindow: true,
                facilityType:    props.facilityTypes[ 0 ].id,
                type:            props.types[ 0 ].id
            })
        }
    }, [ loading ])

    useEffect(() => {
        if ( props.onChangeFilter && filters.facilityType && filters.type ) {
            props.onChangeFilter({
                facilityType:    { id: filters.facilityType },
                restaurantType:  { id: filters.type },
                isExpressWindow: filters.isExpressWindow
            })
        }
    }, [ filters ])

    useEffect(() => {
        if ( props.disabled && props.restaurantInfo ) {
            const { facilityType, restaurantType, isExpressWindow } = props.restaurantInfo

            setFilters({
                facilityType: facilityType.id,
                type:         restaurantType.id,
                isExpressWindow
            })
        }
    }, [ props.restaurantInfo ])

    useEffect(() => {
        if ( props.disabled ) {
            setLoading( true )
            props.getRestaurantFilterInfo({ search: props.restaurantId }).finally(() => setLoading( false ))
        }
    }, [ props.restaurantId ])

    return (
        <div className='restaurant-chart-filter'>
            <Spin spinning={loading}>
                <div className='filter-item-row'>
                    <label htmlFor='object-type'>Тип объекта</label>
                    <Select
                        id='object-type'
                        style={{ width: 120 }}
                        onChange={( value ) => setFilters({
                            ...filters,
                            facilityType: value
                        })}
                        placeholder='Не выбрано'
                        value={filters.facilityType}
                        getPopupContainer={trigger => trigger.parentNode}
                        disabled={props.disabled}
                    >
                        {props.facilityTypes?.map( item => <Option
                            key={`facility-type-${item.id}`}
                            value={item.id}
                        >{item.name}</Option> )}
                    </Select>
                </div>
                <div className='filter-item-row'>
                    <label htmlFor='restaurant-type'>Тип ресторана</label>
                    <Select
                        id='restaurant-type'
                        style={{ width: 120 }}
                        onChange={( value ) => setFilters({
                            ...filters,
                            type: value
                        })}
                        placeholder='Не выбрано'
                        loading={loading}
                        value={filters.type}
                        getPopupContainer={trigger => trigger.parentNode}
                        disabled={props.disabled}
                    >
                        {props.types?.map( item => <Option
                            key={`facility-type-${item.id}`}
                            value={item.id}
                        >{item.name}</Option> )}
                    </Select>
                </div>
                <div className='filter-item-row checkbox'>
                    <label htmlFor='express-window'>Экспресс окно</label>
                    <Checkbox
                        id='express-window'
                        onChange={( e ) => setFilters({
                            ...filters,
                            isExpressWindow: e.target.checked
                        })}
                        checked={filters.isExpressWindow}
                        disabled={props.disabled}
                    />
                </div>
            </Spin>

        </div>
    )
}

export default connect( mapStateToProps, allActions )( RestaurantChartFilter )
