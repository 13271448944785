/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Alert }            from 'antd'

import {
    AreaSeries,
    FlexibleWidthXYPlot,
    LineMarkSeries,
    XAxis,
    LineSeries,
    GradientDefs,
    LabelSeries,
} from 'react-vis'

/* APPLICATION */
import { Link, Spinner } from 'components'
import { format }        from 'tools'
import './manning-chart.scss'

class ManningChart extends Component {
    static propTypes = {
        data:    PropTypes.array,
        onAdd:   PropTypes.func,
        isError: PropTypes.boolean
    }

    constructor ( props ) {
        super( props )
        this.state = {
            hover: null,
            tickx: [],
            ticky: [],
            minx:  0,
            miny:  0,
            maxx:  0,
            maxy:  0,
        }
    }

    componentDidMount () {
        this.setData( this.props.data )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        if ( nextProps.data !== this.props.data ) {
            this.setData( nextProps.data )
        }
    }

    setData = ( data ) => {
        if ( !data ) { return }

        const xs = format.extract.key( data, 'x' ),
              ys = format.extract.key( data, 'y' )

        this.setState({
            tickx: xs,
            ticky: ys,
            minx:  format.extract.min( xs ),
            miny:  format.extract.min( ys ),
            maxx:  format.extract.max( xs ),
            maxy:  format.extract.max( ys ),
        })
    }

    hover = ( hover ) => this.setState({ hover })

    render () {
        const { miny, maxy } = this.state,
              { data, isError } = this.props

        if ( isError ) { return null }

        if ( !data ) {
            return <div className="manning-chart">
                <h2>Зависимость TCPH от количества сотрудников</h2>
                <Spinner />
            </div>
        }

        if ( data.length < 1 ) {
            return (
                <div className="manning-chart">
                    <h2>Зависимость TCPH от количества сотрудников</h2>
                    <Alert
                        message={
                            <span>
                Чтобы сформировать чарт,{' '}
                                <Link onClick={this.props.onAdd}>добавьте цель</Link> по
                                кол-ву транзакций в час
                            </span>
                        }
                        type="warning"
                    />
                </div>
            )
        }

        return (
            <div className="manning-chart">
                <h2>Зависимость TCPH от количества сотрудников</h2>
                <FlexibleWidthXYPlot
                    height={340}
                    yDomain={[ miny, maxy * 1.2 ]}
                    margin={{ left: 60 }}
                >
                    {data
                        .filter(( p ) => data.find(( sp ) => sp.x === p.x ) === p )
                        .map(( point ) => (
                            <LineSeries
                                key={'v' + point.x}
                                data={[
                                    { x: point.x, y: point.y },
                                    { x: point.x, y: miny },
                                ]}
                                stroke="#8FC2FF"
                                opacity={0.3}
                                strokeWidth={1}
                            />
                        ))}

                    <GradientDefs>
                        <linearGradient
                            id="fillDaLines"
                            x1="50%"
                            x2="50%"
                            y1="0%"
                            y2="100%"
                        >
                            <stop offset="0%" stopColor="#D6E9FF" stopOpacity={1} />
                            <stop offset="100%" stopColor="#ffffff" stopOpacity={0} />
                        </linearGradient>
                    </GradientDefs>

                    <AreaSeries
                        data={data}
                        color="url(#fillDaLines)"
                        curve="curveMonotoneX"
                    />

                    <LineMarkSeries
                        data={data}
                        size={3.5}
                        color="#8FC2FF"
                        fill="#8FC2FF"
                        curve="curveMonotoneX"
                        markStyle={{ stroke: 'transparent' }}
                        onNearestXY={this.hover}
                    />

                    <LabelSeries labelAnchorX="end" labelAnchorY="bottom" data={data} />

                    <XAxis
                        tickSizeInner={0}
                        tickFormat={( v ) => parseInt( v )}
                        tickValues={this.state.tickx}
                        style={{
                            line:  { stroke: '#b8b8b8', strokeWidth: 1 },
                            ticks: { stroke: '#b8b8b8' },
                            text:  { stroke: 'none', fill: '#7c7c7c' },
                        }}
                    />
                </FlexibleWidthXYPlot>
                <span className="bottom-label">Сотрудники</span>
            </div>
        )
    }
}

export default ManningChart
