/* VENDOR */
import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { Layout, Card }     from 'antd'
import moment               from 'moment'

/* APPLICATION */
import {
    AppHeader,
    CalendarControls,
    CalendarScreen,
    InnerContent,
    SubCalendar,
    ViewTypeSwitch,
} from 'components'
import { format } from 'tools'
import config     from 'config'

import { allActions, mapStateToProps } from './connector'

import './calendar.scss'
import {AddEventModal} from '../../components/calendar/AddEventModal/AddEventModal';
import {dayAPI} from '../../config/formats.js';
import { isArraysEqual } from 'tools/format/array'

const { Content, Header } = Layout

let tabsCache = {}

class Calendar extends Component {
    /* REFS */

    calendarScreen = React.createRef()
    subCalendar = React.createRef()

    calendar = () =>
        ( this.state.glob === 'my'
                ? this.calendarScreen
                : this.subCalendar
        ).current.getApi()

    /* REACT */

    constructor ( props ) {
        super( props )

        this.state = {
            glob:    'my',
            view:    'week',
            subview: 'month',

            tabs: { my: 'Мои задачи', },

            date:     this.getDateFromSession() ?? moment().startOf('week'),
            selected: null,

            add:      false,
            position: null,
            addDate:  null,

            needReload: false,
        }

        this.set = format.generate.set( this )
    }

    componentDidMount () {
        const savedDate = this.getDateFromSession();

        if (savedDate) {
            this.setState({ date: savedDate }, () => {
                this.load(false, true);
                this.loadCalendarEvents();
            });
        } else {
            this.load(false, true);
            this.loadCalendarEvents();
        }
    }

    componentDidUpdate ( prevProps, prevState ) {
        const { request, month, restaurants, summary, sub, factsNumbers } = this.props,
            { needReload, add, tabs, glob, view } = this.state,
            date = this.date()

        !isArraysEqual(factsNumbers, prevProps.factsNumbers) && this.loadCalendarEvents();
        if (request.restaurantId !== prevProps.request.restaurantId) {
            for (const [ key ] of Object.entries(tabsCache)) {
                tabsCache[ key ].sub = null
            }
            this.load(true)
        }
        if (prevState.subview !== this.state.subview || glob !== prevState.glob) {
            this.setDate()
        }

        if ( (month !== prevProps.month && needReload) || (glob !== prevState.glob && needReload) ) {
            this.load()
            this.set.needReload( false )
        }

        if (prevState.date !== date || prevState.view !== view) {
            this.saveStateToSession();
        }

        const cacheKey = this.getCacheKey();
        const monthDate = moment(this.date()).format(config.format.monthAPI);
        !tabsCache[ cacheKey ] && ( tabsCache[ cacheKey ] = {} )
        !tabsCache[ monthDate ] && ( tabsCache[ monthDate ] = {} )

        if (month !== prevProps.month && month) {
            tabsCache[ cacheKey ].month = month;
        } if (summary !== prevProps.summary && summary) {
            tabsCache[ monthDate ].summary = summary;
        } if (sub !== prevProps.sub && sub) {
            tabsCache[ cacheKey ].sub = sub;
        }

        (( restaurants !== prevProps.restaurants &&
                restaurants &&
                restaurants.length > 1 ) ||
            ( restaurants && restaurants.length > 1 && !tabs.sub )) &&
        this.set.tabs({
            my:  'Мои задачи',
            sub: 'Подчинённые',
        })
        add !== prevState.add &&
        !add &&
        setTimeout(() => this.set.addDate( null ), 300 )
    }

    /* DATA */

    date = () => {
        return moment( this.state.date ).format(config.format.dayAPI)
    }

    /* HELPERS */

    getCacheKey = () => {
        const { glob, view, subview } = this.state
        const monthDate = moment(this.date()).format(config.format.monthAPI);
        const dayDate = moment(this.date()).format(config.format.dayAPI);
        return glob === 'my'
            ? view === 'month' ? monthDate : dayDate
            : subview === 'month' ? monthDate : dayDate;
    }

    extractRepeat = ( event ) => {
        const repeat = event.repeat
            ? format.extract.include( event, [
                'intervalType',
                'intervalIncrement',
                'weekDays',
                'monthDays',
                'completeStrategy',
                'completeDate',
                'completeCount',
            ])
            : null

        if ( repeat ) {
            repeat.completeDate = repeat.completeDate.format( config.format.dayAPI )

            Object.keys( repeat ).forEach(( key ) => {
                if ( !repeat[ key ]) {
                    delete repeat[ key ]
                }
            })
            repeat.completeStrategy !== 'date' && delete repeat.completeDate
            repeat.completeStrategy !== 'count' && delete repeat.completeCount
            repeat.intervalType !== 'week' && delete repeat.weekDays
            repeat.intervalType !== 'month' && delete repeat.monthDays
            repeat.intervalIncrement &&
            ( repeat.intervalIncrement = parseInt( repeat.intervalIncrement ))
            repeat.completeCount &&
            ( repeat.completeCount = parseInt( repeat.completeCount ))
        }

        return repeat
    }

    check = {
        events: ( date ) =>
            this.props.month
                .map(( e ) => moment( e.date ).isSame( date, 'day' ))
                .includes( true ),

        week: ( info ) => {
            const { view } = this.state,
                res = view === 'week'

            res && this.open( info )

            return res
        },

        select: ( info ) => {
            if ( !info.date ) {
                this.set.selected( null )
                return
            }

            if ( !this.check.week( info )) {
                this.select( info )
            }
        },
    }

    /* EVENTS */

    load = (ignoreCache) => {
        const date = this.date(),
            rid = this.props.request.restaurantId,
            { glob, view, subview } = this.state,
            monthEnd = moment(date).endOf('month').format(config.format.dayAPI),
            monthStart = moment(date).startOf('month').format(dayAPI),
            weekEnd = moment(date).endOf('week').format(config.format.dayAPI);

        this.set.selected(null);

        let cacheKey = this.getCacheKey();
        const monthDate = moment(this.date()).format(config.format.monthAPI);

        if (ignoreCache) {
            if (glob === 'my') {
                this.props.flushCalendarSummary();
                this.props.fetchCalendarSummary({ dateStart: monthStart, dateEnd: monthEnd });

                this.props.flushCalendarMonth();
                // Если выбран месяц, используем месяц в запросе, иначе неделю
                if (view === 'month') {
                    this.props.fetchCalendar({ dateStart: monthStart, dateEnd: monthEnd });
                } else {
                    this.props.fetchCalendar({ dateStart: date, dateEnd: weekEnd });
                }
            } else {
                this.props.flushCalendarSub();
                if (subview === 'month') {
                    this.props.fetchCalendarSub({ rid, dateStart: monthStart, dateEnd: monthEnd });
                } else {
                    this.props.fetchCalendarSub({ rid, dateStart: date, dateEnd: weekEnd });
                }
            }
        }


        if ( glob === 'my' ) {
            if ( tabsCache?.[ monthDate ]?.month && tabsCache?.[ monthDate ]?.summary
                && moment(date).endOf('week').month() === moment(monthDate).month()
            ) {
                cacheKey = monthDate;
            }

            // Проверка каэша и запрос по fetchCalendar
            if ( tabsCache?.[ cacheKey ]?.month ) {
                this.props.setCalendarMonth( tabsCache[ cacheKey ].month );
            } else {
                this.props.flushCalendarMonth()
                if (view === 'week') {
                    this.props.fetchCalendar({ dateStart: date, dateEnd: weekEnd })
                } else {
                    this.props.fetchCalendar({ dateStart: monthStart, dateEnd: monthEnd })
                }
            }

            // Проверка кэша и запрос по fetchSummary
            if (tabsCache?.[ monthDate ]?.summary) {
                this.props.setCalendarSummary( tabsCache[ monthDate ].summary );
            } else {
                this.props.flushCalendarSummary()
                this.props.fetchCalendarSummary({ dateStart: monthStart, dateEnd: monthEnd })
            }
        } else {
            if ( tabsCache?.[ monthDate ]?.sub
                && moment(date).endOf('week').month() === moment(monthDate).month()
            ) {
                cacheKey = monthDate;
            }

            // Проверка кэша и запрос fetchCalendarRGM
            if ( tabsCache?.[ cacheKey ]?.sub ) {
                this.props.setCalendarSub( tabsCache[ cacheKey ].sub );
            } else {
                this.props.flushCalendarSub()
                if (subview === 'week') {
                    this.props.fetchCalendarSub({rid, dateStart: date, dateEnd: weekEnd })
                } else {
                    this.props.fetchCalendarSub({rid, dateStart: monthStart, dateEnd: monthEnd })
                }

            }
        }
    }

    saveStateToSession = () => {
        const { date, view } = this.state;

        const currentSessionDate = sessionStorage.getItem('calendarDate');
        if (currentSessionDate) {
            const sessionDate = moment(currentSessionDate, config.format.dayAPI);

            if (sessionDate.isSame(date, 'month') && view === 'month') {
                return;
            }
        }

        sessionStorage.setItem('calendarDate', moment(date).format(config.format.dayAPI));
    };


    getDateFromSession = () => {
        const savedDate = sessionStorage.getItem('calendarDate');
        return savedDate ? moment(savedDate, config.format.dayAPI) : null;
    };


    onFilter = (array) => {
        this.props.setFactsNumbers(array);
    }

    loadCalendarEvents = () => {
        const { factsNumbers } = this.props;
        this.props.fetchCalendarEvents({ factsNumbers });
    }

    onSave = ( event ) => {
        const repeat = this.extractRepeat( event ),
            res = {
                taskId:      event.taskId,
                title:       event.title,
                description: event.description,
                date:        moment( event.date )
                    .set({
                        hours:   event.timeStart.hours(),
                        minutes: event.timeStart.minutes(),
                    })
                    .format( config.format.dateFull + 'Z' ),
                duration: event.timeEnd.diff( event.timeStart, 'minutes' ),
                done:     false,
                repeat,
                single:   !event.single,
            }

        this.onDate( res )
        event.single && this.set.needReload( true )
    }

    onDone = ( id ) => this.props.completeCalendar( id )

    setNeedReload = ( value ) => this.set.needReload( value )

    onRemove = ( data ) => {
        this.props.removeCalendarEvent( data )
        !data.single && this.set.needReload( true )
    }

    setRestaurant = ( rid ) => this.props.setRestaurant( rid.toString())

    setView = ( view ) => this.set.view( view, this.setDate )

    setDate = (raw) => {
        const sessionDate = sessionStorage.getItem('calendarDate')
        const prepared = raw || sessionDate || this.state.date,
            date = this.state.glob === 'my'
                ? this.state.view === 'month'
                    ? moment(prepared)
                    : moment(prepared).startOf('week')
                : this.state.subview === 'month'
                    ? moment(prepared)
                    : moment(prepared).startOf('week')

        // if (moment(raw).month() !== this.state.date.month()) {
        //     this.set.date(date, () => {
        //         this.load(false, true);
        //     });
        // } else {
        //     this.set.date(date, this.load);
        // }

        this.set.date(date, () => {
            this.load(false, true);
        });
        this.calendar().gotoDate(date.format(config.format.date));
    };


    add = ( event ) => this.props.addCalendarEvent( event )

    select = ( info ) => {
        const { selected } = this.state,
            selection =
                selected && info.dateStr === selected.date
                    ? null
                    : {
                        date:       info.dateStr,
                        rendering:  'background',
                        classNames: [ 'fc-selected' ],
                    }

        this.set.selected( selection )
    }

    open = ( info ) => {
        const { view } = this.state,
            half = config.ui.popupWidth / 2,
            deny =
                view === 'month'
                    ? moment( info.date ).isBefore( moment().startOf( 'day' ))
                    : moment( info.date ).isBefore( moment())

        if ( deny ) {
            return
        }

        this.set.state({
            add:      true,
            position: {
                left: info.jsEvent
                    ? Math.min(
                        info.jsEvent.pageX - half,
                        window.innerWidth - config.ui.popupWidth - 24
                    )
                    : 0,
                top: info.jsEvent
                    ? Math.min( info.jsEvent.pageY - 100, window.innerHeight - 512 )
                    : 0,
            },
            addDate: moment( info.date ),
        })
    }

    /* UI */

    content = () => {
        const { date, glob, view, subview, selected } = this.state,
            { events, summary, month, sub, factsNumbers, restaurants, monthLoading, eventsLoading, request } = this.props

        if ( glob === 'my' ) {
            return (
                <CalendarScreen
                    loading={monthLoading}
                    eventsLoading={eventsLoading}
                    ref={this.calendarScreen}
                    view={view}
                    date={date}
                    inbox={events ? events?.tasks : null}
                    events={month}
                    summary={events && summary ? { ...events.summary, ...summary } : null}
                    selected={selected}
                    onSelect={this.check.select}
                    onOpen={this.open}
                    onDone={this.onDone}
                    setNeedReload={this.setNeedReload}
                    updateCalendar={this.props.updateCalendar}
                    extractRepeat={this.extractRepeat}
                    onRemove={this.onRemove}
                    onFilter={this.onFilter}
                    restaurants={restaurants}
                    factsNumbers={factsNumbers}
                />
            )
        }

        return (
            <SubCalendar
                ref={this.subCalendar}
                view={subview}
                date={date}
                data={sub}
                summary={summary}
                isLoading={this.props.subLoading}
                restaurant={parseInt( request.restaurantId )}
                restaurants={restaurants}
                onChange={this.setRestaurant}
            />
        )
    }

    render () {
        const { date, glob, view, subview, add, position, addDate, tabs } =
            this.state

        return (
            <section className="kfc-calendar kfc-tabbed-page scroll-container">
                <Layout>
                    <Header>
                        <AppHeader hideRestaurants />
                    </Header>
                    <Content>
                        <ViewTypeSwitch update={this.set.glob} tabs={tabs} current={glob} />
                        <InnerContent fixed={true}>
                            <div className="tabbed-content">
                                <Card bordered={false}>
                                    <CalendarControls
                                        date={date}
                                        view={glob === 'my' ? view : subview}
                                        onViewChange={
                                            glob === 'my' ? this.setView : this.set.subview
                                        }
                                        onDateChange={this.setDate}
                                    />
                                    {this.content()}
                                </Card>
                            </div>
                        </InnerContent>
                    </Content>
                </Layout>
                <AddEventModal
                    view={view}
                    extractRepeat={this.extractRepeat}
                    setAdd={this.set.add}
                    setAddDate={this.set.addDate}
                    setNeedReload={this.setNeedReload}
                    addHandler={this.add}
                    add={add}
                    addDate={addDate}
                    position={position}
                />
            </section>
        )
    }
}

export default connect( mapStateToProps, allActions )( Calendar )
