/* VENDOR */
import React from 'react'

/* APPLICATION */
import { Icon, IconActive, createIcon } from './Icon'

const svg = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13 10H3C2.45 10 2 10.45 2 11C2 11.55 2.45 12 3 12H13C13.55 12 14 11.55 14 11C14 10.45 13.55 10 13 10ZM13 6H3C2.45 6 2 6.45 2 7C2 7.55 2.45 8 3 8H13C13.55 8 14 7.55 14 7C14 6.45 13.55 6 13 6ZM18 14V11C18 10.45 17.55 10 17 10C16.45 10 16 10.45 16 11V14H13C12.45 14 12 14.45 12 15C12 15.55 12.45 16 13 16H16V19C16 19.55 16.45 20 17 20C17.55 20 18 19.55 18 19V16H21C21.55 16 22 15.55 22 15C22 14.45 21.55 14 21 14H18ZM3 16H9C9.55 16 10 15.55 10 15C10 14.45 9.55 14 9 14H3C2.45 14 2 14.45 2 15C2 15.55 2.45 16 3 16Z"
            fill="#1F86FF"
        />
    </svg>
)

class PlaylistAdd extends Icon {
    render () {
        return svg( this.primary, this.secondary )
    }
}

class PlaylistAddActive extends IconActive {
    render () {
        return svg( this.primary, this.secondary )
    }
}

export default createIcon( PlaylistAdd, PlaylistAddActive )
