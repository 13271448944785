export default {
    options: {
        validateStatus:  () => true,
        withCredentials: true,
        ...( localStorage.getItem( 'access_token' ) && ( function () {
            return { headers: { 'Authorization': `Bearer ${localStorage.getItem( 'access_token' )}` } }
        })())
    },

    base:       process.env.REACT_APP_BASE,
    auth:       process.env.REACT_APP_AUTH_API,
    business:   process.env.REACT_APP_BUSINESS_API,
    dashboard:  process.env.REACT_APP_DASHBOARD_API,
    planning:   process.env.REACT_APP_PLANNING_API,
    facade:     process.env.REACT_APP_FACADE_API,
    restaurant: process.env.REACT_APP_RESTAURANT_API,
    staff:      process.env.REACT_APP_STAFF_API,
    upload:     process.env.REACT_APP_UPLOAD_URL,
    feedback:   process.env.REACT_APP_FEEDBACK_API,
}
