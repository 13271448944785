import React        from 'react'
import { PprTable } from 'components'
import { format }   from 'tools'
import {
    randomName,
    randomInt
} from '../PprPartnerInfo/fake'
import './ppr-partner-area-info.scss'

const rclCodes = [ 'RGM', 'ARGM', 'SM' ]


const PprPartnerAreaInfo = () => {
    const data = {
        region:           'Москва',
        acSurnameName:    randomName(),
        acPositionStatus: 'AC',
        period:           'Июль',
        hrbpSurnameName:  randomName(),
        hrtmSurnameName:  randomName(),
        restaurants:      [ ...Array( randomInt( 5, 10 )) ].map(() => ({
            factsNumber:                  randomInt( 111111, 999999 ),
            restaurantName:               'Restaurant',
            restaurantStructureRgmCount:  randomInt( 1, 9 ),
            restaurantStructureArgmCount: randomInt( 1, 9 ),
            restaurantStructureSmCount:   randomInt( 1, 9 ),
            rgmFact:                      randomInt( 1, 9 ),
            argmFact:                     randomInt( 1, 9 ),
            smFact:                       randomInt( 1, 9 ),
        })),
        rgmOptimal:                 randomInt( 1, 9 ),
        argmOptimal:                randomInt( 1, 9 ),
        smOptimal:                  randomInt( 1, 9 ),
        rgmAreaFact:                randomInt( 1, 9 ),
        argmAreaFact:               randomInt( 1, 9 ),
        smAreaFact:                 randomInt( 1, 9 ),
        areaManagersStructureShort: {
            rgmAreaRelative:  randomInt( 1, 101 ),
            argmAreaRelative: randomInt( 1, 101 ),
            smAreaRelative:   randomInt( 1, 101 ),
        },
        areaOptimal: randomInt( 1, 9 ),
        areaFact:    randomInt( 1, 9 ),
        areaRateNew: 90,
        Managers:    [ ...Array( randomInt( 15, 60 )) ].map(() => ({
            managerSurnameName: Math.floor( Math.random() * 100 ) < 1 ? '' : randomName(),
            managerRclCode:     rclCodes[ randomInt( 0, 3 ) ]
        }))
    }

    return (
        <PprTable
            horizontal
            className='ppr-partner-area-info'
            showScroll
        >
            <thead>
                <tr>
                    <td className='bold' colSpan={2}>{data.region}</td>
                    <td className='bold' colSpan={4}>{data.acSurnameName}</td>
                    <td colSpan={2}>{data.acPositionStatus}</td>
                    <th colSpan={2}>HR BP</th>
                    <td colSpan={2} className='bold'>{data.hrbpSurnameName}</td>
                </tr>
                <tr>
                    <th colSpan={2}>{data.period}</th>
                    <th colSpan={2}>RGM</th>
                    <th colSpan={2}>ARGM</th>
                    <th colSpan={2}>SM</th>
                    <th colSpan={2}>HRM</th>
                    <td colSpan={2} className='bold'>{data.hrtmSurnameName}</td>
                </tr>
                <tr>
                    <th className='id'>ID</th>
                    <th>Ресторан</th>
                    <th>П</th>
                    <th>Ф</th>
                    <th>П</th>
                    <th>Ф</th>
                    <th>П</th>
                    <th>Ф</th>
                    {rclCodes.map( rclCode =>
                        format.array.chunk(
                            data.Managers.filter( item => item.managerRclCode === rclCode ),
                            data.restaurants.length
                        ).map(( _, chIndex ) => {
                            return <th colSpan={2} key={`header-area-info-${chIndex}`}>
                                {rclCode} {format.number.convertToRoman( chIndex + 1 )}
                            </th>
                        })
                    )}
                </tr>
            </thead>
            <tbody>
                {data.restaurants.map(( item, index ) => <tr
                    key={`ppr-area-partner-info-${index}`}
                    className='restaurant-number'
                >
                    <th>{item.factsNumber}</th>
                    <th className='color-black'>{item.restaurantName}</th>
                    <td>{item.restaurantStructureRgmCount}</td>
                    <td>{item.rgmFact}</td>
                    <td>{item.restaurantStructureArgmCount}</td>
                    <td>{item.argmFact}</td>
                    <td>{item.restaurantStructureSmCount}</td>
                    <td>{item.smFact}</td>
                    {rclCodes.map( rclCode =>
                        format.array.chunk(
                            data.Managers.filter( item => item.managerRclCode === rclCode ),
                            data.restaurants.length
                        ).map(( chunk, chIndex ) => {
                            const name = chunk[ index ]?.managerSurnameName
                            const managerRclCode = chunk[ index ]?.managerRclCode
                            const isVacancy = !name && managerRclCode
                            return <React.Fragment key={`managers-${index}-${rclCode}-${chIndex}`}>
                                <td className={`ac-name ${isVacancy && 'bg-color-red'}`}>{name || isVacancy && 'Вакансия'}</td>
                                <td className='rcl-code'>{managerRclCode}</td>
                            </React.Fragment>
                        })
                    )}
                </tr> )}
                <tr>
                    <td className='bold' colSpan={2}>Итого по должности</td>
                    <td className='bold'>{data.rgmOptimal}</td>
                    <td className='bold'>{data.rgmAreaFact}</td>
                    <td className='bold'>{data.argmOptimal}</td>
                    <td className='bold'>{data.argmAreaFact}</td>
                    <td className='bold'>{data.smOptimal}</td>
                    <td className='bold'>{data.smAreaFact}</td>
                </tr>
                <tr>
                    <td className='bold' colSpan={2}>Отклонение по должности</td>
                    <td colSpan={2} className='bold'>{data.areaManagersStructureShort.rgmAreaRelative}%</td>
                    <td colSpan={2} className='bold'>{data.areaManagersStructureShort.argmAreaRelative}%</td>
                    <td colSpan={2} className='bold'>{data.areaManagersStructureShort.smAreaRelative}%</td>
                </tr>
                <tr>
                    <td className='bold' colSpan={2}>Итого по территории</td>
                    <td colSpan={3} className='bold'>{data.areaOptimal}</td>
                    <td colSpan={3} className='bold'>{data.areaFact}</td>
                </tr>
                <tr>
                    <td className='bold' colSpan={2}>Структура менеджеров</td>
                    <td className='bg-color-red bold' colSpan={6}>{data.areaRateNew}%</td>
                </tr>
            </tbody>
        </PprTable>
    )
}

export default PprPartnerAreaInfo
