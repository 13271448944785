/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'

/* APPLICATION */
import { Spinner } from 'components'

class ChartSpin extends Component {
    static propTypes = { height: PropTypes.number, }

    render () {
        return (
            <div style={{ height: this.props.height + 'px' }}>
                <Spinner size="large" />
            </div>
        )
    }
}

export default ChartSpin
