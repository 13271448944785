/* VENDOR */
import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {DatePicker} from 'antd'
import ruRU from 'antd/es/date-picker/locale/ru_RU'

import moment from 'moment'
import 'moment/locale/ru'

/* APPLICATION */
import {ViewTypeSwitch, DateGroupDropDown} from 'components'

import {format} from 'tools'
import config from 'config'

import './calendar-switch.scss'
import { getGroup } from '../../../tools/format/dates.js'

const {RangePicker} = DatePicker,
    dateFormat = 'D MMM YYYY',
    defDates = (props) => ({
        start: props.request.dateStart
            ? moment(props.request.dateStart, config.format.date)
            : props.request.dateStart,
        end: props.request.dateEnd
            ? moment(props.request.dateEnd, config.format.date)
            : props.request.dateEnd,
    })

moment.locale('ru')
moment.updateLocale('ru', {
    monthsShort: {
        format: 'янв_фев_мар_апр_мая_июн_июл_авг_сен_окт_ноя_дек'.split('_'),
        standalone: 'янв_фев_мар_апр_май_июн_июл_авг_сен_окт_ноя_дек'.split('_'),
    },
})

class CalendarSwitch extends Component {
    static propTypes = {
        hide: PropTypes.array,

        workHours: PropTypes.object,
        actions: PropTypes.object,
        request: PropTypes.object,
        timeZoneName: PropTypes.object,

        useGrowing: PropTypes.bool,
        hideDetalization: PropTypes.bool,

        onActiveDate: PropTypes.func,
    }

    constructor (props) {
        super(props)

        const {start, end} = defDates(props)

        this.state = {
            activeDate: false,
            dates: [ start, end ],
            allow: this.testGrouping(start, end),
        }
    }

    componentDidMount () {
        this.forceUpdate()
        this.init(this.props)
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps (nextProps) {
        if (
            format.check.request(
                this.props.request,
                nextProps.request,
                [],
                nextProps.useGrowing ? [ 'growing' ] : []
            )
        ) {
            this.init(nextProps)
        }
    }

    componentDidUpdate (prevProps) {
      if (this.props.workHours !== prevProps.workHours) {
        this.init(this.props)
      }
    }

    init = (props) => {
        const {start, end} = defDates(props)
        this.update(start, end)
    }

    changeGroup = ( e ) => {
        const { actions, workHours } = this.props,
            datesInStorage = JSON.parse( sessionStorage.getItem( 'filtersData' )),
            { group, end, start } = getGroup( e, this.props.workHours, moment(), this.props.timeZoneName )


        datesInStorage.predef = e
        datesInStorage.group = group
        datesInStorage.start = start.format( config.format.date )
        datesInStorage.end = end.format( config.format.date )
        datesInStorage.lastPage = 'dashboardRating'
        datesInStorage.activeDate = false
        datesInStorage.allow = this.testGrouping( moment( datesInStorage.start ), moment( datesInStorage.end ))
        sessionStorage.setItem( 'filtersData', JSON.stringify( datesInStorage ))

        format.dates.setGroup(e, actions, this.update, workHours, null, this.props.timeZoneName)

        this.setState({activeDate: false})
        this.props.onActiveDate && this.props.onActiveDate(true)
    }

    testGrouping = (start, end) => {
        const res = {
            min: 'day',
            max: 'month',
        }

        if (!start || !end) {
            return res
        }

        const diff = end.diff(start)

        if (diff > format.dates.duration(2, 'months')) {
            res.max = 'month'
        } else if (diff > format.dates.duration(2, 'weeks')) {
            res.max = 'week'
        } else if (diff > format.dates.duration(2, 'days')) {
            res.max = 'day'
        } else {
            res.min = 'hour'
            res.max = 'hour'
        }

        return res
    }

    onCalendar = (dates) => {
        if (!dates[ 0 ] || !dates[ 1 ]) {
            return
        }

        const start = dates[ 0 ].startOf('day'),
            end = dates[ 1 ].endOf('day'),
            {workHours} = this.props,
            {setGroup, setPredef, setStartDate, setEndDate} = this.props.actions,
            {predef, group} = format.dates.getPredef(start, end)

        if (start.isSame(end, 'day')) {
            let dow = start.day()
            if (dow === 0) {
                dow = 7
            }

            const wh = {
                timeStart: '00:00',
                timeEnd: '23:59',
            }

            let timeStart = wh.timeStart.split(':'),
                timeEnd = wh.timeEnd.split(':')

            if (wh.noctidial) {
                timeStart = [ '00', '00' ]
                timeEnd = [ '23', '59' ]
            }

            start.set({hours: timeStart[ 0 ], minutes: timeStart[ 1 ]})
            end.set({hours: timeEnd[ 0 ], minutes: timeEnd[ 1 ]})
        }

        if (start.hours() > end.hours()) {
            end.add(1, 'day')
        }

        const allowGroup = this.testGrouping( start, end )

        const sessionDataResultObject = {
            predef:     predef,
            start:      start.format( config.format.date ),
            end:        end.format( config.format.date ),
            group:      group,
            allow:      allowGroup,
            lastPage:   'dashboardRating',
            activeDate: true,
        }

        sessionStorage.setItem( 'filtersData', JSON.stringify( sessionDataResultObject ))

        setPredef( predef )
        setStartDate( start.format( config.format.date ))
        setEndDate( end.format( config.format.date ))
        setGroup( group, workHours )

        this.update(start, end)
        this.setState({activeDate: true})
        this.props.onActiveDate && this.props.onActiveDate(false)
    }

    update = ( start, end ) => {
        const datesInStorage = JSON.parse( sessionStorage.getItem( 'filtersData' )),
            { workHours } = this.props,
            { setGroup, setPredef, setStartDate, setEndDate } = this.props.actions,
            { group: initGroupValue, end: initEndDate, start: initStartDate } = getGroup( 'day', workHours, null, this.props.timeZoneName )

        if ( datesInStorage && ( datesInStorage.lastPage === 'dashboardRating' || datesInStorage.lastPage === 'reports' )) {

            setPredef( datesInStorage.predef )
            setStartDate( datesInStorage.start )
            setEndDate( datesInStorage.end )
            setGroup( datesInStorage.group, workHours )

            this.setState({
                dates: [ moment( datesInStorage.start, config.format.date ), moment( datesInStorage.end, config.format.date ) ],
                allow: datesInStorage.allow,
            })

        } else {
            const sessionDataResultObject = {
                predef:   'day',
                start:    initStartDate.format( config.format.date ),
                end:      initEndDate.format( config.format.date ),
                group:    initGroupValue,
                allow:    this.testGrouping( initStartDate, initEndDate ),
                lastPage: 'dashboardRating',
            }

            sessionStorage.setItem( 'filtersData', JSON.stringify( sessionDataResultObject ))

            this.setState({
                dates: [ start, end ],
                allow: this.testGrouping( start, end ),
            })
        }
    }

    cls = () => {
        let datesInStorage = JSON.parse( sessionStorage.getItem( 'filtersData' ))
        let cls = ''

        cls += datesInStorage && datesInStorage.activeDate ? 'active' : 'passive'
        cls += ' predef-' + ( datesInStorage ? datesInStorage.predef : this.props.request.currentPredef )

        return cls
    }

    render () {
        const { hide, hideDetalization, update } = this.props,
            tabs = hide ? {} : config.tabs.basic
        var datesValue, currentPredef, allowVar, filtersDataObj

        if (hide) {
            Object.keys(config.tabs.basic)
                .filter((t) => hide.indexOf(t) < 0)
                .forEach((t) => (tabs[ t ] = config.tabs.basic[ t ]))
        }

        if ( sessionStorage.getItem( 'filtersData' )) {
            filtersDataObj = JSON.parse( sessionStorage.getItem( 'filtersData' ))
            datesValue = [ moment( filtersDataObj.start ), moment( filtersDataObj.end ) ]
            currentPredef = filtersDataObj.predef
            allowVar = filtersDataObj.allow
        }

        return (
            <div className="calendar-switch">
                <ViewTypeSwitch
                    update={this.changeGroup}
                    tabs={tabs}
                    current={ filtersDataObj && filtersDataObj.activeDate ? 'none' : currentPredef }
                />

                <RangePicker
                    bordered={false}
                    separator=":"
                    locale={ruRU}
                    className={this.cls()}
                    value={datesValue}
                    format={dateFormat}
                    onCalendarChange={this.onCalendar}
                    getCalendarContainer={(trigger) => trigger.parentNode}
                />

                {!hideDetalization && (
                    <DateGroupDropDown max={allowVar?.max} min={allowVar?.min} update={update} />
                )}
            </div>
        )
    }
}

export default CalendarSwitch
