/* IMPORT */

import { staffActions, } from 'services'

/* EXPORT */

export const mapStateToProps = ( state ) => ({
    periods:      state.staff.ppr?.periods,
    periodsError: state.staff.ppr?.periods_error,
    user:         state.user?.info,
    users:        state.staff.areaUsers,
})

export const allActions = { ...staffActions, }
