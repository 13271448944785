/* VENDOR */
import React from 'react'

/* APPLICATION */
import { Icon, IconActive, createIcon } from './Icon'

const svg = ( primary ) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="24" fill="none">
        <path
            fill={primary}
            d="M15.667 10.667H6.333c-.15606-.0046-.31145.0222-.45697.0788-.14552.0565-.27821.1417-.39021.2505-.11201.1088-.20105.2389-.26185.3827-.0608.1438-.09213.2983-.09213.4545 0 .1561.03133.3106.09213.4544.0608.1438.14984.274.26185.3828.112.1087.24469.1939.39021.2505.14552.0565.30091.0833.45697.0788h9.333c.3035-.0089.5915-.1357.803-.3535.2115-.2178.3298-.5094.3298-.813s-.1183-.5953-.3298-.8131c-.2115-.2177-.4995-.3445-.803-.3534h.001zm3.5-8.16703H18v-1.166c-.0089-.30346-.1357-.591518-.3535-.803025-.2178-.211508-.5094-.329817-.813-.329817s-.5952.118309-.813.329817c-.2178.211507-.3446.499565-.3535.803025v1.166H6.333v-1.166c.00457-.15606-.02222-.31145-.07879-.456972-.05656-.14552-.14176-.27821-.25053-.390213-.10877-.112003-.23891-.201041-.38271-.261843-.1438-.060801-.29834-.09213-.45447-.09213s-.31067.031329-.45447.09213c-.1438.060802-.27394.14984-.38271.261843-.10877.112003-.19397.244693-.25053.390213-.05657.145522-.08336.300912-.07879.456972v1.166H2.833c-.30646 0-.60991.06038-.89303.17768-.28312.11731-.54035.28925-.757.50599-.216656.21675-.388482.47405-.505668.75722C.560117 4.22403.499869 4.52751.5 4.83397V21.167c0 .6187.245798 1.2121.68332 1.6496.43752.4376 1.03093.6834 1.64968.6834h16.334c.6182-.0019 1.2105-.2483 1.6476-.6854.4372-.4371.6836-1.0294.6854-1.6476V4.83397c-.0016-.61836-.2479-1.21095-.685-1.64829-.4372-.43733-1.0296-.68386-1.648-.68571zM18 21.167H4c-.30926-.0008-.60564-.124-.82432-.3427-.21868-.2187-.34189-.5151-.34268-.8243V8.33397h16.334V20c-.0008.3092-.124.6056-.3427.8243s-.515.3419-.8243.3427zm-5.833-5.833H6.333c-.15606-.0046-.31145.0222-.45697.0788-.14552.0565-.27821.1417-.39021.2505-.11201.1088-.20105.2389-.26185.3827-.0608.1438-.09213.2983-.09213.4545 0 .1561.03133.3106.09213.4544.0608.1438.14984.274.26185.3828.112.1087.24469.1939.39021.2505.14552.0565.30091.0833.45697.0788h5.833c.3035-.0089.5915-.1357.803-.3535.2115-.2178.3298-.5094.3298-.813s-.1183-.5953-.3298-.8131c-.2115-.2177-.4995-.3445-.803-.3534h.001z"
        />
    </svg>
)

class Schedule extends Icon {
    render () {
        return svg( this.primary, this.secondary )
    }
}

class ScheduleActive extends IconActive {
    render () {
        return svg( this.primary, this.secondary )
    }
}

export default createIcon( Schedule, ScheduleActive )
