/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { connect }          from 'react-redux'
import { Modal, Alert }     from 'antd'
import { Scrollbars }       from 'react-custom-scrollbars'
import moment               from 'moment'

/* APPLICATION */
import { ReportTable, AddButton }        from 'components'
import { staffActions, settingsActions } from 'services'
import { format }                        from 'tools'
import config                            from 'config'

import './staff-positions.scss'

class StaffPositions extends Component {
    static propTypes = {
        data:     PropTypes.array,
        outstaff: PropTypes.bool,
        draft:    PropTypes.bool,
    }

    constructor ( props ) {
        super( props )
        this.state = {
            data:      props.data || [],
            positions: [],
            rates:     [ 1, 2, 3, 4, 5 ].map(( rate ) => ({
                value: rate,
                label: rate,
            })),
            confirm:  false,
            toRemove: null,
        }
    }

    componentDidMount () {
        !this.props.positions
            ? this.props.fetchPositions( this.props.request )
            : this.setPositions( this.props.positions )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        if ( this.props.data !== nextProps.data ) {
            this.setData( nextProps.data )
        }

        if ( this.props.positions !== nextProps.positions ) {
            this.setPositions( nextProps.positions )
        }

        if ( this.props.outstaff !== nextProps.outstaff ) {
            this.setPositions( nextProps.positions, nextProps.outstaff )
        }
    }

    setData = ( data ) => this.setState({ data })

    setPositions = ( poss, staff ) =>
        this.setState({ positions: this.positions( poss, staff ), })

    update = ( position ) =>
        this.props.updatePosition({
            data:   position,
            params: { employeeId: this.props.request.employeeId, },
        })

    remove = () => {
        const rec = this.state.toRemove

        if ( rec._id ) {
            const data = format.copy.array( this.state.data )
            data.splice( data.indexOf( data.find(( i ) => i._id === rec._id )), 1 )
            this.setData( data )
        } else {
            this.props.removePosition({
                employeeId: this.props.request.employeeId,
                guid:       rec.guid,
            })
        }

        this.hidePopup()
    }

    askRemove = ( item ) =>
        this.setState({
            toRemove: item,
            confirm:  true,
        })

    hidePopup = () =>
        this.setState({
            toRemove: null,
            confirm:  false,
        })

    add = () => {
        const pos = format.copy.object( config.defs.position ),
              data = format.copy.array( this.state.data )

        pos._id = format.generate.guid()

        data.push( pos )
        this.setData( data )
    }

    exist = ( guid ) => !!format.find.byKey( this.state.data, 'guid', guid )

    removeDouble = ( item ) => {
        const data = format.copy.array( this.state.data )

        data.splice( data.indexOf( item ), 1 )
        this.setState({ data })
    }

    updatePosition = ( rec, guid ) => {
        const pos = format.find.byKey( this.props.positions, 'positionGuid', guid )

        rec.name = pos.position
        rec.description = pos.name
        rec.guid = pos.positionGuid
    }

    change = ( item, key, val ) => {
        const rec = format.copy.object( item )

        if ( key === 'name' ) {
            if ( this.exist( val )) {
                this.removeDouble( item )
                return
            }

            this.updatePosition( rec, val )
        } else {
            rec[ key ] = val

            if (
                moment( rec.dateEnd, config.format.date ).isBefore( moment( rec.dateStart ))
            ) {
                const tmp = rec.dateEnd

                rec.dateEnd = rec.dateStart
                rec.dateStart = tmp
            }
        }

        rec._id && delete rec._id
        rec.blocked ? format.dates.def( rec ) : format.dates.flush( rec )

        format.update.clear( rec )
        this.update( rec )
    }

    positions = ( poss, staff ) => {
        if ( !poss ) { return [] }
        const outstaff = staff || this.props.outstaff,
              newPoss = []

        poss.forEach(( pos ) => {
            if ( !( outstaff && pos.positionGuid === config.mcGuid )) {
                newPoss.push({
                    value: pos.positionGuid,
                    label: pos.position,
                })
            }
        })
        return newPoss
    }

    render () {
        return (
            <div className="staff-positions tabbed-container">
                <Scrollbars {...config.ui.scrolls}>
                    <div className="report-table">
                        {this.props.draft ? (
                            <Alert
                                message="Заполните и сохраните анкету сотрудника, чтобы добавить его позиции"
                                type="warning"
                            />
                        ) : (
                            <ReportTable
                                data={format.generate.noPager( this.state.data )}
                                columns={config.tables.positions}
                                select={{
                                    positions: this.state.positions,
                                    rates:     this.state.rates,
                                }}
                                rowKey={( r ) => r.guid + r._id}
                                loading={false}
                                onChange={this.change}
                                onRemove={this.askRemove}
                            />
                        )}
                    </div>
                </Scrollbars>

                <Modal
                    title="Вы действительно хотите удалить позицию?"
                    className="kfc-popup"
                    centered={true}
                    open={this.state.confirm}
                    okText="Да, удалить"
                    okButtonProps={{ className: 'wide-btn' }}
                    onOk={this.remove}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    onCancel={this.hidePopup}
                />

                {!this.props.draft && (
                    <div className="tabbed-footer">
                        <AddButton text="Добавить" action={this.add} />
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = ( state ) => ({
    request:   state.request,
    positions: state.settings.positions,
}),

      allActions = {
          ...staffActions,
          ...settingsActions,
      }

export default connect( mapStateToProps, allActions )( StaffPositions )
