import * as lang from './lang'

export const byKey = ( arr, key, val ) => arr.find(( i ) => i[ key ] === val )
export const index = ( arr, key, val ) => arr.indexOf( byKey( arr, key, val ))
export const indexByKeys = ( arr, record, keys ) => {
    let i = 0,
        found = false,
        key

    while ( i < keys.length && !found ) {
        key = keys[ i ]
        found = record[ key ] !== undefined
        i++
    }

    return index( arr, key, record[ key ])
}

export const strInProp = ( item, key, str ) =>
    item.__new ||
  ( item[ key ] &&
    ( item[ key ].toString().toLowerCase().indexOf( str.toLowerCase()) > -1 ||
      item[ key ].toString().toLowerCase().indexOf( lang.enRu( str.toLowerCase())) >
        -1 ||
      item[ key ].toString().toLowerCase().indexOf( lang.ruEn( str.toLowerCase())) >
        -1 ))

export const withStr = ( str, arr, key ) => {
    if ( !arr ) {
        return null
    }

    return arr.filter(( item ) =>
        typeof key === 'string'
            ? strInProp( item, key, str )
            : key.map(( key ) => strInProp( item, key, str )).includes( true )
    )
}
