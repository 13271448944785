/* VENDOR */
import React, { Component }    from 'react'
import PropTypes               from 'prop-types'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Modal, Button } from 'antd'

/* APPLICATION */
import { FormField, Spinner } from 'components'
import config                 from 'config'
import { format }             from 'tools'

import './schedule-turnover.scss'
import moment from 'moment'

class ScheduleTurnover extends Component {
    static propTypes = {
        date:     PropTypes.object,
        turnover: PropTypes.array,

        visible: PropTypes.bool,
        loading: PropTypes.bool,
        error:   PropTypes.bool,

        getTurnover: PropTypes.func,
        onSubmit:    PropTypes.func,
        onCancel:    PropTypes.func,
    }

    constructor ( props ) {
        super( props )
        this.state = {
            turnoverDiff: {},
            isChange:     false,
            useDate:      null,
        }
    }

    componentDidUpdate ( prevProps ) {
        const { date, turnover, loading } = this.props,
              { turnoverDiff } = this.state,
              oldDate = prevProps.date
                  ? moment( prevProps.date ).startOf( 'week' ).format( config.format.dayAPI )
                  : null,
              newDate = date
                  ? moment( date ).startOf( 'week' ).format( config.format.dayAPI )
                  : null

        if ( newDate && newDate !== oldDate ) {
            this.setState({ useDate: newDate })
            this.load( newDate )
        }

        if ( Object.keys( turnoverDiff ).length == 0 && turnover ) {
            const newObj = {}
            turnover.forEach(( item, i ) => {
                newObj[ `day-${i}-row` ] = {
                    date:         moment( item.workStart ).format( config.format.dayAPI ),
                    diff:         item.turnoverRgm === 0 ? 0 : item.deltaRgm,
                    mainTurnover: item.turnover,
                    rgmTurnover:  item.turnoverRgm || item.turnover,
                }
            })
            this.setState({ turnoverDiff: newObj })
        }
        if ( !loading && prevProps.loading ) {
            this.props.form.resetFields()
            this.setState({ turnoverDiff: {} })
        }
    }

    load = ( date ) => {
        const body = { dateStart: date }
        date && this.props.getTurnover( body )
    }

    fields = () => {
        const { date } = this.props,
              fields = [
                  {
                      field: 'date',
                      type:  'hidden',
                      value: moment( date ).startOf( 'week' ),
                  },
              ]

        let day = ''

        if ( date ) {
            for ( let i = 0; i < 7; i++ ) {
                day = moment( date ).add( i, 'days' )

                fields.push({
                    field: `day-${i}-row`,
                    label: day.format( 'D MMMM, ' ) + day.format( 'ddd' ).toUpperCase(),
                    type:  'number',
                    addOn: '₽',
                })
            }
        }

        fields.push({
            field: 'day-total',
            label: 'Общий',
            type:  'number',
            addOn: '₽',
        })

        return fields
    }

    getValue = ( key ) => {
        const { turnover } = this.props,
              { turnoverDiff } = this.state

        if ( !turnover ) { return null }

        if ( key === 'day-total' ) {
            let total = 0,
                totalDeltaRgm = 0,
                totalRgm = 0

            for ( let key in turnoverDiff ) {
                total += turnoverDiff[ key ].mainTurnover
                totalDeltaRgm += turnoverDiff[ key ].diff
                totalRgm += turnoverDiff[ key ].rgmTurnover
            }

            return {
                field:       'day-total',
                turnover:    total,
                turnoverRgm: totalRgm,
                deltaRgm:    totalDeltaRgm,
            }
        }

        const found = turnover.find(
            ( d ) =>
                moment( d.workStart ).isoWeekday() ===
        parseInt( key.replace( 'day-', '' )) + 1
        )

        if ( !found ) { return null }

        return found
    }

    changeTurnover = ( field, value ) => {
        const { turnoverDiff } = this.state

        this.setState({
            turnoverDiff: {
                ...turnoverDiff,
                [ field ]: {
                    ...turnoverDiff[ field ],
                    diff:        value - turnoverDiff[ field ].mainTurnover,
                    rgmTurnover: value,
                },
            },
        })
    }

    field = ( cfg ) => {
        const { form } = this.props,
              { turnoverDiff } = this.state,
              item = this.getValue( cfg.field )

        if ( cfg.field === 'date' ) { return this.headerField( cfg.field ) }

        if ( !item.turnover && item.turnover !== 0 ) { return null }

        const delta =
      item.field === 'day-total'
          ? item.deltaRgm
          : turnoverDiff[ cfg.field ]?.diff

        return (
            <div key={cfg.field} className="item-turnover">
                <div className="date-turnover">{cfg.label}</div>
                <div className="main-turnover">
                    <FormField
                        {...cfg}
                        field={cfg.field + 'disabled'}
                        label={''}
                        value={turnoverDiff[ cfg.field ]?.mainTurnover || item.turnover}
                        form={form}
                        disabled
                    />
                </div>
                <div className="forecast-turnover">
                    <FormField
                        {...cfg}
                        rules={[]}
                        label={''}
                        value={
                            item.turnoverRgm !== 0 || cfg.field === 'day-total'
                                ? item.turnoverRgm
                                : item.turnover
                        }
                        form={form}
                        disabled={
                            ( cfg.field === 'day-total' ? true : false ) || item.turnover === 0
                        }
                        onChange={this.changeTurnover}
                    />
                </div>
                <div className="difference-turnover">
                    {format.strings.thousand( delta > 0 ? '+' + delta : delta )}
                </div>
            </div>
        )
    }

    headerField = ( key ) => {
        return (
            <div key={key} className="item-turnover">
                <div className="date-turnover"></div>
                <div className="main-turnover">
                    <p>План значение,</p>
                    <p>Основное ТО</p>
                </div>
                <div className="forecast-turnover">
                    <p>План значение,</p>
                    <p>Прогноз директора</p>
                </div>
                <div className="difference-turnover">Разница</div>
            </div>
        )
    }

    preSubmit = () => {
        const { turnoverDiff } = this.state

        this.props.form.validateFieldsAndScroll(( err ) => {
            if ( !err ) {
                let newArr = []
                for ( let key in turnoverDiff ) {
                    if (
                        turnoverDiff[ key ].rgmTurnover < 24 &&
            turnoverDiff[ key ].mainTurnover === 0
                    ) { continue }
                    newArr.push({
                        date: turnoverDiff[ key ].date,
                        turnover:
              turnoverDiff[ key ].rgmTurnover || turnoverDiff[ key ].mainTurnover,
                    })
                }
                if ( newArr.length === 0 ) {
                    this.props.addServerError({
                        code: 404006,
                        text: 'Невозможно сформировать расписание без планового ТО',
                    })
                    this.preCancel()
                } else {
                    this.props.onSubmit( newArr )
                }
            }
        })
    }

    preCancel = () => {
        this.props.onCancel()
        this.props.form.resetFields()
        this.setState({ turnoverDiff: {} })
    }

    render () {
        const { visible, turnover, loading, error } = this.props,
              layout = {
                  ...config.ui.wideForm,
                  labelCol:   { span: 8, },
                  wrapperCol: { span: 16, },
              }

        return (
            <Modal
                title="Товарооборот на неделю"
                className="kfc-popup wide kfc-schedule-turnover"
                centered={true}
                open={visible}
                onOk={this.preSubmit}
                onCancel={this.preCancel}
                footer={[
                    <div className="footer" key="buttons">
                        {turnover && (
                            <div className="footer-buttons">
                                {error && !loading && (
                                    <p>Превышено время ожидания. Попробуйте снова.</p>
                                )}
                                <div className="buttons">
                                    {loading && (
                                        <div className="buttons-error">
                                            <Spinner />
                                        </div>
                                    )}
                                    <Button
                                        key="submit"
                                        type="primary"
                                        disabled={loading}
                                        onClick={this.preSubmit}
                                    >
                    Сохранить и продолжить
                                    </Button>
                                    <Button key="back" onClick={this.preCancel}>
                    Отмена
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>,
                ]}
            >
                <div className="info-text">
                    <p>расписание будет составлено с учётом прогноза директора,</p>
                    <p>
            но целевой план заданный ТУ останется во всех показателях и отчётах.
                    </p>
                </div>
                {turnover ? (
                    <Form
                        {...layout}
                    >
                        <div className="table-turnover">
                            {this.fields().map( this.field )}
                        </div>
                    </Form>
                ) : error ? (
                    <div className="turnover-error">
                        <p>Превышено время ожидания. Попробуйте снова.</p>
                        <Button onClick={() => this.load( this.state.useDate )}>
              Обновить
                        </Button>
                    </div>
                ) : (
                    <Spinner />
                )}
            </Modal>
        )
    }
}

export default Form.create({ name: 'ScheduleTurnover' })( ScheduleTurnover )
