/* VENDOR */
import React          from 'react'
import { connect }    from 'react-redux'
import PropTypes      from 'prop-types'
import { Card, Tabs } from 'antd'

/* APPLICATION */
import { Report, ReportTable } from 'components'

class TabbedReport extends Report {
    static propTypes = {
        tabs:       PropTypes.array,
        data:       PropTypes.array,
        name:       PropTypes.array,
        columns:    PropTypes.array.isRequired,
        nested:     PropTypes.oneOfType([ PropTypes.array, PropTypes.object ]),
        defaultTab: PropTypes.string,

        rowKey: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.string,
            PropTypes.func,
        ]),
        searchKey:   PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
        placeholder: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
        emptyText:   PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),

        filter:     PropTypes.oneOfType([ PropTypes.array, PropTypes.bool ]),
        hideHeader: PropTypes.oneOfType([ PropTypes.array, PropTypes.bool ]),
        topHeader:  PropTypes.oneOfType([ PropTypes.array, PropTypes.bool ]),
        loading:    PropTypes.oneOfType([ PropTypes.array, PropTypes.bool ]),

        prepare:      PropTypes.oneOfType([ PropTypes.array, PropTypes.func ]),
        onChange:     PropTypes.func,
        onMore:       PropTypes.array,
        onCellChange: PropTypes.array,
        onCellClick:  PropTypes.array,
        onRowRemove:  PropTypes.array,
        onSearch:     PropTypes.oneOfType([ PropTypes.array, PropTypes.func ]),
        cellActions:  PropTypes.oneOfType([ PropTypes.array, PropTypes.object ]),
    }

    static defaultProps = { rowKey: 'label', }

    constructor ( props ) {
        super( props )

        this.state = { current: 0, }

        this.tableRefs = []
    }

    changeTab = ( key ) => {
        this.setState({ current: key })
        this.props.onChange && this.props.onChange( key )
    }

    defProp = ( type, name ) => {
        switch ( type ) {
            case 'boolean':
                return false
            case 'function':
                return name === 'prepare' ? null : () => {}
            case 'string':
            default:
                return null
        }
    }

    checkType = ( key, type ) => {
        if ( Array.isArray( type )) {
            return type.indexOf( typeof this.props[ key ]) > -1
        }

        if ( type === 'array' ) {
            return Array.isArray( this.props[ key ])
        }

        return typeof this.props[ key ] === type && !Array.isArray( this.props[ key ])
    }

    getType = ( key ) => {
        const types = {
            rowKey:      [ 'array', 'string', 'function' ],
            searchKey:   'string',
            placeholder: 'string',
            emptyText:   'string',

            filter:     'boolean',
            hideHeader: 'boolean',
            topHeader:  'boolean',
            loading:    'boolean',

            onMore:       'function',
            prepare:      'function',
            onCellChange: 'function',
            onRowRemove:  'function',

            nested: 'object',
        }

        return types[ key ] ? types[ key ] : 'string'
    }

    getProp = ( key, index ) => {
        const type = this.getType( key )

        if ( !this.props[ key ]) { return this.defProp( type, key ) }

        return this.checkType( key, type ) ? this.props[ key ] : this.props[ key ][ index ]
    }

    tab = ( title, index ) => (
        {
            key: this.props.name[ index ] || index,
            label: title,
            children: (<ReportTable
                data={this.props.data[ index ]}
                columns={this.props.columns[ index ]}
                unit={this.props.request.unitOfMeasure}
                rowKey={this.getProp( 'rowKey', index )}
                searchKey={this.getProp( 'searchKey', index )}
                placeholder={this.getProp( 'placeholder', index )}
                emptyText={this.getProp( 'emptyText', index )}
                filter={this.getProp( 'filter', index )}
                hideHeader={this.getProp( 'hideHeader', index )}
                topHeader={this.getProp( 'topHeader', index )}
                loading={this.getProp( 'loading', index )}
                prepare={this.getProp( 'prepare', index )}
                onMore={this.getProp( 'onMore', index )}
                onChange={this.getProp( 'onCellChange', index )}
                onCellClick={this.getProp( 'onCellClick', index )}
                onRemove={this.getProp( 'onRowRemove', index )}
                onSearch={this.getProp( 'onSearch', index )}
                cellActions={this.getProp( 'cellActions', index )}
                nested={this.getProp( 'nested', index )}
            >
                {this.props.children && this.props.children[ index ]}
            </ReportTable>)
        }
    )

    render () {
        const { tabs, defaultTab } = this.props

        return (
            <Card bordered={false} className="report-table">
                <Tabs
                    defaultActiveKey={defaultTab}
                    onChange={this.changeTab}
                    animated={false}
                    className='extended-tabs'
                    items={tabs.map( this.tab )}
                >
                </Tabs>
            </Card>
        )
    }
}

const mapStateToProps = ( state ) => ({ request: state.request, })

export default connect( mapStateToProps )( TabbedReport )
