/* VENDOR */
import React, { Component }     from 'react'
import { connect }              from 'react-redux'
import { Layout, Card, Button } from 'antd'
import Icon                     from '@ant-design/icons'
import ReactExport              from 'react-data-export'
import moment                   from 'moment'

/* APPLICATION */
import {
    AppHeader,
    InnerContent,
    BackTitle,
    InnerControls,
    PerformanceChart,
    Report,
    Icons,
    ReportExcelTooltip,
} from 'components'

import { format } from 'tools'
import config     from 'config'

import { allActions, mapStateToProps } from './connector'
import './performance.scss'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet

const { Content, Header } = Layout

class Performance extends Component {
    componentDidMount () {
        const { fetchWorkHours, request, report, workHours } = this.props

        !workHours && fetchWorkHours( request )
        !report && this.load( this.props )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { workHours, request, fetchWorkHours } = this.props
        let workHoursLoad = false

        if ( nextProps.request.restaurantId !== request.restaurantId && nextProps.workHours === workHours ) {
            fetchWorkHours( nextProps.request )
            workHoursLoad = true
        }

        if ( nextProps.workHours !== workHours ) {
            this.load( nextProps )
        }

        if ( format.check.request( request, nextProps.request ) && !workHoursLoad ) {
            this.load( nextProps )
        }
    }

    prepare = ( record, index, columns ) =>
        format.goodBad.iterate.find(
            record,
            [ 'factSpmh', 'factTcph', 'factTurnover', 'factTransactions' ],
            columns
        )

    load = ( rprops ) => {
        const props = rprops || this.props,
              { request } = props

        const performanceRequest = format.copy.object( request )
        performanceRequest.factsNumber = performanceRequest.restaurantId
        delete performanceRequest.restaurantId
        delete performanceRequest.zoneId

        if ( !format.check.enough( props )) { return }

        props.flushPerformance()

        props.fetchPerformanceReport( performanceRequest )
        props.fetchPerformancePlanAndFact( performanceRequest )
    }

    set = ( data ) => this.props.setRequest( data )

    prepareExcel = () => {
        const { report, request } = this.props

        const getFormattedValue = ( value, digits = null ) =>
            value !== null ?
                `${digits !== null ? ( +value ).toFixed( digits ) : value}`
                :
                ''

        if ( !report ) { return [] }

        const isTimeText = request?.currentPredef === 'shift' || request?.currentPredef === 'day'

        return [ {
            columns: [
                { title: isTimeText ? 'Время' : 'Дата' },
                { title: 'SPMH План' },
                { title: 'SPMH Факт' },
                { title: 'TCPH План' },
                { title: 'TCPH Факт' },
                { title: 'Items Per Hour' },
                { title: 'Количество блюд' },
                { title: 'ТО факт' },
                { title: 'Транзакций факт' },
                { title: 'Отработано часов' },
                { title: 'Запланировано часов' },
            ],
            data: report.map(( item => ([
                { value: item.label },
                { value: getFormattedValue( item.planSpmh, 0 ) },
                { value: getFormattedValue( item.factSpmh, 0 ) },
                { value: getFormattedValue( item.planTcph, 2 ) },
                { value: getFormattedValue( item.factTcph, 2 ) },
                { value: getFormattedValue( item.factItemsPerHour, 0 ) },
                { value: getFormattedValue( item.allProductsInChecks, 0 ) },
                { value: getFormattedValue( item.factTurnover, 0 ) },
                { value: getFormattedValue( item.factTransactions, 0 ) },
                { value: getFormattedValue( item.factProductiveUnproductiveHours, 0 ) },
                { value: getFormattedValue( item.planProductiveUnproductiveHours, 0 ) },
            ])
            ))
        } ]
    }

    showExcelTooltip = () => {
        const { report } = this.props
        const fieldsNull = [ 'factSpmh', 'planSpmh', 'factTcph', 'planTcph', 'factTurnover', 'factItemsPerHour', 'allProductsInChecks' ]
        const fieldsZero = [ 'factProductiveUnproductiveHours', 'planProductiveUnproductiveHours', 'factTransactions' ]

        if ( !report ) { return true }

        const allContainsNull = report?.every( item => {
            return Object.entries( item ).filter(
                ([ key ]) => fieldsNull.find( k => k === key )
            ).every(([ _, value ]) => value === null )
        })

        const allContainsZero = report?.every( item => {
            return Object.entries( item ).filter(
                ([ key ]) => fieldsZero.find( k => k === key )
            ).every(([ _, value ]) => value === 0 )
        })

        return allContainsNull && allContainsZero
    }

    render () {
        const { report, plan, fact, request, workHours } = this.props

        const disabledExcel = this.showExcelTooltip()

        return (
            <section className="kfc-report kfc-performance">
                <Layout>
                    <Header>
                        <AppHeader bothTimes timeData={parseInt( request.restaurantId )} />
                    </Header>
                    <Content>
                        <InnerControls
                            actions={format.extract.actions( this.props )}
                            request={request}
                            workHours={workHours}
                            update={this.load}
                            onChange={this.set}
                        />

                        <InnerContent>
                            <BackTitle
                                showDownload
                                text="Производительность"
                                download={
                                    disabledExcel ?
                                            <ReportExcelTooltip />
                                        :
                                            <ExcelFile
                                                filename={`Производительность_${moment().format( 'YYYY-MM-DDTHH:mm:ss' )}`}
                                                element={
                                                    <Button
                                                        type='link'
                                                        className='button-download'
                                                    >
                                                        <Icon component={Icons.Download.active} />
                                                    </Button>
                                                }
                                            >
                                                <ExcelSheet dataSet={this.prepareExcel()} name="Производительность"/>
                                            </ExcelFile>
                                }
                            />

                            <Card bordered={false}>
                                <PerformanceChart plan={plan} fact={fact} />
                            </Card>

                            <Report
                                unit="currency"
                                data={format.generate.noPager( report )}
                                onMore={this.more}
                                columns={config.tables.performance( request )}
                                prepare={this.prepare}
                            />
                        </InnerContent>
                    </Content>
                </Layout>
            </section>
        )
    }
}

export default connect( mapStateToProps, allActions )( Performance )
