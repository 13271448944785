/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import moment               from 'moment'
import ruRU from 'antd/es/date-picker/locale/ru_RU'

import { DatePicker, List } from 'antd'
import Icon, { CalendarOutlined } from '@ant-design/icons'

/* APPLICATION */
import { Icons }  from 'components'
import { format } from 'tools'
import config     from 'config'

import './reports-controls.scss'

const { WeekPicker, RangePicker, MonthPicker } = DatePicker,
      list = [
          {
              title:    'Дневное расписание',
              endpoint: '',
              key:      'daily-schedule',
          },
          {
              title:    'Расписание',
              endpoint: '',
              key:      'schedule',
          },
          {
              title:    'Компактное расписание',
              endpoint: '',
              key:      'short',
          },
          {
              title:    'Плановое рабочее время',
              endpoint: '',
              key:      'worktime',
          },
          {
              title:    'Фактическое рабочее время',
              endpoint: '',
              key:      'factworktime',
          },
          {
              title:    'Отчет по отработанному времени',
              endpoint: '',
              key:      'workedtime',
          },
          {
              title:    'Готовые выйти на замену',
              endpoint: '',
              key:      'ready',
          },
          {
              title:    'Статистика',
              endpoint: '',
              key:      'statistics',
          },
          {
              title:    'Выручка по станциям',
              endpoint: '',
              key:      'stations',
          },
      ]

class ReportsControls extends Component {
    static propTypes = {
        current:  PropTypes.string,
        date:     PropTypes.oneOfType([ PropTypes.object, PropTypes.array ]),
        type:     PropTypes.oneOf([ 'week', 'day', 'range', 'month' ]),
        disabled: PropTypes.bool,

        onChange:     PropTypes.func,
        onSelect:     PropTypes.func,
        onPrint:      PropTypes.func,
        disabledDate: PropTypes.func
    }

    static defaultProps = {
        type:     'week',
        showDate: true
    }

    constructor ( props ) {
        super( props )
        this.state = { open: false, }
    }

    open = () => this.setState({ open: true })
    close = () => {}
    toggle = () => !this.props.disabled && this.setState({ open: !this.state.open })
    set = ( open ) => this.setState({ open })

    onChange = ( date ) => {
        if ( Array.isArray( date )) {
            this.close()
            this.props.onChange( date )
            return
        }

        let res = moment( date )

        this.props.type === 'week' && res.startOf( 'week' )

        this.close()
        this.props.onChange( res )
    }

    select = ( item ) => {
        return () => this.props.onSelect( item )
    }

    print = ( item ) => {
        return () => this.props.onPrint( item )
    }

    reportExcel = ( e ) => {
        const { disabledExportExcel } = this.props
        e.stopPropagation()
        if ( disabledExportExcel ) { return }
        if ( this.props.onReportExcel ) { this.props.onReportExcel() }
    }

    item = ( item ) => {
        const { download } = this.props
        let cls = []

        this.props.current === item.key && cls.push( 'current' )

        return (
            <List.Item
                key={item.key}
                className={cls.join( ' ' ).trim()}
                onClick={this.select( item )}
            >
                {item.title}
                <span className="item-controls">
                    <Icon component={Icons.Print.active} onClick={this.print( item )} />
                </span>
                {download}
            </List.Item>
        )
    }

    date = ( date ) => {
        switch ( this.props.type ) {
            case 'range':
                return format.strings.range( date )
            case 'day':
                return date.format( config.format.dayView )
            case 'month':
                return format.strings.capitalize( date.format( 'MMMM YYYY' ))
            case 'week':
            default:
                return format.strings.range([ date, moment( date ).endOf( 'week' ) ])
        }
    }

    getContainer = ( trigger ) => {
        const res = trigger.parentNode.parentNode

        res.style.position = 'relative'

        return res
    }

    picker = ( date ) => {
        const { type, disabledDate, disabled } = this.props
        let dates = date

        if ( type === 'range' && !Array.isArray( date )) {
            dates = [ date, date ]
        }

        switch ( type ) {
            case 'range':
                return (
                    <RangePicker
                        value={dates}
                        locale={ruRU}
                        open={this.state.open}
                        onChange={this.onChange}
                        onOpenChange={this.set}
                        getCalendarContainer={this.getContainer}
                        disabled={disabled}
                    />
                )
            case 'day':
                return (
                    <DatePicker
                        value={date}
                        locale={ruRU}
                        open={this.state.open}
                        onChange={this.onChange}
                        onOpenChange={this.set}
                        getCalendarContainer={this.getContainer}
                        disabled={disabled}
                    />
                )
            case 'month':
                return (
                    <MonthPicker
                        value={date}
                        locale={ruRU}
                        open={this.state.open}
                        onChange={this.onChange}
                        onBlur={this.close}
                        onOpenChange={this.set}
                        getCalendarContainer={this.getContainer}
                        disabledDate={disabledDate}
                        disabled={disabled}
                    />
                )
            case 'week':
            default:
                return (
                    <WeekPicker
                        value={date}
                        locale={ruRU}
                        open={this.state.open}
                        onChange={this.onChange}
                        onBlur={this.close}
                        onOpenChange={this.set}
                        getCalendarContainer={this.getContainer}
                        disabled={disabled}
                    />
                )
        }
    }

    render () {
        const
            { date, showDate } = this.props

        return (
            <div className="reports-controls">
                <div className="reports-controls-header">
                    <span className="dates-fake" onClick={this.toggle}>
                        <CalendarOutlined />
                        {showDate && this.date( date ) }
                    </span>

                    {this.props.onChange && this.picker( date )}
                </div>

                {this.props.onSelect && (
                    <div className="reports-controls-list">
                        <List size="small" dataSource={list} renderItem={this.item} />
                    </div>
                )}
            </div>
        )
    }
}

export default ReportsControls
