/* VENDOR */
import React, { Component, useRef, useEffect } from 'react'
import { connect }                             from 'react-redux'
import { Switch, Route, withRouter }           from 'react-router-dom'
import config                                  from 'config'

/* APPLICATION */
import { PrivateRoute, Spinner } from 'components'

import {
    Admin,
    AverageCheck,
    AuthError,
    Calendar,
    CheckUpload,
    Dashboard,
    Performance,
    Planning,
    PlanningDeep,
    Rating,
    RatingDeep,
    RatingReport,
    Reports,
    Schedule,
    ScheduleMobile,
    Settings,
    Staff,
    Tasks,
    TimeReport,
    Turnover,
    GuestVote,
} from 'pages'

import { userActions, restaurantActions } from 'services'
import { format }                         from 'tools'
import './styles/global.scss'

const mobilePage = window.location.href.indexOf( 'mobile' ) > -1,
      ReactComment = ( props ) => {
          const el = useRef()
          useEffect(() => {
              el.current.outerHTML = `<!-- ${props.children} -->`
          }, [ props.children ])
          return <div ref={el} />
      },
      mapStateToProps = ( state ) => ({
          user:    state.user,
          request: state.request,
      }),
      allActions = {
          ...userActions,
          ...restaurantActions,
      }

class App extends Component {
    fetch = () => {
        if ( !mobilePage ) {
            this.props.fetchAuthEndpoints().finally(() => {
                if ( format.generate.parseQuery( window.location.search ).code ) {
                    format.update.postAccessToken( this.props.user.endpoints )
                    return
                }
                // TODO: BSR-1876 сейчас сделал так, возможно можно лучше
                const countReloadError = sessionStorage.getItem( config.keys.countReloadError )

                if ( !countReloadError ) {
                    this.props.loadUser()
                    this.props.getAccessAreas()
                }
            })
        }
    }

    componentDidMount () {
        this.fetch()
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        if ( !mobilePage ) {
            if ( this.props.user.restaurant !== nextProps.user.restaurant ) {
                nextProps.loadRestaurants( nextProps.user.restaurant.grantedRestaurants )
            }
        }
    }

    strictAccess = ( page ) => mobilePage || this.props.user.areas.includes( page )
    anyAccess = ( page ) =>
        mobilePage ||
    this.props.user.areas.filter(( area ) => area.indexOf( page ) > -1 ).length > 0

    hasAccess = ( page ) =>
        page[ 0 ] === '*' ? this.anyAccess( page.substr( 1 )) : this.strictAccess( page )

    render () {
        const { user, version } = this.props
        const countReloadError = sessionStorage.getItem( config.keys.countReloadError )

        if ( !mobilePage ) {
            if ( window.location.search.indexOf( 'blocked=true' ) > -1 ) {
                const split = decodeURI( window.location.search.substr( 1 )).split( '&' ),
                      params = {}

                split.forEach(( pair ) => {
                    const s = pair.split( '=' ),
                          key = s[ 0 ],
                          value = s[ 1 ]

                    params[ key ] = value
                })

                return (
                    <div className="App">
                        <AuthError
                            showLogout
                            title={
                                <>
                  Добро пожаловать{params.user && `, ${params.user}`}!
                                    <br />
                                    {params.jobRole && `должность ${params.jobRole}`}
                                </>
                            }
                            text="Ваша учетная запись заблокирована"
                        />
                    </div>
                )
            }
            // TODO: BSR-1876 сейчас сделал так, возможно можно лучше
            if ( countReloadError ) {
                return (
                    <div className="App">
                        <AuthError
                            showLogout
                            text="Ваши права доступа не позволяют войти в систему"
                        />
                    </div>
                )
            }

            if (
                !user.restaurant ||
        !user.restaurant.grantedRestaurants ||
        !user.areas
            ) {
                return <Spinner />
            }

            if ( !user.rid ) {
                return (
                    <div className="App">
                        <AuthError
                            showLogout
                            title={
                                <>
                  Добро пожаловать, {user.info.fullName}!
                                    <br />
                  должность {user.info.jobRole}
                                </>
                            }
                            text="Не удалось войти в систему: ваш аккаунт не связан ни с одним рестораном"
                        />
                    </div>
                )
            }

            if ( user.areas.length < 1 ) {
                return (
                    <div className="App">
                        <AuthError
                            showLogout
                            title={
                                <>
                  Добро пожаловать, {user.info.fullName}!
                                    <br />
                  должность {user.info.jobRole}
                                </>
                            }
                            text="Ваши права доступа не позволяют войти в систему"
                        />
                    </div>
                )
            }
        }

        if (
            ( window.location.pathname === '/' || window.location.pathname === '' ) &&
      !this.hasAccess( 'dashboard' )
        ) {
            window.location.href = this.anyAccess( 'admin' )
                ? '/admin'
                : '/' + user.areas[ 0 ]
        }

        return (
            <div className="App">
                <ReactComment>{version}</ReactComment>
                <Switch>
                    {/* <Route exact path="/login" component={Login} /> */}
                    <PrivateRoute exact path="/check-upload" component={CheckUpload} />

                    {( this.hasAccess( '*admin' ) || this.hasAccess( 'banners' )) && (
                        <PrivateRoute exact path="/admin" component={Admin} />
                    )}

                    {this.hasAccess( 'feedback' ) && (
                        <PrivateRoute exact path="/guest" component={GuestVote} />
                    )}

                    {this.hasAccess( 'calendar' ) && (
                        <PrivateRoute exact path="/calendar" component={Calendar} />
                    )}
                    {this.hasAccess( 'tasks' ) && (
                        <PrivateRoute exact path="/tasks" component={Tasks} />
                    )}
                    {this.hasAccess( 'reports' ) && (
                        <PrivateRoute exact path="/reports" component={Reports} />
                    )}
                    {this.hasAccess( 'settings' ) && (
                        <PrivateRoute exact path="/settings" component={Settings} />
                    )}
                    {this.hasAccess( 'staff' ) && (
                        <PrivateRoute exact path="/staff" component={Staff} />
                    )}

                    {this.hasAccess( 'rating' ) && (
                        <PrivateRoute exact path="/rating" component={Rating} />
                    )}

                    {this.hasAccess( 'planning' ) && (
                        <PrivateRoute exact path="/planning" component={Planning} />
                    )}
                    {this.hasAccess( 'planning' ) && (
                        <PrivateRoute
                            exact
                            path="/planning/:deep"
                            component={PlanningDeep}
                        />
                    )}

                    {this.hasAccess( 'schedule' ) && (
                        <Route exact path="/mobile-schedule" component={ScheduleMobile} />
                    )}
                    {this.hasAccess( 'schedule' ) && (
                        <PrivateRoute exact path="/schedule" component={Schedule} />
                    )}

                    {this.hasAccess( 'dashboard' ) && [
                        <PrivateRoute
                            exact
                            key="performance"
                            path="/dashboard/performance"
                            component={Performance}
                        />,
                        <PrivateRoute
                            exact
                            key="turnover"
                            path="/dashboard/turnover"
                            component={Turnover}
                        />,
                        <PrivateRoute
                            exact
                            key="average"
                            path="/dashboard/average-check"
                            component={AverageCheck}
                        />,
                        <PrivateRoute
                            exact
                            key="rating"
                            path="/dashboard/rating"
                            component={RatingReport}
                        />,
                        <PrivateRoute
                            exact
                            key="deep"
                            path="/dashboard/rating/:deep"
                            component={RatingDeep}
                        />,
                        <PrivateRoute
                            exact
                            key="time"
                            path="/dashboard/time"
                            component={TimeReport}
                        />,
                        <PrivateRoute
                            exact
                            key="dash"
                            path="/dashboard"
                            component={Dashboard}
                        />,
                        <PrivateRoute exact key="index" path="/" component={Dashboard} />,
                    ]}
                </Switch>
            </div>
        )
    }
}

export default withRouter( connect( mapStateToProps, allActions )( App ))
