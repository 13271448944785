import * as twins from './twins'

export const find = ( id, list ) => list.find(( e ) => e.id === id )

export const set = ( employees, shift, id, date, res ) => {
    const tail = twins.get( res, date, shift, 1 ),
          orig = find( id, employees )

    shift.employeeId = id
    shift.employeeName = orig.name
    shift.rateDictionary = orig.rateDictionary

    if ( tail.found ) {
        tail.found.employeeId = id
        tail.found.employeeName = orig.name
        tail.found.rateDictionary = orig.rateDictionary
    }
}
