/* VENDOR */
import { update } from 'reduxigen'

/* APPLICATION */
import * as api   from './api'
import { format } from 'tools'

const asyncUpdate = format.update.asyncu,
      helper = format.helper.basic( 'settings' ),
      whHelper = format.helper.update( 'settings.workHours', 'dayOfWeek' ),
      posHelper = format.helper.update( 'settings.adminPositions', 'positionGuid' ),
      reHelper = ( event, state, val ) => {
          if ( event.status !== 200 ) {
              return state
          }

          const adminPositions = format.copy.array( state.settings.adminPositions ),
                split = event.config.url.split( '/' ),
                guid = split[ split.length - 2 ],
                index = format.find.index( adminPositions, 'positionGuid', guid )

          adminPositions[ index ].deleted = val

          return {
              ...state,
              settings: {
                  ...state.settings,
                  adminPositions,
              },
          }
      }

/* WORK HOURS */

export const flushWorkHours = update( 'settings.flushWH', ( data, state ) => ({
    ...state,
    settings: {
        ...state.settings,
        workHours: null,
    },
}))

export const fetchWorkHours = asyncUpdate(
    'settings.workHours',
    ( params ) => api.getWorkHours( params ),
    ( event, state ) => helper( 'workHours', event.data, state, event.status )
)

export const saveWorkHours = asyncUpdate(
    'workHours.set',
    ( day ) => api.setWorkHours( format.generate.dowRequest( day )),
    ( event, state ) =>
        whHelper( event, state, ( cfg ) => ( cfg.arr[ cfg.index ] = cfg.data ))
)

/* POSITIONS */

export const fetchPositions = asyncUpdate(
    'settings.positions',
    ( params ) => api.getPositions( params ),
    ( event, state ) => {
        return {
            ...state,
            settings: {
                ...state.settings,
                positions:      event.data?.positions,
                positionsError: event.status !== 200
            }
        }
    }
)

export const fetchAdminPositionsCategories = asyncUpdate(
    'settings.admin.positions.categories',
    () => api.getAdminPositionsCategories(),
    ( event, state ) => helper( 'poscats', event.data, state )
)

export const fetchAdminPositions = asyncUpdate(
    'settings.admin.positions',
    ( params ) => api.getAdminPositions( params ),
    ( event, state ) => helper( 'adminPositions', event.data?.positions, state )
)

export const savePosition = asyncUpdate(
    'positions.set',
    ( position ) => api.setPosition( position ),
    ( event, state ) => {
        const { status, data, arr, prod } = format.extract.update(
            event,
            state.settings.positions,
            'positionGuid'
        )

        prod.chart = status === 200 ? data.chart : data.original.chart

        return helper( 'positions', arr, state )
    }
)

export const updateAdminPosition = asyncUpdate(
    'positions.admin.update',
    ( data ) => api.updatePosition( data ),
    ( event, state ) =>
        posHelper( event, state, ( cfg ) => ( cfg.arr[ cfg.index ] = event.data ))
)

export const moveAdminPosition = asyncUpdate(
    'positions.admin.update',
    ( data ) => api.updatePosition( data ),
    ( event, state ) => {
        /* const n = event.data,
              adminPositions = format.copy.array( state.settings.adminPositions ),
              npos = [
                  ...adminPositions.slice( 0, n.order - 1 ),
                  n,
                  ...adminPositions.slice( n.order - 1 ),
              ]

        npos.forEach(( p, i ) => ( p.order = i + 1 )) */

        return state
    }
)

export const createAdminPosition = asyncUpdate(
    'positions.admin.create',
    ( data ) => api.createPosition( data ),
    ( event, state ) => posHelper( event, state, ( cfg ) => cfg.arr.push( event.data ))
)

export const removeAdminPosition = asyncUpdate(
    'positions.admin.remove',
    ( data ) => api.removePosition( data ),
    ( event, state ) => reHelper( event, state, true )
)

export const restoreAdminPosition = asyncUpdate(
    'positions.admin.restore',
    ( data ) => api.restorePosition( data ),
    ( event, state ) => reHelper( event, state, false )
)

/* PPR */

export const fetchSettingsPprRestaurant = asyncUpdate(
    'settings.ppr',
    ( data ) => api.getPprRestaurant( data ),
    ( event, state ) => helper( 'ppr', event.data, state )
)

export const createPprRestaurant = asyncUpdate(
    'settings.ppr.create',
    ( data ) => api.createPprRestaurant( data ),
    ( event, state ) => helper( 'ppr', event.data, state )
)

export const updatePprRestaurant = asyncUpdate(
    'settings.ppr.update',
    ( data ) => api.updatePprRestaurant( data ),
    ( event, state ) => helper( 'ppr', event.data, state )
)

/* SHOW DATA */

export const searchRestaurantOptions = asyncUpdate(
    'settings.showData.get',
    ( data ) => api.searchRestaurantOptions( data ),
    ( event, state ) => {
        return ({
            ...state,
            settings: {
                ...state.settings,
                showData:      event.data?.[ 0 ],
                showDataError: event.status !== 200
            }
        })
    }
)

export const postRestaurantOptions = asyncUpdate(
    'settings.showData.create',
    ( data ) => api.postRestaurantOptions( data ),
    ( event, state ) => helper( 'showData', event.data, state )
)

export const updateRestaurantOptions = asyncUpdate(
    'settings.showData.update',
    ( data ) => api.updateRestaurantOptions( data ),
    ( event, state ) => helper( 'showData', event.data, state )
)
