/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Link }             from 'react-router-dom'

/* APPLICATION */
import config from 'config'
import './logo.scss'

class Logo extends Component {
    static propTypes = { full: PropTypes.bool, }

    render () {
        let cls = 'logo-container'

        if ( this.props.full ) {
            cls += ' full'
        }

        return (
            <Link to="/" className={cls}>
                <React.Fragment>
                    {this.props.full ? (
                        <img
                            src={config.ui.imagesURL + 'logo-full.png'}
                            alt={config.title}
                            width="350px"
                        />
                    ) : (
                        <img src={config.ui.imagesURL + 'logo.png'} alt={config.title} />
                    )}
                </React.Fragment>
            </Link>
        )
    }
}

export default Logo
