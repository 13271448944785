import React                from 'react'
import { Icon, createIcon } from './Icon'

const svg = () => {
    return (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_769_6109)">
                <path
                    d="M6.99986 0.875C3.13346 0.875 -0.00013837 3.4207 -0.00013837 6.5625C-0.00013837 7.86406 0.544002 9.05625 1.44635 10.016C1.03892 11.0934 0.191268 12.0066 0.177596 12.0176C-0.00287274 12.209 -0.0520915 12.4879 0.0518148 12.7285C0.155721 12.9691 0.393612 13.125 0.656112 13.125C2.33775 13.125 3.66392 12.4223 4.45963 11.859C5.24986 12.1078 6.10299 12.25 6.99986 12.25C10.8663 12.25 13.9999 9.7043 13.9999 6.5625C13.9999 3.4207 10.8663 0.875 6.99986 0.875ZM6.99986 10.9375C6.26978 10.9375 5.54791 10.8254 4.85611 10.6066L4.23541 10.4098L3.70221 10.7871C3.31119 11.0633 2.77525 11.3723 2.12994 11.5801C2.32955 11.2492 2.52369 10.8773 2.67408 10.4809L2.96392 9.7125L2.40064 9.11641C1.90572 8.58867 1.31236 7.71641 1.31236 6.5625C1.31236 4.15078 3.86353 2.1875 6.99986 2.1875C10.1362 2.1875 12.6874 4.15078 12.6874 6.5625C12.6874 8.97422 10.1362 10.9375 6.99986 10.9375Z"
                    fill="#1F86FF"
                />
                <circle
                    cx="3"
                    cy="3"
                    r="2.65"
                    fill="#E02020"
                    stroke="white"
                    strokeWidth="0.7"
                />
            </g>
            <defs>
                <clipPath id="clip0_769_6109">
                    <rect width="14" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

class CommentPpr extends Icon {
    render () {
        return svg( this.primary, this.secondary )
    }
}

export default createIcon( CommentPpr )
