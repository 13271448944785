/* VENDOR */
import React from 'react'

/* APPLICATION */
import { Icon, IconActive, createIcon } from './Icon'

const svg = ( primary ) => (
    <svg width="8" height="5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.29 3.29L4.7.7a1 1 0 0 0-1.41 0L.7 3.29A1 1 0 0 0 1.41 5h5.18a1 1 0 0 0 .7-1.71z"
            fill={primary}
        />
    </svg>
)

class Arrow extends Icon {
    render () {
        return svg( this.primary, this.secondary )
    }
}

class ArrowActive extends IconActive {
    render () {
        return svg( this.primary, this.secondary )
    }
}

export default createIcon( Arrow, ArrowActive )
