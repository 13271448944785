/* VENDOR */
import moment from 'moment'

/* APPLICATION */
import { format } from 'tools'

import Template from './Template.js'
import './schedule-template.scss'

const rows = ( data ) => {
    const res = []

    data.employees.forEach(( employee ) => {
        employee.days.forEach(( day, dindex ) => {
            const date = moment( day.date )

            if ( !day.date ) { return }

            day.shifts.forEach(( shift, index ) => {
                res.push({
                    key:
              employee.employeeName +
              day.date +
              shift.position +
              shift.timeStart,
                    employee: index > 0 || dindex > 0 ? '' : employee.employeeName,
                    day:      index > 0 ? '' : format.strings.dowDateRS( date ),
                    time:     format.strings.interval( shift.timeStart, shift.timeEnd ),
                    position: shift.position,
                    duration: format.strings.float( shift.duration ),
                    props:    { className: dindex > 0 || index > 0 ? '' : 'first-line', },
                })
            })
        })
    })

    return res
},
      heads = () => [
          {
              key:      'header',
              employee: 'Сотрудник',
              day:      'Дни',
              time:     'Время',
              position: 'Позиция',
              duration: 'Длительность',
              _sign:    'Ознакомлен',
          },
      ],
      columns = () =>
          format.generate.rowReader([
              'employee',
              'day',
              'time',
              'position',
              'duration',
              '_sign',
          ])

class ScheduleTemplate extends Template {
    constructor ( props ) {
        super( props )

        this.state = {
            data:      rows( props.data ),
            heads:     heads( props.data ),
            columns:   columns( props.data ),
            className: 'schedule-table',
        }
    }

    data = ( data ) => rows( data )
    columns = ( data ) => columns( data )
}

export default ScheduleTemplate
