import moment     from 'moment'
import { format } from 'tools'

export const report = ({
    restaurant,
    data,
    start,
    end
}) => {
    const font = { name: 'Arial', sz: '10', bold: false }
    const excelHeader = {
        columns: [
            {
                title: `Отчет по отработанному времени ${restaurant} ${moment( start ).format( 'DD MMMM YYYY' )} - ${moment( end ).format( 'DD MMMM YYYY' )}`,
                style: { font: { sz: '24', bold: true, name: 'Arial' } }
            }
        ],
        data: [ [ { value: '' } ] ]
    }

    const workedtimeSummary = { rows: [] }

    const upperCols = [
        { title: 'Сотрудник', style: { font, border: { bottom: { style: 'thick' } } }, width: { wpx: 150 } },
        { title: 'Дни', style: { font, border: { bottom: { style: 'thick' } } } },
        { title: 'Фактически отработанное время', style: { font, border: { bottom: { style: 'thick' } } }, width: { wpx: 250 } },
        { title: 'Итого часы', style: { font, border: { bottom: { style: 'thick' } } }, width: { wpx: 150 } },
        { title: 'Дневные', style: { font, border: { bottom: { style: 'thick' } } }, width: { wpx: 150 } },
        { title: 'Ночные', style: { font, border: { bottom: { style: 'thick' } } }, width: { wpx: 150 } },
    ]

    data?.employees?.map(( employee ) => {
        employee?.days?.filter(( day ) => day?.factWorkHours?.length ).map(( day, dindex ) => {
            return day.factWorkHours.map(( factWorkHour, findex ) => {
                workedtimeSummary.rows.push(
                    [
                        { value: dindex === 0 && findex === 0 ? employee.employeeName || '' : '', style: { font } },
                        { value: findex > 0 ? '' : format.strings.dowDateRS( day.date ), style: { font } },
                        { value: format.strings.interval( factWorkHour.timeStart, factWorkHour.timeEnd ), style: { font } },
                        { value: factWorkHour.factSummaryDuration || '', style: { font } },
                        { value: factWorkHour.factDayDuration || '', style: { font } },
                        { value: factWorkHour.factNightDuration || '', style: { font } }
                    ]
                )
            })
        })
    })

    return [
        excelHeader,
        {
            ySteps:  -1,
            columns: upperCols,
            data:    workedtimeSummary.rows
        },
    ]
}
