/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import moment               from 'moment'

import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'

import { Button, Modal } from 'antd'

/* APPLICATION */
import { FormField, Link } from 'components'
import { format }          from 'tools'
import config              from 'config'

import './generate-schedule.scss'

const tmpFormat = config.format.date + ':ss'

class GenerateSchedule extends Component {
    static propTypes = {
        list: PropTypes.array,

        onSubmit: PropTypes.func,
        onShow:   PropTypes.func,
        onCancel: PropTypes.func,
        editPlan: PropTypes.func,

        visible: PropTypes.bool,

        jobRole: PropTypes.string,
    }

    constructor ( props ) {
        super( props )
        this.state = {
            generated: false,
            date:      moment().startOf( 'isoWeek' ).add( 1, 'week' ),
            delayedVisible: props.visible,
        }
    }

    componentDidMount () {
        this.setState({ generated: this.isGenerated( this.state.date, this.props ), })
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        if ( nextProps.list !== this.props.list ) {
            this.setState({ generated: this.isGenerated( this.state.date, nextProps ), })
        }
        if ( nextProps.visible !== this.props.visible ) {
            setTimeout(() => {
                this.setState({ delayedVisible: nextProps.visible, })
            }, 0)
        }
    }

    days = () =>
        config.defs.days.map(( val ) => ({
            value: val,
            label: moment().day( val ).format( 'ddd' ),
        }))

    fields = () => [
        {
            field:        'week',
            label:        '',
            type:         'week',
            open:         this.props.visible,
            container:    '.kfc-generate-schedule .ant-modal-body',
            popupClassName: 'date-picker-week-dropdown',
            disabledDate: ( current ) => current && current < moment().endOf( 'week' ),
            rules:        [ config.rules.required ],
            className:    'week-field',
            onChange:     this.setWeek,
            value:        moment().add( 1, 'week' ),
        },
    ]

    field = ( cfg ) => {
        if ( cfg.type === 'row' ) {
            return this.row( cfg )
        }

        return (
            <FormField
                {...cfg}
                value={cfg.value}
                key={cfg.field}
                form={this.props.form}
                data={{}}
            />
        )
    }

    preSubmit = () => {
        this.props.form.validateFieldsAndScroll(( err, values ) => {
            if ( format.check.form( err, values )) {
                this.props.onSubmit( values )
                this.props.form.resetFields()
            } else {
                console.log( err, values )
            }
        })
    }

    setWeek = ( type, date ) =>
        this.setState({
            generated: this.isGenerated( date.startOf( 'week' ), this.props ),
            date:      date.startOf( 'week' ),
        })

    isGenerated = ( date, props ) => {
        const { list } = props

        if ( !list ) {
            return false
        }

        return list.indexOf( date.startOf( 'week' ).format( tmpFormat )) > -1
    }

    onShow = () => this.props.onShow( this.state.date.format( tmpFormat ))
    onRebuild = () => this.props.onRebuild( this.state.date )

    onEditPlan = () => this.props.editPlan( this.state.date )

    render () {
        return (
            <Modal
                title="Расписание"
                className="kfc-popup kfc-generate-schedule"
                centered={true}
                open={this.state.delayedVisible}
                cancelButtonProps={{ style: { display: 'none' } }}
                onCancel={this.props.onCancel}
                footer={
                    this.state.generated ? (
                        <div className="extra-footer">
                            <p>Расписание на эту неделю уже сформировано</p>
                            <div className="extra-actions">
                                <div>
                                    <Link onClick={this.onShow}>Показать</Link>
                                    <Link onClick={this.preSubmit}>Создать заново</Link>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="buttons-footer">
                            <Button
                                type="primary"
                                className="wide-btn"
                                onClick={this.preSubmit}
                            >
                Сформировать
                            </Button>
                            {/*{this.props.jobRole === 'RGM' && (*/}
                            {/*    <Button type="primary" onClick={this.onEditPlan}>*/}
                            {/*        Сформировать по прогнозу Директора*/}
                            {/*    </Button>*/}
                            {/*)}*/}
                        </div>
                    )
                }
            >
                <Form
                    {...config.ui.wideForm}
                >
                    {this.fields().map( this.field )}
                </Form>
            </Modal>
        )
    }
}

export default Form.create({ name: 'GenerateSchedule' })( GenerateSchedule )
