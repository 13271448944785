/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { RadialChart }      from 'react-vis'

/* APPLICATION */
import { format } from 'tools'
import config     from 'config'
import './siv-info.scss'

class SIVBlock extends Component {
    static propTypes = {
        type:  PropTypes.string,
        chart: PropTypes.bool,
        tasks: PropTypes.object,
        aref:  PropTypes.object,

        ratingVisitStatistics: PropTypes.object,

        overall: PropTypes.number,
        ready:   PropTypes.number,
        delta:   PropTypes.number,

        onSelect: PropTypes.func,
    }

    angle = ( val ) => {
        const def = {
            angle: Math.PI * 2,
            color: '#EBEBEB',
        }

        if ( !val ) { return [ def ] }

        let angle = ( val / 100 ) * ( Math.PI * 2 ),
            color
        val < 50 && ( color = '#A70000' )
        val >= 50 && val < 80 && ( color = '#F5A623' )
        val >= 80 && ( color = '#07A72A' )

        return [
            {
                angle,
                color,
            },
            {
                ...def,
                angle: def.angle - angle,
            },
        ]
    }

    render () {
        const {
            overall,
            type,
            ratingVisitStatistics,
            tasks,
            ready,
            delta,
            chart,
            aref,
        } = this.props,
              { onSelect } = this.props,
              deltaCls = [ 'tasks-delta' ],
              doneCls = [ 'all-done' ],
              deviations = ratingVisitStatistics
                  ? ratingVisitStatistics.deviationStatistics
                  : null;

        ( !tasks || tasks.count === 0 || ready === 100 ) &&
      ( !deviations || deviations.length < 1 ) &&
      doneCls.push( 'done' )

        if ( delta ) {
            delta < 0 ? deltaCls.push( 'bad' ) : deltaCls.push( 'good' )
        }

        return (
            <div className="siv-block-report" onClick={onSelect} ref={aref}>
                <section className="siv-chart">
                    {chart && (
                        <RadialChart
                            data={this.angle( overall )}
                            width={96}
                            height={96}
                            radius={48}
                            innerRadius={38}
                            colorType="literal"
                        />
                    )}
                    <div className="siv-overall">
                        {( overall || overall === 0 ) && <h3>{overall}%</h3>}
                        <h4>{type}</h4>
                    </div>
                </section>
                <footer className="siv-tasks-details">
                    {tasks && ( overall || overall === 0 ) ? (
                        <React.Fragment>
                            <p className="siv-tasks-count">
                                {deviations
                                    ? format.strings.clearCount( deviations.count, [
                                        'отклонение',
                                        'отклонения',
                                        'отклонений',
                                    ])
                                    : format.strings.clearCount( 0, [
                                        'отклонение',
                                        'отклонения',
                                        'отклонений',
                                    ])}
                            </p>
                            <p className="siv-tasks-count">
                                {format.strings.clearCount( tasks.count, [
                                    'задача',
                                    'задачи',
                                    'задач',
                                ])}
                            </p>
                            <span className={doneCls.join( ' ' )}></span>
                            <span className="tasks-percents">
                                {tasks.count > 0 ? ready + '%' : ''}
                            </span>
                            {tasks.count > 0 && !!delta && delta !== '0' && (
                                <span className={deltaCls.join( ' ' )}>
                                    {delta > 0 ? '+' + delta : delta}
                                </span>
                            )}
                        </React.Fragment>
                    ) : (
                        config.ui.nullSymbol
                    )}
                </footer>
            </div>
        )
    }
}

export default SIVBlock
