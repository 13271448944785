/* VENDOR */
import React from 'react'

/* APPLICATION */
import { Icon, IconActive, createIcon } from './Icon'

const svg = ( primary ) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
        <path
            fill={primary}
            d="M8.0925 12.2175c.2925.2925.765.2925 1.0575 0l2.6925-2.6925c.2925-.2925.2925-.765 0-1.0575L9.15 5.775c-.2925-.2925-.765-.2925-1.0575 0-.2925.2925-.2925.765 0 1.0575l1.41 1.4175H3c-.4125 0-.75.3375-.75.75s.3375.75.75.75h6.5025l-1.41 1.41c-.2925.2925-.285.7725 0 1.0575zM14.25 2.25H3.75c-.8325 0-1.5.675-1.5 1.5V6c0 .4125.3375.75.75.75s.75-.3375.75-.75V4.5c0-.4125.3375-.75.75-.75h9c.4125 0 .75.3375.75.75v9c0 .4125-.3375.75-.75.75h-9c-.4125 0-.75-.3375-.75-.75V12c0-.4125-.3375-.75-.75-.75s-.75.3375-.75.75v2.25c0 .825.675 1.5 1.5 1.5h10.5c.825 0 1.5-.675 1.5-1.5V3.75c0-.825-.675-1.5-1.5-1.5z"
        />
    </svg>
)

class LogOut extends Icon {
    render () {
        return svg( this.primary, this.secondary )
    }
}

class LogOutActive extends IconActive {
    render () {
        return svg( this.primary, this.secondary )
    }
}

export default createIcon( LogOut, LogOutActive )
