/* VENDOR */
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Scrollbars} from 'react-custom-scrollbars'

/* APPLICATION */
import {format} from 'tools'

import './sync-scroll.scss'

class SyncScroll extends Component {
    static propTypes = {
        connect: PropTypes.string,
        apply: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
    }

    constructor (props) {
        super(props)

        this.events = {}

        this.eventName = 'syncScroll.' + props.connect

        this.state = {
            width: props.width || '100%',
            height: props.height || 'auto',

            view: {
                width: props.width || '100%',
                height: props.height || '100%',
            },

            refs: {},
        }
    }

    componentDidMount () {
        this.calculate()

        window.addEventListener(this.eventName, this.apply)

        window.addEventListener('resize', this.calculate)
    }

    componentWillUnmount () {
        window.removeEventListener(this.eventName, this.apply)
        window.removeEventListener('resize', this.calculate)
    }

    height = () => this.state.height

    apply = (e) => {
        const body = this.state.refs.body

        body.scrollLeft = e.scrollLeft
        e.inititor !== this && this.bars && this.bars.scrollLeft(e.scrollLeft)
    }

    fetch = () => {
        const container = document.querySelector(this.props.apply),
              body = document.querySelector(
                  this.props.apply + ' .ant-table-scroll-horizontal .ant-table-content'
              ),
              thead = document.querySelector(this.props.apply + ' .ant-table-content .ant-table-thead')

        let offsetLeft = 0
        let offsetRight = 0

        if (thead) {
            const allFixedCellsLeft = thead.querySelectorAll('.ant-table-cell-fix-left')
            const allFixedCellsRight = thead.querySelectorAll('.ant-table-cell-fix-right')

            allFixedCellsLeft.forEach((cell) => {
                offsetLeft += cell.offsetWidth
            })
            allFixedCellsRight.forEach((cell) => {
                offsetRight += cell.offsetWidth
            })
        }

        return {
            body,
            container,
            offsetLeft,
            offsetRight,
        }
    }

    scroll = (original) => {
        const ev = new Event(this.eventName)

        ev.original = original
        ev.initiator = this
        ev.scrollLeft = original.target.scrollLeft
        ev.ScrollTop = original.target.ScrollTop

        window.dispatchEvent(ev)
    }

    calculate = () => {
        const refs = this.fetch(),
              {container, body, offsetLeft, offsetRight} = refs
        if (!container || !body) {
            setTimeout(this.calculate, 100)
            return
        }

        const pad = 0,
              width = container.offsetWidth,
              scroll = body.scrollWidth,
              res = format.copy.object(this.state),
              offset = {
                  left: offsetLeft ? offsetLeft - pad : 0,
                  right: offsetRight ? offsetRight - pad : 0,
              }
        res.width = scroll - offset.left - offset.right

        res.view.width = width - offset.left - offset.right
        res.view.left = offset.left
        res.view.right = offset.right

        res.refs = refs
        this.setState(res)
    }

    render () {
        const {view, width} = this.state
        const {hideTracksWhenNotNeeded} = this.props

        if (!this.state.refs.container) {
            return null
        }

        return (
            <div
                className="sync-scroll-container"
                style={{
                    width: view.width,
                    height: view.height,
                    marginLeft: view.left,
                    marginRight: view.right,
                }}
            >
                <Scrollbars
                    style={{
                        width: '100%',
                        height: 20,
                    }}
                    renderTrackHorizontal={(props) => (
                        <div {...props} className="scroll-track"/>
                    )}
                    renderThumbHorizontal={(props) => (
                        <div {...props} className="scroll-thumb"/>
                    )}
                    ref={(node) => (this.bars = node)}
                    hideTracksWhenNotNeeded={hideTracksWhenNotNeeded ?? true}
                    onScroll={this.scroll}
                >
                    <div
                        className="sync-scroll-size"
                        style={{
                            width: width,
                            height: 1,
                        }}
                    />
                </Scrollbars>
            </div>
        )
    }
}

export default SyncScroll
