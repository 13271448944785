import config     from 'config'
import moment     from 'moment'
import { format } from 'tools'
import * as defs  from '../defs'

export const report = ({
    restaurant,
    data,
    start,
    end
}) => {
    const gray = '007B7B7B'
    const days = [ 1, 2, 3, 4, 5, 6, 7 ]
    const times = [ ...Array( 24 ).keys() ].map( item => ( item < 10 ? `0${item}` : item ) + ':00' )
    const width = { wpx: 150 }
    const font = { name: 'Arial', sz: '10' }

    const excelHeader = {
        columns: [
            {
                title: `Статистика ${restaurant} ${moment( start ).format( 'DD' )} - ${moment( end ).format( 'DD MMMM YYYY' )}`,
                style: { font: { sz: '24', bold: true, name: 'Arial' } },
                width
            }
        ],
        data: [ [ { value: '' } ] ]
    }

    //Статистика по транзакциям и товарообороту
    const statDays = {
        daysCols: [],
        dataCols: [],
        data:     []
    }

    days.forEach( day => {
        const date = moment( data?.days[ day - 1 ]?.date )
        statDays.daysCols.push({
            title: format.strings.dowDateR( date ),
            style: {
                font:   { ...font, bold: false },
                border: { bottom: { style: 'thick' } }
            },
        })
        statDays.daysCols.push({
            title: '',
            style: { font, border: { bottom: { style: 'thick' } } }
        })
        statDays.daysCols.push({
            title: '',
            style: { font }
        })

        statDays.dataCols.push({
            title: 'ТРЗ',
            style: { font: { ...font, bold: true }, alignment: { horizontal: 'right' } },
        })
        statDays.dataCols.push({
            title: 'ТОВ',
            style: { font: { ...font, bold: true }, alignment: { horizontal: 'right' } }
        })
        statDays.dataCols.push({ title: '', style: { font } })
    })

    times.forEach(( time, indexTime ) => {
        statDays.data.push([])
        const index = statDays.data.length - 1
        statDays.data[ index ].push({
            value: time,
            style: { font: { ...font, color: { rgb: gray } } }
        })

        days.forEach( d => {
            const day = data?.days[ d - 1 ]
            const hour = day?.hours?.find(( hour ) => hour.hour === indexTime )

            statDays.data[ index ].push({
                value: hour ? hour.factTransactions : config.ui.nullSymbol,
                style: { alignment: { horizontal: 'right' }, font }
            })
            statDays.data[ index ].push({
                value: hour ? format.strings.thousand( hour.factTurnover ) : config.ui.nullSymbol,
                style: { alignment: { horizontal: 'right' }, font }
            })
            statDays.data[ index ].push({ value: '', style: { font } })
        })

        statDays.data[ index ].push({
            value: time,
            style: { font: { ...font, color: { rgb: gray } } }
        })
    })

    //Статистика по дням сумма
    const statSummary = {
        cols: [],
        data: []
    }

    days.forEach( day => {
        const date = moment( data?.days[ day - 1 ]?.date )
        statSummary.cols.push({
            title: format.strings.dowDateR( date ),
            style: {
                font:   { ...font, bold: false },
                border: { bottom: { style: 'thick' } }
            }
        })
        statSummary.cols.push({ title: '' })
    })

    const rows = format.copy.array( defs.statisticsSummaryRows )

    rows.forEach(( row, indexRow ) => {
        statSummary.data.push([])
        const index = statSummary.data.length - 1
        const f = row.format || (( d ) => d )
        const isBold = indexRow < 3

        statSummary.data[ index ].push({
            value: row.label,
            style: {
                alignment: { wrapText: true },
                font:      { ...font, bold: isBold }
            }
        })

        config.defs.days.forEach(( d ) => {
            const value = f( data.days[ d - 1 ].summary[ row.key ])
            statSummary.data[ index ].push({
                value: ( value === null || value === undefined ) ? '' : value.toString(),
                style: {
                    alignment: { horizontal: 'right' },
                    font:      { ...font, bold: isBold }
                }
            })
            statSummary.data[ index ].push({ value: '' })
        })

        const weekValue = f( data.week[ row.key ])
        statSummary.data[ index ].push({
            value: ( weekValue === null || weekValue === undefined ) ? '' : weekValue.toString(),
            style: {
                alignment: { horizontal: 'right' },
                font:      { ...font, bold: isBold }
            }
        })
    })

    return [
        excelHeader,
        {
            ySteps:  -1,
            xSteps:  1,
            columns: statDays.daysCols,
            data:    [ [ ...Array( 21 ) ].map(() => ({ value: '' })) ]
        },
        {
            ySteps:  -1,
            columns: [
                { title: '', width },
                ...statDays.dataCols,
                { title: '' }
            ],
            data: statDays.data
        },
        excelHeader,
        {
            ySteps:  -1,
            columns: [
                { title: '', width },
                ...statSummary.cols,
                {
                    title: 'Неделя',
                    style: {
                        font:   { ...font, bold: false },
                        border: { bottom: { style: 'thick' } }
                    }
                }
            ],
            data: statSummary.data
        }
    ]
}
