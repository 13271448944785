/* eslint-disable no-useless-escape */
import * as check   from './check'
import * as copy    from './copy'
import * as find    from './find'
import * as strings from './strings'

export const key = ( arr, key ) => arr.map(( record ) => record[ key ])
export const currency = ( unit ) => { 
    switch (unit) {
        case 'percent':
            return '%'
        case 'none':
            return ''
        default:
            return '₽'
    }
}
export const hours = ( time ) => hms( time ).hours
export const minutes = ( time ) => hms( time ).minutes

export const hms = ( time ) => {
    const split = time.split( ':' )

    switch ( time.match( /:/g ).length ) {
        case 2:
            return {
                hours:   parseInt( split[ 0 ]),
                minutes: parseInt( split[ 1 ]),
                seconds: parseInt( split[ 2 ]),
            }
        case 1:
            return {
                hours:   parseInt( split[ 0 ]),
                minutes: parseInt( split[ 1 ]),
            }
        case 0:
            return { hours: parseInt( time ) }
        default:
            return time
    }
}

export const time = ( date ) => date.split( 'T' ).pop().replace( /:00$/, '' )
export const intTime = ( time ) => parseInt( time.replace( /:/, '' ))

export const duration = ( num, readable ) => {
    const hour = 60,
          hours = Math.floor( num / hour ),
          minutes = num - hours * hour

    let res = ''

    if ( readable ) {
        if ( hours > 0 ) {
            res += hours + ' ч '
        }
        if ( minutes > 0 ) {
            res += minutes + ' мин '
        }
    } else {
        res = strings.dbl( hours ) + ':' + strings.dbl( minutes )
    }

    return res
}

export const empty = ( arr, exclude ) =>
    exclude ? arr.filter(( item ) => !!item ) : arr

export const min = ( arr, excludeEmpty ) =>
    empty( arr, excludeEmpty ).reduce(
        ( acc, item ) =>
            parseFloat( check.realNull( item )) < acc ? check.realNull( item ) : acc,
        Infinity
    )

export const max = ( arr, excludeEmpty ) =>
    empty( arr, excludeEmpty ).reduce(
        ( acc, item ) =>
            parseFloat( check.realNull( item )) > acc ? check.realNull( item ) : acc,
        -Infinity
    )

export const selection = ( fr, to, keys, formatter ) => {
    const f = formatter || (( d ) => d )

    return keys.map(( key ) => ( to[ key ] = f( fr[ key ])))
}

export const keys = ( arr, keys ) => {
    const res = {}
    keys.map(( k ) => ( res[ k ] = key( arr, k )))
    return res
}

export const graph = ( arr, xKey, yKey ) =>
    arr.map(( mark ) => ({
        ...mark,
        x: check.realNull( mark[ xKey ]),
        y: check.realNull( mark[ yKey ]),
    }))

export const include = copy.include

const findIndex = ( isArr, arr, data, keys, sub = 'content' ) => {
    if ( typeof keys === 'string' ) {
        return isArr
            ? find.index( arr, keys, data[ keys ])
            : find.index( arr[ sub ], keys, data[ keys ])
    } else {
        return isArr
            ? find.indexByKeys( arr, data, keys )
            : find.indexByKeys( arr[ sub ], data, keys )
    }
}

export const update = ( event, original = [], keys = '', sub = 'content' ) => {
    const status = event.status,
          message = event.data ? event.data.message : event.errorData.message,
          data = JSON.parse( event.config.data ),
          isArr = Array.isArray( original ),
          arr = isArr ? copy.array( original ) : copy.object( original )

    let index = findIndex( isArr, arr, data, keys, sub ),
        prod = isArr ? arr[ index ] : arr[ sub ][ index ]

    if ( index === -1 ) {
        index = arr.length
    }

    return { status, data, arr, index, prod, message }
}

export const actions = ( props ) =>
    include( props, [ 'setGroup', 'setPredef', 'setStartDate', 'setEndDate' ])

export const cookie = ( name ) => {
    const matches = document.cookie.match(
        new RegExp(
            // eslint-disable-next-line
      "(?:^|; )" +
        name.replace( /([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1' ) +
        '=([^;]*)'
        )
    )

    return matches ? decodeURIComponent( matches[ 1 ]) : 0
}

export const authRefresh = () => cookie( 'authorized_till' )
