/* VENDOR */
import React, { Component }   from 'react'
import PropTypes              from 'prop-types'
import { connect }            from 'react-redux'
import moment                 from 'moment'
import { Modal, Alert } from 'antd'
import Icon from '@ant-design/icons'


/* APPLICATION */
import { Icons, ReportTable, Spinner } from 'components'
import { schedulesActions }            from 'services'
import { format }                      from 'tools'
import config                          from 'config'

import './assign-shift.scss'

class AssignShift extends Component {
    static propTypes = {
        onSubmit: PropTypes.func,
        onCancel: PropTypes.func,

        date:  PropTypes.object,
        shift: PropTypes.object,

        visible: PropTypes.bool,
    }

    constructor ( props ) {
        super( props )

        this.state = {
            data:    null,
            current: null,
        }
    }

    componentDidMount () {
        this.props.flushAssignment()
        this.load( this.props )

        document.addEventListener( 'keyup', this.keyHandlers )
    }

    componentWillUnmount () {
        document.removeEventListener( 'keyup', this.keyHandlers )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { visible, assignment } = this.props

        if ( nextProps.visible !== visible ) {
            if ( nextProps.visible ) {
                this.props.flushAssignment()
                this.load( nextProps )
            } else {
                this.setState({
                    data:    null,
                    current: null,
                })
            }
        }

        if ( nextProps.assignment !== assignment ) {
            this.setData( nextProps.assignment )
        }
    }

    load = ( props ) => {
        const { request, date, shift } = props

        if ( !shift ) { return }

        props.fetchAssignment({
            factsNumber: request.restaurantId,
            date:         moment( date ).format( config.format.date ),
            timeStart:    moment( shift.shiftStart ).format( config.format.time ),
            timeEnd:      moment( shift.shiftEnd ).format( config.format.time ),
            positionGuid: shift.positionGuid,
        })
    }

    keyHandlers = ( e ) => {
        if ( !this.props.visible ) { return }

        const { data, current } = this.state,
              index = current ? data.indexOf( current ) : -1

        let next = index

        switch ( e.key ) {
            case 'ArrowUp':
                next--
                next < 0 && ( next = data.length - 1 )
                this.select( data[ next ])
                break

            case 'ArrowDown':
                next++
                next >= data.length && ( next = 0 )
                this.select( data[ next ])
                break

            case 'Enter':
                if ( !current ) { return }
                this.submit()
                break

            default:
      //Do nothing
        }
    }

    setData = ( data ) =>
        this.setState({ data: this.prepare( data, this.state.current ), })

    prepare = ( data, current ) =>
        data
            ? data.length
                ? data.map(( item ) => {
                    this.isCurrent( item, current )
                    return item
                })
                : []
            : null

    isCurrent = ( item, current ) =>
        ( item._current = current && item.employeeId === current.employeeId )

    date = () => format.strings.dowDate( this.props.date )
    time = () =>
        this.props.shift
            ? moment( this.props.shift.shiftStart ).format( config.format.time ) +
        '–' +
        moment( this.props.shift.shiftEnd ).format( config.format.time )
            : ''

    select = ( item ) =>
        this.setState({
            current: item,
            date:    this.prepare( this.state.data, item ),
        })

    submit = () => this.props.onSubmit( this.state.current )

    render () {
        return (
            <Modal
                className="kfc-popup wide"
                centered={true}
                open={this.props.visible}
                title="Назначение на смену"
                width={610}
                okText="Назначить"
                onOk={this.submit}
                okButtonProps={{ disabled: !this.state.current }}
                cancelButtonProps={{ style: { display: 'none' } }}
                onCancel={this.props.onCancel}
            >
                <div className="assign-header">
                    <span className="assign-date">
                        <Icon component={Icons.Calendar.def} />
                        {this.date()}
                    </span>

                    <span className="assign-time">
                        <Icon component={Icons.Clock.def} />
                        {this.time()}
                    </span>

                    <span className="assign-pos">
                        {this.props.shift && this.props.shift.position}
                    </span>
                </div>

                <div className="assign-content report-table">
                    {this.state.data ? (
                        this.state.data.length > 0 ? (
                            <ReportTable
                                data={format.generate.noPager( this.state.data )}
                                columns={config.tables.assignShift}
                                scroll={{ y: 320 }}
                                rowKey="employeeUuid"
                                onCellClick={this.select}
                            />
                        ) : (
                            <Alert type="warning" message="Нет доступных сотрудников" />
                        )
                    ) : (
                        <Spinner />
                    )}
                </div>
            </Modal>
        )
    }
}
const mapStateToProps = ( state ) => ({
    assignment: state.assignment,
    request:    state.request,
}),

      allActions = { ...schedulesActions, }

export default connect( mapStateToProps, allActions )( AssignShift )
