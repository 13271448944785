/* VENDOR */
import Moment           from 'moment'
import { extendMoment } from 'moment-range'

/* APPLICATION */
import config from 'config'

const moment = extendMoment( Moment )

export const employee = ( id, list ) => list.find(( e ) => e.id === id )

export const equal = ( shift, pos ) =>
    pos.shifts.find(
        ( s ) =>
            s.employeeId === shift.employeeId &&
      s.shiftStart === shift.shiftStart &&
      s.shiftEnd === shift.shiftEnd
    )

export const shift = ( id, pos ) => pos.shifts.find(( s ) => s.employeeId === id )

export const shiftByGuid = ( guid, pos ) =>
    pos.shifts.find(( s ) => s.guid === guid )

export const position = ( id, day ) =>
    day.positions.find(( p ) => p.positionGuid === id )

export const deepError = ( cb ) => ( position, pi, positions ) =>
    position.shifts.every(( shift ) =>
        positions.every(( pos ) =>
            pos.shifts
                .filter(( row ) => !!row.employeeId )
                .every(( row ) => intersection( row, shift, cb ))
        )
    )

const intersection = ( row, shift, cb ) => {
    if ( row === shift ) { return true }

    if (
        row.positionGuid === config.mcGuid &&
    shift.positionGuid === config.mcGuid
    ) {
        if ( !row.shiftSupervisor || !shift.shiftSupervisor ) { return true }
        if ( row.shiftStart !== shift.shiftStart ) { return true }
    } else {
        if ( row.employeeId !== shift.employeeId ) { return true }
    }

    if ( moment( row.shiftEnd ).isBefore( moment())) { return true }

    const startA = moment( row.shiftStart ),
          endA = moment( row.shiftEnd ),
          startB = moment( shift.shiftStart ),
          endB = moment( shift.shiftEnd ),
          a = moment.range( startA, endA ),
          b = moment.range( startB, endB )

    if ( a.overlaps( b )) {
        cb( row, shift )
        return false
    }

    return true
},

      getMaxHour = ( shift, day, nday, maxHours, rawOffset ) => {
          const offset = rawOffset || 0,
                start = parseInt( shift ? shift.timeStart : 0 ),
                rawEnd = parseInt( shift ? shift.timeEnd : 0 ),
                end = rawEnd < start ? rawEnd + 24 : rawEnd

          if ( start === end ) {
              return
          }

          let maxHour = -1,
              max = -Infinity

          for ( let i = start; i < end; i++ ) {
              let hdata =
        i > 23
            ? nday.hours
                ? nday.hours.find(( h ) => h.hour === i - 24 )
                : null
            : day.hours.find(( h ) => h.hour === i ),
                  to = hdata ? hdata.turnover : -Infinity

              max !== Math.max( max, to ) && ( maxHour = i )
              max = Math.max( max, to )
          }

          maxHours.push( maxHour + offset )
      },

      hourize = ( data ) => ({
          hours: data.data.map(( d ) => ({
              hour:     parseInt( d.label ),
              turnover: d.planValue,
          })),
      })

export const max = ( data, rawDow, wh, sideDays ) => {
    if ( !data || !sideDays ) { return }

    const dow = parseInt( rawDow ),
          pdow = dow - 1,
          ndow = dow + 1,
          day = data.days.find(( d ) => d.dayOfWeek === dow ),
          nday =
      ndow <= 7
          ? data.days.find(( d ) => d.dayOfWeek === ndow )
          : hourize( sideDays.next ),
          pday =
      pdow >= 1
          ? data.days.find(( d ) => d.dayOfWeek === pdow )
          : hourize( sideDays.prev ),
          whd = wh.days.find(( d ) => d.dayOfWeek === dow ),
          whp = wh.days.find(( d ) =>
              pdow === 0 ? d.dayOfWeek === 7 : d.dayOfWeek === pdow
          ),
          maxHours = []

    if ( parseInt( whd.timeStart ) > parseInt( whd.timeEnd )) {
        const last = whp.shifts[ whp.shifts.length - 1 ]

        getMaxHour( last, pday, day, maxHours, -24 )
    }

    whd.shifts.forEach(( shift ) => getMaxHour( shift, day, nday, maxHours ))

    return maxHours.filter(
        ( h, i ) => maxHours.indexOf( h ) === i && h > -1 && h < 24
    )
}
