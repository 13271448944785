/* eslint-disable camelcase */
/* eslint-disable no-case-declarations */
/* VENDOR */
import React, { Component } from 'react'
import { connect }          from 'react-redux'
import moment               from 'moment'

import { Alert as AAlert, Card, Layout } from 'antd'

/* APPLICATION */
import {
    Alert,
    AppHeader,
    GenerateProgress,
    InnerContent,
    Link,
    ScheduleControls,
    ScheduleDays,
    Spinner,
} from 'components'

import { format } from 'tools'
import config     from 'config'

import logic         from './logic'
import ScheduleTable from './components/ScheduleTable/ScheduleTable'
import ScheduleChart from './components/ScheduleChart/ScheduleChart'

import { allActions, mapStateToProps } from './connector'
import './schedule.scss'
import ScheduleSummaryReport
    from '../../components/schedule/ScheduleSummaryReport/ScheduleSummaryReport.js'

const noChanges = [ null, false, false, false, false, false, false, false ]

class Schedule extends Component {
    constructor ( props ) {
        super( props )

        this.state = {
            loading:     !props.schedule,
            working:     false,
            saving:      false,
            changed:     format.copy.array( noChanges ),
            changeTimer: null,

            ignoreSetDay:     false,
            forcePopup:       false,
            canShow:          false,
            ignoreTomorrowMC: false,
            changeDay:        false,
            compactView:      false,
            focusOnUpdate:    null,

            error:        false,
            errorDate:    '',
            errorMessage: null,

            progress: false,
            received: false,

            schedule: 'current',
            day:      '1',
            from:     null,
            to:       null,
            date:     moment().startOf( 'week' ),

            dataSummaryReport:         null,
            dataSummaryReportSunToSun: null,
            data:                      null,
            chartPositions:            [],
            position:                  null,
            genData:                   null,
            filteredEmps:              [],

            open:             {},
            highlightColumns: null,
            afterSave:        null,
            isSelected:       true,
            isSummaryReport:  false,
        }

        this.set = format.generate.set( this )
    }

    componentDidMount () {
        this.init()
        document.addEventListener( 'keyup', this.keyboardHandlers )
        document.addEventListener( 'keydown', this.preventTab )
    }

    componentWillUnmount () {
        document.removeEventListener( 'keyup', this.keyboardHandlers )
        document.removeEventListener( 'keydown', this.preventTab )
    }

    componentDidUpdate ( prevProps, prevState ) {

        const {
            schedule,
            schedules,
            hours,
            duration,
            request,
            sideDays,
            positions,
            list,
            restaurants,
        } = this.props,
              { saved, saveCount, saveTurnover } = this.props,
              { data, day, afterSave, working, focusOnUpdate, date } = this.state

        if ( restaurants !== prevProps.restaurants && restaurants ) {
            this.getPositions()
        }

        schedules && schedules !== prevProps.schedules && this.setSchedule()

        if (schedule !== prevProps.schedule) {
            this.setData( true )
        }

        if ( duration !== prevProps.duration || day !== prevState.day) {
            this.setData(false, true)
        }

        hours !== prevProps.hours && this.setDay( this.state.day )
        request.restaurantId !== prevProps.request.restaurantId && this.init()
        positions !== prevProps.positions && positions && this.setChartPositions()
        list !== prevProps.list && this.setFilteredEmps()
        saveTurnover && this.load( date )
        saveCount < 1 && saved && !!afterSave && this.afterSave()

        if (data &&
            hours &&
            sideDays &&
            ( data !== prevState.data ||
                hours !== prevProps.hours ||
                sideDays !== prevProps.sideDays )) {
                    this.set.highlightColumns(
                        logic.ui.getHighlights( data, day, hours, sideDays )
                    )
                }

        if ( working !== prevState.working && !working ) {
            if ( focusOnUpdate ) {
                setTimeout(() => this.setKeyboardFocus( focusOnUpdate ), 100 )
                this.set.focusOnUpdate( null )
            }
        }
    }

    /* GLOBAL METHODS */

    init = () => {
        const { schedule, schedules, duration, positions, list } = this.props

        this.fetchAll()
        schedules && this.setSchedule()
        schedule && duration && this.setData()
        positions && this.setChartPositions()
        list && this.setFilteredEmps()

    }

    load = ( date, dismissLoading ) => {
        let datesInStorage = JSON.parse( sessionStorage.getItem( 'scheduleData' ))

        if ( datesInStorage ) {
            this.setState({ date: moment( datesInStorage.start ).startOf( 'week' ), })
            this.fetchWeek( moment( datesInStorage.start ).startOf( 'week' ))
        } else {
            sessionStorage.setItem( 'scheduleData', JSON.stringify({
                start: moment( date ).startOf( 'week' ).format( config.format.dateFull ),
                end:   moment( date ).endOf( 'week' ).format( config.format.dateFull ),
            }))
            this.fetchWeek( date )
        }
        this.flushData( dismissLoading )
    }

    generate = ( data ) =>
        this.set.state({
            progress: true,
            received: false,
            canShow:  false,
            genData:  logic.generate.prepare( data ),
        })

    save = () => {
        const { changed } = this.state

        this.set.state({
            ignoreSetDay: true,
            saving:       true,
        })

        this.props.setDaysToSave( changed.filter(( s ) => s ).length )

        changed.map(( status, dow ) => status && this.saveDay( dow ))

        this.set.afterSave(() => this.load( this.state.date, true ))
    }

    afterSave = () => {
        this.state.afterSave()
        this.set.state({
            afterSave: null,
            working:   false,
        })
        this.props.setSaved( false )
    }

    reset = () => {
        this.set.working( true )
        setTimeout( this.setData, 0 )
    }

    saveDay = ( dow ) => {
        const { data } = this.state,
              body = format.copy.object(
                  logic.shifts.excludeUnassigned( logic.datetime.find( data.days, dow ))
              )

        this.props.saveScheduleDay({
            body,
            params: { factsNumber: this.props.request.restaurantId, },
        })
    }

    preventTab = ( e ) => {
        const { loading, working, saving, progress } = this.state

        if ( loading || working || saving || progress || window._schedule_popup ) {
            return
        }

        if ( e.key === 'Tab' ) {
            e.preventDefault()
            e.stopPropagation()
        }
    }

    getKeyboardFocus = () => {
        const all = [].slice
            .call(
                document.querySelectorAll(
                    '.ant-table-fixed-left tr:not(.row-hidden) .ant-select, .ant-table-fixed-left tr:not(.row-hidden) .chart-time, .ant-table-fixed-left tr:not(.row-hidden) .assign-btn'
                )
            )
            .filter(( node ) => !node.querySelector( 'input:disabled' )),
              current = document.querySelector(
                  '.ant-table-fixed-left tr:not(.row-hidden) .chart-time-focused, .ant-table-fixed-left tr:not(.row-hidden) .ant-select-focused, .ant-table-fixed-left tr:not(.row-hidden) .assign-btn-focused'
              ),
              infocus = all.findIndex(
                  ( node ) =>
                      node.classList.contains( 'chart-time-focused' ) ||
                    node.classList.contains( 'ant-select-focused' ) ||
                    node.classList.contains( 'assign-btn-focused' )
              )

        return { all, current, infocus }
    }

    setKeyboardFocus = ( index ) => {
        const { all, infocus } = this.getKeyboardFocus(),
              target = all[ index ]
                  ? all[ index ].classList.contains( 'assign-btn' )
                      ? all[ index ]
                      : all[ index ].querySelector(
                          '.ant-time-picker-input, .ant-select-search__field'
                      )
                  : null

        if ( all[ infocus ]) {
            all[ infocus ].classList.remove( 'chart-time-focused' )
            all[ infocus ].classList.remove( 'assign-btn-focused' )
            all[ infocus ].classList.remove( 'ant-select-focused' )
        }

        document.dispatchEvent( new Event( 'mousedown' ))
        document.activeElement.blur()

        if ( target ) {
            this.scrollToFocused( all[ index ])

            if ( target.classList.contains( 'assign-btn' )) {
                target.classList.add( 'assign-btn-focused' )
                all[ infocus ].classList.remove( 'ant-select-focused' )
            } else {
                if ( all[ index ].classList.contains( 'ant-select' )) {
                    all[ index ].classList.add( 'ant-select-focused' )
                } else if ( all[ index ].classList.contains( 'chart-time' )) {
                    all[ index ].classList.add( 'chart-time-focused' )
                } else {
                    console.log( target )
                }
                this.nextFocusAction(() => target.click())
            }
        }
    }

    findParent = ( node, type ) => {
        let parent = node.parentNode

        while ( parent.tagName !== type ) {
            parent = parent.parentNode
        }

        return parent
    }

    scrollToFocused = ( target ) => {
        const content = document.querySelector( '.inner-content' ),
              table = document
                  .querySelector( '.staff-schedule-table' )
                  ?.getBoundingClientRect() || { bottom: content.getBoundingClientRect().top, },
              parent = this.findParent( target, 'TD' ),
              top = parent.getBoundingClientRect().top,
              topOffset = table.bottom,
              visible = top >= topOffset && top < window.screen.availHeight - 160

        !visible && content.scrollTo({ top: parent.offsetTop - content.offsetTop })
    }

    nextFocusAction = ( cb ) => {
        this._next_focus = setTimeout( cb, 300 )
    }

    keyboardHandlers = ( e ) => {
        const { loading, working, saving, progress } = this.state

        if ( loading || working || saving || progress || window._schedule_popup ) {
            return
        }

        this.preventTab( e )
        switch ( e.key ) {
            case 'Tab':
                e.preventDefault()
                this._next_focus && clearTimeout( this._next_focus )

                const { all, current, infocus } = this.getKeyboardFocus()

                if ( !current ) {
                    const target = document.querySelector(
                        '.ant-table-fixed-left tr:not(.row-hidden) .ant-select-search__field, .ant-table-fixed-left tr:not(.row-hidden) .assign-btn'
                    )

                    if ( target ) {
                        this.scrollToFocused( target )

                        if ( target.classList.contains( 'assign-btn' )) {
                            target.classList.add( 'assign-btn-focused' )
                        } else {
                            if ( target.classList.contains( 'ant-select-search__field' )) {
                                target.parentNode.parentNode.parentNode.parentNode.parentNode.classList.add(
                                    'ant-select-focused'
                                )
                            }
                            this.nextFocusAction(() => target.click())
                        }
                    }
                } else {
                    const cur = infocus,
                          next = e.shiftKey
                              ? cur < 0 || cur - 1 < 0
                                  ? all.length - 1
                                  : cur - 1
                              : cur < 0 || cur + 1 >= all.length
                                  ? 0
                                  : cur + 1

                    this.setKeyboardFocus( next )
                }
                break

            case 'Enter':
                e.preventDefault()

                const input = document.querySelector(
                    '.ant-select-focused .ant-select-search__field'
                ),
                      focused = document.querySelector(
                          '.ant-table-fixed-left tr:not(.row-hidden) .chart-time-focused, .ant-table-fixed-left tr:not(.row-hidden) .ant-select-focused'
                      ),
                      ddcontainer = focused
                          ? document.getElementById(
                              focused
                                  .querySelector( '.ant-select-selection' )
                                  ?.getAttribute( 'aria-controls' )
                          )
                          : null,
                      dd =
                        ddcontainer &&
                        !ddcontainer.parentNode.classList.contains(
                            'ant-select-dropdown-hidden'
                        ) &&
                        !ddcontainer.parentNode.classList.contains( 'slide-up-enter' ),
                      assign = document.querySelector( '.assign-btn-focused' )

                if ( assign ) {
                    assign.click()
                } else if ( input && dd ) {
                    input.blur()
                } else {
                    focused &&
                    focused
                        .querySelector(
                            '.ant-time-picker-input, .ant-select-search__field'
                        )
                        .click()
                }

                break

            default:
            //Do nothing
        }
    }

    fetchScheduleSummary = ( date ) => {
        const { request } = this.props,
              start = moment( date, config.format.date ).startOf( 'week' ),
              end = moment( start ).endOf( 'week' )

        this.props.fetchReportsSchedulesSummary({
            factsNumber:           request.restaurantId,
            dateStart:             start.format( config.format.dayAPI ),
            dateEnd:               end.format( config.format.dayAPI ),
        })

    }

    /* FETCH METHODS */

    fetchWeek = ( date ) => {
        const { request } = this.props,
              start = moment( date, config.format.date ).startOf( 'week' )

        this.props.getSchedule({
            factsNumber: request.restaurantId ?? request.factsNumber,
            dateStart:   start.format( config.format.dayAPI ),
        })

        this.props.getSideDays({
            factsNumber: request.restaurantId,
            dateStart:   moment( start ).startOf( 'week' ).subtract( 1, 'day' ),
            dateEnd:     moment( start ).endOf( 'week' ).add( 1, 'day' ),
        })

    }

    fetchAll = () => {
        const { request } = this.props,
              { fetchStaff, fetchSchedules } = this.props,
              { fetchWorkHours, fetchDuration, flushSchedules, flushWorkHours } =
                this.props

        flushWorkHours()
        flushSchedules()

        this.getPositions()

        fetchStaff( { ...request, factsNumber: request.restaurantId } )
        fetchSchedules( request )
        fetchWorkHours( request )
        fetchDuration( request )
    }

    changeSelected = ( isSelectedChangingState ) => {
        this.setState(
            { isSelected: isSelectedChangingState, },
            () => {
                this.getPositions()
            }
        )
    }

    getPositions = () => {
        const { restaurants, request, fetchPositionsChartedByType } = this.props

        if ( !restaurants ) {
            return
        }

        const restaurant = restaurants.find( rest => rest.factsNumber === Number( request.restaurantId ))

        if ( !restaurant ) {
            return
        }

        fetchPositionsChartedByType({
            facilityType:    { id: restaurant.facilityType.id },
            isExpressWindow: restaurant.isExpressWindow,
            restaurantType:  { id: restaurant.restaurantType.id },
            factsNumber:     request.restaurantId,
            isSelected:      false,
        })


    }

    getTurnover = ( body ) => {
        this.props.flushTurnoverError()
        body.factsNumber = this.props.request.restaurantId
        this.props.fetchWeekTurnover( body )
    }


    setTurnover = ( body ) => {
        this.props.flushTurnoverError()
        const factsNumber = this.props.request.restaurantId,
              data = {
                  body:        body,
                  factsNumber: factsNumber,
              }

        this.props.setTurnover( data )
    }

    /* PROGRESS METHODS */

    progressReady = () =>
        this.props.generateSchedule({
            params: {
                dateStart:   this.state.genData.date.format( config.format.dayAPI ),
                factsNumber: this.props.request.restaurantId,
            },
            body: { turnover: this.state.genData.turnover, },
        })

    progressDone = () => {
        this.load( this.state.date )
        this.props.flushGenerateError()
        this.set.state({
            progress: false,
            genData:  null,
        })
    }

    /* APPLY DATA SECTION */

    flushData = ( dismissLoading ) => {
        const { data } = this.state

        this.set.state({
            data:     dismissLoading ? data : null,
            position: null,
            loading:  !dismissLoading,
            changed:  format.copy.array( noChanges ),
        })
    }

    setSchedule = () => {
        const { schedule } = this.props,
              { date } = this.state

        if ( !schedule ) {
            this.state.schedule ? this.load( date ) : this.set.schedule( 'current' )
            return
        }

        this.set.canShow( true )
    }

    setData = ( setDay, notSetDate ) => {
        if ( !this.props.duration || !this.props.schedule ) {
            return
        }

        const { duration, hours, sideDays, dataSummaryReport, dataSummaryReportSunToSun } = this.props,
              { ignoreSetDay, changeDay } = this.state,
              schedule = logic.schedule.prepare( this.props.schedule, duration ),
              { day, date, from, to } = logic.datetime.schedule(
                  schedule,
                  setDay && !ignoreSetDay,
                  this.state.day
              )


        this.set.state({
            data:                      schedule,
            day,
            dataSummaryReport:         dataSummaryReport,
            dataSummaryReportSunToSun: dataSummaryReportSunToSun,
            loading:                   false,
            changed:                   format.copy.array( noChanges ),
            schedule:                  logic.datetime.getTab( schedule.dateStart ),
            // date,
            from:                      setDay ? from : this.state.from,
            to:                        setDay ? to : this.state.to,
            saving:                    false,
            received:                  setDay,
            changeDay:                 setDay ? false : changeDay,
            max:                       logic.datetime.maxHours( schedule, day, hours, sideDays ),
            highlightColumns:          logic.ui.getHighlights( schedule, day, hours, sideDays ),
        })

        if (!notSetDate) {
          this.set.state({
            date
          })
        }
    }

    setDay = ( day ) => {
        const { schedule } = this.props,
              wh = logic.datetime.workHours( day )

        if ( !wh ) {
            return
        }

        this.set.state(
            {
                changeDay:    true,
                day:          day,
                from:         wh.from,
                to:           wh.to,
                date:         logic.datetime.offsetDate( schedule?.dateStart, day ),
                position:     null,
                ignoreSetDay: false,
            },
            this.checkErrors
        )
    }

    setWeek = ( schedule ) => {
        const date = logic.schedule.date( schedule ),
              datesInStorage = JSON.parse( sessionStorage.getItem( 'scheduleData' ))

        if ( schedule === this.state.schedule ) {
            return
        }


        datesInStorage.lastPage = 'schedule'
        datesInStorage.start = moment( date ).startOf( 'week' ).format( config.format.dateFull )
        datesInStorage.end = moment( date ).endOf( 'week' ).format( config.format.dateFull )

        sessionStorage.setItem( 'scheduleData', JSON.stringify( datesInStorage ))

        this.load( date )
        this.set.state({
            schedule,
            ignoreSetDay:
                moment().startOf( 'week' ).format( config.format.dateFull ) ===
                schedule.dateStart,
            day: '1',
        })
    }

    setChartPositions = () => {
        this.set.chartPositions( this.props.positions )
    }

    setPosition = ( id ) => {
        const { data } = this.state,
              day = logic.datetime.find( data.days, this.state.day ),
              original = day.positions.find(( p ) => p.positionGuid === id ),
              position = format.copy.object( original )

        if ( !original ) {
            return
            //this.set.position( logic.ui.emptyPosition( positions, id ))
            //return
        }

        position.hours = original?.hours?.map(( h ) => ({ paidHours: h }))

        this.set.position( position )
    }

    setFilteredEmps = () => {
        const { list, request } = this.props,
              { date } = this.state,
              emps = list.filter(( e ) => {
                  if ( e.transfer ) {
                      if (
                          e.transfer.originalFactsNumber.toString() === request.restaurantId
                      ) {
                          return moment( e.transfer.dateTo ).isBefore( date )
                      }
                      if ( moment( e.transfer.dateTo ).isBefore( date )) {
                          return false
                      }
                  }
                  return !!( e.salary && e.rateDictionary )
              })

        this.set.filteredEmps([ ...emps ])
    }

    showPopup = () => {
        this.set.forcePopup( true )

        setTimeout(() => this.set.forcePopup( false ), 500 )
    }

    toggle = ( key, handler ) => {
        return () => {
            const open = format.copy.object( this.state.open )

            open[ key ] = !open[ key ]

            this.set.open( open )
            handler && handler()
        }
    }

    add = ( shift ) => {
        const { list, positions } = this.props,
              { res, pos } = this.extract( shift, 'position' ),
              { position, day, date } = this.state,
              dow = parseInt( day ),
              sh = shift.shiftStart.hours(),
              sm = shift.shiftStart.minutes(),
              eh = shift.shiftEnd.hours(),
              em = shift.shiftEnd.minutes(),
              workHours = this.props.hours.days[ dow - 1 ]

        shift._temp_id = format.generate.guid()

        shift.shiftStart = moment( date ).set({ hour: sh, minute: sm })
        shift.shiftEnd =
            shift.shiftStart.hours() > shift.shiftEnd.hours()
                ? moment( date ).add( 1, 'days' ).set({ hour: eh, minute: em })
                : moment( date ).set({ hour: eh, minute: em })

        logic.shifts.add( list, pos, shift )

        if (
            shift.shiftStart.hours() > shift.shiftEnd.hours() &&
            shift.shiftEnd.hours() > 0 &&
            dow < 7
        ) {
            const { pos } = this.extract( shift, 'position', null, dow + 1 ),
                  dshift = format.copy.object( shift )

            dshift._double = true
            dshift._temp_id = shift._temp_id

            logic.shifts.add( list, pos, dshift )
            this.changeDays([ dow + 1 ])
        }

        this.set.working( true )

        setTimeout(() => {
            logic.calculate.schedule( res, true, dow, workHours, positions )

            setTimeout(() => {
                this.changeDay()
                this.set.data( res )
                position && this.setPosition( position.positionGuid )
            }, 0 )
        }, 0 )
    }

    assign = ( data, target ) => {
        const { infocus } = this.getKeyboardFocus()

        this.change( target, {
            employeeName:   data.employeeId,
            isUnderage:     data.isUnderage,
            rateDictionary: data.rateDictionary,
        })
        this.set.focusOnUpdate( infocus )
    }

    remove = ( shift ) => {
        const { date } = this.state,
              { positions } = this.props,
              { res, pos, day } = this.extract( shift, 'positionGuid' ),
              index = pos.shifts.indexOf(
                  pos.shifts.find(( s ) =>
                      s.guid && shift.guid
                          ? s.guid === shift.guid
                          : shift._temp_id
                              ? s._temp_id === shift._temp_id
                              : s.workScheduleId === shift.workScheduleId
                  )
              )
        const dow = parseInt( this.state.day )
        const workHours = this.props.hours.days[ dow - 1 ]

        index > -1 && pos.shifts.splice( index, 1 )
        logic.twins.remove( shift, date, res )
        this.set.working( true )

        setTimeout(() => {
            logic.calculate.schedule(
                res,
                !!shift.employeeId,
                parseInt( this.state.day ),
                workHours,
                positions
            )

            setTimeout(() => {
                this.setState(
                    {
                        data:      res,
                        error:     false,
                        errorDate: '',
                    },
                    () => !!shift.employeeId && this.changeDay()
                )
                !moment( shift.shiftStart ).isSame( date, 'day' ) &&
                this.changeDays([ day.dayOfWeek - 1 ])
                this.checkErrors()
            }, 0 )
        }, 0 )
    }

    changeDay = () => {
        const changed = [ ...this.state.changed ]

        changed[ this.state.day ] = true
        this.set.changed( changed )
    }

    changeDays = ( list ) => {
        const changed = [ ...this.state.changed ]

        list.forEach(( dow ) => ( changed[ dow ] = true ))
        this.set.changed( changed )
    }

    clearError = ( error ) => {
        if ( !error || this.state.error === error ) {
            this.setState({
                error:        '',
                errorMessage: null,
            })
        }
    }

    setError = ( error, message ) => {
        if ( error === 'underage_error' ||
            error === 'mc_error' ||
            error === 'outstaff_error' ) {
            this.setState({
                error,
                errorMessage: message ?? this.state.errorMessage
            })
            return
        }

        if ( this.state.error === 'tomorrow_mc_error' ) {
            if ( this.state.ignoreTomorrowMC ) {
                this.setState({ errorMessage: message ?? this.state.errorMessage })
            } else {
                this.setState({
                    error,
                    errorMessage: message ?? this.state.errorMessage
                })
            }

        }
    }

    change = ( original, config ) => {
        this.set.working( true )

        setTimeout(() => {
            const { list, positions } = this.props,
                  { date } = this.state,
                  { res, day, pos, shift } = this.extract(
                      original,
                      'positionGuid',
                      original.guid
                  ),
                  dow = parseInt( this.state.day ),
                  changed = [ dow ],
                  workHours = this.props.hours.days[ dow - 1 ]

            Object.keys( config ).forEach(( key ) => {
                const val = config[ key ]

                switch ( key ) {
                    case 'position':
                        logic.positions.update( positions, day, pos, shift, val, date, res )
                        break

                    case 'employeeName':
                        shift._warning = false
                        logic.employee.set( list, shift, val, date, res )
                        break

                    case 'shiftEnd':
                    case 'shiftStart':
                        logic.shifts.setTime( shift, key, val, date, res )
                        dow > 1 && changed.push( dow - 1 )
                        dow < 7 && changed.push( dow + 1 )
                        break

                    case 'shiftSupervisor':
                    case 'isUnderage':
                    case 'rateDictionary':
                        shift[ key ] = val
                        break

                    default:
                        console.log( day, key, val )
                }
            })

            logic.calculate.schedule( res, true, dow, workHours, positions )
            this.changeDays( changed )
            this.set.state({
                data:      res,
                error:     false,
                errorDate: '',
            })
            setTimeout( this.checkErrors, 0 )
        }, 0 )
    }

    changeOne = ( original, key, val ) => {
        this.set.working( true )

        setTimeout(() => {
            const { list, positions } = this.props,
                  { date } = this.state,
                  { res, day, pos, shift } = this.extract(
                      original,
                      'positionGuid',
                      original.guid
                  ),
                  dow = parseInt( this.state.day ),
                  changed = [ dow ]

            const workHours = this.props.hours.days[ dow - 1 ]

            switch ( key ) {
                case 'position':
                    if ( original.positionGuid === val ) {
                        this.set.working( false )
                        return
                    }
                    logic.positions.update( positions, day, pos, shift, val, date, res )
                    logic.calculate.schedule( res, true, dow, workHours, positions )
                    break

                case 'employeeName':
                    if ( original.employeeName === val || original.employeeId === val ) {
                        this.set.working( false )
                        return
                    }
                    shift._warning = false
                    logic.employee.set( list, shift, val, date, res )
                    logic.calculate.schedule( res, true, dow, workHours, positions )
                    break

                case 'shiftEnd':
                case 'shiftStart':
                    logic.shifts.setTime( shift, key, val, date, res )
                    dow > 1 && changed.push( dow - 1 )
                    dow < 7 && changed.push( dow + 1 )
                    logic.calculate.schedule( res, true, dow, workHours, positions )
                    break

                case 'shiftSupervisor':
                    shift[ key ] = val
                    break

                default:
                    console.log( day, key, val )
            }

            this.changeDays( changed )
            this.set.state({
                data:      res,
                error:     false,
                errorDate: '',
            })
            setTimeout( this.checkErrors, 0 )
        }, 0 )
    }

    toggleCompactView = () => {
        this.set.working( true )
        document.activeElement.blur()
        setTimeout(() => {
            this.set.state({
                compactView: !this.state.compactView,
                working:     false,
            })
        })
    }

    /* TOOLS */

    extract = ( original, pid, guid, dow ) =>
        logic.shifts.extract(
            this.state.data,
            dow ?? this.state.day,
            original[ pid ],
            original.employeeId,
            this.props.positions,
            guid
        )

    ignoreTomorrowMC = () => {
        const { data, day } = this.state
        const dow = parseInt( this.state.day )
        const { positions } = this.props
        const workHours = this.props.hours.days[ dow - 1 ]

        this.set.state({
            changeTimer:      true,
            error:            false,
            data:             { ...data },
            ignoreTomorrowMC: true,
        })

        setTimeout(() => {
            logic.calculate.schedule( data, true, day, workHours, positions )
            setTimeout( this.checkErrors, 0 )
        }, 0 )
    }

    /* GET PAGE STATE METHODS */

    checkErrors = () => {
        const { data } = this.state

        if ( !data ) {
            return
        }

        data.days.every(( day ) =>
            day.positions.every(
                logic.shifts.errors(( a, b ) => {
                    const date =
                        moment( a.shiftStart ).format( 'DD' ) ===
                        moment( b.shiftStart ).format( 'DD' )
                            ? moment( a.shiftStart ).format( config.format.onlyDayView )
                            : moment( a.shiftEnd ).format( config.format.onlyDayView )

                    this.set.state({
                        error:     true,
                        errorDate: date,
                    })
                })
            )
        )

        setTimeout(() => {
            this.set.state({
                changeDay:   false,
                changeTimer: null,
            })
        }, 0 )
    }

    changed = ( res, useOutstaffError ) =>
        useOutstaffError &&
        this.state.error === 'outstaff_error' &&
        this.hasOutstaffReturned()
            ? res
            : logic.helpers.changed( res, this.props, this.state )

    hasOutstaffReturned = () => {
        if ( !this.state.data ) {
            return
        }

        const { data, error } = this.state,
              day = logic.datetime.find( data.days, this.state.day ),
              res = []

        day?.positions?.forEach(( pos ) => {
            pos.shifts.forEach(( shift ) => {
                if ( shift.notAssignedToTheRestaurant ) {
                    res.push( shift )
                }
            })
        })
        res.length > 0 &&
        !error &&
        setTimeout(() => this.set.error( 'outstaff_error' ), 0 )

        return res.length > 0 ? res : null
    }

    weekPresent = () => {
        const { schedules } = this.props,
              { date } = this.state,
              datesInStorage = JSON.parse( sessionStorage.getItem( 'scheduleData' ))

        return (
            schedules &&
            ( schedules.indexOf(
                moment( datesInStorage ? datesInStorage.start : date ).startOf( 'week' ).format( config.format.dateFull )
            ) > -1 ||
                moment( datesInStorage ? datesInStorage.start : date ).startOf( 'week' ).isSame( moment().startOf( 'week' )))
        )
    }

    enoughData = () =>
        this.state.data && this.state.from && this.state.to && this.props.list

    /* RENDER PARTS */

    spoiler = ( path, obj, handler ) => {
        const { day, open } = this.state

        let key = path.split( '.' )[ 0 ] + day

        key += obj.positionGuid ? obj.positionGuid : 'Day'

        obj.__open = obj.open ? !open[ key ] : open[ key ]

        obj.__spoiler = true
        obj.__offset = 1
        obj.__spkey = key
        obj.guid = format.generate.guid()
        obj.rowClick = this.toggle( key, handler )
    }

    table = () => {
        const {
            positions,
            hours,
            dataSummaryReport,
            deleteSchedulesEmployeesHided,
            postSchedulesEmployeesHided,
            request
        } = this.props,
              { loading, max, date, data, day } = this.state,
              { open, position, from, to, compactView } = this.state,
              start = moment( date, config.format.date ).startOf( 'week' ),
              end = moment( start ).endOf( 'week' ),
              currentWeek = {
                  dateStart: start.format( config.format.dayAPI ),
                  dateEnd:   end.format( config.format.dayAPI ),
              }

        if ( compactView && !this.state.isSummaryReport || !this.weekPresent() ) {
            return null
        }

        if ( (loading || !this.enoughData()) && !this.state.isSummaryReport) {
            return (
                <div className="spinner-container">
                    <Spinner/>
                </div>
            )
        }


        if ( !this.weekPresent()) {
            const message = moment().startOf( 'week' ).isSame( date ) ? (
                <span>
          Расписание на текущую неделю недоступно. Можно{' '}
                    <Link
                        onClick={this.showPopup}>спланировать другие недели</Link>.
                </span>
            ) : (
                <span>
          Расписание на следующую неделю не составлено.{' '}
                    <Link onClick={this.showPopup}>Сформировать</Link>.
                </span>
            )

            return <AAlert message={message} type="warning"/>
        }

        if ( (!data || !data.days.length) && !this.state.isSummaryReport) {
            return 'На выбранную неделю расписание не сформировано.'
        }

        return (
            !this.state.isSummaryReport ?
                    <ScheduleTable
                        open={open}
                        positions={positions}
                        data={data}
                        day={day}
                        position={position}
                        workHours={hours}
                        from={from}
                        to={to}
                        max={max}
                        onSelect={this.setPosition}
                        spoiler={this.spoiler}
                    />

                :

                    <ScheduleSummaryReport
                        data={dataSummaryReport?.currentWeek}
                        // updatedData={dataSummaryReport?.fromSunToSun}
                        request={request}
                        date={date}
                        currentWeek={currentWeek}
                        fetchAllSchedules={this.props.fetchSchedules}
                        deleteSchedulesEmployeesHided={deleteSchedulesEmployeesHided}
                        fetchScheduleSummary={this.fetchScheduleSummary}
                        postSchedulesEmployeesHided={postSchedulesEmployeesHided}
                    />

        )
    }

    changeSummaryReport = () => {
        const { isSummaryReport } = this.state

        this.set.state({ isSummaryReport: !isSummaryReport })
    }
    chart = () => {
        const {
            day,
            from,
            to,
            date,
            data,
            open,
            chartPositions,
            filteredEmps,
            changeDay,
            ignoreTomorrowMC,
            compactView,
        } = this.state,
              { hours } = this.props

        if ( !data || !data.days.length || !this.enoughData() || !this.weekPresent()) {
            return null
        }

        if ( changeDay ) {
            return (
                <div className="spinner-container chart">
                    <Spinner/>
                </div>
            )
        }

        return (
            !this.state.isSummaryReport ?
                    <ScheduleChart
                        day={day}
                        from={from}
                        to={to}
                        date={date}
                        data={data}
                        open={open}
                        inlineHours={compactView}
                        ignoreTomorrowMC={ignoreTomorrowMC}
                        workHours={hours}
                        positions={chartPositions}
                        employees={filteredEmps}
                        onAdd={this.add}
                        onChange={this.changeOne}
                        onRemove={this.remove}
                        onError={this.setError}
                        clearError={this.clearError}
                        onAssign={this.assign}
                        spoiler={this.spoiler}
                        changeSelected={this.changeSelected}
                        onDone={this.set.working}
                    />
                :
                null
        )
    }

    render () {
        const { request, user, genError } = this.props,
              {
                  schedules,
                  weekTurnover,
                  flushTurnover,
                  saveTurnover,
                  errorTurnover,
                  errorSetTurnover,
                  addServerError
              } = this.props,
              {
                  loading,
                  saving,
                  working,
                  progress,
                  forcePopup,
                  canShow,
                  compactView
              } =
                this.state,
              {
                  highlightColumns,
                  error,
                  errorDate,
                  errorMessage,
                  isSummaryReport
              } = this.state,
              { schedule, data, day } = this.state,
              returned = this.hasOutstaffReturned()

        return (
            <section
                className="kfc-schedule kfc-tabbed-page scroll-container fixed-container">
                {highlightColumns && (
                    <style
                        dangerouslySetInnerHTML={{ __html: highlightColumns }}/>
                )}
                <Layout>
                    <Layout.Header>
                        <AppHeader
                            ready={!loading}
                            timeData={parseInt( request.restaurantId )}
                        />
                    </Layout.Header>
                    <Layout.Content>
                        <ScheduleControls
                            request={request}
                            user={user.info}
                            list={schedules}
                            current={schedule}
                            turnover={weekTurnover}
                            compact={compactView}
                            changed={!!this.changed( true )}
                            popup={forcePopup}
                            onWeek={this.setWeek}
                            onGenerate={this.generate}
                            getTurnover={this.getTurnover}
                            setTurnover={this.setTurnover}
                            onCompact={this.toggleCompactView}
                            flushTurnover={flushTurnover}
                            saveTurnover={saveTurnover}
                            errorTurnover={errorTurnover}
                            errorSetTurnover={errorSetTurnover}
                            addServerError={addServerError}
                            isSummaryReport={isSummaryReport}
                            changeSummaryReport={this.changeSummaryReport}
                        />
                        <InnerContent bigOffset={!!this.changed( true )}>
                            <Card bordered={false}>
                                {this.weekPresent() && !isSummaryReport && (
                                    <ScheduleDays
                                        data={data}
                                        show={!!( data && data.days.length )}
                                        day={day}
                                        onDay={this.setDay}
                                    />
                                )}
                                {this.table()}
                                {this.chart()}
                            </Card>
                        </InnerContent>

                        <GenerateProgress
                            active={progress}
                            received={canShow}
                            error={genError}
                            onReady={this.progressReady}
                            onDone={this.progressDone}
                        />

                        {this.changed(
                            <Alert
                                text={
                                    error
                                        ? logic.helpers.errorMessage(
                                            error,
                                            errorDate,
                                            returned,
                                            errorMessage
                                        )
                                        : 'Изменения вступят в силу после сохранения'
                                }
                                error={error}
                                button={!error}
                                buttonText="Сохранить"
                                action={this.save}
                                cancel={!returned}
                                cancelText={
                                    error === 'tomorrow_mc_error'
                                        ? 'Закрыть'
                                        : 'Отменить изменения'
                                }
                                onCancel={
                                    error === 'tomorrow_mc_error'
                                        ? this.ignoreTomorrowMC
                                        : this.reset
                                }
                            />,
                            true
                        )}

                        {( saving || working ) && (
                            <div className="saving-overlay">
                                <Spinner/>
                            </div>
                        )}
                    </Layout.Content>
                </Layout>
            </section>
        )
    }
}

export default connect( mapStateToProps, allActions )( Schedule )
