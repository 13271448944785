/* VENDOR */
import React from 'react'

/* APPLICATION */
import { Icon, IconActive, createIcon } from './Icon'

const svg = () => (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.55 18.5401L4.96 19.9501L6.76 18.16L5.34 16.74L3.55 18.5401ZM11 22.4501C11.32 22.4501 13 22.4501 13 22.4501V19.5H11V22.4501ZM12 5.50005C10.4087 5.50005 8.88258 6.13219 7.75736 7.25741C6.63214 8.38263 6 9.90875 6 11.5C6 13.0913 6.63214 14.6175 7.75736 15.7427C8.88258 16.8679 10.4087 17.5 12 17.5C13.5913 17.5 15.1174 16.8679 16.2426 15.7427C17.3679 14.6175 18 13.0913 18 11.5C18 8.18005 15.31 5.50005 12 5.50005ZM20 12.5H23V10.5H20V12.5ZM17.24 18.16L19.04 19.9501L20.45 18.5401L18.66 16.74L17.24 18.16ZM20.45 4.46005L19.04 3.05005L17.24 4.84005L18.66 6.26005L20.45 4.46005ZM13 0.550049H11V3.50005H13V0.550049ZM4 10.5H1V12.5H4V10.5ZM6.76 4.84005L4.96 3.05005L3.55 4.46005L5.34 6.26005L6.76 4.84005Z"
            fill="#F5A623"
        />
    </svg>
)

class Day extends Icon {
    render () {
        return svg( this.primary, this.secondary )
    }
}

class DayActive extends IconActive {
    render () {
        return svg( this.primary, this.secondary )
    }
}

export default createIcon( Day, DayActive )
