import { format } from 'tools'

const req = [
    'dateStart',
    'dateEnd',
    'factsNumber',
    'size',
    'page',
    'all',
    'hideCombo',
],
      creq = [
          'dateStart',
          'dateEnd',
          'categoryId',
          'factsNumber',
          'hideCombo',
      ],
      service = 'dashboard'

export const getDishes = ( params ) =>
    format.generate.get( service, 'rating-dishes/page', params, '', req )
export const getCategoryDishes = ( params ) =>
    format.generate.get( service, 'category-dishes', params, '', creq )
export const getAllDishes = ( params ) =>
    format.generate.get( service, 'rating-dishes', params, '', req )
export const getModifiers = ( params ) =>
    format.generate.get( service, 'rating-mods/page', params, '', req )
export const getCategories = ( params ) =>
    format.generate.get( service, 'rating-categories/page', params, '', req )
// export const getManagers = ( params ) =>
//     format.generate.get( service, 'rating-managers/page', params, '', req )

export const getCashiers = ( params ) =>
    format.generate.get(
        service,
        'rating-cashiers-category/page',
        params,
        '',
        req,
        [],
        'rating-cashiers'
    )

export const getCashiersByDish = ( params ) =>
    format.generate.get(
        service,
        'rating-cashiers/page',
        params,
        '',
        req,
        [],
        'rating-cashiers'
    )

export const getDishCategory = ( id ) =>
    format.generate.get(
        'restaurant',
        'admin/dish-categories/' + id,
        [],
        '',
        [],
        [],
        'dish-cat'
    )
