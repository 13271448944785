/* eslint-disable camelcase */
/* VENDOR */
import React  from 'react'
import moment from 'moment'

/* APPLICATION */
import column     from 'config/tables/helpers'
import { format } from 'tools'
import config     from 'config'

import * as update from './update'
import * as find   from './find'
import * as add    from './add'
import * as get    from './get'

export { update, find, add, get }

export const date = ( schedule ) => {
    switch ( schedule ) {
        case 'next':
            return moment().add( 1, 'week' )
        case 'current':
            return moment()
        default:
            return moment( schedule, config.format.date )
    }
}

export const hours = ( handler, from, to ) => {
    const _to = to.hours()
    let i = from.hours()

    while ( i <= _to ) {
        handler( i )
        i++
    }
}

const hour = ( res, hover, max ) => {
    return ( hour ) => {
        const cls = [ 'col-sep col-hour nopad' ],
              isMax = max ? max.indexOf( hour ) > -1 : false

        hover && cls.push( 'col-hover' )
        isMax && cls.push( 'col-max' )

        cls.push( 'numeric align-center' )

        res.push(
            column( format.strings.mktime( hour ), 'hour-' + hour, {
                as:           'cell',
                width:        config.ui.hourWidth,
                hideCurrency: true,
                placement:    'bottom',
                className:    cls.join( ' ' ),
                popover:      () => {
                    if ( isMax ) {
                        return (
                            <span>
                Максимальный
                                <br />
                товарооборот смены
                            </span>
                        )
                    }
                    return false
                },
            })
        )
    }
}

export const hourscols = ( from, to, hoverable, max ) => {
    const res = []

    hours( hour( res, hoverable, max ), from, to )

    return res
}

export const width = ( from, to, compact ) => {
    const hours = 24,
          cfg = compact ? config.tables.schedule.mobile : config.tables.schedule.top,
          width = compact ? config.ui.mobileHourWidth : config.ui.hourWidth

    let res = 0

    res = cfg.reduce(( w, c ) => w + c.width, res )
    res += hours * width

    return res
}

export const hasData = ( state ) =>
    state.data && !!state.data.days.length && state.schedule !== 'current'

export const changed = ( state, props, res ) =>
    state.error === 'tomorrow_mc_error' ||
  ( state.data && state.data !== props.schedule && state.changed.includes( true ))
        ? res
        : ''

export const extract = ( orig, dow, position, employee, positions, guid ) => {
    const res = format.copy.object( orig ),
          day = format.copy.object( get.day( dow, orig )),
          pos = get.position( position, day, positions ),
          shift = guid ? find.shiftByGuid( guid, pos ) : find.shift( employee, pos )

    return {
        res,
        day,
        pos,
        shift,
    }
}

const hlColumn = ( tmpl, cnt, offset ) => ( hour ) =>
    parseInt( hour ) > -1
        ? // eslint-disable-next-line
      `${cnt} .staff-schedule-chart.report-table ${tmpl.replace(
            '${hour}',
            hour + 7 + offset
        )}`
        : `${cnt} .staff-schedule-chart.report-table:empty`

export const highlightColumns = ( selection, container, offset ) => {
    const styles = {
    // eslint-disable-next-line
    "thead th:nth-child(${hour})":
      'border-top: 1px solid #D3AE8B; border-left: 1px solid #D3AE8B; border-right: 1px solid #D3AE8B;',
        // eslint-disable-next-line
    "tbody td:nth-child(${hour})":
      'border-left: 1px solid #D3AE8B; border-right: 1px solid #D3AE8B;',
        // eslint-disable-next-line
    "tbody tr:last-child td:nth-child(${hour})":
      'border-bottom: 1.6px solid #D3AE8B;',
    }

    return Object.keys( styles )
        .map(
            ( rule ) =>
                selection.map(
                    hlColumn( rule, container || '.kfc-schedule', offset ? offset : 0 )
                ) +
        '{' +
        styles[ rule ] +
        '}'
        )
        .join( '' )
}

export const prepareShifts = ( shifts, pos, dayDate ) =>
    shifts
        .filter(( s ) => s === find.equal( s, pos ))
        .filter(( s ) => !s._length )
        .map(( s, index ) => {
            s._was_supervisor = s.shiftSupervisor
            s.guid = format.generate.guid()
            s.positionGuid = pos.positionGuid
            s._double = !moment( s.shiftStart ).isSame( dayDate, 'day' )

            const startTime = moment( s.shiftStart ).format( 'HH:mm' ),
                  endTime = moment( s.shiftEnd ).format( 'HH:mm' )

            if ( format.extract.intTime( startTime ) < format.extract.intTime( endTime )) {
                if ( !moment( s.shiftStart ).isSame( dayDate, 'day' )) {
                    pos.shifts.splice( index, 1 )
                } else if ( !moment( s.shiftStart ).isSame( moment( s.shiftEnd ), 'day' )) {
                    const start = moment( s.shiftStart ),
                          end = moment( s.shiftEnd )

                    s.shiftEnd = start
                        .set({ hour: end.hours(), minutes: end.minutes() })
                        .format( config.format.dateFull )
                }
            }

            return s
        })
