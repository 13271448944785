/* VENDOR */
import { update } from 'reduxigen'

/* APPLICATION */
import * as api   from './api'
import { format } from 'tools'

const asyncUpdate = format.update.asyncu,
      helper = format.helper.basic( 'reports' ),
      gen = ( type ) => {
          return asyncUpdate(
              'reports.' + type,
              ( req ) => api[ 'get' + format.strings.toCamelCase( type ) + 'Report' ]( req ),
              ( event, state ) => {
                  if ( event.status === 200 ) {
                      return helper( type, event.data, state )
                  }
                  return {
                      ...state,
                      reports: {
                          ...state.reports,
                          [ `${type}_error` ]:     true,
                          [ `${type}_error_msg` ]: event.msg || 'Ошибка запроса'
                      }
                  }
              }
          )
      }

export const flushReport = update( 'reports.flush', ( type, state ) => ({
    ...state,
    reports: {
        ...state.reports,
        [ type ]: null,
    },
}))

export const flushIByTimeReport = update(
    'reports.interactive.bytime.flush',
    ( data, state ) => ({
        ...state,
        reports: {
            ...state.reports,
            interactive: {
                ...( state.reports.interactive || {}),
                time: null,
            },
        },
    })
)

export const fetchDailyScheduleReport = gen( 'daily-schedule' )
export const fetchScheduleReport = gen( 'schedule' )
export const fetchWorktimeReport = gen( 'worktime' )
export const fetchFactworktimeReport = gen( 'factworktime' )
export const fetchReadyReport = gen( 'ready' )
export const fetchStatisticsReport = gen( 'statistics' )
export const fetchStationsReport = gen( 'stations' )
export const fetchWorkedtimeReport = gen( 'workedtime' )

export const fetchIByTimeReport = asyncUpdate(
    'reports.interactive.bytime',
    ( params ) => api.getIByTimeReport( params ),
    ( event, state ) => ({
        ...state,
        reports: {
            ...state.reports,
            interactive: {
                ...( state.reports.interactive || {}),
                time: event.data,
            },
        },
    })
)
