/* VENDOR */
import React from 'react'

/* APPLICATION */
import { Icon, IconActive, createIcon } from './Icon'

const svg = ( primary ) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
        <path
            fill={primary}
            d="M8.9925 1.5C4.8525 1.5 1.5 4.86 1.5 9c0 4.14 3.3525 7.5 7.4925 7.5C13.14 16.5 16.5 13.14 16.5 9c0-4.14-3.36-7.5-7.5075-7.5zM9 15c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm-.165-9.75H8.79c-.3 0-.54.24-.54.54v3.54c0 .2625.135.51.3675.645l3.1125 1.8675c.255.15.585.075.735-.18.1575-.255.075-.5925-.1875-.7425L9.375 9.195V5.79c0-.3-.24-.54-.54-.54z"
        />
    </svg>
)

class Clock extends Icon {
    render () {
        return svg( this.primary, this.secondary )
    }
}

class ClockActive extends IconActive {
    render () {
        return svg( this.primary, this.secondary )
    }
}

export default createIcon( Clock, ClockActive )
