import { Col }      from 'antd'
import { PprTable } from 'components'
import classNames   from 'classnames'
import React        from 'react'
import './ppr-team-structure.scss'

const PprTeamStructure = ({ data, loading, error, onRetry }) => {
    const isRedColor = ( content, isNumber ) => {
        return ( isNumber && content < 100 ) || ( !isNumber && content < 0 )
    }

    const td = ({ content, isNumber = false, isBold = false, isTotal = false, isDelta = false }) => {
        return <td className={
            classNames(
                isDelta && content > 0 && 'color-blue',
                isRedColor( content, isNumber ) && ( isTotal ? 'bg-color-red' : 'color-red' ),
                isBold && 'bold'
            )
        }>{content}{isNumber && typeof content === 'number' && '%'}</td>
    }

    const emptyRow = () => <tr>
        <td />
    </tr>

    return (
        <Col span={8}>
            <PprTable
                vertical
                loading={loading}
                error={error}
                onRetry={() => onRetry( 'teamStructure' )}
                className='ppr-team-structure'
            >
                <thead>
                    <tr>
                        <th colSpan={3}>Структура команды</th>
                    </tr>
                    <tr>
                        <th>Позиция</th>
                        <th>%</th>
                        <th>+/–</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {td({ content: 'Trainer' })}
                        {td({ content: data?.trainerRelative, isNumber: true })}
                        {td({ content: data?.trainerDelta, isDelta: true })}
                    </tr>
                    <tr>
                        {td({ content: 'TM' })}
                        {td({ content: data?.tmRelative, isNumber: true })}
                        {td({ content: data?.tmDelta, isDelta: true })}
                    </tr>
                    {emptyRow()}
                    <tr className="result-row">
                        {td({ content: 'Итого', isBold: true })}
                        {td({ content: data?.totalTeamRelative, isBold: true, isNumber: true, isTotal: true })}
                        {td({ content: data?.totalTeamDelta, isBold: true, isDelta: true })}
                    </tr>
                </tbody>
            </PprTable>
        </Col>
    )
}

export default PprTeamStructure
