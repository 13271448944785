/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { XYPlot }           from 'react-vis'

/* APPLICATION */
import { format } from 'tools'
import config     from 'config'

class ChartPlot extends Component {
    static propTypes = {
        plan: PropTypes.array,
        fact: PropTypes.array,

        single:  PropTypes.bool,
        animate: PropTypes.bool,

        height: PropTypes.number,
    }

    getX = ( data ) => {
        const max = data[ 2 ] ? data[ 2 ] : data[ 1 ],
              min = data[ 2 ] ? data[ 1 ] : data[ 0 ]

        return ( max.x - min.x ) / 2
    }

    getY = ( data ) => {
        const ext = format.extract.key( data, 'y' )
        return (
            (( format.extract.max( ext ) - format.extract.min( ext )) / data.length ) * 2
        )
    }

    offset = () => {
        const data = this.props.single ? this.props.fact : this.props.plan

        if ( !data ) { return { x: 0, y: 0 } }
        if ( data.length < 2 ) { return { x: 0, y: 0 } }

        const x = this.getX( data ),
              y = this.getY( data )

        return { x, y }
    }

    extreme = ( arrs, axis, func, def ) => {
        const vals = arrs.map(( arr ) =>
            arr.reduce(( res, v ) => func( v[ axis ], res ), def )
        )

        return vals.reduce(( res, v ) => func( v, res ), def )
    }

    min = ( arrs, axis ) => this.extreme( arrs, axis, Math.min, Infinity )
    max = ( arrs, axis ) => this.extreme( arrs, axis, Math.max, 0 )

    range = ( axis ) => {
        const { plan, fact } = this.props

        if ( !plan.length || !fact.length ) {
            return [ 1, 1 ]
        }

        const min = this.min([ plan, fact ], axis ),
              max = this.max([ plan, fact ], axis )

        return [ min - this.offset()[ axis ], max + this.offset()[ axis ] * 2 ]
    }

    width = () => {
    //Add 1 for visual offsets
        return this.props.plan
            ? config.ui.riskSize * ( this.props.plan.length + 1 ) < document.body.clientWidth * 0.7
                ? document.body.clientWidth * 0.7
                : config.ui.riskSize * ( this.props.plan.length + 1 )
            : 0
    }

    render () {
        return (
            <XYPlot
                {...this.props}
                width={this.width()}
                height={this.props.height}
                margin={{ left: 10, top: 10, right: 20, bottom: 40 }}
                xDomain={this.range( 'x' )}
                yDomain={this.range( 'y' )}
                animation={this.props.animate}
                dontCheckIfEmpty={true}
            >
                {this.props.children}
            </XYPlot>
        )
    }
}

export default ChartPlot
