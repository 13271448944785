/* VENDOR */
import React, { Component }           from 'react'
import PropTypes                      from 'prop-types'
import { InputNumber, Spin, Tooltip } from 'antd'
import Icon, { InfoCircleOutlined }   from '@ant-design/icons'
import classNames                     from 'classnames'

/* APPLICATION */
import { Icons }  from 'components'
import { format } from 'tools'

import './planning-controls.scss'

class PlanningControls extends Component {
    static propTypes = {
        hideAverage: PropTypes.bool,
        hideOnMonth: PropTypes.bool,
        disabled:    PropTypes.bool,
        disabledTO:  PropTypes.bool,

        downloadLoad: PropTypes.bool,

        turnover:          PropTypes.number,
        planTurnoverRgm:   PropTypes.number,
        deltaPlanTurnover: PropTypes.number,
        factTurnover:      PropTypes.number,
        planAverageCheck:  PropTypes.number,
        deltaTurnover:     PropTypes.number,

        onTurnover: PropTypes.func,
        onAverage:  PropTypes.func,
        onDownload: PropTypes.func,
        onUpload:   PropTypes.func,
    }

    render () {
        const {
            turnover,
            planTurnoverRgm,
            hideAverage,
            downloadLoad,
            deltaPlanTurnover,
            factTurnover,
            deltaTurnover,
            planAverageCheck,
        } = this.props

        return (
            <div className="planning-controls">
                <div className="pc-col">
                    <label htmlFor="turnover">План ТО ТУ</label>
                    <InputNumber
                        id="turnover"
                        className="turnover"
                        min={0}
                        value={turnover}
                        disabled
                        formatter={( value ) =>
                            `${format.strings.thousand( parseFloat( value ))} ₽`
                        }
                        parser={format.generate.parser( '₽', ',' )}
                        decimalSeparator={','}
                    />
                </div>
                <div className="pc-col">
                    <label htmlFor="planTurnoverRgm">План ТО Директора</label>
                    <InputNumber
                        id="planTurnoverRgm"
                        className="turnover"
                        min={0}
                        value={planTurnoverRgm}
                        disabled={true}
                        formatter={( value ) =>
                            `${format.strings.thousand( parseFloat( value ))} ₽`
                        }
                        parser={format.generate.parser( '₽', ',' )}
                        decimalSeparator={','}
                    />
                </div>
                <div className="pc-col">
                    <div className="planning-controls-info-icon">
                        <label htmlFor="deltaPlanTurnover">Разница План ТО</label>
                        <Tooltip title='Разница между "План ТО ТУ" и "План ТО Директора"'>
                            <InfoCircleOutlined />
                        </Tooltip>
                    </div>
                    <InputNumber
                        id="deltaPlanTurnover"
                        className='turnover'
                        value={deltaPlanTurnover}
                        disabled={true}
                        formatter={(value) =>
                            `${value ? format.strings.thousand(parseFloat(value)) : 0} ₽`
                        }
                        // style={{
                        //     color:
                        //         deltaPlanTurnover < 0 ? 'rgba(0,108,19,1)' :
                        //             (deltaPlanTurnover > 0 ? 'rgba(255,0,0,1)' : 'rgba(0, 0, 0, 0.45)')
                        // }}
                        parser={format.generate.parser( '₽', ',' )}
                        decimalSeparator={','}
                    />
                </div>
                <div className="pc-col">
                    <label htmlFor="factTurnover">Факт ТО</label>
                    <InputNumber
                        id="factTurnover"
                        className="turnover"
                        min={0}
                        value={factTurnover}
                        disabled={true}
                        formatter={( value ) =>
                            `${format.strings.thousand( parseFloat( value ))} ₽`
                        }
                        parser={format.generate.parser( '₽', ',' )}
                        decimalSeparator={','}
                    />
                </div>
                <div className="pc-col">
                    <div className="planning-controls-info-icon">
                        <label htmlFor="deltaTurnover">Разница</label>
                        <Tooltip title='Разница между "Факт ТО" и "План ТО Директора"'>
                            <InfoCircleOutlined />
                        </Tooltip>
                    </div>
                    <InputNumber
                        id="deltaTurnover"
                        className="turnover"
                        value={deltaTurnover}
                        disabled={true}
                        formatter={( value ) =>
                            `${format.strings.thousand( parseFloat( value ))} ₽`
                        }
                        parser={format.generate.parser( '₽', ',' )}
                        decimalSeparator={','}
                    />
                </div>


                {!hideAverage && (
                    <div className="pc-row">
                        <label htmlFor="planAverageCheck">План СЧ</label>
                        <InputNumber
                            id="planAverageCheck"
                            min={0}
                            value={planAverageCheck}
                            disabled
                            parser={format.generate.parser( '₽', ',' )}
                        />
                    </div>
                )}
                <div className="pc-row">
                    <span
                        className={classNames( 'planning-button', downloadLoad && 'disabled' )}
                        onClick={!downloadLoad && this.props.onDownload}
                    >
                        <Spin spinning={downloadLoad}>
                            <Icon component={Icons.Download.active}/>
                            Скачать шаблон
                        </Spin>
                    </span>

                </div>
            </div>
        )
    }
}

export default PlanningControls
