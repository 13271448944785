import column from './helpers'
import shared from './shared'

export default {
    dishlist: [
        column( 'Блюдо', 'name', {
            width:  320,
            sorter: 'locale',
            as:     'topstar',
        }),
        shared.percFact,
        shared.percPlan,
        shared.percDelta,
    ],

    dishes: [
        column( 'Блюдо', 'name', {
            width:  320,
            sorter: 'locale',
            as:     'topstar',
        }),
        shared.percFact,
        shared.percPlan,
        shared.percDelta,
    ],

    modifiers: [
        column( 'Модификатор', 'name', {
            width:  320,
            sorter: 'locale',
            as:     'topstar',
        }),
        shared.percFact,
        shared.percPlan,
        shared.percDelta,
    ],

    categories: [
        column( 'Категория', 'name', {
            width:  320,
            sorter: 'locale',
            as:     'topstar',
        }),
        shared.percFact,
        {
            ...shared.percPlan,
            editable: false,
        },
        shared.percDelta,
    ],

    managers: [
        column( 'Менеджер смены', 'name', { sorter: 'locale', }),
        shared.turnover,
        shared.fullness,
        shared.averageCheck,
        column( 'SPMH', 'spmh', {
            numeric: true,
            format:  'thousand',
            sorter:  'bynull',
            render:  ( text ) => text + ' ₽/час',
        }),
        column( 'TCPH', 'tcph', {
            numeric: true,
            format:  'thousand',
            sorter:  'bynull',
            render:  ( text ) => text + ' ед/час',
        }),
    ],
}
