/* VENDOR */
import React, { Component }             from 'react'
import PropTypes                        from 'prop-types'
import { Menu, Dropdown, Input, Empty } from 'antd'
import { CaretDownOutlined }            from '@ant-design/icons'
import { Scrollbars }                   from 'react-custom-scrollbars'

/* APPLICATION */
import { Link } from 'components'
import config   from 'config'

import './view-type-drop.scss'

const { Search } = Input,
      defClass = 'view-type-drop view-type-switch',
      defText = 'Другие расписания'

let searchTimeout = null

class ViewTypeDrop extends Component {
    static propTypes = {
        placeholder:       PropTypes.string,
        filterPlaceholder: PropTypes.string,

        items:   PropTypes.object.isRequired,
        current: PropTypes.string,

        height: PropTypes.any,
        filter: PropTypes.bool,
        search: PropTypes.string,

        onFilter:  PropTypes.func,
        update:    PropTypes.func,
        container: PropTypes.func,
    }

    static defaultProps = { placeholder: defText, }

    constructor ( props ) {
        super( props )

        this.container = React.createRef()

        this.state = {
            cls:  defClass,
            text: props.placeholder,
            menu: null,
            open: false,
        }
    }

    componentDidMount () {
        this.setText( this.props )
        this.setClass( this.props.className )
        this.generateMenu( this.props )
    }

    componentWillUnmount () {
        searchTimeout && clearTimeout( searchTimeout )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { items, className, current, search } = this.props;

        ( items !== nextProps.items || search !== nextProps.search ) &&
      this.generateMenu( nextProps )
        className !== nextProps.className && this.setClass( nextProps.className )
        current !== nextProps.current && this.setText( nextProps )
    }

    setClass = ( name ) =>
        this.setState({ cls: [ defClass, name ].join( ' ' ).trim(), })

    getCurrent = ( props ) => {
        const found = Object.keys( props.items ).find(( key ) => key === props.current )
        return found
    }

    setText = ( props ) =>
        this.setState({ text: this.getText( props ), })

    getText = ( props ) => {
        const found = this.getCurrent( props )
        return found ? props.items[ found ] : props.placeholder
    }

    generateMenu = ( props ) =>
        this.setState({
            menu: this.getMenu( props ),
            text: this.getText( props ),
        })

    rawMenu = ( items ) =>
        items && Object.keys( items ).length > 0 ? (
            <Menu>
                {Object.keys( items )
                    .sort(( a ) => ( a === 'all' ? -1 : 0 ))
                    .map(( key ) => this.item( key, items[ key ]))}
            </Menu>
        ) : (
            <Empty description="Ничего не найдено" image={null} />
        )

    getMenu = ( props ) => {
        const { items, height, filterPlaceholder, filter, onFilter, search } =
      props

        return height ? (
            <div style={{ height: height }} className="view-type-drop-wrap">
                {filter && (
                    <div className="dd-filter-overlay">
                        <Search
                            value={search}
                            placeholder={filterPlaceholder || 'Поиск'}
                            onChange={onFilter}
                        />
                    </div>
                )}
                <Scrollbars
                    {...config.ui.scrolls}
                    ref={( node ) => ( this.scrolls = node )}
                >
                    {this.rawMenu( items )}
                </Scrollbars>
            </div>
        ) : filter ? (
            <div className="view-type-drop-wrap">
                <div className="dd-filter-overlay">
                    <Search
                        value={search}
                        placeholder={filterPlaceholder || 'Поиск'}
                        onChange={onFilter}
                    />
                </div>
                {this.rawMenu( items )}
            </div>
        ) : (
            <div className="view-type-drop-wrap">{this.rawMenu( items )}</div>
        )
    }

    item = ( key, text ) => (
        <Menu.Item key={key}>
            <Link onClick={this.change( key )}>{text}</Link>
        </Menu.Item>
    )

    change = ( key ) => {
        return ( e ) => {
            e.preventDefault()
            this.props.update( key )
            this.trigger( false )
        }
    }

    trigger = ( open ) => {
        const { filter } = this.props

        this.setState({ open, })
        filter &&
      !open &&
      ( searchTimeout = setTimeout(
          () => this.props.onFilter({ target: { value: '' } }),
          200
      ))
    }

    render () {
        const { cls, menu, text, open } = this.state,
              { placeholder, container } = this.props

        return (
            <div className={cls} ref={this.container}>
                <Dropdown
                    overlay={menu}
                    arrow={true}
                    trigger={[ 'click' ]}
                    open={open}
                    className={text !== placeholder ? 'active' : ''}
                    onOpenChange={this.trigger}
                    overlayClassName="view-type-drop-dd"
                    getPopupContainer={
                        container ||
            (( trigger ) => this.container.current || trigger.parentNode )
                    }
                >
                    <Link className="ant-dropdown-link">
                        {text}
                        <CaretDownOutlined/>
                    </Link>
                </Dropdown>
            </div>
        )
    }
}

export default ViewTypeDrop
