import React from 'react';
import cn from 'classnames';
import styles from '../statistics/StatisticsTab/statistics-tab.module.scss';
import {Radio, Tooltip} from 'antd';
import {connect} from 'react-redux';
import {allActions, GuestVoteRadioButtonStoreProps, mapStateToProps} from './connector';
import {InfoCircleOutlined} from '@ant-design/icons';

type GuestVoteRadioButtonProps = {
  className?: string;
}

const METRICS_TYPE_OPTIONS = [
    {label: 'Рейтинг', value: 'RATING'},
    {label: 'OSAT', value: 'OSAT'},
    {label: 'HI', value: 'HI'},
];

const GuestVoteMetricsTypeSwitcher = (props: GuestVoteRadioButtonProps & GuestVoteRadioButtonStoreProps) => {
    const {className, metricsType} = props;

    return (
        <div className={cn(className, styles.metrics_switcher)}>
            <Radio.Group
                className={cn(className)}
                optionType="button"
                options={METRICS_TYPE_OPTIONS}
                value={metricsType}
                buttonStyle="solid"
                onChange={(e) => props.setMetricsType(e.target.value)}
            >
            </Radio.Group>
            <Tooltip title='Фильтры «Тип обращения»,  «Уровни влияния», «Статусы обращения», «Источники», «Категории» и «Подкатегории» не влияют на расчет показателей «OSAT» и «Рейтинг»'>
                <InfoCircleOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            </Tooltip>
        </div>
    );
};

export default connect(mapStateToProps, allActions)(GuestVoteMetricsTypeSwitcher);
