/* VENDOR */
import React from 'react'

/* APPLICATION */
import { Icon, IconActive, createIcon } from './Icon'

const svg = ( primary ) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
        <path
            fill={primary}
            d="M20.668 13.143c.0514-.3789.0787-.7606.082-1.143-.0033-.3823-.0306-.7641-.082-1.143l2.462-1.92399c.1078-.08793.181-.21117.2066-.3479.0256-.13672.0021-.27811-.0666-.3991l-2.334-4.038c-.0691-.12011-.1786-.21184-.3089-.25889-.1304-.04705-.2732-.04638-.4031.00189l-2.9 1.167c-.6044-.46787-1.269-.85232-1.976-1.143l-.443-3.092c-.0192-.137356-.088-.262954-.1933-.353187-.1053-.090232-.24-.138885-.3787-.136813H9.66602c-.13868-.002072-.27334.046581-.37868.136813-.10533.090233-.17408.215831-.19332.353187l-.443 3.092c-.70364.29545-1.36667.67956-1.973 1.143l-2.9-1.167c-.12954-.05194-.27366-.05446-.40493-.00707-.13128.04738-.24056.14137-.30707.26407l-2.335999 4.037c-.071535.12023-.096781.26242-.07101.39993.025772.13751.100793.2609.21101.34707L3.33202 10.857c-.05092.379-.07831.7607-.082 1.143.00369.3823.03108.7641.082 1.143L.870021 15.068c-.107788.0879-.180977.2112-.206601.3479-.025624.1367-.002031.2781.066601.3991l2.332999 4.037c.06913.1201.17859.2118.30894.2589.13035.047.27316.0464.40306-.0019l2.903-1.168c.6032.4676 1.26646.852 1.972 1.143l.443 3.092c.01924.1374.08799.263.19332.3532.10534.0902.24.1389.37868.1368H14.332c.1387.0021.2734-.0466.3787-.1368s.1741-.2158.1933-.3532l.443-3.092c.7033-.2955 1.366-.6796 1.972-1.143l2.9 1.167c.1296.0519.2737.0545.405.0071.1312-.0474.2405-.1414.307-.2641l2.333-4.037c.0687-.121.0922-.2624.0666-.3991-.0256-.1367-.0988-.26-.2066-.3479l-2.456-1.924zM12 16.083c-.8077.001-1.5976-.2376-2.26973-.6857-.67211-.448-1.19621-1.0853-1.50601-1.8313-.3098-.746-.39137-1.5671-.23439-2.3595.15697-.7923.54545-1.52032 1.11627-2.09183.57081-.57152 1.29836-.96089 2.09046-1.11884.7922-.15795 1.6134-.07738 2.3598.2315.7463.30889 1.3843.83221 1.8332 1.50377.4488.6716.6884 1.4612.6884 2.2689-.0013 1.0816-.4312 2.1186-1.1956 2.8839-.7643.7653-1.8008 1.1965-2.8824 1.1991z"
        />
    </svg>
)

class Settings extends Icon {
    render () {
        return svg( this.primary, this.secondary )
    }
}

class SettingsActive extends IconActive {
    render () {
        return svg( this.primary, this.secondary )
    }
}

export default createIcon( Settings, SettingsActive )
