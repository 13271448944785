export function throttle ( callback, limit ) {
    let waiting = false
    return function () {
        if ( !waiting ) {
            callback.apply( this, arguments )
            waiting = true
            setTimeout( function () {
                waiting = false
            }, limit )
        }
    }
}

export const getRandomArbitrary = ( min, max ) => {
    return Math.floor( Math.random() * ( max - min ) + min )
}
