/* VENDOR */
import React from 'react'

/* APPLICATION */
import { Icon, IconActive, createIcon } from './Icon'

const svg = ( primary ) => (
    <svg width="15" height="15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14ZM5.33332 6.41667C5.93163 6.41667 6.41666 5.93164 6.41666 5.33333C6.41666 4.73502 5.93163 4.25 5.33332 4.25C4.73502 4.25 4.24999 4.73502 4.24999 5.33333C4.24999 5.93164 4.73502 6.41667 5.33332 6.41667ZM9.66667 6.41667C10.265 6.41667 10.75 5.93164 10.75 5.33333C10.75 4.73502 10.265 4.25 9.66667 4.25C9.06836 4.25 8.58333 4.73502 8.58333 5.33333C8.58333 5.93164 9.06836 6.41667 9.66667 6.41667ZM6.0986 9.30138C5.68735 9.507 5.21057 9.94687 4.69721 10.9736L3.80278 10.5264C4.37274 9.38646 4.9793 8.743 5.65138 8.40695C6.29931 8.08299 6.94326 8.08318 7.46646 8.08333L7.49999 8.08333L7.53352 8.08333C8.05672 8.08318 8.70067 8.08299 9.3486 8.40695C10.0207 8.743 10.6272 9.38646 11.1972 10.5264L10.3028 10.9736C9.78941 9.94687 9.31263 9.507 8.90138 9.30138C8.47989 9.09063 8.05425 9.08333 7.49999 9.08333C6.94573 9.08333 6.52009 9.09063 6.0986 9.30138Z"
            fill={primary}
        />
    </svg>
)

class Sad extends Icon {
    render () {
        return svg( this.primary, this.secondary )
    }
}

class SadActive extends IconActive {
    render () {
        return svg( this.primary, this.secondary )
    }
}

export default createIcon( Sad, SadActive )
