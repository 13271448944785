/* VENDOR */
import React from 'react'

/* APPLICATION */
import { Icon, IconActive, createIcon } from './Icon'

const svg = () => (
    <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M28 25.3333V6.66667C28 5.2 26.8 4 25.3333 4H6.66667C5.2 4 4 5.2 4 6.66667V25.3333C4 26.8 5.2 28 6.66667 28H25.3333C26.8 28 28 26.8 28 25.3333ZM11.3333 18L14.6667 22.0133L19.3333 16L25.3333 24H6.66667L11.3333 18Z"
            fill="#666"
        />
    </svg>
)

class Import extends Icon {
    render () {
        return svg( this.primary, this.secondary )
    }
}

class ImportActive extends IconActive {
    render () {
        return svg( this.primary, this.secondary )
    }
}

export default createIcon( Import, ImportActive )
