import { format } from 'tools'
import config     from 'config'

import * as twins from './twins'

export const get = ( id, day, positions ) => {
    let pos = find( id, day )

    if ( !pos ) {
        pos = empty( positions, id )
        day.positions.push( pos )
    }

    return pos
}

export const find = ( id, day ) =>
    day.positions.find(( p ) => p.positionGuid === id )

export const empty = ( positions, id ) => {
    const res = format.copy.object( config.defs.schedulePosition ),
          pos = positions.find(( p ) => p.positionGuid === id )

    format.extract.selection( pos, res, [ 'position', 'positionGuid' ])
    res.positionName = pos.name
    res.shifts = []

    return { ...res }
}

export const update = ( poss, day, pos, shift, id, date, res ) => {
    shift.positionGuid = shift.positionGuid || pos.positionGuid

    const tail = twins.get( res, date, shift, 1 ),
          target = get( id, day, poss ),
          tailTarget = tail.day && tail.position ? get( id, tail.day, poss ) : null

    change( day, pos, shift, target )
    tailTarget && change( tail.day, tail.position, tail.found, tailTarget )
}

export const change = ( day, pos, shift, target ) => {
    pos.shifts.splice( pos.shifts.indexOf( shift ), 1 )

    shift.positionGuid = target.positionGuid
    shift.positionGuid !== config.mcGuid && ( shift.shiftSupervisor = false )

    target.shifts.push( shift )
}
