/* VENDOR */
import {update} from 'reduxigen'

/* APPLICATION */
import * as api from './api'
import {format} from 'tools'

const asyncUpdate = format.update.asyncu,
      helper = format.helper.basic( 'calendar' )

export const flushCalendar = update( 'calendar.flush', ( value, state ) => ({
    ...state,
    calendar: {},
}))

export const flushCalendarMonth = update(
    'calendar.flush.month',
    ( n, state ) => ({
        ...state,
        calendar: {
            ...state.calendar,
            month: null,
        },
    })
)

export const flushCalendarSub = update(
    'calendar.flush.sub',
    ( value, state ) => ({
        ...state,
        calendar: {
            ...state.calendar,
            sub: null,
        },
    })
)

export const flushCalendarSummary = update(
    'calendar.flush.summary',
    ( value, state ) => ({
        ...state,
        calendar: {
            ...state.calendar,
            summary: null,
        },
    })
)

export const fetchCalendarSub = asyncUpdate(
    'calendar.sub',
    ( params ) => api.getCalendarSub( params ),
    ( event, state ) => {
        if ( event.status === 200 ) {
          const days = event.data.days
          event.data.tasks = days.map(day => {
            return day.tasks
          }).flat()
        }

        return helper( 'sub', event.data, state )
    }
)

export const fetchCalendarEvents = asyncUpdate(
    'calendar.events',
    ( params ) => api.getCalendarEvents( params ),
    ( event, state ) => helper( 'events', event.data, state )
)

export const fetchCalendar = asyncUpdate(
    'calendar.month',
    ( params ) => api.getCalendar( params ),
    ( event, state ) => {
        if ( event.status === 200 ) {
            const days = event.data.days
            const tasks = days.map(day => {
                return day.tasks
            }).flat()

            return {
                ...state,
                calendar: {
                    ...state.calendar,
                    month: tasks,
                },
            }
        }

        return state
    }
)

export const fetchCalendarSummary = asyncUpdate(
    'calendar.summary',
    ( params ) => api.getCalendarSummary( params ),
    ( event, state ) => helper( 'summary', event.data, state )
)

export const updateCalendar = asyncUpdate(
    'calendar.update',
    ( body ) => api.updateCalendarEvent( body ),
    ( event, state ) => {
        if ( event.status === 200 ) {
            if ( !event.config.params.single ) {
                return {
                    ...state,
                    calendar: {
                        ...state.calendar,
                        events: {
                            ...state.calendar.events,
                            tasks: [ ...state.calendar.events.tasks ],
                        },
                        month: [ ...state.calendar.month ],
                    },
                }
            }

            const data = format.copy.object( event.data ),
                  events = format.copy.object( state.calendar.events ),
                  arr = format.copy.array( events.tasks ),
                  month = format.copy.array( state.calendar.month ),
                  index = format.find.index( arr, 'taskId', event.data.taskId ),
                  inCal = format.find.index( month, 'taskId', event.data.taskId )

            inCal > -1
                ? month.splice( inCal, 1 )
                : ( events.summary.dated = Math.min(
                        ++events.summary.dated,
                        events.summary.total
                    ))
            index > -1 && arr.splice( index, 1 )
            month.push( data )

            events.tasks = arr

            return {
                ...state,
                calendar: {
                    ...state.calendar,
                    month,
                    events,
                },
            }
        } else {
            return state
        }
    }
)

export const completeCalendar = asyncUpdate(
    'calendar.complete',
    ( body ) => api.completeCalendarEvent( body ),
    ( event, state ) => {
        const month = format.copy.array( state.calendar.month ),
              index = format.find.index( month, 'taskId', event.data.taskId )

        month[ index ] = event.data

        return {
            ...state,
            calendar: {
                ...state.calendar,
                month,
            },
        }
    }
)

export const addCalendarEvent = asyncUpdate(
    'calendar.add',
    ( body ) => api.addCalendarEvent( body ),
    ( event, state ) =>
        event.status === 200 && !event.data.repeat
            ? {
                    ...state,
                    calendar: {
                        ...state.calendar,
                        month: [ ...state.calendar.month, event.data ],
                    },
                }
            : {
                    ...state,
                    calendar: {
                        ...state.calendar,
                        month: [ ...state.calendar.month ],
                    },
                }
)

export const removeCalendarEvent = asyncUpdate(
    'calendar.remove',
    ( data ) => api.removeCalendarEvent( data ),
    ( event, state ) => {
        if ( event.status === 204 ) {
            if ( !event.config.params.single ) {
                return {
                    ...state,
                    calendar: {
                        ...state.calendar,
                        month: [ ...state.calendar.month ],
                    },
                }
            }

            const id = event.config.url.split( '/' ).pop(),
                  month = format.copy.array( state.calendar.month ),
                  found = month.find(( e ) => e.taskId === id ),
                  index = month.indexOf( found )

            index > -1 && month.splice( index, 1 )

            return {
                ...state,
                calendar: {
                    ...state.calendar,
                    month,
                },
            }
        } else {
            return state
        }
    }
)

export const setCalendarMonth = update(
    'calendar.set.events',
    ( month, state ) => {

        return ({
            ...state,
            calendar: {
                ...state.calendar,
                month,
            },
        })
    }
)

export const setCalendarSummary = update(
    'calendar.set.summary',
    ( summary, state ) => ({
        ...state,
        calendar: {
            ...state.calendar,
            summary,
        },
    })
)

export const setCalendarSub = update(
    'calendar.set.sub',
    ( sub, state ) => ({
        ...state,
        calendar: {
            ...state.calendar,
            sub,
        },
    })
)

export const setFactsNumbers = update(
    'calendar.set.factsNumbers',
    ( factsNumbers, state ) => {
        return ({
            ...state,
            calendar: {
                ...state.calendar,
                factsNumbers,
            },
        })
    }
)
